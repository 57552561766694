import {
  Box, LinearProgress
} from "@mui/material";
import OnboardingPopover from "./onboardingPopover";
import OnboardingTooltip from "./onboardingTooltip";

const ProcessingArticlesTooltip = ({ setIsVisible, isVisible, children }) => {

  return (
    <OnboardingTooltip
      title={
        <OnboardingPopover
          title="Processing"
          content="Ferret is processing new results and will notify you when content is ready..."
          icon={
            <Box
              sx={{
                "& .MuiLinearProgress-colorPrimary": {
                  backgroundColor: "#91CB6C",
                },
                "& .MuiLinearProgress-barColorPrimary": {
                  backgroundColor: "#EFF1F5",
                },
              }}
            >
              <LinearProgress style={{ borderRadius: 16, }} />
            </Box>
          }
          onClick={() => {
            setIsVisible(false);
            // props.onClick();
          }}
        />
      }
      componentStyle={{
        marginTop: "10% !important",
        maxWidth: "90%",
        marginLeft: "5%",
        marginRight: "5%",
        padding: 0,
        bottom: 0,
      }}
      arrowStyles={{ overflow: "inherit" }}
      isVisible={isVisible}
      placement="top"
    >
      {children}
    </OnboardingTooltip>
  );
};

export default ProcessingArticlesTooltip;
