import React, { useState, useEffect } from 'react';
import NoPersoneInfo from './noPersoneInfo'
import PersonalInfoSummary from './personalInfoSummary'
import ArticleCategoriesList from './articleCategoriesList'
import Variables from '../../utils/Variables';
import FilterByVerificationType from '../filterByVerificationType/filterByVerificationType';
import { Toolbar } from '@mui/material';
import Styles from './personeInfo.module.css'
import { WhiteBgButtonGreenBorder } from '../buttons/whiteBgButtonGreenBorder';
import MapView from "../map/mapView";

const PersoneInfo = (props) => {
  const { personalSummary, emptyCategories, dataCategories } = props?.personalInfo
  const { profileName = '', linkedInData, feedbackOptions, profilePic, setProfilePic,
  setPDFWebFerretAI,
  setPDFWebFerretAISummary,
  setPDFEducationMore,
  setPDFExperienceMore,
  setPDFShowOthers,
  setPDFShowMore,
  setOpenMapView,
 } = props
  const {
    onClick,
    verified,
    setVerified,
    partial,
    setPartial,
    needsReview,
    setNeedsReview,
    processArticleInProgress,
    potentialMatchId,
  } = props;

  const [dataCat, setDataCat] = useState([])
  const [emptyDataCat, setEmptyDataCat] = useState([])

  useEffect(() => {
    if (dataCategories && processArticleInProgress) {
      let dataMap = dataCategories.map((item) => item.category)
      let newDataCategory = [...dataCategories.map((item) => ({ ...item, isProcessing: processArticleInProgress.includes(item.category) })),
      ...(processArticleInProgress.filter((item) => !dataMap.includes(item))
          .map((item) => ({ category: item, isProcessing: true, count: 0 })))
          .sort((a, b) => b.isProcessing - a.isProcessing)]
      newDataCategory.sort((a, b) => b.isProcessing - a.isProcessing)
      setDataCat(newDataCategory)

      let newEmptyDataCategory = [...emptyCategories].filter((item) => !processArticleInProgress.includes(item.category) && item.category !== "Crunchbase")
      setEmptyDataCat(newEmptyDataCategory)
    } else if (dataCategories || emptyCategories) {
      setDataCat([...dataCategories])
      setEmptyDataCat([...emptyCategories])
    }
  }, [dataCategories, processArticleInProgress])

  return (
    <div className={Styles.container}>
      <Toolbar />
      {dataCat && (
        <PersonalInfoSummary
          profilePic={profilePic}
          setProfilePic={setProfilePic}
          feedbackOptions={feedbackOptions}
          linkedInData={linkedInData}
          profileName={profileName}
          personalSummary={personalSummary}
          potentialMatchId={potentialMatchId}
          setPDFWebFerretAI={setPDFWebFerretAI}
          setPDFWebFerretAISummary={setPDFWebFerretAISummary}
          setPDFEducationMore={setPDFEducationMore}
          setPDFExperienceMore={setPDFExperienceMore}
          setPDFShowOthers={setPDFShowOthers}
          setPDFShowMore={setPDFShowMore}
        />
      )}
      {dataCat && dataCat.length > 0 && (
        <FilterByVerificationType
          verified={verified}
          setVerified={setVerified}
          partial={partial}
          setPartial={setPartial}
          needsReview={needsReview}
          setNeedsReview={setNeedsReview}
        />
      )}
       {/* <WhiteBgButtonGreenBorder
        onClick={() => {
          setOpenMapView?.(true)
        }}
        style={{ width: 150, padding: 10, marginLeft: 20, marginTop: 20, whiteSpace: "nowrap" }}
      >
        <span className={Styles.generatePdfText}>Open Map</span>
      </WhiteBgButtonGreenBorder> */}
      {dataCat && dataCat.length > 0 && (
        <ArticleCategoriesList
          data={dataCat}
          isClickable={true}
          onClick={onClick}
          isDataCategory={true}
          verified={verified}
          partial={partial}
          needsReview={needsReview}
        />
      )}
      <div className={Styles.emptyCategories}>
        {emptyDataCat && emptyDataCat.length > 0 && (
          <ArticleCategoriesList data={emptyDataCat} isClickable={false} />
        )}
      </div>
    </div>
  );
}
export default PersoneInfo