import React from 'react'
import Lottie from 'react-lottie'

const TagAnimation = (props) => {
  const {source, style, speed = 1, ...otherProps} = props
  return (
    <Lottie
      animationData={source}
      style={style}
      loop={false}
      autoPlay
      speed={speed}
      {...otherProps}
    />
  )
}

export default TagAnimation