import React, { useState, useEffect, useRef } from "react";
import createStyle from "./styles";
import { useLazyQuery, useMutation } from "react-apollo";
import { GET_UNICOURT_CASE, IS_VALID_URL, SAVE_ARTICLE_HISTORY } from "../../queries/queries";
import RecordUnavailable from "../article/recordUnavailable/recordUnavailable";
import { Backdrop, CircularProgress } from "@mui/material";
import PlainTextArticle from "../plainTextArticle/plainTextArticle";
import Styles from "./newsContentDispatcher.module.css";
import LoadingBar from "../../web/components/loadingBar/locadingBar";
import { searchResultStore } from "../../store/searchResultStore";
import { GreenBgButton } from "../buttons/greenBgButton";
import iconNewWindow from "../../images/svg/icon-new-window.svg";
import IllustrationDesktopBrowser from "../../images/svg/Illustration-Desktop-Browser.svg";
import parseSaveHistory from "../articleCardWithQuery/parseSaveHistoryData";

const NewsContentDispatcher = (props) => {
  const {
    content: article,
    screenHeight,
    setIsSummaryExpanded,
    isSummaryExpanded,
    isExpandable,
    removeIframe,
    isWeb,
    urlVailidaterData,
    newsItem,
    recentSearchId,
    contactDataID,
    displayPostArticleRelevanceBot,
    potentialMatchId,
    setIsArticleOpened
  } = props;

  const style = createStyle(screenHeight);
  const { innerWidth: deviceWidth, innerHeight: windowHeight } = window;
  const iframeRef = useRef(null);

  const [sourceUrl, setSourceUrl] = useState()
  const [pdfUrl, setPdfUrl] = useState()
  const [iframeLoaded, setIframeLoaded] = useState(false)
  const [unicourtURL, setUnicourtURL] = useState("")

  const isPdfGenerating = searchResultStore((state) => state.isPdfGenerating);

  let errorTh = 0;

  const [imageDimensions, setImageDimensions] = useState({
    width: deviceWidth - 30,
    height: deviceWidth - 30,
  });

  const [getUnicourtCaseDetails, {
    loading: getUnicourtCaseDetailsLoading,
    error: getUnicourtCaseDetailsError,
    data: getUnicourtCaseDetailsData
  }] = useLazyQuery(GET_UNICOURT_CASE, {
    fetchPolicy: 'no-cache',
    errorPolicy: "all"
  });

  const [saveArticleHistory] = useMutation(SAVE_ARTICLE_HISTORY, {
    onCompleted(data) { },
    onError(ee) { },
  });


  useEffect(() => {
    if (!getUnicourtCaseDetailsLoading && getUnicourtCaseDetailsData?.getUnicourtCase) {
      setUnicourtURL(getUnicourtCaseDetailsData?.getUnicourtCase?.url)
    }
    if (!getUnicourtCaseDetailsLoading && (getUnicourtCaseDetailsData?.getUnicourtCase || getUnicourtCaseDetailsError)) {

      isValidUrl({
        variables: {
          url: getUnicourtCaseDetailsData?.getUnicourtCase?.url,
          title: getUnicourtCaseDetailsData?.getUnicourtCase?.name
        }
      })

      const newsItemData = { ...newsItem, article: { ...newsItem.article, pageURL: getUnicourtCaseDetailsData?.getUnicourtCase?.url }, pageURL: getUnicourtCaseDetailsData?.getUnicourtCase?.url };
      let newsdata = parseSaveHistory(
        newsItemData,
        recentSearchId,
        contactDataID,
        displayPostArticleRelevanceBot,
        potentialMatchId
      )

      saveArticleHistory({
        variables: {
          data: newsdata,
        },
      })
    }
  }, [
    getUnicourtCaseDetailsLoading,
    getUnicourtCaseDetailsError,
    getUnicourtCaseDetailsData
  ])

  const [isValidUrl, { loading, error, data }] = useLazyQuery(IS_VALID_URL, {
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if (urlVailidaterData == undefined) {
      if (article) {
        if (newsItem.itemType == "Unicourt") {
          getUnicourtCaseDetails({
            variables: {
              caseId: newsItem.caseId
            }
          })
        } else {
          let _sourceUrl = article?.selectedDocument ?
            article.selectedDocument?.details?.url
            :
            article.pageURL

          // Call api for url validity check
          isValidUrl({
            variables: {
              url: _sourceUrl,
              title: article?.title
            }
          })
        }
      }
    }
  }, [article]);

  useEffect(() => {
    if (!loading && data) {

      let _sourceUrl = article?.selectedDocument ?
        article.selectedDocument?.details?.url
        :
        article.pageURL

      if (data?.isValidUrl?.httpsEnabled) {
        _sourceUrl = _sourceUrl?.replace(/^http:\/\//i, 'https://');
      }

      if (_sourceUrl?.includes('/WikinewsLatestNews')) {
        const trimmedOutput = _sourceUrl.substring(_sourceUrl.lastIndexOf('/') + 1);
        _sourceUrl = 'https://en.wikinews.org/wiki/' + trimmedOutput;
      }

      if (data?.isValidUrl?.contentType?.includes("pdf")) {
        const _pdfURL = "https://docs.google.com/viewerng/viewer?url=" + _sourceUrl + '&embedded=true';
        setPdfUrl(_pdfURL)
      } else {
        setSourceUrl(_sourceUrl ?? unicourtURL)
      }

      if (setIsArticleOpened) {
        setIsArticleOpened?.(true)
      }

    } else if (!loading && error) {
      if (setIsArticleOpened) {
        setIsArticleOpened?.(true)
      }
    }
  }, [loading, data, error])

  const onIframeLoaded = (res) => {
    setIframeLoaded(true)
  }

  useEffect(() => {
    if (!article?.imageURL) return;

    Image.getSize(
      article.imageURL,
      (width, height) => {
        const scaleFactor = deviceWidth / width;
        const scaledHeight = Math.floor(height * scaleFactor);
        setImageDimensions({
          width: deviceWidth,
          height: scaledHeight,
        });
      },
      // Handle image load error
      (error) => {
        console.error('Error loading image:', error);
      }
    );
  }, [article, deviceWidth]);

  return (
    <>
      {(urlVailidaterData || (isPdfGenerating && !isWeb)) ? (
        (urlVailidaterData || (isPdfGenerating && !isWeb)) ? (
          <div className={Styles.container}>
            <img
              className={Styles.image}
              src={IllustrationDesktopBrowser}
              resizeMode={"contain"}
            />
            <p className={Styles.title} style={{ marginBottom: 16 }}>
              Access the original content in{" "}
              {urlVailidaterData?.isValidUrl?.contentType?.includes("pdf")
                ? "PDF"
                : "webpage"}{" "}
              format by clicking the button below.
            </p>

            <GreenBgButton className={Styles["showCacheBtn"]}>
              <a href={urlVailidaterData?.sourceUrl} className={Styles.showCacheBtnTxt}>
                Open{" "}
                {urlVailidaterData?.isValidUrl?.contentType?.includes("pdf")
                  ? "PDF"
                  : "Article"}{" "}
                <img src={iconNewWindow} className={Styles.iconNewWindow} />
              </a>
            </GreenBgButton>

            <p className={Styles.text}>
              Please take note: Although the webpage is accessible, its
              rendering in PDF format may not be optimal. For the best viewing
              experience, we recommend clicking the link to open it directly in
              your browser.
            </p>
          </div>
        ) : (
          <RecordUnavailable
            sourceUrl={sourceUrl}
            errorMsg={data?.isValidUrl?.valid && data?.isValidUrl?.httpsEnabled}
            isValidUrl={data?.isValidUrl?.valid}
            screenHeight={400}
            fullText={article?.fulltext}
            setIsSummaryExpanded={setIsSummaryExpanded}
            isSummaryExpanded={isSummaryExpanded}
            isExpandable={isExpandable}
            setIsArticleOpened={setIsArticleOpened}
          />
        )
      ) : (
        <div
          style={{
            ...style.mainContainer,
            height: removeIframe ? 0 : screenHeight,
          }}
        >
          {article?.imageURL && article?.allowRenderImage ? (
            <img
              resizeMode="cover"
              style={{
                width: imageDimensions.width,
                height: imageDimensions.height,
                ...style.image,
              }}
              src={{ uri: article?.imageURL }}
              resizeMethod="scale"
            />
          ) : null}
          {(loading || isPdfGenerating) &&
            (isWeb ? (
              <div className={Styles.containerRightparantProgressbar}>
                <LoadingBar />
              </div>
            ) : (
              <Backdrop
                sx={{
                  backgroundColor: "#00000000",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={true}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            ))}
          {(!isPdfGenerating && (error ||
            data?.isValidUrl?.valid == false ||
            data?.isValidUrl?.httpsEnabled == false ||
            getUnicourtCaseDetailsError?.error?.length > 0)) && (
              <RecordUnavailable
                sourceUrl={sourceUrl}
                errorMsg={
                  data?.isValidUrl?.valid && data?.isValidUrl?.httpsEnabled
                }
                isValidUrl={data?.isValidUrl?.valid}
                screenHeight={screenHeight}
                fullText={article?.fulltext}
                setIsSummaryExpanded={setIsSummaryExpanded}
                isSummaryExpanded={isSummaryExpanded}
                isExpandable={isExpandable}
                setIsArticleOpened={setIsArticleOpened}
              />
            )}
          {!loading && !isPdfGenerating &&
            data &&
            data?.isValidUrl?.valid == true &&
            data?.isValidUrl?.httpsEnabled == true ? (
            <div style={style.container} className={Styles.iframeContainer}>
              {(!isPdfGenerating || !isWeb) && (
                <>
                  {data?.isValidUrl?.contentType?.includes("pdf") ? (
                    pdfUrl ? (
                      <iframe
                        className={Styles.iframePlaceholder}
                        ref={iframeRef}
                        src={pdfUrl}
                        onLoad={iframeLoaded}
                        style={{
                          width: "100%",
                          height: "100%",
                          overflow: "scroll",
                        }}
                      />
                    ) : (
                      <></>
                    )
                  ) : (
                    <iframe
                      className={Styles.iframePlaceholder}
                      src={sourceUrl}
                      style={{
                        width: "100%",
                        height: "100%",
                        overflow: "auto",
                      }}
                    />
                  )}
                </>
              )}
            </div>
          ) : null}

          {isPdfGenerating && isWeb && (
            <div className={Styles.container}>
              <img
                className={Styles.image}
                src={IllustrationDesktopBrowser}
                resizeMode={"contain"}
              />
              <p className={Styles.title} style={{ marginBottom: 16 }}>
                Access the original content in{" "}
                {data?.isValidUrl?.contentType?.includes("pdf")
                  ? "PDF"
                  : "webpage"}{" "}
                format by clicking the button below.
              </p>

              <GreenBgButton className={Styles["showCacheBtn"]}>
                <a href={data?.isValidUrl?.contentType?.includes("pdf") ? pdfUrl : sourceUrl} className={Styles.showCacheBtnTxt}>
                  Open{" "}
                  {data?.isValidUrl?.contentType?.includes("pdf")
                    ? "PDF"
                    : "Article"}{" "}
                  <img
                    src={iconNewWindow}
                    className={Styles.iconNewWindow}
                  />
                </a>
              </GreenBgButton>

              <p className={Styles.text}>
                Please take note: Although the webpage is accessible, its
                rendering in PDF format may not be optimal. For the best
                viewing experience, we recommend clicking the link to open
                it directly in your browser.
              </p>
            </div>
          )}

          {/* {!article?.pageURL && <PlainTextArticle content={article?.content} />} */}
        </div>
      )}
    </>
  );
};

export default NewsContentDispatcher;
