const Answer = (label, type, status, updated, itemOther) => ({
  label,
  type,
  status,
  updated,
  value: type === questionsTypes.OTHER ? itemOther : label,
});

const Question = (identifier, label, answers, meta_data, potentialMatches) => ({
  identifier,
  label,
  answers,
  answered: false,
  potentialMatches,
  meta_data
});

const optionsToAnswers = ({item: label, type, status = false, updated = 0, itemOther = ''}) => Answer(label, type, status, updated, itemOther);

const questionsTypes = {
  REGULAR: 'radio_button',
  OTHER: 'textbox',
};

export {Question, optionsToAnswers, questionsTypes};
