import React, { useState, useEffect, useContext, useRef } from 'react';
import Styles from './recentViewActivity.module.css';
import { useLazyQuery } from 'react-apollo';
import {
  GET_ARTICLE_HISTORY,
  GET_HISTORY_ARTICLE_TYPE,
  GET_CONFIGURATION
} from '../../queries/queries';
// import AlertPopup from '../../molecules/alertPopup';
import alertIcon from '../../images/no-results-icon.png'
import getFormatedArticles from '../../utils/formatters/articleFormatter';
import SectionListView from '../articleList/SectionListView'
import Variables from '../../utils/Variables'
import useRefineSearch from '../searchModalV2/useRefineSearch';
import {
  sortArticles,
  formatArticleAttributes,
  filterArticles,
  returnIntialTags
} from '../../utils/filterUtils';
import filterSection from './filterSection';
import { useNavigate, useLocation } from "react-router-dom";
import { IconButton, CircularProgress, Backdrop } from "@mui/material";
import back_white from "../../images/svg/back_white.svg"
import AlertPopup from '../AlertPopup/AlertPopup';
import { recentViewArticles } from '../../store/recentViewActivityStore'
import Article from '../../web/components/article/article';
import PDFDownload from '../../images/svg/pdf-download';
import Download from '../../images/svg/download';
import { searchResultStore } from '../../store/searchResultStore';
import { generatePdf } from '../../utils/pdfGeneratorUtils';
import { capitalizeCase } from '../../utils/utils';

let theme;
let clickedFilter = false;
let clearFilterArticleType = false;
let clearFilterItemType = false;
let lastDataSize;
let riskLevelsStatuses = {
  high: true,
  medium: true,
  low: true,
}
let temporaryStatusesOfFilters = null;
let originalArticles = [];

const RecentViewActivity = props => {

  const scrollRef = useRef();

  const navigate = useNavigate();
  const incomingArticles = []

  const [showArticleDetails, setArticleDetails] = useState(false)
  const [openArticleDetail, setOpenArticleDetail] = useState({ status: false, data: {} });

  const [isSummaryExpanded, setIsSummaryExpanded] = useState(false);
  const [sectionIndex, setSectionIndex] = useState(0);

  const [isPDFGenerate, setPDFGenerate] = useState(false);

  const setIsPdfGenerating = searchResultStore(
    (state) => state.setIsPdfGenerating
  );

  const configurationData = recentViewArticles((state) => state.configurationData)
  const setConfigurationData = recentViewArticles((state) => state.setConfigurationData)

  const ConfigurationLoading = recentViewArticles((state) => state.ConfigurationLoading)
  const setConfigurationLoading = recentViewArticles((state) => state.setConfigurationLoading)

  const searchSettings = recentViewArticles((state) => state.searchSettings)
  const setSearchSettings = recentViewArticles((state) => state.setSearchSettings)

  const filteredArticles = recentViewArticles((state) => state.filteredArticles)
  const setFilteredArticles = recentViewArticles((state) => state.setFilteredArticles)

  const filteredArticles2 = recentViewArticles((state) => state.filteredArticles2)
  const setFilteredArticles2 = recentViewArticles((state) => state.setFilteredArticles2)

  const originalfilteredArticles = recentViewArticles((state) => state.originalfilteredArticles)
  const setOriginalFilteredArticles = recentViewArticles((state) => state.setOriginalFilteredArticles)

  const isEnableFilter = recentViewArticles((state) => state.isEnableFilter)
  const setEnableFilter = recentViewArticles((state) => state.setEnableFilter)

  const articleFilterData = recentViewArticles((state) => state.articleFilterData)
  const setArticleFilterData = recentViewArticles((state) => state.setArticleFilterData)

  const personalInfo = recentViewArticles((state) => state.personalInfo)
  const setPersonalInfo = recentViewArticles((state) => state.setPersonalInfo)

  const isShowPersonalInfo = recentViewArticles((state) => state.isShowPersonalInfo)
  const setShowPersonalInfo = recentViewArticles((state) => state.setShowPersonalInfo)

  const selectedFilter = recentViewArticles((state) => state.selectedFilter)
  const setSelectedFilter = recentViewArticles((state) => state.setSelectedFilter)

  const sortState = recentViewArticles((state) => state.sortState)
  const setSortState = recentViewArticles((state) => state.setSortState)

  const showStrongMatchArticles = recentViewArticles((state) => state.showStrongMatchArticles)
  const setShowStrongMatchArticles = recentViewArticles((state) => state.setShowStrongMatchArticles)

  const showWeakMatchArticles = recentViewArticles((state) => state.showWeakMatchArticles)
  const setShowWeakMatchArticles = recentViewArticles((state) => state.setShowWeakMatchArticles)

  const filterAttributes = recentViewArticles((state) => state.filterAttributes)
  const setFilterAttributes = recentViewArticles((state) => state.setFilterAttributes)

  const readFilter = recentViewArticles((state) => state.readFilter)
  const setReadFilter = recentViewArticles((state) => state.setReadFilter)

  const unreadFilter = recentViewArticles((state) => state.unreadFilter)
  const setUnreadFilter = recentViewArticles((state) => state.setUnreadFilter)

  const attributes = recentViewArticles((state) => state.attributes)
  const setAttributes = recentViewArticles((state) => state.setAttributes)

  const verified = recentViewArticles((state) => state.verified)
  const setVerified = recentViewArticles((state) => state.setVerified)

  const partial = recentViewArticles((state) => state.partial)
  const setPartial = recentViewArticles((state) => state.setPartial)

  const needsReview = recentViewArticles((state) => state.needsReview)
  const setNeedsReview = recentViewArticles((state) => state.setNeedsReview)

  const refreshList = recentViewArticles((state) => state.refreshList)
  const setRefreshList = recentViewArticles((state) => state.setRefreshList)

  const showHeader = recentViewArticles((state) => state.showHeader)
  const setShowHeader = recentViewArticles((state) => state.setShowHeader)

  const modalFilterVisible = recentViewArticles((state) => state.modalFilterVisible)
  const setModalFilterVisible = recentViewArticles((state) => state.setModalFilterVisible)

  const articleCount = recentViewArticles((state) => state.articleCount)
  const setArticleCount = recentViewArticles((state) => state.setArticleCount)

  const articles = recentViewArticles((state) => state.articles)
  const setArticles = recentViewArticles((state) => state.setArticles)

  const applyFilter = recentViewArticles((state) => state.applyFilter)
  const setApplyFilter = recentViewArticles((state) => state.setApplyFilter)

  const isNoResult = recentViewArticles((state) => state.isNoResult)
  const setNoResult = recentViewArticles((state) => state.setNoResult)

  const alertPopupVisible = recentViewArticles((state) => state.alertPopupVisible)
  const setAlertPopupVisible = recentViewArticles((state) => state.setAlertPopupVisible)

  const refreshing = recentViewArticles((state) => state.refreshing)
  const setRefreshing = recentViewArticles((state) => state.setRefreshing)

  const refreshingFilter = recentViewArticles((state) => state.refreshingFilter)
  const setRefreshingFilter = recentViewArticles((state) => state.setRefreshingFilter)

  const categoryFilterData = recentViewArticles((state) => state.categoryFilterData)
  const setCategoryFilterData = recentViewArticles((state) => state.setCategoryFilterData)

  const articlePosition = recentViewArticles((state) => state.articlePosition)
  const setArticlePosition = recentViewArticles((state) => state.setArticlePosition)

  const removeAll = recentViewArticles((state) => state.removeAll)
  const [articleSummary, setArticleSummary] = useState("");
  const [showArticleSummary, setShowArticleSummary] = useState(false);
  const {
    mapSingleArticle,
  } = useRefineSearch();

  let isFirstOpen = false;

  const [getConfigData, { loading: configLoading, data: configData }] = useLazyQuery(GET_CONFIGURATION);
  useEffect(() => {
    if (!configLoading && configData) {
      setConfigurationData(configData)
      setConfigurationLoading(configLoading)
    }
  }, [configLoading, configData])

  const [getFeed, { loading, data }] = useLazyQuery(GET_ARTICLE_HISTORY, {
    fetchPolicy: 'no-cache',
  });

  const [getArticleType, { loading: loadingType, data: articleData }] = useLazyQuery(GET_HISTORY_ARTICLE_TYPE);

  useEffect(() => {
    getConfigData()
    getArticleType({ variables: { riskRanking: [] } })
    window.scrollTo(0, 0);
    setShowHeader(true)
  }, [])

  useEffect(() => {
    
    const current = scrollRef.current;

    if (!showArticleDetails) {
      setTimeout(() => {
        current.scrollTo(0, articlePosition - 100);
      }, 100)
    }

    const handleScroll = () => {
      if (scrollRef.current && !showArticleDetails) {
        setArticlePosition(scrollRef.current.scrollTop);
      }
    };

    if (current) {
      current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (current) {
        current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [showArticleDetails]);

  
  useEffect(() => {
    if (refreshList) {
      getArticleType({ variables: { riskRanking: [] } })
      setRefreshList(false)
    }
  }, [refreshList])

  useEffect(() => {
    async function fetchData() {
      reloadScreenData()
    }
    fetchData();
  }, [])

  const handleGeneratePDF = () => {
    setIsPdfGenerating(true);
    setPDFGenerate(true);
    setTimeout(() => {
      let fullName =
        capitalizeCase(
          openArticleDetail?.data?.newsItem?.highlightKey ?? openArticleDetail?.data?.newsItem.fullName ?? '',
          true
        )
      generatePdf(
        openArticleDetail?.status ? 'article-content-div' : 'content-to-pdf',
        fullName,
        () => {
          setIsPdfGenerating(false);
          setPDFGenerate(false);
        })
    }, 500);
  };


  const reloadScreenData = () => {
    if (Variables.articleIdMarkNo && filteredArticles.length > 0) {
      setTimeout(() => {
        let riskCategory = ''
        const mmArticle = filteredArticles.filter((item => {
          if (item?.id ? item.id == Variables.articleIdMarkNo : item.articleId == Variables.articleIdMarkNo) {
            riskCategory = item?.riskCategory
            return false
          }
          return true
        }))
        originalArticles = originalArticles.filter((item => {
          if (item?.id ? item.id == Variables.articleIdMarkNo : item.articleId == Variables.articleIdMarkNo) {
            riskCategory = item?.riskCategory
            return false
          }
          return true
        }))
        setFilteredArticles(formatArticleAttributes(mmArticle))
        filterArticleTypeCount();
        const countArticle = {
          "highRisk": articleCount?.highRisk - (riskCategory == 'high' ? 1 : 0),
          "news": articleCount?.news - (riskCategory == 'low' ? 1 : 0),
          "alerts": articleCount?.alerts - (riskCategory == 'medium' ? 1 : 0),
        }
        setArticleCount(countArticle)
        Variables.articleIdMarkNo = false
        setAlertPopupVisible(mmArticle.length == 0)
      }, 200)
    }
  }

  useEffect(() => {
    fetchArticlesTypeByRiskRanking()
  }, []);

  const fetchArticlesTypeByRiskRanking = () => {
    const riskLevel = Variables.riskLevelsStatuses
    const riskLevelFilter = []
    if (riskLevel) {
      if (riskLevel?.high) {
        riskLevelFilter.push('HIGH')
      }
      if (riskLevel?.low) {
        riskLevelFilter.push('LOW')
      }
      if (riskLevel?.medium) {
        riskLevelFilter.push('MEDIUM')
      }
    }
  }

  useEffect(() => {
    if (applyFilter)
      onHandleRefresh(true)
  }, [applyFilter])


  useEffect(() => {
    if (!loadingType && articleData) {

      getFeed({
        variables: { chunkSize: 2000 }
      })
      let articleFilterData = articleData?.getHistoryArticleType?.filter(item => item.count)
      setArticleFilterData(articleFilterData)
      filterArticleTypeCount(articleFilterData)
    }
  }, [loadingType, articleData,])

  useEffect(() => {
    if (
      !loading &&
      data &&
      Array.isArray(data?.getArticleHistory?.articles)
    ) {
      if (data?.getArticleHistory?.categoryFilterData !== null) {
        setCategoryFilterData(data?.getArticleHistory?.categoryFilterData)
      }
      fetchArticlesTypeByRiskRanking()
      const parsedArticles = getFormatedArticles(data.getArticleHistory.articles);
      originalArticles = parsedArticles;
      setFilteredArticles(parsedArticles)
      if (refreshing || refreshingFilter) {
        setRefreshing(false);
        setRefreshingFilter(false);
        const latestArticles = parsedArticles;
        lastDataSize = latestArticles.length;
        setArticles(latestArticles);
      } else {
        const latestArticles = parsedArticles;
        lastDataSize = latestArticles.length;
        const newArticles = articles.concat(latestArticles);
        setArticles(newArticles);
      }
      setAlertPopupVisible(parsedArticles.length == 0)
    } else if (!loading &&
      data &&
      Array.isArray(data?.getArticleHistory?.articles) &&
      data?.getArticleHistory?.articles?.length == 0) {
      setNoResult(true)
      setAlertPopupVisible(true)
    }
  }, [loading, data])

  const getSelectedParentCategory = () => {
    let categoryType = []
    if (temporaryStatusesOfFilters) {
      temporaryStatusesOfFilters[0].filters.forEach(type => {
        if (type.status) {
          categoryType = [...categoryType, type?.label]
        }
        else if (type.count && type.count > 0)
          isFirstOpen = true
      });
    }
    return categoryType
  }

  const filterAction = (isRiskRankingApplied) => {
    if (!originalArticles || originalArticles.length === 0) {
      setFilteredArticles([])
      return;
    }

    const selectedParentCategory = getSelectedParentCategory()

    let filterByArticleType = [...originalArticles]
    filterByArticleType = filterByArticleType.filter(
      item => riskLevelsStatuses[item?.riskCategory?.toLowerCase()]
    );

    if (selectedParentCategory && ((selectedParentCategory.length) || isFirstOpen)) {
      filterByArticleType = filterByArticleType.filter(
        item => selectedParentCategory.includes(item.parentCategory) || selectedParentCategory.includes('All')
      );

    }

    if (!isRiskRankingApplied) {
      if (categoryFilterData) {
        setAttributes(returnIntialTags(categoryFilterData?.Places, categoryFilterData?.Organizations, undefined, [], categoryFilterData?.People, categoryFilterData?.Parties, categoryFilterData?.Attorneys))
      }
      setFilteredArticles(formatArticleAttributes(filterByArticleType));
    } else {
      filterArticleTypeCount()
    }

    setEnableFilter(true)
  };

  const filterArticleTypeCount = (data) => {
    const Filterdata = (articleFilterData && articleFilterData.length > 0) ? articleFilterData : data
    if (Filterdata) {
      let mArticleFilter = [...Filterdata]
      const updatedArray = mArticleFilter.map(itemType => {
        return {
          ...itemType,
          count: filterArticles(
            showStrongMatchArticles,
            showWeakMatchArticles,
            readFilter,
            unreadFilter,
            filterAttributes,
            originalArticles,
            itemType?.category,
          ).filter(itemArticle => itemType?.category === (itemArticle?.parentCategory ?? null)
            && isRiskCategorySelected(itemArticle?.riskCategory?.toLowerCase())
          ).length
        }
      })
      setArticleFilterData(updatedArray)

      if (Variables.articleIdMarkNo && filteredArticles.length > 0) {
        setPersonalInfo((personalInfo) => ({ ...personalInfo, dataCategories: updatedArray }))
      }
    }
  }

  const onContinuePress = () => {
    setAlertPopupVisible(false)
    navigate((-1))
  }

  const isRiskCategorySelected = (category) => {
    const riskLevel = Variables.riskLevelsStatuses
    if (category && riskLevel?.[category]) {
      return true
    } else {
      return false
    }
  }

  const onHandleRefresh = (clearData = false) => {
    clickedFilter = true;
    const variables = {
      chunkSize: 50,
    }
    if (applyFilter) {
      variables.articleType = clearFilterArticleType ? [] : applyFilter.seletedArticleType
      variables.itemType = clearFilterItemType ? [] : applyFilter.seletedItemType
      variables.riskCode = clearFilterItemType ? [] : applyFilter.seletedRiskType;
    }

    setRefreshing(!clearData)
    setRefreshingFilter(clearData)
    if (clearData) {
      setArticles([])
    }
  };

  const onFilterCompleted = (riskLevel, filterData, isRiskRankingApplied = false) => {
    riskLevelsStatuses = riskLevel
    temporaryStatusesOfFilters = filterData
    let applyFilter = false
    if (!riskLevelsStatuses?.high || !riskLevelsStatuses?.low || !riskLevelsStatuses?.medium) {
      applyFilter = true
    }
    if (temporaryStatusesOfFilters) {
      temporaryStatusesOfFilters[0].filters.forEach(type => {
        if (type.count && type.count > 0 && !type.status) {
          applyFilter = true;
        }
      });
    }
    filterAction(isRiskRankingApplied)
    const selectedParentCategory = getSelectedParentCategory();

    if (!selectedParentCategory.includes(selectedFilter)) {
      window.scrollTo(0, 0);
    }
  }

  //sorting articles
  useEffect(() => {
    setFilteredArticles2(sortArticles(filteredArticles2, sortState));
  }, [sortState]);

  //filtering articles
  useEffect(() => {
    setFilteredArticles2(
      filterArticles(
        showStrongMatchArticles,
        showWeakMatchArticles,
        readFilter,
        unreadFilter,
        filterAttributes,
        filteredArticles,
        getSelectedParentCategory()
      ),
    );
  }, [
    showStrongMatchArticles,
    showWeakMatchArticles,
    readFilter,
    unreadFilter,
    filterAttributes,
    filteredArticles,
  ]);

  useEffect(() => {
    filterArticleTypeCount();
  }, [originalfilteredArticles]);

  //filtering for article count
  useEffect(() => {
    setOriginalFilteredArticles(
      filterArticles(
        showStrongMatchArticles,
        showWeakMatchArticles,
        readFilter,
        unreadFilter,
        filterAttributes,
        originalArticles,
      ),
    );
  }, [
    showStrongMatchArticles,
    showWeakMatchArticles,
    readFilter,
    unreadFilter,
    filterAttributes,
    originalArticles,
  ]);

  const onBackPress = () => {
    removeAll()
    navigate((-1))
  }
  return (
    <div className={Styles.container}>
      {showHeader && <div className={Styles.headerBackRecent}>
        <IconButton onClick={onBackPress}>
          <img src={back_white} className={Styles.back_whiteRecent} alt="back_white" />
        </IconButton>
        <span className={Styles.headerTextRecent}>
          Recently Viewed Articles
        </span>
      </div>}
      <div
        ref={scrollRef}
        className={Styles.main}
      >
        <SectionListView
          selectedParentCategory={getSelectedParentCategory()}
          isHeader={true}
          setSelectedFilter={setSelectedFilter}
          selectedFilter={selectedFilter}
          isShowPersonalInfo={isShowPersonalInfo}
          isVisible={modalFilterVisible}
          onFilterCompleted={onFilterCompleted}
          articleType={articleFilterData}
          articleCount={articleCount}
          articleRelevacnyData={JSON.stringify(props)}
          articles={filterSection(filteredArticles2)}
          articlesLoading={(loading || loadingType)}
          refreshing={refreshing}
          onScrollBeginDrag={() => clickedFilter = false}
          articleSource={'RECENT'}
          noBtnPressDelay={true}
          isShowName={false}
          reloadScreenData={reloadScreenData}
          configData={configurationData}
          configLoading={ConfigurationLoading}
          sortState={sortState}
          setSortState={setSortState}
          showStrongMatchArticles={showStrongMatchArticles}
          setShowStrongMatchArticles={setShowStrongMatchArticles}
          showWeakMatchArticles={showWeakMatchArticles}
          setShowWeakMatchArticles={setShowWeakMatchArticles}
          filterAttributes={filterAttributes}
          setFilterAttributes={setFilterAttributes}
          readFilter={readFilter}
          setReadFilter={setReadFilter}
          unreadFilter={unreadFilter}
          setUnreadFilter={setUnreadFilter}
          attributes={attributes}
          setAttributes={setAttributes}
          setRefreshList={setRefreshList}
          setShowHeader={setShowHeader}
          verified={verified}
          setVerified={setVerified}
          partial={partial}
          setPartial={setPartial}
          needsReview={needsReview}
          setNeedsReview={setNeedsReview}
          recentArticle={filteredArticles2}
          navFrom="recentActivity"
          setArticleDetails={setArticleDetails}
          openArticleDetail={openArticleDetail}
          setOpenArticleDetail={setOpenArticleDetail}
          setPDFIsSummaryExpanded={setIsSummaryExpanded}
          setPDFSectionIndex={setSectionIndex}
          handleGeneratePDF={handleGeneratePDF}
          articleSummary={articleSummary}
          setArticleSummary={setArticleSummary}
          showArticleSummary={showArticleSummary}
          setShowArticleSummary={setShowArticleSummary}
        />
      </div>
      {/* <AlertPopup
          isVisible={alertPopupVisible}
          onContinuePress={onContinuePress}
          title={ 'There’s nothing here' }
          description={ 'As you view articles they’ll appear here so you can find them again easily.'}
          buttonText={ 'Back to My Dashboard'}
          showcloseIcon={false}
          alertIcon={alertIcon}
        /> */}
      {(loading || loadingType) && (
        <Backdrop
          sx={{ backgroundColor: "#00000000", zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: "#00000000" }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {alertPopupVisible && <AlertPopup onBackdropPress={onContinuePress} />}

      {true && openArticleDetail && openArticleDetail.status &&
        <div style={{ display: isPDFGenerate ? "block" : "none", zIndex: -100, position: "absolute" }}>
          <Article
            isPDFSummaryExpanded={isSummaryExpanded}
            sectionPFDIndex={sectionIndex}
            articleSummary={articleSummary}
            showArticleSummary={showArticleSummary}
            {...openArticleDetail.data} />
        </div>
      }
    </div>
  );
};

export default RecentViewActivity;
