import React, { useEffect, useState } from "react";
import DropdownArrow from "../../../images/svg/dropdown-arrow";
import EducationProfile from "../../../images/svg/education-profile";
import ExperienceProfile from "../../../images/svg/experience-profile";
import { objectType } from "../../../utils/utils";
import Variables from "../../../utils/Variables";
import { getTitle } from "./getTitle";
import { getTracerIcon } from "./getTracerIcon";
import LinkedInfo from "./linkedInfo";
import createStyle from "./styles";
import Styles from "./personalInfoummary.module.css";

const PersonalInfoSummary = (props) => {
  const {
    personalSummary,
    profileName,
    linkedInData,
    feedbackOptions,
    profilePic,
    setProfilePic,
    educationPDFMore,
    experiencePDFMore,
    showPDFOthers,
    showPDFMore,
  } = props;
  const [showMore, setShowMore] = useState(false);
  const [educationMore, setEducationMore] = useState(false);
  const [experienceMore, setExperienceMore] = useState(false);
  const [showOthers, setShowOthers] = useState({});
  const style = createStyle();
  const isInfoAvailable = personalSummary && Variables?.info_summary_enabled;
  const [addressLable, setAddressLable] = useState();
  const [estAge, setEstAge] = useState();
  const [title, setTitle] = useState();
  const [mobileAssoLable, setMobileAssoLable] = useState();
  const [moreInfoCount, setMoreInfoCount] = useState(0);
  const [educationCount, setEducationCount] = useState(0);
  const [experienceCount, setExperienceCount] = useState(0);

  useEffect(()=>{
    setEducationMore(educationPDFMore)
  },[educationPDFMore])
  useEffect(()=>{
    setExperienceMore(experiencePDFMore)
  },[experiencePDFMore])
  useEffect(()=>{
    if(showPDFOthers){
      setShowOthers(showPDFOthers)
    }
  },[showPDFOthers])

  useEffect(()=>{
    setShowMore(showPDFMore)
  },[showPDFMore])

  const renderSectionHeader = (key, isLast) => {
    let keyToLable = key
      .replace(/([A-Z])/g, " $1")
      ?.trim()
      ?.toLowerCase();
    if (personalSummary?.[key]?.length < 1) return;
    keyToLable = keyToLable
      .replace("current address", "potential current address")
      .replace("counties", "potential counties")
      .replace("address history", "other observed addresses")
      .replace("mobile phone numbers", "potential mobile phone numbers");
    if (keyToLable == "phone numbers") keyToLable = "potential phone numbers";
    if (
      personalSummary[key] &&
      objectType(personalSummary[key]) == "array" &&
      personalSummary[key].length <= 1
    )
      keyToLable = keyToLable
        .replace("addresses", "address")
        .replace("relatives", "relative")
        .replace("associates", "associate")
        .replace("counties", "country")
        .replace("numbers", "number");

    return (
      <div
        style={{
          ...style.moreViewCenterInner2,
          borderBottomWidth: isLast ? 0 : 1,
        }}
      >
        <div
          style={style.moreViewCenterInner}
          onClick={() =>
            setShowOthers({
              ...showOthers,
              [key]: showOthers[key] ? !showOthers[key] : true,
            })
          }
        >
          <span style={style.sectionDropInner}>
            {keyToLable}
            <span style={{ color: "#8E96A8", marginLeft: 4 }}>
              {"(" + renderSectionCount(key) + ")"}
            </span>
          </span>
          <DropdownArrow
            style={
              showOthers[key]
                ? { transform: `rotate(180deg)`, marginTop: 16, marginLeft: 8 }
                : { marginTop: 16, marginLeft: 8 }
            }
            type={"more"}
          />
        </div>
        {showOthers[key] && renderSectionView(key)}
      </div>
    );
  };

  useEffect(() => {
    let countMoreInfor = 0;
    personalSummary &&
      Object.keys(personalSummary)?.map((key, index) => {
        personalSummary?.[key] && renderSectionHeaderLable(key);
        if (personalSummary?.[key]) {
          countMoreInfor = renderSectionCount(key) + countMoreInfor;
        }
      });
    setMoreInfoCount(countMoreInfor);
    linkedInData &&
      linkedInData?.education &&
      setEducationCount(linkedInData.education.length);
    linkedInData &&
      linkedInData?.experience &&
      setExperienceCount(linkedInData.experience.length);
    linkedInData &&
      linkedInData?.experience &&
      setTitle(linkedInData?.experience?.[0]?.title);
  }, [personalSummary, linkedInData]);

  const renderStringArray = (values, key) => {
    if (values?.[0]?.length > 3) {
      return (
        <>
          {values.map((item, index) => {
            let hideLable = item;
            if (key == "emailAddresses" && item && item.includes("@")) {
              hideLable =
                item
                  .split("@")[0]
                  .split("")
                  .map((item, index) => (index < 2 ? item : "*"))
                  .toString()
                  .replace(/,/g, "") +
                "@" +
                item.split("@")[1];
            }
            return (
              <>
                <div style={style.innerTextContainer}>
                  {getTracerIcon(key)}
                  <spna style={style.sectionView}>{hideLable}</spna>
                </div>
              </>
            );
          })}
        </>
      );
    } else {
      // dedupe values for display
      const value = values
        .filter((val, id, array) => array.indexOf(val) == id)
        .join(", ")
        .toString();
      return (
        <div style={style.innerTextContainer}>
          {getTracerIcon(key)}
          <spna style={style.sectionView}>{value}</spna>
        </div>
      );
    }
  };

  const renderViewRow = (values, key1, key2, key3, index, isNewline, key) => {
    let lable = values?.[key1];
    if (
      (key == "phoneNumbers" || key == "mobilePhoneNumbers") &&
      lable &&
      lable.length >= 14
    ) {
      lable = lable.substring(0, 6) + "***-**" + lable.substring(12);
    } else if (key == "currentAddress" && lable && lable.length > 1) {
      lable =
        lable
          .split(" ")[0]
          .split("")
          .map(() => "*")
          .toString()
          .replace(/,/g, "") +
        " " +
        lable.replace(lable.split(" ")[0], "");
      lable = lable.replace(/;/g, ",");
    }
    return (
      <>
        {isNewline ? (
          <>
            <div style={style.innerTextContainer}>
              {getTracerIcon(key)}
              <span style={style.sectionView}>{(lable ?? "") + " "} </span>
              <span style={style.sectionViewNextLine}>
                {(values?.[key2] ?? "") + " - "}
                <span style={style.sectionViewNextLine}>
                  {index == 0 ? "Current" : values?.[key3] ?? ""}
                </span>
              </span>
            </div>
          </>
        ) : (
          <div style={style.innerTextContainer}>
            {getTracerIcon(key)}
            <span style={style.sectionView}>
              {(lable ?? "") + " "}
              <span style={style.sectionViewGray}>
                {(values?.[key2] ?? "") + " - "}
              </span>
              <span style={style.sectionViewGray}>
                {index == 0 ? "Current" : values?.[key3] ?? ""}
              </span>
            </span>
          </div>
        )}
      </>
    );
  };

  const customView = (title, item) => {
    if (title == "dates") return <></>;
    return (
      <>
        {title && (
          <div className={Styles.linkedInSectionView}>
            <spna className={Styles.sectionView}>{title}</spna>
            <spna className={Styles.sectionView2}>{item}</spna>
          </div>
        )}
      </>
    );
  };
  const renderSectionLinkedinView = (data, type) => {
    return (
      <div style={style.moreViewCenterBottom}>
        {data?.map((item) => {
          return (
            <div style={{ ...style.innerTextContainer, marginLeft: 16 }}>
              {type == "1" && (
                <div style={{ width: 30 }}>
                  <ExperienceProfile />
                </div>
              )}
              {type == "2" && (
                <div style={{ width: 30 }}>
                  <EducationProfile />
                </div>
              )}
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                  width: "100%",
                }}
              >
                {Object.keys(item).map((key) => {
                  return customView(getTitle(key), item[key]);
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderSectionView = (key) => {
    const values = personalSummary?.[key];
    if (objectType(values) == "array") {
      if (values && values.length && objectType(values[0]) != "object") {
        let filteredValues = values?.filter(
          (val, id, array) => array.indexOf(val) == id
        );
        return renderStringArray(filteredValues, key);
      } else if (values && values.length) {
        return (
          <>
            {key == "phoneNumbers" || key == "mobilePhoneNumbers"
              ? values.map((item, index) =>
                  renderViewRow(
                    item,
                    "phoneNumber",
                    "lastReportedDate",
                    "firstReportedDate",
                    index,
                    false,
                    key
                  )
                )
              : values.map((item, index) =>
                  renderViewRow(
                    item,
                    "county",
                    "startDate",
                    "endDate",
                    index,
                    false,
                    key
                  )
                )}
            {/* {renderShowMore(key, values)} */}
          </>
        );
      }
    } else if (objectType(values) == "object") {
      return renderViewRow(
        values,
        "fullAddress",
        "startDate",
        "endDate",
        0,
        true,
        key
      );
    } else {
      const value = key == "estimatedAge" ? values + " Years Old" : values;
      return (
        <div style={style.innerTextContainer}>
          {getTracerIcon(key)}
          <spna style={style.sectionView}>{value}</spna>
        </div>
      );
    }
  };

  const renderSectionCount = (key) => {
    const values = personalSummary?.[key];
    if (objectType(values) == "array") {
      if (values && values.length && objectType(values[0]) != "object") {
        let filteredValues = values?.filter(
          (val, id, array) => array.indexOf(val) == id
        );
        return filteredValues?.length ?? 0;
      } else if (values && values.length) {
        return values?.length ?? 0;
      } else {
        return 0;
      }
    } else if (objectType(values) == "object") {
      return 1;
    } else {
      return 1;
    }
  };

  const renderSectionHeaderLable = (key) => {
    const values = personalSummary?.[key];
    if (objectType(values) == "array") {
      if (values && values.length && objectType(values[0]) != "object") {
        if (key == "locationSummary" && addressLable == undefined) {
          let location = [...new Set(values)].join(", ");
          setAddressLable(location);
        }
      } else if (values && values.length) {
        let lable = "";
        if (key == "mobilePhoneNumbers") {
          lable = values?.[0]?.["phoneNumber"];
        } else {
          lable = values?.[0]?.["county"];
        }
        if (key == "mobilePhoneNumbers" && lable && lable.length >= 14) {
          lable = lable.substring(0, 6) + "***-**" + lable.substring(12);
        }
        if (key == "mobilePhoneNumbers" && mobileAssoLable == undefined) {
          setMobileAssoLable(lable);
        }
      }
    } else {
      if (key == "estimatedAge" && estAge == undefined) {
        setEstAge(`Est Age ${values}`);
      }
    }
  };

  return (
    <>
      <div style={style.borderContainerMain}>
        <LinkedInfo
          profile_picture_src={linkedInData?.profile_picture_src}
          linkedInURL={linkedInData?.url}
          facebookURL={linkedInData?.facebookURL}
          twiterURL={linkedInData?.twiterURL}
          estAge={estAge}
          addressLable={addressLable}
          title={title}
          mobileLable={mobileAssoLable}
          profileName={profileName}
          isInfoAvailable={isInfoAvailable}
          feedbackOptions={feedbackOptions}
          profilePic={profilePic}
          setProfilePic={setProfilePic}
        />

        <div
          style={{
            ...style.moreViewCenter,
            backgroundColor: linkedInData?.education?.length
              ? "transparent"
              : "#EFF1F5",
          }}
          onClick={() =>
            linkedInData?.education?.length
              ? setEducationMore(!educationMore)
              : null
          }
        >
          <span
            style={{
              ...style.sectionDrop,
              color: linkedInData?.education?.length ? "#142030" : "#8E96A8",
            }}
          >
            {"Education"}
            <span style={{ color: "#8E96A8", marginLeft: 4 }}>
              {"(" + educationCount + ")"}
            </span>
          </span>
          <DropdownArrow
            disable={!linkedInData?.education?.length}
            style={
              educationMore
                ? { transform: `rotate(180deg)`, marginTop: 16, marginLeft: 8 }
                : { marginTop: 16, marginLeft: 8 }
            }
            type={"more"}
          />
        </div>
        {educationMore && renderSectionLinkedinView(linkedInData?.education, 2)}

        <div
          style={{
            ...style.moreViewCenter,
            backgroundColor: linkedInData?.experience?.length
              ? "transparent"
              : "#EFF1F5",
            marginTop: linkedInData?.experience ? 0 : 1,
            borderTopWidth: educationMore ? 1 : 0,
          }}
          onClick={() =>
            linkedInData?.experience?.length
              ? setExperienceMore(!experienceMore)
              : null
          }
        >
          <span
            style={{
              ...style.sectionDrop,
              color: linkedInData?.experience?.length ? "#142030" : "#8E96A8",
            }}
          >
            {"Experience"}
            <span style={{ color: "#8E96A8", marginLeft: 4 }}>
              {"(" + experienceCount + ")"}
            </span>
          </span>
          <DropdownArrow
            disable={!linkedInData?.experience?.length}
            style={
              experienceMore
                ? { transform: `rotate(180deg)`, marginTop: 16, marginLeft: 8 }
                : { marginTop: 16, marginLeft: 8 }
            }
            type={"more"}
          />
        </div>
        {experienceMore &&
          renderSectionLinkedinView(linkedInData?.experience, 1)}

        <div
          style={{
            ...(showMore ? style.moreViewCenter : style.moreViewNew),
            backgroundColor: isInfoAvailable ? "transparent" : "#EFF1F5",
            marginTop: isInfoAvailable ? 0 : 1,
            borderTopWidth: experienceMore ? 1 : 0,
          }}
          onClick={() => (isInfoAvailable ? setShowMore(!showMore) : null)}
        >
          <span
            style={{
              ...style.sectionDrop,
              color: isInfoAvailable ? "#142030" : "#8E96A8",
            }}
          >
            {"More Info"}
            <span style={{ color: "#8E96A8", marginLeft: 4 }}>
              {"(" + moreInfoCount + ")"}
            </span>
          </span>
          <DropdownArrow
            disable={!isInfoAvailable}
            style={
              showMore
                ? { transform: `rotate(180deg)`, marginTop: 16, marginLeft: 8 }
                : { marginTop: 16, marginLeft: 8 }
            }
            type={"more"}
          />
        </div>

        {showMore && (
          <div
            style={{
              ...style.moreViewNew,
              paddingRight: 0,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {Object.keys(personalSummary)?.map((key, index) => (
              <>
                {personalSummary?.[key] &&
                  renderSectionHeader(
                    key,
                    Object.keys(personalSummary).length == index + 1
                  )}
              </>
            ))}
          </div>
        )}
      </div>
    </>
  );
};
export default PersonalInfoSummary;
