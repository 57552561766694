import React from 'react';
import { Checkbox } from '@mui/material';
import Styles from './checkFilter.module.css'

const CheckFilter = (props) => {
  const { label, status, setStatus, count = '' } = props;
  let disabled = false;

  if(count!=='' && count == 0) disabled = true

  return (
    <div className={Styles.container}>
      <span className={Styles.lableText}>{label}</span>
      <div className={Styles.containerCount}>
        <span className={Styles.countText} style={{ color: disabled ? '#AAA' : '#222F4B' }}>{count}</span>
        <Checkbox
          disabled={false}
          checked={disabled ? false : status}
          onClick={setStatus}
          className={`${Styles.checkBox}`}
          color="success"
          sx={{
            color: "#078167",
            '&.Mui-checked': {
              color: "#078167",
            },
            paddingLeft: 0
          }}
        />
      </div>
    </div>
  )
}

export default CheckFilter;
