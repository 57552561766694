import * as React from "react"
const StateOwned = (props) => {
    return (
    <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
      >
        <path
          d="M31 30h-.333a.667.667 0 0 1-.667-.667V24a.666.666 0 0 1 .667-.667H31a1 1 0 1 0 0-2H1a1 1 0 0 0 0 2h.333A.667.667 0 0 1 2 24v5.333a.667.667 0 0 1-.667.667H1a1 1 0 1 0 0 2h30a1 1 0 0 0 0-2ZM8 28.333a1 1 0 1 1-2 0V25a1 1 0 1 1 2 0v3.333Zm6 0a1 1 0 0 1-2 0V25a1 1 0 0 1 2 0v3.333Zm6 0a1 1 0 0 1-2 0V25a1 1 0 0 1 2 0v3.333Zm6 0a1 1 0 0 1-2 0V25a1 1 0 0 1 2 0v3.333ZM8.24 10h15.52a.347.347 0 0 0 .24-.147.347.347 0 0 0 0-.306 8.653 8.653 0 0 0-6.213-5.334.653.653 0 0 1-.454-.653V1.333a1.333 1.333 0 1 0-2.666 0V3.56a.654.654 0 0 1-.52.653A8.653 8.653 0 0 0 8 9.547a.347.347 0 0 0 0 .306.347.347 0 0 0 .24.147Z"
          fill={props.selected ? "#142030" :  "#b5b9c4"}
        />
        <path
          d="M5.667 13.333h1a.667.667 0 0 1 .666.667v5.333A.667.667 0 0 0 8 20h16a.667.667 0 0 0 .667-.667V14a.667.667 0 0 1 .666-.667h1a1 1 0 1 0 0-2H5.667a1 1 0 0 0 0 2ZM18 14a1 1 0 1 1 2 0v3.333a1 1 0 0 1-2 0V14Zm-6 0a1 1 0 1 1 2 0v3.333a1 1 0 0 1-2 0V14Z"
          fill={props.selected ? props.iconType =="blackWhite"? "#1E1E1E": "#FB8100" : '#b5b9c4'}
        />
      </svg>
)}

export default StateOwned
