import gql from 'graphql-tag';

const UPDATE_PASSWORD = gql`
  mutation passwordUpdate($newPassword: String!, $oldPassword: String!) {
    passwordUpdate(newPassword: $newPassword, oldPassword: $oldPassword) {
      data
    }
  }
`;

const FORGOT_PASSWORD_REQUEST_OTP = gql`
  mutation requestOtp($email: String!) {
    requestOtp(email: $email) {
      authToken
    }
  }
`;

const VERIFY_OTP = gql`
  mutation VerifyOtp($authToken: String!, $otp: String!) {
    verifyOtp(authToken: $authToken, otp: $otp) {
      authToken
      showVersionA
    }
  }
`;

const RESET_PASSWORD = gql`
  mutation resetPassword($authToken: String!, $newPassword: String!) {
    resetPassword(authToken: $authToken, newPassword: $newPassword) {
      id
      accessToken
      refreshToken
      email
    }
  }
`;

const AUTHENTICATE_LINKEDIN_USER = gql`
mutation LinkedIn($code: String!, $requestType: RequestType, $deviceDetails: DeviceDetails){
  authenticateLinkedinUser(code: $code, requestType: $requestType, deviceDetails: $deviceDetails) {
    id
    name
    lastName
    profilePhoto
    accessToken
    refreshToken
    email
    showVersionA
  }
}
`;

const IMPORT_CONTACTS = gql`
mutation ImportContacts($contacts: [Contact!]!,
  $autoUpdateSync: Boolean,
  $listID: String, $isLastBatch: Boolean,
  $batchID: Int!,
  $batchNumber: Int!,
  $totalBatches: Int!,
  $reimport: Boolean!) {
    importContacts(contacts: $contacts,
      autoUpdateSync:$autoUpdateSync,
      listID: $listID,
      isLastBatch: $isLastBatch,
      batchID: $batchID,
      batchNumber: $batchNumber,
      totalBatches: $totalBatches,
      reimport: $reimport) {
      message
      listID
    }
  }
`;

const Save_Recent_Search = gql`
  mutation saveRecentSearch($data: RecentSearch) {
    saveRecentSearch(data: $data) {
      id
      akaList
      searchTerm
      searchType
      contact
      eventType
      tahoeId
      tracerQueryId
      searchContext{
        title
        entityType
        age
        phone
        city
        state
        country
      }
      metaData {
        Person
        Company
        Location
        Place
        Nationality
      }
      irrelevantArticles
    }
  }
`;

const Update_Recent_Searches_Status = gql`
  mutation updateRecentSearchesStatus($searches: [String!]) {
    updateRecentSearchesStatus(searches: $searches) {
      data
    }
  }
`;

const Get_Recent_Search = gql`
    query getRecentSearch($limit: Int, $offset: Int) {
      getRecentSearch(limit: $limit, offset: $offset) {
        linkedinProfile {
          profile_picture_src
        }
        id
        age
        akaList
        contact
        irrelevantArticles
        searchTerm
        searchType
        eventType
    		searchCompleted
    		tracerQueryId
    		tahoeId
        searchContext{
          title
          entityType
          age
          phone
          city
          state
          country
        }
        metaData {
          Person
          Company
          Location
          Place
          Nationality
        }
      }
    }
`;
const UPDATE_CONTACT_DATA = gql`
  mutation updateContactData($contact: Contact){
    updateContactData(contact: $contact) {
      data
    }
  }
`;
const SAVE_SELF_SEARCH = gql`
  mutation saveSelfSearch($data: SelfSearch!) {
    saveSelfSearch(data: $data) {
      status
      message
    }
  }
`;

const GET_FE_TOGGLE_SETTINGS = gql`
  query getFEToggleSettings($os: String){
    getFEToggleSettings(os: $os){
      linkedinEnabled
      allActivityEnabled
      subscriptionsEnabled
      infoSummaryEnabled
      potentialMatchTagsEnabled
      savedSearchesTagsEnabled
      registrationEnabled
    }
  }
`;

const GET_POTENTIAL_MATCHES = gql`
  query getPotentialMatches($contactId: String) {
    getPotentialMatches(contactId: $contactId) {
      linkedinProfile{
            profile_picture_src
            url
          }
    Person
    Place {
      city
      state
      country
    }
    age
    Company
    TahoeId
    TracerQueryId
    MatchType
    RiskCategory
    ContactId
    countByCategory {
      count
      category
    }
    akaList
  }
  }
`;

const REFINE_SEARCH = gql`
  mutation refine_search($fullname: RefineSearchQuery!) {
    refine_search(fullname: $fullname) {
      profilePic
      feedbackOptions {
        id
        reason
        displayFields {
          fieldId
          title
          type
        }
      }
      searchIds
      currentMonthAdhocSearchCount
      result_type
      linkedinProfile {
        url
        profile_picture_src
        education {
          school
          program_type
          dates
        }
        experience {
          title
          company
          dates
          location
        }
      }
      potentialMatchId
      contactId
      verificationSummary {
        parentCategory
        needsReviewCount
        partialCount
        verifiedCount
      }
      categoryFilterData {
        People
        Organizations
        Places
        Parties
        Attorneys
      }
      readArticles {
        articleId
      }
      data {
        QA_data {
          question
          attribute
          options {
            item
            type
          }
        }
        question
        options {
          item
          type
        }
        potentialMatches {
          linkedinProfile{
            profile_picture_src
            url
          }
          Person
          akaList
          Place {
            city
            state
            country
          }
          countByCategory {
            category
            count
            verified
            partial
            needs_review
            score
          }
          age
          phone
          Company
          ContactId
          MatchType
          RiskCategory
          TracerQueryId
          TahoeId
        }
        attribute
        meta_data
        display {
          Person
          Nationality
          Place
          Company
          Location
        }
      }
      akaList
      filterData {
        category
        riskCodes
        itemTypes
        count
        verified
        partial
        needs_review
      }
      articleTypeCount {
        highRisk
        news
        alerts
      }
      article_feed {
        matchStrength
        matchType
        riskRanking
        category
        article {
          articleId
          title
          backupTitle
          itemType
          riskCode
          url
          imageURL
          source
          details {
            key
            value
          }
          attributes {
            key
            value
          }
        }
      }
      summary {
        totalArticles
        countByRiskRanking {
          highRisk
          news
          alerts
        }
        emptyCategories {
          category
          count
          itemTypes
          riskCodes
          score
        }
        dataCategories {
          category
          count
          itemTypes
          riskCodes
          verified
          partial
          needs_review
          score
        }
        personalSummary {
          estimatedAge
          locationSummary
          currentAddress {
            county
            fullAddress
            startDate
            endDate
          }
          otherObservedNames
          emailAddresses
          possibleRelatives
          possibleAssociates
          counties {
            county
            startDate
            endDate
          }
          addressHistory {
            county
            startDate
            fullAddress
            endDate
          }
          phoneNumbers {
            phoneNumber
            lastReportedDate
            firstReportedDate
          }
          mobilePhoneNumbers {
            phoneNumber
            lastReportedDate
            firstReportedDate
          }
        }
      }
      article_details {
        sourceIdentifier
        verifiedBy
        caseId
        matchingLogic
        verificationMethod
        verificationType
        matchedAttributes{
          key
          value
        }
        subCategory {
          title
          document {
            title
            docketSubCategory
            details {
              key
              value
            }
          }
        }
        relevanceInfo {
          peopleOthers
          companyOthers
          placesOthers
          peopleKnow
          companyKnow
          placesKnow
          relevanceAttributes {
            key
            value
          }
        }
        date
        parentCategory
        riskRanking
        articleId
        matchType
        matchStrength
        itemType
        riskCode
        articleType
        title
        backupTitle
        url
        fullName
        highlightKey
        summary
        details {
          key
          value
        }
        attributes {
          key
          value
        }
        tracers
        tracerData {
          header
          body
        }
      }
    }
  }
`;

const GET_CONFIGURATION = gql`
  query getArbConfiguration{
    getArbConfiguration{
      itemType
      parb
      prearb
    }
  }
`;

const ME = gql`
  query me {
    me {
      id
      name
      lastName
      profilePhoto
      isOnboarded
      userConsentDeviceContacts
      userConsentGoogleContacts
      notificationOnboardingDone
      newsOnboardingDone
      neverShowNotifications
      advancedSearchOnboardingDone
      userPreference
      email
      userType
      syncToken
      hasContacts
      suscriptionPlan
      currentServerTimeMs
      relevancySortFilterTooltip
      filterTooltips
      recentFilterTooltips
      contactImportFlagActive
      adhocSortFilterTooltip
      monitoredContactCount
      subscriptionDetails {
        platform
        willAutoRenew
        isCancelled
        paymentMethods
        currentMonthAdhocSearchCount
        articleCount
        subscriptionId
        isInIntroPeriod
        isInTrialPeriod
        isSubscribed
        hasLimitedAccess
        itemId
        itemName
        hasExpired
        daysLeftToExpire
        expirationDateMs
        trialEndDate
        subscriptionPlanDbId{
          id
          title
          adhocSearchLimitPerMonth
          description
          monthlyPrice
          yearlyPrice
          actualMonthlyPrice
          actualYearlyPrice
          discount
          discountPercentage
          periodicity
          productId
          benefits
        }
      }
      config {
        subscriptionBaseUrl
        authBaseUrl
        contactsBaseUrl
        activityBaseUrl
        searchBaseUrl
        notificationBaseUrl
        contactsHandlerBaseUrl
      }
      selfSearchData{
        name
        lastName
        company
        city
        region
        country
      }
      disclaimerData {
        isDisclaimerAccepted
      }
      upgradeSubsriptionPlan {
        productId
        actualYearlyPrice
        actualMonthlyPrice
        monthlyPrice
        yearlyPrice
      }
    }
  }
`;

const CONTACTS_SUMMARY = gql`
  query addressBook {
    addressBook {
      summary {
        total
        provider
        updated
      }
      listID
    }
  }
`;

const GET_NYLAS_AUTH_TOKEN = gql`
  query getNylasAuthToken($email: String!, $password:String!){
    getNylasAuthToken(email: $email, password:$password) {
      status
      message
      data {
        access_token
        account_id
        email_address
        provider
        token_type
      }
    }
  }
`;

const AUTHENTICATE_GOOGLE_USER = gql`
mutation Google($accessToken: String!, $requestType: RequestType, $deviceDetails: DeviceDetails){
  authenticateGoogleUser(accessToken: $accessToken, requestType: $requestType, deviceDetails: $deviceDetails) {
    id
    accessToken
    refreshToken
    name
    lastName
    profilePhoto
    email
    showVersionA
    isNewUser
    userType
    advancedSearchOnboardingDone
  }
}
`;

const FEED = gql`
query searchFeed(
  $skip: Int
  $orderedBy: String
  $chunkSize: Int
  $articleType: [ArticleType]
  $itemType: [String]
  $riskCode: [String]
) {
  searchFeed(
    skip: $skip
    orderedBy: $orderedBy
    chunkSize: $chunkSize
    showTotalArticles: true
    articleType: $articleType
    itemType: $itemType
    riskCode: $riskCode
  ) {
    articles {
      contactID
      id
      parentCategory
      createdAt
      itemtype
      fullName
      matchType
      title
      riskCategory
      source
      terms
      category
      attributes {
        key
        value
      }
      pageURL
      imageURL
      riskCode
      timestamp
      articleId
      isRelevant
      relevanceInfo{
        peopleOthers
        companyOthers
        placesOthers
        peopleKnow
        companyKnow
        placesKnow
        relevanceAttributes{
            key
            value
        }
      }
      article {
        title
        pageURL
        imageURL
        source
        timestamp
        summary
        details {
          key
          value
        }
      }
    }
    articleCount {
      highRisk
      news
      alerts
    }
  }
}
`;

const SAVE_ARTICLE_RELEVANCY = gql`
mutation saveArticleRelevancy(
  $contactId: String
  $articleId: String!
  $isRelevant: ResponseType
  $relevanceInfo: RelevanceValuesInput
  $searchTerm: String!
  $articleCategory: String
  $articleSource: ArticleSource!
  $refId: String
  $recentSearchId:String
  $contactData:AdhocSearch
  $potentialMatchId:String
) {
  saveArticleRelevancy(
    articleRelevancy: {
      searchTerm: $searchTerm
      articleSource: $articleSource
      refId: $refId
      contactId: $contactId
      articleId: $articleId
      isRelevant: $isRelevant
      relevanceInfo: $relevanceInfo
      recentSearchId: $recentSearchId
      contactData: $contactData
      articleCategory: $articleCategory
      potentialMatchId: $potentialMatchId
    }
  ) {
    data
    contactId
  }
}
`;

const AUTHENTICATE_APPLE_USER = gql`
mutation authenticateAppleUser($code: String!, $email: String, $name: String, $lastName: String!,$requestType: RequestType, $deviceDetails: DeviceDetails){
  authenticateAppleUser(code: $code, email: $email, name: $name, lastName: $lastName, requestType: $requestType, deviceDetails: $deviceDetails) {
    id
    accessToken
    refreshToken
    name
    lastName
    profilePhoto
    email
    showVersionA
    isNewUser
    userType
    advancedSearchOnboardingDone
  }
}
`;

const AUTHENTICATE_FERRET_USER = gql`
  mutation signUpFerretUser(
    $email: String!
    $password: String!
    $lastName: String
    $name: String
    $dateOfBirth: String
    $phoneNumber: String
    $address: Address
    $deviceDetails: DeviceDetails
  ) {
    signUpEmail(
      email: $email
      password: $password
      lastName: $lastName
      name: $name
      dateOfBirth: $dateOfBirth
      phoneNumber: $phoneNumber
      address: $address
      deviceDetails: $deviceDetails
    ) {
      id
      accessToken
      refreshToken
      name
      lastName
      profilePhoto
      email
    }
  }
`;

const AUTHENTICATE_FERRET_EMAIL = gql`
mutation verifyEmailOtp(
  $id: ID!
  $code: String!
) {

verifyEmail (
id : $id
code : $code
){
id
email
name
lastName
profilePhoto
accessToken
refreshToken
showVersionA
}
}
`;

const SIGN_IN_FERRET_USER = gql`
mutation signInFerretUser($email: String!, $password: String!, $deviceDetails: DeviceDetails){
  signInEmail(email: $email, password: $password, deviceDetails: $deviceDetails) {
      id
      accessToken
      refreshToken
      name
      lastName
      profilePhoto
      email
      advancedSearchOnboardingDone
    }
  }
`;


const GET_SHARED_CONTACTS = gql`
  query getSharedContacts{
    getSharedContacts{
        id
        name
        lastName
        photo
        provider
        idProvider
        isPreferred
        riskCategory
        company
        jobTitle
        phoneNumbers{
          number
        }
        emailAddresses{
          email
        }
        postalAddresses {
          city
          region
          state
          country
        }
      }
    }
`;

const GET_FERRET_CONTACT_LIST = gql`
  query getFerretContactList(
    $pageSize: Int
    $cursor: Int
    $showSavedSearches: Boolean
    $emailFilter: Boolean
  ) {
    contactList(
      pageSize: $pageSize
      cursor: $cursor
      showSavedSearches: $showSavedSearches
      emailFilter: $emailFilter
    ) {
      contacts {
        linkedinProfile{
          profile_picture_src
          url
        }
        id
        recentSearchId
        age
        akaList
        name
        lastName
        photo
        provider
        idProvider
        isPreferred
        hasTracerPotentialMatches
        multiplePotentialMatchesFound
        riskCategory
        company
        jobTitle
        searchType
        isProcessed
        searchContext{
          title
          entityType
          age
          phone
          originalPhone
          city
          state
          stateAbbr
          country
          countryAbbr
        }
        tahoeId
        tracerQueryId
        phoneNumbers{
          number
        }
        emailAddresses{
          email
        }
        postalAddresses {
          city
          region
          state
          country
        }
        summary{
          news{title,total}
          debts{title,total}
          watchlists{title,total}
          crunchbase{title,total}
          positiveNews{title,total}
          courtRecords{title,total}
          papersAndLeaks{title,total}
          criminalRecords{title,total}
          corporateRecords{title,total}
          politicalExposure{title,total}
          stateOwnedEnterprise{title,total}
          courtFilings {title, total}
          assets {title, total}
          webRecords {title,total}
        }
        metaData{
          Location
          Place
          Company
          Nationality
          Person
        }
      }
      categories {
        title
        score
      }
      pageInfo {
        cursor
        hasMore
        pageSize
      }
    }
  }
`;

const UPDATE_FERRET_USER = gql`
  mutation updateFerretUser(
    $name: String
    $password: String
    $lastName: String
    $email: String
    $base64String: String
    $isOnboarded: Boolean
    $userConsentDeviceContacts:Boolean
    $userConsentGoogleContacts:Boolean
    $notificationOnboardingDone: Boolean
    $newsOnboardingDone:Boolean
    $neverShowNotifications:Boolean
    $advancedSearchOnboardingDone:Boolean
    $userPreference: UserPreferenceEnum
    $professionalPreferences: String
    $personalPreferences: [String]
    $syncToken: String
    $relevancySortFilterTooltip: Boolean
    $adhocSortFilterTooltip: Boolean
    $filterTooltips: Boolean
    $recentFilterTooltips: Boolean
  ) {
    updateUser(
      name: $name
      password: $password
      lastName: $lastName
      email: $email
      base64String: $base64String
      isOnboarded: $isOnboarded
      userConsentDeviceContacts:$userConsentDeviceContacts
      userConsentGoogleContacts:$userConsentGoogleContacts
      notificationOnboardingDone: $notificationOnboardingDone
      newsOnboardingDone:$newsOnboardingDone
      neverShowNotifications:$neverShowNotifications
      advancedSearchOnboardingDone:$advancedSearchOnboardingDone
      userPreference: $userPreference
      professionalPreferences: $professionalPreferences
      personalPreferences: $personalPreferences
      syncToken: $syncToken
      relevancySortFilterTooltip:$relevancySortFilterTooltip
      adhocSortFilterTooltip:$adhocSortFilterTooltip
      filterTooltips: $filterTooltips
      recentFilterTooltips: $recentFilterTooltips
    ) {
      id
      name
      lastName
      profilePhoto
      isOnboarded
      notificationOnboardingDone
      userConsentDeviceContacts
      userConsentGoogleContacts
      newsOnboardingDone
      neverShowNotifications
      advancedSearchOnboardingDone
      userPreference
      email
      userType
      suscriptionPlan
      currentServerTimeMs
      syncToken
      relevancySortFilterTooltip
      adhocSortFilterTooltip
      filterTooltips
      recentFilterTooltips
      subscriptionDetails {
        subscriptionId
        isInIntroPeriod
        isInTrialPeriod
        willAutoRenew
        isSubscribed
        hasLimitedAccess
        itemId
        itemName
        hasExpired
        expirationDateMs
        trialEndDate
      }
      disclaimerData {
        isDisclaimerAccepted
      }
    }
  }
`;

const INVITE_CONTACTS = gql`
  mutation inviteContactsToFerret($contacts: [Contact!]!) {
    inviteContacts(contacts: $contacts) {
      data
    }
  }
`;

const GET_ARTICLE_BY_ID = gql`
  query getArticleById($id: String) {
    article(id: $id) {
      attributes {
        key
        value
      }
      title
      pageURL
      imageURL
      source
      timestamp
    }
  }
`;

const GET_QUERIES = gql`
  query queries {
    queries {
      id
      name
      lastName
      riskCategory
      isPreferred
    }
  }
`;

const RECENT_ACTIVITY_SUBSCRIPTION = gql`
subscription syncProgress{
  syncProgress{
    type
    user
    status
    batchNumber
    totalBatches
    totalArticles
    articlesFoundFor
    contactsReceived
    articlesCount
    contactId
		riskFactor
		riskCategory
  }
}
`;

const GET_FULLTEXT = gql`
  query getFulltext($articleId: String, $dataSource: DataSource) {
    getFulltxt(articleId: $articleId, dataSource: $dataSource) {
      data
    }
  }
`;
const GET_ARTICLE_TYPE = gql`
  query getArticleType($riskRanking:[String!]!) {
    articleType(riskRanking:$riskRanking) {
      category
      itemTypes
      riskCodes
      count
    }
  }
`;

const SAVE_PURCHASE = gql`
  mutation savePurchase($details: PurchaseDetails) {
    savePurchase(details: $details) {
      data
    }
  }
`;

const SAVE_ADHOC_SEARCH = gql`
  mutation saveAdhocSearch($data: AdhocSearch!) {
    saveAdhocSearch(data: $data) {
      data
      contactId
    }
  }
`;

const SET_PREFERENCE = gql`
  mutation setPreference($contacts: [ContactPreferredData!]!) {
    setPreference(contacts: $contacts) {
      message
    }
  }
`;

const UPDATE_DEVICE_TOKEN = gql`
  mutation updateDeviceToken($deviceDetails: DeviceDetails){
    updateDeviceToken(deviceDetails: $deviceDetails){
      status
    }
  }
`;

const GET_NOTIFICATION = gql`
  query getNotifications($limit: Int, $offset: Int, $showRecent: Boolean, $showRead: Boolean){
    getNotifications(limit: $limit,offset: $offset, showRecent: $showRecent, showRead: $showRead){
      id
      osArticleId
      title
      body
      category
      type
      isRead
      contactName
      contactImage
      articleId
      createdAt,
      contactId
      recentSearchId
    }
  }
`;

const DELETE_A_CONTACT = gql`
  mutation deleteAContact($user: String!, $contactId: String!){
    deleteAContact(user: $user, contactId: $contactId){
      data
    }
  }
`;

const READ_NNOTIFICATION = gql`
  mutation readNotification($notificationId: [String!]!){
    readNotification(notificationId: $notificationId){
      data
    }
  }
`;

const GET_SAVED_SEARCHES = gql`
  query getSavedSearches(
    $pageSize: Int
    $cursor: Int
    $showPreferred: Boolean
  ) {
    getSavedSearches(
      pageSize: $pageSize
      cursor: $cursor
      showPreferred: $showPreferred
    ) {
      totalContacts
      contacts {
        id
        linkedinProfile{
          profile_picture_src
          url
        }
        name
        lastName
        provider
        photo
        idProvider
        isProcessed
        multiplePotentialMatchesFound
        isPreferred
        riskCategory
        company
        jobTitle
        tahoeId
        tracerQueryId
        age
        akaList
        searchContext{
          title
          entityType
          age
          phone
          city
          state
          country
        }
        summary{
          news{title,total}
          debts{title,total}
          watchlists{title,total}
          crunchbase{title,total}
          positiveNews{title,total}
          courtRecords{title,total}
          papersAndLeaks{title,total}
          criminalRecords{title,total}
          corporateRecords{title,total}
          politicalExposure{title,total}
          stateOwnedEnterprise{title,total}
          courtFilings{title, total}
          assets {title, total}
          webRecords{title,total}
        }
        postalAddresses {
          city
          region
          state
          country
        }
        metaData{
          Location
          Place
          Company
          Nationality
          Person
        }
      }
      categories {
        title
        score
      }
      pageInfo {
        cursor
        hasMore
        pageSize
      }
    }
  }
`;

const GET_SAVED_SEARCHES_ARTICLES = gql`
  query getSavedSearchArticles(
    $skip: Int
    $orderedBy: String
    $chunkSize: Int
    $articleType: [ArticleType]
    $itemType: [String]
    $riskCode: [String]
    $source: String
    $contacts: [String!]!
  ) {
    getSavedSearchArticles(
      skip: $skip
      orderedBy: $orderedBy
      chunkSize: $chunkSize
      showTotalArticles: true
      articleType: $articleType
      itemType: $itemType
      riskCode: $riskCode
      source: $source
      contacts: $contacts
    ) {
      articles {
        contactID
        id
        parentCategory
        createdAt
        itemtype
        fullName
        matchType
        title
        riskCategory
        source
        terms
        category
        attributes {
          key
          value
        }
        pageURL
        imageURL
        riskCode
        timestamp
        articleId
        isRelevant
        relevanceInfo{
        peopleOthers
        companyOthers
        placesOthers
        peopleKnow
        companyKnow
        placesKnow
        relevanceAttributes{
            key
            value
        }
      }
      tracers
        tracerData {
          header
          body
        }
        article {
          title
          pageURL
          imageURL
          source
          timestamp
          summary
          details {
            key
            value
          }
        }
      }
      articleCount {
        highRisk
        news
        alerts
      }
    }
  }
`;

const GET_SAVED_SEARCH_ARTICLE_TYPE = gql`
  query savedSearchArticleType($contacts: [String!]!, $source: String, $riskRanking:[String!]!) {
    savedSearchArticleType(contacts: $contacts, source: $source, riskRanking:$riskRanking) {
      category
      itemTypes
      riskCodes
      count
    }
  }
`;

const GET_NOTIFICATION_DETAILS = gql`
  query getNotificationDetails($articleId: String, $contactId: String){
    getNotificationDetails(articleId: $articleId, contactId: $contactId){
      id
      createdAt
      itemtype
      fullName
      matchType
      title
      riskCategory
      source
      contactID
      terms
      category
      attributes {
        key
        value
      }
      relevanceInfo{
        peopleOthers
        companyOthers
        placesOthers
        peopleKnow
        companyKnow
        placesKnow
        relevanceAttributes{
            key
            value
        }
      }
      pageURL
      imageURL
      riskCode
      timestamp
      articleId
      article {
        title
        pageURL
        imageURL
        source
        timestamp
        summary
        details {
          key
          value
        }
      }
    }
  }
`;

const GET_ARTICLE_HISTORY = gql`
query getArticleHistory(
  $skip: Int
  $orderedBy: String
  $chunkSize: Int
  $articleType: [ArticleType]
  $itemType: [String]
  $riskCode: [String]
) {
  getArticleHistory(
    skip: $skip
    orderedBy: $orderedBy
    chunkSize: $chunkSize
    showTotalArticles: true
    articleType: $articleType
    itemType: $itemType
    riskCode: $riskCode
  ) {
    categoryFilterData {
      People
      Organizations
      Places
      Parties
      Attorneys
    }
    articles {
      sourceIdentifier
      verifiedBy
      matchingLogic
      updatedAt
      contactID
      id
      parentCategory
      createdAt
      itemtype
      fullName
      matchType
      title
      riskCategory
      source
      terms
      category
      tracers
      tracerData {
        header
        body
      }
      attributes {
        key
        value
      }
      verificationType
      verificationMethod
      matchedAttributes{
          key
          value
        }
      subCategory {
        title
        document {
          title
          docketSubCategory
          details {
            key
            value
          }
        }
      }  
      pageURL
      imageURL
      riskCode
      timestamp
      articleId
      isRelevant
      potentialMatchId
      relevanceInfo{
        peopleOthers
        companyOthers
        placesOthers
        peopleKnow
        companyKnow
        placesKnow
        relevanceAttributes{
            key
            value
        }
      }
      article {
        title
        pageURL
        imageURL
        source
        timestamp
        summary
        details {
          key
          value
        }
      }
    }
    articleCount {
      highRisk
      news
      alerts
    }
  }
}
`;

const SAVE_ARTICLE_HISTORY = gql`
  mutation saveArticleHistory($data: HistoryInput){
    saveArticleHistory(data: $data){
      data
    }
  }
`;


const GET_HISTORY_ARTICLE_TYPE = gql`
  query getHistoryArticleType($riskRanking:[String!]!) {
    getHistoryArticleType(riskRanking:$riskRanking) {
      category
      itemTypes
      riskCodes
      count
    }
  }
`;

const GET_CONTACT_SYNC_PROGRESS = gql`
  query getContactSyncProgress{
    getContactSyncProgress{
      batchID
      batchNumbersReceived
      batchesReceived
      totalBatches
      contactsHavingArticles
      contactsReceived
      totalArticles
      reimport
    }
  }
`;

const REFRESH_SESSION = gql`
  mutation refreshSession($refreshToken: String!){
    refreshSession(refreshToken:$refreshToken){
      refreshToken
      accessToken
    }
  }
`;

const IS_VALID_URL = gql`
  query isValidUrl(
    $url: String, 
    $title: String
  ) {
    isValidUrl(
      url: $url, 
      title: $title
    ) {
      valid
      status
      statusText
      titleMatchScore
      errorMessage
      contentType
      httpsEnabled
    }
  }
`;

const DELETE_USER_ACCOUNT = gql`
  mutation DeleteUser{
    deleteUser {
      status
      message
    }
  }
`;

const GET_SUBSCRIPTION_PLAN = gql`
  query {
    getSubsriptionPlan{
      id
      title
      description
      monthlyPrice
      yearlyPrice
      actualMonthlyPrice
      actualYearlyPrice
      discount
      discountPercentage
      periodicity
      freeTrialPeriodInDays
      productId
      benefits
      monitorContactLimitPerMonth
      adhocSearchLimitPerMonth
      score
      stripePriceId
    }
  }
`;

const UPDATE_USER_DISCLAIMER = gql`
  mutation UpdateUserDisclaimer($data: UserDisclaimerInput) {
    updateUserDisclaimer(data: $data) {
      status
      message
    }
  }
`;

const UPDATE_CONTACT_IMPORT_FLAG = gql`
  mutation UpdateContactImportFlag($contactImportFlagActive: Boolean) {
    updateContactImportFlag(contactImportFlagActive: $contactImportFlagActive) {
      contactImportFlagActive
    }
  }
`;

const CREATE_STRIPE_SUBSCRIPTION = gql`
  mutation createStripeSubscription($priceId: String!, $paymentMethodId: String){
    createStripeSubscription(priceId: $priceId, paymentMethodId: $paymentMethodId){
      subscriptionId
      clientSecret
      applyTrialPeriod
    }
  }
`

const UPDATE_STRIPE_SUBSCRIPTION = gql`
  mutation updateStripeSubscription(
    $subscriptionId: String!,
    $priceId: String!
  ){
    updateStripeSubscription(subscriptionId: $subscriptionId, priceId: $priceId){
      data
    }
  }
`

const CANCEL_STRIPE_SUBSCRIPTION = gql`
  mutation cancelStripeSubscription($subscriptionId: String!){
    cancelStripeSubscription(subscriptionId: $subscriptionId){
    data
    }
  }
`

const SETUP_PAYMENT_INTENT = gql`
  mutation setupPaymentIntent{
    setupPaymentIntent{
    clientSecret
      customerId
    }
  }
`

const CHECK_META_SEARCH_DATA = gql`
  query checkMetaSearchData($searchIds: [String]) {
    checkMetaSearchData(searchIds: $searchIds) {
      hasData
      refresh
      source
    }
  }
`;

const GET_RECENT_SEARCH_BY_ID = gql`
    query getRecentSearchById($recentSearchId: String) {
      getRecentSearchById(recentSearchId: $recentSearchId) {
        id
        akaList
        contact
        irrelevantArticles
        searchTerm
        searchType
        eventType
    		searchCompleted
    		tracerQueryId
    		tahoeId
        searchContext{
          title
          entityType
          age
          phone
          city
          state
          country
        }
        metaData {
          Person
          Company
          Location
          Place
          Nationality
        }
      }
    }
`;

const SUBMIT_FEEDBACK_RESPONSE = gql`
  mutation SubmitFeedbackResponse($data: FeedbackResponseInput) {
    submitFeedbackResponse(data: $data) {
      status
      imageUrl
    }
  }
`;

const USER_UPDATES = gql`
  subscription userUpdates{
    userUpdates{
      type
      user
      linkedinProfile
      potentialMatchId
    }
  }
`
const GENERATE_SUMMARY = gql`
  query GenerateSummary($uuid: String, $type: SummaryType) {
    generateSummary(uuid: $uuid, type: $type) {
      summary
      status
    }
  }
`
const GET_FAVICON = gql`
  query getFavIcon($iconURL: String!){
    getFavIcon(iconURL:$iconURL){
      status
      icon
    }
  }
`

const GET_UNICOURT_CASE = gql`
  query getUnicourtCase($caseId: String!) {
    getUnicourtCase(caseId: $caseId) {
      name
      url
    }
  }
`

export {
  UPDATE_PASSWORD,
  FORGOT_PASSWORD_REQUEST_OTP,
  RESET_PASSWORD,
  VERIFY_OTP,
  AUTHENTICATE_LINKEDIN_USER,
  Save_Recent_Search,
  Get_Recent_Search,
  Update_Recent_Searches_Status,
  IMPORT_CONTACTS,
  ME,
  CONTACTS_SUMMARY,
  AUTHENTICATE_GOOGLE_USER,
  FEED,
  SAVE_ARTICLE_RELEVANCY,
  AUTHENTICATE_APPLE_USER,
  AUTHENTICATE_FERRET_USER,
  AUTHENTICATE_FERRET_EMAIL,
  SIGN_IN_FERRET_USER,
  GET_FERRET_CONTACT_LIST,
  UPDATE_FERRET_USER,
  INVITE_CONTACTS,
  GET_ARTICLE_BY_ID,
  GET_QUERIES,
  GET_CONFIGURATION,
  RECENT_ACTIVITY_SUBSCRIPTION,
  GET_FULLTEXT,
  REFINE_SEARCH,
  GET_POTENTIAL_MATCHES,
  GET_ARTICLE_TYPE,
  SAVE_ADHOC_SEARCH,
  SAVE_PURCHASE,
  SET_PREFERENCE,
  DELETE_A_CONTACT,
  SAVE_SELF_SEARCH,
  UPDATE_DEVICE_TOKEN,
  GET_NOTIFICATION,
  READ_NNOTIFICATION,
  GET_SAVED_SEARCHES,
  GET_SAVED_SEARCHES_ARTICLES,
  GET_SAVED_SEARCH_ARTICLE_TYPE,
  GET_NOTIFICATION_DETAILS,
  SAVE_ARTICLE_HISTORY,
  GET_ARTICLE_HISTORY,
  GET_HISTORY_ARTICLE_TYPE,
  GET_CONTACT_SYNC_PROGRESS,
  REFRESH_SESSION,
  GET_SHARED_CONTACTS,
  GET_FE_TOGGLE_SETTINGS,
  IS_VALID_URL,
  DELETE_USER_ACCOUNT,
  GET_SUBSCRIPTION_PLAN,
  UPDATE_CONTACT_DATA,
  UPDATE_USER_DISCLAIMER,
  UPDATE_CONTACT_IMPORT_FLAG,
  GET_NYLAS_AUTH_TOKEN,
  CREATE_STRIPE_SUBSCRIPTION,
  UPDATE_STRIPE_SUBSCRIPTION,
  CANCEL_STRIPE_SUBSCRIPTION,
  SETUP_PAYMENT_INTENT,
  CHECK_META_SEARCH_DATA,
  GET_RECENT_SEARCH_BY_ID,
  SUBMIT_FEEDBACK_RESPONSE,
  USER_UPDATES,
  GENERATE_SUMMARY,
  GET_FAVICON,
  GET_UNICOURT_CASE
};
