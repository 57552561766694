import * as React from "react"

const ChevronUpIcon = (props) => (
  <svg
    width={18}
    height={10}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.571 8.6 8.6 1.573a.549.549 0 0 1 .8 0l7.029 7.029"
      stroke="#91CB6C"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ChevronUpIcon
