
const createStyle = (riskCategory, details, zoomSize = 0, isTracers = false) => {
  const titleTextColor = '#142030';
  const riskColors = {
    high: '#DA011B',
    medium: '#FB8100',
    low: '#0F9125',
    HIGH: '#FF3D3D',
    MEDIUM: '#FFAD57',
    LOW: '#56DC6D',
    null: '#56DC6D',
  }

  return {
    titleContainer: {
      display: "flex",
      flexDirection: 'row',
      marginTop: 4,
      marginBottom: 10,
    },
    titleDecorator: {
      width: 4,
      backgroundColor: riskColors.riskCategory,
    },
    title: {
      color: titleTextColor,
      fontFamily: 'Proxima Nova',
      fontSize: 24,
      fontWeight: '700',
    },
    summary: {
      color: titleTextColor,
      fontFamily: 'Proxima Nova',
      fontSize: isTracers ? 16 : 14,
      lineHeight: 1,
      fontWeight: 400,
      // lineClamp: 3,
      whiteSpace: "break-spaces"
    },
    summaryDocument: {
      fontWeight: '400',
      color: titleTextColor,
      fontFamily: 'Proxima Nova',
      fontSize: 14 + zoomSize,
    },
    summary2: {
      marningLeft: 20,
      marningRight: 20,
      position: 'absolute',
      fontFamily: 'Proxima Nova',
      fontSize: isTracers ? 16 + zoomSize : 14 + zoomSize,
      color: 'transparent',
      visibility: "hidden",
      fontWeight: 400,
      lineHeight: 1,
      lineClamp: 3,
      whiteSpace: "break-spaces"
    },
    summaryHighlight: {
      color: 'black',
      backgroundColor: '#FFFF03',
      fontFamily: 'Proxima Nova',
      fontWeight: 'bold',
      fontSize: 18 + zoomSize,
    },
    detailContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 10,
    },
    detailLabel: {
      color: titleTextColor,
      fontFamily: 'Proxima Nova',
      fontSize: 14 + zoomSize,
      fontWeight: 'bold',
    },
    detailValue: {
      color: titleTextColor,
      fontFamily: 'Proxima Nova',
      fontSize: 14 + zoomSize,
    },
    showButton: {
      color: '#FFF',
      fontSize: 14 + zoomSize,
      fontWeight: '600',
      textTransform: 'uppercase',
    },
    viewButton: {
      backgroundColor: '#078167',
      paddingVertical: 4,
      paddingHorizontal: 10,
      borderRadius: 6,
      marginBottom: details ? 20 : 10,
    },
    buttonNotifi: {
      marginTop: 10,
      marginBottom: 10,
      color: '#91CB6C',
      fontFamily: "Proxima Nova",
      fontSize: 14 + zoomSize,
      fontWeight: '600',
    },
    cacheBtnIcon: {
      alignSelf: "center",
      marginLeft: 10,
      marginBottom: 1
    },
    touchView: {
      display: "flex",
      flexDirection: 'row',
      marginTop: 5,
      marginBottom: 10,
      justifyContent: "start",
      width: "100%",
      position: "-webkit-sticky",
      position: "sticky",
      backgroundColor: "white",
      bottom: 0,
      paddingTop: 8,
      paddingBottom: 8,
    },
    aboutMainView: {
      display: "flex",
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 16,
      justifyContent: 'flex-end'
    },
    aboutYour: {
      color: titleTextColor,
      fontWeight: "700",
      fontSize: 14,
      fontFamily: 'Proxima Nova',
      marginLeft: 8
    },
    btnView: {
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 6,
      padding: 8,
      display: 'flex',
      backgroundColor: '#EFF1F5',
      marginLeft: 10
    },
    btnText: {
      color: '#142030',
      fontWeight: "500",
      fontSize: 14,
      fontFamily: 'Proxima Nova',
    },
  }
};

export default createStyle;
