import React, { useContext, useEffect } from "react";
import moment from "moment-timezone";
import { userInfo } from "../../root-navigation";
import { GreenBgButton } from "../buttons/greenBgButton";
import StylesMobile from "./subscriptionPreview.module.css";
import StylesWeb from "./subscriptionPreviewWeb.module.css";
import { ButtonBase } from "@mui/material";
import AmericanExpress from "../../images/svg/americanExpress";
import { UserContext } from "../../contexts/userContext";

const SubscriptionPreview = ({
  isDesktop,
  freePlan,
  currentPlan,
  newPlan,
  isDownGradingPlan,
  handleBuyButtonPress,
}) => {

  const { user } = useContext(UserContext);

  const Styles = isDesktop ? StylesWeb : StylesMobile

  let oldPlan = currentPlan ?? freePlan[0];

  let newPlanPrice =
    newPlan.periodicity === "MONTHLY"
      ? `${newPlan.monthlyPrice.toFixed(2)}/mo`
      : `${newPlan.yearlyPrice.toFixed(2)}/yr`;

  let newPlanPeriodicity =
    newPlan.periodicity === "MONTHLY"
      ? newPlan?.periodicity.toLowerCase()
      : "annual";

  let oldPlanPeriodicity =
    currentPlan && currentPlan.periodicity === "MONTHLY"
      ? currentPlan?.periodicity.toLowerCase()
      : "annual";

  const dateFormat = "MM/DD/YY";

  const expirePlanDate = user.isInTrialPeriod
    ? moment(parseInt(user.trialEndDate)).format(dateFormat)
    : moment(parseInt(user.expirationDateMs)).format(dateFormat)

  const todayDate = user.isInTrialPeriod ? moment(parseInt(user.trialEndDate)).format(dateFormat) : moment(new Date()).format(dateFormat);

  const newPlanDate = moment((isDownGradingPlan) ? expirePlanDate : todayDate, dateFormat)
    .add(1, "days")
    .add(1, (!isDownGradingPlan && oldPlanPeriodicity == "monthly" && newPlanPeriodicity == "monthly") ? "month" : "")
    .add(1, (!isDownGradingPlan && oldPlanPeriodicity == "annual" && newPlanPeriodicity == "annual") ? "year" : "")
    .add(1, (!isDownGradingPlan && oldPlanPeriodicity == "monthly" && newPlanPeriodicity == "annual") ? "year" : "")
    .format(dateFormat);

  let startPlanDate = isDownGradingPlan ? newPlanDate : todayDate;

  return (
    <>
      <div className={Styles.body}>
        <header>
          <span className={Styles.heading}>Confirm Subscription Change</span>
        </header>
        <main>
          <div className={`${Styles.container} ${Styles.greyBorder} `}>
            <div>
              <span className={Styles.title}>{oldPlan.title}</span>
              <span className={`${Styles.planStatus} ${Styles.currentPlan}`}>
                Current
              </span>
            </div>
            {currentPlan && (
              <>
                <div className={Styles.divPlanDuration}>
                  <span className={Styles.planDurationTxt}>
                    {oldPlanPeriodicity}
                  </span>
                </div>
                <p className={Styles.price}>
                  $
                  {oldPlan.periodicity === "MONTHLY"
                    ? `${oldPlan.monthlyPrice.toFixed(2)}/mo`
                    : `${oldPlan.yearlyPrice.toFixed(2)}/yr`}
                </p>

                <span className={Styles.expire}>{expirePlanDate}</span>
              </>
            )}
          </div>
          <div className={`${Styles.container} ${Styles.greenBorder}`}>
            <div>
              <span className={Styles.title}>{newPlan.title}</span>
              <span className={`${Styles.planStatus} ${Styles.newPlan}`}>
                New
              </span>
            </div>
            <div className={Styles.divPlanDuration}>
              <span className={Styles.planDurationTxt}>
                {newPlanPeriodicity}
              </span>
            </div>
            <p className={Styles.price}>{`$${newPlanPrice}`}</p>
            <div>
              <span className={Styles.start}>{startPlanDate}</span>
              {!isDownGradingPlan && (
                <span className={Styles.renew}>{newPlanDate}</span>
              )}
            </div>
            {/* {!isDownGradingPlan && (
              <>
                <div className={Styles.divProrated}>
                  <span className={Styles.proratedCredit}>Prorated Credit</span>
                  <span className={Styles.proratedPrice}>($59.99)</span>
                </div>
                <div className={Styles.divProrated}>
                  <span className={Styles.proratedCredit}>Amount Due</span>
                  <span className={Styles.proratedPrice}>$90.00</span>
                </div>
              </>
            )} */}
          </div>
          <p className={Styles.planRenewInfo}>
            {isDownGradingPlan
              ? `Your ${oldPlanPeriodicity} subscription is paid up until ${expirePlanDate}. Starting ${newPlanDate} you’ll be billed ${newPlan.periodicity === "MONTHLY"
                ? `$${newPlan.monthlyPrice} monthly`
                : `$${newPlan.yearlyPrice} annual`
              }.`
              : `Your ${newPlanPeriodicity} subscription will be available ${user.isInTrialPeriod ? 'after trial period expires' : 'instantly'} and renew automatically on ${newPlanDate}.`}
          </p>
          {process.env.REACT_APP_ENV !== "prod" &&
            <div
              className={`${Styles.container} ${Styles.greyBorder} ${Styles.divCreditCard}`}
            >
              <span className={Styles.cardDetailsTxt}>Amex X1234 Exp 01/25</span>
              <ButtonBase>
                <div className={Styles.ccBtnTxtGreen}>Edit</div>
              </ButtonBase>
              <AmericanExpress />
            </div>
          }
        </main>
        <footer>
          <GreenBgButton
            onClick={handleBuyButtonPress}
            fullWidth
            className={Styles["buyBtn"]}
          >
            <p className={Styles.btnTextBold}>Confirm & Update</p>
          </GreenBgButton>
        </footer>
      </div>
    </>
  );
};

export default SubscriptionPreview;
