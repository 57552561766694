// Icons
import CloseIcon from "../images/icons/close-x.svg";

/**
 * SideOverlay Component
 * @returns {JSX.Element}
 * @constructor
 */
function SideOverlay({
  width = "w-[410px]",
  offset,
  bgColor,
  showBackdrop = true,
  showCloseBtn = true,
  handleCloseClick,
  children,
  isSearch,
}) {
  /**
   * Main render method
   */
  const backgroundColor = bgColor ?? "bg-gray-main";

  return (
    <div
      className={`absolute top-0 w-full h-full ${
        showBackdrop ? "bg-blue-dark bg-opacity-80" : ""
      }`}
      style={{ left: offset ? `${offset}px` : 0, zIndex: 10 }}
    >
      <aside
        className={`${
          isSearch ? "w-[450px]" : width
          } h-full z-10 ${backgroundColor} relative border border-r border-solid border-gray-light`}
      >
        {showCloseBtn && (
          <button
            className={`border-0 cursor-pointer absolute top-[15px] right-[15px]`}
            onClick={handleCloseClick}
          >
            <img src={CloseIcon} alt="Close" />
          </button>
        )}

        {children}
      </aside>
    </div>
  );
}

// Component export
export default SideOverlay;
