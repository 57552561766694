import React, { useEffect, useState } from "react";
import SearchResults from "../searchResult/searchResults";
import { generatePdf } from "../../../utils/pdfGeneratorUtils";
import { searchFormStore } from "../../../store/searchFormStore";

const SearchResultsWebPDF = () => {

  const [responseData, setResponseData] = useState()
  const [fullName, setFullName] = useState()

  useEffect(() => {
    document.addEventListener('message', (event) => {
      let ferretAISummary = event.data.ferretAISummary
      let ferretAI = event.data.ferretAI
      let educationMore = event.data.educationMore
      let experienceMore = event.data.experienceMore
      let showOthers = event.data.showOthers
      let showMore = event.data.showMore
      let respData = JSON.parse(event.data.message)

      respData = {
        ...respData,
        ferretAISummary: ferretAISummary,
        ferretAI: ferretAI,
        educationMore: educationMore,
        experienceMore: experienceMore,
        showOthers: showOthers,
        showMore: showMore
      }

      setResponseData(respData)
      setFullName(event.data.fullName)
    });
  }, [])

  useEffect(() => {
    if (responseData) {
      setTimeout(() => {
        generatePdf(
          'content-to-pdf',
          fullName,
          () => {
            if (window.ReactNativeWebView) { // ensure window.ReactNativeWebView is there, otherwise, web app might crash if is not there
              window.ReactNativeWebView.postMessage('PDF created successfully and stored in your file system')
            }
          })
      }, 2000)
    }
  }, [responseData])

  return (
    <>
      {responseData ?
        <SearchResults feed={responseData} />
        :
        <></>
      }
    </>
  );
};

export default SearchResultsWebPDF;
