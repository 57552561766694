import { useMutation } from '@apollo/react-hooks';
import { CircularProgress } from '@mui/material';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useLazyQuery } from 'react-apollo';
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/userContext";
import ImportContactsLandingLogo from '../../images/svg/import-contacts-landing-logo';
import {
  CONTACTS_SUMMARY, GET_FERRET_CONTACT_LIST, IMPORT_CONTACTS, UPDATE_FERRET_USER
} from '../../queries/queries';
import { setUserInfo, userInfo } from '../../root-navigation';
import {
  providers
} from '../../utils/contact-parser';
import {
  parseGoogleContactsForContactsComponent,
  parseGoogleContactsForMutation
} from '../../utils/google-contacts-parser';
import { googleLoginWithContactScope } from '../../utils/google-login-helper';
import {
  parseIcloudContactsForContactsComponent,
  parseIcloudContactsForMutation
} from '../../utils/iCloud-contacts-parser';
import Variables from '../../utils/Variables';
import { GreenBorderButton } from '../buttons/greenBorderButton';
import ICloudAuthModal from '../iCloudAuthModal/iCloudAuthModal';
import SocialNetworksGrid from '../socialNetworksGrid/socialNetworksGrid';
import getGoogleContacts from './googleContactsHelper';
import getIcloudContacts from './iCloudContactsHelper';
import Styles from './importContact.module.css';

const CHUNK_SIZE = 300;
let chunk = 0;
let contactsToSend = [];
let sucessRequest = 0;
const REQUEST_TIMEOUT = 500;
let reImport = false;

const socialNetworks = Object.fromEntries(
  new Map([
    [
      providers.phoneEnd,
      {
        title: 'Device Contacts',
        titleColor: 'white',
        placeHolder: 'From your Android mobile device.',
        backgroundColor: '#AAAAAA',
        img: require('../../images/contacts-icon.png'),
        logoWidth: 24,
        logoHeight: 24,
        route: 'Contacts',
        enabled: true,
        uploadStatus: false,
        fetchContactStatus: false,
        contactSize: 0
      },
    ],
    [
      providers.google,
      {
        title: 'Google Contacts',
        placeHolder: 'From your Google account.',
        titleColor: 'rgba(86, 92, 109, 1)',
        backgroundColor: 'white',
        img: require('../../images/logo-google.png'),
        logoWidth: 24,
        logoHeight: 24,
        route: 'Contacts',
        enabled: true,
        uploadStatus: false,
        fetchContactStatus: false,
        arrowStyle: '#0D1324',
        contactSize: 0
      },
    ],
    [
      providers.iCloud,
      {
        title: 'iCloud Contacts',
        placeHolder: 'From your Apple account or iPhone.',
        titleColor: 'rgba(86, 92, 109, 1)',
        backgroundColor: 'white',
        img: require('../../images/icloud_logo.png'),
        logoWidth: 28,
        logoHeight: 28,
        route: 'Contacts',
        enabled: true,
        uploadStatus: false,
        fetchContactStatus: false,
        arrowStyle: '#0D1324',
        contactSize: 0
      },
    ],
    [
      providers.facebook,
      {
        title: 'Facebook',
        titleColor: 'white',
        backgroundColor: '#2A3CA3',
        img: require('../../images/logo-facebook.png'),
        logoWidth: 12,
        logoHeight: 20,
        route: 'Contacts',
        uploadStatus: false,
        fetchContactStatus: false,
        contactSize: 0
      },
    ],
    [
      providers.linkedIn,
      {
        title: 'LinkedIn',
        titleColor: 'white',
        backgroundColor: '#0084BF',
        img: require('../../images/logo-linkedin.png'),
        logoWidth: 20,
        logoHeight: 20,
        route: 'Contacts',
        uploadStatus: false,
        fetchContactStatus: false,
        contactSize: 0
      },
    ],
  ]),
);
let phoneEmails = []
const ImportContactsLanding = () => {

const location = useLocation()
const props = location?.state ? JSON.parse(location?.state) : {}
const [showIcloudModal, setShowIcloudModal] = useState(false)

// useEffect(()=>{
//   const nylasURL = window?.location?.search
//   if(nylasURL){
//     let data = {}
//     nylasURL.replace('?','').split('&').forEach(item=>{
//       data[item.split('=')[0]] = item.split('=')[1]
//     })
//     getIcloudContacts(
//       () => {
//         setIsLoading(true)
//         setRemindBtn(true)
//       },
//       (error, contacts) => {
//         preprocessContactsToSend(
//           error,
//           contacts,
//           parseIcloudContactsForContactsComponent,
//           parseIcloudContactsForMutation,
//           providers.iCloud,
//         );
//       },
//       updateUser,
//       null,
//       false,
//       data,
//     );

//   }
// },[])

  const navigate = useNavigate();

  const [isloading, setIsLoading] = useState(false);
  const [listID, setListID] = useState(null);
  const [provs, setProvs] = useState({ ...socialNetworks });
  const [totalContacts, setTotalContacts] = useState(0);
  const [parsedContact, setParsedContact] = useState([]);
  const [hideRemindBtn ,setRemindBtn] = useState(false)
  const { user, setUser } = useContext(UserContext);
  var contactListId = null;
  const [updateUser, {loading: updateUserLoading, data: updateUserData} ] = useMutation(UPDATE_FERRET_USER);

  useEffect(()=>{
    if(updateUserData && !updateUserLoading){
      let u = setUserInfo(updateUserData.updateUser);
      setUser({...u});
    }
  },[updateUserLoading, updateUserData])

  const [getFerretContactList, {loading: contactLoading, data: contactData} ] = useLazyQuery(
    GET_FERRET_CONTACT_LIST,{
      fetchPolicy: 'network-only',
    }
  );

  useEffect(()=>{
    if(!contactLoading && contactData){
      phoneEmails = []
        if(contactData?.contactList?.contacts && Array.isArray(contactData.contactList.contacts) && contactData.contactList.contacts.length){
          contactData.contactList.contacts.forEach(item =>{
            if(item?.phoneNumbers && item.phoneNumbers?.length){
              item.phoneNumbers.forEach(numbers=>{phoneEmails.push((numbers.number?.trim()+'--'+item.name?.trim()+' '+(item.lastName ?? '')?.trim())?.trim())})
            }
            if(item?.emailAddresses && item.emailAddresses?.length){
              item.emailAddresses.forEach(emails=>{phoneEmails.push((emails.email?.trim()+'--'+item.name?.trim()+' '+(item.lastName ?? '')?.trim())?.trim())})
            }
          })
        }
    }
  },[contactLoading, contactData])

  useEffect(() => {
    Object.keys(socialNetworks).forEach((key) => {
      socialNetworks[key].numberOfContacts = '';
    });
    setListID(null);
    setProvs({ ...socialNetworks });
    getAddressBook();
    getFerretContactList()
  }, []);

  // socialNetworks[providers.phone].action = () =>{
  //   reImport = false;
    // getDeviceContacts("landing",
    //   () => {setIsLoading(true), setRemindBtn(true)},
    //   (error, contacts) => {
    //     preprocessContactsToSend(
    //       error,
    //       contacts,
    //       ParsePhoneContactsForContactsComponent,
    //       ParsePhoneContactsForMutation,
    //       providers.phone,
    //     );
    //   },
    //   updateUser
    // );
  // }


  socialNetworks[providers.iCloud].action = () =>{
    setShowIcloudModal(true)
    // setIsLoading(true)
    // reImport = false;
    // const url = 'https://api.nylas.com/oauth/authorize?'+
    //             'client_id='+process.env.REACT_APP_NYLAS_CLIENT_ID+
    //             // '&redirect_uri='+process.env.REACT_APP_NYLAS_CALLBACK_URL+
    //             '&redirect_uri=http://localhost:3000/importContactsLanding'+
    //             '&response_type=token'+
    //             '&scopes=email.read_only,calendar.read_only,contacts.read_only'+
    //             '&provider=icloud&redirect_on_error=true'
    // window.open(url,"_self");
  }

  const handleGoogle = (data) => {
    getGoogleContacts(
      () => {
        setIsLoading(true)
        setRemindBtn(true)
      },
      (error, contacts) => {
        preprocessContactsToSend(
          error,
          contacts,
          parseGoogleContactsForContactsComponent,
          parseGoogleContactsForMutation,
          providers.google,
        );
      },
      updateUser,
      null,
      false,
      data,
    );
  }

  socialNetworks[providers.google].action = () =>{
    reImport = false;
    googleLoginWithContactScope(handleGoogle);
  };
  const callImportScreen = () => {
    const data = {
      socialNetworks: Object.values(provs),
      listID,
      modeCheck: props?.modeCheck
    }
    navigate('/importedContacts', { state: JSON.stringify(data) })
    // props.navigation.push('ImportedContacts', {
    //   socialNetworks: Object.values(provs),
    //   listID,
    //   modeCheck: props?.route?.params?.modeCheck
    // });
  }

  const [importContacts, {loading: importContactLoading, data: importContactData, error}] = useMutation(IMPORT_CONTACTS);

  useEffect(()=>{
    if(!importContactLoading && importContactData){
      if (importContactData?.importContacts?.listID){
        contactListId = importContactData.importContacts.listID;
      }
      let u = userInfo;
      u.hasContacts = true;
      setUser({...u});
      sucessRequest += 1;

      reImport = true;
      chunk += 1;
      if(chunk < contactsToSend.length){
        sendContactsInChunks()
      }else if(contactsToSend.length <= sucessRequest) {
        chunk = 0;
        contactsToSend = [];
        getAddressBook();
        setIsLoading(false);
        getFerretContactList()
        callImportScreen()
        console.log('callImportScreen=====2')
      }
    }
    if(!importContactLoading && error){
      sucessRequest += 1;
      setRemindBtn(false)
      console.log('callImportScreen=====5')
      reImport = true;
      chunk += 1;
      if(chunk < contactsToSend.length){
        sendContactsInChunks()
      }else if(contactsToSend.length <= sucessRequest) {
        chunk = 0;
        contactsToSend = [];
        getAddressBook();
        setIsLoading(false);
        getFerretContactList()
        console.log('callImportScreen=====3')
      }
    }
  },[importContactLoading, importContactData, error])

  const [getAddressBook, {loading, data: addressData, error: addressError}] = useLazyQuery(CONTACTS_SUMMARY, {
    fetchPolicy: 'network-only'
  });

  useEffect(()=>{
    if(!loading && addressData){
      updateNumbersOfContacts(addressData)
    }else if(!loading && addressError){
      Object.keys(socialNetworks).forEach((key) => {
        socialNetworks[key].numberOfContacts = 0;
      });
      setListID(null);
      setProvs({ ...socialNetworks });
    }
  },[loading, addressData, addressError])

  const sendContactsInChunks = () => {
    console.log('callImportScreen=====sendContactsInChunks====1')
    if (chunk < contactsToSend.length) {
      Variables.isAddSearches = true // assign true for refresh saved searched
      let data =  {
        autoUpdateSync: true,
        contacts: contactsToSend[chunk],
        listID: contactListId ? contactListId : listID,
        isLastBatch: contactsToSend.length === chunk+1,
        batchID: contactsToSend[chunk][0].provider,
        batchNumber: chunk+1,
        totalBatches: contactsToSend.length,
        reimport: props?.screenName == "Account" ? true : reImport
      }
      console.log('data=====',JSON.stringify(data))
      importContacts({
        variables: {
          autoUpdateSync: true,
          contacts: contactsToSend[chunk],
          listID: contactListId ? contactListId : listID,
          isLastBatch: contactsToSend.length === chunk+1,
          batchID: contactsToSend[chunk][0].provider,
          batchNumber: chunk+1,
          totalBatches: contactsToSend.length,
          reimport: props?.screenName == "Account" ? true : reImport
        }
      });
      // reImport = true;
      // chunk += 1;
      // if(chunk < contactsToSend.length){
      //   setTimeout(() => {
      //     sendContactsInChunks()
      //   }, REQUEST_TIMEOUT);
      // }
    }
  };

  const preprocessContactsToSend = (
    error,
    contacts,
    parserForContactsComponent,
    parserFromMutation,
    providers,
  ) => {
    if (!contacts || error) {
      // socialNetworks[providers].numberOfContacts = 0;
      setListID(null);
      setProvs({ ...socialNetworks });
      setIsLoading(false)
      setRemindBtn(false)
      return;
    }
    const parsedContacts = parserFromMutation(
      parserForContactsComponent(contacts),phoneEmails
    );
    console.log('parsedContacts====',parsedContacts)
    if(parsedContacts?.length === 0){
      setIsLoading(false)
      setRemindBtn(false)
      Object.keys(socialNetworks).forEach((key) => {
        if (key == providers) {
          socialNetworks[key].contactSize = contacts.length;
          socialNetworks[key].fetchContactStatus = true
        }
      });
      setProvs({...socialNetworks})
      return;
    }
    setParsedContact((data)=> [...data,...parsedContacts])
    setTotalContacts(parsedContacts.length);
    setRemindBtn(true)
    setIsLoading(false)
    Object.keys(socialNetworks).forEach((key) => {
      if (key == providers) {
        socialNetworks[key].numberOfContacts = parsedContact.length;
        socialNetworks[key].contactSize = contacts.length;
        socialNetworks[key].fetchContactStatus = true
      }
    });
    setProvs({...socialNetworks})
  };

  const sendContacts = () => {
    if (parsedContact.length > 0) {
      chunk = 0;
      sucessRequest = 0;
      contactsToSend = _.chunk(parsedContact, CHUNK_SIZE);
      setIsLoading(true);
      sendContactsInChunks();
    } else {
      callImportScreen();
    }
  }

  const onBackdropPress = () =>{
    setShowIcloudModal(false)
  }

  const onContinuePress = (data) =>{
    setShowIcloudModal(false)
    if(data){
      getIcloudContacts(
        () => {
          setIsLoading(true)
          setRemindBtn(true)
        },
        (error, contacts) => {
          preprocessContactsToSend(
            error,
            contacts,
            parseIcloudContactsForContactsComponent,
            parseIcloudContactsForMutation,
            providers.iCloud,
          );
        },
        updateUser,
        null,
        false,
        data,
      );
    }
  }

  const updateNumbersOfContacts = (data) => {
    const { addressBook = {} } = data;
    const { summary = [], listID: id = null } = addressBook;

    if (!summary.length) return;
    const updatedProvs = { ...provs };
    let newTotalContacts = 0;
    summary.forEach((item) => {
      const { provider: provID, total, updated } = item;
      let mProvID = provID == providers.phone ? providers.phoneEnd : provID
      if (updatedProvs[mProvID]) {
        updatedProvs[mProvID].numberOfContacts = total;
        updatedProvs[mProvID].contactSize = total;
        if(updated) updatedProvs[mProvID].uploadStatus= true;
        newTotalContacts += total;
      }
    });
    setListID(id);
    setProvs(updatedProvs);
    setTotalContacts(newTotalContacts);
  };

  const displayRemindMeBtn=()=>{
    let showRemindBtn = false
    if(totalContacts == 0 || Variables?.isContactImported || props?.screenName == "Account"){
      showRemindBtn= true
    }
    if(hideRemindBtn){
      showRemindBtn =false
    }

    if(showRemindBtn){
      return(
      <GreenBorderButton
        fullWidth
        variant="contained"
        onClick={() => {navigate(-1)}}
        style={{marginTop: 15}}>
        <div className={Styles.backBtnText}>Remind me Later</div>
      </GreenBorderButton>
      )
    }

  }


  return (
    <div className={Styles.bodyMain}>
      <div className={Styles.container}>
        <div className={Styles.body}>
          <span className={Styles.title}>Import Contacts</span>
          <div className={Styles.imgContain}>
            <ImportContactsLandingLogo className={Styles.mainLogo} />
          </div>
          <span className={Styles.description}>
          We’ll allow you to easily search for contacts in your network, and get notified of critical news updates.
          </span>
          <div className={Styles.hr} />
          {provs && (
            <SocialNetworksGrid socialNetworks={Object.values(provs)} />
          )}
        </div>
      </div>

      {(isloading || loading || contactLoading) ? (
        <div className={Styles.activityIndicator}>
          <CircularProgress color="inherit" />
        </div>
      ):<div className={Styles.footer}>
      {props?.screenName == "Account" || props?.screenName == "SavedSearches" || props?.screenName == 'ClosestContactsArticles' || props?.screenName == 'newDashboard' ?
        <div className={Styles.btContainer}>
          {totalContacts !== 0 ?
            <GreenBorderButton
              fullWidth
              variant="contained"
              onClick={() => sendContacts()}>
              <div className={Styles.backBtnText}>Finish Importing</div>
            </GreenBorderButton>
            :null
          }

          {displayRemindMeBtn()}

        </div>
        :
        <div className={Styles.btContainer}>

          {totalContacts !== 0 ?
            <GreenBorderButton
              fullWidth
              variant="contained"
              onClick={() => sendContacts()}>
              <div className={Styles.backBtnText}>Finish Importing</div>
            </GreenBorderButton>
            :
          <GreenBorderButton
            fullWidth
            variant="contained"
            onClick={() => {
              props?.modeCheck == 'login' ? navigate("/dashboard",  {state: {showSplash: true}}, { replace: true }):
              navigate("/dashboard", { replace: true })
            //:
            // props.navigation.push('NoContactsSelected', {
            // socialNetworks: Object.values(provs),
            // listID,
            //   })
            }}
            style={{marginTop: 15}}>
            <div className={Styles.backBtnText}>Remind me Later</div>
          </GreenBorderButton>
          }
        </div>
      }
      {showIcloudModal &&
      <ICloudAuthModal
        onBackdropPress={onBackdropPress}
        onContinuePress={onContinuePress}
      />}
    </div>}
    </div>
  );
};

export default ImportContactsLanding;

