// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".alphabetScroll_letterSidebar__b7bq2 {\n    padding: 0;\n    position: fixed;\n    top: 46%;\n    z-index: 0;\n    right: 0;\n    overflow: hidden;\n    overflow-y: scroll;\n    height: 38%;\n    margin-right: 3px;\n    list-style-type: none;\n}\n\n.alphabetScroll_letterSidebar__b7bq2 li {\n    list-style: none;\n    width: 10px;\n    text-align: center;\n    font-weight: 500;\n}\n\n.alphabetScroll_alphaHeaderText__1Vmha {\n    font-family: 'Proxima Nova';\n    font-style: normal;\n    font-weight: 600;\n    font-size: 10px;\n    text-align: center;\n    text-transform: uppercase;\n    color: #697080;\n    list-style-type: none;\n}\n\n.alphabetScroll_alphaHeaderTextDummy__9KoP1 {\n    font-size: 12px;\n    color: transparent;\n}", "",{"version":3,"sources":["webpack://./src/components/alphabetScroll/alphabetScroll.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,eAAe;IACf,QAAQ;IACR,UAAU;IACV,QAAQ;IACR,gBAAgB;IAChB,kBAAkB;IAClB,WAAW;IACX,iBAAiB;IACjB,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,2BAA2B;IAC3B,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,kBAAkB;IAClB,yBAAyB;IACzB,cAAc;IACd,qBAAqB;AACzB;;AAEA;IACI,eAAe;IACf,kBAAkB;AACtB","sourcesContent":[".letterSidebar {\n    padding: 0;\n    position: fixed;\n    top: 46%;\n    z-index: 0;\n    right: 0;\n    overflow: hidden;\n    overflow-y: scroll;\n    height: 38%;\n    margin-right: 3px;\n    list-style-type: none;\n}\n\n.letterSidebar li {\n    list-style: none;\n    width: 10px;\n    text-align: center;\n    font-weight: 500;\n}\n\n.alphaHeaderText {\n    font-family: 'Proxima Nova';\n    font-style: normal;\n    font-weight: 600;\n    font-size: 10px;\n    text-align: center;\n    text-transform: uppercase;\n    color: #697080;\n    list-style-type: none;\n}\n\n.alphaHeaderTextDummy {\n    font-size: 12px;\n    color: transparent;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"letterSidebar": "alphabetScroll_letterSidebar__b7bq2",
	"alphaHeaderText": "alphabetScroll_alphaHeaderText__1Vmha",
	"alphaHeaderTextDummy": "alphabetScroll_alphaHeaderTextDummy__9KoP1"
};
export default ___CSS_LOADER_EXPORT___;
