import React from "react";
import AddressProfile from "../../../images/svg/address-profile";
import Styles from "./article.module.css";

const AssetsView = (props) => {
  const { style, data, newsItem } = props;

  const renderItem = (item, header, isMax) => {
    try {
      if (item) {
        const data = JSON.parse(item);
        if (Array.isArray(data)) {
          return data?.map((mItem) => renderSigleItem(mItem, header, isMax));
        } else {
          return renderSigleItem(data, header, isMax);
        }
      } else {
        return (
          <div>
            <span style={{ ...style.tracersValue, color: "#142030" }}>
              {"No information available."}
            </span>
          </div>
        );
      }
    } catch (e) {
      console.log("data======eee", e);
    }
  };

  const renderSigleItem = (data, header, isMax) => {
    try {
      return Object.keys(data).map((key) => {
        let value = data[key] && data[key] != "" ? data[key] : "Not specified";
        if (key == "State") value = value.toUpperCase();
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {header != key &&
              header != "Owner(s)" &&
              header != "Site Address" && (
                <span style={style.tracersKey}>{key}</span>
              )}
            <div style={style.addressIconView}>
              {header == "Site Address" && (
                <div style={{ width: 16, height: 16, marginLeft: 16 }}>
                  <AddressProfile />
                </div>
              )}
              <span
                style={
                  value == "Not specified"
                    ? { color: "#8E96A8", ...style.tracersValue }
                    : {
                        ...style.tracersValue,
                        fontWeight: header != "Owner(s)" ? "400" : "700",
                      }
                }
                dangerouslySetInnerHTML={{ __html: value }}
              />
            </div>
          </div>
        );
      });
    } catch (e) {
      console.log("data======eee", e);
    }
  };

  const renderSectionHeader = (title, isSeperator) => (
    <div style={{ marginBottom: 10 }}>
      <div style={{ backgroundColor: "#E5E5E5", marginBottom: 10 }}></div>
      <span style={style.tracersTitle}>{title}</span>
    </div>
  );

  return (
    <div className={Styles.gridContainer}>
      {data?.map((item, index) => (
        <div
          className={
            item.header == "Assessed Value"
              ? Styles.gridContainerCardLast
              : Styles.gridContainerCard
          }
        >
          {renderSectionHeader(item.header, index != 0)}
          <div
            className={
              item.header == "Owner(s)" || item.header == "Site Address"
                ? Styles.containerNoGrid
                : Styles.containerGrid
            }
          >
            {renderItem(
              item.body,
              item.header,
              item.header == "Assessed Value"
            )}
          </div>
        </div>
      ))}
      <p className={Styles.bottomLable}>
        {newsItem?.articleId
          ? `${newsItem?.articleId}-${newsItem?.sourceIdentifier}-${newsItem?.verifiedBy}-${newsItem?.matchingLogic}`
          : `${newsItem?.sourceIdentifier}-${newsItem?.verifiedBy}-${newsItem?.matchingLogic}`}
      </p>
    </div>
  );
};
export default AssetsView;
