import { useState } from "react";
import OnboardingTooltip from "./onboardingTooltip";
import OnboardingPopover from "../onboardingTooltips/onboardingPopover";
import { getFilterImage } from "../../images/filterColorIcon/getFilterImage";

const ArticleCategoriesTooltip = (props) => {

  let msg = ""
  switch (props.category) {
    case 'News':
      msg = "Over 19million negatvie news articles serviced by a web JSON API or Web by www.oneclickcompliance.com."
      break
    case 'Adverse Media':
      msg = "A collection of news articles, where each article contains largely negative keywords."
      break
    case 'Potential Criminal Records':
      msg = "Publicly available arrest and conviction records from numerous jurisdictions across the US."
      break
    case 'Debts':
      msg = "Bankruptcy, foreclosure, lien, and eviction records from various reporting agencies across the US."
      break
    case 'Political Exposure':
      msg = "A global listing of politically exposed people."
      break
    case 'Watchlists':
      msg = "A listing of current and historical sanctions from various global entities."
      break
    case 'Papers and Leaks':
      msg = "Information from more than 290 investigative reporters covering 105 countries and territories.    "
      break
    case 'State Owned Enterprise':
      msg = "List of state owned entities and in high risk jurisdictions key staff."
      break
    case 'Positive News':
      msg = "A collection of news articles, where each article contains largely positive keywords."
      break
    case 'Corporate Records':
      msg = "Officers, filing dates, and statuses of publicly registered US corporations."
      break
    case 'Court Records':
      msg = "Publicly available legal documents from over 400 US state and federal jurisdictions."
      break
    case 'Crunchbase':
      msg = "Needs content"
      break
    case 'Court Decisions':
      msg = "Publicly available legal decisions from over 400 US state and federal jurisdictions."
      break
    case 'Assets':
      msg = "Assessment values and owners of various real properties from across the United States."
      break
    case 'MetaSearch':
      msg = "Results from curated websites and articles."
      break
    case 'Property':
      msg = "Assessment values and owners of various real properties from across the United States."
      break
    default:
      msg = "Needs content"
  }

  return (
    <OnboardingTooltip
      title={
        <OnboardingPopover
          isLeftMargin={false}
          title={props.category}
          content={msg}
          icon={getFilterImage(props.category, false, 'white', {
            transform: "scale(.80)",
            marginRight: 6,
          })}
          onClick={() => {
            props.onClick();
          }}
        />
      }
      isVisible={props.isVisible}
      placement="top"
      componentStyle={{
        marginBottom: "5px !important",
        maxWidth: "90%",
        padding: 0,
        marginLeft: "5%",
        marginRight: "5%",
      }}
      arrowStyles={{ overflow: "inherit", marginLeft: .5}}
      {...props}
    >
      {props.children}
    </OnboardingTooltip>
  );
};

export default ArticleCategoriesTooltip;
