import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import greenLocationPin from '../../images/svg/green_location_pin.svg'
import redLocationPin from '../../images/svg/red_location_pin.svg'
import blueLocationPin from '../../images/svg/blue_location_pin.svg'
import { appStore } from '../../store/appStore';
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import Styles from './mapCategory.module.css'

const MapCategory = ({ filteredArticles, articleCardClick, height }) => {

  const [mapState, setMapState] = useState();
  const [markers, setMarkers] = useState([]);

  const infoWindowRef = useRef()
  const clusterInfoWindowRef = useRef()
  const markerClusterRef = useRef()

  const dimensions = appStore((state) => state.dimensions)

  useEffect(() => {
    // Load the Google Maps API script dynamically
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCriNGw5vJt3Pwzq7t_ozcMMAPLP7vj-WA&callback=initMap&libraries=visualization&v=weekly`;
    script.async = true;
    document.body.appendChild(script);

    script.onload = initMap;

    return () => {
      // Clean up
      document.body.removeChild(script);
    };
  }, []);

  const filteredArticlesLength = useMemo(() => {
    // Return the length of filteredArticles
    return filteredArticles.length;
  }, [filteredArticles.length]);


  useEffect(() => {
    if (mapState && filteredArticlesLength > 0) {
      clearAll()
      getLatLng()
    }
  }, [filteredArticlesLength, mapState ])

  const clearAll = () => {
    markerClusterRef.current?.clearMarkers()
    infoWindowRef.current?.close()
    clusterInfoWindowRef.current?.close()
    setMarkers([])
    markers.forEach(marker => marker.setMap(null));
  }

  const initMap = () => {
    const newMap = new window.google.maps.Map(document.getElementById('map'), {
      center: { lat: 36.778259, lng: -119.417931},
      zoom: 3,
      minZoom: 2,
      maxZoom: 15,
      disableDefaultUI: true,
      styles: mapStyle // Apply custom map style
    });

    setMapState(newMap)
  }

  const getLatLng = () => {
    if (filteredArticles?.length > 0) {

      markerClusterRef.current = new MarkerClusterer({ map: mapState, markers: [], onClusterClick: handleClusterClick });

      clearAll()

      // Create new markers for each address
      const newMarkers = [];

      filteredArticles.forEach(article => {

        let addressesData = ""
        let addressArray = []

        if (article.parentCategory == "Assets") {
          const siteAddressData = article?.tracerData?.find(item => item.header === "Site Address");
          const fullAddress = siteAddressData && JSON.parse(siteAddressData?.body)?.[0]?.["Full Address"];
          addressesData = fullAddress && fullAddress?.replace(/<br\s*\/?>/gi, "\n");
          addressArray = addressesData && addressesData?.split("\\n");
        } else if (article.parentCategory == "Corporate Records") {
          addressesData = article?.tracerData?.find(item => item.header === "Addresses");
          const addressesString = addressesData?.body && JSON.parse(addressesData.body)?.Addresses;
          addressArray = addressesString && addressesString?.split("\n");
        } else if (article.parentCategory == "Court Records") {
          const overviewData = article?.tracerData?.find(item => item.header === "Overview");
          if (overviewData) {
            const addressesString = JSON.parse(overviewData.body);
            const state = addressesString.State;
            const county = addressesString.County;
            addressArray.push(`${state} ${county}`)
          }
        }

        // Remove special characters from each item
        let addressArray2 = addressArray?.[0]

        // addressArray2 = addressArray2?.map((item) => {
        //   return item.replace(/[^\w\s]/gi, '');
        // });

        addressArray2 = [addressArray2?.replace(/[^\w\s]/gi, '')];

        const uniqueAddresses = [...new Set(addressArray2)];

        if (uniqueAddresses?.length > 0) {
          uniqueAddresses?.map((address, index) => {

            const geocoder = new window.google.maps.Geocoder();

            geocoder.geocode({ 'address': address }, (results, status) => {
              if (status === 'OK') {
                const location = results[0].geometry.location;
                const latLng = { lat: location.lat(), lng: location.lng() };
                const marker = new window.google.maps.Marker({
                  map: mapState,
                  position: latLng,
                  title: address,
                  name: article.title,
                  category: article?.parentCategory,
                  articleId: article?.articleId,
                  icon: {
                    url: article.parentCategory == "Assets" ? greenLocationPin :
                      article.parentCategory == "Court Records" ? redLocationPin : blueLocationPin,
                    scaledSize: new window.google.maps.Size(32, 32)
                  }
                });

                marker.addListener('click', () => {
                  mapState.panTo(marker.getPosition());

                  if (infoWindowRef.current) {
                    infoWindowRef.current?.close()
                    infoWindowRef.current = undefined
                  }

                  // Create and attach InfoWindow to marker
                  infoWindowRef.current = new window.google.maps.InfoWindow({
                    content:
                      `<div id="info-window-content"
                       style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center;"
                       >
                        <div style="margin-top: 10px; overflow-wrap: anywhere;">
                          <h3>${article.title}</h3>
                          <p>Category: ${article?.parentCategory}</p>
                          <p>Address: ${address}</p>
                        </div>
                        <img style="width: 24px; height: 24px; margin-left: 10px" src=${require("../../images/svg/next-icon.svg").default} />
                      </div>`
                  });

                  // Add click listener to the custom HTML element inside the info window
                  infoWindowRef.current.addListener('domready', function () {
                    var infoContentDiv = document.getElementById('info-window-content');
                    if (infoContentDiv) {
                      infoContentDiv.addEventListener('click', function () {
                        let article1  = {...article, status: true}
                        articleCardClick(article1)
                      });
                    }
                  });

                  infoWindowRef.current.open(mapState, marker);
                });

                if (index === 0) {
                  mapState.panTo(marker.getPosition());
                }

                newMarkers.push(marker);

                markerClusterRef.current.addMarker(marker);
              }
            });
          })
        }
      });

      setMarkers([...markers, ...newMarkers]);

    }
  }


  const handleClusterClick = (event, cluster, map) => {

    var center = cluster.position;

    var content = "";

    cluster.markers.forEach(function (item) {
      content += `
      <button 
        data-articleId="${item.articleId}"
        class="cluster-info-window-item"
        style="width: 100%; margin: 16px 10px 10px 0; display: flex; flex-direction: row; justify-content: space-between; border-bottom: 1px solid #bebebe;"
      >
        <div style="text-align: left; overflow-wrap: anywhere;">
          <h3>${item.name}</h3>
          <p>Category: ${item?.category}</p>
          <p>Address: ${item.title}</p>
        </div>
        <img style="width: 24px; height: 24px; margin-left: 10px; padding-right: 10px;" src=${require("../../images/svg/next-icon.svg").default} />
      </button>
      `;
    });

    if (clusterInfoWindowRef.current) {
      clusterInfoWindowRef.current.close()
      clusterInfoWindowRef.current = undefined
    }

    clusterInfoWindowRef.current = new window.google.maps.InfoWindow({
      position: center,
      content: `
      <div id="info-window-content"
        style="display: flex; flex-direction: column; max-height: 400px; justify-content: flex-start;overflow-x: hidden; overflow-y: scroll;scrollbar-width: 10px;scrollbar-color: green white;"
      >
       ${content}
      </div>
      `
    });

    clusterInfoWindowRef.current.addListener('domready', function () {
      var contentInfoContentDivs = document.querySelectorAll('.cluster-info-window-item');
      contentInfoContentDivs.forEach(function (contentInfoContentDiv) {
        contentInfoContentDiv.addEventListener('click', function () {
          const articleId = contentInfoContentDiv.getAttribute("data-articleId");
          const article = filteredArticles.find(item => item.articleId == articleId);
          if (article) {
            articleCardClick(article);
          }
        });
      });
    });

    clusterInfoWindowRef.current.open(map);
  };


  return (
    <div id="map" style={{ width: '100%', height: height }}></div>
  );
};


const mapStyle = [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#242f3e" // map color
      }
    ]
  },
  {
    featureType: 'poi',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }] // Turn off points of interest labels
  },
  {
    featureType: 'road',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }] // Turn off road labels
  },
  {
    featureType: 'all',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }] // Turn off all labels
  },
  {
    featureType: 'administrative',
    elementType: 'labels',
    stylers: [{ visibility: 'on' }] // Turn on administrative labels (city, state, country)
  },
  {
    featureType: 'administrative.locality',
    elementType: 'labels',
    stylers: [{ visibility: 'on' }] // Turn on locality (city) labels
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#000000",
        "visibility": "off"
      }
    ]
  },
];


export default MapCategory;
