import { TextField } from "@mui/material";
import Styles from './simpleMultilineField.module.css'

export const SimpleMultilineField = ({
  title,
  placeholder,
  value,
  setValue,
  type,
  fieldValid,
  setFieldValid,
  inputProps,
  fieldLabel,
}) => {
  return (
    <>
      <div className={Styles.fieldLabel}>{title}</div>

      <TextField
        value={value}
        fullWidth
        multiline
        rows={4}
        maxRows={4}
        minRows={4}
        size="medium"
        InputProps={{
          sx: {
            color: "#142030",
            marginTop: 1,
            backgroundColor: "#FFFFFF",
            boxSizing: "content-box",
            width: "auto",
            display: "flex",
            paddingTop: 4,
            paddingBottom: 4,
            "& ::placeholder": {
              color: "#8E96A8"
            },
          }
        }}
        FormHelperTextProps={{ style: { backgroundColor: 'transparent', padding: 0, margin: 0, marginTop: 4 } }}
        error={fieldValid.status === false}
        helperText={fieldValid.msg}
        onChange={
          (e) => setValue(e.target.value)
        }
      />

    </>
  )
}
