import { create } from 'zustand'

export const searchResultStore = create((set) => ({
  linkedInUserData: {},
  showArticleDetails: false,
  chkBxNotifyUser: false,
  saveSearch: false,
  monitorSearch: false,
  showProcessArticlesModal: { status: false, count: 0, isSameCount: false },
  openArticleDetail: { status: false, data: {} },
  processArticleInProgress: [],
  feedbackOptions1: [],
  profilePic1: "",
  processArticleDone: [],
  configurationData: {},
  ConfigurationLoading: false,
  searchSettings: {},
  filteredArticles: [],
  filteredArticles2: [],
  originalfilteredArticles: [],
  originalArticles2: [],
  isEnableFilter: false,
  isFromPopup: false,
  articleFilterData: undefined,
  personalInfo: {},
  aKAList: [],
  articleTypeCount: [],
  isFilterApplied: false,
  recentSearchId: undefined,
  totalArticle: 0,
  saveSearchVariables: 0,
  savedContactID: undefined,
  isShowPersonalInfo: true,
  selectedFilter: undefined,
  sortState: 2,
  showStrongMatchArticles: false,
  showWeakMatchArticles: false,
  filterAttributes: {
    "People": [],
    "Organizations": [],
    "Places": [],
    "Parties": [],
    "Attorneys": []
  },
  readFilter: false,
  unreadFilter: false,
  attributes: [],
  headerTags: [],
  saveRelevancyData: undefined,
  deleteRelevancyData: undefined,
  verified: true,
  partial: true,
  needsReview: true,
  refreshList: false,
  showHeader: true,
  showSaveConfirmationAlert: { status: false, msg: "" },
  modalFilterVisible: true,
  summaryPosition: 0,
  articlePosition: 0,
  hasData: false,
  searchSettingsRelevancy: undefined,
  metaData: {},
  isPdfGenerating: false,
  selectedMapArticle: undefined,
  openMapDashboard: false,
  selectedMapCategory: 0,
  mapFilteredArticles: [],
  openMetaSearch: false,
  selectedfilterIndex: -1,
  openFromMap: false,
  setOpenFromMap: (data) => set(() => ({ openFromMap: data })),
  setSelectedfilterIndex: (data) => set(() => ({ selectedfilterIndex: data })),
  setOpenMetaSearch: (data) => set(() => ({ openMetaSearch: data })),
  setMapFilteredArticles: (data) => set(() => ({ mapFilteredArticles: data })),
  setOpenMapDashboard: (data) => set(() => ({ openMapDashboard: data })),
  setSelectedMapCategory: (data) => set(() => ({ selectedMapCategory: data })),
  setSearchSettingsRelevancy: (data) => set(() => ({ searchSettingsRelevancy: data })),
  setLinkedInUserData: (data) => set(() => ({ linkedInUserData: data })),
  setArticleDetails: (data) => set(() => ({ showArticleDetails: data })),
  setChkBxNotifyUser: (data) => set(() => ({ chkBxNotifyUser: data })),
  setSaveSearch: (data) => set(() => ({ saveSearch: data })),
  setMonitorSearch: (data) => set(() => ({ monitorSearch: data })),
  setShowProcessArticlesModal: (data) => set(() => ({ showProcessArticlesModal: data })),
  setOpenArticleDetail: (data) => set(() => ({ openArticleDetail: data })),
  setProcessArticleInProgress: (data) => set(() => ({ processArticleInProgress: data })),
  setProcessArticleDone: (data) => set(() => ({ processArticleDone: data })),
  setFeedbackOptions1: (data) => set(() => ({ feedbackOptions1: data })),
  setProfilePic1: (data) => set(() => ({ profilePic1: data })),
  setConfigurationData: (data) => set(() => ({ configurationData: data})),
  setConfigurationLoading: (data) => set(() => ({ ConfigurationLoading: data})),
  setSearchSettings: (data) => set(() => ({ searchSettings: data})),
  setFilteredArticles: (data) => set(() => ({ filteredArticles: data})),
  setFilteredArticles2: (data) => set(() => ({ filteredArticles2: data})),
  setOriginalFilteredArticles: (data) => set(() => ({ originalfilteredArticles: data })),
  setOriginalArticles2: (data) => set(() => ({ originalArticles2: data })),
  setEnableFilter: (data) => set(() => ({ isEnableFilter: data})),
  setFromPopup: (data) => set(() => ({ isFromPopup: data})),
  setArticleFilterData: (data) => set(() => ({ articleFilterData: data})),
  setPersonalInfo: (data) => set(() => ({ personalInfo: data})),
  setAKAList: (data) => set(() => ({ aKAList: data})),
  setArticleTypeCount: (data) => set(() => ({ articleTypeCount: data})),
  setIsFilterApplied: (data) => set(() => ({ isFilterApplied: data})),
  setRecentSearchId: (data) => set(() => ({ recentSearchId: data})),
  setTotalArticle: (data) => set(() => ({ totalArticle: data})),
  setSaveSearchVariables: (data) => set(() => ({ saveSearchVariables: data})),
  setSavedContactID: (data) => set(() => ({ savedContactID: data})),
  setShowPersonalInfo: (data) => set(() => ({ isShowPersonalInfo: data})),
  setSelectedFilter: (data) => set(() => ({ selectedFilter: data})),
  setSortState: (data) => set(() => ({ sortState: data})),
  setShowStrongMatchArticles: (data) => set(() => ({ showStrongMatchArticles: data})),
  setShowWeakMatchArticles: (data) => set(() => ({ showWeakMatchArticles: data})),
  setFilterAttributes: (data) => set(() => ({ filterAttributes: data})),
  setReadFilter: (data) => set(() => ({ readFilter: data})),
  setUnreadFilter: (data) => set(() => ({ unreadFilter: data})),
  setAttributes: (data) => set(() => ({ attributes: data})),
  setHeaderTags: (data) => set(() => ({ headerTags: data})),
  setSaveRelevancyData: (data) => set(() => ({ saveRelevancyData: data })),
  setDeleteRelevancyData: (data) => set(() => ({ deleteRelevancyData: data })),
  setVerified: (data) => set(() => ({ verified: data})),
  setPartial: (data) => set(() => ({ partial: data})),
  setNeedsReview: (data) => set(() => ({ needsReview: data})),
  setRefreshList: (data) => set(() => ({ refreshList: data})),
  setShowHeader: (data) => set(() => ({ showHeader: data})),
  setShowSaveConfirmationAlert: (data) => set(() => ({ showSaveConfirmationAlert: data})),
  setModalFilterVisible: (data) => set(() => ({ modalFilterVisible: data})),
  setSummaryPosition: (data) => set(() => ({ summaryPosition: data})),
  setArticlePosition: (data) => set(() => ({ articlePosition: data})),
  setHasData: (data) => set(() => ({ hasData: data})),
  setMetaData: (data) => set(() => ({ metaData: data })),
  setIsPdfGenerating: (data) => set(() => ({ isPdfGenerating: data })),
  setSelectedMapArticle: (data) => set(() => ({ selectedMapArticle: data })),
  removeAll: () => set({ 
    configurationData: {},
    ConfigurationLoading: false,
    searchSettings: {},
    filteredArticles: [],
    filteredArticles2: [],
    originalfilteredArticles: [],
    originalArticles2: [],
    isEnableFilter: false,
    isFromPopup: false,
    articleFilterData: undefined,
    personalInfo: {},
    aKAList: [],
    articleTypeCount: [],
    isFilterApplied: false,
    recentSearchId: undefined,
    totalArticle: 0,
    saveSearchVariables: 0,
    savedContactID: undefined,
    isShowPersonalInfo: true,
    selectedFilter: undefined,
    sortState: 2,
    showStrongMatchArticles: false,
    showWeakMatchArticles: false,
    filterAttributes: {
      "People": [],
      "Organizations": [],
      "Places": [],
      "Parties": [],
      "Attorneys": []
    },
    readFilter: false,
    unreadFilter: false,
    attributes: [],
    headerTags: [],
    saveRelevancyData: undefined,
    deleteRelevancyData: undefined,
    verified: true,
    partial: true,
    needsReview: true,
    refreshList: false,
    showHeader: true,
    showSaveConfirmationAlert: { status: false, msg: "" },
    modalFilterVisible: true,
    summaryPosition: 0,
    articlePosition: 0,
    hasData: false,
    linkedInUserData: {},
    showArticleDetails: false,
    chkBxNotifyUser: false,
    saveSearch: false,
    monitorSearch: false,
    showProcessArticlesModal: { status: false, count: 0, isSameCount: false },
    openArticleDetail: { status: false, data: {} },
    processArticleInProgress: [],
    feedbackOptions1: [],
    profilePic1: "",
    searchSettingsRelevancy: undefined,
    metaData: {},
    isPdfGenerating: false,
    selectedMapArticle: undefined,
    openMapDashboard: false,
    selectedMapCategory: 0,
    mapFilteredArticles: [],
    openMetaSearch: false,
    selectedfilterIndex: -1,
    openFromMap: false,
  }),
}))


