import { useState, useEffect } from 'react';
import {
  GET_FULLTEXT,
  GET_NOTIFICATION_DETAILS,
  READ_NNOTIFICATION,
  SAVE_ARTICLE_HISTORY,
} from '../../queries/queries';
import { useLazyQuery, useMutation } from 'react-apollo';
import { useNavigate } from 'react-router-dom';
import parseSaveHistory from '../articleCardWithQuery/parseSaveHistoryData';

let selectRelevantArticle = null;
let selectRemoveNotification = null;
let contactDataID = null

const GetNotificationDetails = () => {

  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState();
  const [loadingDone, setLoadingDone] = useState(false);
  const [notificationId, setNotificationId] = useState();
  const [isFromDashboard, setFromDashboard] = useState(false);

  const [getFulltext, { loading: getFullTextLoading, data: getFullTextData, error: getFullTextError }] = useLazyQuery(GET_FULLTEXT, {
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (!getFullTextLoading && getFullTextData) {
      setLoadingDone(false)
      if (!!getFullTextData) {
        const newArticle = {
          ...selectedItem,
          article: {
            ...selectedItem?.article,
            fulltext: getFullTextData.getFulltxt.data || '',
          },
        };
        setSelectedItem(newArticle)
        gotoArticle(newArticle);
      }
    } else if (!getFullTextLoading && getFullTextError) {
      setLoadingDone(false)
    }
  }, [getFullTextLoading, getFullTextData, getFullTextError])

  const [get_article_details, { loading: articleLoading, data: articleData, error: articleError }] = useLazyQuery(GET_NOTIFICATION_DETAILS, {
    fetchPolicy: 'network-only'
  });

  const [saveArticleHistory] = useMutation(SAVE_ARTICLE_HISTORY, {
    onCompleted(data) {
    },
    onError(ee) {
    }
  })

  useEffect(() => {
    if (!articleLoading && articleData) {
      setSelectedItem(articleData.getNotificationDetails)
      if (!!articleData.getNotificationDetails.articleId) {
        getFulltext({
          variables: {
            articleId: articleData.getNotificationDetails.articleId,
          },
        });
        saveArticleHistory({
          variables: {
            data: parseSaveHistory(articleData.getNotificationDetails, null, contactDataID)
          }
        })
      } else {
        gotoArticle(articleData.getNotificationDetails);
      }
    } else if (!articleLoading && articleError) {
      setLoadingDone(false)
    }
  }, [articleLoading, articleData, articleError])

  const [readNotification] = useMutation(READ_NNOTIFICATION, {
    onCompleted(data) {
    },
    onError(dd) {
    },
  })

  const getArticleDetails = (query, notificationId, isFromDashboard, relevantArticle, removeNotification, contactId) => {
    contactDataID = contactId
    selectRelevantArticle = relevantArticle;
    selectRemoveNotification = removeNotification;
    setSelectedItem()
    setNotificationId(notificationId)
    setLoadingDone(true)
    setFromDashboard(isFromDashboard)
    get_article_details(query)
  };

  const gotoArticle = (item) => {
    callReadNotificationApi(notificationId);
    setLoadingDone(false);
    selectRemoveNotification?.(notificationId);
    navigate("/article", {
      state: {
        // articleCount: props.articleCount,
        newsItem: item,
        article: item.article,
        // articleRelevacnyData:props.articleRelevacnyData
        onBackPressNavigation: "Notification",
        // setRelevantArticle: selectRelevantArticle,
        // selectRemoveNotification: selectRemoveNotification,
      },
    });
  };

  const callReadNotificationApi = (notificationId) => {
    if (notificationId)
      readNotification({ variables: { notificationId: [notificationId] } });
  };

  return {
    loadingDone,
    getArticleDetails,
    callReadNotificationApi,
  };

}
export default GetNotificationDetails;