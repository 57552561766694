import * as React from "react"

const DropdownArrowGreen = (props) => {
  return (
    <svg
      width={14}
      height={8}
      fill="none"
      {...props}
    >
      <path
        d="M1.429 1.3 6.7 6.57a.412.412 0 0 0 .6 0l5.272-5.27"
        stroke={'#078167'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default DropdownArrowGreen
