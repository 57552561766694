const createStyle = () =>({
    label: {
      color: '#1A233B',
      fontFamily: "Proxima Nova",
      fontSize: 14,
      fontWeight: "600",
      letterSpacing: 0.01,
    },
  });

export default createStyle;