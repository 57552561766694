import {
  ButtonBase, Chip,
  Dialog, IconButton, InputAdornment, TextField
} from "@mui/material";
import React, { useState } from 'react';
import AddOrgsIcon from "../../images/svg/add-orgs-icon";
import CloseIcon from "../../images/svg/closeGreen";
import CloseIconGray from "../../images/svg/close_gray";
import OrgsIcon from "../../images/svg/orgs-icon";
import Styles from "./addOrgModal.module.css";
import { capitalizeCase } from "../../utils/utils";

const AddOrgModal = ({
  onRequestComplete,
  query,
  setShowAddOrgsModal,
  metaData
}) => {
  let maxShowItem = 6;
  let showMoreCount = 12;

  const [orgsArray, setOrgsArray] = useState([]);
  const [inputOrg, setInputOrg] = useState({});

  const [nameShowMore, setNameShowMore] = useState(false);
  const [nameShowMoreCount, setNameShowMoreCount] = useState(maxShowItem);

  const onShowMore = (size, less = false) => {
    if (less) {
      setNameShowMoreCount(maxShowItem);
      setNameShowMore(false);
    } else if (size > nameShowMoreCount) {
      if (size < nameShowMoreCount + showMoreCount)
        setNameShowMore(!nameShowMore);
      setNameShowMoreCount(nameShowMoreCount + showMoreCount); //add 50 tags on show more click if load all tags the UI stuck
    } else if (!nameShowMore) {
      setNameShowMore(!nameShowMore);
    } else if (nameShowMore) {
      setNameShowMoreCount(maxShowItem);
      setNameShowMore(!nameShowMore);
    }
  };
  const handleDelete = (chipToDelete: ChipData) => {
    setOrgsArray((orgs) => orgs.filter((org) => org.key !== chipToDelete.key));
  };

  const onBackdropPress = () => {
    setShowAddOrgsModal(false)
  }

  const onSkipBtnClick = () => {
    onRequestComplete(query)
    onBackdropPress()
  }

  const onRefineSearchBtnClick = () => {

    let company = orgsArray.map((item) => {
      return item.value
    }).join(';')

    let updatedMetaData = {
      ...metaData,
      Company: company
    }

    if (query?.variables?.fullname?.meta_data) {
      query.variables.fullname.meta_data = JSON.stringify(updatedMetaData)
    }

    onRequestComplete(query, orgsArray?.map(item => item.value))

    onBackdropPress()
  }

  return (
    <Dialog open={true} PaperProps={{
      style: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        margin: 0,
      }
    }}>
      <div className={Styles.container}>

        <ButtonBase
          className={Styles.crossButton}
          onClick={onBackdropPress}
        >
          <CloseIconGray className={Styles.closeIcon} />
        </ButtonBase>

        <h2 className={Styles.title}>
          Is your{" "}
          <span className={Styles.personName}>
            {capitalizeCase(metaData?.Person, true)}
          </span>{" "}
          professional experience associated with any organizations?
        </h2>

        <p className={Styles.txtAddOrg}>
          Add Organizations to pull their LinkedIn Profile
        </p>

        <TextField
          onKeyDown={(ev)=>{
            if(ev.key === 'Enter'){
              if (inputOrg && inputOrg.value?.length > 0) {
                setOrgsArray([inputOrg,...orgsArray]);
                setInputOrg({ value: "" });
              }
            }
          }}
          fullWidth
          variant="outlined"
          type="text"
          value={inputOrg.value}
          className={Styles["orgsInputField"]}
          InputProps={{
            sx: {
              color: "#142030",
              height: 48,
              backgroundColor: "#FFFFFF",
            },
            startAdornment: (
              <InputAdornment position="end">
                <OrgsIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    if (inputOrg && inputOrg.value?.length > 0) {
                      setOrgsArray([inputOrg,...orgsArray]);
                      setInputOrg({ value: "" });
                    }
                  }}
                >
                  <AddOrgsIcon />
                </IconButton>
              </InputAdornment>
            )
          }}
          onChange={
            (e) => {
              setInputOrg({
                key: Date.now(), value: e.target.value
              })
            }
          }
        />

        <div className={Styles.divChip}>
          {orgsArray &&
            orgsArray.slice(0, nameShowMoreCount).map((item, index) => {
              return (
                <Chip
                  label={item.value}
                  className={Styles["chips"]}
                  onDelete={() => handleDelete(item)}
                  deleteIcon={<CloseIcon style={{ width: 10, height: 10 }} />}
                  sx={{
                    color: "#078167",
                    fontFamily: "Proxima Nova",
                    fontSize: 14,
                    fontStyle: "normal",
                    fontWeight: 400,
                    textTransform: "capitalize",
                  }}
                />
              );
            })}
        </div>
        {orgsArray?.length > maxShowItem && <>
        <span
          className={Styles.showMore}
          onClick={() => onShowMore(orgsArray?.length)}
        >
          {nameShowMore || orgsArray?.length < nameShowMoreCount
            ? ""
            : "Show More (" + (orgsArray?.length - nameShowMoreCount) + ")"}
        </span>
        <span
          className={Styles.showLess}
          onClick={() => onShowMore(orgsArray?.length, true)}
        >
          {(nameShowMore || orgsArray?.length < nameShowMoreCount) ? "Show Less"  : ""}
        </span>
        </>}
        <ButtonBase
          className={`${Styles["button"]} ${
            orgsArray.length == 0 ? Styles["btnGray"] : Styles["btnGreen"]
          }`}
          onClick={orgsArray.length == 0 ? undefined : onRefineSearchBtnClick}
          fullWidth
        >
          <div
            className={`${Styles["btnApplyTxt"]} ${Styles["btnApplyTxtWhite"]}`}
          >
            {"Refine Search"}
          </div>
        </ButtonBase>
        <ButtonBase
          className={`${Styles["button"]} ${Styles["btnWhite"]}`}
          onClick={onSkipBtnClick}
          fullWidth
        >
          <div
            className={`${Styles["btnApplyTxt"]} ${Styles["btnApplyTxtGreen"]}`}
          >
            {"Skip"}
          </div>
        </ButtonBase>
      </div>
    </Dialog >
  )
}

export default AddOrgModal