// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".articleCategoriesList_gridContainerMain__s3ToB {\n  margin-top: 14px;\n  border-color: #eff1f5;\n  margin-bottom: 20px;\n}\n.articleCategoriesList_gridContainer__7sijj {\n  white-space: nowrap;\n  flex-flow: row wrap;\n  display: flex;\n  align-items: flex-start;\n}\n", "",{"version":3,"sources":["webpack://./src/web/components/PersonInfo/articleCategoriesList.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,qBAAqB;EACrB,mBAAmB;AACrB;AACA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,aAAa;EACb,uBAAuB;AACzB","sourcesContent":[".gridContainerMain {\n  margin-top: 14px;\n  border-color: #eff1f5;\n  margin-bottom: 20px;\n}\n.gridContainer {\n  white-space: nowrap;\n  flex-flow: row wrap;\n  display: flex;\n  align-items: flex-start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gridContainerMain": "articleCategoriesList_gridContainerMain__s3ToB",
	"gridContainer": "articleCategoriesList_gridContainer__7sijj"
};
export default ___CSS_LOADER_EXPORT___;
