import React from 'react';
import PotentialMatchesCard from './potentialMatchesCard';
import StylesMobile from './savedSearchMatch.module.css'
import StylesWeb from './savedSearchMatchWeb.module.css'

const SavedSearchMatch = (props) => {
  const { onPotentialSelected, potentialMatch, showAdvancedSearch, isDesktop, selectedPotentialIndex = -1 } = props
  const Styles = isDesktop ? StylesWeb : StylesMobile 
  return (
    <div className={Styles.mainBg}>
      <div className={Styles.searchTitleMatch}>
        Potential{' '}
        {potentialMatch?.length > 1 ? `Matches` : `Match`}
      </div>
        <ui className={Styles.mainList}>
          {potentialMatch.map((item, index)=>
          <PotentialMatchesCard
            isSelected={index == selectedPotentialIndex}
            isDesktop={isDesktop}
            item={item}
            index={index}
            onPotentialSelected={(item)=>onPotentialSelected(item, index)}
            potentialMatch={potentialMatch}
            mStyle={props.style}/>
            )}
        </ui>
    </div>
  );
}

export default SavedSearchMatch
