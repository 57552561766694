import { Backdrop, CircularProgress, Modal } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import MapCategory from "./MapCategory";
import CloseIconGray from "../../images/svg/close_gray";
import Styles from "./mapView.module.css";
import CheckFilter from "./checkFilter/checkFilter";
import ArticleCardWithQuery from "../articleCardWithQuery/articleCardWithQuery";
import { useNavigate } from "react-router-dom";
import { savedSearchesStore } from "../../store/savedSearchesStore";
import { searchResultStore } from "../../store/searchResultStore";
import SaveArticleRelevancy from "../articleList/saveArticleRelevancy";
import { SAVE_ARTICLE_HISTORY, UPDATE_FERRET_USER } from "../../queries/queries";
import { useMutation } from "react-apollo";
import { userInfo, setUserInfo } from '../../root-navigation';
import { UserContext } from "../../contexts/userContext";
import { appStore } from "../../store/appStore";
import ScrollButton from "./scrollButton";
import { checkParbFunctionVal } from "../../utils/utils";
import parseSaveHistory from "../articleCardWithQuery/parseSaveHistoryData";
import Variables from "../../utils/Variables";

const MapView = (props) => {

  const articleDivRef = useRef()

  const dimensions = appStore((state) => state.dimensions)
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const [mapHeight, setMapHeight] = useState(window.innerHeight - 220);


  const { reloadScreenData, articleSource, searchTerm, savedContactID, recentSearchId, contactData, setRefreshList, configData, configLoading } = props
  const { setOpenMapView, articles = [], isWeb, onFilterCompleted, articleFilterData, isFromSavedSearch, articleDetails, onArticleTypeClick, setOpenMapDashboard } = props
  const navigate = useNavigate();
  const setOldPageName = savedSearchesStore((state) => state.setOldPageName);
  const setSelectedFilter = searchResultStore((state) => state.setSelectedFilter);
  const setSelectedMapArticle = searchResultStore((state) => state.setSelectedMapArticle);

  const selectedMapCategory = searchResultStore((state) => state.selectedMapCategory);
  const setSelectedMapCategory = searchResultStore((state) => state.setSelectedMapCategory);
  const openFromMap = searchResultStore((state) => state.openFromMap);
  const setOpenFromMap = searchResultStore((state) => state.setOpenFromMap);

  const [showLoader, setShowLoader] = useState(false);
  const { relevantArticle } = SaveArticleRelevancy(reloadScreenData, setShowLoader, articleSource, searchTerm, savedContactID, recentSearchId, contactData, setRefreshList);
  const [mapCategoty, setMapCategoty] = useState([
    {
      categoty: "Assets",
      count: 0,
      status: true,
    },
    {
      categoty: "Corporate Records",
      count: 0,
      status: false,
    },
    {
      categoty: "Court Records",
      count: 0,
      status: false,
    },
  ]);

  const [filteredArticles, setFilteredArticles] = useState()

  const handleCloseMapView = () => {
    setOpenMapView(false);
    
  };

  const setArtcileCount = (mMapCategoty) => {
    if (articleFilterData && articleFilterData.length) {
      articleFilterData.forEach(item => {
        if (item.category == 'Assets') {
          mMapCategoty[0].count = item.count;
        } else if (item.category == 'Corporate Records') {
          mMapCategoty[1].count = item.count;
        } else if (item.category == 'Court Records') {
          mMapCategoty[2].count = item.count;
        }
      })
      setMapCategoty(mMapCategoty)
    }
  }
  useEffect(() => {
    let mMapCategoty = [...mapCategoty];
    setArtcileCount(mMapCategoty);
    onCategoryChange(selectedMapCategory > 0 ? selectedMapCategory : mMapCategoty[0].count > 0 ? 0 : mMapCategoty[1].count > 0 ? 1 : mMapCategoty[2].count > 0 ? 2 : 0)

    const handleResize = () => {
      setTimeout(function () {
        let height = 0
        if (window?.innerHeight < 450) {
          height = window?.innerHeight - 150
        } else {
          height = window?.innerHeight - 220
        }
        setMapHeight(height);
      }, 500);
    };

    handleResize()
    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize); // Listen for screen rotation

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize); // Remove listener on component unmount
    };
  }, []);

  useEffect(() => {
    let mMapCategoty = [...mapCategoty];
    setArtcileCount(mMapCategoty);
  }, [articleFilterData]);

  const onCategoryChange = (selectIndex) => {
    setSelectedMapCategory(selectIndex)
    setMapCategoty(mapCategoty.map((item, index) => ({ ...item, status: index == selectIndex ? true : false })))
    if (articleFilterData && articleFilterData.length) {
      let filter = {
        title: "Type of Article",
        filters: articleFilterData.map((item) => {
          return {
            label: item.category,
            itemType: item.itemTypes,
            riskCode: item.riskCodes,
            count: item.count,
            status: item.category == mapCategoty[selectIndex].categoty ? true : false,
          };
        }),
      };
      onFilterCompleted([filter]);
    }
  }

  useEffect(() => {
    setFilteredArticles(articles);

    setTimeout(() => {


      const container = articleDivRef.current;

      if (container) {
        const handleScroll = () => {
          setCanScrollLeft(container.scrollLeft > 0);
          setCanScrollRight(container.scrollLeft + container.clientWidth < container.scrollWidth - 10);
        };

        container.addEventListener('scroll', handleScroll);

        handleScroll(); // Check initial state

        return () => {
          container.removeEventListener('scroll', handleScroll);
        };
      }
    }, 200)
  }, [articles]);

  const [saveArticleHistory] = useMutation(SAVE_ARTICLE_HISTORY, {
    onCompleted(data) {
    },
    onError(ee) {
    }
  })

  const articleCardClick = (item, selected, index) => {
    {
      const selectedFilter = mapCategoty?.[selectedMapCategory]?.categoty

      if (item.itemType !== "Unicourt") {
        let displayPostArticleRelevanceBot = false
        if (configData && !configLoading) {
          displayPostArticleRelevanceBot = checkParbFunctionVal(configData, configLoading, item, false)
        }
        saveArticleHistory({
          variables: {
            data: parseSaveHistory(item, recentSearchId, savedContactID, displayPostArticleRelevanceBot, item?.potentialMatchId)
          }
        })
      }

      if (isWeb) {
        if (window.location.pathname == '/articles') {
          onArticleTypeClick?.({ category: selectedFilter })
          setSelectedFilter(selectedFilter);
          handleCloseMapView();
          const _index = filteredArticles.findIndex(article => article.articleId === item.articleId);
          setSelectedMapArticle({ ...item, selected: selected, index: _index })
          setOpenFromMap(true)
        } else {
          setSelectedFilter(selectedFilter);
          if (isFromSavedSearch) {
            setOldPageName('/articles')
          } else {
            setOldPageName('')
          }
          const _index = filteredArticles.findIndex(article => article.articleId === item.articleId);
          setSelectedMapArticle({ ...item, selected: selected, index: _index })
          navigate("/articles", { state: articleDetails });
          setOpenFromMap(true)
        }
      } else {
        handleCloseMapView();
        onArticleTypeClick?.({ category: selectedFilter })
        setSelectedMapArticle({ ...item, selected: selected, index: index })
      }
    }
  }


  const dontShow = () => {
    const requestData = {
      relevancySortFilterTooltip: true
    }
    userUpdaterMutation({
      variables: requestData,
    });
  }

  const { setUser } = useContext(UserContext);
  const [userUpdaterMutation, { loading: loadingDontShow }] =
    useMutation(UPDATE_FERRET_USER,
      {
        fetchPolicy: 'no-cache',
        onCompleted: ({ updateUser }) => {
          let u = setUserInfo(updateUser);
          setUser({ ...u });
        }
      });

  return (
    <Modal backdropOpacity={0.3} open={true}>
      <div className={Styles.containerData}>
        <div className={Styles.containerDataText}>
          <MapCategory
            height={mapHeight}
            filteredArticles={filteredArticles}
            articleCardClick={articleCardClick}
          />
        </div>

        <div
          className={Styles.crossButton}
          onClick={() => {
            handleCloseMapView();
            setOpenMapDashboard?.(false)
            setSelectedMapCategory(0)
          }}
        >
          <CloseIconGray color="#fff" className={Styles.closeIcon} />
        </div>

        <div style={window.innerHeight < 450 ? { overflowY: 'scroll', height: 150 } : {}}>
          <div className={Styles.bottomView}>
            <div className={Styles.leftCatergory}>
              {mapCategoty.map((item, index) => (
                <CheckFilter
                  selectedMapCategory={selectedMapCategory}
                  index={index}
                  count={item.count}
                  label={item.categoty}
                  status={item.status}
                  iconType="map"
                  setStatus={() => {
                    if (item.count > 0)
                      onCategoryChange(index)
                  }}
                />
              ))}
            </div>

            <div className={Styles.articleCardContainerDiv}>

              {(dimensions.isDesktop == true) &&
                <ScrollButton
                  direction="left"
                  canScroll={canScrollLeft}
                  articleDivRef={articleDivRef}
                  onClick={() => {
                    articleDivRef?.current.scrollBy({
                      left: -200,
                      behavior: 'smooth'
                    });
                  }}
                />
              }

              <div
                ref={articleDivRef}
                className={Styles.articleCardDiv}
                style={{ width: "100%", overflowX: 'auto', overflowY: 'hidden', display: 'flex', height: 200, paddingRight: 20 }}>
                {filteredArticles?.map((item, index) => {
                  return (
                    <ArticleCardWithQuery
                      isWeb={isWeb}
                      height={166}
                      isMap={true}
                      key={index + "_" + item.articleId}
                      newsItem={item}
                      {...props}
                      onPress={(selected) => { articleCardClick(item, selected, index) }}
                      setRelevantArticle={relevantArticle}
                      dontShow={dontShow}
                      setShowLoader={setShowLoader}
                    />
                  );
                })}
              </div>

              {(dimensions.isDesktop == true) &&
                <ScrollButton
                  direction="right"
                  canScroll={canScrollRight}
                  articleDivRef={articleDivRef}
                  onClick={() => {
                    articleDivRef?.current.scrollBy({
                      left: 200,
                      behavior: 'smooth'
                    });
                  }}
                />
              }
            </div>
            {(showLoader || loadingDontShow) && (
              <Backdrop
                sx={{ backgroundColor: "#00000000", zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: "#00000000" }}
                open={true}
              >
                <CircularProgress color="primary" />
              </Backdrop>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MapView;
