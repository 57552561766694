import React from "react";
import Styles from "./contactsProcessed.module.css";
import { getFilterImage } from "../../images/filterColorIcon/getFilterImage";

const ContactsProcessedRows = ({ item }) => {
  return (
    <div className={Styles.rows}>
      <div className={Styles.filterRows}>
        {getFilterImage(item.title, true, null, null, {
          transform: "scale(.8)",
        })}
        <span className={Styles.filterTxt}>{item.title}</span>
      </div>
      {item?.total && <span className={Styles.filterCount}>{item?.total}</span>}
    </div>
  );
};

export default ContactsProcessedRows;
