import {useMutation} from '@apollo/react-hooks';
import {REFINE_SEARCH} from '../../queries/queries';
import Variables from '../../utils/Variables';

const useRefineSearch = () => {
  const [refine_search, { data, loading, error }] = useMutation(REFINE_SEARCH, {
    onError: (e) => {
      console.log(e)
    }
  });
  const refineSearch = query => {
    console.log('refine_search====',JSON.stringify(query))
    refine_search(query);
  }

  const getResultTypeFromRefineSearch = () => {
    if (!loading && !data) return 'Not-available';
    if (data?.hasOwnProperty('refine_search')) {
      const {
        refine_search: {result_type},
      } = data;
      if (result_type === null && !!getArticleDetailsFromRefineSearch())
        return 'articles';
      else return result_type;
    } else return 'Not-available';
  };

  const getQuestionFromRefineSearch = () => {
    if (loading) return {};
    return data?.refine_search.data;
  };

  const getArticleDetailsFromRefineSearch = () => {
    if (
      data?.hasOwnProperty('refine_search') &&
      data?.refine_search?.hasOwnProperty('article_details')
    ) {
      const {
        refine_search: {article_details: articleDetails = []},
      } = data;
      return articleDetails;
    } else return [];
  };

  const getArticleFilterData = () => {
    if (
      data?.hasOwnProperty('refine_search') &&
      data?.refine_search?.hasOwnProperty('filterData')
    ) {
      const {
        refine_search: {filterData: filterData = []},
      } = data;
      return filterData?.filter(item=> item.count);
    } else return [];
  };

  const getCategoryFilterData = () => {
    if (
      data?.hasOwnProperty('refine_search') &&
      data?.refine_search?.hasOwnProperty('categoryFilterData')
    ) {
      const {
        refine_search: {categoryFilterData: categoryFilterData = {}},
      } = data;
      return categoryFilterData;
    } else return [];
  };

  const getAKAList = () => {
    if (
      data?.hasOwnProperty('refine_search') &&
      data?.refine_search?.hasOwnProperty('akaList')
    ) {
      const {
        refine_search: {akaList: akaList = []},
      } = data;
      return akaList?.filter((val,id,array) => array.indexOf(val) == id)
    } else return [];
  };

  const getPersonalInfo = () => {
    if (
      data?.hasOwnProperty('refine_search') &&
      data?.refine_search?.hasOwnProperty('summary')
    ) {
      const {
        refine_search: {summary: summary = null},
      } = data;

      try {
        let dataCategoriesSorted = summary.dataCategories.sort(
          (a, b) => b.score - a.score
        )

        let emptyCategoriesSorted = summary.emptyCategories.sort(
          (a, b) => b.score - a.score
        )

        let updatedSummary = { ...summary, dataCategories: dataCategoriesSorted, emptyCategories: emptyCategoriesSorted }

        return updatedSummary;
      } catch (e) {
        console.log("error in refine search summary", e)
        return summary;
      }

    } else return
  };

  const getReadArticlesIds = () => {
    if (
      data?.hasOwnProperty('refine_search') &&
      data?.refine_search?.hasOwnProperty('readArticles')
    ) {
      const {
        refine_search: {readArticles: readArticles = []},
      } = data;
      return readArticles.map((item) => item.articleId);
    } else return
  };

  const getArticleTypeCount = () => {
    if (
      data?.hasOwnProperty('refine_search') &&
      data?.refine_search?.hasOwnProperty('articleTypeCount')
    ) {
      const {
        refine_search: {articleTypeCount: articleTypeCount = {}},
      } = data;
      return articleTypeCount;
    } else return {};
  };

  const getPotentialMatchId = () => {
    if (
      data?.hasOwnProperty('refine_search') &&
      data?.refine_search?.hasOwnProperty('potentialMatchId')
    ) {
      const {
        refine_search: { potentialMatchId: potentialMatchId = null },
      } = data;
      return potentialMatchId;
    } else return {};
  };

  const parseDate = (date) =>{
    let startDate = null
    let endDate = null
    try{
      let spDate =  date?.split('·')?.[0]?.split('-')
      startDate = spDate?.length > 0 ? spDate[0]?.trim() : null
      endDate = spDate?.length > 1 ? spDate[1]?.trim() : null
    }catch(e){console.log(e)}
    return {startDate: startDate, endDate: endDate}
  }

  const getLinkedInData = () => {
    if (
      data?.hasOwnProperty('refine_search') &&
      data?.refine_search?.hasOwnProperty('linkedinProfile')
    ) {
      const {
        refine_search: { linkedinProfile: linkedinProfile = null },
      } = data;
        if(linkedinProfile){
          linkedinProfile.education =linkedinProfile.education?.map(item=>{
            let dates = parseDate(item.dates)
            return {...item, ...dates}
          })
          linkedinProfile.experience = linkedinProfile.experience?.map(item=>{
            let dates = parseDate(item.dates)
            return {...item, ...dates}
          })
        }
      return linkedinProfile;
    } else return {};
  };

  const getContactId = () => {
    if (
      data?.hasOwnProperty('refine_search') &&
      data?.refine_search?.hasOwnProperty('contactId')
    ) {
      const {
        refine_search: { contactId: contactId = null },
      } = data;
      return contactId;
    } else return {};
  };

  const getSearchId = () => {
    if (
      data?.hasOwnProperty("refine_search") &&
      data?.refine_search?.hasOwnProperty("searchIds")
    ) {
      const {
        refine_search: { searchIds: searchIds = null },
      } = data;
      return searchIds;
    } else return {};
  };

  const getFeedbackOptions = () => {
    if (
      data?.hasOwnProperty("refine_search") &&
      data?.refine_search?.hasOwnProperty("feedbackOptions")
    ) {
      const {
        refine_search: { feedbackOptions: feedbackOptions = null },
      } = data;
      return feedbackOptions;
    } else return {};
  };

  const getProfilePic = () => {
    if (
      data?.hasOwnProperty("refine_search") &&
      data?.refine_search?.hasOwnProperty("profilePic")
    ) {
      const {
        refine_search: { profilePic: profilePic = null },
      } = data;
      return profilePic;
    } else return {};
  };


  const getSearchedMetaData = (initialQuestions, initialSearchString, separator= Variables.metaDataSeparator) => {
    let saveSearchdata = {}
    initialQuestions.forEach(item => {
      if(item.identifier === 'ask_type' && item.answered === true){
        const key_type = item?.answers.filter(values => { return values.status; })[0].value;
        saveSearchdata[key_type] = initialSearchString
      }else if(item.answered === true){
        let value;
        item.answers.filter(answer => answer.status).forEach(item => {
          value = value ? value + separator + item.value : item.value
        });
        saveSearchdata[item.identifier] = value
      }else if(item.identifier === 'form' && item.answered === false){
        item?.answers.forEach(answerItem=>{
          saveSearchdata[answerItem.label] = answerItem.value;
        })
      }
    })
    return saveSearchdata;
  };

  const resultTypes = {
    QUESTION: 'question',
    LAST_QUESTION: 'last_question',
    ARTICLES: 'articles',
  };


  const mapSingleArticle = (article, searchName, readArticles) => {
    const newArticle = {
      ...article,
      article: {
        details: null,
        imageURL: null,
        itemtype: article.itemType,
        pageURL: article.url,
        source: null,
        summary: article.summary,
        timestamp: null,
        title: article.title,
      },
      isRead: readArticles?.includes(article.articleId) ?? true,
      category: article.articleType,
      parentCategory:article.parentCategory,
      fullName: article?.fullName && article?.fullName !== '' && article?.fullName !== null ? article.fullName : (searchName),
      imageURL: null,
      itemtype: article.itemType,
      matchType: article.matchType,
      pageURL: article.url,
      riskCategory: article.riskRanking,
      riskCode: article.riskCode ?? null,
      source: null,
      terms: null,
      title: article.title,
      articleId: article.articleId,
      attributes: article?.attributes,
      date: article.date,
    };
    return newArticle;
  };

  return {
    refineSearch,
    data,
    loading,
    error,
    resultTypes,
    getResultTypeFromRefineSearch,
    getQuestionFromRefineSearch,
    getArticleDetailsFromRefineSearch,
    getSearchedMetaData,
    getArticleFilterData,
    getPersonalInfo,
    getArticleTypeCount,
    mapSingleArticle,
    getReadArticlesIds,
    getAKAList,
    getCategoryFilterData,
    getPotentialMatchId,
    getContactId,
    getSearchId,
    getLinkedInData,
    getFeedbackOptions,
    getProfilePic,
  };
};

export default useRefineSearch;
