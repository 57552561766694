
import RemoveAdvanceSearch from '../utils/removeAdvanceSearch'
const getMetaFromContacts = (newsItem) => {

  let metadata = {}
  let requestMetadata = {}
  let searchType = newsItem.searchType
  let searchTerm = `${newsItem.name} ${newsItem.lastName != null ? newsItem.lastName : ''}`
  let searchContext = newsItem.searchContext
  let tahoeId = newsItem.tahoeId
  let tracerQueryId = newsItem.tracerQueryId
  let akaList = {}

  try {

    akaList = newsItem.akaList?.map((item) => {
      return { param: item, aka: true };
    })

    if (newsItem?.name != newsItem?.company) {
      metadata.Person = `${newsItem.name} ${newsItem.lastName != null ? newsItem.lastName : ''}`
      requestMetadata.Person = `${newsItem.name} ${newsItem.lastName != null ? newsItem.lastName : ''}`
    }

    if (newsItem?.company) {
      metadata.Company = newsItem.company
      requestMetadata.Company = newsItem.company
    }

    if (newsItem?.postalAddresses && newsItem.postalAddresses?.length > 0) {

      if (newsItem?.postalAddresses[0]?.city) {
        requestMetadata.Place = newsItem.postalAddresses[0].city
        metadata.Place = RemoveAdvanceSearch(searchContext, newsItem.postalAddresses[0].city, true)
      }

      if (newsItem?.postalAddresses[0]?.region) {
        metadata.Place = metadata?.Place ? metadata?.Place + ", " + newsItem.postalAddresses[0].region : newsItem.postalAddresses[0].region
        requestMetadata.Place = requestMetadata?.Place ? requestMetadata?.Place + ", " + newsItem.postalAddresses[0].region : newsItem.postalAddresses[0].region
      }

      if (newsItem?.postalAddresses[0]?.country) {
        metadata.Place = metadata?.Place ? metadata?.Place + ", " + newsItem.postalAddresses[0].country : newsItem.postalAddresses[0].country
        requestMetadata.Place = requestMetadata?.Place ? requestMetadata?.Place + ", " + newsItem.postalAddresses[0].country : newsItem.postalAddresses[0].country
      }

    }

    if (newsItem?.metaData?.Company?.length) {
      metadata.Company = (metadata.Company && metadata.Company != '') ? metadata.Company + ';' + newsItem?.metaData?.Company?.join(';')?.toString() : newsItem?.metaData?.Company?.join(';')?.toString()
      requestMetadata.Company = (requestMetadata.Company && requestMetadata.Company != '') ? requestMetadata.Company + ';' + newsItem?.metaData?.Company?.join(';')?.toString() : newsItem?.metaData?.Company?.join(';')?.toString()
    }
    if (newsItem?.metaData?.Location?.length) {
      metadata.Place = (metadata.Place && metadata.Place != '') ? metadata.Place + ';' + newsItem?.metaData?.Location?.join(';')?.toString() : newsItem?.metaData?.Location?.join(';')?.toString()
      requestMetadata.Place = (requestMetadata.Place && requestMetadata.Place != '') ? requestMetadata.Place + ';' + newsItem?.metaData?.Location?.join(';')?.toString() : newsItem?.metaData?.Location?.join(';')?.toString()
    }

    let mPlace = ''
    if (searchContext?.city && searchContext?.city != '')
      mPlace = searchContext.city
    if (searchContext?.state && searchContext?.state != '')
      mPlace = mPlace != '' ? mPlace + ', ' + searchContext.state : searchContext.state
    if (searchContext?.country && searchContext?.country != '')
      mPlace = mPlace != '' ? mPlace + ', ' + searchContext.country : searchContext.country

    if (mPlace && mPlace != '') {
      requestMetadata.Place = (requestMetadata.Place && requestMetadata.Place != '') ? requestMetadata.Place + ';' + mPlace : mPlace
    }

    if (searchContext?.phone && searchContext?.phone != '') {
      requestMetadata.Phone = searchContext?.phone
    }

    if (searchType == null || searchType == '')
      searchType = requestMetadata.Person && requestMetadata.Person != '' ? 'PERSON' : requestMetadata?.Company?.toLowerCase() == searchTerm?.toLowerCase() ? 'COMPANY' : requestMetadata?.Location?.toLowerCase() == searchTerm?.toLowerCase() ? 'LOCATION' : requestMetadata?.Place?.toLowerCase() == searchTerm?.toLowerCase() ? 'PLACE' : requestMetadata?.Nationality?.toLowerCase() == searchTerm?.toLowerCase() ? 'PLACE' : "PERSON"

  } catch (e) { console.log('e-----', e) }

  return {
    metadata,
    searchType,
    searchTerm,
    searchContext,
    tahoeId,
    tracerQueryId,
    requestMetadata,
    akaList
  }

}
export default getMetaFromContacts