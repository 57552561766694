import React from 'react'
import createStyle from './styles'

const PlainTextArticle = (props) => {
  const { content } = props
  const style = createStyle()
  return content?.length > 0 ? (
    <div>
      <span style={style.container}>{content}</span>
    </div>
  ) : (<></>)
}

export default PlainTextArticle