import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HttpLink, ApolloClient, InMemoryCache, split } from "@apollo/client";
import { ApolloProvider } from 'react-apollo';
import { fromPromise } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { setContext } from 'apollo-link-context';
import { getMainDefinition } from '@apollo/client/utilities';
import {
  getToken,
  getRefreshToken,
  saveToken,
  saveRefreshToken,
  getSubscriptionURL,
} from './utils/safe-storage';
import { logout } from './utils/auth';
import { REFRESH_SESSION } from './queries/queries';
import { getBaseUrlFor } from './utils/cloud-config';
import { WebSocketLink } from '@apollo/client/link/ws';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import Variables from './utils/Variables';

const graphqlFetch = (uri, options) => {
  const { operationName } = JSON.parse(options.body);
  const baseUrl = getBaseUrlFor(operationName);
  return fetch(`${baseUrl}/graphql`, options);
};

const link = new HttpLink({
  fetch: graphqlFetch,
});

const getNewToken = async () => {
  const refresh_token = getRefreshToken();
  return client.mutate({ mutation: REFRESH_SESSION, fetchPolicy: 'no-cache', variables: { refreshToken: refresh_token } }).then((response) => {
    // extract your accessToken from your response data and return it
    const { accessToken, refreshToken } = response.data.refreshSession;
    saveToken(accessToken);
    saveRefreshToken(refreshToken);
    return accessToken;
  });
};

window.onbeforeunload = () => {
  localStorage.setItem("reload", true);
}

let retrySession = 0;

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors && operation && operation?.operationName !== 'refreshSession') {
      for (let err of graphQLErrors) {
        switch (err.statusCode) {
          case 401 || 403:
            return fromPromise(
              getNewToken().catch((error) => {
                logout();
                // Handle token refresh errors e.g clear stored tokens, redirect to login
                return forward;
              })
            )
              .filter((value) => Boolean(value))
              .flatMap((accessToken) => {
                const oldHeaders = operation.getContext().headers;
                // modify the operation context with a new token
                operation.setContext({
                  headers: {
                    ...oldHeaders,
                    authorization: `Bearer ${accessToken}`,
                  },
                });
                // retry the request, returning the new observable
                return forward(operation);
              });
        }
      }
    }
  }
);

const authLink = setContext(async (operation, { headers }) => {
  const operationName =
    operation?.query?.definitions?.[0].selectionSet?.selections?.[0].name
      ?.value ?? '';

  const token = getToken();
  if (!token || loginOperations.includes(operationName)) {
    return {
      ...headers,
    };
  }
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  };
});

const wsLink = new WebSocketLink({
  //this is the uri for the back end local environment
  // uri: `ws://0.0.0.0:4004/subscriptions`,
  uri: process.env.REACT_APP_DEFAULT_SUBSCRIPTION_URL,
  options: {
    timeout: 120000,
    lazy: true,
    reconnect: true,
    minTimeout: 120000,
    inactivityTimeout: 120000,
    connectionParams: async () => {
      const token = getToken();

      return {
        authorization: `Bearer ${token}`,
      };
    },
    connectionCallback: (error) => {
      console.log("socket error", error)
    }
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind == 'OperationDefinition' &&
      definition.operation == 'subscription'
    );
  },
  wsLink,
  //TODO:
  //try to add the errorLink here PLACE 1
  authLink.concat(errorLink.concat(link))
  // authLink.concat(link),
);

const loginOperations = [
  'authenticateGoogleUser',
  'authenticateLinkedinUser',
  'authenticateAppleUser',
  'signUpEmail',
  'signInEmail',
];

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
  },
  query: {
    fetchPolicy: 'network-only',
  },
  mutate: {
    fetchPolicy: 'network-only',
  },
};


const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache({
    addTypename: false
  }),
  defaultOptions: defaultOptions
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ApolloProvider client={client}>
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </ApolloProvider>

);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
