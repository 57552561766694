export const xml = `<?xml version="1.0" encoding="UTF-8"?>
<svg width="355px" height="180px" viewBox="0 0 355 180" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>3EA39A1C-C9A4-4F8C-8952-DADD2983ED9E</title>
    <defs>
        <polygon id="path-1" points="0 0.0835163973 1.48742635 0.0835163973 1.48742635 11.19122 0 11.19122"></polygon>
        <polygon id="path-3" points="0.111286796 0.142750795 78.7952014 0.142750795 78.7952014 160.055995 0.111286796 160.055995"></polygon>
        <polygon id="path-5" points="0 159.913267 79.4792014 159.913267 79.4792014 0 0 0"></polygon>
        <polygon id="path-7" points="0 0 14.9631366 0 14.9631366 9.77469107 0 9.77469107"></polygon>
        <polygon id="path-9" points="0 0 14.9632359 0 14.9632359 9.77469107 0 9.77469107"></polygon>
    </defs>
    <g id="Sign-up" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Terms-of-service" transform="translate(-10.000000, -176.000000)">
            <g id="-g-Illustration" transform="translate(10.000000, 176.000000)">
                <rect id="Content-box" x="0" y="0" width="355" height="180"></rect>
                <path d="M263.5,69.5 C259.623765,101.835383 208.5,111.865845 196.892,110.041845 C185.283999,108.217845 107.930506,140.313632 105.148148,120.822856 C102.36579,101.33208 126.778744,98.1779206 122.373214,81.1425721 C117.967684,64.1072235 95.3068254,53.0829066 105.148148,39.5 C115.648148,25.0079928 150.810922,22.5 181.200402,31 C211.589882,39.5 267.376235,37.1646172 263.5,69.5 Z" id="Fill-1" fill="#D8EFE8"></path>
                <g id="Bg-things" transform="translate(73.000000, 20.000000)">
                    <ellipse id="Oval-11" fill="#48D2A0" opacity="0.162430037" cx="75.8293651" cy="4.65689863" rx="3.82936508" ry="3.82874864"></ellipse>
                    <ellipse id="Oval-11" fill="#48D2A0" opacity="0.170184235" cx="176.297619" cy="12.1253992" rx="2.29761905" ry="2.29724919"></ellipse>
                    <ellipse id="Oval-11" fill="#48D2A0" opacity="0.301364272" cx="33.5740741" cy="115.401649" rx="3.57407407" ry="3.57349873"></ellipse>
                    <g id="Group-23" opacity="0.364272388" transform="translate(8.500000, 14.828150) rotate(-120.000000) translate(-8.500000, -14.828150) translate(3.000000, 8.828150)">
                        <g id="Group" transform="translate(-0.000000, -0.000000)" stroke="#48D2A0" stroke-linecap="round" stroke-width="5.25">
                            <line x1="1.31237055e-12" y1="5.27110798" x2="10.0783379" y2="5.27110798" id="Line"></line>
                            <line x1="-0.000217632768" y1="5.27105455" x2="10.0781203" y2="5.27105455" id="Line" transform="translate(5.038951, 5.271055) rotate(-270.000000) translate(-5.038951, -5.271055) "></line>
                        </g>
                        <g id="Group-22" transform="translate(5.014862, 5.731886) rotate(-270.000000) translate(-5.014862, -5.731886) translate(-0.485138, 4.231886)"></g>
                    </g>
                    <g id="Group-23-Copy" opacity="0.364272388" transform="translate(203.000000, 98.828150) rotate(-73.000000) translate(-203.000000, -98.828150) translate(199.000000, 94.828150)">
                        <g id="Group" transform="translate(-0.000000, -0.000000)" stroke="#48D2A0" stroke-linecap="round" stroke-width="3.67499994">
                            <line x1="9.18659372e-13" y1="3.68977552" x2="7.0548364" y2="3.68977552" id="Line"></line>
                            <line x1="-0.000152342935" y1="3.68973812" x2="7.05468406" y2="3.68973812" id="Line" transform="translate(3.527266, 3.689738) rotate(-270.000000) translate(-3.527266, -3.689738) "></line>
                        </g>
                        <g id="Group-22" transform="translate(3.510403, 4.012320) rotate(-270.000000) translate(-3.510403, -4.012320) translate(-0.339597, 2.962320)"></g>
                    </g>
                </g>
                <g id="Phone" transform="translate(130.000000, 9.000000)">
                    <g id="Phone-case">
                        <path d="M0.390746387,21.2319749 L1.02481437,21.2319749 C1.21405436,21.2319749 1.36749836,21.3854189 1.36749836,21.5746589 L1.36749836,27.2707827 C1.36749836,27.4600227 1.21405436,27.6134667 1.02481437,27.6134667 L0.390746387,27.6134667 C0.201278393,27.6134667 0.0480623984,27.4600227 0.0480623984,27.2707827 L0.0480623984,21.5746589 C0.0480623984,21.3854189 0.201278393,21.2319749 0.390746387,21.2319749" id="Fill-1" fill="#3F3D56"></path>
                        <g id="Group-5" transform="translate(0.000000, 32.831999)">
                            <mask id="mask-2" fill="white">
                                <use xlink:href="#path-1"></use>
                            </mask>
                            <g id="Clip-4"></g>
                            <path d="M0.386414387,0.0835163973 L1.10096636,0.0835163973 C1.31437436,0.0835163973 1.48742635,0.256568392 1.48742635,0.469976385 L1.48742635,10.804988 C1.48742635,11.018168 1.31437436,11.19122 1.10096636,11.19122 L0.386414387,11.19122 C0.173006394,11.19122 -4.55999985e-05,11.018168 -4.55999985e-05,10.804988 L-4.55999985e-05,0.469976385 C-4.55999985e-05,0.256568392 0.173006394,0.0835163973 0.386414387,0.0835163973" id="Fill-3" fill="#3F3D56" mask="url(#mask-2)"></path>
                        </g>
                        <path d="M0.391635587,47.9098664 L1.07175957,47.9098664 C1.27490756,47.9098664 1.43952355,48.0744824 1.43952355,48.2774024 L1.43952355,58.7457941 C1.43952355,58.9489421 1.27490756,59.1135581 1.07175957,59.1135581 L0.391635587,59.1135581 C0.188487594,59.1135581 0.0240995992,58.9489421 0.0240995992,58.7457941 L0.0240995992,48.2774024 C0.0240995992,48.0744824 0.188487594,47.9098664 0.391635587,47.9098664" id="Fill-6" fill="#3F3D56"></path>
                        <g id="Group-10" transform="translate(0.684000, 0.000000)">
                            <mask id="mask-4" fill="white">
                                <use xlink:href="#path-3"></use>
                            </mask>
                            <g id="Clip-9"></g>
                            <path d="M8.24951853,0.142750795 L70.6567645,0.142750795 C75.1515564,0.142750795 78.7952242,3.78641868 78.7952242,8.28098253 L78.7952242,151.917786 C78.7952242,156.412578 75.1515564,160.056018 70.6567645,160.056018 L8.24951853,160.056018 C3.75495468,160.056018 0.111286796,156.412578 0.111286796,151.917786 L0.111286796,8.28098253 C0.111286796,3.78641868 3.75495468,0.142750795 8.24951853,0.142750795" id="Fill-8" fill="#3F3D56" mask="url(#mask-4)"></path>
                        </g>
                        <path d="M33.1266661,4.91827904 L42.9552898,4.91827904 C43.2754018,4.91827904 43.5348658,5.17774303 43.5348658,5.49785502 L43.5348658,6.56991099 C43.5348658,6.88979498 43.2754018,7.14948697 42.9552898,7.14948697 L33.1266661,7.14948697 C32.8065541,7.14948697 32.5470901,6.88979498 32.5470901,6.56991099 L32.5470901,5.49785502 C32.5470901,5.17774303 32.8065541,4.91827904 33.1266661,4.91827904" id="Fill-11" fill="#E6E8EC"></path>
                        <path d="M46.4618841,4.76832345 C47.1607041,4.76832345 47.7272841,5.33490343 47.7272841,6.0337234 C47.7272841,6.73277138 47.1607041,7.29935136 46.4618841,7.29935136 C45.7630641,7.29935136 45.1962561,6.73277138 45.1962561,6.0337234 C45.1962561,5.33490343 45.7630641,4.76832345 46.4618841,4.76832345" id="Fill-13" fill="#E6E8EC"></path>
                        <path d="M74.6201112,10.5578361 L74.6201112,149.642392 C74.6201112,153.093171 71.8214113,155.889591 68.3706314,155.889591 L11.9041532,155.889591 C8.45314533,155.889591 5.65467342,153.093171 5.65467342,149.642392 L5.65467342,10.5578361 C5.65467342,7.10660017 8.45177733,4.30835626 11.9030132,4.30835626 L11.9041532,4.30835626 L20.3538329,4.30835626 L20.3538329,5.39363623 C20.3545169,8.23520013 22.6580009,10.5389121 25.4997928,10.5395961 L54.2163918,10.5395961 C57.0584118,10.5395961 59.3623517,8.23565613 59.3623517,5.39363623 L59.3623517,4.30835626 L68.3706314,4.30835626 C71.8218673,4.30835626 74.6201112,7.10546017 74.6201112,10.5566961 L74.6201112,10.5578361 Z" id="Fill-15" fill="#FFFFFF"></path>
                        <path d="M7.03069897,28.0556043 L73.3802928,28.0556043 C74.1354288,28.0556043 74.7473808,28.6677843 74.7473808,29.4226922 L74.7473808,148.043564 L5.66361102,148.043564 L5.66361102,29.4226922 C5.66361102,28.6677843 6.275563,28.0556043 7.03069897,28.0556043" id="Fill-19" fill="#FFFFFF"></path>
                    </g>
                    <g id="Phone-content" transform="translate(2.000000, 0.142728)">
                        <path d="M69.8372185,116.253297 L10.3962525,116.253297 C9.64111649,116.253297 9.02893651,115.640889 9.02893651,114.885981 L9.02893651,90.7544623 L71.2045345,90.7544623 L71.2045345,114.885981 C71.2045345,115.640889 70.5925825,116.253297 69.8372185,116.253297" id="Fill-57" fill="#FFFFFF"></path>
                        <mask id="mask-6" fill="white">
                            <use xlink:href="#path-5"></use>
                        </mask>
                        <g id="Clip-62"></g>
                        <polygon id="Fill-61" fill="#525266" mask="url(#mask-6)" points="9.50282763 52.6378447 53.5079662 52.6378447 53.5079662 48.9898448 9.50282763 48.9898448"></polygon>
                        <polygon id="Fill-63" fill="#C0C0CC" mask="url(#mask-6)" points="9.91303148 44.5233249 27.2487829 44.5233249 27.2487829 43.611325 9.91303148 43.611325"></polygon>
                        <polygon id="Fill-64" fill="#525266" mask="url(#mask-6)" points="9.50282763 58.0163645 29.882151 58.0163645 29.882151 54.3683646 9.50282763 54.3683646"></polygon>
                        <polygon id="Fill-66" fill="#E5E5E5" mask="url(#mask-6)" points="9.50282763 63.3948843 54.5028276 63.3948843 54.5028276 59.7468844 9.50282763 59.7468844"></polygon>
                        <polygon id="Fill-66-Copy" fill="#E5E5E5" mask="url(#mask-6)" points="9.50282763 101.394884 61.5028276 101.394884 61.5028276 97.7468844 9.50282763 97.7468844"></polygon>
                        <polygon id="Fill-67" fill="#E5E5E5" mask="url(#mask-6)" points="9.78953516 70.3948843 49.7895352 70.3948843 49.7895352 66.7468844 9.78953516 66.7468844"></polygon>
                        <polygon id="Fill-67-Copy" fill="#E5E5E5" mask="url(#mask-6)" points="9.78953516 108.394884 53.7895352 108.394884 53.7895352 104.746884 9.78953516 104.746884"></polygon>
                        <polygon id="Fill-67-Copy-2" fill="#E5E5E5" mask="url(#mask-6)" points="9.78953516 115.394884 59.7895352 115.394884 59.7895352 111.746884 9.78953516 111.746884"></polygon>
                        <polygon id="Fill-85" fill="#525266" mask="url(#mask-6)" points="9.50282763 88.0856318 65.5028276 88.0856318 65.5028276 84.4376319 9.50282763 84.4376319"></polygon>
                        <polygon id="Fill-87" fill="#525266" mask="url(#mask-6)" points="9.50282763 93.4636956 37.5028276 93.4636956 37.5028276 89.8156957 9.50282763 89.8156957"></polygon>
                        <polygon id="Fill-92" fill="#525266" mask="url(#mask-6)" points="9.02902771 36.0880668 27.4097031 36.0880668 27.4097031 32.4400669 9.02902771 32.4400669"></polygon>
                        <polygon id="Fill-92-Copy" fill="#078167" mask="url(#mask-6)" points="32.0290277 20.0880668 51.0290277 20.0880668 51.0290277 16.4400669 32.0290277 16.4400669"></polygon>
                        <polygon id="Fill-92-Copy-2" fill="#078167" mask="url(#mask-6)" points="10.0290277 146.264067 67.0290277 146.264067 67.0290277 135.264067 10.0290277 135.264067"></polygon>
                        <circle id="Oval" fill="#078167" mask="url(#mask-6)" cx="26" cy="18" r="2"></circle>
                    </g>
                </g>
                <g id="User" transform="translate(222.500000, 108.500000) scale(-1, 1) translate(-222.500000, -108.500000) translate(200.000000, 37.000000)">
                    <path d="M38.7407273,64.1112974 L37.7167433,68.5264431 C37.7167433,68.5264431 38.0580713,78.7152409 35.3274473,77.6963611 C32.5968234,76.6774813 33.2794794,67.8471899 33.2794794,67.8471899 L35.6687753,63.4320442 L38.7407273,64.1112974 Z" id="Fill-1" fill="#FFB8B8"></path>
                    <path d="M23.5516315,2.12944428 C27.3219406,2.12944428 30.3781914,5.17046079 30.3781914,8.92197613 C30.3781914,12.6731518 27.3219406,15.714508 23.5516315,15.714508 C19.7813225,15.714508 16.7250717,12.6731518 16.7250717,8.92197613 C16.7250717,5.17046079 19.7813225,2.12944428 23.5516315,2.12944428" id="Fill-3" fill="#FFB8B8"></path>
                    <path d="M18.2610476,10.7899224 L17.5783917,20.6390936 L26.1115915,21.6579733 C26.1115915,21.6579733 26.1115915,13.5069351 26.7942475,12.4880553 C27.4769035,11.4691756 18.2610476,10.7899224 18.2610476,10.7899224" id="Fill-5" fill="#FFB8B8"></path>
                    <path d="M37.2047513,23.5259196 L26.1515269,18.8686201 C26.1515269,18.8686201 20.8715241,14.8144975 17.7743139,17.8117022 L16.3837437,19.7900271 C16.3837437,19.7900271 4.09593592,24.5447994 4.09593592,28.2806919 L8.87452783,46.9601545 C8.87452783,46.9601545 6.14390388,57.4885788 10.2398398,58.167832 L32.4261594,60.2055916 C32.4261594,60.2055916 35.4981113,56.4696991 32.7674874,51.7149268 L33.7914714,40.5072492 L37.2047513,23.5259196 Z" id="Fill-7" fill="#575A89"></path>
                    <path d="M29.6955354,23.8655462 L37.2047513,23.5259196 C37.2047513,23.5259196 39.5940472,24.2051728 41.3006872,30.658078 C43.0073272,37.1109833 43.3486552,47.6394077 43.3486552,47.6394077 L39.2527192,66.6584968 L34.1327993,65.9792436 L36.1807673,47.6394077 L32.7674874,35.4128503 L29.6955354,23.8655462 Z" id="Fill-9" fill="#575A89"></path>
                    <path d="M8.19187184,25.9033057 L4.4894871,27.0505644 C4.4894871,27.0505644 1.36531197,31.6769578 1.36531197,34.054344 C1.36531197,36.4317301 0,54.4319395 0,54.4319395 L8.87452783,69.035883 L11.6051518,61.9037245 L6.14390388,52.7338065 L10.5811678,41.526129 L8.19187184,25.9033057 Z" id="Fill-11" fill="#575A89"></path>
                    <path d="M33.4501434,59.5263384 L8.90968461,57.3778606 C8.90968461,57.3778606 6.48523188,72.7717755 6.82655987,78.8850542 C7.16788786,84.9983328 7.50921586,94.5078774 7.50921586,94.5078774 L4.77859191,135.942322 L15.3597597,134.583815 L16.7250717,100.281529 L21.1623356,81.6020669 L26.2822555,105.375928 L29.6955354,135.942322 L38.9113913,134.923442 L35.8394393,97.9041433 C35.8394393,97.9041433 38.5700633,64.6207373 33.4501434,59.5263384" id="Fill-13" fill="#2F2E41"></path>
                    <g id="Group-17" transform="translate(30.036863, 133.225309)">
                        <mask id="mask-8" fill="white">
                            <use xlink:href="#path-7"></use>
                        </mask>
                        <g id="Clip-16"></g>
                        <path d="M6.82655987,0 L9.89851181,1.01887978 C9.89851181,1.01887978 16.3837437,-0.679253185 14.6771037,3.73589252 C12.9704638,8.15103822 8.19187184,7.47178503 8.19187184,7.47178503 C8.19187184,7.47178503 2.38929595,11.8869307 0,8.49066481 L1.36531197,1.35850637 L6.82655987,0 Z" id="Fill-15" fill="#2F2E41" mask="url(#mask-8)"></path>
                    </g>
                    <g id="Group-20" transform="translate(5.119920, 133.225309)">
                        <mask id="mask-10" fill="white">
                            <use xlink:href="#path-9"></use>
                        </mask>
                        <g id="Clip-19"></g>
                        <path d="M6.82655987,0 L9.89851181,1.01887978 C9.89851181,1.01887978 16.3837437,-0.679253185 14.6771037,3.73589252 C12.9704638,8.15103822 8.19187184,7.47178503 8.19187184,7.47178503 C8.19187184,7.47178503 2.38929595,11.8869307 0,8.49066481 L1.36531197,1.35850637 L6.82655987,0 Z" id="Fill-18" fill="#2F2E41" mask="url(#mask-10)"></path>
                    </g>
                    <path d="M18.2610476,14.8654415 C18.2610476,14.8654415 11.0931598,9.77104261 14.8477677,3.65776395 C17.4524416,-0.583153311 22.8498612,-0.247602238 25.9115733,0.355914217 C27.5861284,0.663276283 29.0012743,1.77113823 29.6955354,3.31813736 C30.2075274,4.50683043 30.2075274,5.6955235 28.1595595,5.6955235 C24.0636235,5.6955235 26.1115915,8.75216283 26.1115915,8.75216283 C26.1115915,8.75216283 23.3809676,9.43141602 24.0636235,11.8088022 C24.7462795,14.1861883 18.2610476,14.8654415 18.2610476,14.8654415" id="Fill-21" fill="#2F2E41"></path>
                </g>
                <g id="Magifying-Glass" transform="translate(111.000000, 39.000000)" fill="#078167" fill-rule="nonzero">
                    <path d="M41.2511766,7.44571585 C36.7711559,2.73422849 30.600504,0.0499023042 24.1367621,0.000688726166 C17.6730202,-0.0485248519 11.4630901,2.54153817 6.91340874,7.18427527 C-2.16699242,16.4405167 -2.32089752,31.5122549 6.56096605,40.9645767 C14.6178799,49.5384749 27.6152196,50.9881176 37.3265964,44.3959843 L54.6686236,62.6376895 C55.1157682,63.1077053 55.8550351,63.1223349 56.3200253,62.6703696 L60.6416807,58.468646 C61.1065188,58.0165208 61.1209872,57.2690195 60.6740008,56.7988499 L43.3304345,38.5587009 C50.1937119,29.0509381 49.317078,15.9334228 41.2511766,7.44571585 L41.2511766,7.44571585 Z M35.4107077,36.8756296 C28.5935573,43.4148222 17.7543272,43.2019099 11.2004003,36.4000728 C4.64647337,29.5982356 4.85960054,18.7830995 11.6764372,12.2435813 C18.4932739,5.70406305 29.3325143,5.91645748 35.8867675,12.7179816 C39.0344318,15.9843972 40.752633,20.3646989 40.6633535,24.8951866 C40.574074,29.4256743 38.6846278,33.7351981 35.4107077,36.8756296 Z" id="Shape-Copy" transform="translate(30.500000, 31.500000) scale(-1, 1) translate(-30.500000, -31.500000) "></path>
                </g>
            </g>
        </g>
    </g>
</svg>
           
            `;