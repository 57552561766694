import React from "react";
import { ButtonBase } from "@mui/material";
import Styles from "./recentlyUpdatedContacts.module.css";
import recentlyicon from "../../images/svg/recently-updated-contacts.svg";

const RecentlyUpdatedContacts = ({ onOpenRecentlyUpdatedContacts }) => {
  return (
    <>
      <div className={Styles.containerMain}>
        <div className={Styles.container}>
          <div className={Styles.containerTitle}>
            <img src={recentlyicon} alt="notification-logo" />
            <span className={Styles.titleMain}>Recently Updated Contacts</span>
          </div>
          <ButtonBase
            style={{ alignItems: "center" }}
            onClick={(e) => {
              e.stopPropagation();
              onOpenRecentlyUpdatedContacts?.();
            }}
          >
            <span className={Styles.buttonNotifi}>{"View all"}</span>
          </ButtonBase>
        </div>
        <div></div>
      </div>
    </>
  );
};

export default RecentlyUpdatedContacts;
