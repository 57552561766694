import React, { useEffect, useState, useContext } from 'react';
import PotentialMatchesCard from '../savedSearchMatch/potentialMatchesCard';
import { useLazyQuery, useMutation } from 'react-apollo';
import {
  GET_FERRET_CONTACT_LIST,
  GET_POTENTIAL_MATCHES,
  UPDATE_CONTACT_DATA,
} from '../../queries/queries';
import useRefineSearch from '../searchModalV2/useRefineSearch';
import { UserContext } from '../../contexts/userContext';
import getMetaFromContacts from '../../utils/getMetaFromContacts';
import { Backdrop, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { IconButton, CircularProgress } from "@mui/material";
import { GreenBgButton } from '../buttons/greenBgButton';
import { useNavigate } from "react-router-dom";
import Variables from '../../utils/Variables';
import SubscriptionPlansModal, { FREE_PLAN_ID } from '../subscriptionPlans/subscriptionPlansModal';
import SearchLimitExceedModal from '../searchLimitExceedModal';
import { ModelType } from '../searchLimitExceedModal/searchLimitExceedModal';
import { searchResultStore } from '../../store/searchResultStore'
import Styles from './potentialMatch.module.css'
import { searchFormStore } from '../../store/searchFormStore';

const PotentialMatch = ({
  contactID,
  potentialModalVisible,
  setPotentialModalVisible,
  potentialData,
  onSavePotentialMatch,
  isWeb,
}) => {
  const FREE_PLAN_ID = "free"
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [clickedPotential, setClickedPotential] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [isSearchLimitExceed, setSearchLimitExceed] = useState(false);
  const [searchLimitExceedTitle, setSearchLimitExceedTitle] = useState(null);
  const [subscriptionModalVisible, setSubscriptionModalVisible] = useState(false)
  const [lastQuery, setLastQuery] = useState()
  const removeAll = searchResultStore((state) => state.removeAll)
  const removeAllFormStore = searchFormStore((state) => state.searchResultData)
  const setSelectedPotentialMatchData = searchFormStore (
    (state) => state.setSelectedPotentialMatchData
  );
  const {
    refineSearch,
    data,
    loading,
    error,
    resultTypes,
    getResultTypeFromRefineSearch,
    getQuestionFromRefineSearch,
    getArticleDetailsFromRefineSearch,
    getArticleFilterData,
    getPersonalInfo,
    getArticleTypeCount,
    mapSingleArticle,
    getReadArticlesIds,
    getAKAList,
    getCategoryFilterData,
    getPotentialMatchId,
    getContactId
  } = useRefineSearch();

  const onBackOpenModal = () => {
    setPotentialModalVisible(true);
  };

  const onPotentialSelected = (item) => {
    if(isWeb){
      removeAll()
      setSelectedPotentialMatchData(item)
      return
    }
    setSelectedItem(item);
    setClickedPotential(true);
    let metadata = {};
    if (item?.Company && item?.Company?.length) {
      item?.Company?.forEach((item) => {
        if (item)
          metadata.Company = metadata.Company
            ? metadata.Company + ';' + item
            : item;
      });
    }
    if (item?.Person) metadata.Person = item.Person;

    if (item?.Place && item?.Place?.length) {
      item?.Place?.forEach((item) => {
        let place;
        if (item) {
          if (item?.city) place = item.city;
          if (item?.state)
            place = place ? place + ', ' + item.state : item.state;
          if (item?.country)
            place = place ? place + ', ' + item.country : item.country;
        }
        if (place)
          metadata.Place = metadata.Place
            ? metadata.Place + ';' + place
            : place;
      });
    }

    let variables = {
      fullname: {
        search: 'search',
        meta_data: JSON.stringify(metadata),
        source: 'ADHOC_SEARCH',
      },
    };

    if (item?.TahoeId) variables.fullname.tahoeId = item.TahoeId;
    if (item?.TracerQueryId)
      variables.fullname.tracerQueryId = item.TracerQueryId;

    let query = {
      variables,
    };
    setLastQuery(query)
    refineSearch(query);
  };

  const startFreeTrial = async () => {
    setSubscriptionModalVisible(true)
    setSearchLimitExceed(false)
  }

  const navigateToDashboard = () => {
    setSearchLimitExceed(false)
    setPotentialModalVisible(false)
  }

  useEffect(() => {
    if (!!data && !loading) {
      if (data?.refine_search?.result_type?.toUpperCase() === "SUBSCRIPTION LIMIT REACHED") {
        setSearchLimitExceedTitle(`You Have Reached Your Search Limit`)
        setSearchLimitExceed(true)
        const updateUserInfo = { ...user, searchLimitExceed: true }
        setUser(updateUserInfo)
      } else {
        if (clickedPotential) {
          let currentMonthAdhocSearchCount =
            user.adhocSearchLimitPerMonth -
            data?.refine_search?.currentMonthAdhocSearchCount;
          let divideByThree = parseInt(user.adhocSearchLimitPerMonth / 3);
          if (
            currentMonthAdhocSearchCount == divideByThree ||
            currentMonthAdhocSearchCount < 3
          ) {
            setSearchLimitExceed(true);
            switch (true) {
              case currentMonthAdhocSearchCount === divideByThree:
                setSearchLimitExceedTitle(`You Have ${currentMonthAdhocSearchCount} Free Searches Left This Month`)
                break
              case currentMonthAdhocSearchCount === 2:
                setSearchLimitExceedTitle(`You Have 2 Searches Left This Month`)
                break
              case currentMonthAdhocSearchCount === 1:
                setSearchLimitExceedTitle(`You Have 1 Free Searches Left This Month`)
                break
              case currentMonthAdhocSearchCount === 0:
                //setOpenPotentialMatch(false)
                setSearchLimitExceedTitle(`You Have Reached Your Search Limit`)
                const updateUserInfo = { ...user, searchLimitExceed: true }
                setUser(updateUserInfo)
              default:
                break
            }
          } else {
            if (clickedPotential) goToResults()
          }
        } else {
          if (clickedPotential) goToResults()
        }
      }
    }
  }, [data, loading]);

  const goToResults = (id, articles, contactId) => {
    const {
      metadata,
      searchTerm,
      searchType,
      searchContext,
      tahoeId,
      tracerQueryId,
      requestMetadata,
    } = getMetaFromContacts(selectedItem);

    const articleDetails = getArticleDetailsFromRefineSearch();

    if (!articleDetails) {
      return;
    }
    Variables.potentialMatchId = getPotentialMatchId()
    Variables.contactId = getContactId()
    const readArticles = getReadArticlesIds();
    const articleFilterData = getArticleFilterData();
    const aKAList = getAKAList();
    const personalInfo = getPersonalInfo();
    const articleTypeCount = getArticleTypeCount();
    const parsedArticles = articleDetails
      .filter((item) => {
        return item;
      })
      .map((item) =>
        mapSingleArticle(item, selectedItem?.Person, readArticles),
      );

    const data = {
      //irrelevantArticleId: id ? id : irrelevantArticleId,
      //irrelevantArticles: articles ? articles : irrelevantArticles,
      //metadata: metaTags,
      lastQuery: lastQuery,
      searchTerm: selectedItem?.Person,
      searchType: searchType,
      articles: parsedArticles,
      //savedContactID: contactId ?? savedContactID,
      savedTahoeId: tahoeId,
      savedTracerQueryId: tracerQueryId,
      articleFilterData: articleFilterData,
      personalInfo: personalInfo,
      articleTypeCount: articleTypeCount,
      clickedPotential: clickedPotential,
      potentialsearch: selectedItem?.Person,
      aKAList: aKAList,
      searchSettings: {
        initialSearchString: selectedItem?.Person,
        // intitialMetadata: metaTags,
        //initialSearchParams: arrayData,
        // initialQuestions: questions,
        // qaMetaData: metaData,
        //initialPotentialMatch: potentialMatch,
        isAdhocSearch: true,
      },
      navigatedFrom: 'SavedSearchesPreview',
      // categoryFilterData: getCategoryFilterData(),
      // onBackOpenModal: onBackOpenModal,
    };
    console.log('location.state===1=', data)
    removeAll()
    navigate('/SearchResultsWithQuery', { state: data, preventScrollReset: true })
  };

  useEffect(() => {
    if (potentialModalVisible) setSelectedIndex(-1);
  }, [potentialModalVisible]);

  const onBackDropPressHandler = () => {
    Variables.potentialMatchId = null
    Variables.contactId = null
    setPotentialModalVisible(false);
  };

  const onPotentialOptionSelected = (item, flag, index) => {
    setSelectedIndex(index);
  };

  const [updateContactData, { loading: loadingUpdate }] = useMutation(
    UPDATE_CONTACT_DATA,
    {
      onCompleted: (data) => {
        onSavePotentialMatch();
      },
      onError: (data) => {
        onSavePotentialMatch();
      },
    },
  );

  const onPress = (index) => {
    if (index >= 0 && potentialData && potentialData?.length > index) {
      updateContactData({
        variables: {
          contact: {
            id: contactID,
            multiplePotentialMatchesFound: false,
            tracerQueryId: potentialData[index].TracerQueryId,
            tahoeId: potentialData[index].TahoeId,
            isProcessed: false,
            akaList: potentialData[index]?.akaList,
            // name: potentialData[index].Person.split(' ').length === 1 ? potentialData[index].Person : potentialData[index].Person.substr(0,potentialData[index].Person.indexOf(' ')),
            // lastName: potentialData[index].Person.split(' ').length > 1 ? potentialData[index].Person.substr(potentialData[index].Person.indexOf(' ')+1) : '',
            // postalAddresses: potentialData[index].Place,
            // company: potentialData[index].Company?.join(';')?.toString(),
            // riskCategory: potentialData[index].RiskCategory
          },
        },
      });
    }
  };

  return (
    <Dialog
      open={potentialModalVisible}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          margin: 0,
        }
      }}
    >
      <div className={Styles.container}>
        <header className={Styles.header}>
          <span
            onClick={onBackDropPressHandler}
            className={Styles.headerSideBtnTxt}>Close</span>
          <span className={Styles.headerMiddleBtn}>Select a Potential Match</span>
        </header>
        <main className={Styles.mainDiv}>
          <ui className={Styles.list}>
            {potentialData.map((item, index) =>
            <PotentialMatchesCard
              item={item}
              index={index}
              onPotentialSelected={onPotentialSelected}
              onPotentialOptionSelected={onPotentialOptionSelected}
              isNext={true}
              isCheckBox={true}
              selectedIndex={selectedIndex}
                mStyle={{ marginHorizontal: 10 }}
              />
            )}
          </ui>
        </main>
        <div className={Styles.btnDiv}>
          <GreenBgButton
            fullWidth
            onClick={() => onPress(selectedIndex)}
            variant="contained"
            className={`${selectedIndex == -1 ? Styles["applyBtnDis"] : Styles["applyBtn"]}`}>
            <div className="acceptBtnTxt">Save Selection</div>
          </GreenBgButton>
        </div>
        {isSearchLimitExceed && <SearchLimitExceedModal
          title={searchLimitExceedTitle}
          onBackdropPress={() => { setSearchLimitExceed(false) }}
          startFreeTrial={startFreeTrial}
          goback={navigateToDashboard}
          modelType={ModelType.SEARCH_LIMIT_MODAL}
        />}
        {subscriptionModalVisible && <SubscriptionPlansModal
          subscriptionModalVisible={subscriptionModalVisible}
          setSubscriptionModalVisible={setSubscriptionModalVisible}
        />}
      </div>
      {(loadingUpdate || loading) && (
        <Backdrop
          sx={{ color: '#AAA', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: "#00000000" }}
          open={true}
        >
          <CircularProgress />
        </Backdrop>
      )}
    </Dialog>
  );
};
export default PotentialMatch;
