import React, { createContext, useState } from 'react'
import Variables from '../utils/Variables'

const OnboardingContext = createContext()
const OnboardingStates = {
  alerts: 'alerts',
  newSearch: 'newSearch',
  recentActivity: 'recentActivity',
  savedSearches: 'savedSearches',
  notificationBanner: 'notificationBanner',
  notificationBannerEnd: 'notificationBannerEnd',
  searchMe: 'searchMe',
  inTheNews: 'inTheNews',
  completed: 'completed',
  revisit: 'revisit',
  filterHeader: 'filterHeader',
  recentFilterHeader: 'recentFilterHeader',
  filterType: 'filterType',
  end: 'end',
}

const OnboardingContextProvider = (props) => {
  const {children, value} = props
  const [onboardingState, setOnboardingState] = useState(value)
  return (
    <OnboardingContext.Provider value={{onboardingState, setOnboardingState}}>
      {children}
    </OnboardingContext.Provider>
  )
}
const getOnboardingInitialStatus = () => null;

const getNextOnboardingStatus = (currentOnboardingStatus) => {
    switch (currentOnboardingStatus) {
      
      case OnboardingStates.alerts:
        return OnboardingStates.newSearch;

      case OnboardingStates.recentActivity:
        return OnboardingStates.newSearch;
  
      case OnboardingStates.newSearch:
        return OnboardingStates.savedSearches;
    
      case OnboardingStates.savedSearches:
        return OnboardingStates.notificationBanner;
        // return Variables.isContactImported ? OnboardingStates.notificationBanner : OnboardingStates.completed

      case OnboardingStates.notificationBanner:
          return OnboardingStates.completed;

      case OnboardingStates.notificationBannerEnd:
        return Variables.filterOnBoarding ? OnboardingStates.filterHeader : OnboardingStates.end;
      
      case OnboardingStates.searchMe:
        return OnboardingStates.completed;
        
      case OnboardingStates.inTheNews:
        return OnboardingStates.completed;
  
      case OnboardingStates.completed:
        return OnboardingStates.filterHeader;
  
      case OnboardingStates.filterHeader:
        return OnboardingStates.recentFilterHeader;

      case OnboardingStates.recentFilterHeader:
        return OnboardingStates.filterType;
  
      case OnboardingStates.filterType:
        return OnboardingStates.end;

      case OnboardingStates.revisit:
        return OnboardingStates.recentActivity;
      
      case OnboardingStates.end:
        return OnboardingStates.end;

      default:
        return OnboardingStates.recentActivity;
    }
}

export default OnboardingContextProvider
export {
  OnboardingContext,
  OnboardingStates,
  getNextOnboardingStatus,
  getOnboardingInitialStatus,
}