import ImportContactsLanding from "../../components/importContactsLanding/importContactsLanding";
import Styles from "./importLayout.module.css";

/**
 * Dashboard Page
 * @constructor
 */
const ImportLayoutPage1 = () => {
  return (
    <>
      <div className={Styles.body}>
        <div className={Styles.bodyMain}>
          <ImportContactsLanding />
        </div>
      </div>
    </>
  );
};

// Page export
export default ImportLayoutPage1;
