// Icons
import CTALogo from "../images/icons/cta/logo-white.svg";

// Images
import CTAFingerprintBG from "../images/backgrounds/cta-fingerprint.svg";

/**
 * TryPremium Component
 * @returns {JSX.Element}
 * @constructor
 */
function TryPremium(props) {
  /**
   * Main render method
   */
  return (
    <>
      <div
        onClick={props.callSubs}
        className="bg-cta-gradient rounded-lg p-4 bg-no-repeat bg-center"
        style={{ backgroundImage: `url(${CTAFingerprintBG})` }}
      >
        <button className="btn btn--primary btn--small">
          Start 7-day Free Trial
        </button>
        <img
          className="float-right relative -top-4 right-4"
          src={CTALogo}
          alt="Ferret"
        />
      </div>
    </>
  );
}

// Component export
export default TryPremium;
