import * as React from "react"

const AccuracyModalIcon = (props) => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4115_87896)">
      <path d="M16.1857 8.14282C16.1857 8.03996 16.1857 7.91996 16.1857 7.81711C16.1863 7.72895 16.1627 7.64231 16.1173 7.56671C16.072 7.49111 16.0067 7.42947 15.9286 7.38854C15.24 7.0904 14.6538 6.59705 14.2425 5.96944C13.8312 5.34183 13.6128 4.60748 13.6143 3.85711C13.6177 2.86255 13.8525 1.88243 14.3 0.99425C14.3422 0.883861 14.3422 0.761781 14.3 0.651393C14.2554 0.538398 14.1697 0.446509 14.06 0.39425C13.2386 0.0808171 12.3583 -0.0482734 11.4815 0.0161121C10.6047 0.0804976 9.75278 0.336786 8.98597 0.766843C8.21916 1.1969 7.55624 1.79022 7.04409 2.50482C6.53195 3.21942 6.1831 4.03783 6.02224 4.90216C5.86137 5.76649 5.89242 6.65561 6.11318 7.50661C6.33394 8.35762 6.73903 9.14971 7.29977 9.82684C7.86051 10.504 8.56321 11.0496 9.35815 11.4251C10.1531 11.8007 11.0208 11.9969 11.9 12C12.7453 12.0017 13.5814 11.8238 14.3527 11.478C15.1241 11.1322 15.8132 10.6265 16.3743 9.99425C16.4289 9.93543 16.4658 9.86247 16.481 9.78367C16.4961 9.70487 16.4889 9.6234 16.46 9.54854C16.2804 9.10155 16.1874 8.62453 16.1857 8.14282V8.14282Z" fill="#5391F7" />
      <path d="M11.2657 15.8572C11.2987 15.7967 11.316 15.7289 11.316 15.66C11.316 15.5911 11.2987 15.5233 11.2657 15.4629C11.2189 15.3825 11.1439 15.3222 11.0553 15.2937C10.9667 15.2652 10.8707 15.2705 10.7857 15.3086C8.62225 15.5118 6.56217 16.3301 4.84888 17.6667C3.13559 19.0033 1.84068 20.8024 1.11715 22.8514C1.06462 22.9805 1.04648 23.1211 1.06451 23.2593C1.08254 23.3975 1.13611 23.5287 1.22001 23.64C1.30185 23.758 1.41259 23.8531 1.54164 23.9161C1.67069 23.9791 1.81375 24.008 1.95715 24H9.5C9.57355 24.0046 9.64683 23.9876 9.71081 23.9511C9.77479 23.9145 9.82666 23.86 9.86 23.7943C9.89788 23.7324 9.91792 23.6612 9.91792 23.5886C9.91792 23.516 9.89788 23.4448 9.86 23.3829L9.39715 22.44C9.3764 22.3912 9.36571 22.3387 9.36571 22.2857C9.36571 22.2327 9.3764 22.1802 9.39715 22.1314L11.2657 15.8572Z" fill="#5391F7" />
      <path d="M22.6657 22.8514C21.9422 20.8024 20.6473 19.0033 18.934 17.6667C17.2207 16.3301 15.1606 15.5118 12.9972 15.3086C12.9293 15.2968 12.8596 15.3015 12.7939 15.3224C12.7282 15.3433 12.6686 15.3797 12.62 15.4286C12.587 15.489 12.5697 15.5568 12.5697 15.6257C12.5697 15.6946 12.587 15.7624 12.62 15.8228L14.5057 22.0971C14.5397 22.1972 14.5397 22.3057 14.5057 22.4057L14.0257 23.3486C13.9927 23.4121 13.9754 23.4827 13.9754 23.5543C13.9754 23.6259 13.9927 23.6965 14.0257 23.76C14.0591 23.8257 14.1109 23.8802 14.1749 23.9168C14.2389 23.9533 14.3122 23.9703 14.3857 23.9657H21.9286C22.0658 23.965 22.2009 23.9321 22.323 23.8696C22.4451 23.807 22.5507 23.7166 22.6314 23.6057C22.6944 23.4909 22.7302 23.3633 22.7361 23.2325C22.7421 23.1018 22.718 22.9714 22.6657 22.8514V22.8514Z" fill="#5391F7" />
      <path d="M21.3286 8.14294C21.3277 7.96234 21.3769 7.78502 21.4707 7.63069C21.5645 7.47635 21.6993 7.35104 21.86 7.26866C22.461 6.94765 22.9662 6.47317 23.3243 5.89349C23.6824 5.31382 23.8805 4.64964 23.8986 3.96852C23.9166 3.28741 23.7539 2.61367 23.427 2.01586C23.1001 1.41804 22.6207 0.917489 22.0375 0.565124C21.4543 0.212758 20.7882 0.0211565 20.107 0.00981214C19.4257 -0.00153226 18.7536 0.167786 18.1591 0.500539C17.5645 0.833293 17.0687 1.31761 16.722 1.9042C16.3754 2.4908 16.1904 3.15875 16.1857 3.84009C16.1857 4.18108 16.3212 4.50811 16.5623 4.74922C16.8034 4.99034 17.1304 5.1258 17.4714 5.1258C17.8124 5.1258 18.1394 4.99034 18.3806 4.74922C18.6217 4.50811 18.7571 4.18108 18.7571 3.84009C18.7571 3.49909 18.8926 3.17207 19.1337 2.93095C19.3748 2.68983 19.7019 2.55437 20.0429 2.55437C20.3839 2.55437 20.7109 2.68983 20.952 2.93095C21.1931 3.17207 21.3286 3.49909 21.3286 3.84009C21.3292 4.07138 21.2674 4.29855 21.1498 4.49768C21.0321 4.6968 20.8629 4.8605 20.66 4.97151C20.0882 5.27718 19.6095 5.73162 19.2746 6.28683C18.9396 6.84204 18.7609 7.4774 18.7571 8.1258C18.7571 8.46679 18.8926 8.79382 19.1337 9.03494C19.3748 9.27606 19.7019 9.41151 20.0429 9.41151C20.3839 9.41151 20.7109 9.27606 20.952 9.03494C21.1931 8.79382 21.3286 8.46679 21.3286 8.1258V8.14294Z" fill="#5391F7" />
      <path d="M20.0429 14.5714C20.9896 14.5714 21.7572 13.8039 21.7572 12.8571C21.7572 11.9103 20.9896 11.1428 20.0429 11.1428C19.0961 11.1428 18.3286 11.9103 18.3286 12.8571C18.3286 13.8039 19.0961 14.5714 20.0429 14.5714Z" fill="#5391F7" />
    </g>
    <defs>
      <clipPath id="clip0_4115_87896">
        <rect width="24" height="24" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>

)

export default AccuracyModalIcon
