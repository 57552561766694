import React, { useEffect, useState, useContext } from 'react';
import { Backdrop, ButtonBase, CircularProgress, Modal } from '@mui/material';
import Styles from './pushNotification.module.css';
import { GreenBgButton } from '../buttons/greenBgButton';
import PushNotificationStatusIcon from '../../images/svg/push-notification-status-icon'
import { WhiteBgButtonGreenBorder } from '../buttons/whiteBgButtonGreenBorder';
import CloseIconGray from '../../images/svg/close_gray';
import { getShowNotificationStatusAlert, setShowNotificationStatusAlert } from '../../utils/safe-storage';
import PushNotificationError from './PushNotificationError';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_FERRET_USER } from '../../queries/queries';
import SingleBtnAlert from '../alert/singleBtnAlert';
import { setUserInfo } from '../../root-navigation';
import { UserContext } from '../../contexts/userContext';

const PushNotificationStatus = () => {
  const [showPushNotification, setShowPushNotification] = useState({
    showAlert: false,
    showError: false,
  });
  const [showSingleBtnAlert, setShowSingleBtnAlert] = useState({
    status: false,
    msg: "",
  });
  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    let showNotification = getShowNotificationStatusAlert()
    const checkNotification = async () => {
      const permission = await navigator.permissions.query({ name: "notifications" });
      if (permission.state == "granted" || Notification.permission == "granted")
        return;

      setShowPushNotification({ showAlert: showNotification, showError: false })
    }
    checkNotification()
  }, [])

  const pushNotificationEnable = () => {
    Notification.requestPermission().then((permission) => {
      switch (permission) {
        case "granted":
          onBackdropPress()
          navigator.serviceWorker.ready.then(function (registration) {
            registration.showNotification('Ferret - Know Your Contacts', {
              body: "Thank you for subscribing to Ferret notifications.",
              icon: "/public/images/logo.png",
              data: {
                "action": "none"
              }
            });
          });
          break
        case "denied":
          onBackdropPress(true)
          break
        default:
          onBackdropPress()
          break
      }
    });
  }

  const handleMayBeLater = () => {
    setShowNotificationStatusAlert(false)
    onBackdropPress()
  }

  const [updateUser, { loading: updateUserLoading, data: updateUserData, error: updateUserError }] = useMutation(UPDATE_FERRET_USER);

  useEffect(() => {
    if (!updateUserLoading && updateUserData) {
      let u = setUserInfo(updateUserData.updateUser);
      setUser({ ...u });
      onBackdropPress()
    } else if (!updateUserLoading && updateUserError) {
      console.error("error", updateUserError)
    }
  }, [updateUserData, updateUserError])

  const handleDontShowAgain = () => {
    updateUser({
      variables: {
        neverShowNotifications: true,
      },
    });
  }

  const onBackdropPress = (showError) => {
    setShowPushNotification({ showAlert: false, showError: showError ?? false })
  }

  return (
    <>
      {(showPushNotification.showAlert.toString() === "true") && <Modal
        open={true}
        className={Styles.modal}>

        <div className={Styles.container}>
          <div className={Styles.btnClose}>
            <ButtonBase onClick={() => { onBackdropPress() }}>
              <CloseIconGray className={Styles.iconClose} />
            </ButtonBase>
          </div>
          <main className={Styles.content}>
            <PushNotificationStatusIcon />
            <span className={Styles.textQuestion}>{'Did one of your contacts just get hit with a lawsuit?'}</span>
            <span className={Styles.textSimple}>
              {
                (user?.isSubscribed) ?
                  "Enable Ferret notifications and be notified when your watched contacts have new results." :
                  "Enable Ferret notifications and be notified when you have new search results"
              }
            </span>

            <div className={Styles.btnContainer}>
              <WhiteBgButtonGreenBorder
                disableElevation
                style={{
                  marginLeft: 6,
                  marginRight: 6
                }}
                loading={false}
                onClick={() => { handleMayBeLater() }}
                fullWidth
                variant="contained"
              >
                <div className={`${Styles.btnTitle} ${Styles.btnTitleGreen}`}>Maybe Later</div>
              </WhiteBgButtonGreenBorder>

              <GreenBgButton
                disableElevation
                style={{
                  marginLeft: 6,
                  marginRight: 6
                }}
                loading={false}
                onClick={() => { pushNotificationEnable() }}
                fullWidth
                variant="contained"
              >
                <div className={Styles.btnTitle}>Enable</div>
              </GreenBgButton>
            </div>
            <ButtonBase onClick={() => { handleDontShowAgain() }}>
              <span className={Styles.dontShowAgain}>{'Don’t show me this message again'}</span>
            </ButtonBase>
          </main>
        </div>
      </Modal>}

      {(showPushNotification.showError === true) && <PushNotificationError
        setShowPushNotification={setShowPushNotification}
      />}

      {(updateUserLoading) && <Backdrop
        sx={{ backgroundColor: "#00000000", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>}

      {(showSingleBtnAlert.status == true) && <SingleBtnAlert
        description={showSingleBtnAlert.msg}
        btnTxt="OK"
        onBtnClick={() => {
          setShowSingleBtnAlert({ status: false, msg: "" })
        }}
      />
      }
    </>
  );
};

export default PushNotificationStatus;
