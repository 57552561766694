import React, { useEffect, useState } from "react";
import Tag from "../atoms/tag";
import TitleGroup from "../atoms/titleGroup";
import createStyle from "./styles";
import { questionsTypes } from "../searchModalV2/Question";
import { Input } from "@mui/material";

const GroupTags = (props) => {

  const styles = createStyle();
  let maxShowItem = 6;
  let showMoreCount = 5;
  const {
    isAttributeView = false,
    title,
    groupName,
    tags,
    lastChild = false,
    onPressTag = () => { },
    selectable = false,
    theme = 'light',
    tagStyle,
    filterAttributes,
    showMoreTagsCount =  maxShowItem,
    isFromFilter = false,
    isFromBot = false,
    isFromArticle = false,
  } = props;

  const [mShowMoreTagsCount, setShowMoreTagsCount] =
    useState(showMoreTagsCount);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    setShowMoreTagsCount(showMoreTagsCount);
  }, [showMoreTagsCount]);

  const onShowMore = (size, less = false) => {
    if (less) {
      setShowMoreTagsCount(maxShowItem);
      setShowMore(false);
    } else if (size > mShowMoreTagsCount) {
      if (size <= mShowMoreTagsCount + showMoreCount) setShowMore(!showMore);
      setShowMoreTagsCount(mShowMoreTagsCount + showMoreCount); //add 50 tags on show more click if load all tags the UI stuck
    } else if (!showMore) {
      setShowMore(!showMore);
    } else if (showMore) {
      setShowMoreTagsCount(maxShowItem);
      setShowMore(!showMore);
    }
  };

  const groupTag =
    groupName == "Name(s)"
      ? "People"
      : groupName == "Place(s)"
      ? "Places"
      : groupName == "Organization(s)"
      ? "Organizations"
      : groupName == "Parties"
      ? "Parties"
      : "Attorneys";
  const tagsLabels = tags?.map((value, index) => {
    if (index > mShowMoreTagsCount && !(isFromBot && !isFromArticle && index === tags?.length - 1)) return;
    let tag = value;
    if (isFromFilter) {
      if (value && typeof (value) === 'string') {
        tag = {
          label: value,
          status: filterAttributes && filterAttributes[groupTag]?.length ? filterAttributes[groupTag]?.includes(value) : false
        }
      } else if (tag) {
        tag.status = filterAttributes && filterAttributes[groupTag]?.length ? filterAttributes[groupTag]?.includes(value.label) : false
      }
    }
    return (
      <Tag
        isFromFilter={isFromFilter}
        key={`${title}-${tag.label}`}
        aka={tag.isAKA ? `AKA` : false}
        label={tag.label}
        status={tag.status}
        onPress={() => onPressTag(groupName, tag.label, tag.status)}
        selectable={selectable}
        tagStyle={tagStyle}
        groupName={groupName}
      />
    )
  });

  let otherInputView = false;

  const selectedAnswer = tags?.findIndex(answer => answer.status && answer.type === questionsTypes.OTHER) || 0;

  if (selectedAnswer !== -1) {
    const { value, label } = tags[selectedAnswer];
    otherInputView = (
      <Input
        autoComplete={'no'}
        defaultValue=""
        style={styles.otherQuestionInput}
        value={value}
        onChange={(e) => onPressTag(groupName, label, e.target.value)}
        autoFocus={false}
        autoCorrect={false}
        spellCheck={false}
      />
    );
  }

  const showMoreLable = () => {
    return (
      <>
        {(tags?.length - (isFromArticle ? 1 : 2)) > maxShowItem && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <span
              style={styles.showMore}
              onClick={() => onShowMore(tags?.length - (isFromArticle ? 0 : 1))}
            >
              {showMore || tags?.length < mShowMoreTagsCount
                ? ""
                : "Show More (" + (tags?.length - (isFromArticle ? 1 : 2) - mShowMoreTagsCount) + ")"}
            </span>
            <span
              style={styles.showLess}
              onClick={() => onShowMore(tags?.length - (isFromArticle ? 0 : 1), true)}
            >
              {mShowMoreTagsCount > 6 ? "Show Less" : ""}
            </span>
          </div>
        )}
      </>
    );
  };
  return (
    <div style={lastChild ? styles.containerLastChild : styles.container}>
      {isAttributeView && <TitleGroup label={title} theme={theme} />}
      <div style={{ ...styles.row, marginTop: isAttributeView ? 10 : 0 }}>{tagsLabels}</div>
      {otherInputView}
      {(isFromBot || isFromArticle) && showMoreLable()}
    </div>
  );
};

export default GroupTags;
