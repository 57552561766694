import React, { useState } from 'react';
import Styles from './mapView.module.css';

const ScrollButton = ({ direction, canScroll, articleDivRef }) => {
  const [scrollInterval, setScrollInterval] = useState(null);

  const handleMouseDown = () => {
    setScrollInterval(setInterval(() => {
      const scrollAmount = direction === 'left' ? -200 : 200; // Adjust scroll speed as needed
      articleDivRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }, 50)); 
  };

  const handleMouseUp = () => {
    clearInterval(scrollInterval);
  };

  return (
    <button
    style={{ display: canScroll ? "flex" : "none" }}
      className={`${Styles.scrollButton} ${Styles[direction]}`}
      onMouseDown={canScroll ? handleMouseDown : null}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
      disabled={!canScroll}
    >
      {direction === 'left' ? '<' : '>'}
    </button>
  );
};

export default ScrollButton;
