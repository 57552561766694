
const createStyle = () => {
  return {
    container: {
      // borderBottomWidth: 1,
      // borderBottomColor: theme.dark ? '#283147' : '#F1F1F1',
    },
    containerLastChild: {
      paddingVertical: 20,
      borderBottomWidth: 1,
      borderBottomColor: 'transparent',
    },
    row: {
      marginTop: 10,
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    otherQuestionInput: {
      marginVertical: 10,
      borderBottomColor: '#C0C7D6',
      borderBottomWidth: 1,
      paddingBottom: 6,
      paddingHorizontal: 10,
      color: '#686F7F',
      fontFamily: 'Proxima Nova',
      fontSize: 14,
      width: '100%',
      marginBottom: 20,
      letterSpacing: -0.1,
    },
    filterRow: {
      paddingBottom: 20,
      borderBottomWidth: 1,
      borderColor: '#F1F1F1',
    },
    showMore: {
      marginBottom: 10,
      fontFamily: 'Proxima Nova',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: 14,
      letterSpacing: -0.01,
      color: '#078167'
    },
    showLess: {
      marginBottom: 10,
      fontFamily: 'Proxima Nova',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: 14,
      letterSpacing: -0.01,
      color: '#697080'
    },
  }
}

export default createStyle;
