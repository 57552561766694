import React, { useEffect, useState } from "react";
import { GreenBgButton } from "../../../components/buttons/greenBgButton";
import { Modal } from "@mui/material";
import Slide from "@mui/material/Slide";
import CloseIconWhite from "../../../images/svg/closeWhite";
import BackIconPopup from "../../../images/svg/backIconPopup";
import { PasswordField } from "../../../components/fields/passwordField";
import { EmailField } from "../../../components/fields/emailField";
import { useLazyQuery } from "react-apollo";
import { CircularProgress } from "@mui/material";
import { GET_NYLAS_AUTH_TOKEN } from "../../../queries/queries";
import TwoBtnAlert from "../../../components/alert/twoBtnAlert";
import LockAppPassword from "../../../images/svg/lock_app_password";
import Styles from "./iCloud.module.css";

const ICloudAuthModal = (props) => {
  const { onBackdropPress, onContinuePress } = props;
  const [notFound, setNotFound] = useState();
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState({ status: null, msg: "" });
  const [password, setPassword] = useState("");
  const [checkFormValid, setCheckFormValid] = useState(false);
  const [passwordValid, setPasswordValid] = useState({ status: null, msg: "" });
  const [showAuth, setShowAuth] = useState(false);

  const [getNylasAuthToken, { loading, data, error }] = useLazyQuery(
    GET_NYLAS_AUTH_TOKEN,
    {
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (!loading && data && data.getNylasAuthToken) {
      if (data.getNylasAuthToken.status) {
        onContinuePress(data.getNylasAuthToken.data);
      } else {
        setNotFound(true);
      }
    }
  }, [loading, data, error]);

  const backToAppPassword = () => {
    setCheckFormValid(false);
    setEmailValid({ status: null, msg: "" });
    setPasswordValid({ status: null, msg: "" });
    setShowAuth(false);
  };

  const onLogin = () => {
    setCheckFormValid(true);
    if (emailValid.status && passwordValid.status) {
      getNylasAuthToken({
        variables: {
          email: email,
          password: password,
        },
      });
    }
  };

  return (
    <Modal
      open={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Slide in={true}>
        <div className={Styles.theme4}>
          {showAuth ? (
            <div className={Styles.containerWeb}>
              <div className={Styles.header}>
                <div className={Styles.theme5} onClick={backToAppPassword}>
                  <BackIconPopup color="#697080" className={Styles.theme10} />
                </div>
                <span className={Styles.headerTitleApp3}>
                  {"App-Specific Password"}
                </span>
                <div className={Styles.theme5} onClick={onBackdropPress}>
                  <CloseIconWhite color="#697080" className={Styles.theme14} />
                </div>
              </div>
              <div className={Styles.theme6}>
                <div className={Styles.content3}>
                  <LockAppPassword />
                  <span className={Styles.headerTitleApp2}>
                    {"Enter your Apple ID and app-specific password"}
                  </span>
                  <span className={Styles.how_txt7}>
                    {
                      "You’ll need your usual Apple ID email address, and your new app-specific password that you just created."
                    }
                  </span>
                </div>

                <div className={Styles.contentWeb}>
                  <EmailField
                    isIcloud={true}
                    placeholder=" "
                    email={email}
                    setEmail={setEmail}
                    checkFormValid={checkFormValid}
                    emailValid={emailValid}
                    setEmailValid={setEmailValid}
                  />
                  <PasswordField
                    placeholder=" "
                    title="App-Specific Password"
                    isIcloud={true}
                    password={password}
                    setPassword={setPassword}
                    checkFormValid={checkFormValid}
                    passwordValid={passwordValid}
                    setPasswordValid={setPasswordValid}
                    showPasswordIcon={true}
                  />
                </div>
                {loading && (
                  <div className={Styles.activityIndicator}>
                    <CircularProgress color="inherit" />
                  </div>
                )}
                {notFound && (
                  <TwoBtnAlert
                    description={data?.getNylasAuthToken?.message}
                    rightBtnTxt={"Try again"}
                    onLeftBtnClick={() => {
                      setNotFound(false);
                    }}
                    onRightBtnClick={() => {
                      setNotFound(false);
                    }}
                  />
                )}
              </div>

              <div className={Styles.theme7}>
                <div className={Styles.theme8} />
                <div className={Styles.theme9} />
              </div>

              <div
                className={Styles.button}
                style={{ marginRight: 20, marginLeft: 30, marginBottom: 20 }}
              >
                <GreenBgButton
                  padding={"10px 40px 10px 40px"}
                  loading={false}
                  onClick={onLogin}
                  variant="contained"
                >
                  <div className={Styles.backBtnText}>Login</div>
                </GreenBgButton>
              </div>
            </div>
          ) : (
            <div className={Styles.containerWeb}>
              <div className={Styles.closeTheme}>
                <CloseIconWhite
                  onClick={onBackdropPress}
                  color="#697080"
                  className={Styles.theme12}
                />
              </div>
              <span className={Styles.headerTitleApp}>
                {"App-Specific Password"}
              </span>
              <div className={Styles.content4Web}>
                <LockAppPassword />
                <span className={Styles.headerTitleApp2}>
                  {"Generate an app-specific password"}
                </span>
                <span className={Styles.how_txt7}>
                  {
                    "You’ll need to sign into your Apple ID account, and create a new "
                  }
                  <a className={Styles.theme13} target="_blank">
                    app-specific password
                  </a>
                  {" to authorize Ferret to sync your Apple Contacts."}
                </span>
                <div className={Styles.txtContainer1}>
                  <span className={Styles.how_txt3}>{"1."}</span>
                  <span className={Styles.how_txt4}>
                    {"Go to"}
                    <a
                      href="https://appleid.apple.com/sign-in"
                      className={Styles.theme13}
                      style={{ paddingLeft: 2.5 }}
                      target="_blank"
                    >
                      appleid.apple.com
                    </a>
                  </span>
                </div>

                <div className={Styles.txtContainer1}>
                  <span className={Styles.how_txt3}>{"2."}</span>
                  <span className={Styles.how_txt4}>
                    {"Sign in with your usual Apple ID"}
                  </span>
                </div>

                <div className={Styles.txtContainer1}>
                  <span className={Styles.how_txt3}>{"3."}</span>
                  <span className={Styles.how_txt4}>
                    {
                      "Under Sign-In and Security, Select Generate an app-specific password (it’s at the bottom of the list)"
                    }
                  </span>
                </div>

                <div className={Styles.txtContainer1}>
                  <span className={Styles.how_txt3}>{"4."}</span>
                  <span className={Styles.how_txt4}>
                    {
                      "Click Create an App-Specific Password and follow the prompts. Your new password will be in the format xxxx-xxxx-xxxx-xxxx"
                    }
                  </span>
                </div>

                <div className={Styles.txtContainer1}>
                  <span className={Styles.how_txt3}>{"5."}</span>
                  <span className={Styles.how_txt4}>
                    {"Copy that password and hit the button below"}
                  </span>
                </div>

                <div className={Styles.txtContainer2}>
                  <div className={Styles.how_txt5} />
                  <div className={Styles.how_txt6} />
                </div>

                <div className={Styles.button}>
                  <GreenBgButton
                    padding={"10px 40px 10px 40px"}
                    loading={false}
                    onClick={() => {
                      setShowAuth(true);
                    }}
                    variant="contained"
                  >
                    <div className={Styles.backBtnText}>
                      I’ve Created My App-Specific Password
                    </div>
                  </GreenBgButton>
                </div>
              </div>
            </div>
          )}
        </div>
      </Slide>
    </Modal>
  );
};

export default ICloudAuthModal;
