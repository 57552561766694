import moment from "moment-timezone";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useMutation, useSubscription } from "react-apollo";
import {
  CHECK_META_SEARCH_DATA,
  GET_CONFIGURATION,
  SAVE_ADHOC_SEARCH,
  USER_UPDATES,
} from "../../../queries/queries";
import Variables from "../../../utils/Variables";
import createStyle from "./styles";
import { useLazyQuery } from "react-apollo";
import { useLocation, useNavigate } from "react-router-dom";
import { searchResultStore } from "../../../store/searchResultStore";
import {
  filterArticles,
  formatArticleAttributes,
  returnIntialTags,
  sortArticles,
} from "../../../utils/filterUtils";
import savedSearchData from "../../../utils/savedSearchData";
import {
  capitalizeCase,
  parseDate,
  removeDuplicateTags,
  titleCase,
} from "../../../utils/utils";
import useIntialModalData from "../../../components/searchModalV2/useIntialModalData";
import useRefineSearch from "../../../components/searchModalV2/useRefineSearch";
import "./style.css";
import Styles from "./articleLayout.module.css";
import SectionListView from "../../components/articleList/SectionListView";
import PersonalInfoSummary from "../../components/PersonInfo/personalInfoSummary";
import FilterLeftSection from "../../components/filterLeftSection/FilterLeftSection";
import FiltersView from "../../components/filtersView/filtersView";
import BackImage from "../../images/svg/back-web.svg";
import SaveSearchResultModal from "../../components/searchResult/saveSearchResultModal";
import { searchFormStore } from "../../../store/searchFormStore";
import { WhiteBgButtonGreenBorder } from "../../../components/buttons/whiteBgButtonGreenBorder";
import { generatePdf } from "../../../utils/pdfGeneratorUtils";
import MapView from "../../../components/map/mapView";
import { UserContext } from "../../../contexts/userContext";
import SubscriptionPlansModal from "../../../components/subscriptionPlans/subscriptionPlansModal";
import SearchLimitExceedModal from "../../../components/searchLimitExceedModal";
import { ModelType } from "../../../components/searchLimitExceedModal/searchLimitExceedModal";

var _ = require("lodash");

const defaultProfilePicture = require("../../../images/default-avatar.png");
let originalArticles = [];
let theme;
let riskLevelsStatuses = {
  high: true,
  medium: true,
  low: true,
};
let temporaryStatusesOfFilters = null;
let backCount = 0;

let hasData = false;
let showMetaSearchDialog = false;
let metaAPICount = 0;
let hasDataTimer;
let metaSearchArticleCount = 0;

const ArticleLayout = (props) => {
  const filterRef = useRef(null);
  const location = useLocation();
  const feed = location.state;
  const navigate = useNavigate();
  const setEditSearchData = searchFormStore((state) => state.setEditSearchData);

  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    if (!location || !location.state) {
      navigate("/search", { replace: true });
    }
  }, []);

  const {
    mPhoneNumberInput,
    mCountryInput,
    mCityInput,
    mStateInput,
    mAgeInput,
    queryData,
    savedTracerQueryId,
    savedTahoeId,
    lastQuery,
    isHeader = false,
    isSetOldFilter = false,
    potentialMatchId,
    searchIds,
    articleSource = "SEARCH",
    linkedInData,
    feedbackOptions,
    profilePic,
    isFromSavedSearch,
    fromMultipleSavedSearch,
    isEditedSavedsearch,
  } = feed ?? {};
  const fullName = feed?.fullName;
  const searchTerm = feed?.searchTerm;
  const style = createStyle(theme);
  let initialSearchSettings = {};
  if (!!feed && feed.hasOwnProperty("searchSettings"))
    initialSearchSettings = feed.searchSettings;
  const setOpenEditSearch = searchFormStore((state) => state.setOpenEditSearch);
  const openMapDashboard = searchResultStore((state) => state.openMapDashboard);
  const profilePic1 = searchResultStore((state) => state.profilePic1);
  const setProfilePic1 = searchResultStore((state) => state.setProfilePic1);
  const feedbackOptions1 = searchResultStore((state) => state.feedbackOptions1);
  const setHasData = searchResultStore((state) => state.setHasData);
  const processArticleDone = searchResultStore(
    (state) => state.processArticleDone
  );
  const setProcessArticleDone = searchResultStore(
    (state) => state.setProcessArticleDone
  );

  const processArticleInProgress = searchResultStore(
    (state) => state.processArticleInProgress
  );
  const setProcessArticleInProgress = searchResultStore(
    (state) => state.setProcessArticleInProgress
  );

  const showProcessArticlesModal = searchResultStore(
    (state) => state.showProcessArticlesModal
  );
  const setShowProcessArticlesModal = searchResultStore(
    (state) => state.setShowProcessArticlesModal
  );
  const setFeedbackOptions1 = searchResultStore(
    (state) => state.setFeedbackOptions1
  );
  const linkedInUserData = searchResultStore((state) => state.linkedInUserData);
  const setLinkedInUserData = searchResultStore(
    (state) => state.setLinkedInUserData
  );
  const categoryFilterData = props?.feed?.categoryFilterData;
  const configurationData = searchResultStore(
    (state) => state.configurationData
  );
  const setConfigurationData = searchResultStore(
    (state) => state.setConfigurationData
  );
  const ConfigurationLoading = searchResultStore(
    (state) => state.ConfigurationLoading
  );
  const setConfigurationLoading = searchResultStore(
    (state) => state.setConfigurationLoading
  );
  const searchSettings = searchResultStore((state) => state.searchSettings);
  const filteredArticles = searchResultStore((state) => state.filteredArticles);
  const setFilteredArticles = searchResultStore(
    (state) => state.setFilteredArticles
  );
  const filteredArticles2 = searchResultStore(
    (state) => state.filteredArticles2
  );
  const setFilteredArticles2 = searchResultStore(
    (state) => state.setFilteredArticles2
  );

  const originalfilteredArticles = searchResultStore(
    (state) => state.originalfilteredArticles
  );
  const setOriginalFilteredArticles = searchResultStore(
    (state) => state.setOriginalFilteredArticles
  );

  const setEnableFilter = searchResultStore((state) => state.setEnableFilter);

  const articleFilterData = searchResultStore(
    (state) => state.articleFilterData
  );
  const setArticleFilterData = searchResultStore(
    (state) => state.setArticleFilterData
  );

  const personalInfo = searchResultStore((state) => state.personalInfo);
  const setPersonalInfo = searchResultStore((state) => state.setPersonalInfo);

  const aKAList = searchResultStore((state) => state.aKAList);

  const articleTypeCount = searchResultStore((state) => state.articleTypeCount);
  const setArticleTypeCount = searchResultStore(
    (state) => state.setArticleTypeCount
  );

  const setIsFilterApplied = searchResultStore(
    (state) => state.setIsFilterApplied
  );

  const recentSearchId = searchResultStore((state) => state.recentSearchId);

  const setTotalArticle = searchResultStore((state) => state.setTotalArticle);

  const saveSearchVariables = searchResultStore(
    (state) => state.saveSearchVariables
  );
  const setSaveSearchVariables = searchResultStore(
    (state) => state.setSaveSearchVariables
  );

  const savedContactID = searchResultStore((state) => state.savedContactID);
  const setSavedContactID = searchResultStore(
    (state) => state.setSavedContactID
  );

  const isShowPersonalInfo = searchResultStore(
    (state) => state.isShowPersonalInfo
  );

  const selectedFilter = searchResultStore((state) => state.selectedFilter);
  const setSelectedFilter = searchResultStore(
    (state) => state.setSelectedFilter
  );

  const sortState = searchResultStore((state) => state.sortState);
  const setSortState = searchResultStore((state) => state.setSortState);

  const showStrongMatchArticles = searchResultStore(
    (state) => state.showStrongMatchArticles
  );
  const setShowStrongMatchArticles = searchResultStore(
    (state) => state.setShowStrongMatchArticles
  );

  const showWeakMatchArticles = searchResultStore(
    (state) => state.showWeakMatchArticles
  );
  const setShowWeakMatchArticles = searchResultStore(
    (state) => state.setShowWeakMatchArticles
  );

  const filterAttributes = searchResultStore((state) => state.filterAttributes);
  const setFilterAttributes = searchResultStore(
    (state) => state.setFilterAttributes
  );

  const readFilter = searchResultStore((state) => state.readFilter);
  const setReadFilter = searchResultStore((state) => state.setReadFilter);

  const unreadFilter = searchResultStore((state) => state.unreadFilter);
  const setUnreadFilter = searchResultStore((state) => state.setUnreadFilter);

  const attributes = searchResultStore((state) => state.attributes);
  const setAttributes = searchResultStore((state) => state.setAttributes);

  const headerTags = searchResultStore((state) => state.headerTags);
  const setHeaderTags = searchResultStore((state) => state.setHeaderTags);

  const saveRelevancyData = searchResultStore(
    (state) => state.saveRelevancyData
  );
  const setSaveRelevancyData = searchResultStore(
    (state) => state.setSaveRelevancyData
  );

  const deleteRelevancyData = searchResultStore(
    (state) => state.deleteRelevancyData
  );
  const setDeleteRelevancyData = searchResultStore(
    (state) => state.setDeleteRelevancyData
  );

  const verified = searchResultStore((state) => state.verified);
  const setVerified = searchResultStore((state) => state.setVerified);

  const partial = searchResultStore((state) => state.partial);
  const setPartial = searchResultStore((state) => state.setPartial);

  const needsReview = searchResultStore((state) => state.needsReview);
  const setNeedsReview = searchResultStore((state) => state.setNeedsReview);

  const refreshList = searchResultStore((state) => state.refreshList);
  const setRefreshList = searchResultStore((state) => state.setRefreshList);

  const setShowHeader = searchResultStore((state) => state.setShowHeader);

  const showSaveConfirmationAlert = searchResultStore(
    (state) => state.showSaveConfirmationAlert
  );
  const setShowSaveConfirmationAlert = searchResultStore(
    (state) => state.setShowSaveConfirmationAlert
  );

  const modalFilterVisible = searchResultStore(
    (state) => state.modalFilterVisible
  );

  const isPdfGenerating = searchResultStore(
    (state) => state.isPdfGenerating
  );
  const setIsPdfGenerating = searchResultStore(
    (state) => state.setIsPdfGenerating
  );

  const [filterHeight, setFilterHeight] = useState(47);

  const [chkBxNotifyUser, setChkBxNotifyUser] = useState(false);
  const [saveSearch, setSaveSearch] = useState(false);
  const [monitorSearch, setMonitorSearch] = useState(false);
  const [isArticleOpened, setIsArticleOpened] = useState(false);
  const [openMap, setOpenMap] = useState(false);
  const [mapFilteredArticles, setMapFilteredArticles] = useState(false);
  const [isSearchLimitExceed, setSearchLimitExceed] = useState(false);
  const [subscriptionModalVisible, setSubscriptionModalVisible] =
    useState(false);

  const timezone = moment.tz.guess();

  const {
    refineSearch,
    data,
    loading,
    getArticleDetailsFromRefineSearch,
    mapSingleArticle,
    getReadArticlesIds,
    getPersonalInfo,
    getArticleFilterData,
    getPotentialMatchId,
    getContactId,
  } = useRefineSearch();

  let isFirstOpen = false;

  const {
    loadingData,
    searchSettings: searchSettingsRelevancy,
    getModalDataForMetaTag,
  } = useIntialModalData({}, "", "");

  useEffect(() => {
    if (
      !loadingData &&
      searchSettingsRelevancy &&
      searchSettingsRelevancy?.initialSearchString?.length
    ) {

      let searchData = { ...searchSettingsRelevancy };
      let data = {
        inNewSearch: true,
        fromEditSearch: true,
        isRecentSearch: false,
        fromBackSearch: false,
        searchSettings: searchData,
        cleanStateOnBlur: false,
        isAutoFocus: false,
        isEdit: true,
        mPhoneNumberInput: mPhoneNumberInput,
        mCountryInput: mCountryInput,
        mCityInput: mCityInput,
        mStateInput: mStateInput,
        mAgeInput: mAgeInput,
        mQueryData: queryData,
        mSavedTracerQueryId: savedTracerQueryId,
        mSavedTahoeId: savedTahoeId,
        recentSearchId: feed?.irrelevantArticleId,
        qaMetaData: lastQuery,
      };

      setEditSearchData(data);
    }
  }, [searchSettingsRelevancy]);

  useEffect(() => {
    if (
      linkedInData &&
      Object.getOwnPropertyNames(linkedInUserData).length == 0
    )
      setLinkedInUserData(linkedInData);

    if (feedbackOptions && !feedbackOptions1)
      setFeedbackOptions1(feedbackOptions);

    if (profilePic && !profilePic1) setProfilePic1(profilePic);
  }, []);

  const broadcast = new BroadcastChannel("background-linkedin-message");

  useEffect(() => {
    if (broadcast) {
      broadcast.onmessage = (res) => {
        updateLinkedInView(res.data.data);
      };
    }
  }, []);

  const { data: apolloSubscriptionData, error: apolloSubscriptionError } =
    useSubscription(USER_UPDATES);

  useEffect(() => {
    if (apolloSubscriptionData) {
      updateLinkedInView(apolloSubscriptionData.userUpdates);
    }
  }, [apolloSubscriptionData, apolloSubscriptionError]);

  const updateLinkedInView = (payload) => {
    if (
      payload?.type === "linkedin-profile-match" &&
      payload?.potentialMatchId === Variables.potentialMatchId
    ) {
      if (payload?.linkedinProfile) {
        let data = JSON.parse(payload?.linkedinProfile);
        let jsonData = data && data.length > 0 && JSON.parse(data);
        setProfilePic1(jsonData?.profile_picture_src);
        if (jsonData) {
          jsonData.education = jsonData.education?.map((item) => {
            let dates = parseDate(item.dates);
            return { ...item, ...dates };
          });
          jsonData.experience = jsonData.experience?.map((item) => {
            let dates = parseDate(item.dates);
            return { ...item, ...dates };
          });
        }
        setLinkedInUserData(jsonData);
      }
    }
  };

  useEffect(() => {
    if (refreshList == true) {
      setRefreshList(false);

      let query = JSON.parse(JSON.stringify(lastQuery));
      let meta_data = JSON.parse(query.variables.fullname.meta_data);
      if (
        saveRelevancyData?.Organizations &&
        saveRelevancyData?.Organizations?.length
      ) {
        meta_data.Company =
          meta_data.Company && meta_data.Company != ""
            ? meta_data.Company +
            ";" +
            saveRelevancyData?.Organizations?.join(";")?.toString()
            : saveRelevancyData?.Organizations?.join(";")?.toString();
      }
      if (saveRelevancyData?.Places && saveRelevancyData?.Places?.length) {
        meta_data.Place =
          meta_data.Place && meta_data.Place != ""
            ? meta_data.Place +
            ";" +
            saveRelevancyData?.Places?.join(";")?.toString()
            : saveRelevancyData?.Places?.join(";")?.toString();
      }

      if (
        deleteRelevancyData?.Organizations &&
        deleteRelevancyData?.Organizations?.length
      ) {
        meta_data.Company =
          meta_data.Company &&
          meta_data.Company != "" &&
          meta_data.Company.split(";");

        meta_data.Company = meta_data?.Company?.filter((item) => {
          return !deleteRelevancyData?.Organizations?.includes(item);
        })
          .join(";")
          ?.toString();

        if (
          saveRelevancyData?.Organizations &&
          saveRelevancyData?.Organizations?.length
        ) {
          saveRelevancyData.Organizations =
            saveRelevancyData?.Organizations?.filter((item) => {
              return !deleteRelevancyData?.Organizations?.includes(item);
            });
        }
      }
      if (deleteRelevancyData?.Places && deleteRelevancyData?.Places?.length) {
        meta_data.Place =
          meta_data.Place &&
          meta_data.Place != "" &&
          meta_data.Place.split(";");

        meta_data.Place = meta_data?.Place?.filter((item) => {
          return !deleteRelevancyData?.Places?.includes(item);
        })
          .join(";")
          ?.toString();

        if (saveRelevancyData?.Places && saveRelevancyData?.Places?.length) {
          saveRelevancyData.Places = saveRelevancyData?.Places?.filter(
            (item) => {
              return !deleteRelevancyData?.Places?.includes(item);
            }
          );
        }
      }

      query.variables.fullname.meta_data = JSON.stringify(meta_data);

      query.variables.fullname.refresh = true

      if (
        query.variables.fullname?.contactId == undefined &&
        Variables.savedRelevancyContactId?.length > 0
      )
        query.variables.fullname.contactId = Variables.savedRelevancyContactId;

      const name =
        searchSettings?.initialSearchString ??
        feed.searchSettings?.initialSearchString ??
        fullName ??
        "";
      const searchType =
        meta_data?.Person && meta_data?.Person != ""
          ? "PERSON"
          : meta_data?.Company?.toLowerCase() == name?.toLowerCase()
            ? "COMPANY"
            : meta_data?.Location?.toLowerCase() == name?.toLowerCase()
              ? "LOCATION"
              : meta_data?.Place?.toLowerCase() == name?.toLowerCase()
                ? "PLACE"
                : meta_data?.Nationality?.toLowerCase() == name?.toLowerCase()
                  ? "PLACE"
                  : "PERSON";
      getModalDataForMetaTag(
        searchType,
        meta_data,
        name,
        mPhoneNumberInput,
        mCountryInput,
        mCityInput,
        mStateInput,
        mAgeInput
      );
      refineSearch(query);

      setDeleteRelevancyData("");

      if (Variables.isAddSearches && Variables.savedSearchId) {
        setSavedContactID(Variables.savedSearchId);
        Variables.savedSearchId = undefined;
      }
    }
  }, [refreshList]);

  useEffect(() => {
    return () => {
      originalArticles = [];
      temporaryStatusesOfFilters = null;
    };
  }, []);

  useEffect(() => {
    if (!!data && !loading && data && data.hasOwnProperty("refine_search")) {
      if (
        data?.refine_search?.result_type?.toUpperCase() ===
        "SUBSCRIPTION LIMIT REACHED"
      ) {
        // Alert.alert(
        //   "Error",
        //   data?.refine_search?.result_type?.toUpperCase(),
        //   [
        //     {
        //       text: "OK",
        //       style: "default",
        //     },
        //   ],
        //   { cancelable: true }
        // );
        return;
      }
      Variables.potentialMatchId = getPotentialMatchId();
      Variables.contactId = getContactId();
      const articleFilterData = getArticleFilterData();
      setArticleFilterData(articleFilterData.filter((item) => item.count));
      const parsedArticles = getArticleDetailsFromRefineSearch()
        ?.filter((item) => {
          return item;
        })
        .map((item) =>
          mapSingleArticle(
            item,
            searchSettings?.initialSearchString ??
            feed.searchSettings?.initialSearchString ??
            fullName ??
            "",
            getReadArticlesIds()
          )
        );
      originalArticles = getFormatedArticles(parsedArticles);
      setOriginalFilteredArticles(originalArticles);
      filterAction();
      setPersonalInfo(getPersonalInfo());
    }
  }, [data, loading]);

  useEffect(() => {
    if (searchSettings)
      setSaveSearchVariables(
        savedSearchData(
          searchSettings?.intitialMetadata,
          searchSettings?.initialSearchString,
          feed?.irrelevantArticleId,
          props?.feed?.articleTypeCount,
          savedContactID,
          savedTracerQueryId,
          savedTahoeId,
          queryData,
          aKAList
        )
      );
  }, [searchSettings, savedContactID, aKAList]);

  const [getConfigData, { loading: configLoading, data: configData }] =
    useLazyQuery(GET_CONFIGURATION);

  useEffect(() => {
    if (configData && !configLoading) {
      setConfigurationData(configData);
      setConfigurationLoading(configLoading);
    }
  }, [configData, configLoading]);

  const [
    saveAdhocSearch,
    { data: saveData, loading: saveLoading, error: saveError },
  ] = useMutation(SAVE_ADHOC_SEARCH);

  useEffect(() => {
    if (!saveLoading && (saveData || saveError)) {
      if (saveData?.saveAdhocSearch?.data?.toLowerCase() === "limit reached") {
        const updateUserInfo = { ...user, watchedLimitExceed: true };
        setUser(updateUserInfo);
        setSearchLimitExceed(true);
      } else {
        goBack();
      }
    }
  }, [saveData, saveLoading, saveError]);

  const startFreeTrial = async () => {
    setSubscriptionModalVisible(true);
    setSearchLimitExceed(false);
  };
  const cancelPopup = () => {
    setSubscriptionModalVisible(false);
    setSearchLimitExceed(false);
    goBack();
  };

  useEffect(() => {
    getConfigData();
    Variables.savedRelevancyContactId = "";
  }, []);

  useEffect(() => {
    async function fetchData() {
      reloadScreenData();
    }
    fetchData();
  }, []);

  const [
    checkMetaSearch,
    {
      loading: checkMetaSearchLoading,
      data: checkMetaSearchData,
      error: checkMetaSearchError,
    },
  ] = useLazyQuery(CHECK_META_SEARCH_DATA, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (!checkMetaSearchLoading && checkMetaSearchData) {
      let data = checkMetaSearchData.checkMetaSearchData;
      switch (true) {
        case data.hasData == true && data.refresh == false:
          callGetMetaSearchAfter10sec(data);
          break;
        case data.hasData == true && data.refresh == true:
          callGetMetaSearchAfter10sec(data);
          onGetMetaSearchResponse(false);
          break;
        case data.hasData == false && data.refresh == false:
          onGetMetaSearchResponse(true);
          break;
      }
      metaAPICount++;
    }
  }, [checkMetaSearchLoading, checkMetaSearchData]);

  const onGetMetaSearchResponse = (finalOutputReceive) => {
    if (finalOutputReceive) {
      hasData = false;
      setHasData?.(false);
      setProcessArticleDone(processArticleInProgress);
      setProcessArticleInProgress([]);
    }
    if (metaAPICount > 0) {
      showMetaSearchDialog = true;
      var query = JSON.parse(JSON.stringify(lastQuery));
      query.variables.fullname.refresh = true
      refineSearch(query);
    }
  };

  const callGetMetaSearchAfter10sec = (data) => {
    if (!hasData) {
      setHasData(data.hasData);
      hasData = data.hasData;
    }

    setProcessArticleInProgress(data.source);
    setProcessArticleDone(data.source);
    hasDataTimer = setTimeout(() => {
      callGetMetaSearchData();
    }, 10000);
  };

  const callGetMetaSearchData = () => {
    checkMetaSearch({
      variables: {
        searchIds: searchIds,
      },
    });
  };

  useEffect(() => {
    callGetMetaSearchData();
  }, []);

  useEffect(() => {
    if (categoryFilterData) {
      setAttributes(
        returnIntialTags(
          categoryFilterData?.Places,
          categoryFilterData?.Organizations,
          searchSettings,
          aKAList,
          categoryFilterData?.People,
          categoryFilterData?.Parties,
          categoryFilterData?.Attorneys
        )
      );
    }
  }, [categoryFilterData]);

  const reloadScreenData = () => {
    if (Variables.articleIdMarkNo && filteredArticles.length > 0) {
      setTimeout(() => {
        let riskCategory = "";
        const mmArticle = filteredArticles.filter((item) => {
          if (
            item?.id
              ? item.id == Variables.articleIdMarkNo
              : item.articleId == Variables.articleIdMarkNo
          ) {
            riskCategory = item?.riskCategory;
            return false;
          }
          return true;
        });
        originalArticles = originalArticles.filter((item) => {
          if (
            item?.id
              ? item.id == Variables.articleIdMarkNo
              : item.articleId == Variables.articleIdMarkNo
          ) {
            riskCategory = item?.riskCategory;
            return false;
          }
          return true;
        });
        setFilteredArticles(formatArticleAttributes(mmArticle));
        const countArticle = {
          highRisk:
            articleTypeCount?.highRisk - (riskCategory == "high" ? 1 : 0),
          news: articleTypeCount?.news - (riskCategory == "low" ? 1 : 0),
          alerts: articleTypeCount?.alerts - (riskCategory == "medium" ? 1 : 0),
        };
        setArticleTypeCount(countArticle);
        Variables.articleIdMarkNo = false;
      }, 200);
    }

    if (Variables.isAddSearches && Variables.savedSearchId) {
      setSavedContactID(Variables.savedSearchId);
      Variables.savedSearchId = undefined;
    }
  };

  const filterAction = (isRiskRankingApplied) => {
    if (!originalArticles || originalArticles.length === 0) {
      setFilteredArticles([]);
      return;
    }
    const selectedParentCategory = getSelectedParentCategory();
    let filterByArticleType = [...originalArticles];
    filterByArticleType = filterByArticleType.filter(
      (item) => riskLevelsStatuses[item.riskCategory]
    );
    setTotalArticle(filterByArticleType.length);
    if (
      selectedParentCategory &&
      (selectedParentCategory.length || isFirstOpen)
    ) {
      filterByArticleType = filterByArticleType.filter(
        (item) =>
          selectedParentCategory.includes(item.parentCategory) ||
          selectedParentCategory.includes("All")
      );
    }
    if (!isRiskRankingApplied) {
      setFilteredArticles(formatArticleAttributes(filterByArticleType));
      setMapFilteredArticles(formatArticleAttributes(filterByArticleType))
    } else {
      filterArticleTypeCount();
    }
    setEnableFilter(true);
  };

  const getSelectedParentCategory = () => {
    let categoryType = [];
    if (temporaryStatusesOfFilters) {
      temporaryStatusesOfFilters?.[0]?.filters?.forEach((type) => {
        if (type.status) {
          categoryType = [...categoryType, type?.label];
        } else if (type.count && type.count > 0) isFirstOpen = true;
      });
    }
    return categoryType;
  };

  const getRandomDate = () => {
    const start = new Date(2012, 0, 1);
    const end = new Date();
    const randomDate = new Date(
      start.getTime() + Math.random() * (end.getTime() - start.getTime())
    );

    return moment.tz(randomDate, timezone);
  };

  const getFormatedArticles = (articles) => {
    const now = moment();
    const riskCategories = {
      HIGH: "high",
      MEDIUM: "medium",
      LOW: "low",
    };

    return articles.map((article) => {
      if (article.createdAt) {
        article.date = moment(parseInt(article.createdAt)).fromNow();
        article.originalDate = moment(parseInt(article.createdAt)).tz(timezone);
      } else {
        if (!article.date) {
          article.date = getRandomDate().format();
        }
        let mDate = moment.parseZone(article.date).tz(timezone);
        article.originalDate = mDate;
        if (mDate.isValid()) {
          if (now.diff(mDate, "years") > 0) {
            article.date = mDate.format("L - LT");
          } else {
            article.date = mDate.fromNow();
          }
        } else {
          article.date = null;
        }
      }

      if (!article.profilePicture) {
        article.profilePicture = defaultProfilePicture;
      }
      article.riskCategory = riskCategories[article.riskCategory] ?? "low";
      return article;
    });
  };

  useEffect(() => {
    if (!feed) return;
    let testArticles =
      feed?.articles?.map((item) => {
        return { ...item };
      }) ?? [];

    if (originalfilteredArticles?.length > 0) {
      originalArticles = [...originalfilteredArticles];
    } else if (originalArticles?.length == 0) {
      originalArticles = getFormatedArticles(testArticles);

      // This will call in case of browser reload
      let reload = localStorage.getItem("reload");
      if (reload == "true") {
        localStorage.setItem("reload", false);
        setRefreshList(true);
      }
    }
    filterAction();
  }, [feed]);

  useEffect(() => {
    filterAction();
  }, [riskLevelsStatuses]);

  useEffect(() => {
    let tags = [
      ...(searchSettings?.initialSearchParams ??
        feed?.searchSettings?.initialSearchParams ??
        []),
    ];
    if (aKAList) tags?.splice(1, 0, ...aKAList);
    setHeaderTags(removeDuplicateTags(tags));
  }, [aKAList]);

  const filterArticleTypeCount = () => {
    if (articleFilterData) {
      let mArticleFilter = [...articleFilterData];
      const updatedArray = mArticleFilter.map((itemType) => {
        return {
          ...itemType,
          count: originalfilteredArticles.filter(
            (itemArticle) =>
              itemType?.category === (itemArticle.parentCategory ?? null)
          ).length,
        };
      });
      setArticleFilterData(updatedArray);
    }
  };

  useEffect(() => {
    if (articleFilterData == undefined && backCount == 0) {
      backCount++;
      navigate(-1);
    }
  }, []);

  const saveMySearches = () => {
    setShowSaveConfirmationAlert({ status: false, msg: "" });
    if (saveSearchVariables == undefined) return;
    saveSearchVariables.data.searchIds = searchIds;
    saveSearchVariables.data.notifyUser = chkBxNotifyUser;
    saveSearchVariables.data.monitorSearch = monitorSearch;
    saveSearchVariables.data.saveSearch = monitorSearch ? true : saveSearch;

    if (personalInfo?.personalSummary?.estimatedAge) {
      saveSearchVariables.data.age =
        personalInfo?.personalSummary?.estimatedAge;
    }

    saveAdhocSearch({
      variables: saveSearchVariables,
    });
    if (monitorSearch) Variables.isRefreshClosestContacts = true;
    Variables.isAddSearches = true;
  };

  const onFilterCompleted = (
    riskLevel,
    filterData,
    isRiskRankingApplied = false
  ) => {
    riskLevelsStatuses = riskLevel;
    temporaryStatusesOfFilters = filterData;
    let applyFilter = false;
    if (
      !riskLevelsStatuses?.high ||
      !riskLevelsStatuses?.low ||
      !riskLevelsStatuses?.medium
    ) {
      applyFilter = true;
    }
    if (temporaryStatusesOfFilters) {
      temporaryStatusesOfFilters?.[0]?.filters?.forEach((type) => {
        if (type.count && type.count > 0 && !type.status) {
          applyFilter = true;
        }
      });
    }
    setIsFilterApplied(applyFilter);
    filterAction(isRiskRankingApplied);
    const selectedParentCategory = getSelectedParentCategory();
    if (!selectedParentCategory.includes(selectedFilter)) {
      window.scrollTo(0, 0);
    }
  };
  //sorting articles
  useEffect(() => {
    setFilteredArticles2(sortArticles(filteredArticles2, sortState));
  }, [sortState]);

  useEffect(() => {
    //filtering articles
    setFilteredArticles2(
      filterArticles(
        showStrongMatchArticles,
        showWeakMatchArticles,
        readFilter,
        unreadFilter,
        filterAttributes,
        filteredArticles
      )
    );
  }, [
    showStrongMatchArticles,
    showWeakMatchArticles,
    readFilter,
    unreadFilter,
    filterAttributes,
    filteredArticles,
  ]);

  //filtering for article count
  useEffect(() => {
    setOriginalFilteredArticles(
      filterArticles(
        showStrongMatchArticles,
        showWeakMatchArticles,
        readFilter,
        unreadFilter,
        filterAttributes,
        originalArticles
      )
    );
  }, [
    showStrongMatchArticles,
    showWeakMatchArticles,
    readFilter,
    unreadFilter,
    filterAttributes,
    originalArticles,
  ]);

  useEffect(() => {
    filterArticleTypeCount();
  }, [originalfilteredArticles]);

  const goBack = () => {
    setShowSaveConfirmationAlert({ status: false, msg: "" });
    navigate(-1);
  };

  const onEdit = () => {
    setOpenEditSearch(true);
  };

  return (
    <>
      {!location || !location.state ? (
        <></>
      ) : (
        <div>
          <div className={Styles.header}>
            <button
              onClick={() => {

                if (openMapDashboard) {
                  goBack();
                  return
                }

                if (isFromSavedSearch && isEditedSavedsearch) {
                  setShowSaveConfirmationAlert({
                    status: true,
                    msg:
                      searchSettings?.initialSearchString ??
                      feed.searchSettings?.initialSearchString ??
                      fullName ??
                      "",
                  });
                } else if (!isFromSavedSearch) {
                  setShowSaveConfirmationAlert({
                    status: true,
                    msg:
                      searchSettings?.initialSearchString ??
                      feed.searchSettings?.initialSearchString ??
                      fullName ??
                      "",
                  });
                } else {
                  goBack();
                }
              }}
            >
              <img src={BackImage} />{" "}
              <span className={Styles.titleTxt}>Profile Details</span>
            </button>

            <div className="flex flex-row">
              <WhiteBgButtonGreenBorder
                onClick={() => {
                  setOpenMap(true)
                }}
                style={{ width: 150, padding: 10, marginRight: 8, whiteSpace: "nowrap" }}
              >
                <span className={Styles.generatePdfText}>Open Map</span>
              </WhiteBgButtonGreenBorder>

              <WhiteBgButtonGreenBorder
                loading={isPdfGenerating}
                disabled={!isArticleOpened}
                onClick={() => {
                  setIsPdfGenerating(true)
                  generatePdf(
                    "article-content-div",
                    searchSettings?.initialSearchString ??
                    feed.searchSettings?.initialSearchString ??
                    fullName ?? "article-detail-pdf",
                    () => {
                      setIsPdfGenerating(false)
                    }
                  )
                }}
                style={{ maxWidth: 150, padding: 10, marginRight: 8, whiteSpace: "nowrap" }}
              >
                <span className={Styles.generatePdfText}>Generate PDF</span>
              </WhiteBgButtonGreenBorder>

              {!fromMultipleSavedSearch ? (
                <button
                  className="w-1/2 btn btn--primary"
                  onClick={onEdit}
                  style={{ maxWidth: 130, padding: 10 }}
                >
                  Edit Search
                </button>
              ) : (
                <div style={{ he: 60 }}></div>
              )}
            </div>

          </div>
          <div className={Styles.articleMain}>
            <aside>
              <div className={Styles.bodySearch}>
                <PersonalInfoSummary
                  profileName={capitalizeCase(
                    searchSettings?.initialSearchString ??
                    feed.searchSettings?.initialSearchString ??
                    fullName ??
                    "",
                    true
                  )}
                  personalSummary={personalInfo?.personalSummary}
                  profilePic={profilePic1}
                  setProfilePic={setProfilePic1}
                  feedbackOptions={feedbackOptions1}
                  linkedInData={linkedInUserData}
                />
                <FilterLeftSection
                  sortState={sortState}
                  setSortState={setSortState}
                  showStrongMatchArticles={showStrongMatchArticles}
                  setShowStrongMatchArticles={setShowStrongMatchArticles}
                  showWeakMatchArticles={showWeakMatchArticles}
                  setShowWeakMatchArticles={setShowWeakMatchArticles}
                  filterAttributes={filterAttributes}
                  setFilterAttributes={setFilterAttributes}
                  readFilter={readFilter}
                  setReadFilter={setReadFilter}
                  unreadFilter={unreadFilter}
                  setUnreadFilter={setUnreadFilter}
                  isAllShow={false}
                  attributes={attributes}
                  setAttributes={setAttributes}
                  articleType={articleFilterData}
                />
              </div>
            </aside>
            <div className={Styles.container}>
              <FiltersView
                isAllShow={isHeader}
                isShowPersonalInfo={isShowPersonalInfo}
                statusesOfFiltersOld={
                  isSetOldFilter ? Variables.statusesOfFilters : null
                }
                onFilterCompleted={(filter) => {
                  Variables.statusesOfFilters = filter;
                  onFilterCompleted(riskLevelsStatuses, filter);
                }}
                articleType={articleFilterData}
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
                sortState={sortState}
                setSortState={setSortState}
                showStrongMatchArticles={showStrongMatchArticles}
                setShowStrongMatchArticles={setShowStrongMatchArticles}
                showWeakMatchArticles={showWeakMatchArticles}
                setShowWeakMatchArticles={setShowWeakMatchArticles}
                filterAttributes={filterAttributes}
                setFilterAttributes={setFilterAttributes}
                readFilter={readFilter}
                setReadFilter={setReadFilter}
                unreadFilter={unreadFilter}
                setUnreadFilter={setUnreadFilter}
                attributes={attributes}
                setAttributes={setAttributes}
                verified={verified}
                setVerified={setVerified}
                partial={partial}
                setPartial={setPartial}
                needsReview={needsReview}
                setNeedsReview={setNeedsReview}
                processingArticle={hasData}
                filterRef={filterRef}
                setFilterHeight={setFilterHeight}
              />
              <SectionListView
                contactDataID={savedContactID}
                selectedParentCategory={getSelectedParentCategory()}
                setSelectedFilter={setSelectedFilter}
                selectedFilter={selectedFilter}
                isShowPersonalInfo={isShowPersonalInfo}
                searchTerm={searchTerm}
                recentSearchId={recentSearchId}
                savedContactID={savedContactID}
                isVisible={modalFilterVisible}
                onFilterCompleted={onFilterCompleted}
                articleType={articleFilterData}
                articleCount={articleTypeCount}
                articleRelevacnyData={JSON.stringify(props)}
                articles={sortArticles(filteredArticles2, sortState)}
                style={style}
                articleSource={articleSource}
                noBtnPressDelay={true}
                isShowName={false}
                contactData={saveSearchVariables?.data}
                reloadScreenData={reloadScreenData}
                configData={configurationData}
                configLoading={ConfigurationLoading}
                sortState={sortState}
                setSortState={setSortState}
                showStrongMatchArticles={showStrongMatchArticles}
                setShowStrongMatchArticles={setShowStrongMatchArticles}
                showWeakMatchArticles={showWeakMatchArticles}
                setShowWeakMatchArticles={setShowWeakMatchArticles}
                filterAttributes={filterAttributes}
                setFilterAttributes={setFilterAttributes}
                readFilter={readFilter}
                setReadFilter={setReadFilter}
                unreadFilter={unreadFilter}
                setUnreadFilter={setUnreadFilter}
                attributes={attributes}
                setAttributes={setAttributes}
                setHeaderTags={setHeaderTags}
                headerTags={headerTags}
                saveRelevancyData={saveRelevancyData}
                setSaveRelevancyData={setSaveRelevancyData}
                deleteRelevancyData={deleteRelevancyData}
                setDeleteRelevancyData={setDeleteRelevancyData}
                setRefreshList={setRefreshList}
                verified={verified}
                setVerified={setVerified}
                partial={partial}
                setPartial={setPartial}
                needsReview={needsReview}
                setNeedsReview={setNeedsReview}
                setShowHeader={setShowHeader}
                potentialMatchId={potentialMatchId}
                processingArticle={hasData}
                filterHeight={filterHeight}
                loading={loading || saveLoading}
                isArticleOpened={isArticleOpened}
                setIsArticleOpened={setIsArticleOpened}
              />
            </div>
          </div>

          {subscriptionModalVisible && (
            <SubscriptionPlansModal
              subscriptionModalVisible={subscriptionModalVisible}
              setSubscriptionModalVisible={setSubscriptionModalVisible}
            />
          )}

          {isSearchLimitExceed && (
            <SearchLimitExceedModal
              onBackdropPress={() => {
                setSearchLimitExceed(false);
              }}
              startFreeTrial={startFreeTrial}
              goback={cancelPopup}
              modelType={ModelType.WATCHED_SEARCH_LIMIT_MODAL}
            />
          )}


          {showSaveConfirmationAlert.status && (
            <SaveSearchResultModal
              setShowSaveConfirmationAlert={setShowSaveConfirmationAlert}
              name={titleCase(showSaveConfirmationAlert.msg)}
              cancelBtnClick={goBack}
              setChkBxNotifyUser={setChkBxNotifyUser}
              chkBxNotifyUser={chkBxNotifyUser}
              setSaveSearch={setSaveSearch}
              saveSearch={saveSearch}
              setMonitorSearch={setMonitorSearch}
              monitorSearch={monitorSearch}
              saveMySearches={saveMySearches}
              showNotifyUser={hasData}
            />
          )}

          {openMap && (
            <MapView
              onFilterCompleted={(filter) => {
                onFilterCompleted(riskLevelsStatuses, filter, false, true);
              }}
              articleFilterData={articleFilterData}
              articles={mapFilteredArticles}
              setOpenMapView={setOpenMap}
              configData={configurationData}
              configLoading={ConfigurationLoading}
              isFromSavedSearch={isFromSavedSearch}
              isWeb={true}
              articleDetails={{
                mPhoneNumberInput,
                mCountryInput,
                mCityInput,
                mStateInput,
                mAgeInput,
                queryData,
                savedTracerQueryId,
                savedTahoeId,
                lastQuery,
                fullName,
                searchTerm,
                searchSettings,
                categoryFilterData,
                irrelevantArticleId: feed?.irrelevantArticleId,
                articles: feed?.articles,
                articleSource: "SEARCH",
                linkedInData,
                hasData: hasData,
                searchIds: searchIds,
                potentialMatchId: feed?.potentialMatchId,
                isFromSavedSearch: isFromSavedSearch,
                fromMultipleSavedSearch: fromMultipleSavedSearch,
                isEditedSavedsearch: isEditedSavedsearch,
              }}
              reloadScreenData={reloadScreenData}
              articleSource={"SEARCH"}
              searchTerm={searchTerm}
              savedContactID={feed?.savedContactID}
              recentSearchId={feed?.irrelevantArticleId}
              contactData={saveSearchVariables?.data}
              setRefreshList={setRefreshList}
            />
          )}
        </div>
      )}
    </>
  );
};

export default ArticleLayout;
