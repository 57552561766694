// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".phoneNumberField_phoneNumberField__VpKUI .MuiInputAdornment-root {\n  margin-right: 10px;\n  height: auto;\n  padding: 4px 18px 4px 0;\n  border-right: 1px solid #C0C7D6;\n}\n\n.phoneNumberField_phoneNumberField__VpKUI {\n  position: relative;\n}\n\n.phoneNumberField_fieldLabel__3uQme {\n  font-family: 'Proxima Nova';\n  font-style: normal;\n  font-weight: 600;\n  font-size: 13px;\n  line-height: 16px;\n  color: #142030;\n}\n\n.phoneNumberField_arrowDown__N0pVM {\n  position: absolute;\n  z-index: 2;\n  top: 33px;\n  left: 52px;\n}", "",{"version":3,"sources":["webpack://./src/components/fields/phoneNumberField.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,uBAAuB;EACvB,+BAA+B;AACjC;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;EAC3B,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,SAAS;EACT,UAAU;AACZ","sourcesContent":[".phoneNumberField :global .MuiInputAdornment-root {\n  margin-right: 10px;\n  height: auto;\n  padding: 4px 18px 4px 0;\n  border-right: 1px solid #C0C7D6;\n}\n\n.phoneNumberField {\n  position: relative;\n}\n\n.fieldLabel {\n  font-family: 'Proxima Nova';\n  font-style: normal;\n  font-weight: 600;\n  font-size: 13px;\n  line-height: 16px;\n  color: #142030;\n}\n\n.arrowDown {\n  position: absolute;\n  z-index: 2;\n  top: 33px;\n  left: 52px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"phoneNumberField": "phoneNumberField_phoneNumberField__VpKUI",
	"fieldLabel": "phoneNumberField_fieldLabel__3uQme",
	"arrowDown": "phoneNumberField_arrowDown__N0pVM"
};
export default ___CSS_LOADER_EXPORT___;
