const extractHostname=(url)=> {
    let hostname;
    if (url.indexOf('//') > -1) {
      hostname = url.split('/')[2];
    } else {
      hostname = url.split('/')[0];
    }
    hostname = hostname.split(':')[0];
    hostname = hostname.split('?')[0];
    return hostname;
  }

const extractRootDomain=(url)=> {
  let domain = extractHostname(url),
    splitArr = domain.split('.'),
    arrLen = splitArr.length,
    srcName= domain;
  if (arrLen === 2) {
    srcName = splitArr[arrLen - 2];
  }
  if (arrLen > 2) {
    srcName = splitArr[arrLen - 2];
    if (splitArr[arrLen - 2].length == 2 && splitArr[arrLen - 1].length == 2) {
      srcName = splitArr[arrLen - 3] + '.' + srcName;
    }
  }
  return {srcName:srcName,domain:domain};
}

export {
    extractHostname,
    extractRootDomain
  };
  