
const createStyle = (theme) => {
  return {
    articleSeparator: {
      marginBottom: 15,
    },
    separator: {
      backgroundColor: "#EFF1F5",
      height: 4
    }
  }
}

export default createStyle
