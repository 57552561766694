import { MuiTelInput } from 'mui-tel-input'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Styles from './phoneNumberField.module.css';

export const PhoneNumberField = ({
  value,
  setValue,
  fieldValid,
  countryCode,
  setCountryCode
}) => {
  return (
    <div className={Styles.phoneNumberField}>
      <div className={Styles.fieldLabel}>Phone Number</div>

      <div>

        <div className={Styles.arrowDown}><ArrowDropDownIcon className="arrowIcon" /></div>

        <MuiTelInput
          fullWidth
          forceCallingCode
          defaultCountry='US'
          value={value}
          variant="outlined"
          type="text"
          placeholder="Phone Number"
          inputProps={{ inputMode: 'numeric' }}
          InputProps={{
            sx: {
              color: "#142030",
              height: 48,
              marginTop: 1,
              backgroundColor: "#FFFFFF",
              "& ::placeholder": {
                color: "#8E96A8"
              },
            }
          }}
          FormHelperTextProps={{ style: { backgroundColor: 'transparent', padding: 0, margin: 0, marginTop: 4 } }}
          error={fieldValid.status === false}
          helperText={fieldValid.msg}
          onChange={
            (value, country) => {
              setValue(value)
              setCountryCode(country)
            }
          }
        />
      </div>
    </div>
  )
}
