import { create } from 'zustand'

export const preferredStore = create((set) => ({
  isPreferred: null,
  selectedItem: null,
  setPreferred: (data) => set(() => ({ isPreferred: data})),
  setSelectedItem: (data) => set(() => ({ selectedItem: data})),
  removeAll: () => set({ 
    isPreferred: null,
    onPreferredClick: null,
  }),
}))
