import { create } from 'zustand'

export const appStore = create((set) => ({
  dimensions: {},
  openSubscription: false,
  setDimensions: (data) => set(() => ({ dimensions: data })),
  setOpenSubscription: (data) => set(() => ({ openSubscription: data })),
  removeAll: () => set({
    dimensions: {},
    openSubscription: false,
  })
}))