import React, { useState } from "react";
import createStyle from "./styles";
import { RESET_PASSWORD } from "../../../queries/queries";
import { useMutation } from "react-apollo";
import ResetPasswordIllustrationLogo from "../../../images/svg/reset-password-illustration";
import { GreenBgButton } from "../../../components/buttons/greenBgButton";
import { useNavigate, useLocation } from "react-router-dom";
import { PasswordField } from "../../../components/fields/passwordField";
import Styles from "./resetPassword.module.css";
import AppWalkthourgh from "../../components/appWalkthrough/appWalkthrough";
import Footer from "../../layout/Footer";

function ResetPasswordForm({ verifiedAuthToken }) {
  const navigate = useNavigate();
  const styles = createStyle();

  const [displayError, setDisplayError] = useState(false);
  const [submitButtonState, setSubmitButtonState] = useState(true);
  const errorMessage = "Unable to change the password";

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPasswordValid, setNewPasswordValid] = useState({
    status: null,
    msg: "",
  });
  const [confirmPasswordValid, setConfirmPasswordValid] = useState({
    status: null,
    msg: "",
  });

  const [checkFormValidation, setCheckFormValidation] = useState(false);

  //resend otp
  const [resetPassword, { loading: resetPasswordLoading }] = useMutation(
    RESET_PASSWORD,
    {
      onCompleted(data) {
        setSubmitButtonState(true);
        navigate("/resetPasswordSuccess", {
          state: {
            user: data.resetPassword,
          },
        });
      },
      onError() {
        setDisplayError(true);
        setTimeout(() => {
          setSubmitButtonState(true);
          setDisplayError(false);
        }, 3000);
      },
    }
  );

  const loginButtons = [
    <PasswordField
      title="New Password"
      password={newPassword}
      setPassword={setNewPassword}
      placeholder={"8 - 32 Characters; Mixed Case"}
      passwordValid={newPasswordValid}
      setPasswordValid={setNewPasswordValid}
      checkFormValid={checkFormValidation}
      showPasswordStrength={true}
      showPasswordIcon={true}
    />,
    <PasswordField
      title="Confirm Password"
      password={confirmPassword}
      setPassword={setConfirmPassword}
      placeholder={"8 - 32 Characters; Mixed Case"}
      passwordValid={confirmPasswordValid}
      setPasswordValid={setConfirmPasswordValid}
      checkFormValid={checkFormValidation}
      howPasswordStrength={true}
      showPasswordIcon={true}
    />,
  ];

  const checkPasswordMatch = () => {
    let isValid = false;
    if (newPassword.length > 0 && confirmPassword.length > 0) {
      if (newPassword !== confirmPassword) {
        setConfirmPasswordValid({
          status: false,
          msg: "Your password do not match",
        });
        isValid = false;
      } else {
        setConfirmPasswordValid({ status: true, msg: "" });
        isValid = true;
      }
    }
    return isValid;
  };

  const onBtnClick = () => {
    setCheckFormValidation(true);
    let isValid = checkPasswordMatch();
    if (isValid && newPasswordValid.status && confirmPasswordValid.status) {
      setSubmitButtonState(false);
      resetPassword({
        variables: {
          authToken: verifiedAuthToken,
          newPassword: confirmPassword,
        },
      });
    }
  };

  return (
    <>
      <div>
        {loginButtons.map((signInButton) => (
          <div style={styles.separator}>{signInButton}</div>
        ))}
        {displayError && (
          <span style={styles.errorMessage}>{errorMessage}</span>
        )}
        <div style={styles.resetButton}>
          <GreenBgButton
            disabled={!submitButtonState}
            fullWidth
            variant="contained"
            loading={resetPasswordLoading}
            onClick={onBtnClick}
          >
            <div
              style={{
                fontFamily: "Proxima Nova",
                fontSize: 16,
                fontWeight: "600",
              }}
            >
              Reset my password
            </div>
          </GreenBgButton>
        </div>
      </div>
    </>
  );
}

export default function ResetPassword() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const verifiedAuthToken = state?.verifiedAuthToken;
  const styles = createStyle();
  const [user, setUser] = useState("");

  const TextInfo = () => {
    const styles = createStyle();
    return (
      <>
        <div style={styles.textQuestion}>Reset Password</div>
        <div style={styles.textSimple}>
          You're almost there! Please enter your new password to recover your
          access to Ferret
        </div>
      </>
    );
  };

  return (
    <div className={Styles.body}>
      <div className={Styles.bodyMain}>
        <div>
          <div className={Styles.navHeaderTitle}>Password Reset</div> 
          <ResetPasswordIllustrationLogo style={{ ...styles.logo }} />
          <TextInfo />
          <div style={styles.formContainer}>
            <ResetPasswordForm verifiedAuthToken={verifiedAuthToken} />
          </div>
        </div>
      </div>
      <AppWalkthourgh />
      <div className={Styles.footer}>
        <Footer isLogin={true} isCenterCopyright={true} />
      </div>
    </div>
  );
}
