import React from 'react';
import createStyle from './styles';

const TitleGroup = props => {
  const {label, style} = props;
  const styles = createStyle();
  return (
    <div style={{...styles.titleGroup, ...style}}>
      {label}
    </div>
  );
};

export default TitleGroup;
