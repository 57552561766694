import React, { useState, useContext, useEffect } from "react";
import createStyle, { getRiskCategoryColors } from "./styles";
import { extractRootDomain } from "../../../utils/getHostName";
import UserAvatar from "../../../images/svg/user-Avatar";
import { capitalizeCase, capitalizeSearchTermsTag } from "../../../utils/utils";
import { UserContext } from "../../../contexts/userContext";
import QuestionMarkGrey from "../../../images/svg/question-mark-grey";
import ExclamationMarkGrey from "../../../images/svg/exclamation-mark-grey";
import TickGreen from "../../../images/svg/tick-green";
import { Button, ButtonBase } from "@mui/material";
import FilterHeaderTooltip from "../../../components/onboardingTooltips/filterHeaderTooltip";
import GreenNext from "../../images/svg/green-next.svg";
import GreenGrey from "../../images/svg/green-grey.svg";
import Styles from "./article.module.css";
import { GET_FAVICON } from "../../../queries/queries";
import { useLazyQuery } from "react-apollo";
import webPlaceholder from "../../../images/svg/web-placeholder.svg";
import _ from "lodash";

const ArticleCard = (props) => {
  const {
    article,
    onPress,
    isShowName = true,
    enableRelevanceModal,
    displayPostArticleRelevanceBot,
    setRelevantArticle,
    openFilter,
    dontShow,
    filterAttributes,
    isRecent,
    isSelected,
  } = props;
  const isTracers = article.tracers;

  const style = createStyle(article.isRead);
  const riskCategoryColors = getRiskCategoryColors();
  const [alertsOnboard, setAlertsOnboard] = useState(false);
  const { user, setUser } = useContext(UserContext);
  const [icon, setIcon] = useState(false);

  const [articleSubCategory, setArticleSubCategory] = useState([]);
  const [openSubCategory, setOpenSubCategory] = useState(false);

  const [getFavicon, { loading, data, error }] = useLazyQuery(GET_FAVICON, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (!loading && data && data.getFavIcon?.icon) {
      setIcon("data:image/png;base64," + data.getFavIcon?.icon);
      article.icon = "data:image/png;base64," + data.getFavIcon?.icon;
    } else if (!loading && data && !data.getFavIcon?.icon) {
      setIcon(webPlaceholder);
      article.icon = webPlaceholder;
    }
  }, [loading, data, error]);

  const getIcon = () => {
    if (
      article?.pageURL &&
      article?.pageURL.includes("http") &&
      !icon &&
      !article.icon
    ) {
      let parsedUrl = new URL(article.pageURL);
      let baseUrl = parsedUrl.origin;
      if (baseUrl) {
        getFavicon({
          variables: {
            iconURL: baseUrl,
          },
        });
      }
    } else if (article?.icon) {
      setIcon(article.icon);
    } else if (article && !article?.pageURL) {
      setIcon(webPlaceholder);
    }
  };
  const debouncedGetIcon = _.debounce(getIcon, 400);

  useEffect(() => {
    debouncedGetIcon();

    if (article.subCategory) {
      const transformedData = article.subCategory.flatMap(entry => {
        return entry.document.map(documentEntry => {
          const concatenatedTitle = entry.title
            ? `${entry.title}-${documentEntry.title}`
            : documentEntry.title;

          return {
            title: concatenatedTitle,
            documentNumber: concatenatedTitle?.match(/document\D*(\d+)/)?.[1],
            url: documentEntry.details.find(item => item.key === "url").value,
            details: documentEntry.details
          };
        });
      });

      const sortedData = transformedData.sort((a, b) => {
        return a.documentNumber - b.documentNumber;
      });

      setArticleSubCategory(sortedData)
    }
  }, [article]);

  if (!article) {
    return <></>;
  }
  let markerStyle = { ...style.marker };
  if (
    article.riskCategory &&
    riskCategoryColors[article.riskCategory.toLowerCase()]
  ) {
    markerStyle.backgroundColor =
      riskCategoryColors[article.riskCategory.toLowerCase()];
  }
  let date = "";

  if (article.date && article.date?.length > 0) {
    date = `${article.date.substring(0, 10)}`;
  }

  const RenderAttributes = ({ attribute, style }) => {
    if (!attribute?.value?.[0]) return <></>;
    let attributearr = attribute?.value.map((a) => a.toLowerCase());
    let tempArr = [];
    if (filterAttributes.length > 0)
      tempArr = filterAttributes.filter((x) =>
        attributearr.includes(x.toLowerCase())
      );
    if (!tempArr.length > 0 || tempArr?.[0] == "") {
      return (
        <div
          style={{
            ...style.attributeItem,
            borderColor: isSelected ? "#C0C7D6" : "#EFF1F5",
          }}
        >
          {capitalizeSearchTermsTag(attribute?.value?.[0], null, true)}
        </div>
      );
    }
    return (
      <div style={style.attributeItem}>
        {capitalizeSearchTermsTag(tempArr?.[0], null, true)}
      </div>
    );
  };

  const dontShowView = () => {
    setAlertsOnboard(false);
    dontShow?.();
    setRelevantArticle(article, "NO", article?.potentialMatchId);
  };
  const skip = (no) => {
    if (no && !user.relevancySortFilterTooltip) {
      setAlertsOnboard(true);
    } else {
      setAlertsOnboard(false);
      setRelevantArticle(article, "NO", article?.potentialMatchId);
    }
  };
  const openFilterSort = () => {
    setAlertsOnboard(false);
    setRelevantArticle(article, "NO", article?.potentialMatchId);
    openFilter?.();
  };

  let articleSrc;
  if (article?.pageURL) articleSrc = extractRootDomain(article.pageURL);

  //manually setting Favicon and souce name for Court records
  if (
    (article?.category === "Court Records" &&
      article?.riskCode === "cl-opinion") ||
    article?.category === "CL RECAP"
  ) {
    articleSrc = { srcName: "courtlistener", domain: "www.courtlistener.com" };
  }

  let sourceName = articleSrc?.srcName || "";
  let isHyperLink = false;
  if (articleSrc?.srcName.toLowerCase() === "crunchbase") {
    sourceName = `Powered by ${articleSrc?.srcName}`;
    isHyperLink = true;
  }

  const onClickCard = () => {
    if (
      article &&
      (article.subCategory == null ||
        article.subCategory == undefined ||
        article.subCategory.length === 0)
    ) {
      onPress?.(article);
    }
  };

  const getSelectedData = (item) => {
    let data = {};

    item?.details.forEach((item) => {
      data[item.key] = item.value;
    });

    return { ...item, details: data };
  };

  return (
    <div
      onClick={onClickCard}
      disabled={
        !(
          article &&
          (article.subCategory == undefined || article.subCategory.length === 0)
        )
      }
    >
      <div style={style.searchContainer}>
        <div style={style.searchTitleContainer}>
          <VerificationTypeView
            verificationType={article?.verificationType}
            style={style}
          />
          <img src={isSelected ? GreenNext : GreenGrey}></img>
        </div>
        {isRecent
          ? isTracers &&
          article?.category &&
          article?.category?.length > 0 && (
            <span style={style.articleTracersTitle}>{article.category}</span>
          )
          : isTracers &&
          article?.articleType &&
          article?.articleType?.length > 0 && (
            <span style={style.articleTracersTitle}>
              {article.articleType}
            </span>
          )}
        {article?.category == "Unicourt" &&
          <span style={style.articleTracersTitle}>
            {article.category}
          </span>
        }
        {article.title?.length > 0 && (
          <span
            className={Styles.articleTitle}
            style={{ color: article.isRead ? "#8E96A8" : "#142030" }}
          >
            {article.title}
          </span>
        )}
        {articleSubCategory && articleSubCategory.length > 0 ? (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingBottom: 14
              }}
              onClick={() => setOpenSubCategory(!openSubCategory)}
            >
              <span className={Styles.selectDocument}>
                Select Document
              </span>
              <img
                style={
                  openSubCategory
                    ? { transform: `rotate(180deg)`, width: 30, height: 30 }
                    : { width: 30, height: 30 }
                }
                src={require("../../../images/filter-close.png")}
              />
            </div>

            {openSubCategory &&
              <div className={Styles.subCategoryView}>
                {articleSubCategory.map((item) => {
                  return (
                    <div
                      style={style.subCategoryBtn}
                      onClick={() => onPress?.(article, getSelectedData(item))}
                    >
                      <span style={style.documentTitle}>
                        {item.title}
                      </span>
                      {/* <Icon name="chevron-right" size={16} color="#8E96A8" className={Styles.subCategoryBtnIcon} /> */}
                    </div>
                  );
                })}
              </div>
            }
          </>
        ) : null}
        <div>
          {!isTracers && articleSrc?.srcName ? (
            <div style={style.articleSource}>
              {icon && <img src={icon} style={style.articleSrcImg} />}
              <span
                onClick={(e) => {
                  e.stopPropagation();

                  if (isHyperLink)
                    window.location.href = "http://www.crunchbase.com";
                }}
                style={style.articleCategory}
                numberOfLines={1}
              >
                {sourceName}
              </span>
            </div>
          ) : (
            !isTracers && (
              <span style={style.articleCategoryType}>
                {article?.parentCategory ?? article.category}
              </span>
            )
          )}
          {article.matchedAttributes?.length > 0 ? (
            <div style={style.attributes}>
              {article.matchedAttributes.map((a) => (
                <RenderAttributes attribute={a} style={style} key={a.key} />
              ))}
            </div>
          ) : (
            <div style={style.attributes}>
              {article?.attributes?.map((a) => (
                <RenderAttributes attribute={a} style={style} key={a.key} />
              ))}
            </div>
          )}
        </div>
        {displayPostArticleRelevanceBot && (
          <>
            <div style={style.aboutMainView}>
              <UserAvatar />
              <span
                style={style.aboutYour}
              >{`Is this about your ${capitalizeCase(
                article.fullName,
                true
              )}?`}</span>
            </div>
            <div style={style.aboutMainView2}>
              <div
                style={style.btnView}
                onClick={(e) => {
                  e.stopPropagation();
                  enableRelevanceModal();
                }}
              >
                <span style={style.btnText}>Yes</span>
              </div>
              <div
                style={style.btnView}
                onClick={(e) => {
                  e.stopPropagation();
                  skip(true);
                }}
              >
                <div style={style.btnText}>No</div>
              </div>
              {alertsOnboard && (
                <FilterHeaderTooltip
                  onPress={skip}
                  isFilter={true}
                  dontShow={dontShowView}
                  openFilter={openFilterSort}
                  isWeb={true}
                ></FilterHeaderTooltip>
              )}
            </div>
          </>
        )}
        {article.terms?.length > 0 && (
          <div style={style.termsSection}>
            <Tags terms={[article.terms]} style={style} />
          </div>
        )}
      </div>
    </div>
  );
};

// Change the atom "Tag" when data allows it
const Tags = (props) => {
  const { terms, style } = props;
  return terms?.map((item) => {
    return <span style={style.termText}>{item}</span>;
  });
};

const VerificationTypeView = ({ verificationType, textColor }) => {
  const style = createStyle();
  if (verificationType === "partial")
    return (
      <span
        style={{ ...style.verificationType, color: textColor ?? "#697080" }}
      >
        <QuestionMarkGrey />
        <span style={{ paddingLeft: 5, paddingRight: 5 }}>Partial</span>
      </span>
    );
  else if (verificationType === "needs_review")
    return (
      <span
        style={{ ...style.verificationType, color: textColor ?? "#697080" }}
      >
        <div style={{ marginRight: 4 }}>
          <ExclamationMarkGrey />
        </div>
        Needs Review
      </span>
    );
  else if (verificationType === "verified")
    return (
      <span style={{ ...style.verificationType, color: "#91CB6C" }}>
        <div style={{ marginRight: 4 }}>
          <TickGreen />
        </div>
        Verified
      </span>
    );
  else return <></>;
};

export default ArticleCard;
export { VerificationTypeView };
