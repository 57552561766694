import React, { useContext, useEffect, useRef, useState } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import {
  GET_FERRET_CONTACT_LIST,
  GET_SAVED_SEARCHES,
} from "../../../queries/queries";
import parseFerretContactsForContactsComponent from "../../../utils/ferret-contact-parser";
import ContactCard from "./ContactCard/contactCard";
import SearchHeader from "../../../components/SearchHeader/SearchHeader";
import StarredContactModal from "../StarredContactModal/starredContactModal";
import Variables from "../../../utils/Variables";
import { GreenBgButton } from "../../../components/buttons/greenBgButton";
import { useNavigate, useLocation } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import "./style.css";
import AlphabetScroll from "../../components/alphabetScroll/alphabetScroll";
import Styles from "./importClosest.module.css";
import { GreenBorderButton } from "../../../components/buttons/greenBorderButton";
import { Checkbox } from "@mui/material";
import { UserContext } from "../../../contexts/userContext";

const ImportClosestContacts = ({ setShowSplash }) => {
  const location = useLocation();
  let props = location.state ? JSON.parse(location.state) : {};
  const { user } = useContext(UserContext);
  const refs = useRef([]);
  const [searchValue, setSearchValue] = useState("");
  const [showStarredModal, setShowStarredModal] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [orignalContacts, setOrignalContacts] = useState([]);
  const [contactCardHeight, setContactCardHeight] = useState(1000);
  const [googleCheck, setGoogleCheck] = useState(true);
  const [phoneCheck, setPhoneCheck] = useState(true);
  const [savedCheck, setSavedCheck] = useState(true);
  const [iCloudCheck, setICloudCheck] = useState(true);
  const [providers, setProviders] = useState([]);
  const navigate = useNavigate();
  const topRef = useRef();
  const cardContainer = useRef();

  useEffect(() => {
    if (topRef?.current?.clientHeight)
      setContactCardHeight(
        window.innerHeight - topRef?.current?.clientHeight - 100
      );
  }, [topRef?.current?.clientHeight, window.innerHeight]);

  const [getFerretContactList, { data, error, loading }] = useLazyQuery(
    GET_FERRET_CONTACT_LIST,
    {
      fetchPolicy: "network-only",
    }
  );

  const [
    getStarContact,
    { loading: loadingContacts, error: errorContacts, data: dataContacts },
  ] = useLazyQuery(GET_SAVED_SEARCHES, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (!loadingContacts && dataContacts) {
      navigate("/dashboard", {
        replace: true,
        state: { contactData: data, showSplash: true },
      });
      setShowSplash?.(false);
    } else if (!loadingContacts && errorContacts) {
      navigate("/dashboard", {
        replace: true,
        state: { contactData: {}, showSplash: true },
      });
      setShowSplash?.(false);
    }
  }, [loadingContacts, errorContacts, dataContacts]);

  useEffect(() => {
    getFerretContactList({
      variables: {
        showSavedSearches: true,
      },
    });
  }, []);

  useEffect(() => {
    if (!loading && data?.contactList) {
      setShowStarredModal(true);
    }
  }, [loading, data]);

  const handleChange = (text) => {
    setSearchValue(text);
  };

  const handleCancel = () => {
    setSearchValue("");
  };

  useEffect(() => {
    if (!loading && data?.contactList?.contacts) {
      const contacts = parseFerretContactsForContactsComponent(
        data.contactList.contacts
      );
      let dict = {};
      for (var i = 0; i < contacts.length; i++) {
        let outerObject = contacts[i];
        const names = outerObject.data;
        dict[outerObject.title.toUpperCase()] = names;
      }
      setContacts(dict);
      setOrignalContacts(dict);
    }
  }, [data, loading]);

  useEffect(() => {
    let contacts = { ...orignalContacts };
    if (searchValue) {
      const valueCriteria = searchValue.toUpperCase();
      Object.keys(contacts)?.forEach((itemKeys) => {
        let mItem = contacts[itemKeys]?.filter((item) => {
          const itemData =
            item?.firstName?.toUpperCase() +
            " " +
            item?.lastName?.toUpperCase();
          if (providers && providers.length > 0)
            return (
              itemData?.indexOf(valueCriteria) > -1 &&
              providers?.includes(item?.originalContact?.provider)
            );
          else return itemData?.indexOf(valueCriteria) > -1;
        });
        if (mItem && mItem.length) contacts[itemKeys] = mItem;
        else delete contacts[itemKeys];
      });
      setContacts(contacts);
    } else if (!googleCheck || !phoneCheck || !savedCheck || !iCloudCheck) {
      Object.keys(contacts)?.forEach((itemKeys) => {
        let mItem = contacts[itemKeys]?.filter((item) => {
          return providers.includes(item?.originalContact?.provider);
        });
        if (mItem && mItem.length) contacts[itemKeys] = mItem;
        else delete contacts[itemKeys];
      });
      setContacts(contacts);
    } else {
      setContacts(contacts);
    }
  }, [searchValue, providers]);

  useEffect(() => {
    if (googleCheck && phoneCheck && savedCheck && iCloudCheck)
      setProviders([1, 3, 4, 6]);
    else {
      let providerLocal = [];
      if (googleCheck) providerLocal.push(3);
      if (phoneCheck) providerLocal.push(1);
      if (savedCheck) providerLocal.push(6);
      if (iCloudCheck) providerLocal.push(4);
      setProviders(providerLocal);
    }
  }, [googleCheck, phoneCheck, savedCheck, iCloudCheck]);

  const onChangePreferred = (id, isPreferred) => {
    Variables.isAddSearches = true;
    Variables.isRefreshClosestContacts = true;
    setOrignalContacts((contacts) => {
      let mContact = { ...contacts };
      Object.values(mContact)?.forEach((item) => {
        const selectedContact = item.findIndex(
          (item) => item?.originalContact?.id === id
        );
        if (selectedContact !== -1) {
          item[selectedContact].isPreferred = isPreferred;
        }
      });
      return mContact;
    });
  };

  if (!loading && error && !data) {
    return (
      <>
        <span className={Styles.errorMessage}>
          Opps there is an error loading the data
        </span>
      </>
    );
  }
  const onDashboard = () => {
    if(user?.isSubscribed){
      navigate("/savedSearch", {
        replace: true,
      });
    }else{
      if (props?.modeCheck == "signup") {
        navigate("/dashboard", { state: { showSplash: true } });
      } else if (
        Variables.navigationStackNameForContacts &&
        Variables.navigationStackNameForContacts == "SavedSearches"
      ) {
        navigate("/dashboard", {
          replace: true,
          state: { screenName: "Searches", showSplash: true },
        });
      } else {
        getStarContact();
        setShowSplash?.(true);
      }
    }
  };

  const onReset = () => {
    setGoogleCheck(true);
    setPhoneCheck(true);
    setSavedCheck(true);
    setICloudCheck(true);
    setSearchValue("");
  };

  let alphaArray = [];
  Object.keys(contacts).map((key) => {
    alphaArray = [...alphaArray, key];
  });

  return (
    <div className={Styles.container50}>
      <span className={Styles.headerText}>Search Result Notifications</span>
      {loading || !data ? (
        <div className="loadingbar">
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className={Styles.container}>
            <div ref={topRef} className={Styles.mainContainer}>
              <div className={Styles.header1}>
                <span className={Styles.headerNumber}>
                  {data?.contactList?.contacts?.length}
                </span>
                <span className={Styles.headerTitle}>Imported Contacts</span>
              </div>
              <span className={Styles.info}>
                Select the contacts who you want to monitor. You’ll receive
                notifications in real time:
              </span>
              <span className={Styles.headerText}>Sort and Filter</span>
              <div className={Styles.searchView}>
                <SearchHeader
                  placeholder={"Search Contacts"}
                  selectAllButton={false}
                  value={searchValue}
                  onChangeText={handleChange}
                  onCancel={handleCancel}
                  placeholder="Search Contacts"
                />
              </div>
              <AlphabetScroll
                onClick={(a) => {
                  cardContainer?.current?.scrollTo({
                    top: refs.current[a].offsetTop,
                    left: 0,
                    behavior: "smooth",
                  });
                }}
                styles={Styles.marginTop}
                alphaArray={alphaArray}
              />
              <span className={Styles.headerTitle}>Imported Source</span>
              <div className={Styles.checkView}>
                <Checkbox
                  disabled={false}
                  checked={googleCheck}
                  onClick={() => setGoogleCheck(!googleCheck)}
                  color="success"
                  sx={{
                    color: "#078167",
                    "&.Mui-checked": {
                      color: "#078167",
                    },
                    paddingLeft: 0,
                  }}
                />
                <span className={Styles.checkTitle}>Google</span>
                <Checkbox
                  disabled={false}
                  checked={phoneCheck}
                  onClick={() => setPhoneCheck(!phoneCheck)}
                  color="success"
                  sx={{
                    color: "#078167",
                    "&.Mui-checked": {
                      color: "#078167",
                    },
                    paddingLeft: 0,
                  }}
                />
                <span className={Styles.checkTitle}>Personal Contacts</span>
                <Checkbox
                  disabled={false}
                  checked={savedCheck}
                  onClick={() => setSavedCheck(!savedCheck)}
                  color="success"
                  sx={{
                    color: "#078167",
                    "&.Mui-checked": {
                      color: "#078167",
                    },
                    paddingLeft: 0,
                  }}
                />
                <span className={Styles.checkTitle}>Saved on Ferret</span>
                <Checkbox
                  disabled={false}
                  checked={iCloudCheck}
                  onClick={() => setICloudCheck(!iCloudCheck)}
                  color="success"
                  sx={{
                    color: "#078167",
                    "&.Mui-checked": {
                      color: "#078167",
                    },
                    paddingLeft: 0,
                  }}
                />
                <span className={Styles.checkTitle}>iCloud</span>
              </div>
              <div className={Styles.footer}>
                <GreenBorderButton
                  padding={"10px 40px 10px 40px"}
                  variant="contained"
                  onClick={onReset}
                  style={{ borderColor: "#C0C7D6" }}
                >
                  <div className={Styles.backBtnText}>Reset</div>
                </GreenBorderButton>
                <GreenBgButton
                  style={{ marginLeft: 16 }}
                  padding={"10px 40px 10px 40px"}
                  onClick={onDashboard}
                  variant="contained"
                >
                  <div className={Styles.btTxt}>{(user?.isSubscribed)  ? 'See My Contacts' : 'Go to Dashboard'}</div>
                </GreenBgButton>
              </div>
            </div>
            <div
              ref={cardContainer}
              className={Styles.listContact}
              style={{ height: contactCardHeight }}
            >
              {contacts &&
                Object.entries(contacts).map(([key, values], index) => (
                  <>
                    {
                      <div
                        ref={(element) => {
                          refs.current[key] = element;
                        }}
                        key={index}
                      >
                        <span className={Styles.alphaHeaderText1}>{key}</span>
                        {values &&
                          values.map((item) => (
                            <ContactCard
                              name={
                                item.lastName
                                  ? `${item.firstName} ${item.lastName}`
                                  : item.firstName
                              }
                              isPreferred={item.isPreferred}
                              styles={Styles.contactCard}
                              photo={item.photo}
                              onChangePreferred={onChangePreferred}
                              tags={[]}
                              id={item?.originalContact?.id}
                            />
                          ))}
                      </div>
                    }
                  </>
                ))}
            </div>
          </div>

          {showStarredModal && (
            <StarredContactModal
              showcloseIcon={true}
              isVisible={showStarredModal}
              onBackdropPress={() => setShowStarredModal(false)}
              onContinuePress={() => setShowStarredModal(false)}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ImportClosestContacts;
