import logoWhite from "../../images/svg/logo-white.svg";
import Styles from './darkCardItem.module.css';

const DarkCardItem = ({
  description,
  title,
  name,
  style,
  descriptionIcon,
  descriptionBoxStyle,
  descriptionTextBoxStyle,
  imgStyles,
  imgSpanStyle,
  onClick,
  cardType = "full"
}) => {
  return (
    <div className={ Styles.body }>
      <span style={descriptionBoxStyle}>
        <span className="descriptionTextBox" style={descriptionTextBoxStyle}>
          {title && (
            <div className="h3" style={{ color: "#EFF1F5" }}>
              {title}
            </div>
          )}
          <span className="smallCopyDark" style={{ color: "#C0C7D6" }}>
            {description}
          </span>
          <div
            onClick={onClick}
            className="h4"
            style={{
              color: "#FFFFFF",
              marginTop: cardType == "full" ? 10 : 0,
              background: "#078167",
              borderRadius: "6px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "fit-content",
              height: "25px",
              paddingLeft: 8,
              paddingRight: 8
            }}
          >
            {name}
          </div>
        </span>
      </span>
      {descriptionIcon && <span className="iconRightBg" style={imgSpanStyle}>{descriptionIcon}</span>}
      <img
        className="iconRight"
        style={{ ...{ height: 60, marginTop: 25 }, ...imgStyles }}
        src={logoWhite}
        alt="finger-print-logo"
      />
    </div>
  );
};

export default DarkCardItem;
