import { textTransform } from "@mui/system";

const createStyleWeb = (disabled) => {
  return {
    paramContainer:{
      cursor: 'pointer',
    },
    simpleTag: {
      color: disabled ? '#454f69' : '#142030',
      backgroundColor: disabled ? '#c5cbd4' : '#FFF',
      fontFamily: 'Proxima Nova',
      fontSize: 14,
      fontWeight: '400',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'row',
      padding: 8,
      borderWidth: 0,
      borderStyle: 'solid',
      borderRadius: 6,
      marginRight: 10,
      marginBottom: 10,
      borderWidth: 1,
      borderColor: '#EFF1F5',
      alignItems: "baseline",
      textTransform: 'capitalize',
    },
    selectedTag: {
      color: disabled ? '#1A233B' : '#078167',
      backgroundColor: disabled ? '#c5cbd4' : '#E6F2F0',
      fontFamily: 'Proxima Nova',
      fontSize: 14,
      fontWeight: '400',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'row',
      padding: 8,
      borderWidth: 0,
      borderStyle: 'solid',
      borderRadius: 6,
      marginRight: 10,
      marginBottom: 10,
      borderColor: disabled ? '#c5cbd4' : '#078167',
      alignItems: "baseline",
      textTransform: 'capitalize',
    },
  }
}

export default createStyleWeb;
