import * as React from "react"

const AdvancedSearchUpArrowIcon = (props) => (
  <svg
    width={14}
    height={12}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.429 8.7 6.7 3.43a.411.411 0 0 1 .6 0l5.271 5.27"
      stroke="#078167"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default AdvancedSearchUpArrowIcon
