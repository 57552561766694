import { Tooltip } from "@mui/material";
import { appStore } from "../../store/appStore";


const OnboardingTooltip = (props) => {
  const {
    title,
    icon,
    content,
    onPress,
    isVisible,
    anchorId,
    arrowStyles,
    componentStyle,
    fromDashboard,
    style={},
    ...otherProps
  } = props;
  const placement = props?.placement ? props?.placement : "top";
  const dimensions = appStore((state) => state.dimensions)

  const popperStyles = fromDashboard ? {
    width: "100%",
    height: "100%",
    position: "absolute",
    display: "flex",
    alignItems: "flex-end",
    inset: "0px auto 0px 0px",
    transform: "translate3d(0px, 0px, 0px)",
    zIndex: 10,
  } : {}

  return (
    <Tooltip
      style={style}
      title={title}
      arrow={true}
      open={isVisible}
      placement={placement}
      PopperProps={{
        disablePortal: true,
        popperOptions: {
          modifiers: [
            {
              name: 'flip',
              enabled: dimensions.isDesktop ? true : false
            }
          ]
        }
      }}
      componentsProps={{
        popper: {
          sx: popperStyles,
        },
        tooltip: {
          sx: {
            // zIndex: '0 !important',
            ...componentStyle,
            bgcolor: '#5391F7',
            position: "sticky",
            
            '& .MuiTooltip-arrow': {
              color: '#5391F7',
              ...arrowStyles
            },   
            // '& .MuiPopover-root': {
            //   zIndex: '0 !important',
            // }, 
          },
        },
      }}
    >
      <div>{props.children}</div>
    </Tooltip>
  );
};

export default OnboardingTooltip;
