import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import appWalk4 from "../../images/appWalk4.png";
import Styles from "./preferences.module.css";

const Preferences1 = ({ surveyValue, setSurveyValue }) => {
  const data = [
    {
      label: "Professional Reasons",
      index: 1,
    },
    {
      label: "Personal Reasons",
      index: 2,
    },
    {
      label: "Professional & Personal Reasons",
      index: 3,
    },
  ];

  return (
    <FormControl>
      <img src={appWalk4} className={Styles.imgPref1} />

      <h2 className={`${Styles.preferencesQtn} ${Styles.preferencesQtn1}`}>
        How do you intend to use Ferret?
      </h2>

      <h4 className={`${Styles.preferencesDesc} ${Styles.preferencesDesc1}`}>
        (This helps us better tailor your onboarding experience)
      </h4>

      <RadioGroup
        name="radio-buttons-group"
        value={surveyValue}
        onChange={(e) => setSurveyValue(parseInt(e.target.value))}
        className={Styles.pref1radioBtn}
      >
        {data.map((d) => (
          <FormControlLabel
            key={d.index}
            value={d.index}
            control={
              <Radio
                sx={{
                  color: "#078167",
                  "&.Mui-checked": {
                    color: "#078167",
                  },
                }}
              />
            }
            label={<span className={Styles.radioBtnTxt}>{d.label}</span>}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default Preferences1;
