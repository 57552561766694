// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".searchParams_backgroudView__\\+7qo1{\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    padding: 20px;\n    gap: 16px;\n    background: #FFFFFF;\n    border-radius: 12px;\n}\n.searchParams_tags__bZ1go {\n    border-radius: 6px;\n    background-color: #E6F2F0;\n    margin-right: 10px;\n    margin-bottom: 10px;\n    padding: 4px 8px 8px 6px;\n}\n.searchParams_tagContainer__f\\+M3r {\n    display: flex;\n    flex-direction: row;\n    overflow: auto;\n    white-space: nowrap;\n    flex-wrap: wrap;\n}\n.searchParams_tagMain__v5LQH {\n    background-color: #eff1f5\n}", "",{"version":3,"sources":["webpack://./src/web/components/searchParams/searchParams.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,aAAa;IACb,SAAS;IACT,mBAAmB;IACnB,mBAAmB;AACvB;AACA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,kBAAkB;IAClB,mBAAmB;IACnB,wBAAwB;AAC5B;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,cAAc;IACd,mBAAmB;IACnB,eAAe;AACnB;AACA;IACI;AACJ","sourcesContent":[".backgroudView{\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    padding: 20px;\n    gap: 16px;\n    background: #FFFFFF;\n    border-radius: 12px;\n}\n.tags {\n    border-radius: 6px;\n    background-color: #E6F2F0;\n    margin-right: 10px;\n    margin-bottom: 10px;\n    padding: 4px 8px 8px 6px;\n}\n.tagContainer {\n    display: flex;\n    flex-direction: row;\n    overflow: auto;\n    white-space: nowrap;\n    flex-wrap: wrap;\n}\n.tagMain {\n    background-color: #eff1f5\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backgroudView": "searchParams_backgroudView__+7qo1",
	"tags": "searchParams_tags__bZ1go",
	"tagContainer": "searchParams_tagContainer__f+M3r",
	"tagMain": "searchParams_tagMain__v5LQH"
};
export default ___CSS_LOADER_EXPORT___;
