import * as React from "react"
const CriminalRecords = (props) => {
    return (
        <svg
        width={33}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#a)">
          <path
            d="M18.66 16.213a.854.854 0 0 0-.427-.96 2.178 2.178 0 0 0-.573-.24 4 4 0 0 0-2.32 0 2.178 2.178 0 0 0-.573.24.854.854 0 0 0 .4 1.6h2.666a.853.853 0 0 0 .827-.64Z"
            fill={props.selected ?  "#142030" :  props.iconType == 'white' ? '#fff' :  '#b5b9c4'}
          />
          <path
            d="M1.167 31.333a.667.667 0 0 0 .666.667h29.334a.667.667 0 0 0 .666-.667v-3.466a3.107 3.107 0 0 0-1.333-2.494 20.974 20.974 0 0 0-9.08-3.24.333.333 0 0 1-.28-.333v-1.653a.106.106 0 0 1 .067-.107A9.333 9.333 0 0 0 25.833 12V9.333a9.333 9.333 0 1 0-18.666 0V12a9.333 9.333 0 0 0 4.506 8 .36.36 0 0 1 .16.293v1.534a.333.333 0 0 1-.28.333 20.973 20.973 0 0 0-9.08 3.173 3.107 3.107 0 0 0-1.333 2.494l.027 3.506Zm21-21.573a1 1 0 0 1-1 1h-1.334a1 1 0 0 1 0-2h1.334a1.013 1.013 0 0 1 1 1Zm-8 0a1 1 0 0 1-1 1h-1.334a1 1 0 0 1 0-2h1.334a1.013 1.013 0 0 1 1 1ZM12.647 4a.333.333 0 0 1 .333 0 .32.32 0 0 1 .187.293v.374a.667.667 0 1 0 1.333 0V3.253a.346.346 0 0 1 .24-.32c.233-.061.469-.11.707-.146a.373.373 0 0 1 .266.066.333.333 0 0 1 .12.267v1.2a.667.667 0 0 0 1.334 0V3.107a.334.334 0 0 1 .12-.254.373.373 0 0 1 .266-.066c.238.036.474.085.707.146a.347.347 0 0 1 .24.32v1.414a.666.666 0 1 0 1.333 0V4.2a.32.32 0 0 1 .187-.293.333.333 0 0 1 .333 0 6.586 6.586 0 0 1 2 2.24.306.306 0 0 1 0 .333.347.347 0 0 1-.28.16H10.927a.36.36 0 0 1-.28-.16.306.306 0 0 1 0-.333 6.587 6.587 0 0 1 2-2.147ZM10.34 14.533a.347.347 0 0 1 0-.306.373.373 0 0 1 .267-.16 8.533 8.533 0 0 0 5.693-2.174.254.254 0 0 1 .347 0A8.533 8.533 0 0 0 22.367 14a.373.373 0 0 1 .266.16.347.347 0 0 1 0 .307 6.667 6.667 0 0 1-12.32 0l.027.066Z"
            fill={props.selected ?  "#142030" :  props.iconType == 'white' ? '#fff' :  '#b5b9c4'}
          />
          <path
            d="M25.833 12.004V9.337a9.33 9.33 0 0 0-.397-2.693H7.564a9.33 9.33 0 0 0-.397 2.693v2.667c-.001.734.069 1.423.201 2.066h3.347c3.337 0 5.493-2.093 5.493-2.093s.146-.128.271-.128c.125 0 .266.128.266.128s1.93 2.027 5.77 2.027h3.12c.128-.625.197-1.291.198-2Zm-3.666-2.24a1 1 0 0 1-1 1h-1.334a1 1 0 1 1 0-2h1.334a1.013 1.013 0 0 1 1 1Zm-8 0a1 1 0 0 1-1 1h-1.334a1 1 0 1 1 0-2h1.334a1.013 1.013 0 0 1 1 1Z"
            fill={props.selected ? props.iconType =="blackWhite"? "#1E1E1E": "#DA3528" : props.iconType == 'white' ? '#fff' :  '#b5b9c4'}
          />
        </g>
        <defs>
          <clip-path id="a">
            <path fill="#fff" transform="translate(.5)" d="M0 0h32v32H0z" />
          </clip-path>
        </defs>
      </svg>
)}

export default CriminalRecords
