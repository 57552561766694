const GOOGLE_CONTACTS_SCOPE =
  "https://www.googleapis.com/auth/contacts.readonly";

export const googleOneTapLogin = (handleResponse) => {
  window.google.accounts.id.initialize({
    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    callback: (data) => handleResponse(data),
    cancel_on_tap_outside: false,
    itp_support: true,
  });

  window.google.accounts.id.prompt((notification) => {
    if (notification.isNotDisplayed()) {
      document.cookie = `g_state=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT`;
      window.google.accounts.id.prompt();
    }
  });
};

export const googleDefaultLogin = (handleResponse) => {
  const client = window.google.accounts.oauth2.initTokenClient({
    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    scope: "profile email",
    callback: (data) => {
      handleResponse(data);
    },
    error_callback: function (err) {
      console.log("getContactsFromGoogle err:", err);
    },
  });

  client.requestAccessToken();
};

export const googleLoginWithContactScope = (handleResponse) => {
  const client = window.google.accounts.oauth2.initTokenClient({
    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    scope: "profile email " + GOOGLE_CONTACTS_SCOPE,
    callback: (data) => {
      handleResponse(data);
    },
    error_callback: function (err) {
      console.log("getContactsFromGoogle err:", err);
    },
  });

  client.requestAccessToken();
};

function getDefaultConfiguration() {
  return {
    webClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  };
}

export { getDefaultConfiguration, GOOGLE_CONTACTS_SCOPE };
