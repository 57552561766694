const { innerWidth: windowWidth, innerHeight: windowHeight } = window

const createStyle = (screenHeight) => {
  return {
    image: {
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
      marginHorizontal: 15,
      marginVertical: 15,
    },
    mainContainer: {
      display: "flex",
      width: "100%",
      height: "100%",
      flex: 1,
      paddingBottom: 30,
      borderTopLeftRadius: 12,
      borderTopRightRadius: 12,
      backgroundColor: '#FFF',
    },
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "60vh",
      overflow: "scroll",
    },
    loadingPageIndicator: {
      height: screenHeight,
      width: '100%',
      backgroundColor: '#FFF',
      borderTopLeftRadius: 12,
      borderTopRightRadius: 12,
    },
    errorPageMessageContainer: {
      height: screenHeight,
      width: '100%',
      backgroundColor: '#FFF',
      borderTopLeftRadius: 12,
      borderTopRightRadius: 12,
      alignItems: 'center',
    },
    errorPageMessage: {
      marginTop: 60,
    },
    pdfContainer: {
      flex: 1,
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    pdf: {
      flex: 1,
      width: windowWidth,
      height: windowHeight,
    }
  }
};

export default createStyle;
