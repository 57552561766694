// Layout
import SideOverlay from "../../layout/SideOverlay";

import { notificationStore } from "../../../store/notificationStore";
import Notification from "../notificationList/notification";
import ArticleLayoutWeb from "../article/article";
import { useNavigate } from "react-router-dom";

/**
 * SearchOverlay Component
 * @returns {JSX.Element}
 * @constructor
 */
function NotificationOverlay() {
  const navigate = useNavigate();
  const setOpenNotification = notificationStore(
    (state) => state.setOpenNotification
  );

  const setOpenArticle = notificationStore((state) => state.setOpenArticle);
  const openArticle = notificationStore((state) => state.openArticle);

  /**
   * Hides the search overlay
   */
  const hideOverlay = () => {
    setOpenNotification(false);
    setOpenArticle({ status: false, item: null });
  };

  /**
   * Renders the side overlay profile edit component
   * @returns {JSX.Element}
   */
  const renderArticleOverlay = () => {
    let data = {
      // articleCount: props.articleCount,
      newsItem: openArticle.item,
      article: openArticle.item.article,
      // articleRelevacnyData:props.articleRelevacnyData
      onBackPressNavigation: "Notification",
      // setRelevantArticle: selectRelevantArticle,
      // selectRemoveNotification: selectRemoveNotification,
    };

    return (
      <SideOverlay
        width="w-[calc(100%-410px)]"
        offset="410"
        bgColor="bg-white"
        handleCloseClick={() => setOpenArticle({ status: false, item: null })}
      >
        <div className="p-[30px]">
          <ArticleLayoutWeb {...data} />
        </div>
      </SideOverlay>
    );
  };
  /**
   * Main render method
   */
  return (
    <>
      <SideOverlay handleCloseClick={hideOverlay}>
        <div className="p-[30px]">
          <Notification />
        </div>
      </SideOverlay>
      ;{openArticle.status && renderArticleOverlay()}
    </>
  );
}

// Component export
export default NotificationOverlay;
