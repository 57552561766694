import {
  Box,
  ButtonBase,
  Checkbox,
  Dialog,
  LinearProgress,
} from "@mui/material";
import { useContext, useEffect } from "react";
import React from "react";
import CloseIconGray from "../../../images/svg/close_gray";
import Styles from "./saveSearchResultModal.module.css";
import { UserContext } from "../../../contexts/userContext";
import { capitalizeCase } from "../../../utils/utils";

const SaveSearchResultModal = ({
  setShowSaveConfirmationAlert,
  name,
  cancelBtnClick,
  setChkBxNotifyUser,
  chkBxNotifyUser,
  setSaveSearch,
  saveSearch,
  setMonitorSearch,
  monitorSearch,
  saveMySearches,
  showNotifyUser,
}) => {

  const { user } = useContext(UserContext);

  useEffect(() => {
    setChkBxNotifyUser(showNotifyUser);
  }, []);

  const onCrossBtnClick = () => {
    setSaveSearch(false);
    setChkBxNotifyUser(false);
    setMonitorSearch(false);
    setShowSaveConfirmationAlert(false);
  };

  return (
    <Dialog
      open={true}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
          margin: 0,
        },
      }}
    >
      <div className={Styles.container}>
        <ButtonBase className={Styles.crossButton} onClick={onCrossBtnClick}>
          <CloseIconGray className={Styles.closeIcon} />
        </ButtonBase>
        <h2 className={Styles.title}>
          Do you want to save your search for {name}?
        </h2>

        <div>
          <span className={Styles.desc}>
            {"Save or monitor this search to make it easy to find out new records for " + capitalizeCase(name, true) + "."}
          </span>

          <div className={Styles.checkBoxDiv}>
            {showNotifyUser && (
              <div className={Styles.checkBoxContainer}>
                <Box
                  className={Styles.checkBoxProgress}
                  sx={{
                    width: 30,
                    "& .MuiLinearProgress-colorPrimary": {
                      backgroundColor: "#91CB6C",
                    },
                    "& .MuiLinearProgress-barColorPrimary": {
                      backgroundColor: "#EFF1F5",
                    },
                  }}
                >
                  <LinearProgress className={Styles.progress} />
                </Box>
                <span className={Styles.labelTxt}>
                  Get notified when current results are finished processing
                </span>
                <Checkbox
                  disabled={false}
                  checked={chkBxNotifyUser}
                  onChange={(event) => {
                    setChkBxNotifyUser(event.target.checked);
                  }}
                  color="success"
                  sx={{
                    color: "#078167",
                    "&.Mui-checked": {
                      color: "#078167",
                    },
                    padding: 0,
                  }}
                />
              </div>
            )}

            <div
              className={`${Styles.checkBoxContainer} ${Styles.checkBoxContainerCenter}`}
            >
              <span className={Styles.labelTxt}>Save search</span>
              <Checkbox
                disabled={false}
                checked={saveSearch}
                onChange={(event) => {
                  setSaveSearch(event.target.checked);
                }}
                color="success"
                sx={{
                  color: "#078167",
                  "&.Mui-checked": {
                    color: "#078167",
                  },
                  padding: 0,
                }}
              />
            </div>

            <div className={Styles.checkBoxContainer}>
              <span className={Styles.labelTxt}>Save & monitor search</span>
              <Checkbox
                disabled={false}
                checked={monitorSearch}
                onChange={(event) => {
                  setMonitorSearch(event.target.checked);
                }}
                color="success"
                sx={{
                  color: "#078167",
                  "&.Mui-checked": {
                    color: "#078167",
                  },
                  padding: 0,
                }}
              />
            </div>
          </div>

          <hr className={Styles.horizontalLine} />

          <ButtonBase
            className={`${Styles["btnApply"]} ${
              !chkBxNotifyUser && !saveSearch && !monitorSearch
                ? Styles["btnWhite"]
                : Styles["btnGreen"]
            }`}
            onClick={
              !chkBxNotifyUser && !saveSearch && !monitorSearch
                ? cancelBtnClick
                : saveMySearches
            }
            fullWidth={true}
          >
            <div
              className={`${Styles["btnApplyTxt"]} ${
                !chkBxNotifyUser && !saveSearch && !monitorSearch
                  ? Styles["btnApplyTxtGreen"]
                  : Styles["btnApplyTxtWhite"]
              }`}
            >
              {!chkBxNotifyUser && !saveSearch && !monitorSearch
                ? "Close"
                : "Apply"}
            </div>
          </ButtonBase>
        </div>
      </div>
    </Dialog>
  );
};

export default SaveSearchResultModal;
