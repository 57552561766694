import moment from "moment-timezone";

const REFERENCE = moment(new Date());
const filterSection = (articles) => {
  let articleData = [];
  if (articles.length > 0) {
    let YESTERDAY = REFERENCE.clone().subtract(1, "days");
    let THIS_WEEK = REFERENCE.clone().subtract(REFERENCE.weekday(), "days");
    let LAST_WEEK = REFERENCE.clone().subtract(REFERENCE.weekday() + 7, "days");
    let THIS_MONTH = REFERENCE.clone().subtract(REFERENCE.date(), "days");
    let LAST_MONTH = REFERENCE.clone().subtract(REFERENCE.date() + 31, "days");

    let filterData = {};
    articles.forEach((item) => {
      item.isRead = true;
      const date = moment(parseInt(item.updatedAt));
      if (date.format("YYYY-MM-DD") === REFERENCE.format("YYYY-MM-DD")) {
        if (!filterData.hasOwnProperty("Viewed Today"))
          filterData["Viewed Today"] = [];
        filterData["Viewed Today"].push(item);
      } else if (date.format("YYYY-MM-DD") === YESTERDAY.format("YYYY-MM-DD")) {
        if (!filterData.hasOwnProperty("Viewed Yesterday"))
          filterData["Viewed Yesterday"] = [];
        filterData["Viewed Yesterday"].push(item);
      } else if (THIS_WEEK.isBefore(date)) {
        if (!filterData.hasOwnProperty("Viewed This Week"))
          filterData["Viewed This Week"] = [];
        filterData["Viewed This Week"].push(item);
      } else if (LAST_WEEK.isBefore(date)) {
        if (!filterData.hasOwnProperty("Viewed Last Week"))
          filterData["Viewed Last Week"] = [];
        filterData["Viewed Last Week"].push(item);
      } else if (THIS_MONTH.isBefore(date)) {
        if (!filterData.hasOwnProperty("Viewed This Month"))
          filterData["Viewed This Month"] = [];
        filterData["Viewed This Month"].push(item);
      } else if (LAST_MONTH.isBefore(date)) {
        if (!filterData.hasOwnProperty("Viewed Last Month"))
          filterData["Viewed Last Month"] = [];
        filterData["Viewed Last Month"].push(item);
      } else {
        if (!filterData.hasOwnProperty("Viewed Older"))
          filterData["Viewed Older"] = [];
        filterData["Viewed Older"].push(item);
      }
    });

    const orderedKeys = [
      "Viewed Today",
      "Viewed Yesterday",
      "Viewed This Week",
      "Viewed Last Week",
      "Viewed This Month",
      "Viewed Last Month",
      "Viewed Older"
    ];

    orderedKeys.forEach((key) => {
      if (filterData.hasOwnProperty(key)) {
        articleData.push({
          title: key,
          data: filterData[key],
        });
      }
    });
  }
  return articleData;
};

export default filterSection;
