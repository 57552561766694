// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".savedSearchMatchWeb_searchTitleMatch__A\\+mwM {\n  font-size: 36px;\n  margin-top: 6px;\n  padding-left: 10px;\n  margin-bottom: 10px;\n  font-weight: 700;\n  margin-left: 2px;\n  font-family: \"Proxima Nova\";\n  font-style: normal;\n  letter-spacing: -0.01;\n  color: #142030;\n}\n.savedSearchMatchWeb_mainBg__SJj5- {\n}\n.savedSearchMatchWeb_mainList__uhcGK {\n  margin-top: 20px;\n display: flex;\n flex-direction: column;\n overflow-y: scroll;\n height: calc(100vh - 130px);\n}", "",{"version":3,"sources":["webpack://./src/components/savedSearchMatch/savedSearchMatchWeb.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,eAAe;EACf,kBAAkB;EAClB,mBAAmB;EACnB,gBAAgB;EAChB,gBAAgB;EAChB,2BAA2B;EAC3B,kBAAkB;EAClB,qBAAqB;EACrB,cAAc;AAChB;AACA;AACA;AACA;EACE,gBAAgB;CACjB,aAAa;CACb,sBAAsB;CACtB,kBAAkB;CAClB,2BAA2B;AAC5B","sourcesContent":[".searchTitleMatch {\n  font-size: 36px;\n  margin-top: 6px;\n  padding-left: 10px;\n  margin-bottom: 10px;\n  font-weight: 700;\n  margin-left: 2px;\n  font-family: \"Proxima Nova\";\n  font-style: normal;\n  letter-spacing: -0.01;\n  color: #142030;\n}\n.mainBg {\n}\n.mainList {\n  margin-top: 20px;\n display: flex;\n flex-direction: column;\n overflow-y: scroll;\n height: calc(100vh - 130px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchTitleMatch": "savedSearchMatchWeb_searchTitleMatch__A+mwM",
	"mainBg": "savedSearchMatchWeb_mainBg__SJj5-",
	"mainList": "savedSearchMatchWeb_mainList__uhcGK"
};
export default ___CSS_LOADER_EXPORT___;
