import React, { useState, useEffect } from "react";
import createStyle from "./styles";
import GroupTags from "../groupTags/GroupTags";
import TitleGroup from "../atoms/titleGroup";
import { filterChangesCounter } from "../../utils/filterUtils";
import SearchFiter from "../../images/svg/searchfilter";
import {
  ButtonBase,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { Modal } from "@mui/material";
import Slide from "@mui/material/Slide";
import { GreenBgButton } from "../buttons/greenBgButton";
import CloseBt from "../../images/svg/close.svg";

var _ = require("lodash");
const FilterModal = ({
  filterModalVisible,
  setFilterModalVisible,
  sortState,
  setSortState,
  showStrongMatchArticles,
  setShowStrongMatchArticles,
  showWeakMatchArticles,
  setShowWeakMatchArticles,
  filterAttributes,
  setFilterAttributes,
  readFilter,
  setReadFilter,
  unreadFilter,
  setUnreadFilter,
  isAllShow,
  attributes: orignalAttributes,
  setAttributes,
}) => {
  const style = createStyle();
  let maxShowItem = 6;
  let showMoreCount = 50;

  const [localFilterAttributes, setLocalFilterAttributes] = useState(
    filterAttributes ?? {
      People: [],
      Organizations: [],
      Places: [],
      Parties: [],
      Attorneys: [],
    }
  );
  const [toggleStrongMatch, setToggleStrongMatch] = useState(
    showStrongMatchArticles
  );
  const [toggleWeakMatch, setToggleWeakMatch] = useState(showWeakMatchArticles);
  const [localSortState, setLocalSortState] = useState(sortState);
  const [filterChangesCount, setFilterChangesCount] = useState(0);
  const [toggleRead, setToggleRead] = useState(readFilter);
  const [toggleUnread, setToggleUnread] = useState(unreadFilter);

  const [nameShowMore, setNameShowMore] = useState(false);
  const [placeShowMore, setPlaceShowMore] = useState(false);
  const [orgShowMore, setOrgShowMore] = useState(false);
  const [partiesShowMore, setPartiesShowMore] = useState(false);
  const [attorneysShowMore, setAttorneysShowMore] = useState(false);

  const [nameShowMoreCount, setNameShowMoreCount] = useState(maxShowItem);
  const [placeShowMoreCount, setPlaceShowMoreCount] = useState(maxShowItem);
  const [orgShowMoreCount, setOrgShowMoreCount] = useState(maxShowItem);
  const [partiesShowMoreCount, setPartiesShowMoreCount] = useState(maxShowItem);
  const [attorneysShowMoreCount, setAttorneysShowMoreCount] =
    useState(maxShowItem);

  const [nameSearch, setNameSearch] = useState();
  const [placeSearch, setPlaceSearch] = useState();
  const [orgSearch, setOrgSearch] = useState();
  const [partiesSearch, setPartiesSearch] = useState();
  const [attorneysSearch, setAttorneysSearch] = useState();
  const [attributes, setSortAttributes] = useState();

  useEffect(() => {
    if (orignalAttributes) {
      let localAttributes = [...orignalAttributes];
      if (
        (nameSearch && nameSearch.length) ||
        (placeSearch && placeSearch.length) ||
        (orgSearch && orgSearch.length) ||
        (partiesSearch && partiesSearch.length) ||
        (attorneysSearch && attorneysSearch.length)
      ) {
        localAttributes = localAttributes.map((category) => {
          let searchTag =
            category.title == "Name(s)"
              ? nameSearch
              : category.title == "Place(s)"
                ? placeSearch
                : category.title == "Organization(s)"
                  ? orgSearch
                  : category.title == "Parties"
                    ? partiesSearch
                    : attorneysSearch;
          let tagValue = category?.tags.filter((tags) =>
            searchTag && searchTag.length
              ? typeof tags === "object"
                ? tags.label.toLowerCase().includes(searchTag.toLowerCase())
                : tags.toLowerCase().includes(searchTag.toLowerCase())
              : true
          );

          if (
            category.title == "Name(s)" &&
            tagValue?.length > nameShowMoreCount
          )
            setNameShowMore(false);
          if (
            category.title == "Place(s)" &&
            tagValue?.length > placeShowMoreCount
          )
            setPlaceShowMore(false);
          if (
            category.title == "Organization(s)" &&
            tagValue?.length > orgShowMoreCount
          )
            setOrgShowMore(false);
          if (
            category.title == "Parties" &&
            tagValue?.length > partiesShowMoreCount
          )
            setPartiesShowMore(false);
          if (
            category.title == "Attorneys" &&
            tagValue?.length > attorneysShowMoreCount
          )
            setAttorneysShowMore(false);

          return {
            title: category.title,
            tags: tagValue,
          };
        });
      } else {
        localAttributes.forEach((category) => {
          if (category?.tags?.length > nameShowMoreCount)
            setNameShowMore(false);
          if (category?.tags?.length > placeShowMoreCount)
            setPlaceShowMore(false);
          if (category?.tags?.length > orgShowMoreCount) setOrgShowMore(false);
          if (category?.tags?.length > partiesShowMoreCount)
            setPartiesShowMore(false);
          if (category?.tags?.length > attorneysShowMoreCount)
            setAttorneysShowMore(false);
        });
      }
      setSortAttributes(localAttributes);
    }
  }, [
    orignalAttributes,
    nameSearch,
    placeSearch,
    orgSearch,
    partiesSearch,
    attorneysSearch,
  ]);

  function AttributesNotAvail({ title, style }) {
    let name = "";
    if (title?.toLowerCase() == "name(s)") name = "names";
    else if (title?.toLowerCase() == "place(s)") name = "places";
    else if (title?.toLowerCase() == "organization(s)") name = "organizations";
    else if (title?.toLowerCase() == "parties") name = "parties";
    else if (title?.toLowerCase() == "attorneys") name = "attorneys";
    return (
      <div style={style.filterRow}>
        <span style={{ paddingTop: 10, color: "grey" }}>
          Additional {name} will appear here when available
        </span>
      </div>
    );
  }

  useEffect(() => {
    setFilterChangesCount(
      filterChangesCounter(
        toggleStrongMatch,
        toggleWeakMatch,
        localSortState,
        toggleRead,
        toggleUnread,
        localFilterAttributes
      )
    );
  }, [
    toggleStrongMatch,
    toggleWeakMatch,
    localSortState,
    localFilterAttributes,
    toggleRead,
    toggleUnread,
    filterModalVisible,
  ]);

  const onReset = () => {
    setToggleStrongMatch(false);
    setToggleWeakMatch(false);
    setLocalSortState(2);
    setToggleRead(false);
    setToggleUnread(false);
    onResetDeselectAllAttributes(attributes, setAttributes);
  };

  const onCloseReset = () => {
    setToggleStrongMatch(showStrongMatchArticles);
    setToggleWeakMatch(showWeakMatchArticles);
    setLocalSortState(sortState);
    setToggleRead(readFilter);
    setToggleUnread(unreadFilter);
    onResetDeselectAttributes(filterAttributes);
  };

  const onResetDeselectAllAttributes = (attributes, setAttributes) => {
    setLocalFilterAttributes({
      People: [],
      Organizations: [],
      Places: [],
      Parties: [],
      Attorneys: [],
    });
  };

  const onResetDeselectAttributes = (filterAttributes) => {
    setLocalFilterAttributes(filterAttributes);
  };

  const onBackDropPressHandler = () => {
    setFilterModalVisible(false);
    onCloseReset();
  };

  const data = [
    {
      label: "Newest - Oldest",
      index: 2,
    },
    {
      label: "Oldest - Newest",
      index: 3,
    },
  ];

  const onSelectAttributeHandler = (grouptag, attributeName, status) => {
    let groupName =
      grouptag == "Name(s)"
        ? "People"
        : grouptag == "Place(s)"
          ? "Places"
          : grouptag == "Organization(s)"
            ? "Organizations"
            : grouptag == "Parties"
              ? "Parties"
              : "Attorneys";
    let localFilter = localFilterAttributes
      ? JSON.parse(JSON.stringify(localFilterAttributes))
      : {
        People: [],
        Organizations: [],
        Places: [],
        Parties: [],
        Attorneys: [],
      };
    if (!status) localFilter[groupName]?.push(attributeName);
    else if (status && localFilter[groupName]?.includes(attributeName))
      localFilter[groupName]?.splice(
        localFilter[groupName]?.indexOf(attributeName),
        1
      );
    setLocalFilterAttributes(localFilter);
  };

  const onShowMore = (title, size, less = false) => {
    if (title == "Name(s)") {
      if (less) {
        setNameShowMoreCount(maxShowItem);
        setNameShowMore(false);
      } else if (size > nameShowMoreCount) {
        if (size < nameShowMoreCount + showMoreCount)
          setNameShowMore(!nameShowMore);
        setNameShowMoreCount(nameShowMoreCount + showMoreCount); //add 50 tags on show more click if load all tags the UI stuck
      } else if (!nameShowMore) {
        setNameShowMore(!nameShowMore);
      } else if (nameShowMore) {
        setNameShowMoreCount(maxShowItem);
        setNameShowMore(!nameShowMore);
      }
    } else if (title == "Place(s)") {
      if (less) {
        setPlaceShowMoreCount(maxShowItem);
        setPlaceShowMore(false);
      } else if (size > placeShowMoreCount) {
        if (size < placeShowMoreCount + showMoreCount) setPlaceShowMore(true);
        setPlaceShowMoreCount(placeShowMoreCount + showMoreCount); //add 50 tags on show more click if load all tags the UI stuck
      } else if (!placeShowMore) {
        setPlaceShowMore(!placeShowMore);
      } else if (placeShowMore) {
        setPlaceShowMoreCount(maxShowItem);
        setPlaceShowMore(false);
      }
    } else if (title == "Organization(s)") {
      if (less) {
        setOrgShowMoreCount(maxShowItem);
        setOrgShowMore(false);
      } else if (size > orgShowMoreCount) {
        if (size < orgShowMoreCount + showMoreCount) setOrgShowMore(true);
        setOrgShowMoreCount(orgShowMoreCount + showMoreCount); //add 50 tags on show more click if load all tags the UI stuck
      } else if (!orgShowMore) {
        setOrgShowMore(!orgShowMore);
      } else if (orgShowMore) {
        setOrgShowMoreCount(maxShowItem);
        setOrgShowMore(false);
      }
    } else if (title == "Parties") {
      if (less) {
        setPartiesShowMoreCount(maxShowItem);
        setPartiesShowMore(false);
      } else if (size > partiesShowMoreCount) {
        if (size < partiesShowMoreCount + showMoreCount)
          setPartiesShowMore(true);
        setPartiesShowMoreCount(partiesShowMoreCount + showMoreCount); //add 50 tags on show more click if load all tags the UI stuck
      } else if (!partiesShowMore) {
        setPartiesShowMore(!partiesShowMore);
      } else if (partiesShowMore) {
        setPartiesShowMoreCount(maxShowItem);
        setPartiesShowMore(false);
      }
    } else if (title == "Attorneys") {
      if (less) {
        setAttorneysShowMoreCount(maxShowItem);
        setAttorneysShowMore(false);
      } else if (size > attorneysShowMoreCount) {
        if (size < attorneysShowMoreCount + showMoreCount)
          setAttorneysShowMore(true);
        setAttorneysShowMoreCount(attorneysShowMoreCount + showMoreCount); //add 50 tags on show more click if load all tags the UI stuck
      } else if (!attorneysShowMore) {
        setAttorneysShowMore(!attorneysShowMore);
      } else if (attorneysShowMore) {
        setAttorneysShowMoreCount(maxShowItem);
        setAttorneysShowMore(false);
      }
    }
  };

  const keyboardView = () => (
    <div style={style.parentContainer}>
      <div style={{ ...style.header }}>
        <div onClick={onBackDropPressHandler}>
          <span style={style.headerSideBtn}>Close</span>
        </div>
        <div style={{ ...style.headerTitleView }}>
          <span style={style.headerMiddleBtn}>
            {isAllShow ? "Filter" : "Sort & Filter"}
          </span>
        </div>
        <div onClick={onReset}>
          <span style={style.headerSideBtn}>
            Reset {filterChangesCount > 0 && `(${filterChangesCount})`}
          </span>
        </div>
      </div>
      <div style={style.container}>
        <div style={style.body}>
          {/* {!isAllShow && (
            <div style={{ ...style.filterRow, paddingLeft: 0 }}>
              <span style={{ ...style.filterTextHeaders, paddingBottom: 10 }}>
                Sort by
              </span>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={localSortState}
                defaultValue={localSortState}
                name="radio-buttons-group"
                onChange={(event, value) => {
                  setLocalSortState(value);
                }}
              >
                {data.map((item) => (
                  <FormControlLabel
                    value={item.index}
                    control={
                      <Radio
                        sx={{
                          color: "#078167",
                          "&.Mui-checked": {
                            color: "#078167",
                          },
                        }}
                      />
                    }
                    sx={{
                      color: "#142030",
                      fontFamily: "Proxima Nova",
                      fontWeight: 400,
                      fontSize: 16,
                    }}
                    label={item.label}
                  />
                ))}
              </RadioGroup>
            </div>
          )} */}
          <div style={style.filterRow}>
            {/* <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={style.filterTextHeaders}>Filter by</span>
               <span style={style.filterTextHeaders}>Match Strength</span> 
            </div>
            <div
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              paddingTop: 8,
            }}>
            <Checkbox
              disabled={false}
              checked={toggleStrongMatch}
              onChange={(event) => {
                setToggleStrongMatch(event.target.checked);
                setToggleWeakMatch(!event.target.checked);
              }}
              color="success"
              sx={{
                color: "#078167",
                '&.Mui-checked': {
                  color: "#078167",
                },
                paddingLeft: 0
              }}
            />
            <span style={style.label}>
              Strong Match
            </span>
          </div>
          <div style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Checkbox
              disabled={false}
              checked={toggleWeakMatch}
              onChange={(event) => {
                setToggleStrongMatch(!event.target.checked);
                setToggleWeakMatch(event.target.checked);
              }}
              color="success"
              sx={{
                color: "#078167",
                '&.Mui-checked': {
                  color: "#078167",
                },
                paddingLeft: 0
              }}
            />
            <span style={style.label}>Weak Match</span>
          </div> */}
          </div>
          <div>
            {attributes?.map(({ title, tags }, index) => (
              <>
                <TitleGroup label={title} style={{ marginBottom: 20 }} />
                {orignalAttributes[index]?.tags?.length > maxShowItem && (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      value={
                        title == "Name(s)"
                          ? nameSearch
                          : title == "Place(s)"
                            ? placeSearch
                            : title == "Organization(s)"
                              ? orgSearch
                              : title == "Parties"
                                ? partiesSearch
                                : attorneysSearch
                      }
                      onChange={(e) => {
                        title == "Name(s)"
                          ? setNameSearch(e.target.value)
                          : title == "Place(s)"
                            ? setPlaceSearch(e.target.value)
                            : title == "Organization(s)"
                              ? setOrgSearch(e.target.value)
                              : title == "Parties"
                                ? setPartiesSearch(e.target.value)
                                : setAttorneysSearch(e.target.value);
                      }}
                      style={style.searchTags}
                      placeholder={
                        title == "Name(s)"
                          ? "Search name..."
                          : title == "Place(s)"
                            ? "Search place..."
                            : title == "Organization(s)"
                              ? "Search organization..."
                              : title == "Parties"
                                ? "Search parties..."
                                : "Search attorneys..."
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchFiter />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <ButtonBase
                              style={{
                                paddingTop: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                paddingRight: 4,
                              }}
                              onClick={() => {
                                title == "Name(s)"
                                  ? setNameSearch("")
                                  : title == "Place(s)"
                                    ? setPlaceSearch("")
                                    : title == "Organization(s)"
                                      ? setOrgSearch("")
                                      : title == "Parties"
                                        ? setPartiesSearch("")
                                        : setAttorneysSearch("");
                              }}
                            >
                              <img src={CloseBt}></img>
                            </ButtonBase>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                )}
                {tags?.length == 0 &&
                  (title == "Name(s)"
                    ? nameSearch == undefined || nameSearch?.length == 0
                    : title == "Place(s)"
                      ? placeSearch == undefined || placeSearch?.length == 0
                      : title == "Organization(s)"
                        ? orgSearch == undefined || orgSearch?.length == 0
                        : title == "Parties"
                          ? partiesSearch == undefined || partiesSearch?.length == 0
                          : attorneysSearch == undefined || attorneysSearch?.length == 0) &&
                  <div style={{ ...style.titleGroup, color: "grey", paddingBottom: "16px", textAlign: "start" }}>
                    No tags {title} found
                  </div>
                }
                {!tags?.length > 0 &&
                  (title == "Name(s)"
                    ? nameSearch?.length > 0
                    : title == "Place(s)"
                      ? placeSearch?.length > 0
                      : title == "Organization(s)"
                        ? orgSearch?.length > 0
                        : title == "Parties"
                          ? partiesSearch?.length > 0
                          : attorneysSearch?.length > 0) && (
                    <span style={style.titleGroup}>
                      No matching {title} found
                    </span>
                  )}
                {tags?.length > 0 ? (
                  <div style={style.filterRow}>
                    <GroupTags
                      isFromFilter={true}
                      key={index}
                      title={title}
                      tags={tags}
                      filterAttributes={localFilterAttributes}
                      showMoreTagsBtn={
                        title == "Name(s)"
                          ? nameShowMore
                          : title == "Place(s)"
                            ? placeShowMore
                            : title == "Organization(s)"
                              ? orgShowMore
                              : title == "Parties"
                                ? partiesShowMore
                                : attorneysShowMore
                      }
                      showMoreTagsCount={
                        title == "Name(s)"
                          ? nameShowMoreCount
                          : title == "Place(s)"
                            ? placeShowMoreCount
                            : title == "Organization(s)"
                              ? orgShowMoreCount
                              : title == "Parties"
                                ? partiesShowMoreCount
                                : attorneysShowMoreCount
                      }
                      selectable={true}
                      maxShowItem={maxShowItem}
                      groupName={title}
                      onPressTag={(groupName, label, status) => {
                        onSelectAttributeHandler(groupName, label, status);
                      }}
                      lastChild={index === attributes.length - 1}
                      tagStyle={{
                        backgroundColor: "#EFF1F5",
                      }}
                    />
                    {tags?.length > maxShowItem && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <span
                          style={style.showMore}
                          onClick={() => onShowMore(title, tags?.length)}
                        >
                          {(title == "Name(s)"
                            ? nameShowMore
                            : title == "Place(s)"
                              ? placeShowMore
                              : title == "Organization(s)"
                                ? orgShowMore
                                : title == "Parties"
                                  ? partiesShowMore
                                  : attorneysShowMore) ||
                            tags?.length <
                            (title == "Name(s)"
                              ? nameShowMoreCount
                              : title == "Place(s)"
                                ? placeShowMoreCount
                                : title == "Organization(s)"
                                  ? orgShowMoreCount
                                  : title == "Parties"
                                    ? partiesShowMoreCount
                                    : attorneysShowMoreCount)
                            ? ""
                            : "Show More (" +
                            (tags?.length -
                              1 -
                              (title == "Name(s)"
                                ? nameShowMoreCount
                                : title == "Place(s)"
                                  ? placeShowMoreCount
                                  : title == "Organization(s)"
                                    ? orgShowMoreCount
                                    : title == "Parties"
                                      ? partiesShowMoreCount
                                      : attorneysShowMoreCount)) +
                            ")"}
                        </span>
                        <span
                          style={style.showLess}
                          onClick={() => onShowMore(title, tags?.length, true)}
                        >
                          {(
                            title == "Name(s)"
                              ? nameShowMoreCount > 6
                              : title == "Place(s)"
                                ? placeShowMoreCount > 6
                                : title == "Organization(s)"
                                  ? orgShowMoreCount > 6
                                  : title == "Parties"
                                    ? partiesShowMoreCount > 6
                                    : attorneysShowMoreCount > 6
                          )
                            ? "Show Less"
                            : ""}
                        </span>
                      </div>
                    )}
                  </div>
                ) : (
                  !(title == "Name(s)"
                    ? nameSearch && nameSearch.length
                    : title == "Place(s)"
                      ? placeSearch && placeSearch.length
                      : title == "Organization(s)"
                        ? orgSearch && orgSearch.length
                        : title == "Parties"
                          ? partiesSearch && partiesSearch.length
                          : attorneysSearch && attorneysSearch.length) &&
                  !isAllShow && (
                    <AttributesNotAvail
                      title={title}
                      style={style}
                      key={title}
                    />
                  )
                )}
              </>
            ))}
          </div>
          {!isAllShow && (
            <div style={style.filterRow}>
              <div>
                <span style={style.filterTextHeaders}>Unread / Read</span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  paddingTop: 14,
                }}
              >
                <Checkbox
                  disabled={false}
                  checked={toggleUnread}
                  onChange={(event) => {
                    setToggleUnread(event.target.checked);
                    setToggleRead(!event.target.checked);
                  }}
                  color="success"
                  sx={{
                    color: "#078167",
                    "&.Mui-checked": {
                      color: "#078167",
                    },
                    paddingLeft: 0,
                  }}
                />
                <span style={style.label}>Unread</span>
              </div>
              <div style={{ flexDirection: "row", alignItems: "center" }}>
                <Checkbox
                  disabled={false}
                  checked={toggleRead}
                  onChange={(event) => {
                    setToggleRead(event.target.checked);
                    setToggleUnread(!event.target.checked);
                  }}
                  color="success"
                  sx={{
                    color: "#078167",
                    "&.Mui-checked": {
                      color: "#078167",
                    },
                    paddingLeft: 0,
                  }}
                />
                <span style={style.label}>Read</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div style={style.applyBtnDiv}>
        <GreenBgButton
          fullWidth
          variant="contained"
          style={style.applyBtn}
          onClick={() => {
            setShowStrongMatchArticles(toggleStrongMatch);
            setShowWeakMatchArticles(toggleWeakMatch);
            setSortState(localSortState);
            setFilterAttributes(localFilterAttributes);
            setFilterModalVisible(false);
            setReadFilter(toggleRead);
            setUnreadFilter(toggleUnread);
          }}
        >
          {filterChangesCount < 1 ? `Apply` : `Apply (${filterChangesCount})`}
        </GreenBgButton>
      </div>
    </div>
  );
  return (
    <Modal
      open={filterModalVisible}
      onClose={onBackDropPressHandler}
      style={style.modal}
      closeAfterTransition
    >
      <Slide in={filterModalVisible} direction="up">
        {keyboardView()}
      </Slide>
    </Modal>
  );
};
export default FilterModal;