import { ButtonBase, Modal } from '@mui/material';
import React from 'react';
import CloseIconGray from '../../images/svg/close_gray';
import Styles from './installPWA.module.css';
import { GreenBgButton } from '../buttons/greenBgButton';
import { doInstallPWA, isThisDeviceRunningiOS } from '../../utils/installPWAHelper';
import FerretFavicon from '../../images/svg/ferret-favicon';

const InstallPWA = ({ setShowInstallPWA, setShowInstallPWAIOS }) => {

  const installBtnClick = () => {
    let isIOS = isThisDeviceRunningiOS()
    setShowInstallPWA(false)
    if (isIOS) {
      setShowInstallPWAIOS(true)
    } else {
      doInstallPWA()
    }
  }

  return (
    <Modal open={true} className={Styles.modal}>
      <div className={Styles.container}>
        <div className={Styles.main}>
          <main className={Styles.content}>
            <div className={Styles.btnClose}>
              <ButtonBase onClick={() => { setShowInstallPWA(false) }}>
                <CloseIconGray className={Styles.iconClose} />
              </ButtonBase>
            </div>
            <FerretFavicon className={Styles.logo} />
            <span className={Styles.title} >Ferret.ai</span>
          </main>
          <GreenBgButton
            onClick={installBtnClick}
            className={Styles.installBtn}
          >
            <div className={Styles.installBtnTxt}>Install</div>
          </GreenBgButton>
        </div>
      </div>
    </Modal>
  )
}

export default InstallPWA