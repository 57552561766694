// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".personalInfoummary_linkedInSectionView__boqyC {\n  flex-direction: row;\n  display: flex;\n  justify-content: space-between;\n  width: -webkit-fill-available;\n}\n.personalInfoummary_sectionView__-cS\\+k {\n  font-family: \"Proxima Nova\";\n  font-style: normal;\n  font-weight: 700;\n  font-size: 14px;\n  padding-left: 6;\n  color: #142030;\n  flex-grow: 0;\n  width: 30%;\n}\n.personalInfoummary_sectionView2__B0qq1 {\n  font-family: \"Proxima Nova\";\n  font-style: normal;\n  font-weight: 400;\n  font-size: 14px;\n  padding-left: 6;\n  color: #142030;\n  flex-grow: 0;\n  width: 70%;\n  text-align: end;\n}\n", "",{"version":3,"sources":["webpack://./src/web/components/PersonInfo/personalInfoummary.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,aAAa;EACb,8BAA8B;EAC9B,6BAA6B;AAC/B;AACA;EACE,2BAA2B;EAC3B,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,eAAe;EACf,cAAc;EACd,YAAY;EACZ,UAAU;AACZ;AACA;EACE,2BAA2B;EAE3B,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,eAAe;EACf,cAAc;EACd,YAAY;EACZ,UAAU;EACV,eAAe;AACjB","sourcesContent":[".linkedInSectionView {\n  flex-direction: row;\n  display: flex;\n  justify-content: space-between;\n  width: -webkit-fill-available;\n}\n.sectionView {\n  font-family: \"Proxima Nova\";\n  font-style: normal;\n  font-weight: 700;\n  font-size: 14px;\n  padding-left: 6;\n  color: #142030;\n  flex-grow: 0;\n  width: 30%;\n}\n.sectionView2 {\n  font-family: \"Proxima Nova\";\n  font-weight: 400;\n  font-style: normal;\n  font-weight: 400;\n  font-size: 14px;\n  padding-left: 6;\n  color: #142030;\n  flex-grow: 0;\n  width: 70%;\n  text-align: end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"linkedInSectionView": "personalInfoummary_linkedInSectionView__boqyC",
	"sectionView": "personalInfoummary_sectionView__-cS+k",
	"sectionView2": "personalInfoummary_sectionView2__B0qq1"
};
export default ___CSS_LOADER_EXPORT___;
