import { TextField, Input } from '@mui/material';
import { fontSize } from '@mui/system';
import React, { useEffect, useRef } from 'react';
import searchGlyph from "../../images/svg/searchGlyph.svg"
import CloseBlackIcon from '../../images/svg/closeWhite';
import { AddSharp } from '@mui/icons-material';
import Styles from './SearchHeader.module.css'

const SearchHeader = props => {
  const searchRef = useRef()
  const { value, onChangeText, onCancel, selectAllButton, placeholder } = props
  const clearText = (isCancel) => {
    onChangeText('')
    if (!isCancel)
      searchRef?.current?.focus()
  }
  return (
    <div className={Styles.containermain}>
      <div className={Styles.container}>
        <img src={searchGlyph} className={Styles.searchIcon}></img>
        <Input
          inputRef={searchRef}
          autoComplete={'no'}
          value={value}
          onChange={(event) => { onChangeText(event.target.value) }}
          className={Styles.headerSearchText}
          disableUnderline={true}
          placeholder={placeholder}
          InputProps={{
            disableUnderline: true,
            style: { textTransform: 'capitalize', backgroundColor: '#fff', fontSize: 16 },
            sx: {
              input: {
                color: "#8E96A8",
                fontFamily: "Proxima Nova",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: 14,
              },
            },
          }} />
        {(value && value != '') && <div onClick={() => clearText()} className={Styles.closeIcon}><CloseBlackIcon style={{ width: 6, heigth: 6 }} /></div>}
      </div>
      {(value && value != '') && <span onClick={() => clearText(true)} className={Styles.textCancel}>Cancel</span>}
    </div>
  )
}
export default SearchHeader