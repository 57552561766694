const parseSectionListHeight = (contacts,offsetList,countPush, topRiskHeight = false, isStarredFilter = false, isInTheNews = false, displayTags=true) => {
    let count = countPush;
    
    if(topRiskHeight && count == 0){
        offsetList[count] = topRiskHeight
        count++;
    }else{
        offsetList[count] = (count ? offsetList[count-1] + 30 : 0)
        count++;
    }
    offsetList[count] = (offsetList[count-1] + 30)
    count++;
    contacts.forEach((item,index)=>{
        let savedSearch = item?.originalContact;

        let iconsList = savedSearch?.summary ? Object.values(savedSearch.summary)?.filter(item=>item?.total) : []

        let viewSize = isInTheNews ? 42 : isStarredFilter ? 55 : 42
        let metaDataLength = savedSearch?.meta_data_array?.length;
        if(metaDataLength && displayTags){
            viewSize = (
                35 //EXPECTED TAG HEIGHT
                + (isInTheNews ? 51 : isStarredFilter ? 63 : 52)) //EXPECTED CARD HEIGHT WITHOUT TAG
        }

        if(savedSearch?.isProcessed == false)
            viewSize = viewSize + 26

        if( !savedSearch?.name || savedSearch?.name == '' || !savedSearch?.lastName || savedSearch?.lastName == '')
            viewSize = viewSize + 26

        if(savedSearch?.isProcessed && savedSearch?.multiplePotentialMatchesFound)
            viewSize = viewSize + 34
        
        if(iconsList && iconsList.length)
            viewSize = viewSize + (metaDataLength ? 32 : 38)
        
        if(isStarredFilter){
          offsetList[count] = (offsetList[count-1] + (metaDataLength ? viewSize+10 : viewSize+5))
          count++;
          item.customSize = viewSize
          item.originalContact.customSize = viewSize
        }else{
          offsetList[count] =(offsetList[count-1] + (viewSize + 10))
          count++;
          item.customSize = viewSize;
          item.originalContact.customSize = viewSize;
        }
    })
    return count;
}
export default parseSectionListHeight;