// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".notificationBanner_containerMain__YIMlp {\n  border-radius: 6px;\n  padding: 20px;\n  display: flex;\n  flex-direction: column;\n  background-color: #ffffff;\n  border: 1px solid #eff1f5;\n  box-shadow: 0px 4px 51px rgba(0, 0, 0, 0.05);\n}\n", "",{"version":3,"sources":["webpack://./src/web/components/NotificationListView/notificationBanner.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,yBAAyB;EACzB,4CAA4C;AAC9C","sourcesContent":[".containerMain {\n  border-radius: 6px;\n  padding: 20px;\n  display: flex;\n  flex-direction: column;\n  background-color: #ffffff;\n  border: 1px solid #eff1f5;\n  box-shadow: 0px 4px 51px rgba(0, 0, 0, 0.05);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerMain": "notificationBanner_containerMain__YIMlp"
};
export default ___CSS_LOADER_EXPORT___;
