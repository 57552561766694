import * as React from "react"

const QuestionMarkGreen = (props) => (
  <svg
    width={7}
    height={13}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.861 6.364c.486-.878 1.421-1.396 1.964-2.173.575-.814.253-2.337-1.377-2.337-1.067 0-1.591.809-1.812 1.478L0 2.644C.448 1.299 1.667.143 3.442.143c1.484 0 2.501.676 3.019 1.522.442.726.701 2.084.019 3.095-.758 1.118-1.484 1.459-1.876 2.179-.158.29-.22.48-.22 1.414H2.557c-.006-.492-.082-1.294.303-1.99Zm1.85 4.516c0 .694-.568 1.263-1.263 1.263a1.267 1.267 0 0 1-1.263-1.263c0-.695.569-1.263 1.263-1.263.695 0 1.264.568 1.264 1.263Z"
      fill={props?.selected ? '#078167' :'#8E96A8' }
    />
  </svg>
)

export default QuestionMarkGreen
