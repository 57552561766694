import moment from 'moment-timezone'

const getFormatedArticles = (articles) => {
  const now = moment()
  const riskCategories = {
    'HIGH': 'high',
    'MEDIUM': 'medium',
    'LOW': 'low'
  }
  const defaultProfilePicture =  require('../../images/default-avatar.png')
  return articles.map((article) => {
    //TODO: calculate the date the right way once the timestamp is sent from the backend
    
    if(article.hasOwnProperty('updatedAt'))
      article.date = moment(parseInt(article.updatedAt)).fromNow()
    else
      article.date = moment(parseInt(article.createdAt)).fromNow()

    if (!article.profilePicture) {
      article.profilePicture = defaultProfilePicture
    }
    article.riskCategory = riskCategories[article.riskCategory] ?? 'low'
    return article
  })
}

export default getFormatedArticles