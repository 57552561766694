// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.mapCategory_gm-style__0e9KZ .mapCategory_gm-style-iw__mwSpT {\n  font-size: 16px;\n  font-weight: bold;\n  font-family: sans-serif;\n  text-transform: uppercase;\n}\n\n.mapCategory_closeIcon__-Tr-C {\n  color: red; /* Change this to your desired color */\n  font-size: 24px; /* Change this to your desired font size */\n  /* Add any other styling properties you want */\n}", "",{"version":3,"sources":["webpack://./src/components/map/mapCategory.module.css"],"names":[],"mappings":";AACA;EACE,eAAe;EACf,iBAAiB;EACjB,uBAAuB;EACvB,yBAAyB;AAC3B;;AAEA;EACE,UAAU,EAAE,sCAAsC;EAClD,eAAe,EAAE,0CAA0C;EAC3D,8CAA8C;AAChD","sourcesContent":["\n.gm-style .gm-style-iw {\n  font-size: 16px;\n  font-weight: bold;\n  font-family: sans-serif;\n  text-transform: uppercase;\n}\n\n.closeIcon {\n  color: red; /* Change this to your desired color */\n  font-size: 24px; /* Change this to your desired font size */\n  /* Add any other styling properties you want */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gm-style": "mapCategory_gm-style__0e9KZ",
	"gm-style-iw": "mapCategory_gm-style-iw__mwSpT",
	"closeIcon": "mapCategory_closeIcon__-Tr-C"
};
export default ___CSS_LOADER_EXPORT___;
