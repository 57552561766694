import _ from 'lodash'
import { providers } from './contact-providers'

function ParsePhoneContactsForContactsComponent(contacts) {
  if (!Array.isArray(contacts) || contacts.length == 0) { return []}
  const parsedContacts = []
  contacts.sort((a, b) => {
    const fullNameA = `${a.givenName} ${a.familyName}`.toLowerCase().trim()
    const fullNameB = `${b.givenName} ${b.familyName}`.toLowerCase().trim()

    if (fullNameA < fullNameB) { return -1 }
    if (fullNameA > fullNameB) { return 1 }
    return 0
  }).forEach((contact) => {
    if (contact.givenName?.length === 0 || contact.givenName == contact.phoneNumbers?.[0]?.number) {
      return
    }
    const newContact = {
      firstName: contact.givenName,
      lastName: contact.familyName,
      photo: contact.photo ? contact.photo : require('./../images/default-avatar.png'),
      selected: true,
      isPreferred: contact.isPreferred ?? false,
      terms: [],
      originalContact: contact,
    }
    if(contact.postalAddresses?.[0]) {
      const postalAddress = contact.postalAddresses[0]
      let newContactAddress = ""
      if (postalAddress.city && postalAddress.city.length > 0) {
        newContactAddress = postalAddress.city;
      }
      if (postalAddress.state && postalAddress.state.length > 0) {
        newContactAddress += newContactAddress === "" ? postalAddress.state : `, ${postalAddress.state}`
      }
      newContact.terms.push(newContactAddress)
    }
    if (contact.company && contact.company.length > 0) {
      newContact.terms.push(contact.company)
    }
    if (contact.recordID && contact.recordID.length > 0) {
      newContact.idProvider = contact.recordID
    }
    const initialLetter = newContact?.firstName?.[0]?.toUpperCase() ?? '#'
    const lastSection = parsedContacts[parsedContacts.length - 1]
    if (parsedContacts.length == 0 || lastSection.title != initialLetter) {
      parsedContacts.push({
          title: initialLetter,
          data: [ newContact ]
      })
    } else {
      lastSection.data.push(newContact)
    }
  })
  return parsedContacts
}

function ParsePhoneContactsForMutation(contactsWithinSection,phoneEmails) {
  const contactsForMutation = []
  contactsWithinSection.forEach((section) => {
    section.data.forEach((contact) => {
      if (contact.selected === false) {
          return
      }
      let originalContact = contact.originalContact

      let emailFound = false
      let phoneFound = false

      const newContact = {
          name: originalContact.givenName,
          lastName: originalContact.familyName,
          company: originalContact.company,
          emailAddresses: originalContact.emailAddresses.map((emailData) => {
            if (emailData?.email?.length > 0 && !phoneEmails.includes((emailData.email?.trim()+'--'+originalContact.givenName?.trim()+' '+(originalContact.familyName ?? '')?.trim()))) {
              phoneEmails.push((emailData.email?.trim()+'--'+originalContact.givenName?.trim()+' '+(originalContact.familyName ?? '')?.trim()))
              emailFound = true
            }
            return {email: emailData?.email}
          }).filter((emailAddress) => emailAddress && Object.keys(emailAddress).length > 0),
          jobTitle: originalContact.jobTitle,
          postalAddresses: originalContact.postalAddresses.map((postalAddress) => { return _.pick(postalAddress, ['city', 'region', 'state', 'country'])}),
          phoneNumbers: originalContact.phoneNumbers.map((phoneNumberData) => {
            if (phoneNumberData?.number?.length > 0 && !phoneEmails.includes((phoneNumberData.number?.trim()+'--'+originalContact.givenName?.trim()+' '+(originalContact.familyName ?? '')?.trim()))) {
              phoneEmails.push((phoneNumberData.number?.trim()+'--'+originalContact.givenName?.trim()+' '+(originalContact.familyName ?? '')?.trim()))
              phoneFound=true
            }
            return {number: phoneNumberData?.number}
          }).filter((phoneNumber) => phoneNumber && Object.keys(phoneNumber).length > 0),
          provider: providers.phone,
          isPreferred: contact.isPreferred,
      }
      newContact?.postalAddresses?.forEach((postalAddress) => {
        if (postalAddress?.region == postalAddress?.state)
          delete postalAddress.state;
      });
      if (originalContact.birthday) {
          let month = originalContact.birthday.month
          if (parseInt(month) < 10) {
              month = `0${month}`
          }
          newContact.birthday = `${originalContact.birthday.year}-${month}-${originalContact.birthday.day}`
      }
      if (originalContact.recordID) {
        newContact.idProvider = originalContact.recordID
      }
      if(emailFound || phoneFound){
        contactsForMutation.push(newContact)
      }
    })
  })
  return contactsForMutation
}

export {ParsePhoneContactsForContactsComponent, ParsePhoneContactsForMutation,providers,}