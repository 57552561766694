import { Button, IconButton, Stack } from "@mui/material";
import { Buffer } from "buffer";
import { useContext, useEffect, useState } from 'react';
import { useMutation } from 'react-apollo';
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/userContext";
import { AppleIcon } from "../../images/svg/apple_icon";
import { BackIcon } from "../../images/svg/back";
import ferretLogo from "../../images/svg/ferret-logo.svg";
import { GoogleIcon } from "../../images/svg/google-icon";
import { AUTHENTICATE_APPLE_USER, AUTHENTICATE_GOOGLE_USER, SIGN_IN_FERRET_USER } from '../../queries/queries';
import { saveUserDataAndRedirectToInitialScreen } from '../../root-navigation';
import { signInApple } from "../../utils/apple-login-helper";
import { getDeviceDetails } from '../../utils/DeviceDetails';
import { fetchFirebaseToken } from '../../utils/firebase';
import { googleDefaultLogin } from "../../utils/google-login-helper";
import { setFirebaseToken } from '../../utils/safe-storage';
import Variables from '../../utils/Variables';
import SingleBtnAlert from "../alert/singleBtnAlert";
import TwoBtnAlert from '../alert/twoBtnAlert';
import { AppleBtn } from '../buttons/appleBtn';
import { GoogleBtn } from '../buttons/googleBtn';
import { GreenBgButton } from '../buttons/greenBgButton';
import { EmailField } from '../fields/emailField';
import { PasswordField } from '../fields/passwordField';
import Styles from './login.module.css';

const Login = () => {

  const [checkFormValid, setCheckFormValid] = useState(false)
  const [email, setEmail] = useState();
  const [emailValid, setEmailValid] = useState({ status: null, msg: "" });
  const [password, setPassword] = useState();
  const [passwordValid, setPasswordValid] = useState({ status: null, msg: "" });
  const [accountExist, setAccountExist] = useState({ status: null, msg: "" });
  const [showSingleBtnAlert, setShowSingleBtnAlert] = useState({ status: false, msg: "" });
  const [displayError, setDisplayError] = useState(false);
  const errorMessage = 'Email or password incorrect';

  const { setUser } = useContext(UserContext);

  const navigate = useNavigate();

  useEffect(() => {
    fetchFirebaseToken(setFirebaseToken)
  }, [])

  useEffect(() => {
    setDisplayError(false)
  }, [password, email])

  const [signInFerret, { loading: emailLoginLoader }] = useMutation(SIGN_IN_FERRET_USER,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        saveUserDataAndRedirectToInitialScreen(
          data.signInEmail,
          null,
          () => { },
          'login',
          null,
          setUser,
          navigate
        )
      },
      onError: (error) => {
        if (
          error &&
          error.graphQLErrors &&
          Array.isArray(error.graphQLErrors) &&
          error.graphQLErrors.length > 0
        ) {
          console.log('data=====onError', error)
          if (error.graphQLErrors[0].statusCode == "404") {
            setAccountExist({ status: false, msg: error.graphQLErrors[0].message })
          } else {
            setDisplayError(true);
          }
        }
      }
    });

  const [authenticateGoogleUser, { loading: googleLoginLoader }] = useMutation(AUTHENTICATE_GOOGLE_USER,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        saveUserDataAndRedirectToInitialScreen(
          data.authenticateGoogleUser,
          null,
          () => { },
          'login',
          null,
          setUser,
          navigate
        )
      },
      onError: (error) => {
        if (
          error &&
          error.graphQLErrors &&
          Array.isArray(error.graphQLErrors) &&
          error.graphQLErrors.length > 0
        ) {
          console.log('data=====onError', error)
          setAccountExist({ status: false, msg: error.graphQLErrors[0].message })
        }
      }
    });

  const onGoogleLogin = async (response) => {
    const deviceInfo = getDeviceDetails();
    authenticateGoogleUser({
      variables: {
        accessToken: response?.access_token,
        requestType: 'LOGIN',
        deviceDetails: deviceInfo,
      }
    })
  }

  const [authenticateAppleUser, { loading: appleLoginLoader }] = useMutation(AUTHENTICATE_APPLE_USER,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        saveUserDataAndRedirectToInitialScreen(
          data.authenticateAppleUser,
          null,
          () => { },
          'login',
          null,
          setUser,
          navigate
        )
      },
      onError: (error) => {
        if (
          error &&
          error.graphQLErrors &&
          Array.isArray(error.graphQLErrors) &&
          error.graphQLErrors.length > 0
        ) {
          console.log('data=====onError', error.graphQLErrors[0].message)
          setAccountExist({ status: false, msg: error.graphQLErrors[0].message })
        }
      }
    });

  const onAppleLogin = async () => {
    const response = await signInApple()
    const id_token = response.authorization?.id_token
    if (id_token) {
      const { email, name } = response.user ?? [];
      let mEmail = email;
      let firstName = name?.firstName;
      let lastName = name?.lastName;

      if (mEmail == null) {
        const data = JSON.parse(
          Buffer.from(id_token.split('.')[1], 'base64').toString(
            'ascii',
          ),
        );
        mEmail = data.email;
        firstName = data.email
          .split('@')[0]
          .replaceAll(/[^\w\s]/g, ' ')
          .replaceAll(/_/g, ' ');
        lastName = ' ';
      }
      const deviceInfo = getDeviceDetails();
      authenticateAppleUser({
        variables: {
          code: id_token,
          email: mEmail,
          name: firstName,
          lastName: lastName,
          requestType: 'LOGIN',
          deviceDetails: deviceInfo,
        },
      });
    }
  }

  const handleLogin = async () => {
    setCheckFormValid(true)
    setDisplayError(false)
    if (emailValid.status && passwordValid.status) {
      const deviceInfo = getDeviceDetails();
      const data = {
        "email": email,
        "password": password,
        deviceDetails: deviceInfo,
      }
      signInFerret({
        variables: data
      })
    }
  }

  return (
    <Stack className={Styles.bodyLogin}>

      <header className={Styles.navHeader}>
        {Variables.isFromLandingpage &&
          <IconButton
            className={Styles["backIconBtn"]}
            onClick={() => navigate((-1), { replace: true })}>
            <BackIcon />
          </IconButton>
        }

        <div className={Styles.navHeaderTitle}>Welcome Back</div>
      </header>

      <main className={Styles.loginMain}>

        <img src={ferretLogo} className={Styles.ferretLogo} alt="ferretLogo" />

        <div className={Styles.loginFormField}>
          <EmailField
            email={email}
            setEmail={setEmail}
            checkFormValid={checkFormValid}
            emailValid={emailValid}
            setEmailValid={setEmailValid} />
        </div>

        <div className={Styles.divPassword}>
          <PasswordField
            password={password}
            setPassword={setPassword}
            checkFormValid={checkFormValid}
            passwordValid={passwordValid}
            setPasswordValid={setPasswordValid}
            showPasswordIcon={true} />
        </div>

        {displayError && (
          <div className={Styles.errorMessage}>{errorMessage}</div>
        )}

        <button className={Styles.forgotPasswordButton} onClick={() => { navigate('/forgotPassword') }}>Forgot Password?</button>

        <GreenBgButton
          onClick={handleLogin}
          fullWidth
          variant="contained"
          loading={googleLoginLoader || emailLoginLoader || appleLoginLoader}>
          <div className={Styles.btnTxt}>Log In</div>
        </GreenBgButton>

        <div className={Styles.or}>OR</div>

        <GoogleBtn
          onClick={() => googleDefaultLogin(onGoogleLogin)}
          fullWidth
          variant="contained"
          startIcon={<GoogleIcon />}
          style={{
            marginTop: 16
          }}>
          <div className={Styles.signinGoogleTxt}>Sign in with Google</div>
        </GoogleBtn>

        <AppleBtn
          onClick={onAppleLogin}
          fullWidth
          variant="contained"
          startIcon={<AppleIcon />}
          style={{
            marginTop: 16
          }}>
          <div className={Styles.signinAppleTxt}>Sign in with Apple</div>
        </AppleBtn>

        <Button
          fullWidth
          variant="text"
          style={{
            marginTop: 16
          }}
          onClick={() => navigate('/acceptTermsOfService')}
        >
          <div className={Styles.signupTxt}>Not a member yet? Sign up</div>
        </Button>

        {(accountExist.status == false) && <TwoBtnAlert
          description={accountExist.msg}
          leftBtnTxt={"Cancel"}
          rightBtnTxt={"Sign Up"}
          onLeftBtnClick={() => {
            setAccountExist({ status: null, msg: "" })
          }}
          onRightBtnClick={() => {
            setAccountExist({ status: null, msg: "" })
            navigate('/acceptTermsOfService');
          }}
        />
        }

        {(showSingleBtnAlert.status == true) && <SingleBtnAlert
          description={showSingleBtnAlert.msg}
          btnTxt="OK"
          onBtnClick={() => {
            setShowSingleBtnAlert({ status: false, msg: "" })
          }}
        />
        }
      </main>
    </Stack >
  )
}

export default Login;
