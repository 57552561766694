import { Button, Stack, IconButton, Grid } from "@mui/material";
import react, { useState, useCallback, useEffect, useRef } from 'react'
import ferretLogo from "../../images/svg/ferret-logo.svg"
import tickGreen from "../../images/svg/tick-green.svg"
import termService from "../../images/svg/term_service.svg"
import { GreenBgButton } from '../buttons/greenBgButton';
import { GreenBorderButton } from '../buttons/greenBorderButton';
import { useNavigate } from "react-router-dom";
import TwoBtnAlert from '../alert/twoBtnAlert';
import Styles from "./acceptTermsOfService.module.css";
import { BackIcon } from "../../images/svg/back";

const AcceptTermsOfService = () => {
  const navigate = useNavigate();
  const [isAgree, setAgree] = useState(false)
  const [acceptBtnDisable, setAcceptBtnDisable] = useState(false)

  const refContainer = useRef();

  useEffect(() => {
    setTimeout(() => {
      let scrollableCheck =
        refContainer.current.scrollHeight > refContainer.current.clientHeight;
      if (!scrollableCheck) {
        setAcceptBtnDisable(true)
      }
    }, 200)

    window.addEventListener('scroll', handleScroll, true);

    // Remove the event listener
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, []);

  const handleScroll = () => {
    const bottom = Math.ceil(window.innerHeight + window.scrollY) >= (document.documentElement.scrollHeight - 60)
    if (!acceptBtnDisable && bottom)
      setAcceptBtnDisable(true)
  };

  return (
    <div>

      <div className={Styles.bodyAcceptTerms} ref={refContainer}>

        <IconButton className={Styles.bodyAcceptTermsBackButton} onClick={() => navigate((-1), { replace: true })}>
          <BackIcon />
        </IconButton>

        <div
          className={Styles.main}>
          <img src={ferretLogo} className={Styles.ferretLogo} alt="ferretLogo" />
          <img src={termService} className={Styles.termService} alt="termService" />
          <div className={Styles.termCondition}>Terms and Conditions</div>
          <div className={Styles.lastReview}>Last Revised on July 7, 2022</div>
          <div className={Styles.termText}>By choosing “Accept” below, you agree with the <a className={Styles.termTextGreen} style={{ textDecoration: 'none' }} href="https://www.ferret.ai//terms-of-use" target="_blank">Terms and Conditions</a> and that you have read and understand the <a className={Styles.termTextGreen} style={{ textDecoration: 'none' }} href="https://www.ferret.ai//privacy-policy" target="_blank">Privacy Policy.</a>   <br></br><br></br> The Terms and Conditions contain important provisions for you to consider, including:</div>
          <div className={Styles.termTextLine}><img src={tickGreen} className={Styles.tickGreen} />Ferret is not a consumer reporting agency and does not produce the consumer reports under the Fair Credit Reporting Act (FCRA) and related laws.</div>
          <div className={Styles.termTextLine}><img src={tickGreen} className={Styles.tickGreen} />You may not use Ferret or the information available through Ferret to make decisions about consumer credit, employment, insurance, tenant screening, or any other purpose prohibited by the FCRA and related laws.</div>
          <div className={Styles.termTextLine}><img src={tickGreen} className={Styles.tickGreen} />Ferret is not responsible for the accuracy of information made available through Ferret.</div>
          <div className={Styles.termTextLine}><img src={tickGreen} className={Styles.tickGreen} />You agree to an arbitration agreement and class action waiver.</div>
        </div>
      </div>

      <div className={Styles.containBt}>
        <GreenBorderButton
          fullWidth
          variant="contained"
          onClick={() => navigate((-1), { replace: true })}
          style={{
            marginLeft: 20,
          }}>
          <div className={Styles.backBtnTxt}>Back</div>
        </GreenBorderButton>

        <GreenBgButton
          fullWidth
          disabled={!acceptBtnDisable}
          onClick={() => setAgree(true)}
          variant="contained"
          style={{
            marginLeft: 20,
            marginRight: 20,
          }}>
          <div className={Styles.acceptBtnTxt}>Accept</div>
        </GreenBgButton>
      </div>
      {isAgree && <TwoBtnAlert
        isCapitalize={true}
        title={"Accept Terms"}
        description={"By continuing, you agree to Ferret's Terms & Conditions"}
        leftBtnTxt={"Cancel"}
        rightBtnTxt={"Agree"}
        onLeftBtnClick={() => {
          setAgree(false)
        }}
        onRightBtnClick={() => {
          setAgree(false)
          navigate('/signUpLanding');
        }}
      />}
    </div>
  )
}

export default AcceptTermsOfService;
