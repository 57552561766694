
const getRiskCategoryColors = () => {
  return {
    high: '#DA011B',
    medium: '#FB8100',
    low: '#0F9125',
  }
}
const createStyle = (isRead) => {
  const searchMatch = '#8E96A8'
  const searchHeaderColor = '#3D4763'
  const termColor = '#142030'
  return {
    container: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: '#FFF',
    },
    marker: {
      backgroundColor: getRiskCategoryColors().high,
      width: 10,
      height: 10,
      borderRadius: 5,
      marginTop: 4,
      marginEnd: 8,
    },
    separator: {
      borderBottomColor: '#EFF1F5',
      borderBottomWidth: 1,
    },
    termsSection: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: 'flex-start',
      marginTop: 10,
    },
    termText: {
      backgroundColor: '#FFFFFF',
      color: termColor,
      paddingLeft: 7,
      paddingTop: 3,
      paddingRight: 7,
      paddingBottom: 3,
      borderRadius: 6,
      overflow: "hidden",
      marginRight: 10,
      marginBottom: 10,
      borderColor: "#DCE3F2",
      borderWidth: 1,
      borderStyle: "solid",
    },
    aboutMainView: {
      display: "flex",
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 6,
      display: "flex"
    },
    aboutMainView2: {
      display: "flex",
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: 10,
      display: "flex"
    },
    aboutYour: {
      color: termColor,
      fontWeight: "600",
      fontSize: 14,
      fontFamily: 'Proxima Nova',
      marginLeft: 8
    },
    btnView: {
      width: 38,
      height: 32,
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 6,
      backgroundColor: '#EFF1F5',
      marginRight: 10,
      display: "flex"
    },
    btnText: {
      color: termColor,
      fontWeight: "400",
      fontSize: 14,
      fontFamily: 'Proxima Nova',
    },
    articlePicture: {
      borderTopLeftRadius: 6,
      borderTopRightRadius: 6,
      height: 162,
      width: '100%',
      resizeMode: 'contain'
    },
    contentContainer: {
      display: "flex",
      flexDirection: 'row',
    },
    searchContainer: {
      display: "flex",
      flex: 1,
      flexDirection: 'column',
      padding: 16,
      paddingBottom: 12,
      borderBottomColor: '#E5E5E5',
      borderBottomWidth: 4
    },
    searchTitleContainer: {
      display: "flex",
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 8,
    },
    verificationType: {
      fontFamily: 'Proxima Nova',
      fontWeight: '500',
      fontSize: 13,
      textAlign: "right",
      letterSpacing: -0.01,
      textTransform: "uppercase",
      color: '#697080',
      paddingEnd: 12,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    profilePicture: {
      width: 22,
      height: 22,
      borderRadius: 11,
    },
    attributes: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: 'flex-start',
      marginTop: 10,
      display: "flex",
    },
    attributeItem: {
      color: '#697080',
      paddingLeft: 7,
      paddingTop: 3,
      paddingRight: 7,
      paddingBottom: 3,
      borderRadius: 6,
      overflow: "hidden",
      marginRight: 10,
      marginBottom: 6,
      borderColor: "#EFF1F5",
      borderWidth: 1,
      borderStyle: "solid",
      fontSize: 14,
      fontWeight: 400,
    },
    searchTitle: {
      color: searchHeaderColor,
      fontSize: 14,
      fontWeight: 'bold',
      marginLeft: 12,
      width: "40%"
    },
    matchType: {
      color: searchMatch,
      fontWeight: "600",
      fontSize: 14,
      fontFamily: 'Proxima Nova',
    },
    articleTitle: {
      color: termColor,
      marginBottom: 12,
      fontSize: 16,
      fontWeight: '700',
      flex: 1,
      color: isRead ? "#8E96A8" : "#142030",
      display: "inline-block"
    },
    articleTracersTitle: {
      color: '#697080',
      marginBottom: 8,
      fontSize: 13,
      fontWeight: 'bold',
      flex: 1,
    },
    articleSettings: {
      marginLeft: 20
    },
    articleCategory: {
      color: '#697080',
      fontSize: 14,
      fontWeight: '400',
      paddingTop: 1,
      letterSpacing: -1,
    },
    articleSource: {
      color: '#697080',
      fontSize: 12,
      fontWeight: 'bold',
      marginBottom: 6,
      marginTop: 6,
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      display: "flex"
    },
    articleCategoryType: {
      color: '#697080',
      fontWeight: "bold",
      fontSize: 13,
      marginTop: 8
    },
    articleSrcImg: {
      height: 24,
      width: 24,
      marginRight: 4
    },
    articleSourcePicture: {
      width: 22,
      height: 22,
      borderRadius: 11,
    },
    subCategoryView: {
      marginTop: 12,
      marginBottom: 12
    },
    subCategoryBtn: {
      backgroundColor: "#F8FAFF",
      borderRadius: 8,
      paddingTop: 12,
      paddingBottom: 12,
      paddingStart: 12,
      marginTop: 12,
      flex: 1,
      display: "flex",
      flexDirection: "row"
    },
    documentTitle: {
      color: "#202B3A",
      flex: 1,
      fontWeight: "600",
      fontSize: 12,
      letterSpacing: -0.01
    },
    subCategoryBtnIcon: {
      marginRight: 10
    }
  }
}

export { getRiskCategoryColors }
export default createStyle