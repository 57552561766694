import _ from 'lodash';
import {providers} from './contact-providers';

function extractContactsFromGoogleContactsReponse(originalContacts) {
  return Array.isArray(originalContacts?.connections) ? originalContacts.connections : []
}

function parseGoogleContactsForContactsComponent(contacts) {
  if (!Array.isArray(contacts) || contacts.length == 0) {
    return [];
  }
  const parsedContacts = [];
  contacts.forEach(contact => {
    try{
    const name = contact?.names
    if(name && Array.isArray(name) && name.length > 0 && name[0]?.givenName?.length > 0){
    const givenName = name[0].givenName;
    const familyName = name[0].familyName
    let photo = require('./../images/default-avatar.png');
    if(contact?.photos && contact?.photos.length >0 && contact?.photos[0]?.url)
      photo = contact?.photos[0]?.url;
    const newContact = {
      firstName: givenName,
      lastName:  familyName,
      photo: photo,
      selected: true,
      isPreferred: contact.isPreferred,
      terms: [],
      originalContact: contact,
    }
    const addressData = contact?.addresses
    if (addressData && Array.isArray(addressData) && addressData.length > 0) {
      const address = addressData[0]
      const region = address?.region ?? ''
      let newContactAddress = address?.city ?? ''

      newContactAddress += newContactAddress.length == 0 ? region : region.length > 0 ? `, ${region}` : ''
      if(newContactAddress.length > 0) {
        newContact.terms.push(newContactAddress);
      }
    }

    const organizations = contact?.organizations
    if (organizations && Array.isArray(organizations) && organizations.length > 0) {
      const companyName = organizations[0].name
      if (companyName?.length > 0) {
        newContact.terms.push(companyName)
      }
    }

    const contactId = contact?.resourceName
    if (contactId?.length > 0) {
      let realContactId = _.last(contactId.split('/'))
      newContact.idProvider = realContactId
    }
    const initialLetter = newContact.firstName.toUpperCase()
    // const lastSection = parsedContacts[parsedContacts.length - 1]
    // if (parsedContacts.length == 0 || lastSection.title != initialLetter) {
      parsedContacts.push({
        title: initialLetter,
        data: [newContact],
      })
    // } else {
    //   lastSection.data.push(newContact)
    // }
    }
    }catch(ee){}
  })
  return parsedContacts
}

function parseGoogleContactsForMutation(contactsWithinSection,mPhoneEmails, onlyUpdate=false) {
  let phoneEmails = [...mPhoneEmails]
  const contactsForMutation = [];
  contactsWithinSection.forEach(section => {
    section.data.forEach(contact => {
      if (contact.selected === false) {
        return;
      }

      let emailFound = false
      let phoneFound = false

      const originalContact = contact.originalContact;
      const newContact = {
        name: contact.firstName,
        lastName: contact.lastName,
        provider: providers.google,
        isPreferred: contact?.isPreferred ?? false,
        photo: contact.photo,
      };
      const organizations = originalContact.organizations
      if (Array.isArray(organizations) && organizations.length > 0) {
        const company = organizations[0]
        const companyName = company?.name
        if (companyName?.length > 0) {
          newContact.company = companyName
        }
        const jobTitle = company?.department
        if (jobTitle?.length > 0) {
          newContact.jobTitle = jobTitle
        }
      }
      const emails = originalContact?.emailAddresses
      if (emails && Array.isArray(emails) && emails.length > 0) {
        newContact.emailAddresses = emails.map((emailData) => {
          const newEmail = {}
          if (emailData?.value?.length > 0 && !phoneEmails.includes((emailData.value?.trim()+'--'+contact.firstName?.trim()+' '+(contact.lastName ?? '')?.trim())?.trim())) {
            emailFound = true
            phoneEmails.push((emailData.value?.trim()+'--'+contact.firstName?.trim()+' '+(contact.lastName ?? '')?.trim())?.trim())
          }
          newEmail.email = emailData.value
          return newEmail;
        }).filter((emailAddress) => emailAddress && Object.keys(emailAddress).length > 0)
      }
      const addresses = originalContact?.addresses
      if (addresses && Array.isArray(addresses) && addresses.length > 0) {
        newContact.postalAddresses = addresses.map((addressData) => {
          const newAddress = {}
          if (addressData?.city?.length > 0) {
            newAddress.city = addressData.city
          }
          if (addressData?.region?.length > 0) {
            newAddress.region = addressData.region
          }
          if (addressData?.country?.length > 0) {
            newAddress.country = addressData.country
          }
          return newAddress
        }).filter((address) => Object.keys(address).length > 0)
      }
      const phoneNumbers = originalContact?.phoneNumbers
      if (phoneNumbers && Array.isArray(phoneNumbers) && phoneNumbers.length > 0) {
        newContact.phoneNumbers = phoneNumbers.map((phoneNumberData) => {
          const newPhoneNumber = {}
          if (phoneNumberData?.value?.length > 0 && !phoneEmails.includes(((phoneNumberData.value?.trim()?.replace(/[^+\d]/g, ""))+'--'+contact.firstName?.trim()+' '+(contact.lastName ?? '')?.trim())?.trim())) {
            phoneFound = true
            phoneEmails.push((phoneNumberData.value?.trim()?.replace(/[^+\d]/g, "")+'--'+contact.firstName?.trim()+' '+(contact.lastName ?? '')?.trim())?.trim())
          }
          newPhoneNumber.number = phoneNumberData.value
          return newPhoneNumber
        }).filter((phoneNumber) => phoneNumber && Object.keys(phoneNumber).length > 0)
      }
      const contactId = originalContact?.resourceName
      if (contactId?.length > 0) {
        newContact.idProvider = contactId
      }
      if(emailFound || phoneFound)
        contactsForMutation.push(newContact);
    });
  });
  return contactsForMutation;
}

function isLastPage(contactsReponse) {
  if (contactsReponse?.nextPageToken) { return false }
  return true
}

export { parseGoogleContactsForContactsComponent, parseGoogleContactsForMutation, extractContactsFromGoogleContactsReponse, isLastPage }
