import React, { useState } from "react";
import createStyle from "./styles";
import TickGreen from "../../../images/svg/tick-green";
import ExclamationMarkGrey from "../../../images/svg/exclamation-mark-grey";
import InfoGreen from "../../../images/svg/info-green";
import VerificationTypes from "./VerificationTypes";
import { ButtonBase } from "@mui/material";
import "./style.css";
import QuestionMarkGreen from "../../../images/svg/question-mark-green";

const FilterByVerificationType = (props) => {
  const {
    verified,
    setVerified,
    partial,
    setPartial,
    needsReview,
    setNeedsReview,
    verifiedCount,
    needsReviewCount,
    partialCount,
    isAllShow,
  } = props;
  const style = createStyle();
  const [showVerificationTypes, setShowVerificationTypes] = useState(false);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: isAllShow ? 20 : 0,
          borderBottomColor: "rgb(229, 229, 229)",
          borderBottomWidth: "4px",
          marginBottom: isAllShow ? 10 : 0,
        }}
      >
        <span style={isAllShow ? style.filterLableRecent : style.filterLable}>
          Filter By Verification Type
        </span>
        <ButtonBase
          style={{ paddingLeft: 4 }}
          onClick={() => {
            setShowVerificationTypes(true);
          }}
        >
          <InfoGreen />
        </ButtonBase>
      </div>
      <div
        id="filterByVerificationTypeMain"
        style={{
          display: "flex",
          flexDirection: "row",
          marginLeft: isAllShow ? 0 : 16,
          overflowX: "scroll",
          overflowY: "hidden",
        }}
      >
        <ButtonBase
          style={{
            ...style.itemView,
            backgroundColor: !verified ? "#FFFFFF" : "#E6F2F0",
            borderColor: "#EFF1F5",
          }}
          onClick={() => {
            if (!verified || partial || needsReview) {
              setVerified(!verified);
            }
          }}
        >
          <TickGreen />
          <span
            style={{
              ...style.itemLabel,
              ...{ color: !verified ? "#697080" : "#078167" },
            }}
          >
            Verified{" "}
            {verifiedCount >= 0 ? (
              <span style={{ marginLeft: 2 }}>({verifiedCount})</span>
            ) : (
              ""
            )}
          </span>
        </ButtonBase>

        <ButtonBase
          style={{
            ...style.itemView,
            backgroundColor: !partial ? "#FFFFFF" : "#E6F2F0",
            borderColor: "#EFF1F5",
          }}
          onClick={() => {
            if (verified || !partial || needsReview) {
              setPartial(!partial);
            }
          }}
        >
          <QuestionMarkGreen selected={partial} />
          <span
            style={{
              ...style.itemLabel,
              ...{ color: !partial ? "#697080" : "#078167" },
            }}
          >
            Partial{" "}
            {partialCount >= 0 ? (
              <span style={{ marginLeft: 2 }}>({partialCount})</span>
            ) : (
              ""
            )}
          </span>
        </ButtonBase>

        <ButtonBase
          style={{
            ...style.itemView,
            backgroundColor: !needsReview ? "#FFFFFF" : "#E6F2F0",
            borderColor: "#EFF1F5",
          }}
          onClick={() => {
            if (verified || partial || !needsReview) {
              setNeedsReview(!needsReview);
            }
          }}
        >
          <ExclamationMarkGrey selected={needsReview} />
          <span
            style={{
              ...style.itemLabel,
              ...{ color: !needsReview ? "#697080" : "#078167" },
            }}
          >
            Needs review{" "}
            {needsReviewCount >= 0 ? (
              <span style={{ marginLeft: 2 }}>({needsReviewCount})</span>
            ) : (
              ""
            )}
          </span>
        </ButtonBase>

        {showVerificationTypes && (
          <VerificationTypes
            setShowVerificationTypes={setShowVerificationTypes}
          />
        )}
      </div>
    </>
  );
};

export default FilterByVerificationType;
