// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".selectOptionField_fieldLabel__d1u\\+6 {\n  font-family: 'Proxima Nova';\n  font-style: normal;\n  font-weight: 600;\n  font-size: 13px;\n  line-height: 16px;\n  color: #142030;\n  margin-bottom: 5px;\n}\n.selectOptionField_MuiSvgIcon-root__KZT63.selectOptionField_MuiSelect-icon__WKJcc {\n  color: #000000;\n}", "",{"version":3,"sources":["webpack://./src/components/fields/selectOptionField.module.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,kBAAkB;AACpB;AACA;EACE,cAAc;AAChB","sourcesContent":[".fieldLabel {\n  font-family: 'Proxima Nova';\n  font-style: normal;\n  font-weight: 600;\n  font-size: 13px;\n  line-height: 16px;\n  color: #142030;\n  margin-bottom: 5px;\n}\n.MuiSvgIcon-root.MuiSelect-icon {\n  color: #000000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fieldLabel": "selectOptionField_fieldLabel__d1u+6",
	"MuiSvgIcon-root": "selectOptionField_MuiSvgIcon-root__KZT63",
	"MuiSelect-icon": "selectOptionField_MuiSelect-icon__WKJcc"
};
export default ___CSS_LOADER_EXPORT___;
