const createStyle = () => {
  return {
    container: {
      opacity: 1,
      position: 'absolute',
      bottom: 0,
      alignSelf: 'center',
      backgroundColor: "#FFFFFF",
      borderTopRightRadius: 12,
      borderTopLeftRadius: 12,
      maxWidth: 750,
    },
    content: {
      display: "flex",
      flexDirection: "column",
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginTop: 30,
      marginBottom: 15,
      backgroundColor: "#FFFFFF",
    },
    mainIcon: {
      marginBottom: 20,
      alignSelf: 'center',
      resizeMode: 'contain'
    },
    textQuestion: {
      color: "#1A233B",
      fontFamily: "Proxima Nova",
      fontSize: 24,
      marginHorizontal: 10,
      fontWeight: 'bold',
      textAlign: 'center',
      marginTop: 16,
      display: "flex",
      justifyContent: "center"
    },
    textSimple: {
      marginHorizontal: 16,
      color: "#222F4B",
      fontFamily: "Proxima Nova",
      fontSize: 14,
      marginTop: 10,
      marginLeft: 12,
      marginRight: 12
    },
    textSimpleGreen: {
      marginHorizontal: 10,
      color: "#222F4B",
      fontFamily: "Proxima Nova",
      fontSize: 16,
      fontWeight: "bold",
      color: '#2A896F',
      marginBottom: 40,
    },
    btnTxt: {
      textTransform: "none",
      fontSize: 16,
      fontFamily: "Proxima Nova",
      fontWeight: "400",
    },
    button: {
      width: "95%",
      marginTop: 14,
    }
  }
};

export default createStyle;