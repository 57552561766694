import React from 'react'
import createStyle from './styles'

const EmptyCategoryMessage = () => {

    const style = createStyle()

    return (
        <div style={style.container}>
            <span style={style.textStyle}>
                No Information available.{"\n"}
                Try adjusting your filters or viewing a different category.
            </span>
        </div>
    )
}

export default EmptyCategoryMessage
