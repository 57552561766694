// React
import { useContext, useEffect, useState } from "react";

// Layout
import SideOverlay from "../../layout/SideOverlay";

// Components
import AccountAvatar from "./AccountAvatar";
import TryPremium from "../TryPremium";
import ProfileEditForm from "./ProfileEditForm";
import { logout } from "../../../utils/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../../contexts/userContext";
import SubscriptionPlansModal from "../../../components/subscriptionPlans/subscriptionPlansModal";
import { accountOverlayStore } from "../../../store/accountOverlayStore";
import Styles from "./accountOverlay.module.css";
import { closestContactsStore } from "../../../store/closestContactsStore";
import { searchFormStore } from "../../../store/searchFormStore";
import { searchResultStore } from "../../../store/searchResultStore";
import { savedSearchesStore } from "../../../store/savedSearchesStore";
import ChangePassworForm from "./ChangePassworForm";
import { notificationStore } from "../../../store/notificationStore";

/**
 * AccountOverlay Component
 * @returns {JSX.Element}
 * @constructor
 */
function AccountOverlay({ isDesktop }) {
  const LOCATION = useLocation();

  const [appVersion, setAppVersion] = useState("")

  const removeAll = savedSearchesStore((state) => state.removeAll);
  const closestRemoveAll = closestContactsStore((state) => state.removeAll);
  const searchFormRemoveAll = searchFormStore((state) => state.removeAll);
  const searchResultRemoveAll = searchResultStore((state) => state.removeAll);
  const removeAllAccount = accountOverlayStore((state) => state.removeAll);
  const removeAllNotification = notificationStore((state) => state.removeAll);

  const openSubscription = accountOverlayStore(
    (state) => state.openSubscription
  );
  const setOpenSubscription = accountOverlayStore(
    (state) => state.setOpenSubscription
  );

  const openEditProfile = accountOverlayStore((state) => state.openEditProfile);
  const setOpenEditProfile = accountOverlayStore(
    (state) => state.setOpenEditProfile
  );

  const openChangePassword = accountOverlayStore(
    (state) => state.openChangePassword
  );
  const setChangePassword = accountOverlayStore(
    (state) => state.setChangePassword
  );

  const setOpenAccount = accountOverlayStore((state) => state.setOpenAccount);

  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  /**
   * Hides the account overlay
   */
  const hideAccountOverlay = () => {
    closeAllOverlays();
    setOpenAccount(false);
  };

  /**
   * Closes all overlays before opening a specific one
   */
  const closeAllOverlays = () => {
    setOpenEditProfile(false);
    setOpenSubscription(false);
    setChangePassword(false);
  };

  /**
   * Toggles change password overlay state
   */
  const toggleChangePasswordOverlay = () => {
    const OPEN_CHANGE_PASSWORD_OVERLAY = () => {
      closeAllOverlays();
      setChangePassword(true);
    };
    openChangePassword ? closeAllOverlays() : OPEN_CHANGE_PASSWORD_OVERLAY();
  };

  useEffect(() => {
    if (LOCATION.pathname === "/editProfile") {
      toggleChangePasswordOverlay();
    }

    const majorVersion = process.env.REACT_APP_MAJOR_VERSION;
    const minorVersion = process.env.REACT_APP_MINOR_VERSION;
    const patchVersion = process.env.REACT_APP_PATCH_VERSION;
    const buildNumber = process.env.REACT_APP_BUILD_NUMBER;
    let version = `v${majorVersion}.${minorVersion}.${patchVersion} (build ${buildNumber})`
    setAppVersion(version)
  }, []);

  /**
   * Toggles profile edit overlay state
   */
  const toggleProfileEditOverlay = () => {
    const OPEN_PROFILE_EDIT_OVERLAY = () => {
      closeAllOverlays();
      setOpenEditProfile(true);
    };
    openEditProfile ? closeAllOverlays() : OPEN_PROFILE_EDIT_OVERLAY();
  };

  /**
   * Toggles subscription overlay state
   */
  const toggleSubscriptionOverlay = () => {
    const OPEN_SUBSCRIPTION_OVERLAY = () => {
      closeAllOverlays();
      setOpenSubscription(true);
    };
    openSubscription ? closeAllOverlays() : OPEN_SUBSCRIPTION_OVERLAY();
  };

  const renderChangePasswordOverlay = (logoutUser) => {
    return (
      <SideOverlay
        offset="410"
        bgColor="bg-white"
        showBackdrop={false}
        handleCloseClick={toggleChangePasswordOverlay}
      >
        <div className="p-[30px]">
          <ChangePassworForm
            logoutUser={logoutUser}
            handleCloseClick={hideAccountOverlay}
          />
        </div>
      </SideOverlay>
    );
  };

  /**
   * Renders the side overlay profile edit component
   * @returns {JSX.Element}
   */
  const renderProfileEditOverlay = () => {
    return (
      <SideOverlay
        offset="410"
        bgColor="bg-white"
        showBackdrop={false}
        handleCloseClick={toggleProfileEditOverlay}
      >
        <div className="p-[30px]">
          <ProfileEditForm changePassword={toggleChangePasswordOverlay} />
        </div>
      </SideOverlay>
    );
  };

  /**
   * Renders the side overlay subscription component
   * @returns {JSX.Element}
   */
  const renderSubscriptionOverlay = () => {
    return (
      <SideOverlay
        offset="410"
        bgColor="bg-white"
        showBackdrop={false}
        showCloseBtn={false}
      >
        <div className="p-[30px] h-full">
          <SubscriptionPlansModal
            subscriptionModalVisible={openSubscription}
            setSubscriptionModalVisible={setOpenSubscription}
            isDesktop={isDesktop}
            hideAccountOverlay={hideAccountOverlay}
          />
        </div>
      </SideOverlay>
    );
  };

  const logoutUser = () => {
    hideAccountOverlay();
    searchFormRemoveAll();
    searchResultRemoveAll();
    closestRemoveAll();
    removeAll();
    removeAllNotification();
    removeAllAccount();
    logout();
  };

  const manageContact = () => {
    hideAccountOverlay();
    const data = {
      screenName: "Account",
    };
    navigate("/importContactsLanding", { state: JSON.stringify(data) });
  };

  const openWeb = (url) => {
    window.open(url, "_blank");
  };

  /**
   * Main render method
   */
  return (
    <>
      <SideOverlay handleCloseClick={hideAccountOverlay}>
        <div className="flex flex-col h-full z-10">
          <div className="w-[390px]">
            <div className="p-[30px] flex flex-col h-screen">
              <div className="flex-none">
                <h2 className="text-center mb-5">Account</h2>
                <AccountAvatar />
                {!user?.isSubscribed && (
                  <div className="mt-4">
                    <TryPremium callSubs={toggleSubscriptionOverlay} />
                  </div>
                )}
              </div>
              <div className="overflow-y-auto">
                <ul className="action-list">
                  <li
                    className={`${openEditProfile ? "active" : ""}`}
                    onClick={toggleProfileEditOverlay}
                  >
                    Edit Profile
                  </li>
                  <li onClick={manageContact}>Manage Contact List</li>
                  <li
                    className={`${openSubscription ? "active" : ""}`}
                    onClick={toggleSubscriptionOverlay}
                  >
                    Manage Subscription
                  </li>
                  <li onClick={() => openWeb("https://www.ferret.ai/faqs")}>
                    FAQ's
                  </li>
                  <li
                    onClick={() =>
                      openWeb("https://www.ferret.ai/mobile-support")
                    }
                  >
                    Support
                  </li>
                  <li
                    onClick={() =>
                      openWeb("https://www.ferret.ai/terms-of-use")
                    }
                  >
                    Terms of Use
                  </li>
                  <li
                    onClick={() =>
                      openWeb("https://www.ferret.ai/privacy-policy")
                    }
                  >
                    Privacy Policy
                  </li>
                  <li onClick={logoutUser}>Logout</li>
                </ul>
              </div>
              <section className={Styles.footer}>
                <span className={Styles.version}>{appVersion}</span>
              </section>
            </div>
          </div>
        </div>
      </SideOverlay>

      {openEditProfile && renderProfileEditOverlay()}
      {openSubscription && renderSubscriptionOverlay()}
      {openChangePassword && renderChangePasswordOverlay(logoutUser)}
    </>
  );
}

// Component export
export default AccountOverlay;
