import React, { useEffect, useRef } from "react";
import EditPencil from "../../../images/svg/edit-pencil--green";
import EditPencilWhite from "../../../images/svg/edit-pencil--white";
import CloseIcon from "../../../images/svg/closeGreen";
import CloseWhite from "../../../images/svg/closeWhite";
import createStyle from "./styles";
import Variables from "../../../utils/Variables";
import { capitalizeCase } from "../../../utils/utils";
import Styles from "./searchParams.module.css";
const SearchParams = (props) => {
  const {
    searchParams,
    onClickSearchParam,
    isVisible = true,
    disabled,
  } = props;
  const style = createStyle();
  const scrollParamsViewRef = useRef();

  const onClickSearchParamHandler = (param) => {
    if (!disabled) {
      onClickSearchParam(param);
    }
  };
  const paramTags = searchParams
    ?.filter((item) => item?.onlyForHeader != true)
    ?.map((p) => {
      const { param, isEditable, updated } = p;
      let value = param;
      if (value && value !== "") {
        const itemKey = `param-tag-${value}`;
        if (updated == 5) value = `App. ${value} y/o`;
        return (
          <div
            onClick={() => onClickSearchParamHandler(p)}
            className={Styles.tags}
            key={itemKey}
          >
            <span style={style.paramLabel}>
              {capitalizeCase(
                value.replace(/;/g, Variables.metaDataUISeparator),
                true
              )}
            </span>
            {isEditable ? (
              <EditPencil style={style.pencil} />
            ) : (
              <CloseIcon style={style.pencil} />
            )}
          </div>
        );
      }
    });

  if (isVisible)
    return (
      <div className={Styles.tagMain}>
        <span style={style.searchParamsContainer}>Edit Your Search</span>
        <div className={Styles.backgroudView}>
          <div ref={scrollParamsViewRef} className={Styles.tagContainer}>
            {paramTags}
          </div>
          <div style={style.strengthContainer}>
            <span style={style.strengthTitle}>Search Strength: </span>
            <span style={style.strengthValue}>
              {searchParams.length > 3
                ? "Strong"
                : searchParams.length === 3
                ? "Medium"
                : "Weak"}
            </span>
          </div>
        </div>
      </div>
    );
  else return <div />;
};

export default SearchParams;
