import { useState, useContext, useEffect } from "react";
import ferretLogo from "../../images/svg/ferret-logo.svg";
import { Button, Stack, IconButton } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "react-apollo";
import { UserContext } from "../../contexts/userContext";

import { UPDATE_FERRET_USER } from "../../queries/queries";
import Variables from "../../utils/Variables";
import { GreenBgButton } from "../buttons/greenBgButton";
import Preferences1 from "./preferences1";
import Preferences2 from "./preferences2";
import Preferences3 from "./preferences3";
import NavHeaderLight from "../navHeader/navHeaderLight";
import DotSlider from "../dotSlider/dotSlider";
import Styles from './preferences.module.css'
import { ButtonBase } from '@mui/material';
import { isEqualOrHigherThanIphoneX } from "../../utils/isEqualOrHigherThanIphoneX";

const Preferences = () => {

  const location = useLocation()
  const props = location?.state ? JSON.parse(location?.state) : {}

  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(0);
  const [dotsLength, setDotsLength] = useState(2);

  const [surveyValue, setSurveyValue] = useState(1);
  const [professionalReasonsSurvey, setProfessionalReasonsSurvey] = useState();
  const [personalReasonsSurvey, setPersonalReasonsSurvey] = useState([]);
  const [userUpdaterMutation] = useMutation(UPDATE_FERRET_USER);

  useEffect(() => {
    if (surveyValue == 3) setDotsLength(3);
    else setDotsLength(2);
  }, [surveyValue]);

  useEffect(() => {
    let tempSteps = [...steps];
    if (surveyValue == 1) {
      tempSteps[1] = "pref2";
      tempSteps.length = 2;
    } else if (surveyValue == 2) {
      tempSteps[1] = "pref3";
      tempSteps.length = 2;
    } else if (surveyValue == 3) {
      tempSteps[1] = "pref2";
      tempSteps[2] = "pref3";
    }
    setSteps([...tempSteps]);
  }, [surveyValue]);

  const professionalReasons = [
    "Accounting",
    "Analyst",
    "Business Owner / Management",
    "Finance / Banking",
    "Investing",
    "Legal Services",
    "Real Estate",
    "Sales",
    "Other",
  ];

  const submitBtn = () => {
    if (dotsLength != currentStep + 1) setCurrentStep((prev) => prev + 1);
    else {
      let variables = {};
      if (surveyValue == 1) {
        variables.userPreference = "PROFESSIONALPREFERENCE";
        variables.professionalPreferences =
          professionalReasons[professionalReasonsSurvey - 1];
      } else if (surveyValue == 2) {
        variables.userPreference = "PERSONALPREFERENCE";
        variables.personalPreferences = personalReasonsSurvey;
      } else if (surveyValue == 3) {
        variables.userPreference = "BOTHPREFERENCE";
        variables.professionalPreferences =
          professionalReasons[professionalReasonsSurvey - 1];
        variables.personalPreferences = personalReasonsSurvey;
      }
      userUpdaterMutation({
        variables: variables,
      });
      navigate("/preferencesSubmit", {state: props? JSON.stringify(props) : {}});
    }
  };

  const onPressback = () => {
    setCurrentStep((prev) => prev - 1);
  };

  let btnDisabled =
    (currentStep == 1 &&
      surveyValue == 1 &&
      typeof professionalReasonsSurvey !== "number") ||
    (currentStep == 1 &&
      surveyValue == 2 &&
      personalReasonsSurvey.length < 1) ||
    (currentStep == 1 &&
      surveyValue == 3 &&
      typeof professionalReasonsSurvey !== "number") ||
    (currentStep == 2 && surveyValue == 3 && personalReasonsSurvey.length < 1);

  let pref1 = (
    <Preferences1 surveyValue={surveyValue} setSurveyValue={setSurveyValue} />
  );

  const onNext = () => {
    if(props?.showVersionA){
      navigate("/importContactsLanding", {state: props ? JSON.stringify(props) : {}})
    }else{
      navigate("/dashboard", {state: {showSplash: true}})
    }
  }

  const [steps, setSteps] = useState([<></>]);

  return (
    <Stack className={Styles.body}>

      <NavHeaderLight
        navigate={navigate}
        headerName="Preferences"
        showBack={currentStep !== 0}
        onClick={onPressback}
        customRightBtn={<div style={{ color: "transparent" }}>XXXX</div>}
      />

      <div className={Styles.preferencesMain}>

        <img src={ferretLogo} className={Styles.ferretLogo} alt="ferretLogo" />

        {currentStep == 0 && pref1}

        {steps[currentStep] == "pref2" && (
          <Preferences2
            professionalReasonsSurvey={professionalReasonsSurvey}
            setProfessionalReasonsSurvey={setProfessionalReasonsSurvey}
          />
        )}

        {steps[currentStep] == "pref3" && (
          <Preferences3
            personalReasonsSurvey={personalReasonsSurvey}
            setPersonalReasonsSurvey={setPersonalReasonsSurvey}
          />
        )}
      </div>
      <div className={Styles.preferencesBottom}>
        <DotSlider dots={dotsLength} page={currentStep + 1} />
        <ButtonBase
          style={{
            marginTop: 20, padding: 16, background: btnDisabled ? '#999999' : '#078167',
            borderRadius: 6
          }}
          onClick={btnDisabled ? null : submitBtn}
        >
          <div className={Styles.finishbtnPre}>{dotsLength == currentStep + 1 ? 'Finish' : 'Next'}</div>
        </ButtonBase>
        <Button
          fullWidth
          variant="text"
          style={{
            marginTop: 16,
          }}
          onClick={onNext}
        >
          <div className={Styles.buttonText} style={{ color: '#078167', textTransform: "capitalize" }}>Finish Later</div>
        </Button>
      </div>
    </Stack>
  );
};

export default Preferences;
