const createStyle = () => {
  const titleTextColor = '#000'
  const paragraphTextColor = '#222F4B'
  const backgroundColor = '#FFF'
  return {
    modal: {
      borderTopRightRadius: 12,
      borderTopLeftRadius: 12,
      marginBottom: 0,
      marginLeft: 15,
      marginRight: 15,
    },
    container: {
      position: 'absolute',
      width: '90%',
      marginLeft: '5%',
      marginRight: '5%',
      backgroundColor: backgroundColor,
      borderRadius: 12,
      marginBottom: 0,
      padding: 0,
      maxWidth: 450,
    },
    content: {
      display: "flex",
      flexDirection: "column",
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginTop: 10,
      marginBottom: 15,
      backgroundColor: backgroundColor,
    },
    mainIcon: {
      marginBottom: 20,
      alignSelf: 'center',
      height:120,
    },
    textQuestion: {
      color: '#142030',
      fontFamily: "Proxima Nova",
      fontSize: 18,
      marginLeft: 20,
      marginRight: 20,
      fontWeight: 700,
      textAlign: 'center',
      marginBottom: 20,
    },
    textSimple: {
      marginLeft: 10,
      marginRight: 10,
      color: paragraphTextColor,
      fontFamily: "Proxima Nova",
      fontSize: 16,
      textAlign: 'center',
      marginBottom: 20,
    },
    buttonContainer: {
      paddingLeft: 20,
      paddingRight: 20,
      width: '-webkit-fill-available'
    },
    button: {
      marginBottom: 12,
    },
    btTitle: {
      fontWeight: '600',
      fontSize: 16,
      textAlign: 'center',
      fontFamily: "Proxima Nova",
    },
    crossButton: {
      right: 10,
      width: "100%",
      padding: 10,
      display: "flex",
      justifyContent: "flex-end",
      backgroundColor: "#00000000"
    },
  };
};

export default createStyle;