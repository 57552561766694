
const createStyle = (theme) => {
    return {
        container: {
            flex: 1,
            display: "flex",
            margin: 30,
        },
        textStyle: {
            color: "#697080",
            fontFamily: 'Proxima Nova',
            fontWeight: '400',
            fontSize: 16,
            marginTop: 40,
            marginStart: 40,
            marginEnd: 40,
            textAlign: 'center'
        }
    }
}

export default createStyle