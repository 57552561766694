import React from "react";

function ExclamationMarkGrey(props) {
  return (
    <svg
      width="4"
      height="12"
      fill="none"
      viewBox="0 0 4 12"
    >
      <path
        fill={props?.selected ? '#078167' :"#8E96A8"}
        d="M2.232 12a1.333 1.333 0 100-2.667 1.333 1.333 0 000 2.667zM.898 0h2.667v8H.898V0z"
      ></path>
    </svg>
  );
}

export default ExclamationMarkGrey;