import { useState } from "react";
import OnboardingTooltip from "./onboardingTooltip";
import OnboardingPopover from "./onboardingPopover";
import NotificationWhiteIcon from "../../images/svg/notificationWhiteIcon";

const NotificationTooltip = (props) => {
  const [isVisible, setIsVisible] = useState(true);
  const { isWeb = false } = props;
  return (
    <>
      <OnboardingTooltip
        title={
          <OnboardingPopover
            title="Notifications"
            content="Your notifications will appear here as they happen. Click on each new story, or Show Past Notifications to revisit any."
            icon={<NotificationWhiteIcon />}
            onClick={() => {
              setIsVisible(false);
              props.onClick();
            }}
          />
        }
        componentStyle={{
          marginTop: "10px !important",
          maxWidth: isWeb ? "500px" : "90%",
          marginLeft: "5%",
          marginRight: "5%",
        }}
        arrowStyles={{ overflow: "inherit" }}
        isVisible={isVisible}
        placement="bottom"
        {...props}
      ></OnboardingTooltip>

      <div
        style={{
          width: "120%",
          height: "100%",
          position: "absolute",
          display: "flex",
          alignItems: "flex-end",
          inset: "0px auto 0px -100px",
          transform: "translate3d(0px, 0px, 0px)",
          zIndex: 8,
        }}
      />
    </>
  );
};

export default NotificationTooltip;
