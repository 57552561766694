import React, { useContext, useEffect, useRef, useState } from "react";
import AddressProfile from "../../../images/svg/address-profile";
import FacebookProfile from "../../../images/svg/facebook-profile";
import LinkedInProfile from "../../../images/svg/linkedin-profile";
import MobileProfile from "../../../images/svg/mobile-profile";
import TwiterProfile from "../../../images/svg/twiter-profile";
import ProfilePicture from "../profilePicture/profilePicture";
import createStyle from "./styles";
import Variables from "../../../utils/Variables";
import ThreeDotsVertical from "../../../images/svg/three-dots-vertical";
import {
  Alert,
  Backdrop,
  ButtonBase,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Snackbar,
  Slide,
  Dialog,
} from "@mui/material";
import ReportFlag from "../../../images/svg/report-flag";
import Styles from "./linkedinInfo.module.css";
import CloseIconGray from "../../../images/svg/close_gray";
import { SimpleField } from "../../../components/fields/simpleField";
import { SelectOptionField } from "../../../components/fields/selectOptionField";
import { SimpleMultilineField } from "../../../components/fields/simpleMultilineField";
import { GreenBgButton } from "../../../components/buttons/greenBgButton";
import { capitalizeCase, getBase64, resizeImage } from "../../../utils/utils";
import ProfileImagePlaceholder from "../../../images/svg/profile-image.svg";
import { useMutation } from "react-apollo";
import { SUBMIT_FEEDBACK_RESPONSE } from "../../../queries/queries";
import { FileUploader } from "react-drag-drop-files";
import starIconActive from "../../../images/svg/starIconActive.svg";
import starDeactivated from "../../../images/svg/starDeactivated.svg";
import { preferredStore } from "../../../store/preferredStore";
import { closestContactsStore } from "../../../store/closestContactsStore";
import { savedSearchesStore } from "../../../store/savedSearchesStore";
import { SET_PREFERENCE } from "../../../queries/queries";
import LoadingBar from "../loadingBar/locadingBar";
import { IconAge } from "../../../images/svg/icon-age";
import UnpaidMemberPopupModal from "../../../components/unpaidMemberModal/unpaidMemberModal";
import { accountOverlayStore } from "../../../store/accountOverlayStore";
import { UserContext } from "../../../contexts/userContext";

const LinkedInfo = ({
  linkedInURL,
  facebookURL,
  twiterURL,
  profileName,
  estAge,
  addressLable,
  title,
  emailLable,
  mobileLable,
  feedbackOptions,
  profilePic,
  setProfilePic,
}) => {

  const { user, setUser } = useContext(UserContext);

  const isPreferred = preferredStore((state) => state.isPreferred);
  const setPreferred = preferredStore((state) => state.setPreferred);
  const selectedItem = preferredStore((state) => state.selectedItem);
  const setOpenSubscription = accountOverlayStore(
    (state) => state.setOpenSubscription
  );
  const [unpaidMemberModal, setUnpaidMemberModal] = useState(false);

  const setClosetSearches = closestContactsStore(
    (state) => state.setContactList
  );
  const contactList = closestContactsStore((state) => state.contactList);

  const setSavedSearches = savedSearchesStore(
    (state) => state.setSavedSearches
  );
  const savedSearches = savedSearchesStore((state) => state.savedSearches);

  const [setPreferenceContacts, { loading: preferenceLoading }] =
    useMutation(SET_PREFERENCE);

  const displayFieldTypes = {
    TEXT: 1,
    URL: 2,
    IMAGE: 3,
  };

  const snackBarType = {
    SUCCESS: "success",
    ERROR: "error",
  };

  const style = createStyle();

  const [anchorEl, setAnchorEl] = useState(false);
  const [reasonId, setReasonId] = useState("");
  const [provideURL, setProvideURL] = useState("");
  const [suggestChange, setSuggestChange] = useState("");
  const [showReportModal, setShowReportModal] = useState(false);
  const [base64Image, setBase64Image] = useState();
  const [fieldsData, setFieldsData] = useState([]);
  const [optionSelected, setOptionSelected] = useState();
  const [displayFields, setDisplayFields] = useState([]);
  const [showSnackBar, setShowSnackBar] = useState({
    status: false,
    msg: "",
    type: snackBarType.SUCCESS,
  });
  const [linkValid, setLinkValid] = useState({ status: null, msg: "" });
  const isImageSelectShow = useRef(false);
  const isImageFieldEnabled = useRef(true);

  const open = Boolean(anchorEl);

  const [submitFeedbackResponse, { loading }] = useMutation(
    SUBMIT_FEEDBACK_RESPONSE,
    {
      onCompleted(data) {
        onCloseModal();
        if (data?.submitFeedbackResponse?.imageUrl) {
          setProfilePic(data?.submitFeedbackResponse?.imageUrl);
        }
        setShowSnackBar({
          status: true,
          msg: "Data submitted successfully",
          type: snackBarType.SUCCESS,
        });
      },
      onError(ee) {
        setShowSnackBar({
          status: true,
          msg: ee,
          type: snackBarType.ERROR,
        });
      },
    }
  );

  const onStartPress = () => {
    if (selectedItem) {
      let requestData = [
        {
          id: selectedItem.id,
          isPreferred: !isPreferred,
        },
      ];
      setPreferenceContacts({
        variables: {
          contacts: requestData,
        },
      });
      let mSavedSearches = [...savedSearches];
      const selectedContact = mSavedSearches.findIndex(
        (item) => item?.id === selectedItem.id
      );
      if (selectedContact !== -1) {
        mSavedSearches[selectedContact].isPreferred = !isPreferred;
      }
      if (!isPreferred) {
        Variables.isRefreshClosestContacts = true;
      } else {
        setClosetSearches(
          contactList.filter(
            (savedSearch) => savedSearch.id != selectedItem?.id
          )
        );
      }
      setPreferred(!isPreferred);
      setSavedSearches(mSavedSearches);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onIconClick = (type) => {
    window.open(
      type == "linkedIn"
        ? linkedInURL
        : type == "facebook"
        ? facebookURL
        : linkedInURL,
      "_blank"
    );
  };

  const onImageChange = (file) => {
    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        resizeImage(result).then((response) => {
          setBase64Image(response);
          setProvideURL("");
          isImageFieldEnabled.current = false;
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onReportIncorrectDataClick = () => {
    try {
      let items = [];
      feedbackOptions?.map((item) => {
        items.push({
          id: item.id,
          reason: item.reason,
          displayFields: item.displayFields,
        });
      });
      setFieldsData(items);
      setReasonId(items?.[0]?.id);
      setShowReportModal(true);
      handleClose();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (reasonId) {
      resetFields();
      let selectedOptionData = feedbackOptions?.find(
        (item) => item.id === reasonId
      );
      setOptionSelected(selectedOptionData);
      setDisplayFields(selectedOptionData?.displayFields);
      let isImageFieldFound = selectedOptionData?.displayFields?.find(
        (item) => item.type == displayFieldTypes.IMAGE
      );
      isImageSelectShow.current = isImageFieldFound ? true : false;
    }
  }, [reasonId]);

  useEffect(() => {
    if (provideURL?.length > 0 && !isImageSelectShow.current) {
      let checkLink =
        /(https:\/\/(www\.)linkedin\.com(\/[A-Za-z0-9\-\._~:\/\?#\[\]@!$&'\(\)\*\+,;\=]*)?)/g.test(
          provideURL
        );

      if (!checkLink) {
        setLinkValid({ status: false, msg: "Link is not valid" });
      } else {
        setLinkValid({ status: true, msg: "" });
      }
    } else {
      setLinkValid({ status: true, msg: "" });
    }
  }, [provideURL]);

  const onCloseModal = () => {
    resetFields();
    setShowReportModal(false);
  };

  const resetFields = () => {
    setBase64Image(null);
    setProvideURL("");
    setSuggestChange("");
    setLinkValid({ status: true, msg: "" });
    isImageFieldEnabled.current = true;
  };

  const onSubmitReport = () => {
    if (optionSelected) {
      let feedbackJsonData = {
        potentialMatchId: Variables.potentialMatchId,
        reason: optionSelected.reason,
        reasonId: optionSelected.id,
        feedback: optionSelected?.displayFields
          ?.map((item) => {
            if (
              (item.type === displayFieldTypes.IMAGE && base64Image == null) ||
              (item.type === displayFieldTypes.TEXT &&
                suggestChange?.length == 0) ||
              (item.type === displayFieldTypes.URL && provideURL?.length == 0)
            ) {
              return;
            } else {
              return {
                ...item,
                input:
                  item.type === displayFieldTypes.TEXT
                    ? suggestChange
                    : item.type === displayFieldTypes.URL
                    ? provideURL
                    : base64Image,
              };
            }
          })
          .filter((item) => item),
      };

      if (feedbackJsonData?.feedback?.length > 0 && linkValid?.status) {
        submitFeedbackResponse({
          variables: {
            data: feedbackJsonData,
          },
        });
      } else {
        setShowSnackBar({
          status: true,
          msg:
            linkValid.status == false
              ? "Link is not valid"
              : "Fields can't be empty",
          type: snackBarType.ERROR,
        });
      }
    }
  };

  const showRelatedFields = () => {
    return (
      <>
        {displayFields &&
          displayFields?.map((item) => (
            <>
              {item.type == displayFieldTypes.URL && (
                <>
                  <SimpleField
                    title={item.title}
                    placeholder={""}
                    value={provideURL}
                    setValue={setProvideURL}
                    fieldValid={linkValid}
                    type={item.type}
                  />
                  {!isImageSelectShow.current && (
                    <span className={Styles.modalHintLabel}>
                      https://www.linkedin.com/profile-name
                    </span>
                  )}

                  <div className={Styles.modalFieldSpaces} />
                </>
              )}

              {item.type == displayFieldTypes.TEXT && (
                <>
                  <SimpleMultilineField
                    title={item.title}
                    placeholder={""}
                    value={suggestChange}
                    setValue={setSuggestChange}
                    fieldValid={""}
                  />

                  <div className={Styles.modalFieldSpaces} />
                </>
              )}
            </>
          ))}
      </>
    );
  };

  return (
    <>
      <div
        style={{
          ...style.borderContainerNew,
        }}
      >
        <div className={Styles.body}>
          {preferenceLoading && (
            <div className={Styles.loadingStar}>
              <LoadingBar />
            </div>
          )}
          <div className={Styles.menuIcon}>
            {isPreferred != null && (
              <img
                style={{ padding: 10 }}
                src={isPreferred ? starIconActive : starDeactivated}
                onClick={(e) => {
                  e.stopPropagation();
                  onStartPress();
                }}
              />
            )}
            <IconButton
              onClick={(event) => {
                setAnchorEl(event.currentTarget);
              }}
            >
              <ThreeDotsVertical />
            </IconButton>

            <Menu
              className={Styles.menu}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={onReportIncorrectDataClick} dense>
                <ReportFlag />
                <span style={{ marginLeft: 16 }}>Report Incorrect Data</span>
              </MenuItem>
              {/* <Divider className={Styles.menuDivider} />
              <MenuItem onClick={handleClose} dense>
                <Camera />
                <span style={{ marginLeft: 16 }}>Other Profile Option</span>
              </MenuItem>
              <Divider className={Styles.menuDivider} />
              <MenuItem onClick={handleClose} dense>
                <Camera />
                <span style={{ marginLeft: 16 }}>Other Profile Option</span>
              </MenuItem> */}
            </Menu>
          </div>
          <div
            className={`${Styles.linkedInDataContainer} ${
              feedbackOptions && feedbackOptions?.length > 0
                ? Styles.minus16MarginTop
                : Styles.plus16MarginTop
            }`}
          >
            <section style={style.profileSection}>
              <ProfilePicture
                avatarStyle={{
                  width: "150px",
                  height: "150px",
                  backgroundColor: "#C0C7D6",
                  borderRadius: 75,
                }}
                profilePhoto={profilePic}
                isLinkedIn={true}
              />
              {Variables.info_summary_enabled && (
                <div
                  style={{
                    marginTop: 12,
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {facebookURL && (
                    <div onClick={() => onIconClick("facebook")}>
                      <FacebookProfile
                        style={{ marginRight: 8, marginLeft: 8 }}
                      />
                    </div>
                  )}
                  {twiterURL && (
                    <div onClick={() => onIconClick("twiter")}>
                      <TwiterProfile
                        style={{ marginRight: 8, marginLeft: 8 }}
                      />
                    </div>
                  )}
                  {linkedInURL && (
                    <div onClick={() => onIconClick("linkedIn")}>
                      <LinkedInProfile
                        style={{ marginRight: 8, marginLeft: 8 }}
                      />
                    </div>
                  )}
                </div>
              )}

              <span style={style.profileName}>{profileName}</span>
              <span style={style.profileTitle}>{title}</span>
            </section>
            {(addressLable || emailLable || mobileLable) && (
              <section style={style.addressSection}>
                <div style={style.infoContProfile}>
                  {addressLable && (
                    <div style={style.addressProfile}>
                      <AddressProfile />
                      <span style={style.addressText}>{addressLable}</span>
                    </div>
                  )}
                  {mobileLable && (
                    <div style={style.addressProfile}>
                      <MobileProfile />
                      <span style={style.infoText}>{mobileLable}</span>
                    </div>
                  )}
                  {estAge && (
                    <div style={style.addressProfile}>
                      <IconAge />
                      <span style={style.infoText}>{estAge}</span>
                    </div>
                  )}
                </div>
              </section>
            )}
          </div>
        </div>
      </div>

      {showReportModal && (
        <Dialog
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
            },
          }}
          open={true}
        >
          <div className={Styles.modalContainer}>
            <header className={Styles.btnClose}>
              <ButtonBase onClick={onCloseModal}>
                <CloseIconGray className={Styles.iconClose} />
              </ButtonBase>
            </header>

            <main className={Styles.modalContent}>
              <div className={Styles.modalMainBody}>
                <h2 className={Styles.modalH2}>
                  {capitalizeCase(profileName, true)}
                </h2>

                {isImageSelectShow.current === true ? (
                  <FileUploader
                    className={Styles.profilePhotoDiv}
                    handleChange={(file) => onImageChange(file)}
                    onDrop={(file) => onImageChange(file)}
                    name="file"
                    types={["JPG", "PNG", "JPEG"]}
                    children={
                      <img
                        className={Styles.modalSelectedPic}
                        src={base64Image ?? ProfileImagePlaceholder}
                      />
                    }
                  />
                ) : (
                  <ProfilePicture
                    className={Styles.modalProfilePic}
                    avatarStyle={{
                      width: "150px",
                      height: "150px",
                      backgroundColor: "#C0C7D6",
                      borderRadius: 75,
                    }}
                    profilePhoto={profilePic}
                    isLinkedIn={true}
                  />
                )}

                <div className={Styles.modalInputDiv}>
                  <SelectOptionField
                    title={"Reason for Change"}
                    value={reasonId}
                    setValue={setReasonId}
                    options={fieldsData}
                  />

                  <div className={Styles.modalFieldSpaces} />
                  {isImageSelectShow.current === true &&
                  isImageFieldEnabled.current === true ? (
                    showRelatedFields()
                  ) : (
                    <></>
                  )}
                  {isImageSelectShow.current === false && showRelatedFields()}
                </div>
              </div>
            </main>

            <div className={Styles.btnDiv}>
              <GreenBgButton
                loading={false}
                onClick={onSubmitReport}
                fullWidth
                variant="contained"
              >
                <span className={Styles.modalBtnTitle}>Submit</span>
              </GreenBgButton>
            </div>

            {loading && (
              <Backdrop
                sx={{
                  backgroundColor: "#00000000",
                  zIndex: (theme) => theme.zIndex.drawer + 2,
                }}
                open={true}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            )}
          </div>
        </Dialog>
      )}

      {showSnackBar.status && (
        <Snackbar
          open={true}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          TransitionComponent={Slide}
          autoHideDuration={3000}
          onClose={() => setShowSnackBar({ status: false, msg: "" })}
        >
          <Alert severity={showSnackBar.type} sx={{ width: "100%" }}>
            {showSnackBar.msg}
          </Alert>
        </Snackbar>
      )}

      {unpaidMemberModal && (
        <UnpaidMemberPopupModal
          onBackdropPress={() => {
            setUnpaidMemberModal(false);
          }}
          upgradeNow={() => {
            setOpenSubscription(true);
          }}
        />
      )}
    </>
  );
};
export default LinkedInfo;
