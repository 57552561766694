import Iso3166 from "iso-3166-2";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLazyQuery, useMutation } from "react-apollo";
import { useLocation, useNavigate } from "react-router-dom";
import { ModelType } from "../../../components//searchLimitExceedModal/searchLimitExceedModal";
import { checkCompanyInMetaData } from "../../../components/addOrgModal/checkCompanyInMetaData";
import { WhiteBgButtonGreenBorder } from "../../../components/buttons/whiteBgButtonGreenBorder";
import ColoredLinearProgress from "../../../components/loadingBar/ColoredLinearProgress";
import SavedSearchMatch from "../../../components/savedSearchMatch";
import SearchLimitExceedModal from "../../../components/searchLimitExceedModal";
import {
  Question,
  optionsToAnswers,
} from "../../../components/searchModalV2/Question";
import { shouldSearchQuestion } from "../../../components/searchModalV2/questionsAnswers";
import {
  getMetadataFromQuestions,
  getSavedSearchParams,
} from "../../../components/searchModalV2/questionsController";
import useIntialModalData from "../../../components/searchModalV2/useIntialModalData";
import useQuestions from "../../../components/searchModalV2/useQuestions";
import useRefineSearch from "../../../components/searchModalV2/useRefineSearch";
import SubscriptionPlansModal, {
  FREE_PLAN_ID,
} from "../../../components/subscriptionPlans/subscriptionPlansModal";
import { UserContext } from "../../../contexts/userContext";
import { GET_CONFIGURATION, SAVE_ADHOC_SEARCH, Save_Recent_Search } from "../../../queries/queries";
import { accountOverlayStore } from "../../../store/accountOverlayStore";
import { notificationStore } from "../../../store/notificationStore";
import { searchFormStore } from "../../../store/searchFormStore";
import { searchResultStore } from "../../../store/searchResultStore";
import Variables from "../../../utils/Variables";
import addQueryData from "../../../utils/addQueryData";
import formatUserName from "../../../utils/formatUserName";
import { generatePdf } from "../../../utils/pdfGeneratorUtils";
import { capitalizeCase, titleCase } from "../../../utils/utils";
import SaveSearchResultModal from "../../components/searchResult/saveSearchResultModal";
import SearchResultsWithQuery from "../../components/searchResultsWithQuery/searchResultsWithQuery";
import Styles from "./Search.module.css";
import SingleBtnAlert from "../../../components/alert/singleBtnAlert";

let timer = null;

const SearchResults = ({ isDesktop }) => {
  const location = useLocation();
  let props = location.state ?? {};
  const navigate = useNavigate();

  const profileSummaryRef = useRef(null)

  const setOpenSubscription = accountOverlayStore(
    (state) => state.setOpenSubscription
  );

  const removeAll = searchResultStore((state) => state.removeAll);
  const saveSearchVariables = searchResultStore(
    (state) => state.saveSearchVariables
  );
  const setOpenMetaSearch = searchResultStore((state) => state.setOpenMetaSearch);
  const hasData = searchResultStore((state) => state.hasData);
  const openMapDashboard = searchResultStore((state) => state.openMapDashboard);
  const setOpenMapDashboard = searchResultStore((state) => state.setOpenMapDashboard);
  const [showSaveConfirmationAlert, setShowSaveConfirmationAlert] = useState({
    status: false,
    msg: "",
  });
  const [chkBxNotifyUser, setChkBxNotifyUser] = useState(false);
  const [saveSearch, setSaveSearch] = useState(false);
  const [monitorSearch, setMonitorSearch] = useState(false);
  const [questionsEdit, setQuestionsEdit] = useState([]);
  const [showSingleBtnAlert, setShowSingleBtnAlert] = useState({
    status: false,
    msg: "",
  });
  const {
    inNewSearch = false,
    fromBackSearch = false,
    isEdit = false,
    contactID,
    mPhoneNumberInput,
    mCityInput,
    mStateInput,
    mAgeInput = "",
    mQueryData,
    mSavedTracerQueryId,
    mSavedTahoeId,
    navigatedFrom = "",
    oldMetaValue = {},
    recentSearchId,
    searchSettings: {
      initialSearchString = "",
      intitialMetadata = {},
      qaMetaData = {},
      initialSearchParams = [],
      initialQuestions = [],
      initialPotentialMatch = [],
    } = {},
  } = props;
  const mCountryInput =
    props?.isEdit || fromBackSearch
      ? props?.mCountryInput && props?.mCountryInput != ""
        ? props.mCountryInput
        : ""
      : "United States";
  const {
    refineSearch,
    data,
    loading,
    error,
    resultTypes,
    getResultTypeFromRefineSearch,
    getQuestionFromRefineSearch,
    getArticleDetailsFromRefineSearch,
    getArticleFilterData,
    getPersonalInfo,
    getArticleTypeCount,
    mapSingleArticle,
    getReadArticlesIds,
    getAKAList,
    getCategoryFilterData,
    getPotentialMatchId,
    getContactId,
    getSearchId,
    getLinkedInData,
    getFeedbackOptions,
    getProfilePic,
  } = useRefineSearch();

  const {
    setQuestions,
    questions,
    addNewQuestion,
    cleanQuestions,
    addPotentialMatches,
    addMultipleNewQuestion,
    getSearchParams,
    removeIdontKnowLabel,
  } = useQuestions(initialQuestions, initialPotentialMatch);

  const [
    saveAdhocSearch,
    { data: saveData, loading: saveLoading, error: saveError },
  ] = useMutation(SAVE_ADHOC_SEARCH);

  useEffect(() => {
    if (!saveLoading && (saveData || saveError)) {
      backToSearchComponent();
    }
  }, [saveData, saveLoading, saveError]);

  const setOpenNotification = notificationStore(
    (state) => state.setOpenNotification
  );
  const openNotification = notificationStore((state) => state.openNotification);

  const [searchString, setSearchString] = useState(
    initialSearchString ? formatUserName(initialSearchString.trim()) : ""
  );
  const [recentSearchTerm, setRecentSearchTerm] = useState();
  const [phoneNumberInput, setPhoneNumber] = useState(mPhoneNumberInput);
  const [countryInput, setCountry] = useState(mCountryInput);
  const [cityInput, setCity] = useState(mCityInput);
  const [stateInput, setState] = useState(mStateInput);
  const [ageInput, setAge] = useState(mAgeInput);
  const [isClearActive, setCleanActive] = useState();
  const [searchName, setsearchName] = useState();
  const [agePotentialMatch, setAgePotentialMatch] = useState();
  const [searchParams, setSearchParams] = useState(initialSearchParams);
  const [irrelevantArticleId, setIrrelevantArticleId] =
    useState(recentSearchId);
  const [irrelevantArticles, setIrrelevantArticles] = useState();
  const [searchCompleted, setSearchCompleted] = useState(false);
  const [clickedPotential, setClickedPotential] = useState(false);
  const [clickedSavedSearch, setClickedSavedSearch] = useState(false);
  const [clickedEditSearch, setClickedEditSearch] = useState(false);
  const [savedContactID, setSavedContactId] = useState(contactID);
  const [savedTahoeId, setSavedTahoeId] = useState(mSavedTahoeId);
  const [savedTracerQueryId, setSavedTracerQueryId] =
    useState(mSavedTracerQueryId);
  const [queryData, setQueryData] = useState(mQueryData);
  const [oldMetaData, setOldMetaData] = useState(intitialMetadata);
  const { user, setUser } = useContext(UserContext);
  const [metaData, setMetaData] = useState(qaMetaData);
  const [metaDataArray, setMetaDataArray] = useState();
  const [sectionIndex, setSectionIndex] = useState(0);
  const [isSearchLimitExceed, setSearchLimitExceed] = useState(false);
  const [searchLimitExceedTitle, setSearchLimitExceedTitle] = useState(null);
  const [openPotentialMatch, setOpenPotentialMatch] = useState(false);
  const [mFromBackSearch, setFromBackSearch] = useState(fromBackSearch);
  const [progress, setProgress] = useState(0);
  const [subscriptionModalVisible, setSubscriptionModalVisible] =
    useState(false);
  const [eventTypeSaved, setEventTypeSaved] = useState("complete-search");
  const [lastQuery, setLastQuery] = useState();
  const [showAddOrgsModal, setShowAddOrgsModal] = useState();
  const [isEditFromOverlay, setEditFromOverlay] = useState();
  const [isFromSavedSearch, setFromSavedSearch] = useState();


  const setProgressStatus = searchFormStore((state) => state.setProgressStatus);
  const searchFormData = searchFormStore((state) => state.searchFormData);
  const setSearchFormData = searchFormStore((state) => state.setSearchFormData);
  const searchResultData = searchFormStore((state) => state.searchResultData);
  const setSearchData = searchFormStore((state) => state.setSearchData);
  const selectedPotentialIndex = searchFormStore(
    (state) => state.selectedPotentialIndex
  );
  const setSelectedPotentialIndex = searchFormStore(
    (state) => state.setSelectedPotentialIndex
  );
  const setSavedSearchData = searchFormStore(
    (state) => state.setSavedSearchData
  );
  const savedSearchData = searchFormStore((state) => state.savedSearchData);
  const savedPotentialMatch = searchFormStore(
    (state) => state.savedPotentialMatch
  );
  const setPotentialMatch = searchFormStore((state) => state.setPotentialMatch);
  const setOpenEditSearch = searchFormStore((state) => state.setOpenEditSearch);
  const setEditSearchData = searchFormStore((state) => state.setEditSearchData);
  const editSearchComplete = searchFormStore(
    (state) => state.editSearchComplete
  );
  const setEditSearchComplete = searchFormStore(
    (state) => state.setEditSearchComplete
  );
  const isPdfGenerating = searchResultStore(
    (state) => state.isPdfGenerating
  );
  const setIsPdfGenerating = searchResultStore(
    (state) => state.setIsPdfGenerating
  );


  const configurationData = searchResultStore(
    (state) => state.configurationData
  );
  const setConfigurationData = searchResultStore(
    (state) => state.setConfigurationData
  );

  const ConfigurationLoading = searchResultStore(
    (state) => state.ConfigurationLoading
  );
  const setConfigurationLoading = searchResultStore(
    (state) => state.setConfigurationLoading
  );


  const normalise = (value) => ((value - 0) * 100) / (100 - 0);

  useEffect(() => {
    if (
      searchFormData &&
      searchFormData?.name?.length > 0 &&
      searchFormData?.search
    ) {
      setFromSavedSearch(false);
      setSearchData();
      removeAll();
      setPotentialMatch([]);
      addPotentialMatches([]);
      setSelectedPotentialIndex(-1);
      setSearchCompleted(false);
      setClickedPotential(false);
      setClickedSavedSearch(false);
      setOpenEditSearch(false);
      setQuestionsEdit([]);
      setEditFromOverlay(false);
      setEditSearchData({});
      if (searchFormData) {
        setAge(searchFormData?.age?.toString() ?? "");
        setCity(capitalizeCase(searchFormData?.city ?? ""));
        setCountry(searchFormData?.country ?? "");
        setPhoneNumber(searchFormData?.phone ?? "");
        setState(searchFormData?.state ?? "");
        setSearchString(searchFormData?.name ?? "");
      }
      onSubmitEditingSearchbarHandler(
        true,
        searchFormData.name,
        searchFormData?.phone ?? "",
        searchFormData?.country ?? "",
        searchFormData?.city ?? "",
        searchFormData?.state ?? "",
        searchFormData?.age?.toString() ?? ""
      );
      setSearchFormData({ ...searchFormData, search: false });
    }
  }, [searchFormData]);

  useEffect(() => {
    if (editSearchComplete) {
      setFromSavedSearch(false);
      setIrrelevantArticleId(editSearchComplete?.recentSearchId);
      setQuestions(removeIdontKnowLabel(editSearchComplete?.questions));
      setQuestionsEdit(removeIdontKnowLabel(editSearchComplete?.questions));
      setSearchString(
        editSearchComplete?.searchString
          ? formatUserName(editSearchComplete?.searchString?.trim())
          : searchFormData.name ? searchFormData.name : ""
      );
      setAge(searchFormData?.age?.toString() ?? "");
      setCity(capitalizeCase(searchFormData?.city ?? ""));
      setCountry(searchFormData?.country ?? "");
      setPhoneNumber(searchFormData?.phone ?? "");
      setState(searchFormData?.state ?? "");
      setMetaDataArray();
      setSelectedPotentialIndex(-1);
      setPotentialMatch([]);
      addPotentialMatches([]);
      setSearchData();
      removeAll();
      setEditSearchData({});
      setFromBackSearch(false);
      setEditFromOverlay(true);
      if (loading || loadingData) return;
      setSearchCompleted(true);
      setClickedPotential(false);
      setClickedSavedSearch(false);
      setQueryData();
      startProgressBar();
      setLastQuery(editSearchComplete?.queryParms);
      refineSearch(editSearchComplete?.queryParms);
      setEditSearchComplete();
      setOpenEditSearch(false);
    }
  }, [editSearchComplete]);

  useEffect(() => {
    if (savedSearchData) {
      setFromSavedSearch(false);
      setSelectedPotentialIndex(-1);
      setPotentialMatch([]);
      addPotentialMatches([]);
      setSearchData();
      removeAll();
      setQuestionsEdit([]);
      setEditFromOverlay(false);
      setOpenEditSearch(false);
      setEditSearchData({});
      setSearchCompleted(false);
      setClickedPotential(false);
      setClickedSavedSearch(false);
      setOpenMetaSearch(savedSearchData.openMetaSearch)
      searchData(
        savedSearchData.searchTerm,
        savedSearchData.resp,
        savedSearchData.id,
        savedSearchData.irrelevantArticles,
        savedSearchData.contact,
        (savedSearchData.searchType = "PERSON"),
        savedSearchData.tracerQueryId,
        savedSearchData.tahoeId,
        savedSearchData.searchContext,
        savedSearchData.searchCompleted,
        savedSearchData.age,
        savedSearchData.akaList,
        savedSearchData.eventType
      );
      setSavedSearchData();

      if (savedSearchData.searchContext) {
        const data = {
          phone: savedSearchData.searchContext?.phone ?? "",
          city: capitalizeCase(savedSearchData.searchContext?.city ?? ""),
          country: savedSearchData.searchContext?.country ?? "",
          state: savedSearchData.searchContext?.state ?? "",
          age: savedSearchData.searchContext?.age?.toString() ?? "",
          name: formatUserName(savedSearchData.searchTerm.trim()),
          search: false,
        };
        setSearchFormData(data);
      }
    }
  }, [savedSearchData]);

  const { loadingData, searchSettings, getModalDataForMetaTag } =
    useIntialModalData({}, "", "", savedPotentialMatch, mFromBackSearch);

  useEffect(() => {
    if (
      (clickedSavedSearch || clickedPotential || clickedEditSearch) &&
      !loadingData &&
      searchSettings &&
      searchSettings?.initialSearchString?.length
    ) {
      goToResults();
      stopProgressBar();

      let searchData = { ...searchSettings };
      let data = {
        inNewSearch: true,
        fromEditSearch: true,
        isRecentSearch: false,
        fromBackSearch: false,
        searchSettings: searchData,
        cleanStateOnBlur: false,
        isAutoFocus: false,
        isEdit: true,
        mPhoneNumberInput: phoneNumberInput,
        mCountryInput: countryInput,
        mCityInput: cityInput,
        mStateInput: stateInput,
        mAgeInput: ageInput,
        mQueryData: queryData,
        mSavedTracerQueryId: savedTracerQueryId,
        mSavedTahoeId: savedTahoeId,
        recentSearchId: irrelevantArticleId,
        qaMetaData: metaData,
      };

      setEditSearchData(data);
    }
  }, [searchSettings]);

  useEffect(() => {
    if (questions && questions?.length)
      setSearchParams(
        getSearchParams(
          searchName ?? searchString,
          null,
          phoneNumberInput,
          countryInput,
          cityInput,
          stateInput,
          ageInput
        )
      );
  }, [questions]);

  const startProgressBar = () => {
    setProgressStatus(true);
    {
      let prog = 0;
      clearInterval(timer);
      timer = setInterval(() => {
        prog += 1;
        if (prog > 90) {
          // clearInterval(timer)
          return;
        } else {
          setProgress(prog);
        }
      }, 150);
    }
  };

  const stopProgressBar = () => {
    setProgressStatus(false);
    clearInterval(timer);
    setProgress(0);
  };

  useEffect(() => {
    if (!!data && !loading) {
      if (
        data?.refine_search?.result_type?.toUpperCase() ===
        "SUBSCRIPTION LIMIT REACHED"
      ) {
        stopProgressBar();
        setOpenPotentialMatch(false);
        setSearchLimitExceedTitle(`You Have Reached Your Search Limit`);
        setSearchLimitExceed(true);
        const updateUserInfo = { ...user, searchLimitExceed: true };
        setUser(updateUserInfo);
      } else {
        if (user.itemId === FREE_PLAN_ID && clickedPotential) {
          let currentMonthAdhocSearchCount =
            user.adhocSearchLimitPerMonth -
            data?.refine_search?.currentMonthAdhocSearchCount;
          let divideByThree = parseInt(user.adhocSearchLimitPerMonth / 3);
          if (
            currentMonthAdhocSearchCount == divideByThree ||
            currentMonthAdhocSearchCount < 3
          ) {
            stopProgressBar();
            setSearchLimitExceed(true);
            setOpenPotentialMatch(true);
            switch (true) {
              case currentMonthAdhocSearchCount === divideByThree:
                setSearchLimitExceedTitle(
                  `You Have ${currentMonthAdhocSearchCount} Free Searches Left This Month`
                );
                break;
              case currentMonthAdhocSearchCount === 2:
                setSearchLimitExceedTitle(
                  `You Have 2 Searches Left This Month`
                );
                break;
              case currentMonthAdhocSearchCount === 1:
                setSearchLimitExceedTitle(
                  `You Have 1 Free Searches Left This Month`
                );
                break;
              case currentMonthAdhocSearchCount === 0:
                setOpenPotentialMatch(false);
                setSearchLimitExceedTitle(`You Have Reached Your Search Limit`);
                const updateUserInfo = { ...user, searchLimitExceed: true };
                setUser(updateUserInfo);
              default:
                break;
            }
          } else {
            openPotentialMatchScreen();
          }
        } else {
          openPotentialMatchScreen();
        }
      }
    }
  }, [data, loading]);

  useEffect(() => {
    if (!loading && error) {
      stopProgressBar()
      setShowSingleBtnAlert({
        status: true,
        msg: "Did not connect to server. Please try again.",
      });
    }
  }, [error, loading])

  const openPotentialMatchScreen = () => {
    if (searchCompleted) {
      saveSearches(clickedSavedSearch ? eventTypeSaved : "complete-search"); // change potential-match-search to complete-search for ticket 4124
      return;
    }
    stopProgressBar();
    const resultType = getResultTypeFromRefineSearch();
    if (resultType === resultTypes.QUESTION) {
      const {
        question: label,
        options,
        attribute,
        meta_data,
        potentialMatches,
        QA_data,
      } = getQuestionFromRefineSearch();
      setMetaData(meta_data);
      if (attribute === "form") {
        let {
          question: label,
          options,
          attribute,
          meta_data = "{}",
          potentialMatches = null,
        } = shouldSearchQuestion;
        const answers = options.map(optionsToAnswers);
        const newQuestion = Question(
          attribute,
          label,
          answers,
          JSON.parse(meta_data),
          potentialMatches
        );
        addNewQuestion(newQuestion);
      } else if (options !== null) {
        const answers = options.map(optionsToAnswers);
        const newQuestion = Question(
          attribute,
          label,
          answers,
          JSON.parse(meta_data),
          potentialMatches
        );
        addNewQuestion(newQuestion);
      } else if (QA_data != null && QA_data.length) {
        let mNewQuestion = [];
        QA_data.forEach((item) => {
          const { question: label, options, attribute } = item;
          const answers = options.map(optionsToAnswers);
          mNewQuestion.push(Question(attribute, label, answers));
        });
        addMultipleNewQuestion(mNewQuestion);
      }

      if (potentialMatches !== null) {
        setPotentialMatch(potentialMatches);
        addPotentialMatches(potentialMatches);
      }
    } else if (resultType === resultTypes.ARTICLES && metaData) {
      setSearchParams(
        getSearchParams(
          searchName ?? searchString,
          null,
          phoneNumberInput,
          countryInput,
          cityInput,
          stateInput,
          ageInput
        )
      );
      setSearchCompleted(true);
      saveSearches("complete-search");
    }
  };

  useEffect(() => {
    if (
      initialQuestions?.[0]?.identifier === "form" ||
      initialQuestions?.[1]?.identifier === "form" ||
      initialQuestions?.[0]?.identifier === "noResultFound_SearchType"
    ) {
    }
    setTimeout(() => {
      setCleanActive(true);
    }, 200);
    getConfigData();
  }, []);

  const startFreeTrial = () => {
    setOpenSubscription(true)
    setSearchLimitExceed(false);
  };

  const navigateToDashboard = () => {
    setSearchLimitExceed(false);
    if (!openPotentialMatch) {
      navigate('/dashboard', { replace: true })
    } else {
      setOpenPotentialMatch(false);
      startProgressBar();
      openPotentialMatchScreen();
    }
  };


  const [getConfigData, { loading: configLoading, data: configData }] =
    useLazyQuery(GET_CONFIGURATION);

  useEffect(() => {
    if (configData && !configLoading) {
      setConfigurationData(configData);
      setConfigurationLoading(configLoading);
    }
  }, [configData, configLoading]);

  const searchData = (
    searchTerm,
    resp,
    id,
    irrelevantArticles,
    contact,
    searchType = "",
    tracerQueryId,
    tahoeId,
    searchContext,
    searchCompleted,
    age,
    akaList,
    eventType
  ) => {
    if (searchContext) {
      setAge(searchContext?.age?.toString() ?? "");
      setCity(capitalizeCase(searchContext?.city ?? ""));
      setCountry(searchContext?.country ?? "");
      setPhoneNumber(searchContext?.phone ?? "");
      setState(searchContext?.state ?? "");
    }
    setAgePotentialMatch(age);
    setEventTypeSaved(eventType);
    setIrrelevantArticleId(id);
    setSearchString(searchTerm ? formatUserName(searchTerm.trim()) : "");
    setQueryData();

    if (searchCompleted != true) {
      setMetaData();
      onSubmitEditingSearchbarHandler(
        true,
        searchTerm,
        searchContext?.phone ?? "",
        searchContext?.country ?? "",
        searchContext?.city ?? "",
        searchContext?.state ?? "",
        searchContext?.age?.toString() ?? "",
        id
      );
      return;
    }

    setRecentSearchTerm(
      formatUserName(searchContext?.title?.trim() ?? searchTerm)
    );
    setClickedSavedSearch(true);
    setSavedContactId(contact);
    setSavedTahoeId(tahoeId);
    setSavedTracerQueryId(tracerQueryId);
    setIrrelevantArticles(irrelevantArticles);
    setsearchName(searchTerm);
    setClickedPotential(eventType == "potential-match-search");

    let placeL = resp?.Place?.join(";")?.toString();
    let mPlace = "";
    if (searchContext?.city && searchContext?.city != "")
      mPlace = searchContext.city;
    if (searchContext?.state && searchContext?.state != "")
      mPlace =
        mPlace != ""
          ? mPlace + ", " + searchContext.state
          : searchContext.state;
    if (searchContext?.country && searchContext?.country != "")
      mPlace =
        mPlace != ""
          ? mPlace + ", " + searchContext.country
          : searchContext.country;

    var metaDataLocal = {
      Person: resp?.Person[0] ?? (searchType === "PERSON" ? searchTerm : ""),
      Company: resp?.Company?.join(";")?.toString(),
      Place: placeL,
    };
    var metaDataRequest = {
      Person: resp?.Person[0] ?? (searchType === "PERSON" ? searchTerm : ""),
      Company: resp?.Company?.join(";")?.toString(),
      Place:
        placeL && placeL != ""
          ? mPlace
            ? placeL + ";" + mPlace
            : placeL
          : mPlace,
    };

    setMetaData(metaDataLocal);
    let metaArrayLocal = {
      Person: resp?.Person[0] ?? (searchType === "PERSON" ? searchTerm : ""),
      Company: resp?.Company,
      Place: resp?.Place,
    };
    setMetaDataArray(metaArrayLocal);

    setSearchCompleted(true);
    const query = {
      variables: {
        fullname: {
          search: "search",
          meta_data: JSON.stringify(metaDataRequest),
          source: "ADHOC_SEARCH",
        },
      },
    };

    if (contact) query.variables.fullname.contactId = contact;

    if (tracerQueryId) query.variables.fullname.tracerQueryId = tracerQueryId;

    if (tahoeId) query.variables.fullname.tahoeId = tahoeId;

    setShowAddOrgsModal(
      checkCompanyInMetaData(
        query,
        setShowAddOrgsModal,
        (query, company) => {
          startProgressBar();
          setLastQuery(query);
          refineSearch(query);
          setOpenNotification(false);

          if (company && company?.length > 0) {
            setMetaData({
              ...metaDataLocal,
              Company: company?.join(";")?.toString(),
            });
            setMetaDataArray({ ...metaArrayLocal, Company: company });
          }
        },
        null,
        null,
        openNotification
      )
    );
  };

  const saveSearches = (eventType, mQueryData, id) => {
    const name = searchName
      ? searchName
      : searchString
        ? searchString
        : mQueryData?.title ?? "";
    let newMetadata =
      clickedPotential || clickedSavedSearch
        ? metaData
        : getMetadataFromQuestions(questions, JSON.stringify(metaData) === "{}" ? { Person: name } : metaData);
    if (JSON.stringify(newMetadata) === "{}") {
      newMetadata = {
        Person: name,
      };
      setMetaData(JSON.stringify(newMetadata));
    }

    console.log('clickedPotential=====', clickedPotential, clickedSavedSearch, mQueryData, newMetadata, metaData, getMetadataFromQuestions(questions, metaData))
    const query = {
      variables: {
        data: {
          searchTerm: name,
          searchType:
            newMetadata.Person && newMetadata.Person != ""
              ? "PERSON"
              : newMetadata?.Company?.toLowerCase() == name?.toLowerCase()
                ? "COMPANY"
                : newMetadata?.Location?.toLowerCase() == name?.toLowerCase()
                  ? "LOCATION"
                  : newMetadata?.Place?.toLowerCase() == name?.toLowerCase()
                    ? "PLACE"
                    : newMetadata?.Nationality?.toLowerCase() == name?.toLowerCase()
                      ? "PLACE"
                      : "PERSON",
          eventType: eventType,
          metaData: {
            Person: [newMetadata.Person ? newMetadata.Person : ""],
            Company:
              clickedSavedSearch && metaDataArray?.Company
                ? metaDataArray.Company
                : [newMetadata.Company ? newMetadata.Company : ""],
            Location: [newMetadata.Location ? newMetadata.Location : ""],
            Place:
              clickedSavedSearch && metaDataArray?.Place
                ? metaDataArray.Place
                : [newMetadata.Place ? newMetadata.Place : ""],
            Nationality: [
              newMetadata.Nationality ? newMetadata.Nationality : "",
            ],
          },
        },
      },
    };

    if (savedTahoeId) query.variables.data.tahoeId = savedTahoeId;
    if (savedTracerQueryId)
      query.variables.data.tracerQueryId = savedTracerQueryId;
    if (mQueryData) {
      let searchC = { ...mQueryData };
      delete searchC["stateAbbr"];
      delete searchC["countryAbbr"];
      query.variables.data.searchContext = searchC;
    } else if (queryData) {
      let searchC = { ...queryData };
      delete searchC["stateAbbr"];
      delete searchC["countryAbbr"];
      query.variables.data.searchContext = searchC;
    } else {
      let recentQueryData = {
        title: recentSearchTerm ? recentSearchTerm : searchString,
        entityType: sectionIndex == 0 ? "PERSON" : "BUSINESS",
      };

      if (ageInput && ageInput != "") recentQueryData.age = parseInt(ageInput);

      if (cityInput && cityInput != "") recentQueryData.city = cityInput;

      if (stateInput && stateInput != "") recentQueryData.state = stateInput;

      if (countryInput && countryInput != "")
        recentQueryData.country = countryInput;

      if (phoneNumberInput && phoneNumberInput != "")
        recentQueryData.phone = phoneNumberInput;

      query.variables.data.searchContext = recentQueryData;
      setQueryData(recentQueryData);
    }

    if (agePotentialMatch) {
      query.variables.data.age = agePotentialMatch;
    }

    const akaList = getAKAList();
    if (akaList && akaList.length) {
      query.variables.data.akaList = akaList;
    }

    if (
      irrelevantArticleId &&
      (clickedPotential || clickedSavedSearch || isEditFromOverlay)
    )
      query.variables.data.id = irrelevantArticleId;

    save_Article(query);
  };
  const onGetQABotOption = () => {
    const name = searchName ? searchName : searchString;
    const searchType =
      metaData?.Person && metaData?.Person != ""
        ? "PERSON"
        : metaData?.Company?.toLowerCase() == name?.toLowerCase()
          ? "COMPANY"
          : metaData?.Location?.toLowerCase() == name?.toLowerCase()
            ? "LOCATION"
            : metaData?.Place?.toLowerCase() == name?.toLowerCase()
              ? "PLACE"
              : metaData?.Nationality?.toLowerCase() == name?.toLowerCase()
                ? "PLACE"
                : "PERSON";
    let newMetadata =
      clickedPotential || clickedSavedSearch
        ? metaData
        : getMetadataFromQuestions(
          isEditFromOverlay ? questionsEdit : questions,
          metaData
        );
    getModalDataForMetaTag(
      searchType,
      newMetadata,
      name,
      phoneNumberInput,
      countryInput,
      cityInput,
      stateInput,
      ageInput
    );
  };

  const [save_Article, { loading: loadingSaveSeaches }] = useMutation(
    Save_Recent_Search,
    {
      fetchPolicy: "no-cache",
      onCompleted(data) {
        setIrrelevantArticleId(data.saveRecentSearch.id);
        setIrrelevantArticles(data.saveRecentSearch.irrelevantArticles);
        if (searchCompleted && !clickedPotential && !clickedSavedSearch) {
          setClickedEditSearch(true);
          onGetQABotOption();
        } else if (clickedSavedSearch || clickedPotential) {
          onGetQABotOption();
        }
      },
      onError(error) {
        if (searchCompleted && !clickedPotential && !clickedSavedSearch) {
          setClickedEditSearch(true);
          onGetQABotOption();
        } else if (clickedSavedSearch || clickedPotential) {
          onGetQABotOption();
        }
      },
    }
  );

  const onPotentialSelected = (item, index) => {
    if (progress > 0 && savedPotentialMatch.length > 0) return;
    setSavedTahoeId(item.TahoeId);
    setSavedTracerQueryId(item.TracerQueryId);
    setsearchName(item.Person);
    setAgePotentialMatch(item.age);

    let metadata = {};
    if (item?.Company && item?.Company?.length) {
      item?.Company?.forEach((item) => {
        if (item)
          metadata.Company = metadata.Company
            ? metadata.Company + ";" + item
            : item;
      });
    }
    if (item?.Person) metadata.Person = item.Person;

    if (item?.Place && item?.Place?.length) {
      item?.Place?.forEach((item) => {
        let place;
        if (item) {
          if (
            item?.city &&
            queryData?.city?.toLowerCase() != item?.city?.toLowerCase()
          )
            place = item.city;
          if (
            item?.state &&
            queryData?.state?.toLowerCase() != item?.state?.toLowerCase()
          )
            place = place ? place + ", " + item.state : item.state;
          if (
            item?.country &&
            queryData?.country?.toLowerCase() != item?.country?.toLowerCase()
          )
            place = place ? place + ", " + item.country : item.country;
        }
        if (place)
          metadata.Place = metadata.Place
            ? metadata.Place + ";" + place
            : place;
      });
    }

    if (queryData?.city) queryData.city = queryData.city.toLowerCase();

    setMetaData(metadata);
    setSearchCompleted(true);
    setClickedPotential(true);
    setQueryData();

    let metadataQuery = addQueryData(queryData, { ...metadata });

    let variables = {
      fullname: {
        search: "search",
        meta_data: JSON.stringify(metadataQuery),
        source: "ADHOC_SEARCH",
      },
    };

    if (item?.TahoeId) variables.fullname.tahoeId = item.TahoeId;
    if (item?.TracerQueryId)
      variables.fullname.tracerQueryId = item.TracerQueryId;

    let query = {
      variables,
    };
    setShowAddOrgsModal(
      checkCompanyInMetaData(query, setShowAddOrgsModal, (query, company) => {
        setSelectedPotentialIndex(index);
        setSearchData();
        setQuestionsEdit([]);
        setEditFromOverlay(false);
        removeAll();
        startProgressBar();
        setLastQuery(query);
        refineSearch(query);

        if (company && company?.length > 0) {
          setMetaData({ ...metadata, Company: company?.join(";")?.toString() });
        }
      })
    );
  };

  const goToResults = (id, articles, contactId) => {
    const articleDetails = getArticleDetailsFromRefineSearch();
    if (!articleDetails) {
      setSearchCompleted(false);
      return;
    }
    Variables.potentialMatchId = getPotentialMatchId();
    Variables.contactId = getContactId();
    const readArticles = getReadArticlesIds();
    const articleFilterData = getArticleFilterData();
    const aKAList = getAKAList();
    const personalInfo = getPersonalInfo();
    const articleTypeCount = getArticleTypeCount();
    const parsedArticles = articleDetails
      .filter((item) => {
        return item;
      })
      .map((item) =>
        mapSingleArticle(item, searchName ?? searchString, readArticles)
      ); // filter null article
    const metaTags = clickedPotential
      ? metaData
      : getMetadataFromQuestions(questions, metaData);
    const paramsTag =
      clickedSavedSearch || clickedPotential
        ? getSavedSearchParams(metaData)
        : searchParams;
    const name = searchName ? searchName : searchString;
    const data = {
      inNewSearch: inNewSearch,
      lastQuery: lastQuery,
      irrelevantArticleId: id ? id : irrelevantArticleId,
      irrelevantArticles: articles ? articles : irrelevantArticles,
      metadata: metaTags,
      searchTerm: searchName,
      searchType:
        metaTags?.Person && metaTags?.Person != ""
          ? "PERSON"
          : metaTags?.Company?.toLowerCase() == name?.toLowerCase()
            ? "COMPANY"
            : metaTags?.Location?.toLowerCase() == name?.toLowerCase()
              ? "LOCATION"
              : metaTags?.Place?.toLowerCase() == name?.toLowerCase()
                ? "PLACE"
                : metaTags?.Nationality?.toLowerCase() == name?.toLowerCase()
                  ? "PLACE"
                  : "PERSON",
      articles: parsedArticles,
      savedContactID: contactId ?? savedContactID,
      savedTahoeId: savedTahoeId,
      savedTracerQueryId: savedTracerQueryId,
      articleFilterData: articleFilterData,
      personalInfo: personalInfo,
      articleTypeCount: articleTypeCount,
      clickedPotential: clickedPotential,
      potentialsearch: searchString,
      aKAList: aKAList,
      searchSettings:
        clickedSavedSearch || clickedPotential || clickedEditSearch
          ? { ...searchSettings, isAdhocSearch: true }
          : {
            initialSearchString: name,
            intitialMetadata: metaTags,
            initialSearchParams: paramsTag,
            initialQuestions: questions,
            qaMetaData: metaData,
            initialPotentialMatch: savedPotentialMatch,
            isAdhocSearch: true,
          },
      mPhoneNumberInput: phoneNumberInput,
      mCountryInput: countryInput,
      mCityInput: cityInput,
      mStateInput: stateInput,
      mAgeInput: ageInput,
      queryData: queryData,
      potentialMatchId: getPotentialMatchId(),
      mOldMetaData: oldMetaData,
      navigatedFrom: navigatedFrom,
      categoryFilterData: getCategoryFilterData(),
      articleSource: clickedSavedSearch ? "SEARCH" : "SEARCH",
      navigatedFrom: !inNewSearch && isEdit ? "SavedContactsArticles" : "",
      searchIds: getSearchId(),
      linkedInData: getLinkedInData(),
      feedbackOptions: getFeedbackOptions(),
      profilePic: getProfilePic(),
    };
    setSearchData(data);
  };

  const onSubmitEditingSearchbarHandler = (
    focus = true,
    initialSearchString,
    mPhoneNumberInput,
    mCountryInput,
    mCityInput,
    mStateInput,
    mAgeInput,
    id
  ) => {
    setFromBackSearch(false);
    if (loading || loadingData) return;
    setOldMetaData(oldMetaValue);
    let searchValue = "";
    if (initialSearchString && initialSearchString != "") {
      searchValue = initialSearchString;
    } else searchValue = searchString?.trim() || "";

    if (searchValue && searchValue != "") {
      const query = {
        variables: {
          fullname: {
            queryData: {
              title: searchValue,
              entityType: sectionIndex == 0 ? "PERSON" : "BUSINESS",
            },
            source: "ADHOC_SEARCH",
          },
        },
      };

      if (mAgeInput && mAgeInput != "")
        query.variables.fullname.queryData.age = parseInt(mAgeInput);

      if (mCityInput && mCityInput != "")
        query.variables.fullname.queryData.city = mCityInput;

      if (mStateInput && mStateInput != "")
        query.variables.fullname.queryData.state = mStateInput;

      if (mCountryInput && mCountryInput != "")
        query.variables.fullname.queryData.country = mCountryInput;

      if (mPhoneNumberInput && mPhoneNumberInput != "")
        query.variables.fullname.queryData.phone = mPhoneNumberInput;

      setQueryData(query.variables.fullname.queryData);
      if (!isEdit) {
        setSavedContactId();
      }
      setSavedTahoeId();
      setSavedTracerQueryId();
      startProgressBar();
      setLastQuery(query);
      refineSearch(query);
      if (focus) {
        setTimeout(() => {
          saveSearches("adhoc-search", query.variables.fullname.queryData, id);
        }, 400);
      }
    }
    cleanQuestions();
    setsearchName(null);
    setSearchCompleted(false);
    setClickedPotential(false);
    setClickedSavedSearch(false);
    setMetaData({});
  };

  const countries = Object.values(Iso3166.data)
    .map(({ name }) => ({
      label: name,
      value: name,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  // Add main countries to the top of the list, keeping the order defined on this array:
  const highlightedCountries = [
    "United States",
    "Canada",
    "Australia",
    "India",
  ];
  highlightedCountries.reverse().forEach((c) => {
    // Get index of existing
    let idx = countries.findIndex(
      (existingCountry) => existingCountry.label === c
    );

    if (idx !== -1) {
      // Remove it from its original position
      countries.splice(idx, 1);

      // Add it to the top of the list:
      countries.unshift({
        label: c,
        value: c,
      });
    }
  });

  countries.splice(0, 0, { label: "Country of work or residence", value: "" });

  const saveMySearches = () => {
    if (saveSearchVariables == undefined) return;
    saveSearchVariables.data.searchIds = getSearchId();
    saveSearchVariables.data.notifyUser = chkBxNotifyUser;
    saveSearchVariables.data.monitorSearch = monitorSearch;
    saveSearchVariables.data.saveSearch = monitorSearch ? true : saveSearch;

    saveAdhocSearch({
      variables: saveSearchVariables,
    });
    if (monitorSearch) Variables.isRefreshClosestContacts = true;
    Variables.isAddSearches = true;
  };

  const backToSearchComponent = () => {
    setShowSaveConfirmationAlert({ status: false, msg: "" });
    showSaveConfirmationAlert?.onPotentialSelected?.();
  };

  const onEdit = () => {
    setOpenEditSearch(true);
  };


  /**
   * Main render method
   */
  return (
    <div className={Styles.container}>
      {(progress > 0 || savedPotentialMatch.length > 0) && (
        <div className={Styles.containerCenter}>
          <div>
            {progress > 0 && savedPotentialMatch.length == 0 ? (
              <div className={Styles.progressView}>
                <ColoredLinearProgress
                  variant="determinate"
                  value={normalise(progress)}
                  style={{
                    height: 14,
                    width: "100%",
                    borderRadius: 7,
                    alignSelf: "center",
                    marginLeft: progress > 0 ? 10 : 0,
                  }}
                />
              </div>
            ) : savedPotentialMatch.length ? (
              <SavedSearchMatch
                selectedPotentialIndex={selectedPotentialIndex}
                isDesktop={isDesktop}
                potentialMatch={savedPotentialMatch}
                onPotentialSelected={onPotentialSelected}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
      {progress > 0 && savedPotentialMatch.length > 0 && (
        <div className={Styles.containerRightparantProgressbar}>
          <div className={Styles.progressView}>
            <ColoredLinearProgress
              variant="determinate"
              value={normalise(progress)}
              style={{
                height: 14,
                width: "100%",
                borderRadius: 7,
                alignSelf: "center",
                marginLeft: progress > 0 ? 10 : 0,
              }}
            />
          </div>
        </div>
      )}
      {searchResultData && (
        <div className={Styles.recentSearchView}>
          <div className={Styles.header}>
            <span className={Styles.recentSerchTxt}>
              {progress > 0 ||
                savedPotentialMatch.length > 0 ||
                isEditFromOverlay ||
                isFromSavedSearch
                ? ""
                : "Recent Search"}
            </span>
            <div className="flex flex-row">
              <WhiteBgButtonGreenBorder
                onClick={() => {
                  setOpenMapDashboard(true)
                }}
                style={{ width: 150, padding: 10, marginRight: 8, whiteSpace: "nowrap" }}
              >
                <span className={Styles.generatePdfText}>Open Map</span>
              </WhiteBgButtonGreenBorder>

              <WhiteBgButtonGreenBorder
                loading={isPdfGenerating}
                onClick={() => {
                  setIsPdfGenerating(true)
                  generatePdf(
                    'content-to-pdf',
                    searchFormData.name,
                    () => {
                      setIsPdfGenerating(false)
                    })
                }}
                style={{ width: 150, padding: 10, marginRight: 8, whiteSpace: "nowrap" }}
              >
                <span className={Styles.generatePdfText}>Generate PDF</span>
              </WhiteBgButtonGreenBorder>
              {!isFromSavedSearch && (
                <button
                  onClick={onEdit}
                  className="w-1/2 btn btn--primary"
                  style={{ maxWidth: 150, padding: 10 }}
                >
                  Edit Search
                </button>
              )}
            </div>
          </div>
          <div className={Styles.containerRightparantRecent}>
            <SearchResultsWithQuery searchResultData={searchResultData} />
          </div>
        </div>
      )}
      {showAddOrgsModal && showAddOrgsModal}

      {isSearchLimitExceed && (
        <SearchLimitExceedModal
          title={searchLimitExceedTitle}
          onBackdropPress={() => {
            setSearchLimitExceed(false);
          }}
          startFreeTrial={startFreeTrial}
          goback={navigateToDashboard}
          modelType={ModelType.SEARCH_LIMIT_MODAL}
        />
      )}

      {subscriptionModalVisible && (
        <SubscriptionPlansModal
          isWeb={true}
          subscriptionModalVisible={subscriptionModalVisible}
          setSubscriptionModalVisible={setSubscriptionModalVisible}
        />
      )}
      {showSaveConfirmationAlert.status && (
        <SaveSearchResultModal
          setShowSaveConfirmationAlert={(data) => {
            setShowSaveConfirmationAlert(data);
            backToSearchComponent();
          }}
          name={titleCase(showSaveConfirmationAlert.msg)}
          cancelBtnClick={backToSearchComponent}
          setChkBxNotifyUser={setChkBxNotifyUser}
          chkBxNotifyUser={chkBxNotifyUser}
          setSaveSearch={setSaveSearch}
          saveSearch={saveSearch}
          setMonitorSearch={setMonitorSearch}
          monitorSearch={monitorSearch}
          saveMySearches={saveMySearches}
          showNotifyUser={hasData}
        />
      )}

      {showSingleBtnAlert.status == true && (
        <SingleBtnAlert
          description={showSingleBtnAlert.msg}
          btnTxt="OK"
          onBtnClick={() => {
            setShowSingleBtnAlert({ status: false, msg: "" });
          }}
        />
      )}
    </div>
  );
};
export default SearchResults;
