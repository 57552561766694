const NotificationWhiteIcon = (props) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.913 9.93c0 .542-.42.984-.935.984-.515 0-.935-.442-.935-.985V5.005c0-.543.42-.984.935-.984.515 0 .935.441.935.984V9.93Zm-.935 3.938c-.515 0-.935-.441-.935-.984 0-.543.42-.985.935-.985.515 0 .935.442.935.985 0 .543-.42.984-.935.984Zm7.647-1.749L9.988 1.205c-.906-1.606-3.113-1.608-4.02 0L.332 12.12c-.926 1.641.195 3.719 2.01 3.719h11.272c1.813 0 2.936-2.076 2.01-3.719Z"
      fill="#fff"
    />
  </svg>
)

export default NotificationWhiteIcon
