import React, { useState, useEffect } from 'react';
import ModalHeader from '../../ModalHeader/modalHeader';
import Attributes from '../../attributes';
import PreSignUpBackround from '../../../images/svg/pre-sign-up-background'
import AccuracyModalIcon from '../../../images/svg/accuracy-modal-Icon'
import { ButtonBase } from '@mui/material';
import { Modal } from '@mui/material';
import { GreenBgButton } from '../../buttons/greenBgButton';
import Styles from './relevantModal.module.css'

const RelevantModal = (props) => {
  const {
    isVisible,
    onBackdropPress,
    onDonePress,
    initialAttributes,
    fullName,
    setHeaderTags,
    headerTags,
    onClose,
  } = props;


  const modal = {
    marginBottom: 0,
    marginTop: 0,
    marginHorizontal: 0,
  }
  const [attributes, setAttributes] = useState(initialAttributes ?? []);
  const [buttonSuccess, setbuttonSuccess] = useState(false);
  const onSelectAttributeHandler = (category, attributeName, value) => {
    const updateAttributes = attributes?.map((attributesGroup) => {
      if (attributesGroup.groupName !== category) return attributesGroup;
      const updatedAttributesGroup = attributesGroup?.attributes?.map((attribute) => {

        if (attribute.type == 'true_radio' && attribute.label !== attributeName)
          return { ...attribute, status: false };
        if (attribute.type == 'true_radio' && attribute.label === attributeName)
          return { ...attribute, status: !attribute.status };

        if (attribute.label !== attributeName) return attribute;
        if (attribute.status && attribute.type && attribute.type === 'textbox' && (value != false || value == '') && value != true) {
          const updatedAttribute = { ...attribute, value: value }
          return updatedAttribute;
        } else {
          const updatedAttribute = { ...attribute, status: !attribute.status, removeHeader: attribute.status }
          return updatedAttribute;
        }
      });
      return ({
        ...attributesGroup,
        attributes: updatedAttributesGroup,
      })
    });
    setAttributes(updateAttributes);
  }

  useEffect(() => {
    let peopleExist = false
    initialAttributes?.map((attr) => {
      if (Object.values(attr)[0] === "People") {
        peopleExist = true
      }
    })
    if (initialAttributes?.length < 1 || !peopleExist) {
      initialAttributes?.splice(0, 0, { groupName: "People", attributes: [] })
    }
    if (initialAttributes && Array.isArray(initialAttributes)) {
      initialAttributes?.map((a) => {
        if (a.groupName == 'People') {
          a.attributes = [
            { label: 'Yes', status: true, type: 'true_radio' },
            { label: 'No', status: false, type: 'true_radio' },
            // { label: 'Not Sure', status: false, type: 'true_radio' },
          ];
        } else {
          a.attributes = a.attributes.map(item => {
            if (item.type === 'radio_button' || (item.type == 'textbox' && item?.value?.length)) {
              const result = headerTags?.some(element => {
                if (element.param) {
                  return element.param?.toLowerCase() === item.label?.toLowerCase()
                } else {
                  return element?.toLowerCase() === item.label?.toLowerCase()
                }
              })
              item.status = result
            }
            return item
          })
        }
      });
      setAttributes([...initialAttributes])
    }
  }, [initialAttributes]);

  const onBackdropPressHandler = () => {
    setAttributes(initialAttributes);
    onClose?.();
  }

  const resetFilter = () => {
    let peopleExist = false
    initialAttributes?.map((attr) => {
      if (Object.values(attr)[0] === "People") {
        peopleExist = true
      }
    })
    if (initialAttributes?.length < 1 || !peopleExist) {
      initialAttributes?.splice(0, 0, { groupName: "People", attributes: [] })
    }
    if (initialAttributes && Array.isArray(initialAttributes)) {
      initialAttributes?.map((a) => {
        if (a.groupName == 'People') {
          a.attributes = [
            { label: 'Yes', status: false, type: 'true_radio' },
            { label: 'No', status: false, type: 'true_radio' },
            // { label: 'Not Sure', status: false, type: 'true_radio' },
          ];
        } else {
          a.attributes = a.attributes.map(item => {
            item.status = false
            return item
          })
        }
      });
      setAttributes([...initialAttributes])
    }
  }
  return (
    <div>
      <Modal
        style={{
          justifyContent: 'center',
          display: 'flex'
        }}
        open={isVisible}
      >
        {(buttonSuccess) ?
          <div className={Styles.modalContainer}>
            <PreSignUpBackround className={Styles.backgroundImage} />
            <div className={Styles.alignImage}>
              <AccuracyModalIcon />
            </div>
            <div>
              <span className={Styles.title}>Thank you for your</span>
              <span className={Styles.title}>information</span>
            </div>
            <div className={Styles.textContainer}>
              <span className={Styles.titleSmall} style={{ marginTop: 20 }}>You have successfully submitted your results</span>
              <span className={Styles.titleSmall}>from the survey</span>
            </div>
            <div className={Styles.button}>
              <ButtonBase
                className={Styles.separator}
                title="Never Show News From This Source Again"
              />

              <GreenBgButton
                loading={false}
                fullWidth
                variant="contained"
                onClick={() => { onDonePress(attributes); setbuttonSuccess(!buttonSuccess) }}
              >
                <span>Done</span>
              </GreenBgButton>
            </div>
          </div>
          :
          <div className={Styles.container}>
            <div className={Styles.header}>
              <ModalHeader
                title={``}
                onPress={onBackdropPressHandler}
                left={0}
                leftTitle={'Close'}
                rightTitle={'Reset'}
                onResetPress={resetFilter}
              />
            </div>
            <div className={Styles.content}>
              <div className={Styles.alignImage}>
                <AccuracyModalIcon />
              </div>
              {/* <Image
            className={Styles.image}
            source={require('../../../images/post-article-relevancy-bot-logo.png')}
            resizeMode={'contain'}
          /> */}
              {/* <Text className={Styles.textQuestion}>{`We want to make sure that we've got the right ${fullName}`}</Text> */}
              <span className={Styles.textQuestion}>{`Help us improve the accuracy of your results`}</span>
              {/* <Text className={Styles.textSimple}>{'Select the attributes below to strengthen your future searches'}</Text> */}

              <div className={Styles.attributesContainer}>
                <Attributes
                  attributes={attributes}
                  fullName={fullName}
                  selectable
                  onSelectAttribute={onSelectAttributeHandler}
                  theme='dark'
                  from={'relevant'}
                  isFromBot={true}
                />
              </div>
            </div>
            <div className={Styles.saveButton}>
              <GreenBgButton
                loading={false}
                fullWidth
                variant="contained"
                onClick={() => onDonePress(attributes)}
              >
                <div style={{ fontFamily: 'Proxima Nova', fontSize: 14 }}>Save & Close</div>
              </GreenBgButton>
              <div style={{ justifyContent: 'center', alignItems: 'center', margin: 20, marginBottom: 0 }}>
                {/* <Text className={Styles.skipLabel} onPress={() => setbuttonSuccess(!buttonSuccess)}>SKIP</Text> */}
              </div>
            </div>
          </div>
        }
      </Modal>
    </div>
  );
};

export default RelevantModal;