import React from 'react'
const CloseIcon = (props) => {
  return (
    <svg width={props?.style?.width ?? 15} height={props?.style?.height ?? 15} viewBox="0 0 15 15" {...props}>
      <path
        d="M9.605 7.518l4.985-4.985a1.408 1.408 0 000-1.985l-.103-.103a1.408 1.408 0 00-1.986 0L7.517 5.429 2.532.445a1.408 1.408 0 00-1.985 0L.444.548a1.408 1.408 0 000 1.985l4.984 4.985-4.984 4.984a1.408 1.408 0 000 1.986l.103.103c.546.546 1.44.546 1.985 0l4.985-4.985 4.984 4.985c.546.546 1.44.546 1.986 0l.103-.103a1.408 1.408 0 000-1.986L9.605 7.518z"
        transform="translate(-330 -96) translate(15 81) translate(315 15)"
        fill="#078167"
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </svg>
  )
}

export default CloseIcon
