import React from 'react';
import confirmNavigateToContacts from '../../images/confirmNavigateToContacts.png'
import { Modal } from '@mui/material';
import Slide from '@mui/material/Slide';
import createStyle from './styles';
import { GreenBgButton } from '../buttons/greenBgButton';
import { GreenBorderButton } from '../buttons/greenBorderButton';
import { useNavigate } from "react-router-dom";


const ImportContactsConfirmModal = (props) => {
  const {
    isVisible=false,
    onBackdropPress,
  } = props;

  const navigate = useNavigate();
  const style = createStyle();

  const onContinuePress = () => {
    onBackdropPress();
    const data = {
      screenName: 'newDashboard'
    }
    navigate("/importContactsLanding", {state: JSON.stringify(data)})
  }
  
  return (
    <Modal
      open={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      >
        <Slide in={true} direction="up">
          <div style={{
            backgroundColor: 'rgba(84, 83, 83, 0.2)',
            position: 'absolute',
            bottom: 0,
            top: 0,
            width: '100%',
          }}>
            <div style={style.container}>
              <div style={style.content}>
                <img src={confirmNavigateToContacts} style={style.mainIcon} />
                <span style={style.textQuestion}>Do you want news about your own network?</span>
                <span style={style.textSimple}>Upload your contacts to monitor your professional and social network for risks or opportunities.</span>
                
                 <div style={style.buttonContainer}>
                  <GreenBgButton
                    loading={false}
                    onClick={onContinuePress}
                    fullWidth
                    variant="contained"
                    >
                      <div style={style.btTitle}>Import Contacts</div>
                  </GreenBgButton>
                  <GreenBorderButton
                    fullWidth
                    variant="contained"
                    onClick={onBackdropPress}
                    style={{marginTop:10}}>
                      <div  style={style.btTitle}>Back to My Dashboard</div>
                  </GreenBorderButton>
                </div>
              </div>
            </div>
          </div>
        </Slide>
    </Modal>
  );
};

export default ImportContactsConfirmModal;