import * as React from "react"
const PositiveNews = (props) => {
    return(
      <svg
      width={33}
      height={32}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          d="M12.167 6H7.833c-.92 0-1.666.746-1.666 1.667V14c0 .92.746 1.667 1.666 1.667h4.334c.92 0 1.666-.746 1.666-1.667V7.667c0-.92-.746-1.667-1.666-1.667ZM20.167 8.067H17.5a1 1 0 0 0-1 1v.666a1 1 0 0 0 1 1h2.667a1 1 0 0 0 1-1v-.666a1 1 0 0 0-1-1ZM18.5 13.4h-1a1 1 0 0 0-1 1v.667a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V14.4a1 1 0 0 0-1-1ZM14.167 18.733H6.833a1 1 0 0 0-1 1v.667a1 1 0 0 0 1 1h7.334a1 1 0 0 0 1-1v-.667a1 1 0 0 0-1-1ZM13.167 24.067H6.833a1 1 0 0 0-1 1v.666a1 1 0 0 0 1 1h6.334a1 1 0 0 0 1-1v-.666a1 1 0 0 0-1-1Z"
          fill={props.selected ? "#142030" :  props.iconType == 'white' ? '#fff' :  '#b5b9c4'}
        />
        <path
          d="M31.328 10.505a4 4 0 0 1 1.172 2.828V18c0 1.463-.029 2.775-.113 3.95a8.68 8.68 0 0 0-2.559-4.868c.004-.351.005-.712.005-1.082v-2.667A1.333 1.333 0 0 0 28.5 12h-2a.667.667 0 0 0-.667.667V14c0 .306.002.607.005.905a8.67 8.67 0 0 0-2.667-.213 58.226 58.226 0 0 1-.004-.692V4a.667.667 0 0 0-.667-.667H3.833A.667.667 0 0 0 3.167 4v22.667a2 2 0 0 0 2 2h10.666c.408 0 .795-.002 1.164-.007a8.674 8.674 0 0 0 3.408 2.633c-.804.027-1.66.04-2.572.04H5.167A4.667 4.667 0 0 1 .5 26.667V3.333A2.667 2.667 0 0 1 3.167.667h20a2.667 2.667 0 0 1 2.666 2.666v6H28.5a4 4 0 0 1 2.828 1.172Z"
          fill={props.selected ? "#142030" :  props.iconType == 'white' ? '#fff' :  '#b5b9c4'}
        />
        <path
          d="M23.833 14.667a8.667 8.667 0 1 0 8.667 8.666 8.68 8.68 0 0 0-8.667-8.666Zm4 10h-2a.667.667 0 0 0-.666.666v2a1.333 1.333 0 0 1-2.667 0v-2a.667.667 0 0 0-.667-.666h-2a1.334 1.334 0 0 1 0-2.667h2a.667.667 0 0 0 .667-.667v-2a1.334 1.334 0 0 1 2.667 0v2a.667.667 0 0 0 .666.667h2a1.333 1.333 0 0 1 0 2.667Z"
          fill={props.selected ? props.iconType =="blackWhite"? "#1E1E1E": "#9C6" : props.iconType == 'white' ? '#fff' :  '#b5b9c4'}
        />
      </g>
      <defs>
        <clip-path id="a">
          <path fill="#fff" transform="translate(.5)" d="M0 0h32v32H0z" />
        </clip-path>
      </defs>
    </svg>
)}

export default PositiveNews
