import * as React from "react"

const EmailProfile = (props) => (
  <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
  <path d="M13.5 0H1.5C0.675 0 0.00749999 0.675 0.00749999 1.5L0 10.5C0 11.325 0.675 12 1.5 12H13.5C14.325 12 15 11.325 15 10.5V1.5C15 0.675 14.325 0 13.5 0ZM13.5 10.5H1.5V3L7.5 6.75L13.5 3V10.5ZM7.5 5.25L1.5 1.5H13.5L7.5 5.25Z" fill="#078167"/>
  </svg>

)

export default EmailProfile
