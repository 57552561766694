import React from "react";
import createStyle from "./styles";
import { getFilterImage } from "../../../images/filterColorIcon/getFilterImage";
import Styles from "./checkFilter.module.css";

const CheckFilterRecent = (props) => {
  const { index, label, status, iconType, setStatus, count = "" } = props;
  const style = createStyle();

  return (
    <div
      className={Styles.containerFilter}
      style={{ borderColor: status ? "#078167" : "#EFF1F5" }}
      onClick={setStatus}
      key={index}
    >
      <div className={Styles.mainCategory}>
        <div className={Styles.categoryView}>
          {getFilterImage(label, true, "", null, { transform: "scale(.55)" })}
          <span style={{ ...style.lableText, color: "#142030" }}>
            {label === "MetaSearch" ? (
              <>
                {label} <span style={{ fontSize: 'smaller', color: 'green' }}>(BETA)</span>
              </>
            ) : (
              label
            )}
          </span>
        </div>
        <span style={{ ...style.lableText, color: "#697080" }}>
          {" (" + count + ")"}
        </span>
      </div>
    </div>
  );
};

export default CheckFilterRecent;
