import React, { useState, useEffect } from "react";
import createStyle from "./styles";
import NotificationList from "./notificationList";
import { useLazyQuery } from "react-apollo";
import { GET_NOTIFICATION } from "../../../queries/queries";
import moment from "moment";
import {
  filterArticlesByRead,
  filterArticlesByUnread,
} from "../../../utils/filterUtils";
import { Backdrop, Button, ButtonGroup, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { SectionList } from "@protonko/section-list";
import { notificationStore } from "../../../store/notificationStore";
import Styles from "./notification.module.css";

const FILTER_TIMEOUT = 2000;
let lastTimeout = null;

const Notification = (props) => {

  const style = createStyle();
  const [getNotification, { data, loading }] = useLazyQuery(GET_NOTIFICATION, {
    fetchPolicy: "network-only",
  });

  const sections = [
    {
      title: "Unread",
      content: <div></div>,
    },
    {
      title: "Read",
      content: <div></div>,
    },
  ];

  const sectionIndex = notificationStore((state) => state.sectionIndex);
  const setSectionIndex = notificationStore((state) => state.setSectionIndex);

  const notification = notificationStore((state) => state.notification);
  const setNotification = notificationStore((state) => state.setNotification);

  const filteredNotification = notificationStore(
    (state) => state.filteredNotification
  );
  const setFilteredNotification = notificationStore(
    (state) => state.setFilteredNotification
  );

  const filteredNotificationExist = notificationStore(
    (state) => state.filteredNotificationExist
  );
  const setFilteredNotificationExist = notificationStore(
    (state) => state.setFilteredNotificationExist
  );
  const setNotificationCount = notificationStore(
    (state) => state.setNotificationCount
  );

  const loadingClickCard = notificationStore((state) => state.loadingClickCard);

  const isChanged = notificationStore((state) => state.isChanged);
  const setChanged = notificationStore((state) => state.setChanged);

  const removeAll = notificationStore((state) => state.removeAll);

  const [loadingCard, setLoadingCard] = useState(false);

  useEffect(() => {
    if (data && !loading) {
      setChanged(false);
      parseNotification(data);
    }
  }, [data, loading]);

  const callNotification = () => {
    getNotification();
  };

  const broadcast = new BroadcastChannel("refresh-notification");

  useEffect(() => {
    if (broadcast) {
      broadcast.onmessage = (res) => {
        clearTimeout(lastTimeout);
        lastTimeout = setTimeout(() => {
          // To avoid multiple API call once received multiple notification
          callNotification();
        }, FILTER_TIMEOUT);
      };
    }
  }, []);

  useEffect(() => {
    // if(notification == null || notification.length ==0){
    callNotification();
    // }
  }, []);

  useEffect(() => {
    setFilteredNotification(
      notification?.map((n) => ({
        ...n,
        data:
          sectionIndex == 0
            ? filterArticlesByUnread(n.data)
            : filterArticlesByRead(n.data),
      }))
    );

    let unreadNotification = 0;
    notification?.map(
      (n) => (unreadNotification += filterArticlesByUnread(n.data)?.length)
    );
    setNotificationCount(unreadNotification);
  }, [notification, sectionIndex]);

  useEffect(() => {
    let data = [];
    filteredNotification?.map((fN) => {
      data = [...data, ...fN?.data];
    });
    if (data?.length > 0) setFilteredNotificationExist(true);
    else setFilteredNotificationExist(false);
  }, [filteredNotification]);

  const parseNotification = (data) => {
    if (data && data.getNotifications.length > 0) {
      let noti = [];
      let recent = [];
      let past = [];
      let readIDs = [];
      data.getNotifications.forEach((item) => {
        if (!item.isRead) readIDs.push(item.id);
        const notificationDate = new Date(parseInt(item.createdAt));
        if (moment().diff(notificationDate, "days") > 3) {
          past.push(item);
        } else {
          recent.push(item);
        }
      });

      if (recent.length > 0) {
        noti.push({ title: "Recent Notifications", data: recent });
      }
      if (past.length > 0) {
        noti.push({ title: "Past Notifications", data: past });
      }
      setNotification(noti);
    }
  };

  return (
    <>
      <div style={style.container}>
        <div style={style.containerHeader}>
          <div style={style.header}>
            <span style={style.headerText}>Notifications</span>
          </div>
          <div style={style.contactsListContainer}>
            <ButtonGroup
              disableElevation
              variant="contained"
              className={Styles["segmentedControlContainer"]}
            >
              <Button
                fullWidth
                className={
                  sectionIndex == 0
                    ? `${Styles["unreadBtnSelected"]} ${Styles["segmentedControlSelectedButton"]}`
                    : `${Styles["unreadBtnUnselected"]} ${Styles["segmentedControlButton"]}`
                }
                onClick={() => setSectionIndex(0)}
              >
                <span
                  className={
                    sectionIndex == 0
                      ? Styles.segmentedControlSelectedText
                      : Styles.segmentedControlText
                  }
                >
                  {sections[0].title}
                </span>
              </Button>
              <Button
                fullWidth
                className={
                  sectionIndex == 1
                    ? `${Styles["readBtnSelected"]} ${Styles["segmentedControlSelectedButton"]}`
                    : `${Styles["readBtnUnselected"]} ${Styles["segmentedControlButton"]}`
                }
                onClick={() => setSectionIndex(1)}
              >
                <span
                  className={
                    sectionIndex == 1
                      ? Styles.segmentedControlSelectedText
                      : Styles.segmentedControlText
                  }
                >
                  {sections[1].title}
                </span>
              </Button>
            </ButtonGroup>
          </div>
        </div>
        <div style={{ ...style.contactsListContainer }}>
          {filteredNotificationExist ? (
            <div style={style.notificationListContainer}>
              <SectionList
                className={Styles.sectionList}
                sections={filteredNotification}
                keyExtractor={(item, index) => `${item}_${index}`}
                renderItem={(item) => (
                  <NotificationList
                    isTransparent
                    item={item}
                    setLoadingCard={setLoadingCard}
                  />
                )}
                renderSectionHeader={(item) => (
                  <div style={style.titleSection}>{item.title}</div>
                )}
                showsVerticalScrollIndicator={true}
              />
            </div>
          ) : (
            <div
              style={{ ...style.contactsListContainer, alignItems: "center" }}
            >
              {loading ? (
                <></>
              ) : (
                <>
                  <img
                    src={require("../../../images/no-results-icon.png")}
                    style={style.image}
                  />
                  <span style={style.titleText}>There's nothing here yet…</span>
                  {sectionIndex == 0 ? (
                    <span style={style.descriptionText}>
                      Ferret will notify you of any news about your starred
                      contacts. They will be shown here as they come in.
                    </span>
                  ) : (
                    <span style={style.descriptionText}>
                      As you read your notifications we will move them here to
                      help keep things organized.
                    </span>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>

      {(loadingClickCard || loading) && (
        <Backdrop
          sx={{
            position: "absolute",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: "#00000000",
          }}
          open={true}
        >
          <CircularProgress />
        </Backdrop>
      )}
    </>
  );
};

export default Notification;
