import React, { useState } from "react";
import SavedSearchCard from "../savedSearchCard/savedSearchCard";
import getMetaFromContacts from "../../utils/getMetaFromContacts";
import useSaveRecentSearch from "../../utils/apis/useSaveRecentSearch";
import { useNavigate } from "react-router-dom";
import { savedSearchArticlesStore } from "../../store/savedSearchArticlesStore";
import Styles from "./savedSearchCardWithQuery.module.css";
import { checkCompanyInMetaData } from "../addOrgModal/checkCompanyInMetaData";
import Variables from "../../utils/Variables";
import { useMutation } from "react-apollo";
import { SET_PREFERENCE } from "../../queries/queries";
import { closestContactsStore } from '../../store/closestContactsStore';

const SavedSearchCardWithQuery = (props) => {
  const navigate = useNavigate();
  const [showAddOrgsModal, setShowAddOrgsModal] = useState();
  const [setPreferenceContacts] = useMutation(SET_PREFERENCE)
  const removeAll = savedSearchArticlesStore((state) => state.removeAll);

  const { parseSaveRecentSearch } = useSaveRecentSearch();
  const {
    newsItem,
    onDeleteContact,
    checkWatchedLimit,
    getPotentialMatches,
    isWeb,
    onContactSelected,
    selectedPotential,
    IS_WATCHED_SEARCH,
  } = props;

  const closetSearches = closestContactsStore((state) => state.contactList);
  const setClosetSearches = closestContactsStore((state) => state.setContactList);

  const onStartPress = (data) =>{
    if(data){
      Variables.isAddSearches = true
      let requestData = [
        {
          id: data.id,
          isPreferred: false,
        },
      ];
      setPreferenceContacts({
        variables: {
          contacts: requestData,
        },
      });

      let tsavedSearches = closetSearches;
      let mContactList = tsavedSearches.filter((savedSearch)=>savedSearch.id!=data?.id)
      setClosetSearches(mContactList)
    }
  }

  return (
    <div className={Styles.articleSeparator}>
      <SavedSearchCard
        openContactModal={props.contactModal}
        onDeleteContact={onDeleteContact}
        onChangePotentialMatch={props?.onChangePotentialMatch}
        showChangePotentialMatch={props?.showChangePotentialMatch}
        savedSearch={newsItem}
        selectedPotential={selectedPotential}
        style={{ ...props.style }}
        onStartPress={IS_WATCHED_SEARCH ? onStartPress : null}
        isFromClosest={IS_WATCHED_SEARCH}
        onPress={() =>
          onContactClick(
            newsItem,
            navigate,
            parseSaveRecentSearch,
            getPotentialMatches,
            removeAll,
            setShowAddOrgsModal,
            isWeb,
            onContactSelected,
          )
        }
        checkWatchedLimit={checkWatchedLimit}
        categories={props?.categories}
        isWeb={isWeb}
      />
      {showAddOrgsModal && showAddOrgsModal}
    </div>
  );
};

const onContactClick = (
  newsItem,
  navigate,
  parseSaveRecentSearch,
  getPotentialMatches,
  removeAll,
  setShowAddOrgsModal,
  isWeb,
  onContactSelected
) => {
  if (newsItem?.isProcessed && newsItem?.multiplePotentialMatchesFound) {
    getPotentialMatches(newsItem?.id);
    return;
  }
  removeAll?.();

  try{
    if(newsItem?.searchContext?.city && newsItem?.searchContext?.city != ''){
      let mCity = newsItem?.searchContext?.city;
      if(newsItem?.metaData && newsItem?.metaData?.Location && newsItem?.metaData?.Location?.length > 0){
        newsItem.metaData.Location = [newsItem?.metaData?.Location+", "+mCity]
      }else{
        newsItem.metaData.Location = [mCity]
      }
      newsItem.searchContext.city = '';
    }
  }catch(e){console.log(e)}

  const {
    metadata,
    searchTerm,
    searchType,
    searchContext,
    tahoeId,
    tracerQueryId,
    requestMetadata,
    akaList
  } = getMetaFromContacts(newsItem)
  let arrayData = []

  // parseSaveRecentSearch(searchTerm, metadata, 'adhoc-search')
  const data = {
    contactItem: newsItem,
    metadata,
    searchTerm,
    searchType,
    searchContext,
    tahoeId,
    tracerQueryId,
    requestMetadata,
    akaList,
    feed: {
      fullName: `${newsItem.name} ${
        newsItem.lastName != null ? newsItem.lastName : ""
      }`,
      metadata: [],
      searchSettings: { initialSearchParams: arrayData },
    },
  };

  setShowAddOrgsModal(
    checkCompanyInMetaData(
      "",
      setShowAddOrgsModal,
      (query, company) => {

        data.initialMetadata = data.metadata

        if (company && company.length > 0) {
          data.metadata = {
            ...metadata,
            Company: company?.join(";")?.toString(),
          };
          data.requestMetadata = {
            ...requestMetadata,
            Company: company?.join(";")?.toString(),
          };
        }

        Object.values(data.metadata)?.forEach((item) => {
          arrayData = [...arrayData, ...item?.split(";")];
        });

        if (searchContext?.age) {
          let age = `App. ${searchContext?.age} y/o`;
          arrayData.splice(1, 0, age + "");
        }
        if (searchContext?.state) arrayData.splice(1, 0, searchContext?.state);
        if (searchContext?.city) arrayData.splice(1, 0, searchContext?.city);
        if (searchContext?.country)
          arrayData.splice(1, 0, searchContext?.country);
        if (searchContext?.phone) arrayData.splice(1, 0, searchContext?.phone);
        if (searchContext?.stateAbbr)
          arrayData.splice(1, 0, searchContext?.stateAbbr);
        if (searchContext?.countryAbbr)
          arrayData.splice(1, 0, searchContext?.countryAbbr);

        data.feed.searchSettings.initialSearchParams = arrayData;

        if(newsItem?.availableFilter?.length == undefined || newsItem?.availableFilter?.length == 0){
          Variables.isAddSearches = true
        }
        if (isWeb && onContactSelected) {
          onContactSelected(data)
        } else {
          navigate("/savedContactsArticles", { state: JSON.stringify(data) });
        }
      },
      metadata,
      isWeb,
    )
  );
};

export default SavedSearchCardWithQuery;
export { onContactClick };
