import * as React from "react"

const ExperienceProfile = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.6 3.2V1.6H6.4V3.2H9.6ZM1.6 4.8V13.6H14.4V4.8H1.6ZM14.4 3.2C15.288 3.2 16 3.912 16 4.8V13.6C16 14.488 15.288 15.2 14.4 15.2H1.6C0.712 15.2 0 14.488 0 13.6L0.00799999 4.8C0.00799999 3.912 0.712 3.2 1.6 3.2H4.8V1.6C4.8 0.712 5.512 0 6.4 0H9.6C10.488 0 11.2 0.712 11.2 1.6V3.2H14.4Z" fill="#078167"/>
    </svg>
  )
}

export default ExperienceProfile
