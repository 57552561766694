import _ from 'lodash'
import RemoveAdvanceSearch from './removeAdvanceSearch'
import Iso3166 from 'iso-3166-2';
import abbrState from '../utils/apis/getAbbrForState';
var numbers = /^[0-9]+$/;

const parseFerretContactsForContactsComponent = (contacts) => {
  if (!Array.isArray(contacts) || contacts.length == 0) { return []}
  const parsedContacts = []
  contacts.sort((a, b) => {
    const fullNameA = `${a.name} ${a.lastName}`.toLowerCase().trim()
    const fullNameB = `${b.name} ${b.lastName}`.toLowerCase().trim()

    if (fullNameA < fullNameB) { return -1 }
    if (fullNameA > fullNameB) { return 1 }
    return 0
  }).forEach((contact) => {
    if (contact.name?.length == 0 ) {
      return
    }

    try{
      if(contact?.company && contact?.metaData?.Company?.length && contact?.metaData?.Company[0]?.length){
        let companyArray = contact?.company.toLowerCase().split(";")
        let companyMataArray = contact.metaData.Company[0].split(";")
        contact.metaData.Company[0] = companyMataArray.filter(item => !companyArray.includes(item.toLowerCase())).join(";")
      }
      if(contact?.postalAddresses?.length && contact?.postalAddresses[0]?.city?.length && contact?.metaData?.Location?.length && contact?.metaData?.Location[0]?.length){
        let cityArray = contact?.postalAddresses[0]?.city.toLowerCase().split(";")
        let locationMataArray = contact.metaData.Location[0].split(";")
        contact.metaData.Location[0] = locationMataArray.filter(item => !cityArray.includes(item.toLowerCase())).join(";")
      }
    }catch(e){console.log(e)}

    let meta_data = [];
    if(contact?.postalAddresses?.length > 0){
      let mPlaceArray = RemoveAdvanceSearch(contact?.searchContext,contact?.postalAddresses[0]?.city)
      meta_data = [contact.company,mPlaceArray, contact?.postalAddresses[0]?.region, contact?.postalAddresses[0]?.state, contact?.postalAddresses[0]?.country]
    }else if(contact?.company && contact.company != ''){
      meta_data = [contact.company]
    }
  
    if(contact?.searchContext?.age && !meta_data.includes(contact?.searchContext?.age+'')){
      let age = `App. ${contact?.searchContext?.age} y/o`
      meta_data.splice(1, 0, age+'')
    }
    let country;
    if(contact?.searchContext?.country && !meta_data.includes(contact?.searchContext?.country))
      country = Iso3166.country(contact?.searchContext?.country)?.code ?? contact?.searchContext?.country;
    if(contact?.searchContext?.state && !meta_data.includes(contact?.searchContext?.state)){
      let state =
              country == 'US'
                ? abbrState(contact?.searchContext?.state, 'abbr') ?? contact?.searchContext?.state
                : contact?.searchContext?.state;
      meta_data.splice(1, 0, state)
    }
    if(contact?.searchContext?.city && !meta_data.includes(contact?.searchContext?.city))
      meta_data.splice(1, 0, contact?.searchContext?.city)
    if(contact?.searchContext?.country && !meta_data.includes(contact?.searchContext?.country))
      meta_data.splice(1, 0, country)

    if (contact?.searchContext?.originalPhone && !meta_data.includes(contact?.searchContext?.originalPhone)) {
      meta_data.splice(1, 0, contact?.searchContext?.originalPhone)
    }
    else if (contact?.searchContext?.phone && !meta_data.includes(contact?.searchContext?.phone)) {
      meta_data.splice(1, 0, contact?.searchContext?.phone)
    }

    if(contact?.metaData?.Location?.length){
      meta_data = [...meta_data,...contact.metaData.Location]
    }
    if(contact?.metaData?.Company?.length){
      meta_data = [...meta_data,...contact.metaData.Company]
    }
  
    contact.meta_data_array = meta_data
    const newContact = {
      firstName: contact.name,
      lastName: contact.lastName,
      photo: contact.photo ? contact.photo : require('../images/svg/default-avatar.svg'),
      selected: false,
      isPreferred: contact.isPreferred,
      terms: [],
      originalContact: contact,
      isChanged: false,
      isProcessed: contact.isProcessed
    }
    let initialLetter = newContact?.firstName?.[0]?.toUpperCase() ?? '#'
    if(initialLetter.match(numbers)) initialLetter = '#'
    const lastSection = parsedContacts[parsedContacts.length - 1]
    if (parsedContacts.length == 0 || lastSection.title != initialLetter) {
      parsedContacts.push({
          title: initialLetter,
          data: [ newContact ]
      })
    } else {
      lastSection.data.push(newContact)
    }
  })
  return parsedContacts
}

export default parseFerretContactsForContactsComponent