import { styled } from "@mui/material/styles";
import LoadingButton from '@mui/lab/LoadingButton';

export const AppleBtn = styled(LoadingButton)((props) => ({
  fontFamily: 'Proxima Nova',
  fontWeight: 'normal',
  alignItems: "center",
  textAlign: "center",
  color: "#FFFFFF",
  backgroundColor: "#000000",
  borderRadius: 6,
  padding: '15px',
  fontSize: '16px',
  lineHeight: '19px',
  '&:hover': {
    backgroundColor: '#000000',
    color: '#000000',
  }
}));
