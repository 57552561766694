import React, { useRef, useContext } from "react";
import Styles from "./shareferret.module.css";
import ProfilePicture from "../profilePicture/profilePicture";
import { RWebShare } from "react-web-share";
import { UserContext } from "../../../contexts/userContext";

const ShareFerret = () => {
  const circlRef = useRef();
  const { user } = useContext(UserContext);

  return (
    <RWebShare
      disableNative={true}
      data={{
        url: "https://www.ferret.ai?urefid=" + user?.id,
        title: "Share Ferret",
      }}
      onClick={() => console.log("shared successfully!")}
    >
      <div className={Styles.containerMain}>
        <div className={Styles.containerChild}>
          <div className={Styles.rowsPic}>
            <ProfilePicture
              avatarStyle={{
                width: "60px",
                height: "60px",
                backgroundColor: "#C0C7D6",
                borderRadius: 30,
                isDarkPic: true,
              }}
              profilePhoto={null}
            />
            <ProfilePicture
              avatarStyle={{
                width: "60px",
                height: "60px",
                marginLeft: "-20px",
                backgroundColor: "#C0C7D6",
                borderRadius: 30,
                isDarkPic: true,
              }}
              profilePhoto={null}
            />
            <ProfilePicture
              avatarStyle={{
                width: "60px",
                height: "60px",
                marginLeft: "-20px",
                backgroundColor: "#C0C7D6",
                borderRadius: 30,
                isDarkPic: true,
              }}
              profilePhoto={null}
            />
          </div>
          <div className={Styles.rowsCard}>
            <span className={Styles.textView}>Share Ferret</span>
            <span className={Styles.textViewLarge}>
              Refer a Friend to Ferret…
            </span>
          </div>
        </div>
        <div className={Styles.demoView} ref={circlRef} />
      </div>
    </RWebShare>
  );
};

export default ShareFerret;
