import * as React from "react"
const Property = (props) => {
  return (
    <svg
      width={32}
      height={32}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 28"
      {...props}
    >
      <path
        d="M20.9 7.467V1.185h-3.6v3.082L12.5 0 .5 10.667h3.6l8.4-7.479 8.4 7.479h3.6l-3.6-3.2Z"
        fill={props.selected ? "#142030" : '#b5b9c4'}
      />
      <path stroke={props.selected ? props.iconType =="blackWhite"? "#1E1E1E": "#5391F7" : '#b5b9c4'} strokeWidth={2} d="M10.5 11.5h4V20h-4z" />
    </svg>
  );
}

export default Property

