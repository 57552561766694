import Avatar from "@mui/material/Avatar";
import DefaultAvatarProfile from "../../images/svg/default-avatar-profile";

export default function ProfilePicture({
  profilePhoto,
  style,
  avatarStyle,
  onClick,
  customWidth,
  customHeight,
  isLinkedIn,
}) {
  return (
    <>
      {profilePhoto ? (
        <Avatar src={profilePhoto} onClick={onClick} sx={avatarStyle} imgProps={{ referrerPolicy: "no-referrer" }} >
          {isLinkedIn ? <DefaultAvatarProfile style={avatarStyle} /> :
            <Avatar src="/broken-image.jpg" onClick={onClick} sx={avatarStyle} />}
        </Avatar>
      ) : (
        isLinkedIn ? <DefaultAvatarProfile style={avatarStyle}/> : <Avatar src="/broken-image.jpg"  onClick={onClick} sx={avatarStyle}/>
      )}
    </>
  );
}
