import { create } from 'zustand';

export const closestContactsEditListStore = create((set) => ({
  filteredContacts: [],
  originalContacts: [],
  categories: [],
  originalParsedContacts: {},
  searchValue: "",
  potentialModalVisible: "",
  potentialData: [],
  contactID: null,
  isSearchLimitExceed: false,
  subscriptionModalVisible: false,
  setFilteredContacts: (data) => set(() => ({ filteredContacts: data })),
  setOriginalContacts: (data) => set(() => ({ originalContacts: data })),
  setCategories: (data) => set(() => ({ categories: data })),
  setOriginalParsedContacts: (data) =>
    set(() => ({ originalParsedContacts: data })),
  setSearchValue: (data) => set(() => ({ searchValue: data })),
  setPotentialModalVisible: (data) =>
    set(() => ({ potentialModalVisible: data })),
  setPotentialData: (data) => set(() => ({ potentialData: data })),
  setContactID: (data) => set(() => ({ contactID: data })),
  setSearchLimitExceed: (data) => set(() => ({ isSearchLimitExceed: data })),
  setSubscriptionModalVisible: (data) =>
    set(() => ({ subscriptionModalVisible: data })),
  removeAllClosetContactsState: () =>
    set({
      filteredContacts: [],
      originalContacts: [],
      categories: [],
      originalParsedContacts: {},
      searchValue: "",
      potentialModalVisible: "",
      potentialData: [],
      contactID: null,
      isSearchLimitExceed: false,
      subscriptionModalVisible: false,
    }),
}));
