import React, { memo, useState, useEffect, useContext } from "react";
import contactStyles from "./styles";
import { DefaultAvatar } from "../../../../images/svg/default-avatar";
import StarActivated from "../../../../images/svg/starActivated";
import StarDeactivated from "../../../../images/svg/starDeactivated";
import { useMutation } from "react-apollo";
import { SET_PREFERENCE } from "../../../../queries/queries";
import { UserContext } from "../../../../contexts/userContext";
import { capitalizeSearchTermsTag } from "../../../../utils/utils";
import "./style.css";
import SubscriptionPlansModal from "../../../../components/subscriptionPlans/subscriptionPlansModal";
import SearchLimitExceedModal from "../../../../components/searchLimitExceedModal";
import { ModelType } from "../../../../components/searchLimitExceedModal/searchLimitExceedModal";
import Styles from "./contactCard.module.css";

const ContactCard = ({
  name,
  photo,
  isPreferred,
  tags = [],
  styles: propStyles,
  id = "",
  onChangePreferred,
}) => {
  const { user, setUser } = useContext(UserContext);
  const [isSearchLimitExceed, setSearchLimitExceed] = useState(false);
  const [subscriptionModalVisible, setSubscriptionModalVisible] =
    useState(false);

  let hasPicture = false;
  if (photo) hasPicture = typeof photo == "string" ? true : false;
  const hasTags = Boolean(tags.length > 0);
  const [status, setStatus] = useState(isPreferred);
  const [setPreferenceContacts, { data, loading, error }] = useMutation(
    SET_PREFERENCE,
    {
      onCompleted: (data) => {
        checkWatchedLimit(data?.setPreference?.message);
      },
      onError: (error) => {
        checkWatchedLimit(error?.setPreference?.message);
      },
    }
  );

  const checkWatchedLimit = (message) => {
    if (message?.toLowerCase() === "limit reached") {
      const updateUserInfo = { ...user, watchedLimitExceed: true };
      setUser(updateUserInfo);
      setSearchLimitExceed(true);

      setStatus((status) => {
        isPreferred = false;
        onChangePreferred?.(id, false);
        return false;
      });
    } else {
      setStatus((status) => {
        isPreferred = status;
        onChangePreferred?.(id, status);
        return status;
      });
    }
  };

  const startFreeTrial = async () => {
    setSubscriptionModalVisible(true);
    setSearchLimitExceed(false);
  };
  const cancelPopup = () => {
    setSubscriptionModalVisible(false);
    setSearchLimitExceed(false);
  };

  useEffect(() => {
    setStatus(isPreferred);
  }, [isPreferred]);

  const onPressPreference = () => {
    let data = [
      {
        id: id,
        isPreferred: !status,
      },
    ];
    setPreferenceContacts({
      variables: {
        contacts: data,
      },
    });
    setStatus(!status);
  };

  const Tags = (props) => {
    const { terms } = props;

    const tags =
      !terms.every((element) => element === null) &&
      terms?.map((item) => {
        if (item) {
          return item
            .split(";")
            ?.map((itemTag, index) => (
              <span className="termTextSavedCard">
                {capitalizeSearchTermsTag(itemTag, index)}
              </span>
            ));
        }
      });

    if (tags && tags.length > 0) {
      return <div className="scrollmenuCategory">{tags}</div>;
    } else {
      return <></>;
    }
  };
  return (
    <div className={Styles.container}>
      <div className={hasTags ? Styles.topSectionHas : Styles.topSection}>
        <div className={Styles.txtView}>
          {hasPicture ? (
            <img className={Styles.avatar} src={photo} />
          ) : (
            <DefaultAvatar className={Styles.avatar} />
          )}
          <span className={Styles.name}>{name}</span>
        </div>
        <div className={Styles.startView} onClick={() => onPressPreference()}>
          {status ? <StarActivated /> : <StarDeactivated />}
        </div>
      </div>
      <Tags terms={tags} />
      {isSearchLimitExceed && (
        <SearchLimitExceedModal
          onBackdropPress={() => {
            setSearchLimitExceed(false);
          }}
          startFreeTrial={startFreeTrial}
          goback={cancelPopup}
          modelType={ModelType.WATCHED_SEARCH_LIMIT_MODAL}
        />
      )}

      {subscriptionModalVisible && (
        <SubscriptionPlansModal
          subscriptionModalVisible={subscriptionModalVisible}
          setSubscriptionModalVisible={setSubscriptionModalVisible}
        />
      )}
    </div>
  );
};
export default memo(ContactCard);
