const createStyle = () => {
  const backgroundColor = '#FFF'
  return {
    modal: {},
    container: {
      width: '100%',
      backgroundColor: backgroundColor,
      borderTopRightRadius: 12,
      borderTopLeftRadius: 12,
      marginBottom: 0,
      padding: 0,
      paddingStart: 20,
    },
    filters: {
      flex: 1,
      flexDirection: 'row',
      marginHorizontal: -25,
      flexWrap: 'wrap',
      display: "flex"
    },
    main: {
      position: 'fixed',
      backgroundColor: '#F8FAFF',
      shadowOffset: 1,
      shadowOpacity: 0.5,
      elevation: 3,
      width: "100%",
    },
    content: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: -1,
      marginRight: -1
    },
    buttonsContainer: {
      paddingEnd: 20,
      width: '100%',
      marginBottom: 15,
      flexDirection: 'row',
    },
    selectAllButton: {
      flex: 1,
      marginRight: 18,
    },
    clearButton: {
      flex: 1,
    },
    contactListHeaderContainer: {
      flexDirection: 'row',
      marginVertical: 15,
      alignItems: 'center',
    },
    contactListTitle: {
      fontSize: 21,
      fontWeight: 'bold',
      marginLeft: 15,
      marginRight: 15,
      flex: 1,
      color: "#FFFFFF",
    },
    contactListFiltersContainer: {
      borderColor: '#9EA9C1',
      borderRadius: 5,
      marginEnd: 10,
      borderWidth: 1,
      paddingLeft: 10,
      paddingVertical: 9,
      flexDirection: 'row',
      alignItems: 'center',
    },
    contactListFiltersTitle: {
      marginRight: 15,
      color: "#FFFFFF",
      fontSize: 14,
      fontWeight: '600',
    },
    filtersIcon: {
      height: 20,
      width: 20,
      alignSelf: 'center',
      tintColor: '#697080',
      marginRight: 15,
    },
    filterCount: {
      position: 'absolute',
      backgroundColor: '#E6F2F0',
      alignItems: 'center',
      height: 12,
      width: 12,
      right: 4,
      top: 0,
      justifyContent: 'center',
      borderColor: "#E6F2F0",
      borderStyle: 'solid',
      borderRadius: 32,
      margin: "4px 4px 46px 57px",
      display: "flex",
      zIndex: 3000
    },
    filterCountTxt: {
      fontFamily: 'Proxima Nova',
      fontWeight: '600',
      fontSize: 10,
      color: '#078167',
    },
    filterContainer: {
      marginBottom: 5,
      borderBottomColor: '#E5E5E5',
      borderBottomWidth: 4,
      paddingBottom: 12,
    },
    processingArticleDiv: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      backgroundColor: "#5391F7",
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: 16,
      paddingRight: 16
    },
    processingArticleTxt: {
      color: "#FFFFFF",
      fontSize: 14,
      fontFamily: 'Proxima Nova',
      fontFamily: 400,
      marginRight: 8
    },
    progress: {
      borderRadius: 16,
    },
    progressBox: {
      marginLeft: 16,
      marginRight: 16
    }
  }
};

export default createStyle;