import * as React from "react"
const MetaSearch = (props) => {
  return (
    <svg
      width={25}
      height={32}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 16"
      {...props}
    >
      <g fill={props.selected ? "#142030" : '#b5b9c4'}>
        <path d="M6.307 0 7.96 1.374 3.29 6.998l4.67 5.625-1.653 1.373L.5 6.998 6.307 0ZM17.04 1.374 18.693 0 24.5 6.998l-5.807 6.998-1.653-1.373 4.67-5.625-4.67-5.624Z" />
      </g>
      <g fill={props.selected ? props.iconType == "blackWhite" ? "#1E1E1E" : "#FB8100" : '#b5b9c4'}>
        <path d="M9.28 8.072H7.133V5.925H9.28v2.147ZM15.72 5.925h2.147v2.147H15.72V5.925ZM13.573 8.072h-2.146V5.925h2.146v2.147Z" />
      </g>
    </svg>
  );
}

export default MetaSearch

