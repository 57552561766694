import React, { useRef, useEffect } from "react";
import { useLazyQuery } from "react-apollo";
import { GET_ARTICLE_HISTORY } from "../../../queries/queries";
import Styles from "./recentlyActivty.module.css";
import historyIcon from "../../images/svg/history-icon.svg";
import RecentlyActivtyCard from "./recentlyActivtyCard";
import { recentViewArticles } from "../../../store/recentViewActivityStore";
import getFormatedArticles from "../../../utils/formatters/articleFormatter";
import LoadingBar from "../loadingBar/locadingBar";
import { useNavigate } from "react-router-dom";

const RecentlyActivty = () => {
  const circlRef = useRef();
  const navigate = useNavigate();

  const filteredArticles = recentViewArticles(
    (state) => state.filteredArticles
  );
  const setFilteredArticles = recentViewArticles(
    (state) => state.setFilteredArticles
  );

  const [getFeed, { loading, data }] = useLazyQuery(GET_ARTICLE_HISTORY, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    getFeed({
      variables: { chunkSize: 2000 },
    });
  }, []);

  useEffect(() => {
    if (!loading && data && Array.isArray(data?.getArticleHistory?.articles)) {
      let parsedArticles = getFormatedArticles(data.getArticleHistory.articles);
      if (parsedArticles && parsedArticles.length > 2)
        parsedArticles = parsedArticles.slice(0, 2);
      setFilteredArticles(parsedArticles);
    }
  }, [loading, data]);

  return (
    <>
      {/* {!(!loading && filteredArticles?.length == 0) &&  */}
      <>
        <div
          className={Styles.containerMain}
          onClick={() => {
            navigate("/recentViewActivity");
          }}
        >
          <div className={Styles.containerChild}>
            <div className={Styles.rowsHeader}>
              <img src={historyIcon} />
              <span className={Styles.titleMain}>Recently Viewed Articles</span>
            </div>
            {loading ? (
              <LoadingBar />
            ) : (
              <>
                {filteredArticles && filteredArticles.length > 0 ? (
                  filteredArticles.map((item) => (
                    <RecentlyActivtyCard item={item} />
                  ))
                ) : (
                  <span className={Styles.description}>
                    Find Recently Viewed Articles
                  </span>
                )}
              </>
            )}
          </div>
          <div
            onClick={() => {
              navigate("/recentViewActivity");
            }}
            className={Styles.viewAll}
          >
            View all
          </div>
          <div className={Styles.demoView} ref={circlRef} />
        </div>
      </>
      {/* } */}
    </>
  );
};

export default RecentlyActivty;
