import * as React from "react"

const ThreeDotsVertical = (props) => (

  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    fill="none"
    viewBox="0 0 20 20"
  >
    <path
      fill="#697080"
      fillRule="evenodd"
      d="M12 3.2a2.4 2.4 0 11-4.8 0 2.4 2.4 0 014.8 0zm0 7.2a2.4 2.4 0 11-4.8 0 2.4 2.4 0 014.8 0zM9.6 20a2.4 2.4 0 100-4.8 2.4 2.4 0 000 4.8z"
      clipRule="evenodd"
    ></path>
  </svg>

)

export default ThreeDotsVertical