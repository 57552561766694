import { useState, useEffect } from 'react';
import useRefineSearch from './useRefineSearch';
import useQuestions from './useQuestions';
import { Question, optionsToAnswers, questionsTypes } from './Question';
import {
  setAnswerStatus,
  setValueToOhetAnswer,
  getMetadataFromQuestions,
  getSavedSearchParams,
  getCompleteSearchQuery,
  getUpperCaseMeta,
} from './questionsController';

import {
  shouldSearchQuestion,
  mainQuestion,
  person,
  company,
} from './questionsAnswers';

const isloaded = false;

const useIntialModalData = (initialMetaData, initialSearchType, initialSearchname, initialPotentialMatch = [], mFromBackSearch = false) => {

  const {
    refineSearch,
    data,
    loading,
    error,
    resultTypes,
    getResultTypeFromRefineSearch,
    getQuestionFromRefineSearch,
  } = useRefineSearch();


  const {
    setQuestions,
    questions,
    addNewQuestion,
    addMultipleNewQuestion,
    getSearchParams,
    cleanQuestions,
  } = useQuestions([],[]);

  const [metaData, setMetaData] = useState(initialMetaData)
  const [metaDataArray, setMetaDataArray] = useState([])
  const [qaMetaData, setQAMetaData] = useState({})
  const [searchType, setSarchType] = useState(initialSearchType)
  const [searchName, setSearchType] = useState(initialSearchname)
  const [searchSettings, setSearchSettings] = useState()
  const [loadingData, setLoadingData] = useState(false)
  const [phoneNumberInput, setPhoneNumber] = useState(); 
  const [countryInput, setCountry] = useState(); 
  const [cityInput, setCity] = useState(); 
  const [stateInput, setState] = useState(); 
  const [ageInput, setAge] = useState();

  useEffect(()=>{
    if(metaData){
      let meta = []
      Object.values(metaData).forEach(item=> {
        if(item && item != searchName)
          meta = [...meta,...(Number.isInteger(item) ? [item.toString()] : item.split(';'))]
      })
      meta = meta.map(item=>item?.toLowerCase())
      setMetaDataArray(meta)
    }
  },[metaData])

  useEffect(()=>{
    if(questions && questions.length){
      const searchSettings = {
        initialSearchString: searchName,
        intitialMetadata: getMetadataFromQuestions(questions, JSON.stringify(metaData)),
        initialSearchParams: getSearchParams(searchName, null,
          phoneNumberInput,
          countryInput,
          cityInput,
          stateInput,
          ageInput),
        initialQuestions: (!loadingData && mFromBackSearch) ? [] : questions,
        qaMetaData: JSON.stringify(metaData ?? '{}'),
        initialPotentialMatch: initialPotentialMatch
      }
      setSearchSettings(searchSettings)
      setLoadingData(false)
    }
  },[questions])

  const getModalData = (phoneNumberInput,
    countryInput,
    cityInput,
    stateInput,
    ageInput) => {

    setPhoneNumber(phoneNumberInput)
    setCountry(countryInput)
    setCity(cityInput)
    setState(stateInput)
    setAge(ageInput)

    let variables = {}
    if(searchType === 'PLACE' && metaData?.Place  && metaData?.Place != ''){
      variables = {
        fullname: {
          attribute: "next_attribute",
          meta_data: "{\"Place\":\""+metaData.Place+"New York\",\"placeEntered\":\""+metaData.Place+"\"}",
          value: "Company"
        }
      }
    }else if(searchType === 'COMPANY' && metaData?.Company && metaData?.Company != null){
      variables = {
        fullname: {
          attribute: "next_attribute",
          meta_data: "{\"Company\":\""+metaData.Company+"\"}",
          value: "Company"
        }
      }
    }else if(searchType === 'PERSON' && metaData?.Person && metaData?.Person != null){
      variables = {
        fullname: {
          attribute: "potential_matches",
          meta_data: "{\"Person\":\""+metaData.Person+"\"}",
          value: "No"
        }
      }
    }else{
      variables = {
        fullname: {
          attribute: "potential_matches",
          meta_data: "{\"Person\":\""+searchName+"\"}",
          value: "No"
        }
      }
    }
    const query = {
      variables,
    }
    cleanQuestions()
    refineSearch(query);
    setLoadingData(true)
  }

  const getModalDataForMetaTag = (searchType,metaData,searchName,
    phoneNumberInput,
    countryInput,
    cityInput,
    stateInput,
    ageInput) => {

    setMetaData(metaData)
    setSarchType(searchType)
    setSearchType(searchName)
    setPhoneNumber(phoneNumberInput)
    setCountry(countryInput)
    setCity(cityInput)
    setState(stateInput)
    setAge(ageInput)

    let variables = {}
    if(searchType === 'PLACE' && metaData?.Place  && metaData?.Place != ''){
      variables = {
        fullname: {
          attribute: "next_attribute",
          meta_data: "{\"Place\":\""+metaData.Place+"New York\",\"placeEntered\":\""+metaData.Place+"\"}",
          value: "Company"
        }
      }
    }else if(searchType === 'COMPANY' && metaData?.Company && metaData?.Company != null){
      variables = {
        fullname: {
          attribute: "next_attribute",
          meta_data: "{\"Company\":\""+metaData.Company+"\"}",
          value: "Company"
        }
      }
    }else if(searchType === 'PERSON' && metaData?.Person && metaData?.Person != null){
      variables = {
        fullname: {
          attribute: "potential_matches",
          meta_data: "{\"Person\":\""+metaData.Person+"\"}",
          value: "No"
        }
      }
    }else{
      variables = {
        fullname: {
          attribute: "potential_matches",
          meta_data: "{\"Person\":\""+searchName+"\"}",
          value: "No"
        }
      }
    }
    const query = {
      variables,
    }
    cleanQuestions()
    refineSearch(query);
    setLoadingData(true)
  }

  useEffect(() => {
    if (!!data && !loading) {
      const resultType = getResultTypeFromRefineSearch();
      if (resultType === resultTypes.QUESTION) {
        const {
          question: label,
          options,
          attribute,
          meta_data,
          potentialMatches,
          QA_data,
        } = getQuestionFromRefineSearch();
        setQAMetaData(meta_data)
        if(attribute === 'form'){
          const mNewQuestion = []
          getSelectedQuestion().forEach(item=>{
            const {
              question: label,
              options,
              attribute,
            } = item
            const answers = options.map(optionsToAnswers);
            mNewQuestion.push(Question(attribute, label, answers))
          })
          addMultipleNewQuestion(mNewQuestion, false);
        }else if (options !== null) {
          let metaOther = metaData?.[attribute]?.toLowerCase() ?? ''
          let mIndex = 1
          const answers = options.map(item => {
              item.status = metaDataArray.includes(item?.item?.toLowerCase())
              item.updated = 1
              if(item.type == questionsTypes.OTHER){
                // metaOther = metaOther.replace(/^;+|;+$/g,'')
                // if(metaOther && metaOther != ''){
                //   item.itemOther = getUpperCaseMeta(metaOther)
                //   item.status = true
                // }
              }else{
                metaOther = item.status ? metaOther.replace(item?.item?.toLowerCase(),'') : metaOther
              }
              return optionsToAnswers(item)
            }
          )

          if(metaOther && metaOther != '' && metaOther?.split(';')?.length >= 1){
            let metaSplited = metaOther?.split(';')
            metaSplited?.forEach((item,index)=>{
              let ans = {
              "label": getUpperCaseMeta(item), // index == metaSplited.length - 1 ? 'Other' : getUpperCaseMeta(item),
              "status": true,
              "type": 'radio_button', //index == metaSplited.length - 1 ? 'textbox' : 'radio_button',
              "updated": mIndex++,
              "value": getUpperCaseMeta(item)
            }
              answers.splice(answers.length - 1, 0, ans)
            })
          }

          if(answers && answers?.length && answers?.length > 1){
            const newQuestion = Question(attribute, label, answers, JSON.parse(meta_data),potentialMatches);
            addNewQuestion(newQuestion,false);
          }else{
            finishCallWithEmpty()
          }
        }else if(QA_data != null && QA_data.length){
          let mNewQuestion = []
          let mIndex = 1
          QA_data.forEach(item=>{
            const {
              question: label,
              options,
              attribute,
            } = item
            let metaOther = metaData?.[attribute]?.toLowerCase() ?? ''
            const answers = options.map((item => {
              item.status = metaDataArray.includes(item?.item?.toLowerCase())
              item.updated = mIndex++
              if(item.type == questionsTypes.OTHER){
                // metaOther = metaOther.replace(/^;+|;+$/g,'')
                // if(metaOther && metaOther != '' && metaOther?.split(';')?.length == 1){
                //   item.itemOther = getUpperCaseMeta(metaOther);
                //   item.status = true
                // }
              }else{
                metaOther = item.status ? metaOther.replace(item?.item?.toLowerCase(),'') : metaOther
              }
              return optionsToAnswers(item)
            }
          ));
            if(metaOther && metaOther != '' && metaOther?.split(';')?.length >= 1){
              let metaSplited = metaOther?.split(';')
              metaSplited?.forEach((item,index)=>{
                let ans = {
                "label": getUpperCaseMeta(item), // index == metaSplited.length - 1 ? 'Other' : getUpperCaseMeta(item),
                "status": true,
                "type": 'radio_button', //index == metaSplited.length - 1 ? 'textbox' : 'radio_button',
                "updated": mIndex++,
                "value": getUpperCaseMeta(item)
              }
                answers.splice(answers.length - 1, 0, ans)
              })
            }
            if(answers && answers?.length && answers?.length > 1)
              mNewQuestion.push(Question(attribute, label, answers))
          })
            if(mNewQuestion && mNewQuestion?.length){
              addMultipleNewQuestion(mNewQuestion,false);
            }else{
              finishCallWithEmpty()
            }
        }else{
          finishCallWithEmpty()
        }
      }else{
        finishCallWithEmpty()
      }
    }else if (!!data && !error)  {
      finishCallWithEmpty()
    }
  }, [data, loading]);

  const finishCallWithEmpty = () => {
    {
      setLoadingData(false)
      const searchSettings = {
        initialSearchString: searchName,
        intitialMetadata: metaData,
        initialSearchParams: getSearchParams(searchName, metaData,
          phoneNumberInput,
          countryInput,
          cityInput,
          stateInput,
          ageInput),
        initialQuestions: [],
        qaMetaData: JSON.stringify(metaData ?? '{}'),
        initialPotentialMatch: initialPotentialMatch
      }
      setSearchSettings(searchSettings)
    }
  }

  const getSelectedQuestion = () => {
    let newQuestion = []

    const updatedQuestions = {
        ...mainQuestion,
        options: mainQuestion.options.map(answer => {
          let newStatus = false;
          if(searchType?.toLowerCase() == answer.item.toLowerCase()){
            newStatus = true
          }
          return {
            ...answer,
            status: newStatus,
            updated: 1,
          };
        }),
    }

    if(searchType?.toLowerCase() == 'person'){
      newQuestion = [...[updatedQuestions],...getPersonData(newQuestion)]
    }else if(searchType?.toLowerCase() == 'company' ){
      newQuestion = [...[updatedQuestions],...getCompanyData()]
    }else{
      newQuestion = [updatedQuestions]
    }
    return newQuestion
  }


  const getPersonData = () => {

    return person.map((question,index) => {
      return {
        ...question,
        options: question.options.map((answer) => {
          let newStatus = false;
          let value = ''
          if(index == 0 && answer.item.toLowerCase() == 'other' && metaData?.Place && metaData?.Place != '' && searchType?.toLowerCase() == 'person'){
            newStatus = true
            value = metaData.Place
          }else if(index == 1 && answer.item.toLowerCase() == 'other' && metaData?.Company && metaData?.Company != '' && searchType?.toLowerCase() == 'person'){
            newStatus = true
            value = metaData.Company
          }
          return {
            ...answer,
            status: newStatus,
            itemOther: value,
            updated: 2,
          };
        }),
      };
    })
  }

  const getCompanyData = () => {
    return company(searchName).map((question,index) => {
      return {
        ...question,
        options: question.options.map((answer) => {
          let newStatus = false;
          let value = ''
          if(index == 1 && answer.item.toLowerCase() == 'other' && metaData?.Place && metaData?.Place != '' && searchType?.toLowerCase() == 'company'){
            newStatus = true
            value = metaData.Place
          }
          return {
            ...answer,
            status: newStatus,
            updated: 3,
            itemOther: value,
          };
        }),
      };
    })
  }
  
  return {
    getModalData,
    loadingData,
    error,
    searchSettings,
    getModalDataForMetaTag,
  };
}

export default useIntialModalData