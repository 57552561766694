import { useLazyQuery } from "react-apollo";
import { GET_FE_TOGGLE_SETTINGS } from "./../queries/queries";
import Variables from "./Variables";

const SetToggleSettings = () => {
  const [getToggleSettings, { loading: togglesLoading, data: feTogglesdata }] =
    useLazyQuery(GET_FE_TOGGLE_SETTINGS, {
      fetchPolicy: "network-only",
    });

  const getToggleSettingsData = () => {
    getToggleSettings({ variables: { os: "pwa" } });
  };

  const setToggleSettingsData = (data) => {
    Variables.all_activity_enabled =
      data?.getFEToggleSettings?.allActivityEnabled;
    Variables.linkedin_enabled = data?.getFEToggleSettings?.linkedinEnabled;
    Variables.subscriptions_enabled =
      data?.getFEToggleSettings?.subscriptionsEnabled;
    Variables.info_summary_enabled =
      data?.getFEToggleSettings?.infoSummaryEnabled;
    Variables.potential_match_tags_enabled =
      data?.getFEToggleSettings?.potentialMatchTagsEnabled;
    Variables.saved_searches_tags_enabled =
      data?.getFEToggleSettings?.savedSearchesTagsEnabled;
    Variables.registration_enabled =
      data?.getFEToggleSettings?.registrationEnabled;
  };

  return {
    feTogglesdata,
    getToggleSettingsData,
    setToggleSettingsData,
  };
};

export default SetToggleSettings;
