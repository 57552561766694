import { IconButton, Modal } from "@mui/material";
import { useContext } from "react";
import {
  getNextOnboardingStatus, OnboardingContext,
  OnboardingStates
} from '../../../contexts/onboardingContext';
import DashboardBetaIllustration from "../../../images/svg/dashboardBetaIllustration";
import PreSignUpBackround from "../../../images/svg/pre-sign-up-background-1";
import { GreenBgButton } from "../../buttons/greenBgButton";
import { GreenBorderButton } from "../../buttons/greenBorderButton";
import Styles from './completeTooltip.module.css';
import CloseIcon from "../../../images/svg/closeBlack";
import { UserContext } from "../../../contexts/userContext";


export default function CompleteTooltip({ isWeb }) {

  const { onboardingState, setOnboardingState } = useContext(OnboardingContext);

  const changeOnboardingState = () =>
    setOnboardingState(getNextOnboardingStatus(onboardingState));

  const revisitOnboardingState = () =>
    setOnboardingState(getNextOnboardingStatus(OnboardingStates.revisit));

  const { setUser, user } = useContext(UserContext);

  return (
    <Modal disableAutoFocus={true} open={isWeb ? (onboardingState === OnboardingStates.completed && user.advancedSearchOnboardingLocal) : onboardingState === OnboardingStates.completed} sx={{ justifyContent: 'center', display: 'flex', }}>

      <div className={Styles.body}>

        <header className={Styles.navHeader}>
          <span className={Styles.header}>Tutorial Completed</span>
        </header>

        <DashboardBetaIllustration />

        <PreSignUpBackround
          style={{
            zIndex: -1,
            position: "absolute",
            width: "101.3%",
            marginLeft: -13
          }}
        />

        <span className={Styles.title}>That’s it!</span>

        <span className={Styles.msg}>
          As you use it, Ferret learns about your network and what stories
          matter to you.
        </span>

        <span className={`${Styles.msg} ${Styles.msgSecond}`}>
          Your search and notification accuracy will improve over time and as
          you invite your friends!
        </span>

        <GreenBgButton
          onClick={() => changeOnboardingState()}
          variant="contained"
          style={{
            width: "90%",
            marginTop: 12,
          }}
        >
          <div className={Styles.btnTxt}>Go To my Dashboard</div>
        </GreenBgButton>

        <GreenBorderButton
          variant="contained"
          style={{
            width: "90%",
            marginTop: 12,
          }}
          className={Styles.revisitBtn}
          onClick={() => {
            setUser({ ...user, advancedSearchOnboardingLocal: false })
            revisitOnboardingState()
          }}
        >
          <div className={Styles.btnTxt}>Revisit The Tutorial</div>
        </GreenBorderButton>
      </div>
    </Modal>
  );
}
