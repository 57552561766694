import React, {useContext, useState} from 'react';
import {useMutation} from 'react-apollo';
import { SET_PREFERENCE, DELETE_A_CONTACT } from '../../../queries/queries';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import {UserContext} from '../../../contexts/userContext';
import CloseIconWhite from '../../../images/svg/closeWhite';
import deleteicon from '../../../images/svg/delele-shape.svg'
import StarDeactivated from '../../../images/svg/star-icon--deactivated';
import { GreenBgButton } from '../../buttons/greenBgButton';
import ChangePotentialMatchIcon from '../../../images/svg/change-potential-match-icon';
import StarActivated from '../../../images/svg/starActivated';
import CloseIcon from '../../../images/svg/close_gray';
import Styles from './contactModal.module.css';
import Variables from '../../../utils/Variables';

const ContactModal = (props = {}) => {
  const {
    isVisible,
    onBackdropPress,
    onDeleteContact,
    showChangePotentialMatch = false,
    savedSearch,
    setUnpaidMemberModal,
  } = props;

  const [setPreferenceContacts] = useMutation(SET_PREFERENCE, {
    onCompleted: (data) => {
      if (data?.setPreference?.message?.toLowerCase() === 'limit reached') {
        let updatedValue = [
          {
            id: savedSearch.id,
            isPreferred: !savedSearch.isPreferred,
          },
        ];
        onBackdropPress(updatedValue[0]);
      }
      props?.checkWatchedLimit(data?.setPreference?.message);
    },
    onError: (error) => {
      props?.checkWatchedLimit(error?.setPreference?.message);
    },
  });
  const [deleteAContact] = useMutation(DELETE_A_CONTACT, {
    onCompleted: (data) => {
    },
    onError: (error) => {
      // Alert('error while deleting saved search');
    },
  });
  const {user} = useContext(UserContext);

  const onPressPreference = () => {
      let data = [
        {
          id: savedSearch.id,
          isPreferred: !savedSearch.isPreferred,
        },
      ];
      setPreferenceContacts({
        variables: {
          contacts: data,
        },
      });
      onBackdropPress(data[0]);
  };

  const onPressDeleteContact = () => {
    deleteAContact({
      variables: {
        contactId: savedSearch.id,
        user: user.id,
      },
    });
    onDeleteContact(savedSearch);
    onBackdropPress();
  };

  return (

    <Dialog
      open={isVisible}
    >
      <div className={Styles.container}> 
        <div style={{display: 'flex', justifyContent: 'flex-end', padding: 8}}>
          <div onClick={(e) => {
            e.stopPropagation();
            onBackdropPress()
          }}><CloseIcon /></div>
        </div> 
        <div
          className={Styles.optionContainer}
          onClick={(e) => {
            e.stopPropagation();
            onPressDeleteContact()
          }}>
          <img src={deleteicon} className={Styles.imageStyle} />
          <span className={Styles.sourceTitle}>Delete Saved Contact</span>
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            onPressPreference()
          }
          }
          className={Styles.optionContainer}>
          <StarActivated className={Styles.imageStyle} />
          {savedSearch?.isPreferred ? (
            <span className={Styles.sourceTitle}>Remove from Watching</span>
          ) : (
            <span className={Styles.sourceTitle}>Save to Watched Contacts</span>
          )}
        </div>
        {showChangePotentialMatch && (
          <div
            onClick={(e) => {
              e.stopPropagation();
              props?.onChangePotentialMatch(savedSearch);
              onBackdropPress();
            }}
            className={Styles.optionContainer}>
            <ChangePotentialMatchIcon className={Styles.imageStyle} />
            <span className={Styles.sourceTitle}>Change Potential Match</span>
          </div>
          )}
        <div onClick={(e) => {
          e.stopPropagation();
          onBackdropPress()
        }} className={Styles.optionContainerBt}>Cancel</div>
      </div>
    </Dialog>
  );
};
export default ContactModal;
