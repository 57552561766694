// React
import { NavLink, useLocation } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
// Constants
import { UserContext } from "../../contexts/userContext";
import { accountOverlayStore } from "../../store/accountOverlayStore";
import NAV_ITEMS from "../constants/navigation.constants";
import ProfilePicture from "../../components/profilePicture/profilePicture";
import { notificationStore } from "../../store/notificationStore";
import { searchResultStore } from "../../store/searchResultStore";
import { searchFormStore } from "../../store/searchFormStore";
import { preferredStore } from "../../store/preferredStore";
import { setUserInfo, userInfo } from "../../root-navigation";
import Variables from "../../utils/Variables";
import { ME, UPDATE_FERRET_USER } from "../../queries/queries";
import { useLazyQuery } from "react-apollo";
import { useMutation } from "@apollo/react-hooks";
import {
  OnboardingContext,
  OnboardingStates,
  getNextOnboardingStatus,
} from "../../contexts/onboardingContext";
import RecentActivityTooltip from "../../components/onboardingTooltips/recentActivityTooltip";
import NewSearchTooltip from "../../components/onboardingTooltips/newSearchToolTip";
import SavedSearchesTooltip from "../../components/onboardingTooltips/savedSearchesTooltip";
import UnpaidMemberPopupModal from "../../components/unpaidMemberModal/unpaidMemberModal";
/**
 * MainNavigation Component
 * @returns {JSX.Element}
 * @constructor
 */
const MainNavigation = ({
  isSearchOverlayVisible,
  setIsSearchOverlayVisible,
  searchClick,
  notificationClick,
}) => {
  const [getUserInfo, { loading, error, data }] = useLazyQuery(ME);
  const [updateUser] = useMutation(UPDATE_FERRET_USER);
  const [unpaidMemberModal, setUnpaidMemberModal] = useState(false);
  const { onboardingState, setOnboardingState } = useContext(OnboardingContext);
  const { user, setUser } = useContext(UserContext);
  const [modalVisible, setModalVisible] = useState(false);
  const tooltips = {};
  const changeOnboardingState = () =>
    setOnboardingState(getNextOnboardingStatus(onboardingState, true));
  const location = useLocation();
  const removeAll = searchResultStore((state) => state.removeAll);
  const removeAllSearchForm = searchFormStore((state) => state.removeAll);
  const preferredRemoveAll = preferredStore((state) => state.removeAll);


  const setOpenSubscription = accountOverlayStore(
    (state) => state.setOpenSubscription
  );

  const openAccount = accountOverlayStore((state) => state.openAccount);
  const setOpenAccount = accountOverlayStore((state) => state.setOpenAccount);

  const setOpenEditProfile = accountOverlayStore(
    (state) => state.setOpenEditProfile
  );

  const openNotification = notificationStore((state) => state.openNotification);
  const setOpenNotification = notificationStore(
    (state) => state.setOpenNotification
  );
  const notificationCount = notificationStore(
    (state) => state.notificationCount
  );
  const setOpenArticle = notificationStore((state) => state.setOpenArticle);
  const setSectionIndex = notificationStore((state) => state.setSectionIndex);

  // Gets router current location
  const LOCATION = useLocation();

  // Navigation items active color
  const ACTIVE_COLOR = "#078167";

  /**
   * Force hides search and account overlays, this is used when clicking on nav items
   */
  const closeOverlays = () => {
    setIsSearchOverlayVisible(false);
    setOpenEditProfile(false);
    setOpenAccount(false);
    setOpenSubscription(false);
    setOpenNotification(false);
    setOpenArticle(false);
    setSectionIndex(0);
    removeAll();
    removeAllSearchForm();
    preferredRemoveAll();
  };

  useEffect(() => {
    if (
      !error &&
      !loading &&
      !!data &&
      data.hasOwnProperty("me") &&
      onboardingState !== OnboardingStates.end
    ) {
      if (onboardingState == null) {
        let u = setUserInfo(data.me);
        setUser({ ...u });
      }
      const isOnboarded = data.me.isOnboarded;
      const notificationOnboardingDone = data.me.notificationOnboardingDone;
      if (isOnboarded && onboardingState != OnboardingStates.filterHeader) {
        Variables.filterOnBoarding = true;
        setOnboardingState(OnboardingStates.end);
      }
      if (isOnboarded && !notificationOnboardingDone) {
        setOnboardingState(OnboardingStates.notificationBannerEnd);
      }
      if (!isOnboarded) {
        setOnboardingState(OnboardingStates.recentActivity);
      }
    }
  }, [loading, data, error]);

  useEffect(() => {
    async function fetchData() {
      if (
        (!userInfo.isOnboarded &&
          onboardingState === OnboardingStates.filterHeader) ||
        (!userInfo.notificationOnboardingDone &&
          (onboardingState === OnboardingStates.end ||
            onboardingState === OnboardingStates.filterHeader))
      ) {
        const res = await updateUser({
          variables: {
            isOnboarded: true,
            notificationOnboardingDone: true, //showing notification tile by default - C4R-5655
            // notificationOnboardingDone: Variables.isContactImported, //expected - if contacts imported then notification boarding will be shown already.
          },
        });
        const newUserInfo = {
          ...userInfo,
          isOnboarded: res.data.updateUser.isOnboarded,
          notificationOnboardingDone:
            res.data.updateUser.notificationOnboardingDone,
        };
        let u = setUserInfo(res.data.updateUser);
        setUser({ ...u });
      }
    }
    fetchData();
  }, [onboardingState]);

  useEffect(() => {
    getUserInfo();
  }, []);

  /**
   * Returns the css classes needed to render a navigation items
   * depending on whether the path is active or not.
   * @param path
   * @returns {string}
   */
  const getItemClasses = (path) => {
    const BASE =
      "block bg-transparent h-[60px] text-[12px] mb-[40px] flex items-center no-underline cursor-pointer";
    return LOCATION.pathname === path &&
      !isSearchOverlayVisible &&
      !openAccount &&
      !openNotification
      ? `${BASE} w-[86px] text-green-primary border-0 border-r-[4px] border-solid border-green-primary`
      : `${BASE} w-[90px] text-white border-0`;
  };

  /**
   * Renders a generic nav item
   * @param navItem
   * @returns {JSX.Element}
   */
  const renderNavItem = (navItem) => {
    return (
      <NavLink
        to={navItem.path}
        key={navItem.path}
        className={getItemClasses(navItem.path)}
        onClick={closeOverlays}
      >
        <span className={`block w-full text-center relative`}>
          {navItem.path === "/notifications" && (
            <small className="flex items-center justify-center border border-solid border-white bg-green-primary text-white rounded-full w-[18px] h-[15px] absolute top-[-2px] right-[20px]">
              99
            </small>
          )}
          <span
            style={{
              "--active": ACTIVE_COLOR,
              "--fill": `${
                LOCATION.pathname === navItem.path &&
                !isSearchOverlayVisible &&
                !openAccount &&
                !openNotification
                  ? "var(--active)"
                  : "white"
              }`,
              paddingLeft: 6,
              paddingRight: 6,
            }}
            dangerouslySetInnerHTML={{ __html: navItem.icons?.normal }}
          ></span>
          <span
            style={{ paddingLeft: 6, paddingRight: 6 }}
            className="block mt-2"
          >
            {navItem.name}
          </span>
        </span>
      </NavLink>
    );
  };

  const renderSavedSearch = (navItem) => {
    return (
      <NavLink
        to={navItem.path}
        key={navItem.path}
        className={getItemClasses(navItem.path)}
        onClick={closeOverlays}
      >
        <span className={`block w-full text-center relative`}>
          {navItem.path === "/notifications" && (
            <small className="flex items-center justify-center border border-solid border-white bg-green-primary text-white rounded-full w-[18px] h-[15px] absolute top-[-2px] right-[20px]">
              99
            </small>
          )}
          <span
            style={{
              "--active": ACTIVE_COLOR,
              "--fill": `${
                LOCATION.pathname === navItem.path &&
                !isSearchOverlayVisible &&
                !openAccount &&
                !openNotification
                  ? "var(--active)"
                  : "white"
              }`,
              paddingLeft: 6,
              paddingRight: 6,
            }}
            dangerouslySetInnerHTML={{ __html: navItem.icons?.normal }}
          ></span>
          <span
            style={{ paddingLeft: 6, paddingRight: 6 }}
            className="block mt-2"
          >
            {navItem.name}
          </span>
        </span>
      </NavLink>
    );
  };

  /**
   * Renders the item for dashboard
   * @param navItem
   */
  const renderDashboardNavItem = (navItem) => {
    return (
      <NavLink
        to={navItem.path}
        key={navItem.path}
        className={getItemClasses(navItem.path)}
        onClick={closeOverlays}
      >
        <span className={`block w-full text-center`}>
          {LOCATION.pathname === navItem.path && !isSearchOverlayVisible ? (
            <img src={navItem.icons?.active} alt={navItem.name} />
          ) : (
            <img src={navItem.icons?.normal} alt={navItem.name} />
          )}
          <span className="block mt-2">{navItem.name}</span>
        </span>
      </NavLink>
    );
  };

  /**
   * Renders the item for search
   * @param navItem
   */
  const renderSearchNavItem = (navItem) => {
    return (
      <button
        key="search"
        className={`${getItemClasses(navItem.path)} ${
          isSearchOverlayVisible
            ? "border-0 border-r-[4px] border-solid border-green-primary"
            : null
        }`}
        onClick={searchClick}
      >
        <span className={`block w-full text-center`}>
          <span
            style={{
              "--active": ACTIVE_COLOR,
              "--fill": `${isSearchOverlayVisible ? "var(--active)" : "white"}`,
            }}
            dangerouslySetInnerHTML={{ __html: navItem.icons?.normal }}
          ></span>
          <span
            className={`block mt-2 ${
              isSearchOverlayVisible ? "text-green-primary" : null
            }`}
          >
            {navItem.name}
          </span>
        </span>
      </button>
    );
  };

  /**
   * Renders the item for account
   * @param navItem
   */
  const renderAccountNavItem = (navItem) => {
    return (
      <button
        key="account"
        className={`${getItemClasses(navItem.path)} ${
          openAccount
            ? "border-0 border-r-[4px] border-solid border-green-primary"
            : null
        }`}
        onClick={() => {
          closeOverlays();
          setOpenAccount(!openAccount);
        }}
      >
        <span
          className={`w-full text-center flex flex-col items-center justify-center`}
        >
          <ProfilePicture
            avatarStyle={{
              width: 32,
              height: 32,
              borderWidth: 2,
              borderStyle: "solid",
              borderColor: openAccount ? "#078167" : "#FFFFFF",
              borderRadius: 16,
            }}
            profilePhoto={user?.profilePhoto}
          />
          <span
            className={`block mt-2 ${
              openAccount ? "text-green-primary" : null
            }`}
          >
            {navItem.name}
          </span>
        </span>
      </button>
    );
  };

  /**
   * Renders a generic nav item
   * @param navItem
   * @returns {JSX.Element}
   */
  const renderNotificationNavItem = (navItem) => {
    return (
      <button
        key="notifications"
        className={`${getItemClasses(navItem.path)}  ${
          openNotification
            ? "border-0 border-r-[4px] border-solid border-green-primary"
            : null
        }`}
        onClick={() => {
          closeOverlays();
          notificationClick();
        }}
      >
        <span className={`block w-full text-center relative`}>
          {notificationCount > 0 && (
            <small className="flex items-center justify-center border border-solid border-white bg-green-primary text-white rounded-full w-[18px] h-[15px] absolute top-[-2px] right-[20px]">
              {notificationCount}
            </small>
          )}
          <span
            style={{
              "--active": ACTIVE_COLOR,
              "--fill": `${openNotification ? "var(--active)" : "white"}`,
              paddingLeft: 6,
              paddingRight: 6,
            }}
            dangerouslySetInnerHTML={{ __html: navItem.icons?.normal }}
          ></span>
          <span
            style={{ paddingLeft: 6, paddingRight: 6 }}
            className={`block mt-2 ${
              openNotification ? "text-green-primary" : null
            }`}
          >
            {navItem.name}
          </span>
        </span>
      </button>
    );
  };
  /**
   * Main render method
   */
  return (
    <>
      {Object.keys(NAV_ITEMS).map((navItem) => {
        switch (NAV_ITEMS[navItem].name) {
          case "Dashboard":
            return LOCATION.pathname == "/dashboard" &&
              onboardingState == OnboardingStates.recentActivity ? (
              <RecentActivityTooltip
                isVisible={true}
                onClick={() => changeOnboardingState()}
                isWeb={true}
              >
                {renderDashboardNavItem(NAV_ITEMS[navItem])}
              </RecentActivityTooltip>
            ) : (
              renderDashboardNavItem(NAV_ITEMS[navItem])
            );

          case "Search":
            return onboardingState == OnboardingStates.newSearch ? (
              <NewSearchTooltip
                isVisible={true}
                onClick={() => changeOnboardingState()}
                isWeb={true}
              >
                {renderSearchNavItem(NAV_ITEMS[navItem])}
              </NewSearchTooltip>
            ) : LOCATION.pathname === "/dashboard" ? null : (
              renderSearchNavItem(NAV_ITEMS[navItem])
            );

          case "Contacts":
            return onboardingState == OnboardingStates.savedSearches ? (
              <SavedSearchesTooltip
                isVisible={true}
                onClick={() => changeOnboardingState()}
                isWeb={true}
              >
                {renderSavedSearch(NAV_ITEMS[navItem])}
              </SavedSearchesTooltip>
            ) : (
              renderSavedSearch(NAV_ITEMS[navItem])
            );

          case "Account":
            return renderAccountNavItem(NAV_ITEMS[navItem]);

          case "Notifications":
            return renderNotificationNavItem(NAV_ITEMS[navItem]);

          default:
            return renderNavItem(NAV_ITEMS[navItem]);
        }
      })}

      {unpaidMemberModal && (
        <UnpaidMemberPopupModal
          onBackdropPress={() => {
            setUnpaidMemberModal(false);
          }}
          upgradeNow={() => {
            setOpenSubscription(true);
          }}
        />
      )}
    </>
  );
};

// Component export
export default MainNavigation;
