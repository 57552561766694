const createStyle = () => {
  const titleTextColor = '#000'
  const paragraphTextColor = '#222F4B'
  const backgroundColor = '#FFF'
  return {
    modal: {
      borderTopRightRadius: 12,
      borderTopLeftRadius: 12,
      marginBottom: 0,
      marginLeft: 15,
      marginRight: 15,
    },
    container: {
      position: 'absolute',
      bottom: 0,
      width: '90%',
      marginLeft: '5%',
      marginRight: '5%',
      backgroundColor: backgroundColor,
      borderTopRightRadius: 12,
      borderTopLeftRadius: 12,
      marginBottom: 0,
      padding: 0,
    },
    content: {
      display: "flex",
      flexDirection: "column",
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginTop: 30,
      marginBottom: 15,
      backgroundColor: backgroundColor,
    },
    mainIcon: {
      marginBottom: 20,
      alignSelf: 'center',
      height:120,
    },
    textQuestion: {
      color: titleTextColor,
      fontFamily: "Proxima Nova",
      fontSize: 24,
      marginLeft: 10,
      marginRight: 10,
      fontWeight: 'bold',
      textAlign: 'center',
      marginBottom: 20,
    },
    textSimple: {
      marginLeft: 10,
      marginRight: 10,
      color: paragraphTextColor,
      fontFamily: "Proxima Nova",
      fontSize: 16,
      textAlign: 'center',
      marginBottom: 20,
    },
    buttonContainer: {
      paddingLeft: 15,
      paddingRight: 15,
    },
    button: {
      marginBottom: 12,
    },
    btTitle: {
      fontWeight: '600',
      fontSize: 16,
      textAlign: 'center',
      fontFamily: "Proxima Nova",
    }
  };
};

export default createStyle;