const { innerWidth: windowWidth, innerHeight: windowHeight } = window;

const getContactsListContainerStyle = () => {
  return {
    contactsListContainer: {
      backgroundColor: "#F8FAFF",
      flex: 1,
      flexDirection: "column",
      marginTop: 15,
      borderTopLeftRadius: 12,
      borderTopRightRadius: 12,
    },
  };
};
const createStyle = () => {
  const headerTextColor = "#FFF";
  const contactsListContainerStyle = getContactsListContainerStyle();
  const termColor = "#1A233B";
  const bg_card = "#FFFFFF";
  return {
    ...contactsListContainerStyle,
    container: {
      flex: 1,
      display: "flex",
      backgroundColor: "#253662",
      flexDirection: "column",
    },
    centeredContentView: {
      alignSelf: "center",
      position: "absolute",
      justifyContent: "center",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    view: {
      justifyContent: "space-between",
      borderRadius: 5,
      padding: 16,
      width: windowWidth / 2 - 20,
      height: windowWidth / 2 - 20,
      backgroundColor: bg_card,
      marginLeft: 5,
      marginRight: 5,
      marginTop: 8,
      marginBottom: 8,
      shadowColor: "#000",
      shadowOffset: {
        width: 4,
        height: 4,
      },
      shadowRadius: 8,
      shadowOpacity: 0.11,
    },
    viewBanner: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      borderRadius: 5,
      backgroundColor: bg_card,
      marginBottom: 8,
      shadowColor: "#000",
      shadowOffset: {
        width: 4,
        height: 4,
      },
      shadowRadius: 8,
      shadowOpacity: 0.11,
      position: "relative",
    },
    viewBanner1: {
      paddingLeft: 15,
      paddingBottom: 15,
      display: "flex",
      justifyContent: "space-between",
      borderRadius: 5,
      backgroundColor: bg_card,
      marginLeft: 5,
      marginRight: 5,
      shadowColor: "#000",
      shadowOffset: {
        width: 4,
        height: 4,
      },
      flexDirection: "column",
      shadowRadius: 8,
      shadowOpacity: 0.11,
      position: "relative",
    },
    viewName: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    profilePicture: {
      height: 30,
      width: 30,
      borderRadius: 15,
      borderColor: "#FFF",
      borderWidth: 2,
      borderStyle: "solid",
    },
    cardIcon: {
      height: 30,
      width: 30,
      borderRadius: 15,
      borderColor: "#AAA",
      borderWidth: 1,
      borderStyle: "solid",
    },
    headerContainer: {
      display: "flex",
      flexDirection: "column",
    },
    titleContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginLeft: 17,
      marginRight: 17,
      marginTop: 15,
    },
    titleText: {
      fontSize: 21,
      color: headerTextColor,
      fontWeight: "bold",
    },
    betaText: {
      fontSize: 14,
      color: "#078167",
      fontWeight: "400",
    },
    title: {
      color: termColor,
      fontSize: 20,
      marginBottom: 10,
      fontWeight: 700,
      fontFamily: "Proxima Nova",
    },
    titleMain: {
      display: "block",
      marginTop: 15,
      marginLeft: 5,
      color: termColor,
      fontSize: 16,
      marginBottom: 10,
      fontWeight: "bold",
    },
    detailsTitle: {
      color: "#697080",
      fontSize: 16,
      fontWeight: 400,
      fontFamily: "Proxima Nova",
    },
    cardName: {
      marginLeft: 4,
      color: "#1A233B",
      fontSize: 12,
      paddingEnd: 10,
    },
    mainIcon: {
      marginLeft: 10,
      marginRight: 10,
      alignSelf: "center",
      resizeMode: "contain",
      width: 80,
      height: 80,
    },
    buttonNotifi: {
      color: "#078167",
      fontSize: 16,
      marginTop: 10,
      fontWeight: "400",
      fontFamily: "Proxima Nova",
    },
    buttonNotifi1: {
      marginBottom: 15,
      color: "#078167",
      fontSize: 14,
      fontWeight: "400",
    },
    activityIndicator: {
      alignSelf: "center",
      position: "absolute",
      justifyContent: "center",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    overlayCard: {
      width: windowWidth / 2 - 20,
      height: windowWidth / 2 - 20,
      position: "absolute",
      marginTop: 6,
      marginLeft: 6,
      alignItems: "flex-start",
      justifyContent: "center",
    },
    lockIcon: {
      width: 24,
      height: 32,
      marginTop: 130,
      marginLeft: 16,
    },
    viewSubscription: {
      borderRadius: 5,
      shadowColor: "#000",
      shadowOffset: {
        width: 4,
        height: 4,
      },
      shadowRadius: 8,
      shadowOpacity: 0.11,
      paddingTop: 16,
      paddingBottom: 16,
      marginTop: 16,
      marginLeft: 4,
      marginRight: 4,
    },
    titleSubscription: {
      color: "#FFFFFF",
      fontSize: 15,
      lineHeight: 18,
      letterSpacing: -0.01,
      fontWeight: "600",
      fontFamily: "Proxima Nova",
      marginTop: 16,
    },
    detailSubscription: {
      color: "#EFF1F5",
      fontSize: 12,
      lineHeight: 14,
      letterSpacing: -0.01,
      marginTop: 4,
      fontWeight: "400",
      fontFamily: "Proxima Nova",
    },
    premiumButton: {
      width: 140,
      height: 30,
      marginTop: 24,
      borderRadius: 6,
      marginBottom: 16,
    },
    subscriptionLogo: {
      justifyContent: "center",
      alignContent: "center",
    },
  };
};

export default createStyle;
