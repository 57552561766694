import React, { useState, useEffect, useRef, useContext } from "react";
import SavedSearchCardWithQuery from "../savedSearchCardWithQuery/savedSearchCardWithQuery";
import parseFerretContactsForContactsComponent from "../../utils/ferret-contact-parser";
import FiltersModal from "./filtersModal";
import _ from "lodash";
import ContactImportAlert from "../noContactsAlert/ContactImportAlert";
import Variables from "../../utils/Variables";
import ImportContactsConfirmModal from "../importContactsConfirmModel/importContactsConfirmModel";
import InfoIcon from "../../images/svg/info";
import MissingDetails from "../../images/svg/missingDetails";
import FilterHeader from "./filterHeader";
import { UserContext } from "../../contexts/userContext";
import CloseIconWhite from "../../images/svg/closeWhite";
import { UPDATE_CONTACT_IMPORT_FLAG } from "../../queries/queries";
import { useMutation } from "react-apollo";
import { CircularProgress } from "@mui/material";
import parseSectionListHeight from "../../utils/parseSectionListHeight";
import SearchHeader from "../SearchHeader/SearchHeader";
import SavedSearchCard from "../savedSearchCard/savedSearchCard";
import useSaveRecentSearch from "../../utils/apis/useSaveRecentSearch";
import { useNavigate } from "react-router-dom";
import SearchLimitExceedModal from "../searchLimitExceedModal";
import SubscriptionPlansModal from "../subscriptionPlans/subscriptionPlansModal";
import { ModelType } from "../searchLimitExceedModal/searchLimitExceedModal";
import { AlphabetScroll } from "../alphabetScroll/alphabetScroll";
import { useInView } from "react-intersection-observer";
import { isEqualOrHigherThanIphoneX } from "../../utils/isEqualOrHigherThanIphoneX";
import { savedSearchesStore } from "../../store/savedSearchesStore";
import Styles from "./savedSearches.module.css";
import ViewOnlyList from "../ViewOnlyList/ViewOnlyList";

let theme;
const defaultProfilePicture = require("../../images/svg/default-avatar.svg");
const FILTER_TIMEOUT = 250;
let lastTimeout = null;

let sectionListItemOffset = [];
const SavedSearches = (props) => {
  let { getPotentialMatches, searchTitle, categories, loadingCard } = props;
  const refsViewOnlyList = useRef([]);
  const savedSearches = savedSearchesStore((state) => state.savedSearches);
  const setSavedSearches = savedSearchesStore(
    (state) => state.setSavedSearches
  );

  const refs = useRef([]);
  const refsList = useRef();
  const navigate = useNavigate();

  const nsavedSearches = savedSearchesStore((state) => state.nsavedSearches);
  const setNsavedSearches = savedSearchesStore(
    (state) => state.setNsavedSearches
  );

  const modalVisible = savedSearchesStore((state) => state.modalVisible);
  const setModalVisible = savedSearchesStore((state) => state.setModalVisible);

  const contactImported = savedSearchesStore((state) => state.contactImported);
  const setContactImported = savedSearchesStore(
    (state) => state.setContactImported
  );

  const contacts = savedSearchesStore((state) => state.contacts);
  const setContacts = savedSearchesStore((state) => state.setContacts);

  const orignalContacts = savedSearchesStore((state) => state.orignalContacts);
  const setOrignalContacts = savedSearchesStore(
    (state) => state.setOrignalContacts
  );

  const riskLevelsStatuses = savedSearchesStore(
    (state) => state.riskLevelsStatuses
  );
  const setRiskLevelsStatuses = savedSearchesStore(
    (state) => state.setRiskLevelsStatuses
  );

  const searchValue = savedSearchesStore((state) => state.searchValue);
  const setSearch = savedSearchesStore((state) => state.setSearch);

  const appliedFilter = savedSearchesStore((state) => state.appliedFilter);
  const setAppliedFilter = savedSearchesStore(
    (state) => state.setAppliedFilter
  );

  const showConfirmModel = savedSearchesStore(
    (state) => state.showConfirmModel
  );
  const setShowConfirmModel = savedSearchesStore(
    (state) => state.setShowConfirmModel
  );

  const isProcessed = savedSearchesStore((state) => state.isProcessed);
  const setProcessed = savedSearchesStore((state) => state.setProcessed);

  const isMissingName = savedSearchesStore((state) => state.isMissingName);
  const setMissingName = savedSearchesStore((state) => state.setMissingName);

  const topRiskHeight = savedSearchesStore((state) => state.topRiskHeight);
  const setTopRiskHeight = savedSearchesStore(
    (state) => state.setTopRiskHeight
  );

  const availableFilterList = savedSearchesStore(
    (state) => state.availableFilterList
  );
  const setAvailableFilter = savedSearchesStore(
    (state) => state.setAvailableFilter
  );

  const selectedFilter = savedSearchesStore((state) => state.selectedFilter);
  const setSelectedFilter = savedSearchesStore(
    (state) => state.setSelectedFilter
  );

  const isSearchLimitExceed = savedSearchesStore(
    (state) => state.isSearchLimitExceed
  );
  const setSearchLimitExceed = savedSearchesStore(
    (state) => state.setSearchLimitExceed
  );

  const subscriptionModalVisible = savedSearchesStore(
    (state) => state.subscriptionModalVisible
  );
  const setSubscriptionModalVisible = savedSearchesStore(
    (state) => state.setSubscriptionModalVisible
  );

  const savedSearchPosition = savedSearchesStore(
    (state) => state.savedSearchPosition
  );
  const setSavedSearchPosition = savedSearchesStore(
    (state) => state.setSavedSearchPosition
  );

  const { user, setUser } = useContext(UserContext);
  const openModal = () => setModalVisible(true);
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });

  const { parseSaveRecentSearch } = useSaveRecentSearch();

  let customStyles =
    (isProcessed || (isMissingName && user.contactImportFlagActive)) && inView
      ? isEqualOrHigherThanIphoneX
        ? { top: "46%", height: "45%" }
        : { top: "59%", height: "38%" }
      : isProcessed || (isMissingName && user.contactImportFlagActive)
      ? { top: "32%", height: "52%" }
      : inView
      ? isEqualOrHigherThanIphoneX
        ? { top: "36%", height: "45%" }
        : {}
      : { top: "20%", height: "58%" };

  const createFilterSection = (title, labels, status = true) => {
    return {
      title: title,
      filters: labels.map((label) => ({
        label: label,
        status: status,
      })),
    };
  };
  const createFilters = (status) => {
    return [
      createFilterSection(
        "Imported Source",
        ["Google", "Personal Contacts", "Saved on Ferret", "iCloud"],
        status
      ),
    ];
  };

  const statusesOfFilters = savedSearchesStore(
    (state) => state.statusesOfFilters
  );
  const setStatusesOfFilters = savedSearchesStore(
    (state) => state.setStatusesOfFilters
  );

  const temporaryStatusesOfFilters = savedSearchesStore(
    (state) => state.temporaryStatusesOfFilters
  );
  const setTemporaryStatusesOfFilters = savedSearchesStore(
    (state) => state.setTemporaryStatusesOfFilters
  );

  useEffect(() => {
    if (statusesOfFilters == null || statusesOfFilters?.length == 0) {
      setStatusesOfFilters(createFilters(true));
      setTemporaryStatusesOfFilters(createFilters(true));
    }
  }, []);
  // const [statusesOfFilters, setStatusesOfFilters] = useState(createFilters(true))
  // const [temporaryStatusesOfFilters, setTemporaryStatusesOfFilters] = useState(statusesOfFilters)

  const onClearPressHandler = () => {
    setTemporaryStatusesOfFilters(createFilters(false));
  };
  const onApplyPressHandler = () => {
    setTemporaryStatusesOfFilters(createFilters(true));
  };
  const setStatusHandler = (key, label) => {
    const newStatusesOfFilters = temporaryStatusesOfFilters.map(
      (filtersOfSection) => {
        const { title, filters } = filtersOfSection;
        if (title === key) {
          return {
            ...filtersOfSection,
            filters: filters.map((filter) => {
              if (filter.label === label) {
                return {
                  ...filter,
                  status: !filter.status,
                };
              }
              return filter;
            }),
          };
        }
        return filtersOfSection;
      }
    );

    setTemporaryStatusesOfFilters(newStatusesOfFilters);
  };

  const [updateContactImportFlag, { data, loading, error }] = useMutation(
    UPDATE_CONTACT_IMPORT_FLAG,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        if (data) {
          const updateUserInfo = {
            ...user,
            contactImportFlagActive: data.contactImportFlagActive,
          };
          setUser(updateUserInfo);
        }
      },
      onError: (err) => {
        console.log(err, "err");
      },
    }
  );

  const callUpdateContactImportFlag = () => {
    updateContactImportFlag({
      variables: {
        contactImportFlagActive: false,
      },
    });
  };

  const filterAction = (isFIlterApply = false) => {
    if (
      nsavedSearches == null ||
      nsavedSearches == undefined ||
      nsavedSearches.length === 0
    )
      return;

    const selectedSources = statusesOfFilters?.[0]?.filters?.filter(({ status }) => status)?.map(({ label }) => label);

    let riskCategorySelected = false;
    setAppliedFilter(selectedSources?.length < 4);
    if (
      !riskLevelsStatuses?.high ||
      !riskLevelsStatuses.low ||
      !riskLevelsStatuses.medium
    ) {
      setAppliedFilter(true);
      riskCategorySelected = true;
    }

    let contacts = { ...orignalContacts };
    let countPush = 0;
    let offsetList = [];
    let availableFilter = [];
    Object.keys(contacts)?.forEach((itemKeys) => {
      let mItem = contacts[itemKeys]?.filter((item) => {
        if (item.typeEmpty) return true;

        const itemData =
          item?.firstName?.toUpperCase() + " " + item?.lastName?.toUpperCase();

        if (searchValue && searchValue?.length) {
          const valueCriteria = searchValue.toUpperCase();
          return (
            selectedSources?.includes(item.originalContact.provider) &&
            itemData?.indexOf(valueCriteria) > -1 &&
            (riskCategorySelected
              ? riskLevelsStatuses[item.originalContact.riskCategory] &&
                item?.isProcessed != false
              : riskLevelsStatuses[item.originalContact.riskCategory])
          );
        } else {
          return (
            selectedSources?.includes(item.originalContact.provider) &&
            (riskCategorySelected
              ? riskLevelsStatuses[item.originalContact.riskCategory] &&
                item?.isProcessed != false
              : riskLevelsStatuses[item.originalContact.riskCategory])
          );
        }
      });
      mItem?.forEach((item) => {
        if (item?.originalContact?.summary) {
          let avFiltre = Object.values(item?.originalContact?.summary)
            .filter((item) => item?.total)
            .map((item) => item?.title);
          item.originalContact.availableFilter = avFiltre;
          availableFilter = [...new Set([...availableFilter, ...avFiltre])];
        }
      });

      if (selectedFilter && selectedFilter?.length)
        mItem = mItem?.filter((item) => {
          if (item.originalContact?.availableFilter) {
            for (
              let i = 0;
              i < item.originalContact?.availableFilter.length;
              i++
            ) {
              if (
                selectedFilter.includes(
                  item.originalContact?.availableFilter[i]
                )
              ) {
                return true;
              }
            }
            return false;
          }
          return false;
        });

      if (mItem && mItem.length) {
        countPush = parseSectionListHeight(
          mItem,
          offsetList,
          countPush,
          topRiskHeight,
          null,
          null,
          Variables?.saved_searches_tags_enabled
        );
        contacts[itemKeys] = mItem;
      } else delete contacts[itemKeys];
    });
    setAvailableFilter(availableFilter);
    setContacts(contacts);
    //setAlphaFilterVisible(offsetList.length === sectionListItemOffset.length)
    if (isFIlterApply) {
      const filteredBySourceSavedSearches = [...nsavedSearches]?.filter(
        ({ provider }) => selectedSources?.includes(provider)
      );
    }
  };

  const onBackdropPressHandler = (updatedRiskLevelsStatuses) => {
    setModalVisible(false);
    setTimeout(() => {
      setRiskLevelsStatuses(updatedRiskLevelsStatuses);
      setStatusesOfFilters(temporaryStatusesOfFilters);
    }, 600);
  };

  const providers = [
    "",
    "Personal Contacts",
    "LinkedIn",
    "Google",
    "iCloud",
    "Facebook",
    "Saved on Ferret",
  ];

  const getFormatedArticles = (articles) => {
    const riskCategories = {
      HIGH: "high",
      MEDIUM: "medium",
      LOW: "low",
      high: "high",
      medium: "medium",
      low: "low",
    };

    return articles
      .sort((a, b) => {
        const aa = `${a.name} ${a.lastName}`.toLowerCase().trim();
        const bb = `${b.name} ${b.lastName}`.toLowerCase().trim();
        return aa.localeCompare(bb);
      })
      .map((article) => {
        if (!article.profilePicture) {
          article.profilePicture = defaultProfilePicture;
        }
        article.riskCategory = riskCategories[article.riskCategory] ?? "low";
        article.provider = Number.isInteger(article.provider)
          ? providers[article?.provider ?? 1]
          : article.provider;
        return article;
      });
  };

  const calcMissingNameinSavedSearches = (savedSearches) => {
    let isMissingNamee = false;
    savedSearches?.map(({ name, lastName }) => {
      if (!name || name == "" || !lastName || lastName == "") {
        isMissingNamee = true;
        return;
      }
    });
    return isMissingNamee;
  };

  useEffect(() => {
    setTimeout(()=> {
      refsList?.current?.scrollTo(0, savedSearchPosition);
    }, 700)
  }, [savedSearches]);

  const handleScroll = (data) => {
    const position = refsList?.current?.scrollTop
    setSavedSearchPosition(position);
  };

  useEffect(() => {
    Variables.savedSearchFilter = false;
    Variables.navigationStackNameForContacts = false;
    setSearch(searchTitle ?? null);
  }, []);

  useEffect(() => {
    filterAction();
  }, [selectedFilter]);

  useEffect(() => {
    filterAction(true);
  }, [statusesOfFilters, riskLevelsStatuses]);

  useEffect(() => {
    let isFound =
      [...(savedSearches ?? [])]?.filter((item) => item.isProcessed === false)
        ?.length > 0;
    let isFoundMissingName = calcMissingNameinSavedSearches(savedSearches);
    setTopRiskHeight(isFound || isFoundMissingName ? 200 : 170);
    setProcessed(isFound);
    setNsavedSearches(savedSearches);
    setMissingName(isFoundMissingName);
  }, [savedSearches]);

  useEffect(() => {
    filterAction(true);
  }, [orignalContacts]);

  useEffect(() => {
    const isContactImported = nsavedSearches?.filter(
      (item) => item.provider !== providers[6]
    )?.length;
    setContactImported(isContactImported);
  }, [nsavedSearches, orignalContacts]);

  useEffect(() => {
    if (nsavedSearches) {
      let tsavedSearches = getFormatedArticles(nsavedSearches ?? []);
      const contacts = parseFerretContactsForContactsComponent(tsavedSearches);
      let dict = {};
      let countPush = 0;
      for (var i = 0; i < contacts.length; i++) {
        let outerObject = contacts[i];
        const coontactSection = outerObject.data;
        countPush = parseSectionListHeight(
          coontactSection,
          sectionListItemOffset,
          countPush,
          topRiskHeight
        );
        dict[outerObject.title.toUpperCase()] = coontactSection;
      }

      setContacts(dict);
      setOrignalContacts(dict);
    }
  }, [nsavedSearches]);

  useEffect(() => {
    if (lastTimeout) {
      clearTimeout(lastTimeout);
    }
    lastTimeout = setTimeout(() => {
      filterAction(false);
    }, FILTER_TIMEOUT);
  }, [searchValue]);

  const onChangePreferred = (data) => {
    if (data) {
      let mContact = { ...contacts };
      Object.values(mContact)?.forEach((item) => {
        const selectedContact = item.findIndex(
          (item) => item?.originalContact?.id === data.id
        );
        if (selectedContact !== -1) {
          item[selectedContact].isPreferred = data.isPreferred;
          item[selectedContact].originalContact.isPreferred = data.isPreferred;
        }
      });
      setOrignalContacts(mContact);
    }
  };

  const onDeleteContact = (data) => {
    if (data) {
      let tsavedSearches = nsavedSearches;
      setSavedSearches(
        tsavedSearches.filter((savedSearch) => savedSearch.id != data?.id)
      );
    }
  };
  const onChangePotentialMatch = (data) => {
    if (data) {
      getPotentialMatches(data?.id);
    }
  };

  const checkWatchedLimit = (message) => {
    if (message?.toLowerCase() === "limit reached") {
      const updateUserInfo = { ...user, watchedLimitExceed: true };
      setUser(updateUserInfo);
      setSearchLimitExceed(true);
    }
  };

  const onPressImport = () => {
    Variables.navigationStackNameForContacts = "SavedSearches";
    const data = {
      screenName: "SavedSearches",
    };
    navigate("/importContactsLanding", { state: JSON.stringify(data) });
  };

  const onFilterTypeClick = (filterList) => {
    setSelectedFilter(
      filterList
        ?.filter((item) => item.count && item.status)
        .map((item) => item.category)
        ?.toString()
    );
  };

  const cancelSearch = () => setSearch("");

  const startFreeTrial = async () => {
    setSubscriptionModalVisible(true);
    setSearchLimitExceed(false);
  };
  const cancelPopup = () => {
    setSubscriptionModalVisible(false);
    setSearchLimitExceed(false);
  };

  let alphaArray = [];
  Object.keys(contacts).map((key) => {
    alphaArray = [...alphaArray, key];
  });

  return (
    <div className={Styles.body}>
      <Header onPress={openModal} appliedFilter={appliedFilter} count={nsavedSearches?.length} />
      <main className={Styles.main}>
        {nsavedSearches == null ? null : nsavedSearches &&
          nsavedSearches.length == 0 ? (
          <ContactImportAlert
            onPress={onPressImport}
            style={{ marginTop: 80 }}
          />
        ) : (
          <>
            {isProcessed && (
              <div className={Styles.processContainer}>
                <div>
                  <InfoIcon color={"#fff"} />
                </div>
                <span className={Styles.processText}>
                  {"Ferret is processing " +
                    ((nsavedSearches &&
                    nsavedSearches.length !== 0 &&
                    !contactImported)
                      ? "saved searches"
                      : "imported contacts") +
                    " for matches across multiple databases. Some search results, verifications, and tags are pending."}
                </span>
              </div>
            )}

            {!isProcessed && isMissingName && user.contactImportFlagActive && (
              <div className={Styles.processContainer2}>
                <div>
                  <MissingDetails color={"#fff"} />
                </div>
                <span
                  className={`${Styles.processText} ${Styles.processTextCSS}`}
                >
                  Some contacts are missing a full name, reducing accuracy of
                  results. Please update these contacts with a full name to
                  improve results.
                </span>
                <div
                  className={Styles.crossButton}
                  onClick={callUpdateContactImportFlag}
                >
                  <CloseIconWhite className={Styles.closeIcon} />
                </div>
              </div>
            )}

            <div className={Styles.searchBarMainView}>
              <SearchHeader
                value={searchValue}
                onChangeText={setSearch}
                onCancel={cancelSearch}
                selectAllButton={false}
                placeholder="Search Contacts"
              />
            </div>

            {nsavedSearches &&
            nsavedSearches.length !== 0 &&
            !contactImported ? (
              <div
                className={Styles.contactMainView}
                onClick={() => setShowConfirmModel(true)}
              >
                <span className={`${Styles.titleMain} ${Styles.importTextCSS}`}>
                  Connect your contact lists to start monitoring
                </span>
                <span
                  className={`${Styles.buttonNotifi} ${Styles.importTextCSS}`}
                >
                  Connect Contacts Lists
                </span>
              </div>
            ) : null}

            <ViewOnlyList
              categories={categories}
              availableFilter={availableFilterList}
              onFilterTypeClick={onFilterTypeClick}
              contacts={contacts}
              refsList={refsList}
              refsSection={refs}
              ref={refsViewOnlyList}
              nsavedSearches={orignalContacts}
              inViewRef={ref}
              onScroll={handleScroll}
              renderHeader={(key) => (
                <p className={Styles.alphaHeaderText}>{key}</p>
              )}
              renderView={(item) => (
                <div>
                  <SavedSearchCardWithQuery
                    getPotentialMatches={getPotentialMatches}
                    newsItem={item.originalContact}
                    style={{
                      marginBottom: 10,
                      marginLeft: 14,
                      height: item.customSize,
                    }}
                    contactModal={onChangePreferred}
                    onDeleteContact={onDeleteContact}
                    checkWatchedLimit={checkWatchedLimit}
                    onChangePotentialMatch={onChangePotentialMatch}
                    showChangePotentialMatch={
                      item?.originalContact?.hasTracerPotentialMatches
                    }
                    key={item.originalContact.id}
                    categories={categories}
                  />
                </div>
              )}
            />
          </>
        )}
      </main>

      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "100vh",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      )}
      {modalVisible && (
        <FiltersModal
          isVisible={modalVisible}
          onBackdropPress={onBackdropPressHandler}
          statusesOfFilters={temporaryStatusesOfFilters}
          setSourceFilterStatus={setStatusHandler}
          onClearPress={onClearPressHandler}
          onApplyPress={onApplyPressHandler}
          // onPress={onPress}
          // riskLevels={riskLevels}
        />
      )}

      {isSearchLimitExceed && (
        <SearchLimitExceedModal
          onBackdropPress={() => {
            setSearchLimitExceed(false);
          }}
          startFreeTrial={startFreeTrial}
          goback={cancelPopup}
          modelType={ModelType.WATCHED_SEARCH_LIMIT_MODAL}
        />
      )}

      {subscriptionModalVisible && (
        <SubscriptionPlansModal
          subscriptionModalVisible={subscriptionModalVisible}
          setSubscriptionModalVisible={setSubscriptionModalVisible}
        />
      )}
      <AlphabetScroll
        onClick={(a) => {
          refsViewOnlyList?.current?.onAplhaFilterSelected(a)
        }}
        alphaArray={alphaArray}
        customStyle={customStyles}
      />
      {showConfirmModel && (
        <ImportContactsConfirmModal
          onBackdropPress={() => setShowConfirmModel(false)}
        />
      )}
    </div>
  );
};

const Header = (props) => {
  const { onPress, appliedFilter, count } = props;

  return (
    <div className={Styles.titleContainer}>
      <span className={Styles.titleText}>Contacts & Saved Searches</span>
      {count > 0 && <div
        className={
          appliedFilter
            ? Styles.contactListFiltersContainerSeleted
            : Styles.contactListFiltersContainer
        }
        onClick={onPress}
      >
        <img
          src={require("../../images/menu-icon.png")}
          className={Styles.filtersIcon}
        />
      </div>}
    </div>
  );
};

export default SavedSearches;
