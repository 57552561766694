import {useState} from 'react';
import { questionsTypes } from '../searchModalV2/Question';

const useQuestions = (initialQuestions,initialPotentialMatch) => {

  const removeIdontKnowLabel = (questions) => {
    let newQuestionsArray = questions.map((q) => {
      q = {
        ...q,
        answers:(q.answers?.filter((a) => a?.label !== "I don't know")),
      };
      return q
    });
    return newQuestionsArray
  };

  const [questions, setQuestions] = useState(removeIdontKnowLabel(initialQuestions));
  const [potentialMatch, setPotentialMatch] = useState(initialPotentialMatch)

  const addNewQuestion = (newQuestion, isMerged = true) => {
    let newQuestions = []
    if(isMerged){
      newQuestions = [
        ...questions,
        getFormatedQuestion(newQuestion),
      ]
    }else{
      newQuestions = [getFormatedQuestion(newQuestion)]
    }
    setQuestions(newQuestions);
  };

  const addMultipleNewQuestion = (newQuestions, isMerged = true)=> {
    newQuestions.map(newQuestion=>getFormatedQuestion(newQuestion))
    let mNewQuestions = []
    if(isMerged){
      mNewQuestions = [
        ...questions,
        ...newQuestions,
      ]
    }else{
      mNewQuestions = [
        ...newQuestions,
      ]
    }
    setQuestions(mNewQuestions);
  };

  const getFormatedQuestion  = newQuestion => {
    if(newQuestion?.answers?.length > 0 
      && newQuestion?.identifier !== 'next_attribute'
      && newQuestion?.identifier !== 'form'
      && newQuestion?.identifier !== 'entity_select'
      && newQuestion?.identifier !== 'no_result'
      && newQuestion?.identifier !== 'from_dossier'
      && newQuestion?.identifier !== 'potential_matches'
      && newQuestion?.identifier !== 'famous_person'
      && newQuestion?.identifier !== 'noResultFound_details'
      && newQuestion?.identifier !== 'noResultFound_SearchType'
      && newQuestion?.identifier !== 'noResultFound_Company_Called'
      && newQuestion?.identifier !== 'noResultFound_Company_Based'
      && newQuestion?.identifier !== 'noResultFound_Person_Live'
      && newQuestion?.identifier !== 'noResultFound_Person_Live_Work'){
      let questionSize = newQuestion.answers.length;
      newQuestion.answers[questionSize] = newQuestion.answers[questionSize - 1]
      newQuestion.answers[questionSize - 1] = {
        "label": "I don't know",
        "status": false,
        "type": "radio_button",
        "value": ""
      }
    }
    return newQuestion
  }; 

  const addPotentialMatches = potential_matches => {
    setPotentialMatch(potential_matches)
  }

  const setAnswerStatus = (indexOfQuestion, indexOfAnswer, value = true) => {
    const updatedQuestions = questions.map((question, index) => {
      if (index <= indexOfQuestion) return question;
      return {
        ...question,
        answers: question.answers.map((answer, index) => {
          let newStatus = value;
          if (index !== indexOfAnswer) newStatus = false;
          let newValue = answer.value;
          if (answer.type === questionsTypes.OTHER) newValue = '';
          return {
            ...answer,
            status: newStatus,
            value: newValue,
          };
        }),
      };
    });
    return updateQuestionAnswered(updatedQuestions, indexOfQuestion);
  };

  const updateQuestionAnswered = (questions, indexOfQuestion) =>
    questions.map((question, index) => {
      if (index !== indexOfQuestion) return question;
      return {
        ...question,
        answered: question.answers.reduce(
          (acc, {status}) => acc || status,
          false,
        ),
      };
    });

  const cleanQuestions = () => {
    setQuestions([])
    setPotentialMatch([])
  }

  const getIndexOfLastAnsweredQuestion = () => {
    let indexOfQuestion = questions.length - 1;
    while (indexOfQuestion >= 0 && !questions[indexOfQuestion].answered)
      indexOfQuestion--;
    if (indexOfQuestion >= 0) return indexOfQuestion;
    return false;
  };

  const getLastAnsweredQuestion = () => {
    const indexOfQuestion = getIndexOfLastAnsweredQuestion();
    if (indexOfQuestion >= 0) {
      const lastAnsweredQuetion = questions[indexOfQuestion];
      return lastAnsweredQuetion;
    }
    return false;
  };

  const getSearchParams = (
    searchString,
    metaData,
    phoneNumberInput,
    countryInput,
    cityInput,
    stateInput,
    ageInput) => {
    const searchParams = []
    
    if(searchString && searchString != null){
      searchParams.push({
        param: searchString,
        isEditable: true,
        updated: 0
      })
    }

    if(phoneNumberInput && phoneNumberInput != null){
      searchParams.push({
        param: phoneNumberInput,
        isEditable: true,
        updated: 1
      })
    }
    if(countryInput && countryInput != null){
      searchParams.push({
        param: countryInput,
        isEditable: true,
        updated: 2
      })
    }
    if(cityInput && cityInput != null){
      searchParams.push({
        param: cityInput,
        isEditable: true,
        updated: 3
      })
    }
    if(stateInput && stateInput != null){
      searchParams.push({
        param: stateInput,
        isEditable: true,
        updated: 4
      })
    }
    if(ageInput && ageInput != null){
      searchParams.push({
        param: ageInput+'',
        isEditable: true,
        updated: 5
      })
    }

    let updatedMeta = 6
    if(metaData && metaData != null && metaData != ''){
      Object.values(metaData)?.forEach(item => {
        item?.split(';')?.forEach(mItem=>{
          if(mItem != searchString && mItem && mItem != ''){
            searchParams.push({
              param: mItem,
              isEditable: false,
              updated: updatedMeta,
              onlyForHeader: true
            })
            updatedMeta++
          }
        })
      })
    }

    if(questions?.length){
      questions.forEach((question, indexOfQuestion) => {
        question.answers.forEach((answers, indexOfAnswer)=>{
          if(answers?.status && answers.value != '' 
            &&  answers.value != "I don't know " &&  answers.value != "No" 
            && (answers?.value != 'Person' || answers.type != 'radio_button') 
            &&  (answers?.value != 'Company' || answers.type != 'radio_button')
            && (answers?.value != 'Provide Details' || answers.type != 'radio_button')
            && (answers?.value != 'Not Now' || answers.type != 'radio_button')
            && (answers?.value != 'Place' || answers.type != 'radio_button')
            && (answers?.value != 'Topic' || answers.type != 'radio_button')
            && answers.type != questionsTypes.OTHER){
            searchParams.push({ param: answers.value, isEditable: false, indexOfQuestion, indexOfAnswer, updated: answers.updated == 5 ? answers.length + 1 : answers.updated })
          }
        })
      })
    }
   const updatedSearchParams=  searchParams.filter((v,i,a)=>a.findIndex(v2=>['param'].every(k=>v2[k] ===v[k]))===i)
    return updatedSearchParams.sort((a, b) => a.updated > b.updated)
  }

  return {
    setQuestions,
    questions,
    addNewQuestion,
    setAnswerStatus,
    getLastAnsweredQuestion,
    cleanQuestions,
    potentialMatch,
    addPotentialMatches,
    addMultipleNewQuestion,
    getSearchParams,
    removeIdontKnowLabel,
  };
};

export default useQuestions;
