import * as React from "react"

const CloseIconGrey = (props) => (
  <svg
    width={12}
    height={12}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m10.643 1.357-9.286 9.286m0-9.286 9.286 9.286"
      stroke="#697080"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default CloseIconGrey
