import { styled } from "@mui/material/styles";
import LoadingButton from '@mui/lab/LoadingButton';

export const GreenBorderButton = styled(LoadingButton)((props) => ({
  fontFamily: 'Proxima Nova',
  fontWeight: 'normal',
  alignItems: "center",
  textAlign: "center",
  color: "#078167",
  borderRadius: 6,
  textTransform: 'none',
  backgroundColor: props?.backgroundColor ?  props?.backgroundColor : '#FFFFFF',
  borderColor: "#078167",
  borderWidth: 1,
  borderStyle: 'solid',
  padding: props?.padding ? props.padding : '10px',
  fontSize: '16px',
  lineHeight: '19px',
  '&:hover': {
    backgroundColor: '#FFFFFF',
    color: "#078167",
  }
}));
