import React, { useContext } from "react";
import ImportedContactsLogo from "../../images/svg/imported-contacts-logo";
import SocialNetworksGrid from "../socialNetworksGrid/socialNetworksGrid";
import _ from "lodash";
import { GreenBgButton } from "../buttons/greenBgButton";
import { useNavigate, useLocation } from "react-router-dom";
import Styles from "./importContact.module.css";
import { UserContext } from "../../contexts/userContext";

const ImportedContacts = () => {
  const location = useLocation();
  let props = location.state ? JSON.parse(location.state) : {};
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  let originalSocialNetworks
  if (props.socialNetworks) {
    originalSocialNetworks = props.socialNetworks
  } else {
    originalSocialNetworks = props.route?.params?.socialNetworks ?? []
  }

  const nextButtonOnClcik = () => {
    if(user?.isSubscribed|| !user?.hasExpired){
      navigate("/importClosestContacts", {
        state: JSON.stringify({ modeCheck: props?.modeCheck }),
      })
    }else{
      if(props?.modeCheck == 'signup'){
        navigate("/dashboard", { replace: true });
      }else{
        navigate("/dashboard", {
          replace: true,
          state: {
            screen: "Contacts",
            searchTitle: "",
          },
        });
      }
    }
  }

  const socialNetworks = originalSocialNetworks
    .map((item) => {
      return {
        ...item,
        mode: "compact",
        action: null,
        logoHeight: item.title == "Google Contacts" ? 16 : 20,
        logoWidth: item.title == "Google Contacts" ? 16 : 20,
      };
    })
    .filter((item) => _.isInteger(item.contactSize) && item.contactSize > 0);
  const reducer = (total, item) => {
    const contactSize = Number.isInteger(item.contactSize) ? item.contactSize : 0
    return total + contactSize
  }
  const importedContacts = socialNetworks.reduce(reducer, 0)
  return (
    <>
     <div className={Styles.mainContainer} />
      <div className={Styles.container}>
        <div className={Styles.body}>
          <span className={Styles.title}>
            <span className={Styles.titleGreen}>{`${importedContacts}`}</span>{" "}
            <br></br>
            {"Contacts Selected"}
          </span>
          <ImportedContactsLogo className={Styles.logo} />
          <SocialNetworksGrid
            socialNetworks={socialNetworks}
            isFinalState={true}
          />
          <span className={Styles.description}>
            {
             (user?.isSubscribed|| !user?.hasExpired) ? "We’ll continue to monitor these sources for new contacts. Proceed to set up real-time notificatons about new search results for contact."
              : "With a paid subscription, you will have the ability to monitor these sources for new contacts, and setup real-time notifications about new search results for your contacts."
            }
          </span>
        </div>
      </div>
      <div className={Styles.footer}>
        <GreenBgButton
          onClick={nextButtonOnClcik}
          fullWidth
          variant="contained"
        >
          <div>{(user?.isSubscribed|| !user?.hasExpired) ? "Next" : (props?.modeCheck == 'signup' ? "Go To Dashboard" : "See My Contacts")}</div>
        </GreenBgButton>
      </div>
    </>
  )
}

export default ImportedContacts
