import React from "react";
import createStyle from "./styles";
import createStyleWeb from "./stylesWeb";
import {
  capitalizeCase,
} from "../../../utils/utils";

const Tag = (props) => {
  const {
    label,
    status,
    onPress,
    selectable = false,
    disabled = false,
    tagStyle = {},
    aka,
    groupName,
    isFromFilter,
    isWeb = false,
  } = props;
  if (!label || label == "") return <></>;

  const styles = isWeb ? createStyleWeb(disabled, isFromFilter) : createStyle(disabled, isFromFilter)

  if (selectable) {
    return (
      <div onClick={onPress} disabled={disabled} style={styles.paramContainer}>
        <span style={status ? styles.selectedTag : styles.simpleTag}>
          {aka && (
            <span
              style={
                status
                  ? {
                      // ...styles.selectedTag,
                      marginRight: "3px",
                      fontWeight: "600",
                      color: status ? "#949494" : "#949494",
                      textTransform: "uppercase",
                      marginBottom:0
                    }
                  : {
                      // ...styles.simpleTag,
                      marginRight: "3px",
                      fontWeight: "600",
                      color: status ? "#FFF" : "#949494",
                      textTransform: "uppercase",
                      marginBottom:0
                    }
              }
            >
              {aka}  
            </span>
          )}
          {capitalizeCase(label?.toLowerCase(), true)}
        </span>
      </div>
    );
  } else {
    return (
      <span style={{ ...styles.simpleTag, ...tagStyle }}>
        {capitalizeCase(label?.toLowerCase(), true)}
      </span>
    );
  }
};

export default Tag;
