import RemoveAdvanceSearch from "./removeAdvanceSearch";
const getMetaFromNotification = (newsItem) => {
  let metadata = {};
  let requestMetadata = {};
  let searchType = newsItem.searchType;
  let searchTerm = newsItem.searchTerm;
  let searchContext = newsItem.searchContext;
  let tahoeId = newsItem.tahoeId;
  let tracerQueryId = newsItem.tracerQueryId;

  try {
    if (newsItem?.metaData?.Person?.length > 0) {
      metadata.Person = newsItem.metaData.Person?.join(";");
      requestMetadata.Person = newsItem.metaData.Person?.join(";");
    }

    if (newsItem?.metaData?.Company?.length > 0) {
      metadata.Company = newsItem.metaData.Company?.join(";");
      requestMetadata.Company = newsItem.metaData.Company?.join(";");
    }

    if (newsItem?.metaData?.Place?.length > 0) {
      requestMetadata.Place = newsItem.metaData.Place?.join(";");
      metadata.Place = RemoveAdvanceSearch(
        searchContext,
        newsItem.metaData.Place?.join(";")
      );
    }

    let mPlace = "";
    if (searchContext?.city && searchContext?.city != "")
      mPlace = searchContext.city;
    if (searchContext?.state && searchContext?.state != "")
      mPlace =
        mPlace != ""
          ? mPlace + ", " + searchContext.state
          : searchContext.state;
    if (searchContext?.country && searchContext?.country != "")
      mPlace =
        mPlace != ""
          ? mPlace + ", " + searchContext.country
          : searchContext.country;

    if (mPlace && mPlace != "") {
      requestMetadata.Place =
        requestMetadata.Place && requestMetadata.Place != ""
          ? requestMetadata.Place + ";" + mPlace
          : mPlace;
    }

    if (searchType == null || searchType == "")
      searchType = requestMetadata.searchType;
  } catch (e) {
    console.log("e-----", e);
  }

  return {
    metadata,
    searchType,
    searchTerm,
    searchContext,
    tahoeId,
    tracerQueryId,
    requestMetadata,
  };
};
export default getMetaFromNotification;
