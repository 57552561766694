import OnboardingTooltip from "../onboardingTooltip";
import OnboardingPopover from "../onboardingPopover";
import WhiteSearchIcon from "../../../images/svg/whiteSearchIcon";

const AdvancedSearchTooltip1 = ({
  showAdvancedSearchTooltip1,
  setShowAdvancedSearchTooltip1,
  setShowAdvancedSearchTooltip2,
  isWeb,
  showDisclaimer
}) => {
  return (
    <OnboardingTooltip
      title={
        <OnboardingPopover
          title="Advanced Search (1 of 3)"
          content="Use Advanced Search to search with more detail and improve results. Tap 'ADVANCED' to collapse search input."
          icon={<WhiteSearchIcon />}
          showCloseIcon={false}
          showNextButton={true}
          onClickNext={() => {
            setShowAdvancedSearchTooltip1(false);
            setShowAdvancedSearchTooltip2(true);
          }}
        />
      }
      componentStyle={{ marginTop: isWeb ? "-8px !important" : "13% !important" }}
      arrowStyles={{ overflow: "inherit" }}
      isVisible={showAdvancedSearchTooltip1}
      placement="bottom"
    ></OnboardingTooltip>
  );
};

export default AdvancedSearchTooltip1;
