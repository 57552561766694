import React, { useRef } from "react";
import { CircularProgress } from "@mui/material";
import Styles from "./locadingBar.module.css";

const LoadingBar = () => {
  return (
    <div className={Styles.loadingbar}>
      <CircularProgress />
    </div>
  );
};

export default LoadingBar;
