import React from 'react';
import Styles from './noSearchResult.module.css';
import LoadFeedAnimation from '../../LoadArticleAnimatiom/loadFeedAnimation';
import NoResultFoundConfirmSvg from './../../../images/svg/no-result-found-confirm';
import NoResultsFoundConfirm from '../../noResultsFoundConfirm/noResultsFoundConfirm';
const ResultNotFoundText = ({searchName,animationFinished, setAnimationFinished, isSavedSearch}) => {

    return (
      <>
       {!isSavedSearch && 
          <>
          <span className={Styles.title} style={[{ marginBottom: 10 }]}>0 Articles</span>
          <LoadFeedAnimation
            finishAnimation={animationFinished}
            onFinishAnimation={setAnimationFinished}
            isSearchModal={true}
            isNoResult={true}
            articleLoading={false}
            showText={false}
          />
        </>
        }
        {(animationFinished || isSavedSearch) ? 
        <>
            {isSavedSearch && <NoResultFoundConfirmSvg className={Styles.image} />}
            <span className={Styles.title} style={[{ marginTop: animationFinished ? 0 : 10 }]}>
            Sorry! No results found related to your search.
          </span>
            <span className={Styles.text}>
            Please provide the details for
              <span className={Styles.name}> {searchName}</span> if you want to save your
            search.
          </span>
        </> : <></>}
      </>
    );
  };

const NoSearchResult = (props) => {

  const {
      questions,
      animationFinished,
      setAnimationFinished,
      isSavedSearch,
      showConfirmSearch = false,
      searchName} = props

    return (
        <div>
          {!showConfirmSearch && (
              <ResultNotFoundText
                isSavedSearch = {isSavedSearch}
                searchName={searchName}
                animationFinished={animationFinished}
                setAnimationFinished={()=>{setAnimationFinished(true)}}/>
          )}
          {showConfirmSearch && (
            <NoResultsFoundConfirm
              questions={questions}
              searchName={searchName}
            />
          )}
        </div>
      );

}
export default NoSearchResult;