import { useEffect, useState, useContext } from "react";
import { UserContext } from "../../contexts/userContext";
import ProfilePicture from "../profilePicture/profilePicture";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import NavHeader from "../navHeader/navHeaderDark";
import { isMobile } from "react-device-detect";
import { EmailField } from "../fields/emailField";
import { SimpleField } from "../fields/simpleField";
import { PasswordField } from "../fields/passwordField";
import { UPDATE_FERRET_USER, DELETE_USER_ACCOUNT } from "./../../queries/queries";
import { useMutation } from "react-apollo";
import { setUserInfo } from "../../root-navigation";
import { PhotoIcon } from "../../images/svg/photoIcon";
import SubscriptionPlansModal from "../subscriptionPlans/subscriptionPlansModal";
import { Box, Modal } from "@mui/material";
import { logout } from '../../utils/auth';
import {
  OnboardingContext,
  getOnboardingInitialStatus,
} from '../../contexts/onboardingContext';
import ProfilePictureModal from "../ProfilePictureModal/profilePictureModal";
import { savedSearchesStore } from "../../store/savedSearchesStore";
import Styles from './editProfile.module.css'
import { IconButton } from "@mui/material";
import back_white from "../../images/svg/back_white.svg";
import { notificationStore } from "../../store/notificationStore";

export default function EditProfile() {
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const [saveBtnEnabled, setSaveBtnEnabled] = useState(false);
  const [firstNameValid, setFirstNameValid] = useState({
    status: null,
    msg: "",
  });
  const [lastNameValid, setLastNameValid] = useState({ status: null, msg: "" });
  const [firstName, setFirstName] = useState(user.givenName);
  const [lastName, setLastName] = useState(user.familyName);
  const [subscriptionModalVisible, setSubscriptionModalVisible] = useState(false)
  const [isVisible, setisVisible] = useState(false);
  const { setOnboardingState } = useContext(OnboardingContext);
  const removeAll = savedSearchesStore((state) => state.removeAll)
  const removeAllNotification = notificationStore((state) => state.removeAll)

  const [userUpdaterMutation, { data, loading, error }] =
    useMutation(UPDATE_FERRET_USER);

  useEffect(() => {
    if (!loading && data) {
      let u = setUserInfo(data.updateUser);
      setUser({ ...u });
      navigate(-1);
    } else if (!loading && error) {
      console.log(error);
    }
  }, [data, loading, error]);

  const checkPersonalDetailsValidation = () => {
    let isValid = false;

    if (firstName.length == 0) {
      setFirstNameValid({ status: false, msg: "First Name is required" });
      isValid = false;
    } else if (!firstName.match(/^(([A-Za-z]|[" "]|[a-z]+['-]?)+)$/)) {
      setFirstNameValid({
        status: false,
        msg: "Please enter a valid first name.",
      });
      isValid = false;
    } else {
      setFirstNameValid({ status: true, msg: "" });
      isValid = true;
    }

    if (lastName.length == 0) {
      setLastNameValid({ status: false, msg: "Last Name is required" });
      isValid = false;
    } else if (!lastName.match(/^(([A-Za-z]|[" "]|[a-z]+['-]?)+)$/)) {
      setLastNameValid({
        status: false,
        msg: "Please enter a valid last name.",
      });
      isValid = false;
    } else {
      setLastNameValid({ status: true, msg: "" });
      isValid = true;
    }
    return isValid;
  };

  useEffect(() => {
    if (firstName !== user.givenName || lastName !== user.familyName)
      setSaveBtnEnabled(true);
    else setSaveBtnEnabled(false);
    checkPersonalDetailsValidation();
  }, [firstName, lastName, user.givenName, user.familyName]);

  function submitForm() {
    const variables = {
      lastName: lastName,
      name: firstName,
    };
    if (user.userType !== "email") variables.email = user.email;
    userUpdaterMutation({
      variables: variables,
    });
  }

  const [DeleteUser, { loading: loadingVal }] = useMutation(
    DELETE_USER_ACCOUNT,
    {
      onCompleted: (data) => {
        removeAll();
        logout().then(() => setOnboardingState(getOnboardingInitialStatus()));
      },
      onError: (error) => {
        console.log(error, "sgfshjdgfhjsdghjshjfgsjdhfsd");
      },
    }
  );

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    textAlign: "center",
    width: "82%",
    height: '29%',
    borderRadius: "4%",
    display: "flex",
    flexDirection: 'column',
    justifyContent: 'space-around',
    padding: '0px'
  };

  const renderDeleteDialog = () => {
    return (
      <Modal
        open={isVisible}
        transparent={true}
      >
        <Box
          className={Styles.buttonTextSmall}
          sx={style}
        >
          <div style={{ fontWeight: 'bold' }}>Delete Account?</div>
          <div style={{ paddingRight: '22px', paddingLeft: '22px' }}>
            Once you delete your account, your profile is permanently removed
            from Ferret and your account record and personal data are deleted
            from your account.
          </div>
          <div>
            <div
              onClick={() => {
                DeleteUser();
                setisVisible(false);
              }}
              className={Styles.deleteEditBtn}
            >
              Yes, Delete
            </div>
          </div>
          <div style={{ color: '#007AFF', paddingBottom: '5px' }} onClick={() => setisVisible(false)}>Cancel</div>
        </Box>
      </Modal>
    );
  };

  return (
    <div className={Styles.component}>
      <header className={Styles.navHeader}>
        <IconButton
          className={Styles["backIconBtn"]}
          onClick={() => {
            navigate(-1);
          }}
        >
          <img src={back_white} alt="back_white" className={Styles.backBtn} />
        </IconButton>
        <div className={Styles.navHeaderTitle}>
          Edit Profile
        </div>
        <button
          className={Styles.savBtn}
          onClick={() => {
            if (saveBtnEnabled) submitForm();
            else navigate(-1);
          }}
        >
          Save
        </button>
      </header>

      <main className={Styles.editProfileBody}>

        {isVisible && renderDeleteDialog()}

        <div className={Styles.profilePhotoDiv}>
          <div className={Styles.profilePhoto}>
            <ProfilePicture
              avatarStyle={{ width: "140px", height: "140px" }}
              profilePhoto={user?.profilePhoto}
            />
          </div>
          <div className={Styles.profilePhotoEdit}>
            <ProfilePictureModal />
          </div>
        </div>

        <div className={Styles.inputFieldBox}>
          <SimpleField
            title="First Name"
            placeholder={"e.g. John"}
            value={firstName}
            setValue={setFirstName}
            fieldValid={firstNameValid}
          />
          <div className={Styles.field}>
            <SimpleField
              title="Last Name"
              placeholder={"e.g. Doe"}
              value={lastName}
              setValue={setLastName}
              fieldValid={lastNameValid}
            />
          </div>
          <div className={Styles.field}>
            <EmailField
              fieldLabel={"Email"}
              email={user.email}
              setEmail={() => { }}
              emailValid={true}
              setEmailValid={() => { }}
              disabled={true}
            />
          </div>
          {user.userType === 'email' && (
            <div
              className={Styles.changePassword}
              onClick={() => navigate("/changePassword")}
            >
              Change Password
            </div>
          )}
          <span
            className={Styles.deleteAccountBtn}
            onClick={() => setisVisible(true)}
          >
            Delete Account
          </span>
        </div>
      </main >
      {subscriptionModalVisible && (
        <SubscriptionPlansModal
          subscriptionModalVisible={subscriptionModalVisible}
          setSubscriptionModalVisible={setSubscriptionModalVisible}
        />
      )}
    </div>
  );
}
