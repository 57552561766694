import { GreenBgButton } from '../buttons/greenBgButton';
import Styles from './contactImportAlert.module.css';

const ContactImportAlert = props => {
  const { startedContact = false, onPress, screenName, searchValue } = props

  return (
    <div className={Styles.bodyNoContact}>
      {searchValue?.length > 0 ?
        <div className={Styles.mainNoContactSearch}>
          <span className={Styles.no_text_search}>
            No information available.
          </span>
          <span className={Styles.no_text_search}>
            Try adjusting filter.
          </span>
        </div>
        :
        <div className={Styles.mainNoContact}>
          <img src={require('../../images/no-results-icon.png')} className={Styles.noContactLogo} alt="No Contact" />
          <div className={Styles.no_contact_text}>{startedContact ? 'Are you ready to select your starred contacts?' : 'You haven’t added any contacts yet.'}</div>
          {!startedContact && <div className={Styles.no_text}>
            Connect your contact lists and start saving your New Searches
          </div>}
          <GreenBgButton
            style={{ marginTop: 27, paddingLeft: 40, paddingRight: 40 }}
            onClick={() => onPress(startedContact)}
          >
            <span className={Styles.no_contact_text_green}>{startedContact ? 'Get Started' : 'Connect Contact Lists'}</span>
          </GreenBgButton>
        </div>}
    </div>
  );
};

export default ContactImportAlert;
