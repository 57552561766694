import { TextField } from "@mui/material";
import { MobileDatePicker, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Styles from './dobField.module.css'

export const DOBField = ({
  value,
  setValue,
  fieldValid,
  renderInput,
  setDobValid
}) => {

  const handlError = (error) => {
    if (error == null) {
      setDobValid({ status: true, msg: "" });
    } else {
      setDobValid({ status: false, msg: "Date must be valid" });
    }
  };

  return (
    <>
      <div className={Styles.fieldLabel}>Date of Birth</div>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          inputFormat="MM/DD/YYYY"
          format="MM/DD/YYYY"
          disableFuture={true}
          showToolbar={false}
          value={value}
          onChange={(newValue) => {
            setValue(newValue)
          }}
          onError={handlError}
          renderInput={(params) => {
            params.inputProps.placeholder = "e.g. MM/DD/YYYY"
            return (<TextField
              {...params}
              fullWidth
              variant="outlined"
              InputProps={{
                sx: {
                  color: "#142030",
                  height: 48,
                  marginTop: 1,
                  backgroundColor: "#FFFFFF",
                  "& ::placeholder": {
                    color: "#8E96A8"
                  }
                }
              }}
              FormHelperTextProps={{ style: { backgroundColor: 'transparent', padding: 0, margin: 0, marginTop: 4 } }}
              error={fieldValid.status === false}
              helperText={fieldValid.msg}
            />)
          }}
        />
      </LocalizationProvider>
    </>
  )
}
