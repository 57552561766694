import React from "react";
import CloseIcon from "../../images/svg/closeWhite";
import Styles from "./onboardingPopover.module.css";

const Onboarding = (props) => {
  const {
    icon,
    title,
    content,
    onClick,
    onClickNext,
    showCloseIcon = true,
    hideLine = false,
    showNextButton = false,
    isFilter = false,
    dontShow,
    openFilter,
    isWeb,
    isLeftMargin = true,
  } = props;

  return (
    <>
      <div className={Styles.header}>
        <div className={Styles.headerTitle}>

          {icon}

          <div className={Styles.headerTitleText} style={{marginLeft: isLeftMargin ? 14 : 0}}>{title}</div>

          {showCloseIcon && (
            <div onClick={onClick} style={{ padding: 6 }}>
              <CloseIcon />
            </div>
          )}

          {showNextButton && (
            <div onClick={onClickNext} className={Styles.headerRightButtonText}>
              <div className={Styles.btnNext} style={{ color: "#fff" }}>
                NEXT
              </div>
            </div>
          )}

        </div>
      </div>

      {!hideLine && <hr className={Styles.headerDivider}></hr>}

      <div className={Styles.contentText}>
        {content}
      </div>

      {isFilter && <div className={Styles.divDontShow}>
        <div onClick={(e) => {
          e.stopPropagation();
          dontShow()
        }}
          className={Styles.rightButton}>
          <span className={Styles.dontButtonText}>{'Don’t show this message'}</span>
        </div>
        { !isWeb && <div className={Styles.filterView} onClick={(e) => {
          e.stopPropagation();
          openFilter()
        }}>
          <span className={Styles.openFilterText}>{'Open Sort & Filter'}</span>
        </div>}
      </div>}
    </>
  );
};

export default Onboarding;
