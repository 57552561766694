import { useState } from 'react'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Styles from "./twoBtnAlert.module.css";

const TwoBtnAlert = ({
  title,
  description,
  leftBtnTxt,
  onLeftBtnClick,
  rightBtnTxt,
  onRightBtnClick,
  isCapitalize,
  showCheckbox,
  checkBoxText,
  setCheckBoxStatus,
  checkBoxStatus,
}) => {
  const [open, setOpen] = useState(true);

  return (
    <>
      <Dialog open={open}>
        {title && (
          <DialogTitle className={Styles.dialogTitle}>{title}</DialogTitle>
        )}
        <DialogContent>
          <DialogContentText>{description}</DialogContentText>
          {showCheckbox && (
            <div className={Styles.checkBoxDiv}>
              <Checkbox
                disabled={false}
                checked={checkBoxStatus}
                onChange={(event) => {
                  setCheckBoxStatus(event.target.checked);
                }}
                color="success"
                sx={{
                  color: "#078167",
                  "&.Mui-checked": {
                    color: "#078167",
                  },
                  paddingLeft: 0,
                }}
              />
              <span className={Styles.checkBoxLabel}>{checkBoxText}</span>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            style={isCapitalize && { textTransform: "capitalize" }}
            onClick={() => {
              setOpen(false);
              onLeftBtnClick();
            }}
          >
            {leftBtnTxt}
          </Button>
          <Button
            style={isCapitalize && { textTransform: "capitalize" }}
            onClick={() => {
              setOpen(false);
              onRightBtnClick();
            }}
          >
            {rightBtnTxt}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TwoBtnAlert;