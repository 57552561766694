import * as React from "react"

const ZoomAa = (props) => {
    return (
        <svg
            width={18}
            height={16}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="m8.428 14.26 3.589-8.376a.446.446 0 0 1 .823 0l3.588 8.377M10.04 10.5h4.777M1.571 14.26 6.794 2.078a.651.651 0 0 1 1.143 0L9 4.478m-5.086 4.32h2.8"
                stroke="#C0C7D6"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

export default ZoomAa
