import { TextField } from "@mui/material";
import { useState } from "react";
import Styles from "./searchComponent.module.css";

export default function SearchTextField({
  value,
  setValue,
  labelValue,
  placeHolderValue,
  inputType = 'text',
  icon,
  onPressBackButton,
  isError,
  errorText,
  refObj,
  isBackicon,
  inputProps,
  setIsFocusedState,
  setIsFocusedCountry
}) {
  const customStylesPlaceHolder = {
    color: isError ? 'red' : "#8E96A8",
    fontFamily: "Proxima Nova",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 16,
  };
  const [label, setLabel] = useState(placeHolderValue);
  const regex = /[!@#$%^&*()_=+{}\[\]:;<>,?~\\/"]/g;
  const [isFocused, setFocused] = useState(false);

  const onChangeSearch = (data) =>{
   setValue(data.replace(regex, ''))
  }

  return (
    <div className={Styles.searchtextField} style={isError ? { borderColor: 'red' } : { borderColor: (isFocused ? "#078167" : "rgba(255, 255, 255, 0.1)") }}>
      {icon && <span className={Styles.searchFiledIcon}>
        {icon}
      </span>}
      <TextField
        id={labelValue}
        autoComplete={'no'}
        inputRef={refObj}
        type={inputType}
        value={value}
        fullWidth
        focused={value || isFocused ? true : false}
        size="small"
        variant="filled"
        defaultValue={value}
        label={isError ? errorText : ((value == '' || value == undefined) ? label : labelValue)}
        onFocus={() => {
          setFocused?.(true)
          setIsFocusedCountry?.(false)
          setIsFocusedState?.(false)
          setLabel?.(labelValue);
        }}
        onBlur={() => {
          setFocused(false)
          setLabel(placeHolderValue);
        }}
        InputLabelProps={{
          style: customStylesPlaceHolder,
        }}
        inputProps={inputProps}
        InputProps={{
          disableUnderline: true,
          style: {textTransform: 'capitalize', backgroundColor: '#283147'},
          sx: {
            input: {
              color: "#FFFFFF",
              fontFamily: "Proxima Nova",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "16px",
              letterSpacing: "-0.01em",
            },
          },
        }}
        onChange={(e) => onChangeSearch(e.target.value)}
      />
    </div>
  );
}
