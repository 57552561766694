
const createStyle = () => {
  const headerTextColor = '#FFF'
  const termColor = '#1A233B'
  const bg_card = '#FFFFFF'
  return {
    contactsListContainer: {
      display: "flex",
      backgroundColor: '#F8FAFF',
      flex: "auto",
      paddingBottom: 15,
      alignItems: "center",
      flexDirection: "column",
      overflowY: "scroll"
    },
    container: {
      display: "flex",
      flexDirection: "column",
      height: "100vh",
    },
    containerHeader: {
      width: '100%',
      display: "flex",
      flexDirection: "column",
    },
    header: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      position: "relative",
    },
    headerText: {
      color: "#142030",
      fontWeight: "700",
      fontSize: 24,
      fontFamily: "Proxima Nova",
      display: "flex",
      justifyContent: "center",
      width: "100%"
    },
    viewBanner: {
      borderRadius: 8,
      borderBottomStyle: "solid",
      borderBottomColor: '#E1E7F2',
      padding: 12,
      borderBottomWidth: 1,
      backgroundColor: bg_card,
    },
    profilePicture: {
      height: 32,
      width: 32,
      borderRadius: 16,
      borderColor: '#FB8100',
      borderWidth: 2,
      borderStyle: "solid"
    },
    title: {
      fontFamily: 'Proxima Nova',
      color: '#142030',
      fontSize: 16,
      fontWeight: 700,
      textTransform: 'capitalize'
    },
    titleSection: {
      backgroundColor: '#F8FAFF',
      paddingTop: 20,
      marginLeft: 16,
      marginBottom: 10,
      color: termColor,
      fontSize: 22,
      fontWeight: 'bold',
    },
    detailsTitle: {
      fontStyle: 'normal',
      color: '#142030',
      fontFamily: 'Proxima Nova',
      fontWeight: 400,
      fontSize: 14,
    },
    time: {
      marginRight: 5,
      fontFamily: 'Proxima Nova',
      color: '#697080',
      fontWeight: 600,
      fontSize: 10,
      textTransform: 'uppercase'
    },
    textView: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: 10,
      flex: 1
    },
    centeredContentView: {
      display: "flex",
      alignSelf: "center",
      position: "absolute",
      justifyContent: "center",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    image: {
      height: 130,
      display: "flex",
      alignSelf: 'center',
      resizeMode: 'contain',
    },
    titleText: {
      color: '#000',
      fontSize: 18,
      marginTop: 20,
      fontWeight: "600"
    },
    descriptionText: {
      color: '#000',
      fontSize: 16,
      marginTop: 15,
      width: "95%",
      textAlign: 'center'
    },
    segmentedControlContainer: {
      height: 30,
      marginTop: 10,
      width: '70%',
      alignSelf: 'center',
      borderColor: '#fff',
      backgroundColor: '#fff',
    },
    segmentedControlButton: {
      borderStyle: "solid",
      backgroundColor: '#EFF1F5',
      borderRadius: 8,
      borderColor: '#EFF1F5',
    },
    segmentedControlText: {
      color: '#8E96A8',
      fontSize: 14,
      fontWeight: '500',
      textTransform: 'capitalize'
    },
    segmentedControlSelectedButton: {
      borderStyle: "solid",
      borderRadius: 8,
      backgroundColor: '#078167',
    },
    segmentedControlSelectedText: {
      color: '#fff',
      textTransform: 'capitalize'
    },
    innnerBorderStyle: {
      color: '#fff',
    },
    notificationListContainer: {
      marginBottom: 40
    }
  }
}

export default createStyle
