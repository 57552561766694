import React from "react";
import Styles from "./recentlyActivty.module.css";
import { titleCase } from "../../../utils/utils";

const RecentlyActivtyCard = ({ item }) => {
  return (
    <div className={Styles.rightTxt}>
      <span className={Styles.cardNameTxt}>{titleCase(item.fullName)}</span>
      {item?.title && <span className={Styles.cardTagTxt}>{item?.title}</span>}
    </div>
  );
};

export default RecentlyActivtyCard;
