import React, { useRef } from "react";
import Styles from "./appWalkthrough.module.css";
import appWalk1 from "../../../images/appWalk1.png";
import appWalk2 from "../../../images/appWalk2.png";
import appWalk3 from "../../../images/appWalk3.png";
import appWalk4 from "../../../images/appWalk4.png";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MagicSliderDots from "react-magic-slider-dots";
import "react-magic-slider-dots/dist/magic-dots.css";

const appWalkthrough = [
  {
    id: 1,
    titleText:
      "Welcome to Ferret, the tool that helps you avoid risk and spot promising opportunities",
    descriptionText:
      "Search engines and social networks aren’t designed for due diligence. Ferret gives everyone safe & easy access to info that’s either too expensive or impossible to find elsewhere online.",
    imageUrl: appWalk1,
  },
  {
    id: 2,
    titleText: "AI + world class data = exclusive relationship intelligence",
    descriptionText:
      "Ferret isn't just a search engine. It’s curated intelligence that includes lost news archives, crime, corporate & legal records, and much more.",
    imageUrl: appWalk2,
  },
  {
    id: 3,
    titleText:
      "Everything you can’t find on popular search engines or social media",
    descriptionText:
      "Is your new contact secretly a billionaire, or wanted for money laundering? Find important info you won’t see on Google about your network or that person you just met.",
    imageUrl: appWalk3,
  },
  {
    id: 4,
    titleText: "Notifications for your saved searches",
    descriptionText:
      "Search for people, companies, and more, and get updates about new records as they happen about risk or opportunities. Find out the whole story.",
    imageUrl: appWalk4,
  },
];

const hasWindow = typeof window !== "undefined";
const deviceWidth = hasWindow ? window.innerWidth : 0;

export default function AppWalkthourgh() {
  const navigate = useNavigate();
  const sliderRef = useRef(null);
  let timer;
  const autoplaySpeed = 2000;
  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    autoplay: true,
    autoplaySpeed: autoplaySpeed,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (dots) => {
      if (dots == 3) {
        timer = setTimeout(() => {
          sliderRef?.current?.slickGoTo(0, false);
        }, autoplaySpeed);
      } else {
        clearInterval(timer);
      }
    },
    appendDots: (dots) => {
      return (
        <div>
          <div>
            <MagicSliderDots
              dots={dots}
              numDotsToShow={appWalkthrough.length}
              dotWidth={30}
            />
          </div>
        </div>
      );
    },
  };

  const _renderItem = (item, index) => {
    const SLIDE_IMAGE_MARGIN = ["79px", "62px", "91px", "61px"];
    const MARGIN = SLIDE_IMAGE_MARGIN[index];

    return (
      <div className={Styles.slide} key={item.id}>
        <img className={Styles.slideImage} src={item?.imageUrl} />
        <div style={{ marginTop: MARGIN }}>
          <span className={Styles.slideTitle}>{item?.titleText}</span>
          <p className={Styles.slideText}>{item?.descriptionText}</p>
        </div>
      </div>
    );
  };

  return (
    <div className={Styles.slidesContainer}>
      <div className={Styles.slidesContainerInner}>
        <Slider ref={sliderRef} {...settings}>
          {appWalkthrough.map((item, index) => _renderItem(item, index))}
        </Slider>
      </div>
    </div>
  );
}
