import * as React from "react"
const All = (props) => {
  return(
    <svg width="16" height="32" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg"   {...props}>
      <path d="M5.66667 2.33333H15.3333C15.5101 2.33333 15.6797 2.2631 15.8047 2.13807C15.9298 2.01305 16 1.84348 16 1.66667C16 1.48986 15.9298 1.32029 15.8047 1.19526C15.6797 1.07024 15.5101 1 15.3333 1H5.66667C5.48986 1 5.32029 1.07024 5.19526 1.19526C5.07024 1.32029 5 1.48986 5 1.66667C5 1.84348 5.07024 2.01305 5.19526 2.13807C5.32029 2.2631 5.48986 2.33333 5.66667 2.33333Z" fill={props.selected ? "#142030" : '#b5b9c4'}/>
      <path d="M15.3333 6.33333H5.66667C5.48986 6.33333 5.32029 6.40357 5.19526 6.5286C5.07024 6.65362 5 6.82319 5 7C5 7.17681 5.07024 7.34638 5.19526 7.4714C5.32029 7.59643 5.48986 7.66667 5.66667 7.66667H15.3333C15.5101 7.66667 15.6797 7.59643 15.8047 7.4714C15.9298 7.34638 16 7.17681 16 7C16 6.82319 15.9298 6.65362 15.8047 6.5286C15.6797 6.40357 15.5101 6.33333 15.3333 6.33333Z" fill={props.selected ? "#142030" : '#b5b9c4'}/>
      <path d="M15.3333 11.6667H5.66667C5.48986 11.6667 5.32029 11.7369 5.19526 11.8619C5.07024 11.987 5 12.1565 5 12.3333C5 12.5101 5.07024 12.6797 5.19526 12.8047C5.32029 12.9298 5.48986 13 5.66667 13H15.3333C15.5101 13 15.6797 12.9298 15.8047 12.8047C15.9298 12.6797 16 12.5101 16 12.3333C16 12.1565 15.9298 11.987 15.8047 11.8619C15.6797 11.7369 15.5101 11.6667 15.3333 11.6667Z" fill={props.selected ? "#142030" : '#b5b9c4'}/>
      <path d="M2.33333 0.666667H1C0.815905 0.666667 0.666667 0.815905 0.666667 1V2.33333C0.666667 2.51743 0.815905 2.66667 1 2.66667H2.33333C2.51743 2.66667 2.66667 2.51743 2.66667 2.33333V1C2.66667 0.815905 2.51743 0.666667 2.33333 0.666667Z" fill={props.selected ? "#142030" : '#b5b9c4'}/>
      <path d="M2.33333 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V2.33333C0 2.59855 0.105357 2.8529 0.292893 3.04044C0.48043 3.22798 0.734784 3.33333 1 3.33333H2.33333C2.59855 3.33333 2.8529 3.22798 3.04044 3.04044C3.22798 2.8529 3.33333 2.59855 3.33333 2.33333V1C3.33333 0.734784 3.22798 0.48043 3.04044 0.292893C2.8529 0.105357 2.59855 0 2.33333 0ZM2.66667 2.33333C2.66667 2.42174 2.63155 2.50652 2.56904 2.56904C2.50652 2.63155 2.42174 2.66667 2.33333 2.66667H1C0.911595 2.66667 0.82681 2.63155 0.764298 2.56904C0.701786 2.50652 0.666667 2.42174 0.666667 2.33333V1C0.666667 0.911595 0.701786 0.82681 0.764298 0.764298C0.82681 0.701786 0.911595 0.666667 1 0.666667H2.33333C2.42174 0.666667 2.50652 0.701786 2.56904 0.764298C2.63155 0.82681 2.66667 0.911595 2.66667 1V2.33333Z" fill={props.selected ? "#142030" : '#b5b9c4'}/>
      <path d="M2.33333 6H1C0.815905 6 0.666667 6.14924 0.666667 6.33333V7.66667C0.666667 7.85076 0.815905 8 1 8H2.33333C2.51743 8 2.66667 7.85076 2.66667 7.66667V6.33333C2.66667 6.14924 2.51743 6 2.33333 6Z" fill={props.selected ? "#142030" : '#b5b9c4'}/>
      <path d="M2.33333 5.33333H1C0.734784 5.33333 0.48043 5.43869 0.292893 5.62623C0.105357 5.81376 0 6.06812 0 6.33333V7.66667C0 7.93188 0.105357 8.18624 0.292893 8.37377C0.48043 8.56131 0.734784 8.66667 1 8.66667H2.33333C2.59855 8.66667 2.8529 8.56131 3.04044 8.37377C3.22798 8.18624 3.33333 7.93188 3.33333 7.66667V6.33333C3.33333 6.06812 3.22798 5.81376 3.04044 5.62623C2.8529 5.43869 2.59855 5.33333 2.33333 5.33333ZM2.66667 7.66667C2.66667 7.75507 2.63155 7.83986 2.56904 7.90237C2.50652 7.96488 2.42174 8 2.33333 8H1C0.911595 8 0.82681 7.96488 0.764298 7.90237C0.701786 7.83986 0.666667 7.75507 0.666667 7.66667V6.33333C0.666667 6.24493 0.701786 6.16014 0.764298 6.09763C0.82681 6.03512 0.911595 6 1 6H2.33333C2.42174 6 2.50652 6.03512 2.56904 6.09763C2.63155 6.16014 2.66667 6.24493 2.66667 6.33333V7.66667Z" fill={props.selected ? "#142030" : '#b5b9c4'}/>
      <path d="M2.33333 11.3333H1C0.815905 11.3333 0.666667 11.4826 0.666667 11.6667V13C0.666667 13.1841 0.815905 13.3333 1 13.3333H2.33333C2.51743 13.3333 2.66667 13.1841 2.66667 13V11.6667C2.66667 11.4826 2.51743 11.3333 2.33333 11.3333Z" fill={props.selected ? "#142030" : '#b5b9c4'}/>
      <path d="M2.33333 10.6667H1C0.734784 10.6667 0.48043 10.772 0.292893 10.9596C0.105357 11.1471 0 11.4015 0 11.6667V13C0 13.2652 0.105357 13.5196 0.292893 13.7071C0.48043 13.8946 0.734784 14 1 14H2.33333C2.59855 14 2.8529 13.8946 3.04044 13.7071C3.22798 13.5196 3.33333 13.2652 3.33333 13V11.6667C3.33333 11.4015 3.22798 11.1471 3.04044 10.9596C2.8529 10.772 2.59855 10.6667 2.33333 10.6667ZM2.66667 13C2.66667 13.0884 2.63155 13.1732 2.56904 13.2357C2.50652 13.2982 2.42174 13.3333 2.33333 13.3333H1C0.911595 13.3333 0.82681 13.2982 0.764298 13.2357C0.701786 13.1732 0.666667 13.0884 0.666667 13V11.6667C0.666667 11.5783 0.701786 11.4935 0.764298 11.431C0.82681 11.3685 0.911595 11.3333 1 11.3333H2.33333C2.42174 11.3333 2.50652 11.3685 2.56904 11.431C2.63155 11.4935 2.66667 11.5783 2.66667 11.6667V13Z" fill={props.selected ? "#142030" : '#b5b9c4'}/>
      </svg>
  )
}

export default All

