import * as React from "react"

const LeftGreenChevron = (props) => (
  <svg
    width={12}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m3.621 11 7.94-7.94A1.5 1.5 0 0 0 9.439.94l-9 9a1.5 1.5 0 0 0 0 2.12l9 9a1.5 1.5 0 0 0 2.122-2.12L3.62 11Z"
      fill="#078167"
    />
  </svg>
)

export default LeftGreenChevron
