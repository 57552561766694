// Images
import ferretLogoLight from "../images/ferretLogoLight.png";
import ferretLogoLightWhite from "../images/svg/logo-ferret-white.svg";
/**
 * Footer Component
 * @returns {JSX.Element}
 * @constructor
 */
function Footer({ isSearchVisible, isLogin, isCenterCopyright }) {
  /**
   * Main render method
   */
  const year = new Date().getFullYear();
  return (
    <>
      <footer className="p-[20px] relative m-[0px]">
        <span
          className={`block ${
            isCenterCopyright
              ? ""
              : isLogin || isSearchVisible
              ? "md:ml-[5%] lg:ml-[30%]"
              : "md:ml-[40%]"
          } ${isLogin ? "text-white" : "text-gray-blue"} text-small`}
        >
          {"Copyright © " + year + " Ferret"}
        </span>
        <img
          src={isLogin ? ferretLogoLightWhite : ferretLogoLight}
          alt="Ferret"
          className="w-[120px] absolute right-[30px]"
        />
      </footer>
    </>
  );
}

// Component export
export default Footer;
