import * as React from "react"

const DropdownArrow = (props) => {
  return (
    <svg
      width={14}
      height={8}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.429 1.3 6.7 6.57a.412.412 0 0 0 .6 0l5.272-5.27"
        stroke={props.disable ? '#C0C7D6' : (props.type == 'show' ? '#5391F7' : '#142030')}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default DropdownArrow
