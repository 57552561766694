import * as React from "react"

const ReportFlag = (props) => (

  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="18"
    fill="none"
    viewBox="0 0 15 18"
  >
    <path
      fill="#697080"
      d="M9.4 2.864l-.4-2H0v17h2v-7h5.6l.4 2h7v-10H9.4z"
    ></path>
  </svg>

)

export default ReportFlag