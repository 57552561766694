import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_FERRET_CONTACT_LIST, GET_SAVED_SEARCHES } from '../../queries/queries';
import parseFerretContactsForContactsComponent from '../../utils/ferret-contact-parser';
import ContactCard from './ContactCard/contactCard';
import SearchHeader from '../SearchHeader/SearchHeader';
import StarredContactModal from '../StarredContactModal/starredContactModal';
import Variables from '../../utils/Variables';
import { GreenBgButton } from '../buttons/greenBgButton';
import { useNavigate, useLocation } from "react-router-dom";
import { IconButton, CircularProgress } from "@mui/material";
import back_white from "../../images/svg/back_white.svg"
import './style.css'
import { AlphabetScroll } from '../alphabetScroll/alphabetScroll';
import LaunchScreenTransition from '../launchScreenTransition/launchScreenTransition'
import Styles from './importClosest.module.css';

const ImportClosestContacts = () => {
  const location = useLocation();
  let props = location.state ? JSON.parse(location.state) : {}
  const refs = useRef([]);
  const refsList = useRef();
  const [searchValue, setSearchValue] = useState();
  const [showStarredModal, setShowStarredModal] = useState(false);
  const [contacts, setContacts] = useState({})
  const [orignalContacts, setOrignalContacts] = useState([])
  const [savedSearchesRef, setsavedSearchesRef] = useState()
  const [showSplash, setShowSplash] = useState(false)
  const navigate = useNavigate();

  const [getFerretContactList, { data, error, loading }] = useLazyQuery(
    GET_FERRET_CONTACT_LIST, {
    fetchPolicy: 'network-only',
  }
  );

  const [getStarContact, { loading: loadingContacts, error: errorContacts, data: dataContacts }] = useLazyQuery(GET_SAVED_SEARCHES, {
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if (!loadingContacts && dataContacts) {
      navigate("/dashboard", {
        replace: true,
        state: { contactData: data, showSplash: true, screen: "Contacts" },
      });
    } else if (!loadingContacts && errorContacts) {
      navigate("/dashboard", {
        replace: true,
        state: { contactData: {}, showSplash: true, screen: "Contacts" },
      });
    }
  }, [loadingContacts, errorContacts, dataContacts])


  useEffect(() => {
    getFerretContactList({
      variables: {
        showSavedSearches: false
      }
    });
  }, [])

  useEffect(() => {
    if (!loading && data?.contactList) {
      setShowStarredModal(true);
    }
  }, [loading, data]);

  const handleChange = text => {
    setSearchValue(text);
  };

  const handleCancel = () => {
    setSearchValue('');
  };

  useEffect(() => {
    if (!loading && data?.contactList?.contacts) {
      const contacts = parseFerretContactsForContactsComponent(
        data.contactList.contacts,
      );
      let dict = {};
      for (var i = 0; i < contacts.length; i++) {
        let outerObject = contacts[i];
        const names = outerObject.data;
        dict[outerObject.title.toUpperCase()] = names;
      }
      setOrignalContacts(dict)
      setContacts(dict)
    }
  }, [data, loading])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      let contacts = { ...orignalContacts }
      if (searchValue != undefined) {
        const valueCriteria = searchValue.toUpperCase();
        Object.keys(contacts)?.forEach(itemKeys => {
          let mItem = contacts[itemKeys]?.filter(item => {
            const itemData = item?.firstName?.toUpperCase() + " " + item?.lastName?.toUpperCase();
            return itemData?.indexOf(valueCriteria) > -1
          })
          if (mItem && mItem.length)
            contacts[itemKeys] = mItem;
          else
            delete contacts[itemKeys]
        })
        setContacts(contacts)
      } 
    }, 1500)

    return () => clearTimeout(delayDebounceFn)
  }, [searchValue]);

  const onChangePreferred = (id, isPreferred) => {
    setOrignalContacts((contacts) => {
      let mContact = { ...contacts }
      Object.values(mContact)?.forEach((item) => {
        const selectedContact = item.findIndex(item => item?.originalContact?.id === id);
        if (selectedContact !== -1) {
          item[selectedContact].isPreferred = isPreferred
        }
      })
      return mContact
    })
  }

  if (!loading && error && !data) {
    return (
      <>
        <span className={Styles.errorMessage}>
          Opps there is an error loading the data
        </span>
      </>
    );
  }

  const renderList = () => {
    try {
      return (<>
        {
          contacts && Object.entries(contacts).map(([key, values], index) => <>{
            <div
              ref={(element) => {
                refs.current[key] = element;
              }}
              key={index}>
              <span className={Styles.alphaHeaderText1}>{key}</span>
              {values && values?.map(item => <ContactCard
                name={`${item?.firstName} ${item?.lastName}`}
                isPreferred={item?.isPreferred}
                styles={Styles.contactCard}
                photo={item?.photo}
                onChangePreferred={onChangePreferred}
                tags={[]}
                id={item?.originalContact?.id}
              />)}
            </div>
          }</>)
        }
      </>)
    }
    catch (e) {
      console.log("importCloset renderList", e)
    }
  }

  let alphaArray = [];
  Object.keys(contacts).map((key) => { alphaArray = [...alphaArray, key] })

  return (
    <div className={Styles.body}>
      {showSplash ? <LaunchScreenTransition /> : <>
        <div className={Styles.header}>
          <div className={Styles.headerView}>
            <img onClick={() => navigate((-1), { replace: true })} src={back_white} alt="back_white" />
            <span className={Styles.headerText}>
              Search Result Notifications
            </span>
          </div>
        </div>
        {(loading || !data) ? (
          <div className="loadingbar"><CircularProgress /></div>
        ) : (
          <>
            <div className={Styles.container}>
              <div className={Styles.header1}>
                <span className={Styles.headerNumber}>
                  {data?.contactList?.contacts?.length}
                </span>
                <span className={Styles.headerTitle}>Imported Contacts</span>
              </div>
              <div className={Styles.mainContainer}>
                <span className={Styles.info}>
                  Select the contacts who you want to monitor. You’ll receive notifications in real time:
                </span>
                <div className={Styles.searchView}>
                  <SearchHeader
                    placeholder={'Search Contacts'}
                    selectAllButton={false}
                    value={searchValue}
                    onChangeText={handleChange}
                      onCancel={handleCancel}
                  />
                </div>
                  <div ref={refsList} className={Styles.listAndAlphabetContainer}>
                    {renderList()}
                  </div>
                </div>
                <AlphabetScroll
                  onClick={(a) =>
                    refs.current[a].scrollIntoView({ behavior: 'smooth' })
                }
                  styles={Styles.marginTop}
                  alphaArray={alphaArray}
                />
              </div>
              <div className={Styles.footer}>
                <GreenBgButton
                  onClick={() => {
                    if (props?.modeCheck == "signup") {
                      navigate("/dashboard", { state: { showSplash: true } });
                    } else if (
                      Variables.navigationStackNameForContacts &&
                      Variables.navigationStackNameForContacts ==
                        "SavedSearches"
                    ) {
                      navigate("/dashboard", {
                        replace: true,
                        state: { screenName: "Contacts", showSplash: true },
                      });
                    } else {
                      getStarContact()
                      setShowSplash(true)
                    }
                  }}
                  fullWidth
                  variant="contained"
                >
                  <div className={Styles.btTxt}>See My Contacts</div>
                </GreenBgButton>
              </div>

            {showStarredModal && <StarredContactModal
              showcloseIcon={true}
              isVisible={showStarredModal}
              onBackdropPress={() => setShowStarredModal(false)}
              onContinuePress={() => setShowStarredModal(false)}
            />}
          </>
        )}
      </>
      }
    </div>
  );
};

export default ImportClosestContacts;
