import React from 'react';
import createStyle from './styles';
import { xml } from '../../images/svg/terms-conditions';
import { GreenBgButton } from '../buttons/greenBgButton';
import { Buffer } from "buffer"
import { Modal } from '@mui/material';
import Slide from '@mui/material/Slide';

const DisclaimerModal = (props) => {
  const {
    onBackdropPress,
    onContinuePress,
  } = props;
  const { innerWidth: width, innerHeight: height } = window
  const style = createStyle();

  const buff = Buffer(xml);
  const base64data = buff.toString('base64');

  return (
    <Modal
      open={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Slide in={true} direction="up">
        <div style={{
          backgroundColor: 'rgba(84, 83, 83, 0.2)',
          position: 'absolute',
          bottom: 0,
          top: 0,
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        }}>
          <div style={style.container}>
            <span style={style.textQuestion}>{'Disclaimer and Consent'}</span>

            <div style={style.content}>
              <div style={{ maxHeight: height - 250, overflowY: "scroll" }}>
                <div style={{ alignItems: 'center', display: "flex", justifyContent: "center" }}>
                  <img src={`data:image/svg+xml;base64,${base64data}`} />
                </div>
                <div style={{ marginTop: 25, ...style.textSimple }}>{'DISCLAIMER: Ferret is a search engine. By using the search engine you consent to us saving the information you provide to improve future searches. We do not compile a profile on you as a user.  You may not use our service or the information it provides to make decisions about consumer credit, employment, insurance, tenant screening, or any other purpose that would require FCRA compliance. Ferret does not provide consumer reports and is not a consumer reporting agency. (as defined by the Fair Credit Reporting Act, 15 USC 1681 et seq., (“FCRA”)) Refer to our Terms of Use and Privacy Policy. '}</div>
                <div style={style.textSimple}>{'By clicking Continue with Search, I give explicit consent to Ferret to save the information provided, and agree that I will not use Ferret to make decisions regarding employment, consumer credit, tenant screening, or any other purpose that would require Fair Credit Reporting Act compliance.'}</div>
              </div>
              <div style={style.button}>
                <GreenBgButton
                  loading={false}
                  onClick={onContinuePress}
                  fullWidth
                  variant="contained"
                >
                  <div style={style.btnTxt}>Continue with Search</div>
                </GreenBgButton>

                <GreenBgButton
                  onClick={() => onBackdropPress(false)}
                  fullWidth
                  variant="contained"
                  style={{ marginTop: 10, backgroundColor: "#C0C7D6", borderColor: 'transparent', color: "#ffffff" }}
                >
                  <div style={style.btnTxt}>Cancel</div>
                </GreenBgButton>
              </div>
            </div>
          </div>
        </div>
      </Slide>
    </Modal>
  );
};

export default DisclaimerModal;