import * as React from "react"

const WhiteSearchIcon = (props) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={8} cy={8} r={8} fill="#fff" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.512 7.124a2.39 2.39 0 0 1-2.388 2.387 2.39 2.39 0 0 1-2.387-2.387 2.39 2.39 0 0 1 2.387-2.387 2.39 2.39 0 0 1 2.388 2.387m2.76 4.312-2.243-2.243c.43-.602.664-1.329.664-2.069a3.573 3.573 0 0 0-3.569-3.568 3.573 3.573 0 0 0-3.568 3.568c0 1.968 1.6 3.569 3.568 3.569.74 0 1.467-.235 2.07-.664l2.242 2.242a.587.587 0 0 0 .836 0 .591.591 0 0 0 0-.835"
      fill="#5391F7"
    />
  </svg>
)

export default WhiteSearchIcon
