// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".importLayout_body__UKwK5 {\n\tposition: relative;\n\tdisplay: flex;\n\tjustify-content: center;\n\theight: 100vh;\n}\n\n.importLayout_bodyMain__UGdK1 {\n\tdisplay: flex;\n\tflex-direction: column;\n}\n\n.importLayout_bodyCloseContacts__H-gzf {\n\tdisplay: flex;\n\tflex-direction: row;\n}\n\n.importLayout_footer__39HEi {\n\tbottom: 0;\n\tmargin-right: 70px;\n\twidth: 100vw;\n\tposition: absolute;\n  }\n  ", "",{"version":3,"sources":["webpack://./src/web/pages/importContacts/importLayout.module.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,aAAa;CACb,uBAAuB;CACvB,aAAa;AACd;;AAEA;CACC,aAAa;CACb,sBAAsB;AACvB;;AAEA;CACC,aAAa;CACb,mBAAmB;AACpB;;AAEA;CACC,SAAS;CACT,kBAAkB;CAClB,YAAY;CACZ,kBAAkB;EACjB","sourcesContent":[".body {\n\tposition: relative;\n\tdisplay: flex;\n\tjustify-content: center;\n\theight: 100vh;\n}\n\n.bodyMain {\n\tdisplay: flex;\n\tflex-direction: column;\n}\n\n.bodyCloseContacts {\n\tdisplay: flex;\n\tflex-direction: row;\n}\n\n.footer {\n\tbottom: 0;\n\tmargin-right: 70px;\n\twidth: 100vw;\n\tposition: absolute;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "importLayout_body__UKwK5",
	"bodyMain": "importLayout_bodyMain__UGdK1",
	"bodyCloseContacts": "importLayout_bodyCloseContacts__H-gzf",
	"footer": "importLayout_footer__39HEi"
};
export default ___CSS_LOADER_EXPORT___;
