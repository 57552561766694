import React, { useEffect, useState } from 'react';
import Label from '../../atoms/label';
import Tag from '../../atoms/tag';
import { questionsTypes } from '../Question';
import { Chip, IconButton, Input, InputAdornment, TextField } from '@mui/material';
import AddOrgsIcon from '../../../images/svg/add-orgs-icon';
import OrgsIcon from '../../../images/svg/orgs-icon';
import Styles from './questionInterface.module.css'
import CloseIcon from '../../../images/svg/close_gray_small';
import { capitalizeQuestionSameCase } from '../../../utils/utils';

const QuestionInterface = props => {
  const {
    question,
    answers,
    onSelectAnswer,
    onEditingOther,
    onSubmitEditingOther,
    identifier,
    isAutoFocus = true,
    isWeb = false,
  } = props;

  const isForm = identifier === 'form';
  const isFamous = ((identifier === 'potential_matches') && answers && answers.length > 0)
  const selectedQUS = answers.findIndex(answer => answer.status && answer.label != "I don't know");

  const [orgsArray, setOrgsArray] = useState([])
  const [inputOrg, setInputOrg] = useState({})

  useEffect(() => {
    const arr = orgsArray.map(item => item.value).join(";")
    onEditingOther(arr)
  }, [orgsArray])


  const answersTags = answers.map(({label, status}, indexOfAnswer) => (
    <Tag
      isWeb={isWeb}
      key={`${question}-${label}`}
      label={label}
      status={status}
      onPress={() => onSelectAnswer(indexOfAnswer)}
      selectable
      disabled={selectedQUS !== -1 && label === "I don't know"}
    />
  ));

  let answersTagsFamousPerson = false;
  if(isFamous){
    answersTagsFamousPerson =  
    <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
    <Tag
      isWeb={isWeb}
      key={`${question}-${answers[0].label}`}
      label={answers[0].label+', Keep Searching'}
      status={answers[0].status}
      onPress={() => onSelectAnswer(0)}
      selectable
    />
    </div>
  }

  let otherInputView = false;

  const handleDelete = (chipToDelete: ChipData) => {
    setOrgsArray((orgs) => orgs.filter((org) => org.key !== chipToDelete.key));
  };

  const onAddOther = () => {
    if (inputOrg && inputOrg.value?.length > 0) {
      setOrgsArray([...orgsArray, inputOrg])
      setInputOrg({ value: "" })
    }
  }

  const selectedAnswer = answers.findIndex(answer => answer.status && answer.type == questionsTypes.OTHER);
  if (selectedAnswer !== -1) {
    const {type, value} = answers[selectedAnswer];
    if (type === questionsTypes.OTHER) {
      otherInputView = (
        <>
          {/* // <Input
        //   autoComplete={'no'}
        //   defaultValue=""
        //   className={Styles.otherQuestionInput}
        //   value={value}
        //   onChange={(e) => onEditingOther(e.target.value)}
        //   onSubmit={() => onSubmitEditingOther(selectedAnswer)}
        //   autoFocus={isAutoFocus}
        //   autoCorrect={false}
        //   spellCheck={false}
        // /> */}
          <TextField
            onKeyDown={(ev)=>{
              if(ev.key === 'Enter')
                onAddOther()
            }}
            fullWidth
            variant="outlined"
            type="text"
            value={inputOrg.value}
            className={Styles["orgsInputField"]}
            InputProps={{
              sx: {
                color: "#142030",
                height: 48,
                backgroundColor: "#FFFFFF",
              },
              startAdornment: (
                <InputAdornment position="end">
                  <OrgsIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={onAddOther}
                  >
                    <AddOrgsIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
            onChange={
              (e) => {
                setInputOrg({
                  key: Date.now(), value: e.target.value
                })
              }
            }
          />
          <div className={Styles.divChip}>
            {orgsArray && orgsArray.map((item) => {
              return <Chip
                label={item.value}
                className={Styles["chips"]}
                onDelete={() => handleDelete(item)}
                deleteIcon={<CloseIcon />}
              />
            })}
          </div>
        </>
      );
    }
  }

  return (
    <div className={Styles.container}>
      <div className={Styles.questionLabelContainer}>
        <Label text={capitalizeQuestionSameCase(question)} />
      </div>
      {isFamous ? answersTagsFamousPerson : 
      <>
        <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
          {answersTags}
        </div>
        {otherInputView}
      </>
      }
    </div>
  );
};

export default QuestionInterface;
