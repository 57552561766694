import { useEffect, useMemo, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/userContext";
import { PasswordField } from "../fields/passwordField";
import { UPDATE_PASSWORD } from "./../../queries/queries";
import { GreenBgButton } from "../buttons/greenBgButton";
import { useMutation } from "react-apollo";
import { logout } from "../../utils/auth";
import {
  OnboardingContext,
  getOnboardingInitialStatus,
} from "../../contexts/onboardingContext";
import { Box, CircularProgress, AppBar, Grid, IconButton } from "@mui/material";
import { BackIcon } from "../../images/svg/back";
import Styles from "./changePassword.module.css";
import back_white from "../../images/svg/back_white.svg";
import ProfilePicture from "../profilePicture/profilePicture";
import ProfilePictureModal from "../ProfilePictureModal/profilePictureModal";

export default function ChangePassword() {
  const navigate = useNavigate();
  
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [currentPasswordValid, setCurrentPasswordValid] = useState({
    status: null,
    msg: "",
  });
  const [newPasswordValid, setNewPasswordValid] = useState({
    status: null,
    msg: "",
  });
  const [confirmNewPasswordValid, setConfirmNewPasswordValid] = useState({
    status: null,
    msg: "",
  });
  const [displayError, setDisplayError] = useState("");
  const [checkFormValidation, setCheckFormValidation] = useState(false);

  const { user, setUser } = useContext(UserContext);

  const [passwordUpdate, { data, loading, error }] =
    useMutation(UPDATE_PASSWORD);

  useEffect(() => {
    if (data && !loading) {
      navigate("/resetPasswordSuccess", {replace: true, state: {openDashboard: true}})
    } else if (error && !loading) {
      if (
        error &&
        error.graphQLErrors &&
        Array.isArray(error.graphQLErrors) &&
        error.graphQLErrors.length > 0
      ) {
        setDisplayError(error.graphQLErrors[0].message);
      } else {
        setDisplayError("Unable to change your password");
      }
      setTimeout(() => {
        setDisplayError("");
      }, 5000);
    }
  }, [data, loading, error]);

  const checkPasswordMatch = () => {
    let isValid = false;
    if (newPassword.length > 0 && confirmNewPassword.length > 0) {
      if (newPassword !== confirmNewPassword) {
        setConfirmNewPasswordValid({
          status: false,
          msg: "Your new and confirm password do not match",
        });
        isValid = false;
      } else {
        setConfirmNewPasswordValid({ status: true, msg: "" });
        isValid = true;
      }
    }
    return isValid;
  };

  useMemo(() => {
    checkPasswordMatch();
  }, [confirmNewPassword]);

  const handleChangePassword = () => {
    setCheckFormValidation(true);
    let isValid = checkPasswordMatch();
    if (
      isValid &&
      currentPasswordValid.status &&
      newPasswordValid.status &&
      confirmNewPasswordValid.status
    ) {
      passwordUpdate({
        variables: {
          newPassword: newPassword,
          oldPassword: currentPassword,
        },
      });
    }
  };

  return (
    <>
      <div className={Styles.component}>
      <header className={Styles.navHeader}>
        <IconButton
          className={Styles.backIconBtn}
          onClick={() => {
            navigate(-1);
          }}
        >
          <img src={back_white} alt="back_white" className={Styles.backBtn} />
        </IconButton>
        <div className={Styles.navHeaderTitle}>Change Password</div>
      </header>
      <main className={Styles.editProfileBody}>

        <div className={Styles.profilePhotoDiv}>
          <div className={Styles.profilePhoto}>
            <ProfilePicture
              avatarStyle={{ width: "140px", height: "140px" }}
              profilePhoto={user?.profilePhoto}
            />
          </div>
          <div className={Styles.profilePhotoEdit}>
            <ProfilePictureModal />
          </div>
        </div>

        <div className={Styles.inputFieldBox}>
          
           <PasswordField
            title="Current Password"
            password={currentPassword}
            setPassword={setCurrentPassword}
            placeholder={"************"}
            passwordValid={currentPasswordValid}
            setPasswordValid={setCurrentPasswordValid}
            checkFormValid={checkFormValidation}
            screenName={"Change Password"}
            showPasswordIcon={true}
          />
          <div className={Styles.field}>
            <PasswordField
            title="New Password"
            password={newPassword}
            setPassword={setNewPassword}
            placeholder={"8 - 32 Characters; Mixed Case"}
            passwordValid={newPasswordValid}
            setPasswordValid={setNewPasswordValid}
            checkFormValid={checkFormValidation}
            showPasswordStrength={true}
            screenName={"Change Password"}
            showPasswordIcon={true}
          />
          </div>
          <div className={Styles.field}>
            <PasswordField
            title="Confirm New Password"
            password={confirmNewPassword}
            setPassword={setConfirmNewPassword}
            placeholder={"8 - 32 Characters; Mixed Case"}
            passwordValid={confirmNewPasswordValid}
            setPasswordValid={setConfirmNewPasswordValid}
            checkFormValid={checkFormValidation}
            screenName={"Change Password"}
            showPasswordIcon={true}
          />
          </div>
          <div className={Styles.changePasswordErrMsg}>{displayError}</div>
					{loading && (
            <Box sx={{ display: "flex" , marginLeft:'45%'}}>
              <CircularProgress />
            </Box>
          )}
        
        </div>
      </main >
      <div className={Styles.changePasswordBox}>
          <GreenBgButton
            onClick={handleChangePassword}
            fullWidth
            variant="contained"
            style={{
              marginTop: 44,
            }}
          >
            <div className="loginBtnTxt">Change Password</div>
          </GreenBgButton>
        </div>
      
      </div>
    </>
  );
}
