import * as React from "react"
const CorporateRecords = (props) => {
    return (
        <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M22.227 28.333a.333.333 0 0 1 .146.214 2.32 2.32 0 0 0 2.294 1.906H28a1 1 0 0 0 1-1V20.12a1.014 1.014 0 0 0-1-1h-3.333a2.187 2.187 0 0 0-1.134.307.346.346 0 0 1-.28 0l-3.813-1.454a3.306 3.306 0 0 0-2.52.134l-1.987.973a.36.36 0 0 1-.306 0 2.961 2.961 0 0 0-2.56-.16L9.48 20a.36.36 0 0 1-.387 0 2.32 2.32 0 0 0-1.76-.813H4a1.013 1.013 0 0 0-1 1v9.333a1 1 0 0 0 1 1h3.333a2.346 2.346 0 0 0 2-1.187.306.306 0 0 1 .28-.173h.427c.078 0 .154.029.213.08l2.667 2.107a1.894 1.894 0 0 0 2.573.133l2.934-2.413a.36.36 0 0 1 .146-.067l3.36-.68a.332.332 0 0 1 .294.013Zm-3.64-1.973c-.507.413-3.694 3.12-4.2 3.547 0 0-.134-.12-3.24-2.574a1.013 1.013 0 0 0-.614-.213H10a.333.333 0 0 1-.333-.333v-4.454a.307.307 0 0 1 .2-.293l2.96-1.333a.187.187 0 0 1 .253.226A2.667 2.667 0 0 0 14.573 24a2.56 2.56 0 0 0 2.08 0 .334.334 0 0 1 .374.08l1.64 1.8a.32.32 0 0 1 .093.24.413.413 0 0 1-.173.24Zm2.426 0a.347.347 0 0 1-.133-.253 2 2 0 0 0-.44-1.16l-2.56-2.827a1.013 1.013 0 0 0-1.12-.253l-.933.386a.614.614 0 0 1-.787-.333c-.147-.387 0-.547.293-.76l2.467-1.2c.305-.116.642-.116.947 0l3.373 1.333a.333.333 0 0 1 .213.307v4.453a.32.32 0 0 1-.266.32l-.787.16a.32.32 0 0 1-.267-.213v.04Z"
          fill={props.selected ? props.iconType =="blackWhite"? "#1E1E1E": "#9C6" : '#b5b9c4'}
        />
        <path
          d="M4.68 1.453v15.334a1.333 1.333 0 1 0 2.667 0V3.453a.667.667 0 0 1 .666-.666H20.52a.68.68 0 0 1 .467.2L24.48 6.48a.652.652 0 0 1 .2.467v9.84a1.333 1.333 0 1 0 2.667 0V6.12a1.333 1.333 0 0 0-.387-.933L22.293.52a1.334 1.334 0 0 0-.96-.4H6.013A1.333 1.333 0 0 0 4.68 1.453Z"
          fill={props.selected ? "#142030" :  "#b5b9c4"}
        />
        <path
          d="M15.333 8.453a1 1 0 0 0 0-2h-4a1 1 0 1 0 0 2h4ZM11.347 13.787h9.986a1 1 0 1 0 0-2h-9.986a1 1 0 0 0 0 2Z"
          fill={props.selected ? "#142030" :  "#b5b9c4"}
        />
      </svg>
)}

export default CorporateRecords
