import React from 'react'
const BackIconPopup = (props) => {
  return (
    
    <svg width="12" height="21" viewBox="0 0 12 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M3.62132 10.5L11.5607 2.56066C12.1464 1.97487 12.1464 1.02513 11.5607 0.43934C10.9749 -0.146447 10.0251 -0.146447 9.43934 0.43934L0.43934 9.43934C-0.146447 10.0251 -0.146447 10.9749 0.43934 11.5607L9.43934 20.5607C10.0251 21.1464 10.9749 21.1464 11.5607 20.5607C12.1464 19.9749 12.1464 19.0251 11.5607 18.4393L3.62132 10.5Z" fill="#697080"/>
    </svg>
  )
}

export default BackIconPopup
