const createStyle = () => {
  const backgroundColor = "#FFF";
  const backgroundColorEdit = "#FFF";

  return {
    paramContainer: {
      borderWidth: 1,
      borderStyle: "solid",
      borderRadius: 6,
      backgroundColor: "#FFF",
      marginRight: 10,
      marginBottom: 10,
      paddingLeft: 6,
      paddingRight: 8,
      paddingBottom: 8,
      paddingTop: 4,
      borderColor: "#057E64",
    },
    backgroudView: {
      shadowColor: "#000",
      borderRadius: 12,
      backgroundColor: backgroundColor,
      marginHorizontal: -3,
      display: "flex",
      flexDirection: "column",
    },
    backgroudViewEdit: {
      paddingHorizontal: 10,
      backgroundColor: backgroundColorEdit,
    },
    paramContent: {
      display: "flex",
      flexDirection: "row",
      paddingVertical: 8,
      paddingHorizontal: 8,
    },
    paramLabel: {
      color: "#078167",
      fontFamily: "Proxima Nova",
      fontSize: 14,
      fontWeight: "500",
    },
    pencil: {
      width: 8,
      height: 8,
      marginLeft: 8,
    },
    searchParamsContainer: {
      color: "#142030",
      display: "flex",
      fontFamily: "Proxima Nova",
      fontSize: 18,
      fontWeight: "700",
      marginTop: 16,
      marginBottom: 16,
    },
    paramsContainer: {
      borderBottomWidth: 1,
      borderBottomColor: "#F1F1F1",
      paddingBottom: 6,
      display: "flex",
      flexDirection: "row",
      overflow: "auto",
      whiteSpace: "nowrap",
      webkitScrollbar: {
        width: 0,
        background: "red",
      },
    },
    strengthContainer: {
      borderBottomColor: "#ffffff",
      borderLeftColor: "#ffffff",
      borderRightColor: "#ffffff",
      flexDirection: "row",
    },
    strengthTitle: {
      color: "#9EA9C1",
      fontFamily: "Proxima Nova",
      fontSize: 14,
      fontWeight: "600",
    },
    strengthValue: {
      color: "#057E64",
      fontFamily: "Proxima Nova",
      fontSize: 14,
      fontWeight: "600",
    },
  };
};

export default createStyle;
