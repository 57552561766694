import React, { useContext, useState } from "react";
import { useMutation } from "react-apollo";
import { SET_PREFERENCE, DELETE_A_CONTACT } from "../../../queries/queries";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Menu,
  MenuItem,
} from "@mui/material";
import { UserContext } from "../../../contexts/userContext";
import CloseIconWhite from "../../../images/svg/closeWhite";
import deleteicon from "../../../images/svg/delele-shape-grey.svg";
import StarDeactivated from "../../../images/svg/star-icon--deactivated";
import { GreenBgButton } from "../../buttons/greenBgButton";
import ChangePotentialMatchIcon from "../../../images/svg/change-potential-match-icon";
import StarActivated from "../../../images/svg/starActivated";
import CloseIcon from "../../../images/svg/close_gray";
import Styles from "./contactModal.module.css";
import Variables from "../../../utils/Variables";

const ContactMenu = (props = {}) => {
  const {
    isVisible,
    onBackdropPress,
    onDeleteContact,
    showChangePotentialMatch = false,
    savedSearch,
    onPress,
    anchorEl,
    setAnchorEl,
  } = props;
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [setPreferenceContacts] = useMutation(SET_PREFERENCE, {
    onCompleted: (data) => {
      if (data?.setPreference?.message?.toLowerCase() === "limit reached") {
        let updatedValue = [
          {
            id: savedSearch.id,
            isPreferred: !savedSearch.isPreferred,
          },
        ];
        onBackdropPress(updatedValue[0]);
      }
      props?.checkWatchedLimit(data?.setPreference?.message);
    },
    onError: (error) => {
      props?.checkWatchedLimit(error?.setPreference?.message);
    },
  });
  const [deleteAContact] = useMutation(DELETE_A_CONTACT, {
    onCompleted: (data) => {},
    onError: (error) => {
      // Alert('error while deleting saved search');
    },
  });
  const { user } = useContext(UserContext);

  const onPressPreference = () => {
    let data = [
      {
        id: savedSearch.id,
        isPreferred: !savedSearch.isPreferred,
      },
    ];
    setPreferenceContacts({
      variables: {
        contacts: data,
      },
    });
    Variables.isRefreshClosestContacts = true;
    onBackdropPress(data[0]);
  };

  const onPressDeleteContact = () => {
    deleteAContact({
      variables: {
        contactId: savedSearch.id,
        user: user.id,
      },
    });
    onDeleteContact(savedSearch);
    onBackdropPress();
  };

  return (
    <Menu
      className={Styles.menu}
      anchorEl={anchorEl}
      open={open}
      onClose={(e)=>{e.stopPropagation(); handleClose()}}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <MenuItem
        onClick={(e) => {
          e.stopPropagation();
          handleClose()
          onPressDeleteContact();
        }}
        dense
      >
        <img src={deleteicon} className={Styles.imageStyle} />
        <span  style={{ margin: 8 }}>Delete Saved Search</span>
      </MenuItem>
      <MenuItem
        style={{borderStyle: 'solid' ,borderWidth: 0, borderColor: '#C0C7D6', borderTopWidth: 1}}
        onClick={(e) => {
          e.stopPropagation();
          handleClose()
          onPressPreference();
        }}
        dense
      >
        <StarActivated className={Styles.imageStyle} />
        {savedSearch?.isPreferred ? (
          <span style={{ margin: 8 }}>Unmark close contact</span>
        ) : (
          <span style={{ margin: 8 }}>Mark as Close Contact</span>
        )}
      </MenuItem>
      {showChangePotentialMatch && (
        <MenuItem
          style={{borderStyle: 'solid' ,borderWidth: 0, borderColor: '#C0C7D6', borderTopWidth: 1}}
          onClick={(e) => {
            e.stopPropagation();
            handleClose()
            props?.onChangePotentialMatch(savedSearch);
            onBackdropPress();
          }}
          dense
        >
          <ChangePotentialMatchIcon className={Styles.imageStyle} />
          <span style={{ margin: 8 }}>Change Potential Match</span>
        </MenuItem>
      )}
    </Menu>
  );
};
export default ContactMenu;
