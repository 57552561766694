import { useState, useEffect } from "react";
import { IconButton, CircularProgress, Backdrop, Avatar } from "@mui/material";
import { BackIcon } from "../../images/svg/back";
import { useNavigate } from "react-router-dom";
import back_white from "../../images/svg/back_white.svg"
import { DefaultAvatar } from "../../images/svg/default-avatar";
import "./style.css";
import ClosestContactsList from "../closertContactList/closertContactList";

import { useLazyQuery } from 'react-apollo';
import {
  GET_SAVED_SEARCHES,
  GET_POTENTIAL_MATCHES,
} from '../../queries/queries';
import Variables from '../../utils/Variables';
import { closestContactsStore } from '../../store/closestContactsStore'
import PotentialMatch from "../potentialMatch/potentialMatch";
import ContactImportAlert from "../noContactsAlert/ContactImportAlert";
import SearchHeader from "../SearchHeader/SearchHeader";


const ClosestContacts = (props) => {
  const navigate = useNavigate();

  const totalImportCount = closestContactsStore((state) => state.totalImportCount)
  const setTotalImportCount = closestContactsStore((state) => state.setTotalImportCount)

  const isContactImported = closestContactsStore((state) => state.isContactImported)
  const setContactImported = closestContactsStore((state) => state.setContactImported)

  const contactList = closestContactsStore((state) => state.contactList)
  const setContactList = closestContactsStore((state) => state.setContactList)

  const categories = closestContactsStore((state) => state.categories)
  const setCategories = closestContactsStore((state) => state.setCategories)

  const potentialModalVisible = closestContactsStore((state) => state.potentialModalVisible)
  const setPotentialModalVisible = closestContactsStore((state) => state.setPotentialModalVisible)

  const potentialData = closestContactsStore((state) => state.potentialData)
  const setPotentialData = closestContactsStore((state) => state.setPotentialData)

  const contactID = closestContactsStore((state) => state.contactID)
  const setContactID = closestContactsStore((state) => state.setContactID)

  const searchValue = closestContactsStore((state) => state.searchValue);
  const setSearch = closestContactsStore((state) => state.setSearch);

  const removeAll = closestContactsStore((state) => state.removeAll)

  const [potentialMatches, { loading, data }] = useLazyQuery(GET_POTENTIAL_MATCHES);

  const [filteredContacts, setFilteredContacts] = useState(contactList);

  useEffect(() => {
    if (!loading && data && data.getPotentialMatches?.length) {
      setPotentialData(data.getPotentialMatches)
      setPotentialModalVisible(true)
    }
  }, [loading, data])

  const onSavePotentialMatch = (index) => {
    Variables.isAddSearches = true
    setPotentialModalVisible(false)
    getSavedSearches({
      variables: { showPreferred: true }
    })
  }

  const getPotentialMatches = (contactID) => {
    setContactID(contactID)
    potentialMatches({ variables: { contactId: contactID } })
  }

  const [getStarContact, { loading: loadingStarContacts, data: dataStarContacts, error: errorStarContacts }] = useLazyQuery(GET_SAVED_SEARCHES, {
    fetchPolicy: 'no-cache'
  });

  const [getSavedSearches, { loading: loadingContacts, data: dataContacts, error: errorContacts }] = useLazyQuery(GET_SAVED_SEARCHES, {
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if (!loadingContacts && dataContacts) {
      Variables.isRefreshClosestContacts = false
      if (dataContacts && dataContacts?.getSavedSearches?.contacts) {
        setContactList(dataContacts.getSavedSearches.contacts)
        setCategories(dataContacts.getSavedSearches.categories)
      }
      if (dataContacts && dataContacts?.getSavedSearches?.totalContacts) {
        setTotalImportCount(dataContacts.getSavedSearches.totalContacts)
      }
    }
  }, [dataContacts, loadingContacts, errorContacts])

  useEffect(() => {
    if (dataStarContacts && !loadingStarContacts) {
      if (dataStarContacts && dataStarContacts?.getSavedSearches?.totalContacts) {
        if (dataStarContacts && dataStarContacts?.getSavedSearches?.contacts && dataStarContacts?.getSavedSearches?.contacts?.length < dataStarContacts?.getSavedSearches?.totalContacts) {
          setContactImported(true)
        }
      }
    }
  }, [dataStarContacts, loadingStarContacts, errorStarContacts])

  useEffect(() => {
    if (Variables.isRefreshClosestContacts || contactList == null || contactList == false || contactList.length == 0) {
      window.scrollTo(0, 0);
      getSavedSearches({
        variables: { showPreferred: true }
      })
      getStarContact()
    }
  }, [])

  useEffect(() => {
    if (searchValue) {
      const filtered = contactList.filter(contact =>
        `${contact.name} ${contact.lastName}`.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredContacts(filtered);
    } else {
      setFilteredContacts(contactList);
    }
  }, [searchValue, contactList]);

  const nameList = () => {
    let name = ''

    if (contactList && totalImportCount) {
      name = 'You haven’t added any starred contacts yet'
    } else if (contactList) {
      name = 'You haven’t added any contacts yet'
    }

    if (contactList.length) {
      name = ''
      contactList.forEach((item, index) => {
        if (index < 3) name = name + (index == 0 ? item.name : ', ' + item.name)
        else if (index < 4 && contactList.length > 3) name = name + ' & ' + (contactList.length - index) + ' more in this list.'
      })
    }
    return name;
  }

  const RenderProfilePicture = ({ i }) => {
    const styleProfilePic = i != 0 ? { marginStart: -10 } : {};
    const profilePic = contactList.length > i ? contactList[i].photo ?? contactList[i].linkedinProfile?.profile_picture_src : ""
    return (
      <>
        {(!contactList || contactList.length == 0 || contactList.length > i) &&
          <span style={styleProfilePic}>
            {(contactList.length > i && profilePic) ? (
              <Avatar
                className="titleIconContacts"
                src={profilePic}
                avatarStyle={{
                  width: "30px",
                  height: "30px",
                  borderRadius: 15,
                }}
              >
                <DefaultAvatar className="titleIconContacts" />
              </Avatar>
            ) : (
              <img src={require('../../images/svg/default-avatar.svg').default} className="titleIconContacts" />
            )}
          </span>
        }
      </>
    );
  };

  const onBackPress = () => {
    removeAll()
    navigate("/dashboard", { replace: true })
  }

  const Header = () => {
    return (
      <div className="headerWatch">
        <div className="headerBack">
          <IconButton onClick={onBackPress}>
            <img src={back_white} className="back_white" alt="back_white" />
          </IconButton>
          <span className="headerText">
            Watched Contacts
          </span>
        </div>
        {/* <div className="imageView">
          <RenderProfilePicture i={0} />
          <RenderProfilePicture i={1} />
          <RenderProfilePicture i={2} />
          <RenderProfilePicture i={3} />
          <RenderProfilePicture i={4} />
        </div>
        <span className="headerUserText">{nameList()}</span> */}
      </div>
    );
  }

  const onPress = (isGetStart) => {
    if (isGetStart) {
      const data = {
        contactsList: contactList
      }
      navigate('/closestContactsEditList', { state: data })
    } else {
      Variables.navigationStackNameForContacts = 'ClosestContactsArticles';
      const data = {
        screenName: 'ClosestContactsArticles'
      }
      navigate("/importContactsLanding", { state: JSON.stringify(data) })
    }
  }

  const cancelSearch = () => setSearch("");

  return (
    <>
      <PotentialMatch
        contactID={contactID}
        potentialModalVisible={potentialModalVisible}
        setPotentialModalVisible={setPotentialModalVisible}
        potentialData={potentialData}
        onSavePotentialMatch={onSavePotentialMatch} />
      <div className="mainClosest">
        <Header />
        <div className="borderWatch">

          <div className="searchBarMainView">
            <SearchHeader
              value={searchValue}
              onChangeText={setSearch}
              onCancel={cancelSearch}
              selectAllButton={false}
              placeholder="Search Contacts"
            />
          </div>

          {(filteredContacts && filteredContacts.length == 0) ?
            <ContactImportAlert startedContact={totalImportCount} onPress={onPress} searchValue={searchValue}/> :
            <ClosestContactsList
              contactsList={filteredContacts}
              setContactList={setContactList}
              categories={categories}
              getPotentialMatches={getPotentialMatches} />
          }
        </div>
      </div>
      {(loadingContacts || loading) &&
        <Backdrop
          sx={{ color: '#AAA', zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: "#00000000" }}
          open={true}
        >
          <div className="loadingbar11"><CircularProgress /></div>
        </Backdrop>
      }
    </>
  );
};

export default ClosestContacts;
