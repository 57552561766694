import React, { useEffect, useRef, useState } from "react";
import Styles from "./contactsProcessed.module.css";
import contactsicon from "../../images/svg/contacts-icon.svg";
import { getFilterImage } from "../../images/filterColorIcon/getFilterImage";
import ContactsProcessedRows from "./contactsProcessedRows";
import LoadingBar from "../loadingBar/locadingBar";

const ContactsProcessed = ({ isProcessRefresh, savedSearches }) => {
  const circlRef = useRef();
  const isLarge = circlRef?.current?.clientWidth >= 240;
  const [availableFilter, setAvailableFilter] = useState({});
  const [totalContacts, setTotalContacts] = useState(0);
  const [processed, setProcessed] = useState(0);

  useEffect(() => {
    if (!isProcessRefresh && savedSearches && savedSearches.length > 0) {
      const processedItem =
        savedSearches.filter((item) => item?.isProcessed)?.length ?? 0;
      setProcessed(processedItem);
      setTotalContacts(savedSearches.length);

      let categoryFilter = {};
      savedSearches.map((item) => {
        if (item && item?.summary) {
          let keys = Object.keys(item?.summary);
          keys?.map((key) => {
            let value = item?.summary?.[key];
            if (value && value?.total > 0) {
              let total = categoryFilter.hasOwnProperty[key]
                ? categoryFilter[key]?.total + value?.total
                : value?.total;
              categoryFilter[key] = {
                title: value.title,
                total: total,
              };
            }
          });
        }
      });
      let categoryList = Object.values(categoryFilter);
      if (categoryList?.length > 3) categoryList = categoryList.slice(0, 3);
      setAvailableFilter(categoryList);
    }
  }, [isProcessRefresh, savedSearches]);
  return (
    <div>
      <div className={Styles.containerMain}>
        <span className={Styles.titleMain}>Contacts Processed</span>
        {isProcessRefresh ? (
          <LoadingBar />
        ) : (
          <div className={Styles.containerChild}>
            <div className={Styles.rows}>
              <img src={contactsicon} />
              <span className={Styles.processedContact}>
                {totalContacts + " "}{" "}
                <spna className={Styles.processedContactLight}>
                  {"/ " + processed}
                </spna>
              </span>
            </div>

            {availableFilter &&
              availableFilter.length > 0 &&
              availableFilter.map((item) => (
                <ContactsProcessedRows item={item} />
              ))}
          </div>
        )}
      </div>
      <div className={Styles.demoView} ref={circlRef} />
    </div>
  );
};

export default ContactsProcessed;
