import { useImperativeHandle, useRef, useState } from "react";
import Styles from "./viewOnlyList.module.css";
import { useEffect } from "react";
import { viewOnlyListStore } from "../../../store/viewOnlyListStore";
import { forwardRef } from "react";

const ViewOnlyList = (
  { contacts, renderHeader, renderView, refsList, refsSection, onScroll },
  ref
) => {
  useImperativeHandle(ref, () => ({
    onAplhaFilterSelected: (element) => {
      onAplhaFilterSelected(element);
    },
  }));

  const onAplhaFilterSelected = (a) => {
    if (a == "reset") {
      setMaxCountVisibleStore(maxItemVisible);
      setMinCountVisibleStore(0);
      setMaxCountVisible(maxItemVisible);
      setMinCountVisible(0);
      refsList?.current?.scrollTo(0, 0);
      return;
    }
    let filterData =
      Object.entries(contacts)
        .map(([key, values], index) => (key < a ? values?.length : 0))
        .reduce((acc, curr) => acc + curr, 0) + 10;
    let roundedValue = Math.ceil(filterData / 100) * 100;

    setMaxCountVisibleStore(roundedValue);
    setMinCountVisibleStore(roundedValue >= 200 ? roundedValue - 110 : 0);

    setMaxCountVisible(roundedValue);
    setMinCountVisible(roundedValue >= 200 ? roundedValue - 110 : 0);

    setBottomScrolled(true);
    setTopScrolled(true);

    clearTimeout(timerScroll);
    timerScroll = setTimeout(() => {
      refsList?.current?.scrollTo({
        top: refsSection.current[a].offsetTop,
        left: 0,
      });
    }, 300);

    clearTimeout(timerBottomScroll);
    timerBottomScroll = setTimeout(() => {
      setBottomScrolled(false);
      setTopScrolled(false);
    }, 1000);
  };

  const maxItemVisible = 100;

  const [maxCountVisible, setMaxCountVisible] = useState(maxItemVisible);
  const maxCountVisibleStore = viewOnlyListStore(
    (state) => state.maxCountVisible
  );
  const setMaxCountVisibleStore = viewOnlyListStore(
    (state) => state.setMaxCountVisible
  );

  const [minCountVisible, setMinCountVisible] = useState(0);
  const minCountVisibleStore = viewOnlyListStore(
    (state) => state.minCountVisible
  );
  const setMinCountVisibleStore = viewOnlyListStore(
    (state) => state.setMinCountVisible
  );

  const totalContactsStore = viewOnlyListStore((state) => state.totalContacts);
  const setTotalContactsStroe = viewOnlyListStore(
    (state) => state.setTotalContacts
  );
  const [totalContacts, setTotalContacts] = useState(0);

  const [lastPossion, setLastPossion] = useState(0);
  const [isSetBottomScrolled, setBottomScrolled] = useState(false);
  const [isSetTopScrolled, setTopScrolled] = useState(false);
  let loadedItem = 0;
  let timerScroll, timerBottomScroll;
  const refsListLI = useRef();

  useEffect(() => {
    let count = Object.values(contacts)
      .map((arr) => arr.length)
      .reduce((acc, curr) => acc + curr, 0);
    if (totalContactsStore != count) {
      setTimeout(() => {
        setMaxCountVisibleStore(maxItemVisible);
        setMinCountVisibleStore(0);

        setMaxCountVisible(maxItemVisible);
        setMinCountVisible(0);
        refsList?.current?.scrollTo(0, 0);
      }, 300);
    }
    setTotalContacts(count);
    setTotalContactsStroe(count);
  }, [contacts]);

  useEffect(() => {
    setMaxCountVisible(maxCountVisibleStore);
    setMinCountVisible(minCountVisibleStore);
  }, []);

  const handleScroll = (data) => {
    const position = refsList?.current?.scrollTop;
    let scrolledPosition = position + refsList?.current?.clientHeight * 3;
    if (
      scrolledPosition > refsListLI?.current?.clientHeight &&
      position > lastPossion &&
      !isSetBottomScrolled &&
      maxCountVisible < totalContacts
    ) {
      setMaxCountVisible(maxCountVisible + maxItemVisible);
      setMaxCountVisibleStore(maxCountVisible + maxItemVisible);

      let min =
        (minCountVisible == 0 ? minCountVisible - 10 : minCountVisible) +
        maxItemVisible;
      setMinCountVisible(min);
      setMinCountVisibleStore(min);

      setTopScrolled(true);
      refsList?.current?.scrollTo(0, 200);
      clearTimeout(timerBottomScroll);
      timerBottomScroll = setTimeout(() => {
        setTopScrolled(false);
      }, 1000);
    } else if (
      position < 200 &&
      minCountVisible > 0 &&
      position < lastPossion &&
      !isSetTopScrolled
    ) {
      setMaxCountVisible(maxCountVisible - maxItemVisible);
      setMaxCountVisibleStore(maxCountVisible - maxItemVisible);

      let min =
        minCountVisible - maxItemVisible < 0
          ? 0
          : minCountVisible - maxItemVisible;
      setMinCountVisible(min);
      setMinCountVisibleStore(min);

      setBottomScrolled(true);
      clearTimeout(timerScroll);
      timerScroll = setTimeout(() => {
        refsList?.current?.scrollTo(
          0,
          refsListLI?.current?.clientHeight -
            refsList?.current?.clientHeight * 2
        );
      }, 200);
      clearTimeout(timerBottomScroll);
      timerBottomScroll = setTimeout(() => {
        setBottomScrolled(false);
      }, 1000);
    }
    setLastPossion(position);
    onScroll(data);
  };

  const renderLoading = () => <></>;
  return (
    <div className={Styles.list} ref={refsList} onScroll={handleScroll}>
      
      {contacts && JSON.stringify(contacts) == '{}' && <span className={Styles.textStyle}>
          No Information available. <br/>
          Try adjusting your filters.
      </span>} 

      <li className={Styles.listContact} ref={refsListLI}>
        {contacts &&
          Object.entries(contacts).map(([key, values], index) => (
            <div
              ref={(element) => {
                refsSection.current[key] = element;
              }}
              key={index}
            >
              {maxCountVisible >= loadedItem + 1 &&
              minCountVisible < loadedItem + 1
                ? renderHeader(key)
                : renderLoading()}
              {values &&
                values.map((item, index) => {
                  loadedItem++;
                  if (
                    maxCountVisible >= loadedItem &&
                    minCountVisible < loadedItem
                  ) {
                    return renderView(item, index);
                  } else {
                    return renderLoading();
                  }
                })}
            </div>
          ))}
      </li>
    </div>
  );
};
export default forwardRef(ViewOnlyList);
