import { Backdrop, CircularProgress } from "@mui/material";
import React, { useEffect } from 'react';
import { useLazyQuery } from 'react-apollo';
import { GET_FERRET_CONTACT_LIST, GET_POTENTIAL_MATCHES } from '../../queries/queries';
import { savedSearchesStore } from '../../store/savedSearchesStore';
import Variables from '../../utils/Variables';
import PotentialMatch from '../potentialMatch/potentialMatch';
import SavedSearches from '../savedSearches/savedSearches';
let timer = []

const REFRESH_TIMEOUT = 2000
let lastTimeout = null

const SavedSearchesWithQuery = ({ searchTitle }) => {
  const savedSearches = savedSearchesStore((state) => state.savedSearches);
  const setSavedSearches = savedSearchesStore(
    (state) => state.setSavedSearches
  );

  const categories = savedSearchesStore((state) => state.categories)
  const setCategories = savedSearchesStore((state) => state.setCategories)

  const isProcessRefresh = savedSearchesStore(
    (state) => state.isProcessRefresh
  );
  const setProcessRefresh = savedSearchesStore(
    (state) => state.setProcessRefresh
  );

  const potentialModalVisible = savedSearchesStore(
    (state) => state.potentialModalVisible
  );
  const setPotentialModalVisible = savedSearchesStore(
    (state) => state.setPotentialModalVisible
  );

  const potentialData = savedSearchesStore((state) => state.potentialData);
  const setPotentialData = savedSearchesStore(
    (state) => state.setPotentialData
  );

  const contactID = savedSearchesStore((state) => state.contactID);
  const setContactID = savedSearchesStore((state) => state.setContactID);

  const [potentialMatches, { loading, data }] = useLazyQuery(
    GET_POTENTIAL_MATCHES
  );

  useEffect(() => {
    if (!loading && data && data.getPotentialMatches?.length) {
      setPotentialData(data.getPotentialMatches);
      setPotentialModalVisible(true);
    }
  }, [loading, data]);

  const onSavePotentialMatch = () => {
    setPotentialModalVisible(false);
    getFerretContactList({
      variables: {
        showSavedSearches: true,
      },
    });
  };

  const broadcast = new BroadcastChannel('background-contact-process-message');

  useEffect(() => {
    if (broadcast) {
      broadcast.onmessage = (res) => {
        refreshContacts(res.data);
      };
    }
  }, []);

  const refreshContacts = (payload) => {
    if (payload?.data?.type === "import-contact-process-notification") {
      setProcessRefresh(true);
      if (lastTimeout) {
        clearTimeout(lastTimeout);
      }
      lastTimeout = setTimeout(() => {
        // To avoid multiple API call once received multiple notification
        getFerretContactList({
          variables: {
            showSavedSearches: true
          }
        });
      }, REFRESH_TIMEOUT)
    }
  }

  const getPotentialMatches = (contactID) => {
    setContactID(contactID);
    potentialMatches({ variables: { contactId: contactID } });
  };

  const [
    getFerretContactList,
    { loading: loadingContacts, data: dataContacts, error: errorContacts },
  ] = useLazyQuery(GET_FERRET_CONTACT_LIST, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (
      Variables.isAddSearches ||
      savedSearches == null ||
      savedSearches?.length == 0
    ) {
      getFerretContactList({
        variables: {
          showSavedSearches: true,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (!loadingContacts && dataContacts) {
      Variables.isAddSearches = false;
      setProcessRefresh(false);
      setSavedSearches(dataContacts?.contactList?.contacts);
      setCategories(dataContacts?.contactList?.categories)
    }
  }, [loadingContacts, dataContacts, errorContacts]);

  return (
    <>
      <PotentialMatch
        contactID={contactID}
        potentialModalVisible={potentialModalVisible}
        setPotentialModalVisible={setPotentialModalVisible}
        potentialData={potentialData}
        onSavePotentialMatch={onSavePotentialMatch}
      />
      <SavedSearches
        getPotentialMatches={getPotentialMatches}
        savedSearches={savedSearches}
        dataContactList={
          dataContacts?.contactList?.contacts
            ? [...dataContacts?.contactList?.contacts]
            : []
        }
        searchTitle={searchTitle}
        categories={categories}
      />
      {((loadingContacts && !isProcessRefresh) || loading) && (
        <Backdrop
          sx={{
            color: "#AAA",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: "#00000000",
          }}
          open={true}
        >
          <CircularProgress />
        </Backdrop>
      )}
    </>
  );
};

export default SavedSearchesWithQuery;
