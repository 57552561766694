import React from "react";
import Styles from './alphabetScroll.module.css'

export const AlphabetScroll = ({ alphaArray, onClick, customStyle, styles }) => {
  let arr=[1,2,3,4,5,6,7,8];
  return (
    <div>
      <ul className={styles ? styles : Styles.letterSidebar} style={customStyle}>
        {alphaArray.map((a) => (
          <li key={a} onClick={() => onClick(a)} className={Styles.alphaHeaderText}>
            {a}
          </li>
        ))}
        {arr.map((a) => (
          <li key={a} className={Styles.alphaHeaderTextDummy}>
            a
          </li>
        ))}
      </ul>
    </div>
  );
};
