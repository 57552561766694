import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import Styles from "./searchComponent.module.css";

const highlightedCountries = ["United States", "Canada", "Australia", "India"]

export default function SearchSelect({
  value,
  setValue,
  icon,
  label,
  options,
  type,
  inputType = "none",
  lableKey,
  defaultValue,
  refObj,
  country,
  setState,
  isFocused,
  stateAbbr,
  setStateAbbr
}) {
  const MyNativeSelect = withStyles({
    icon: {
      color: "#FFFFFF",
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
  })(Select);


  useEffect(() => {
    setStateAbbr();
  }, [country]);

  return (
    <div className={Styles.searchtextField} style={{ borderColor: (isFocused ? "#078167" : "rgba(255, 255, 255, 0.1)") }}>
      {icon && <span className={Styles.searchFiledIcon}>{icon}</span>}
      <FormControl fullWidth size="small">
        {(value == "" || value == undefined) ? (
          <div style={{ height: 3 }}></div>
        ) : (
          <InputLabel
            id="select-standard-label"
            variant="standard"
            htmlFor="uncontrolled-native"
            sx={{
              // height: 42,
              color: "#8E96A8",
              "&.Mui-focused": {
                color: "#8E96A8",
              },
              fontFamily: "Proxima Nova",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "16px",
              letterSpacing: "-0.01em",
              textAlign: "center",
              paddingTop: value ? "6px" : "0px",
              paddingLeft: "16px",
            }}
          >
            {label}
          </InputLabel>
        )}
        <MyNativeSelect
          disabled={country?.length == 0}
          displayEmpty={true}
          labelId="select-standard-label"
          inputRef={refObj}
          type={inputType}
          disableUnderline={true}
          placeholder="State"
          label={label}
          value={value == "" || value == undefined ? "" : value}
          onChange={(event, child: React.ReactNode) => {
            setStateAbbr(child?.props["data-abbr"]);
            if (type != "state") {
              setState();
            }
            setValue(event.target.value);
          }}
          defaultValue={defaultValue}
          inputProps={{
            sx: {
              height:
                (value == "" || value == undefined) && type == "state"
                  ? 39
                  : 24,
              id: "uncontrolled-native",
              color: value == "" || value == undefined ? "#8E96A8" : "#fff",
              fontFamily: "Proxima Nova",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "16px",
              letterSpacing: "-0.01em",
              paddingLeft: "11px",
              paddingTop: value ? "30px" : "15px",
            },
          }}
          renderValue={() => {
            return type == "state" ? (
              country == "United States" && stateAbbr ? (
                stateAbbr
              ) : value == "" || value == undefined ? (
                label
              ) : (
                options.filter((item) => {
                  return item.value == value;
                })[0]?.inputLabel
              )
            ) : value ? (
              value
            ) : (
                  <div>{type == "state" ? stateAbbr : "Country"}</div>
            );
          }}
        >
          {options.map((item) => {
            return (
              <MenuItem
                style={{ fontWeight: highlightedCountries.includes(item.value) ? "bold" : "normal" }}
                value={item.value}
                data-abbr={lableKey ? item[lableKey] : item.label}
              >
                {item.label}
              </MenuItem>
            );
          })}
        </MyNativeSelect>
      </FormControl>
    </div>
  );
}
