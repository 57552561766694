import Styles from "./importLayout.module.css";
import ImportedContacts from "../../components/importedContacts/importedContacts";

/**
 * Dashboard Page
 * @constructor
 */
const ImportLayoutPage2 = () => {
  return (
    <>
      <div className={Styles.body}>
        <div className={Styles.bodyMain}>
          <ImportedContacts />
        </div>
      </div>
    </>
  );
};

// Page export
export default ImportLayoutPage2;
