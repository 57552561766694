import React from 'react';
import SocialNetworkCard from '../socialNetworkCard/socialNetworkCard';
import Styles from './social.module.css';

const SocialNetworksGrid = props => {
  const {socialNetworks, isFinalState, isWeb} = props;

  return (
    <div className={ isWeb ? Styles.containerWeb : Styles.container}>
      {socialNetworks
        .filter(socialNetwork => socialNetwork.enabled)
        .map(item => {
          return (
             <SocialNetworkCard data={item} isFinalState={isFinalState}/>
          );
        })}
    </div>
  );
};

export default SocialNetworksGrid;
