import { browserName } from "react-device-detect";

let deferredPrompt;

window.addEventListener('beforeinstallprompt', (e) => {
  e.preventDefault();
  deferredPrompt = e;
});

export const checkPWAInstall = () => {
  if (isThisDeviceRunningiOS()) {
    if (browserName.indexOf("Safari") != -1)
      return true
    else
      return false
  }
  if (deferredPrompt == undefined
    || (navigator.standalone || window.matchMedia('(display-mode: standalone)').matches || window.matchMedia('(display-mode: fullscreen)').matches || window.matchMedia('(display-mode: minimal-ui)').matches)
  ) {
    return false;
  } else {
    return true
  }
}


export const doInstallPWA = async () => {
  if ('getInstalledRelatedApps' in window.navigator) {
    let relatedApps = await window.navigator.getInstalledRelatedApps();

    if (relatedApps.length > 0) {
      return true;
    }
    else {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the A2HS prompt");
        } else {
          console.log("User dismissed the A2HS prompt");
        }
        deferredPrompt = null;
      });
      return false;
    }
  }
}

export const isThisDeviceRunningiOS = () => {
  if (['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform)) {
    return true;
  }
  else if (navigator.userAgent.includes("Mac") && "ontouchend" in document) {
    return true;
  }
  else {
    return false;
  }
}
