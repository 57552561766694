// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".twoBtnAlert_dialogTitle__vSIDo {\n  justify-content: center;\n  align-self: center;\n}\n\n.twoBtnAlert_checkBoxDiv__emMwm {\n  flex-direction: row;\n  align-items: center;\n  padding-top: 8px;\n}\n\n.twoBtnAlert_checkBoxLabel__I\\+mq3 {\n  font-weight: 400;\n  font-size: 14px;\n  text-align: center;\n  line-height: 17px;\n  padding: 10px 16px 10px 16px;\n  color: #697080;\n  font-family: \"Proxima Nova\";\n}\n", "",{"version":3,"sources":["webpack://./src/components/alert/twoBtnAlert.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,iBAAiB;EACjB,4BAA4B;EAC5B,cAAc;EACd,2BAA2B;AAC7B","sourcesContent":[".dialogTitle {\n  justify-content: center;\n  align-self: center;\n}\n\n.checkBoxDiv {\n  flex-direction: row;\n  align-items: center;\n  padding-top: 8px;\n}\n\n.checkBoxLabel {\n  font-weight: 400;\n  font-size: 14px;\n  text-align: center;\n  line-height: 17px;\n  padding: 10px 16px 10px 16px;\n  color: #697080;\n  font-family: \"Proxima Nova\";\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogTitle": "twoBtnAlert_dialogTitle__vSIDo",
	"checkBoxDiv": "twoBtnAlert_checkBoxDiv__emMwm",
	"checkBoxLabel": "twoBtnAlert_checkBoxLabel__I+mq3"
};
export default ___CSS_LOADER_EXPORT___;
