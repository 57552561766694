import { Button, IconButton, Stack } from "@mui/material";
import { Buffer } from "buffer";
import React, { useContext, useState } from 'react';
import { useMutation } from 'react-apollo';
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/userContext";
import { AppleIcon } from "../../images/svg/apple_icon";
import { BackIcon } from "../../images/svg/back";
import ferretLogo from "../../images/svg/ferret-logo.svg";
import { GoogleIcon } from "../../images/svg/google-icon";
import { AUTHENTICATE_APPLE_USER, AUTHENTICATE_GOOGLE_USER } from '../../queries/queries';
import { saveUserDataAndRedirectToInitialScreen } from '../../root-navigation';
import { signInApple } from "../../utils/apple-login-helper";
import { getDeviceDetails } from "../../utils/DeviceDetails";
import { googleDefaultLogin } from "../../utils/google-login-helper";
import Variables from "../../utils/Variables";
import SingleBtnAlert from "../alert/singleBtnAlert";
import { AppleBtn } from '../buttons/appleBtn';
import { GoogleBtn } from '../buttons/googleBtn';
import { GreenBgButton } from '../buttons/greenBgButton';
import Styles from './signUpLanding.module.css';
import { signupStore } from "../../store/signupStore";


const SignUpLanding = () => {

  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);
  const [accountExist, setAccountExist] = useState({ status: false, msg: "" });

  const removeAll = signupStore((state) => state.removeAll)

  const [authenticateGoogleUser, { loading: googleSignUpLoader }] = useMutation(AUTHENTICATE_GOOGLE_USER,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        console.log('data=====onCompleted', data)
        saveUserDataAndRedirectToInitialScreen(
          data.authenticateGoogleUser,
          null,
          () => { },
          'signup',
          null,
          setUser,
          navigate,
          setAccountExist
        )
      },
      onError: (error) => {
        console.log('data=====onError', error)
        setAccountExist({ status: true, msg: error?.graphQLErrors[0]?.message })
      }
    });

  const onGoogleSignUp = async(response) => {
    const deviceInfo = await getDeviceDetails();
    authenticateGoogleUser({
      variables: {
        accessToken: response?.access_token,
        requestType: 'SIGNUP',
        deviceDetails: deviceInfo,
      }
    })
  }

  const [authenticateAppleUser, { loading: appleSignUpLoader }] = useMutation(AUTHENTICATE_APPLE_USER,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        saveUserDataAndRedirectToInitialScreen(
          data.authenticateAppleUser,
          null,
          () => { },
          'signup',
          null,
          setUser,
          navigate,
          setAccountExist
        )
      },
      onError: (error) => {
        console.log('data=====onError', error)
        setAccountExist({ status: true, msg: error.graphQLErrors[0].message })
      }
    });

  const onAppleSignUp = async () => {
    const response = await signInApple()
    const id_token = response.authorization?.id_token
    if (id_token) {
      const { email, name } = response.user ?? [];
      let mEmail = email;
      let firstName = name?.firstName;
      let lastName = name?.lastName;

      if (mEmail == null) {
        const data = JSON.parse(
          Buffer.from(id_token.split('.')[1], 'base64').toString(
            'ascii',
          ),
        );
        mEmail = data.email;
        firstName = data.email
          .split('@')[0]
          .replaceAll(/[^\w\s]/g, ' ')
          .replaceAll(/_/g, ' ');
        lastName = ' ';
      }
      const deviceInfo = await getDeviceDetails();
      authenticateAppleUser({
        variables: {
          code: id_token,
          email: mEmail,
          name: firstName,
          lastName: lastName,
          requestType: 'SIGNUP',
          deviceDetails: deviceInfo,
        },
      });
    }
  }

  return (
    <Stack className={Styles.bodySignupLanding}>

      <header className={Styles.navHeader}>
        <IconButton
          className={Styles["backIconBtn"]}
          onClick={() => navigate((-1), { replace: true })}>
            <BackIcon />
        </IconButton>

        <div className={Styles.navHeaderTitle}>Sign Up</div>
      </header>

      <main className={Styles.signupLadingMain}>

        <img src={ferretLogo} className={ Styles.ferretLogo } alt="ferretLogo" />

        <div className={Styles.divBtn}>
        <GoogleBtn
          onClick={() => googleDefaultLogin(onGoogleSignUp)}
          fullWidth
          variant="contained"
          loading={googleSignUpLoader}
            startIcon={<GoogleIcon />}>
          <div className={Styles.signinGoogleTxt}>Sign up with Google</div>
        </GoogleBtn>

        <AppleBtn
          onClick={onAppleSignUp}
          fullWidth
          variant="contained"
          loading={appleSignUpLoader}
          startIcon={<AppleIcon />}
          style={{
            marginTop: 16
          }}>
          <div className={Styles.signinAppleTxt}>Sign up with Apple</div>
        </AppleBtn>

        <div className={ Styles.or }>OR</div>

        <GreenBgButton
          fullWidth
          variant="contained"
          style={{
            marginTop: 16
          }}
            onClick={() => {
              removeAll?.()
              navigate('/signUpUserDetails')
            }}>
            <div className={Styles.signUpBtnTxt}>Sign up with Email</div>
        </GreenBgButton>

        <Button
          fullWidth
          variant="text"
          style={{
            marginTop: 33
          }}
          onClick={() => {
            Variables.isFromLandingpage = false
            navigate('/login')
          }}
        >
            <div className={Styles.alreadyLogin}>Already a member? Log in</div>
          </Button>
        </div>

        {(accountExist.status == true) && <SingleBtnAlert
          description={accountExist.msg}
          btnTxt="OK"
          onBtnClick={() => {
            setAccountExist({ status: null, msg: "" })
            Variables.isFromLandingpage = false
            navigate('/login')
          }}
        />
        }

      </main>
    </Stack>
  )
}

export default SignUpLanding;
