import { useState } from "react";
import OnboardingTooltip from "./onboardingTooltip";
import OnboardingPopover from "./onboardingPopover";
import HomeIconWhite from "../../images/svg/homeIconWhite";

const RecentActivityTooltip = (props) => {
  const [isVisible, setIsVisible] = useState(props.isVisible);
  const { isWeb = false } = props
  return (
    <>
      <OnboardingTooltip
        title={
          <OnboardingPopover
            title="Dashboard"
            content="This is your dashboard. Find updates in your network as they happen. Check notifications and your news feeds for the latest."
            icon={<HomeIconWhite />}
            onClick={() => {
              setIsVisible(false);
              props.onClick();
            }}
          />
        }
        componentStyle={{
          marginBottom: "40px !important",
          maxWidth: isWeb ? '500px' : "90%",
          marginLeft: "5%",
          marginRight: "5%",
        }}
        arrowStyles={{ overflow: "inherit" }}
        isVisible={isVisible}
        placement="top"
        {...props}
      >
        {props.children}
      </OnboardingTooltip>

      {isWeb &&
        <div style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          display: "flex",
          alignItems: "flex-end",
          inset: "0px auto 0px 0px",
          transform: "translate3d(0px, 0px, 0px)",
          zIndex: 8
        }} />
      }
    </>
  );
};

export default RecentActivityTooltip;
