import * as React from "react"
const Crunchbase = (props) => {
    return(
      <svg
      width={33}
      height={32}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)" fill={props.selected ? "#142030" :  "#b5b9c4"}>
        <path d="M11.588 21.163a4.18 4.18 0 1 1 .049-3.443h3.24a7.292 7.292 0 1 0 0 3.443h-3.289ZM25.096 12.13h-.535a7.194 7.194 0 0 0-3.565 1.255V5.267H18.04v20.879h2.974v-.762a7.293 7.293 0 1 0 4.083-13.255Zm4.18 7.81v.13a4.163 4.163 0 0 1-.113.51c-.055.18-.123.356-.202.526v.065a4.206 4.206 0 0 1-2.925 2.293l-.397.065h-.09a4.138 4.138 0 0 1-.453 0c-.19 0-.38-.014-.567-.04h-.13a4.141 4.141 0 0 1-1.061-.325h-.081a4.195 4.195 0 0 1-.94-.632 4.221 4.221 0 0 1-.737-.883 4.185 4.185 0 0 1-.268-.518 4.158 4.158 0 0 1 1.415-5.07 4.187 4.187 0 0 1 1.988-.748c.143-.007.287-.007.43 0a4.189 4.189 0 0 1 4.132 4.068c.012.186.012.372 0 .559Z" />
      </g>
      <defs>
        <clip-path id="a">
          <path fill="#fff" transform="translate(.5)" d="M0 0h32v32H0z" />
        </clip-path>
      </defs>
    </svg>
)}

export default Crunchbase
