import React, { useState, useEffect, useRef, useContext } from "react";
import ArticleCardWithQuery from "../../components/articleCardWithQuery/articleCardWithQuery";
import { useLazyQuery, useMutation } from "react-apollo";
import { GET_FULLTEXT } from "../../../queries/queries";
import createStyle from "./styles";
import Variables from "../../../utils/Variables";
import SaveArticleRelevancy from "./saveArticleRelevancy";
import EmptyCategoryMessage from "../../../components/emptyCategoryMessage/emptyCategoryMessage";
import { UPDATE_FERRET_USER } from "../../../queries/queries";
import { setUserInfo } from "../../../root-navigation";
import { UserContext } from "../../../contexts/userContext";
import { filterArticlesByVerificationType } from "../../../utils/filterUtils";
import FlatList from "flatlist-react";
import Article from "../../components/article/article";
import Styles from "./sectionListView.module.css";
import FilterByVerificationType from "../filterByVerificationType/filterByVerificationType";
import LoadingBar from "../loadingBar/locadingBar";
import { WhiteBgButtonGreenBorder } from "../../../components/buttons/whiteBgButtonGreenBorder";
import { generatePdf } from "../../../utils/pdfGeneratorUtils";
import { searchResultStore } from "../../../store/searchResultStore";

let lastTimeout = null;
const SectionListView = (props) => {
  const flatListRef = useRef();
  const refs = useRef([]);
  const mainParantScrollRef = useRef(null);
  const {
    type = "",
    articles,
    articlesLoading = false,
    onEndReachedHandler,
    onFilterCompleted,
    articleType,
    isHeader = false,
    recentSearchId,
    articleSource = "FEED",
    searchTerm,
    savedContactID,
    isSetOldFilter = false,
    isShowName = true,
    contactData,
    contactDataID,
    reloadScreenData,
    configData = [],
    configLoading = false,
    filterAttributes,
    openFilter = false,
    setHeaderTags,
    headerTags,
    saveRelevancyData,
    setSaveRelevancyData,
    deleteRelevancyData,
    setDeleteRelevancyData,
    setShowHeader,
    setRefreshList,
    verified,
    setVerified,
    partial,
    setPartial,
    needsReview,
    setNeedsReview,
    recentArticle,
    setArticleDetails,
    selectedFilter,
    showStrongMatchArticles,
    showWeakMatchArticles,
    readFilter,
    unreadFilter,
    loading = false,
    potentialMatchId,
    setVerifiedCountRecent,
    setNeedsReviewCountRecent,
    setPartialCountRecent,
    isArticleOpened,
    setIsArticleOpened
  } = props;

  let indexCount = 0

  const style = createStyle();
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1);
  const [selectedArticleItemIndex, setSelectedArticleItemIndex] = useState(-1);
  const [selectedDocument, setSelectedDocument] = useState();
  const [riskLevelsStatuses, setRiskLevelsStatuses] = useState(
    isSetOldFilter
      ? Variables.riskLevelsStatuses
      : {
        high: true,
        medium: true,
        low: true,
      }
  );
  const [showLoader, setShowLoader] = useState(false);
  const [noResultFound, setNoresultfound] = useState(false);
  const [openArticleDetail, setOpenArticleDetail] = useState();
  const [recentListView, setRecentListView] = useState(false);

  const { relevantArticle } = SaveArticleRelevancy(
    reloadScreenData,
    setShowLoader,
    articleSource,
    searchTerm,
    savedContactID,
    recentSearchId,
    contactData,
    (value) => {
      setOpenArticleDetail();
      setSelectedItemIndex(-1);
      setRefreshList(value);
    }
  );

  const [filteredArticles, setFilteredArticles] = useState(articles);

  const [verifiedCount, setVerifiedCount] = useState(0);
  const [needsReviewCount, setNeedsReviewCount] = useState(0);
  const [partialCount, setPartialCount] = useState(0);

  const isPdfGenerating = searchResultStore(
    (state) => state.isPdfGenerating
  );
  const setIsPdfGenerating = searchResultStore(
    (state) => state.setIsPdfGenerating
  );

  const selectedMapArticle = searchResultStore(
    (state) => state.selectedMapArticle
  );
  const setSelectedMapArticle = searchResultStore(
    (state) => state.setSelectedMapArticle
  );

  const { setUser } = useContext(UserContext);
  const [userUpdaterMutation, { loading: loadingDontShow }] = useMutation(
    UPDATE_FERRET_USER,
    {
      fetchPolicy: "no-cache",
      onCompleted: ({ updateUser }) => {
        let u = setUserInfo(updateUser);
        setUser({ ...u });
      },
    }
  );

  const [getFulltext, { loading: loadingGetFullText, data: dataGetFullText }] =
    useLazyQuery(GET_FULLTEXT, {
      fetchPolicy: "no-cache",
    });

  useEffect(() => {
    //filtering articles
    if (props?.navFrom == "recentActivity") {
      let tempArticles = articles;
      tempArticles.forEach((a) => {
        a.data = filterArticlesByVerificationType(
          a?.data,
          !verified,
          !partial,
          !needsReview
        );
      });
      tempArticles = tempArticles.filter((item) => item?.data?.length > 0);
      setFilteredArticles(tempArticles);
    } else {
      setFilteredArticles(
        filterArticlesByVerificationType(
          articles,
          !verified,
          !partial,
          !needsReview
        )
      );
    }
  }, [articles, verified, partial, needsReview]);

  useEffect(() => {
    setSelectedItemIndex(-1);
    setOpenArticleDetail();
  }, [
    selectedFilter,
    showStrongMatchArticles,
    showWeakMatchArticles,
    readFilter,
    unreadFilter,
    filterAttributes,
    verified,
    partial,
    needsReview,
  ]);

  useEffect(() => {
    Variables.riskLevelsStatuses = isSetOldFilter
      ? Variables.riskLevelsStatuses
      : {
        high: true,
        medium: true,
        low: true,
      };

    return () => {
      indexCount = 0
    }
  }, []);

  useEffect(() => {
    if (filteredArticles?.length < 1 && !articlesLoading) {
      clearTimeout(lastTimeout);
      lastTimeout = setTimeout(() => {
        setNoresultfound(true);
      }, 500);
    } else {
      clearTimeout(lastTimeout);
      setNoresultfound(false);
    }
  }, [filteredArticles, articlesLoading]);

  useEffect(() => {
    Variables.statusesOfFilters = isSetOldFilter
      ? Variables.riskLevelsStatuses
      : null;
  }, []);

  useEffect(() => {
    if (articleType) {
      Variables.riskLevelsStatuses = riskLevelsStatuses;
    }
  }, []);

  useEffect(() => {
    var res;
    if (props?.navFrom == "recentActivity") {
      res = recentArticle.reduce(function (obj, v) {
        obj[v.verificationType] = (obj[v.verificationType] || 0) + 1;
        return obj;
      }, {});
    } else {
      res = articles.reduce(function (obj, v) {
        obj[v.verificationType] = (obj[v.verificationType] || 0) + 1;
        return obj;
      }, {});
    }

    if (res.hasOwnProperty("verified")) {
      setVerifiedCount(res["verified"]);
      setVerifiedCountRecent?.(res["verified"]);
    } else {
      setVerifiedCount(0);
      setVerifiedCountRecent?.(0);
    }

    if (res.hasOwnProperty("needs_review")) {
      setNeedsReviewCount(res["needs_review"]);
      setNeedsReviewCountRecent?.(res["needs_review"]);
    } else {
      setNeedsReviewCount(0);
      setNeedsReviewCountRecent?.(0);
    }

    if (res.hasOwnProperty("partial")) {
      setPartialCount(res["partial"]);
      setPartialCountRecent?.(res["partial"]);
    } else {
      setPartialCount(0);
      setPartialCountRecent?.(0);
    }
  }, [articles]);

  useEffect(() => {
    if (
      props?.navFrom == "recentActivity" &&
      verifiedCount == 0 &&
      needsReviewCount == 0 &&
      partialCount == 0
    ) {
      setNoresultfound(true);
    } else {
      setNoresultfound(false);
    }
  }, [verifiedCount, needsReviewCount, partialCount]);

  const dontShow = () => {
    const requestData = {
      relevancySortFilterTooltip: true,
    };
    userUpdaterMutation({
      variables: requestData,
    });
  };

  const callGetFullText = (item, selected) => {
    setOpenArticleDetail();

    setSelectedDocument(selected);
    let data = {};
    if (item.articleType == "CL RECAP") {
      data = {
        articleId: selected.details.articleId,
        dataSource: "CL_RECAP",
      };
    } else {
      data = {
        articleId: item.articleId,
        dataSource: "OTHER",
      };
    }
    getFulltext({
      variables: data,
    });
  };

  useEffect(() => {
    if (selectedMapArticle) {
      setSelectedItem(selectedMapArticle);
      setSelectedItemIndex(selectedMapArticle.index);
      if (!!selectedMapArticle.articleId) {
        callGetFullText(selectedMapArticle, selectedMapArticle.selected);
      } else {
        gotoArticle(selectedMapArticle, selectedMapArticle, selectedMapArticle.selected);
      }
    }
    setSelectedMapArticle(undefined)

    setTimeout(() => {
      try {
        flatListRef?.current?.scrollTo({
          top: refs.current[selectedMapArticle.index - 1].offsetTop - 220,
          left: 0,
          behavior: "smooth",
        });
      } catch (e) { }
    }, 1000)

  }, [selectedMapArticle])

  useEffect(() => {
    if (selectedArticleItemIndex) {
      setTimeout(() => {
        try {
          flatListRef?.current?.scrollTo({
            top: refs.current[selectedArticleItemIndex].offsetTop - 220,
            left: 0,
            behavior: "smooth",
          });
        } catch (e) { }
      }, 1000)
    }
  }, [selectedArticleItemIndex])

  useEffect(() => {
    if (dataGetFullText) {
      const newArticle = {
        ...selectedItem,
        article: {
          ...selectedItem.article,
          fulltext: dataGetFullText.getFulltxt.data || "",
        },
      };
      gotoArticle(newArticle, selectedItem, selectedDocument);
    }
  }, [dataGetFullText, loadingGetFullText]);

  const gotoArticle = (item, selectedItem, selectedDocument) => {
    setOpenArticleDetail();
    let data = {
      isRecent: isHeader,
      articleCount: props.articleCount,
      newsItem: item,
      article: item.article,
      selectedDocument,
      selectedItem,
      recentSearchId,
      articleSource,
      searchTerm,
      savedContactID,
      type: type,
      contactData: contactData,
      setRelevantArticle: relevantArticle,
      setShowLoader: setShowLoader,
      openFilter: openFilter,
      dontShow: dontShow,
      setHeaderTags: setHeaderTags,
      headerTags: headerTags,
      saveRelevancyData: saveRelevancyData,
      setSaveRelevancyData: setSaveRelevancyData,
      deleteRelevancyData: deleteRelevancyData,
      setDeleteRelevancyData: setDeleteRelevancyData,
      potentialMatchId: potentialMatchId,
      setShowHeader: setShowHeader,
      setOpenArticleDetail: setOpenArticleDetail,
      setArticleDetails: setArticleDetails,
      setRefreshList: setRefreshList,
      mainParantScrollRef: mainParantScrollRef,
      setIsArticleOpened: setIsArticleOpened
    };
    setArticleDetails?.(true);
    setShowHeader?.(false);
    window.scrollTo(0, 0);
    setOpenArticleDetail(data);
    setRecentListView(true)
  };

  const onEndReached = () => {
    onEndReachedHandler?.();
  };
  return (
    <>
      <div className={Styles.containerMain}>
        <div className={Styles.containerCenterParant}>
          {isHeader && (
            <span className={Styles.recentTXT}>Recently Viewed Articles</span>
          )}
          {!isHeader && (
            <div className={Styles.containerCenterFilterType}>
              <FilterByVerificationType
                verified={verified}
                setVerified={setVerified}
                partial={partial}
                setPartial={setPartial}
                needsReview={needsReview}
                setNeedsReview={setNeedsReview}
                verifiedCount={verifiedCount}
                needsReviewCount={needsReviewCount}
                partialCount={partialCount}
              />
            </div>
          )}
          {isHeader && noResultFound && (
            <div className={Styles.recentNoItem}>
              {" "}
              <EmptyCategoryMessage />{" "}
            </div>
          )}
          <div
            className={Styles.containerCenter}
            ref={flatListRef}
            style={{
              width:
                isHeader && filteredArticles && !recentListView
                  ? Object.entries(filteredArticles)?.length * 310
                  : 360,
              borderRightWidth: isHeader ? 0 : 1,
              height: isHeader
                ? window.innerHeight - 110
                : window.innerHeight - 270,
            }}
          >
            {(loading ||
              showLoader ||
              loadingDontShow ||
              loadingGetFullText) && (
                <div className={Styles.containerRightparantProgressbar}>
                  <LoadingBar />
                </div>
              )}
            {isHeader ? (
              <>
                <div>
                  <li
                    style={{
                      display: "flex",
                      paddingBottom: 100,
                      flexDirection: recentListView ? "column" : "row",
                    }}
                  >
                    {filteredArticles &&
                      Object.entries(filteredArticles).map(([key, values]) => {
                        if (key == 0) {
                          indexCount = 0
                        }
                        return (
                          <div style={{ flex: 1 }}>
                            <div style={style.sectionView}>
                              <span style={style.contactSectionListTitle}>
                                {values.title}
                              </span>
                            </div>
                            {values?.data &&
                              values.data.map((item, index) => {
                                indexCount++;
                                const currentIndex = indexCount;
                                return (
                                  <div
                                    id={item.articleId}
                                    ref={(element) => {
                                      refs.current[currentIndex] = element;
                                    }}
                                  >
                                    <ArticleCardWithQuery
                                      key={index + "_" + item.articleId}
                                      contactData={contactData}
                                      recentSearchId={recentSearchId}
                                      contactDataID={contactDataID}
                                      openFilter={openFilter}
                                      dontShow={dontShow}
                                      isRecent={true}
                                      isMarginRight={isHeader && !openArticleDetail}
                                      newsItem={item}
                                      isShowName={isShowName}
                                      {...props}
                                      configData={configData}
                                      configLoading={configLoading}
                                      onPress={(selected) => {
                                        setIsArticleOpened?.()
                                        setSelectedItem(item);
                                        setSelectedArticleItemIndex(currentIndex)
                                        setSelectedItemIndex(key + "," + index);
                                        if (!!item.articleId) {
                                          callGetFullText(item, selected);
                                        } else {
                                          gotoArticle(item, item, selected);
                                        }
                                      }}
                                      setRelevantArticle={relevantArticle}
                                      setShowLoader={setShowLoader}
                                      setHeaderTags={setHeaderTags}
                                      headerTags={headerTags}
                                      saveRelevancyData={saveRelevancyData}
                                      setSaveRelevancyData={setSaveRelevancyData}
                                      deleteRelevancyData={deleteRelevancyData}
                                      setDeleteRelevancyData={setDeleteRelevancyData}
                                      filterAttributes={filterAttributes}
                                      setRefreshList={(data) => {
                                        if (openArticleDetail)
                                          setOpenArticleDetail({
                                            ...openArticleDetail,
                                            displayPostArticle: true,
                                          });
                                        setRefreshList(data);
                                      }}
                                      isSelected={
                                        selectedItemIndex == key + "," + index
                                      }
                                    />
                                  </div>
                                )
                              })}
                          </div>
                        )
                      })}
                  </li>
                </div>
              </>
            ) : (
              <>
                <div style={{ marginBottom: 50 }}>
                  {noResultFound ? (
                    <EmptyCategoryMessage />
                  ) : (
                    <FlatList
                      renderWhenEmpty={() => <EmptyCategoryMessage />}
                      list={filteredArticles}
                      renderItem={(item, index) => {
                        return (
                          <div
                            ref={(element) => {
                              refs.current[index] = element;
                            }}
                          >
                            <ArticleCardWithQuery
                              key={index + "_" + item.articleId}
                              contactData={contactData}
                              recentSearchId={recentSearchId}
                              contactDataID={contactDataID}
                              openFilter={openFilter}
                              dontShow={dontShow}
                              newsItem={item}
                              isShowName={isShowName}
                              {...props}
                              configData={configData}
                              configLoading={configLoading}
                              onPress={(selected) => {
                                setIsArticleOpened?.()
                                setSelectedItem(item);
                                setSelectedItemIndex(index);
                                if (!!item.articleId) {
                                  callGetFullText(item, selected);
                                } else {
                                  gotoArticle(item, item, selected);
                                }
                              }}
                              setRelevantArticle={relevantArticle}
                              setShowLoader={setShowLoader}
                              setHeaderTags={setHeaderTags}
                              headerTags={headerTags}
                              saveRelevancyData={saveRelevancyData}
                              setSaveRelevancyData={setSaveRelevancyData}
                              deleteRelevancyData={deleteRelevancyData}
                              setDeleteRelevancyData={setDeleteRelevancyData}
                              setRefreshList={(data) => {
                                if (openArticleDetail)
                                  setOpenArticleDetail({
                                    ...openArticleDetail,
                                    displayPostArticle: true,
                                  });
                                setRefreshList(data);
                              }}
                              isSelected={selectedItemIndex == index}
                            />
                          </div>
                        );
                      }}
                      // ListFooterComponent={() => <View style={{height: 80}} />}
                      // onEndReached={onEndReached}
                      // onEndReachedThreshold={0.2}
                      loadMoreItems={onEndReached}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </div>

        {openArticleDetail && (
          <div className={`${Styles.containerRightparantRecentParent}`}>
            {isHeader && <div className={`${Styles.genPdfButtonDiv}`}>
              <WhiteBgButtonGreenBorder
                loading={isPdfGenerating}
                disabled={!isArticleOpened}
                onClick={() => {
                  setIsPdfGenerating(true)
                  generatePdf(
                    "article-content-div",
                    selectedItem?.fullName,
                    () => {
                      setIsPdfGenerating(false)
                    }
                  )
                }}
                style={{ maxWidth: 150, padding: 10, marginRight: 8, marginTop: 10, right: 0, whiteSpace: "nowrap" }}
              >
                <span className={Styles.generatePdfText}>Generate PDF</span>
              </WhiteBgButtonGreenBorder>
            </div>}
            <div
              className={
                isHeader
                  ? Styles.containerRightparantRecent
                  : Styles.containerRightparant
              }
              ref={mainParantScrollRef}
            >
              <Article {...openArticleDetail} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const parseDataForRiskBar = (riskLevels, articleCount) => {
  const newRiskLevels = getEmptyRiskLevels(articleCount);
  Object.keys(newRiskLevels).forEach(
    (riskLevelKey) =>
      (newRiskLevels[riskLevelKey].isSelected = riskLevels[riskLevelKey])
  );
  return newRiskLevels;
};

const getEmptyRiskLevels = (articleCount) => {
  const riskColors = {
    high: "#DA011B",
    medium: "#FB8100",
    low: "#0F9125",
    HIGH: "#FF3D3D",
    MEDIUM: "#FFAD57",
    LOW: "#56DC6D",
    null: "#56DC6D",
  };
  const baseRiskLevel = {
    badgeCounter: 0,
    isSelected: true,
  };
  return {
    high: {
      ...baseRiskLevel,
      amount: articleCount?.highRisk ? articleCount.highRisk : 0,
      title: "High",
      color: riskColors?.high,
    },
    medium: {
      ...baseRiskLevel,
      amount: articleCount?.alerts ? articleCount.alerts : 0,
      title: "Medium",
      color: riskColors?.medium,
    },
    low: {
      ...baseRiskLevel,
      amount: articleCount?.news ? articleCount.news : 0,
      title: "Low",
      color: riskColors?.low,
    },
  };
};

export default SectionListView;
