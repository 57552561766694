import {getFirebaseToken} from './safe-storage'

const deviceInfo = {
    deviceID: '',
    deviceType: "web",
    deviceToken: '',
    os: "web",  // 'ios', 'android', 'macos', 'windows', 'web';
    osVersion: '1',
    appVersion: "1"
}

function getDeviceDetails(){
    const token = getFirebaseToken();
    deviceInfo.deviceToken = token;
    return deviceInfo;
}

export {getDeviceDetails}