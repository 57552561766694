import React, { useState } from 'react';
import { titleCase } from '../../utils/utils'
import Iso3166 from 'iso-3166-2';
import abbrState from '../../utils/apis/getAbbrForState';
import { getFilterImage } from '../../images/filterColorIcon/getFilterImage'
import Variables from '../../utils/Variables'
import back_white from "../../images/svg/next-icon.svg"
import StylesMobile from './potentialMatchesCard.module.css'
import StylesWeb from './potentialMatchesCardWeb.module.css'
import defaultAvatar from "../../images/svg/default-avatar.svg";
import { Avatar } from '@mui/material';
import { DefaultAvatar } from '../../images/svg/default-avatar';

const PotentialMatchesCard = ({
  item,
  index,
  onPotentialSelected,
  onPotentialOptionSelected,
  potentialMatch,
  mStyle,
  isCheckBox = false,
  isNext = true,
  selectedIndex = -1,
  isDesktop,
  isSelected,
}) => {

  const Styles = isDesktop ? StylesWeb : StylesMobile
  let meta_data = [];
  let akaTags = [];
  let count = 0, moreCount = 0
  let maxShowItem = 4
  const [showMore, setShowMore] = useState(false)


  const NextIcon = () => {
    return (
      <img src={back_white} id="back_white_search" />
    )
  }

  const CategoryIcons = ({ summary }) => {

    let iconsList = summary ? summary.filter(item => item.count).sort((a, b) => b.score - a.score) : []
    console.log('iconsList====', JSON.stringify(summary))
    if (iconsList && iconsList.length) {
      return (
        <div className={Styles.scrollmenuCategory1}>
          {iconsList.map((item) => <a key={item.title}>{
            getFilterImage(item.category, true, null, { transform: "scale(.80)", marginRight: 6 })
          }</a>
          )}
        </div>
      )
    } else {
      <></>
    }

  }

  // Change the atom "Tag" when data allows it
  const Tags = (props) => {
    const { terms, style, fullName, akaList } = props;
    return terms?.map((item, index) => {
      if (
        item == null ||
        item == undefined ||
        fullName.toLowerCase() == item.toLowerCase()
      )
        return <></>;
      else {
        item = titleCase(item);
        return <span className={Styles.termTextSavedCardPotential}>{item}</span>
      }
    });
  };

  const AkaList = (props) => {
    const { akaList } = props;
    return akaList?.map((item) => {
      item = titleCase(item);
      return <span className={Styles.termTextSavedCardPotentialAKA}>{'AKA '}
        <span style={{ fontWeight: '400', color: '#142030', marginLeft: 2, textTransform: 'capitalize', fontSize: 14 }}>{item}</span>
      </span>
    });
  };

  if (item?.Place && item?.Place?.length) {
    item?.Place?.forEach(item => {
      let place;
      if (item) {
        if (item?.city) place = item.city;
        let country;
        if (item?.country)
          country = Iso3166.country(item?.country)?.code ?? item?.country;
        if (item?.state) {
          let state =
            country == 'US'
              ? abbrState(item?.state, 'abbr') ?? item.state
              : item.state;
          place = place ? place + ', ' + state : state;
        }
        if (item?.country) place = place ? place + ', ' + country : country;
      }
      if (place && (count < maxShowItem || showMore)) {
        meta_data.push(place)
        count++
      } else if (place)
        moreCount++
    })
    let mCompany = item.Company.map((item, index) => {
      if (index < maxShowItem || showMore) {
        return (item)
      } else {
        moreCount++
        return (null)
      }
    }).filter(item => item != null)
    meta_data = [...meta_data, ...mCompany]

    akaTags = item.akaList.map((item, index) => {
      if (index < maxShowItem || showMore) {
        return (item)
      } else {
        moreCount++
        return (null)
      }
    }).filter(item => item != null)
  }

  return (
    <div className={Styles.body} style={{}}>
      {isCheckBox && <div onClick={() => onPotentialOptionSelected(item, true, index)} style={{ paddingLeft: 10, cursor: 'pointer' }}>
        <div style={{ borderRadius: 8, borderWidth: 1, width: 16, height: 16, borderColor: '#078167', borderStyle: 'solid' }}>
          {index == selectedIndex && <div style={{ marginLeft: 2, marginTop: 2, borderRadius: 6, width: 12, height: 12, backgroundColor: '#078167' }} />}
        </div>
      </div>}
      <div className={Styles.container} style={isSelected ? { ...mStyle, backgroundColor: '#E6F2F0' } : { ...mStyle }} onClick={() => onPotentialSelected(item, true, index)}>
        <div className={Styles.contentContainer}>
          <div className={Styles.searchContainer}>
            <div className={Styles.searchTitleContainer}>
              <Avatar
                className={Styles["profilePictureLinkedIn"]}
                src={item?.linkedinProfile?.profile_picture_src}
              >
                <DefaultAvatar className={Styles.profilePictureLinkedIn} />
              </Avatar>
              <span
                className={Styles.searchTitle}
                numberOfLines={1}>{titleCase(item.Person)}</span>
              <span className={Styles.matchType}></span>
              {isNext && <NextIcon />}
            </div>
            <CategoryIcons summary={item.countByCategory} />
            {Variables?.potential_match_tags_enabled && <div className={Styles.termsSection}>
              {(item && item.phone) && <span className={Styles.termTextSavedCardPotential}>{item.phone}</span>}
              {item.age && <span className={Styles.termTextSavedCardPotential}>Est Age {item.age}</span>}
              <AkaList
                akaList={akaTags}
              />
              <Tags
                terms={meta_data} fullName={item.Person}
              />
              {(moreCount > 0 || showMore) && <div onClick={(e) => {
                e.stopPropagation();
                setShowMore(!showMore)
              }}>
                <span className={Styles.termTextSavedCardPotential}>{showMore ? 'Less' : '+' + moreCount + ' more'}</span>
              </div>}
            </div>}
          </div>
        </div>
      </div>
    </div>
  )
}
export default PotentialMatchesCard