const getContactsListContainerStyle = () => {
  return {
    contactsListContainer: {
      backgroundColor: '#FFFFFF',
      flex: 1,
      flexDirection: "column",
      borderTopLeftRadius: 12,
      borderTopRightRadius: 12,
    },
  }
}
const createStyle = () => {
  const headerTextColor = '#FFF'
  const contactsListContainerStyle = getContactsListContainerStyle()
  return {
    ...contactsListContainerStyle,
    container: {
      flex: 1,
      backgroundColor: '#fff',
    },
    tagListContainer: {
      flexDirection: 'row',
      marginTop: 10,
      margin: 5,
      alignItems: 'center',
      height: '3.33vh',
      backgroundColor: '#00fafa00',
      borderRadius: 4,
      paddingLeft: 5,
      paddingRight: 5,
      borderColor: "rgba(255, 255, 255, 0.15)",
      borderWidth: 1,
      borderRadius: 6,
      maxWidth: '100vw'
    },
    tagListTitle: {
      color: "#FFFFFF",
      fontSize: 14,
      marginLeft: 0,
      fontWeight: '400',
      lineHeight: 16,
      letterSpacing: -0.01,
    },
    flatlistContainer: {
      marginLeft: 10
    },
    headerContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100vw',
    },
    titleContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginLeft: 17,
      marginRight: 17,
      marginTop: 20,
    },
    titleText: {
      fontSize: 21,
      color: headerTextColor,
      fontWeight: 'bold',
    },
   
    filtersIcon: {
      height: 20,
      width: 20,
      alignSelf: 'center',
      tintColor: '#697080',
      marginRight: 15,
    },
    contactsText: {
      color: headerTextColor,
      fontSize: 16,
      marginTop: 5,
    },
    boldContactsText: {
      fontSize: 31,
      fontWeight: 'bold',
    },
    contactsListContainerWithoutRiskBar: {
      ...contactsListContainerStyle.contactsListContainer,
      marginTop: 16,
      paddingTop: 9,
    },
    contactsListContainerBody: {
      flex: 8,
      flexDirection: "column",
    },
    contactListHeaderContainer:{
      flexDirection: 'row',
      marginTop: 15,
      alignItems: 'center'
    },
    contactListTitle: { 
      fontSize: 18,
      fontWeight: "bold",
      marginLeft: 15,
      marginRight: 15,
      flex: 1,
      color: '#fff',
    },
    contactListFiltersContainer: {
      borderColor: '#9EA9C1',
      borderRadius: 5,
      marginEnd: 16,
      borderWidth: 1,
      paddingLeft: 10,
      paddingVertical: 9,
      flexDirection: 'row',
      alignItems: 'center',
    },
    contactListFiltersTitle: {
      marginRight: 15,
      color: '#fff',
      fontSize: 14,
      fontWeight: '600'
    },
    riskBarContainer: {
      marginTop: 15,
    },
    mainIcon: {
      alignSelf: 'center',
      resizeMode: 'contain',
      
    },
    subTitle: {
      color: '#fff',
      fontSize: '3.34vw',
      textAlign: "center",
      marginTop:15
  },
    title:{
      color: '#fff',
      //fontSize: isEqualOrHigherThanIphoneX ? 28 : 24,
      fontSize: '5.5vw',
      textAlign: "center",
      fontWeight: 'bold',
      marginLeft: 46,
      marginRight: 46,
      flex: 2,
    },
    footer: {
      width: '90.90vw',
      marginTop: '5.55vw',
      marginLeft: 16,
      marginRight: 16,
      marginBottom: '25vw',
  },
  leftHeaderContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  backStyle:{
    padding:10,
    paddingRight:15,
    //paddingLeft:0,
  },
  scrollHeader:{
    marginBottom: '11.11vw'
  },
  cardIcon: {
    height: 30,
    width: 30,
    borderRadius: 15,
    borderColor: '#AAA',
    borderWidth: 1,
  },
  headerLeft: {
    container: {
      marginTop: 10,
    },
    fullName: {
      marginLeft: 10,
      color: '#FFF',
      fontSize: 17,
      fontWeight: '600',
      width: '40vw',
    },
  },
  profilePicture: {
    height: 30,
    width: 30,
    borderRadius: 15,
    borderColor: "#FFF",
    borderWidth: 3
  },
  headerRight: {
    container: {
      flexDirection: 'row',
     marginRight: 16,
    },
    editSearchButton: {
      backgroundColor: '#078167',
      paddingVertical: 4,
      paddingHorizontal: 10,
      borderRadius: 6,
    },
    editSearchText: {
      color: '#FFF',
      textAlign: 'center',
      fontSize: 14,
      fontWeight: '600',
    },
    settings: {
      marginLeft: '6.66vw',
      marginRight: '4vw',
      fillColor: '#FFF'
    },
  },
  }
}

export default createStyle
