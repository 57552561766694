import React from 'react'
const CloseIcon = (props) => {
  return (
     <svg
     width={props?.style?.width ?? 10}
     height={props?.style?.height ?? 10}
     fill="none"
     viewBox="0 0 10 10"
     {...props}
   >
     <path
       stroke={props?.color ? props?.color : "#FFFFFF"}
       strokeLinecap="round"
       strokeLinejoin="round"
       strokeWidth="1.5"
       d="M9.167.833L.833 9.167m0-8.334l8.334 8.334"
     ></path>
   </svg>
  )
}

export default CloseIcon
