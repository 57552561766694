// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".filterView_filterShadow__rAICe {\n    border-bottom: 1px solid #EFF1F5;\n}", "",{"version":3,"sources":["webpack://./src/web/components/filtersView/filterView.module.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;AACpC","sourcesContent":[".filterShadow {\n    border-bottom: 1px solid #EFF1F5;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterShadow": "filterView_filterShadow__rAICe"
};
export default ___CSS_LOADER_EXPORT___;
