import React, { useState, useContext } from 'react';
import SubscribeHeaderArc from '../../images/svg/subscribe-header-arc';
import SubscribeLogo from '../../images/svg/subscribe-logo';
import { UserContext } from "../../contexts/userContext";
import { ButtonBase, Modal, Slide } from '@mui/material';
import { GreenBgButton } from '../buttons/greenBgButton';
import Styles from './searchLimitExceedModal.module.css'

const ModelType = {
  SEARCH_LIMIT_MODAL: "SEARCH_LIMIT_MODAL",
  WATCHED_SEARCH_LIMIT_MODAL: "WATCHED_SEARCH_LIMIT_MODAL"
}

const SearchLimitExceedModal = (props) => {
  const {
    title,
    onBackdropPress,
    startFreeTrial,
    goback,
    modelType
  } = props;
  const [modalState, setModalState] = useState(true);
  const { user } = useContext(UserContext);


  let titleMsg = modelType === ModelType.SEARCH_LIMIT_MODAL ? title ? title : `You’ve reached your search limit` : `You've Reached Your Watch Limit`

  let planArray = user.itemId.split(".").splice(-2)
  let descriptionMsg
  let subscribeFerretMsg

  if (modelType === ModelType.SEARCH_LIMIT_MODAL) {
    if (planArray[0] == "premium") {
      descriptionMsg =
        "Unlock Ferret Professional for additional Monthly Searching";
      subscribeFerretMsg = "Subscribe to Ferret Professional";
    } else if (planArray[0] == "pro") {
      descriptionMsg =
        "For Searches over 1000 per month, or monitoring over 100 saved searches contact sales@ferret.ai";
      subscribeFerretMsg = "Contact to Ferret Sales Team";
    } else {
      descriptionMsg = "Unlock Ferret Premium for additional Monthly Searching";
      subscribeFerretMsg = "Subscribe to Ferret Premium";
    }
  } else {
    if (planArray[0] == "premium") {
      descriptionMsg = `Unlock Ferret Professional to enable additional notifications about searches that are important to you.`;
      subscribeFerretMsg = "Subscribe to Ferret Professional";
    } else if (planArray[0] == "pro") {
      descriptionMsg =
        "For Searches over 1000 per month, or monitoring over 100 saved searches contact sales@ferret.ai";
      subscribeFerretMsg = "Contact to Ferret Sales Team";
    } else {
      descriptionMsg = `Unlock Ferret Premium to enable additional notifications about searches that are important to you.`
      subscribeFerretMsg = "Subscribe to Ferret Premium"
    }
  }

  return (
    <Modal
      disableAutoFocus={true} 
      backdropOpacity={0.3}
      open={modalState}
      onBackdropPress={onBackdropPress}
      className={Styles.modal}
    >
      <Slide in={true} direction="up">
        <div className={Styles.container}>
          <div className={Styles.headerArc}>
            <SubscribeHeaderArc className={Styles.headerArc} />
          </div>
          <div className={Styles.logoDivView}>
            <div className={Styles.logoBgView}>
              <SubscribeLogo className={Styles.logo} />
            </div>
          </div>
          <div className={Styles.content}>
            <span className={Styles.textQuestion}>{titleMsg}</span>
            <span className={Styles.textSimple}>{descriptionMsg}</span>
            <div className={Styles.packageTextView}>
              <span className={Styles.packageText}>{subscribeFerretMsg}</span>
            </div>

            <div className={Styles.priceView}>
              {planArray[0] !== "pro" &&
                <>
                <span className={Styles.startingText}>{"STARTING FROM"}</span>
                <span
                  className={Styles.priceText}
                >{`$${user.monthlyPrice?.toFixed(2)}`}</span>
                <span className={Styles.durationText}>{"per month"}</span>
                </>
              }
            </div>


            <div className={Styles.button}>
              <GreenBgButton fullWidth onClick={() => startFreeTrial()}>
                <span
                  className={Styles.learnMoreText}
                  style={{ textTransform: "capitalize", color: "#FFFFFF" }}
                >
                  {planArray[0] == "pro" ? `Contact Sales Team` : `Start your Subscription`}
                </span>
              </GreenBgButton>
            </div>
            <button className={Styles.learnMoreButton}
              onClick={() => goback()}>
              <span className={Styles.learnMoreText}>No Thank You</span>
            </button>
          </div>
        </div>
      </Slide>
    </Modal>
  );
};


export default SearchLimitExceedModal;

export {
  ModelType
}