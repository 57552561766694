// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".darkCardItem_body__6Ooj2 {\n  background-color: #1A233B;\n  border: 1px solid #EFF1F5;\n  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);\n  border-radius: 6px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  padding: 16px;\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://./src/components/cardItem/darkCardItem.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,yBAAyB;EACzB,2CAA2C;EAC3C,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,aAAa;EACb,eAAe;AACjB","sourcesContent":[".body {\n  background-color: #1A233B;\n  border: 1px solid #EFF1F5;\n  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);\n  border-radius: 6px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  padding: 16px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "darkCardItem_body__6Ooj2"
};
export default ___CSS_LOADER_EXPORT___;
