const StarActivated = () =>{
    return (
        <svg 
      width="20px"
      height="20px"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-328 -332)">
          <g transform="translate(15 320)">
            <g transform="translate(313 12)">
              <path d="M0 0H20V20H0z"></path>
              <path
                fill="#FB8100"
                d="M10 15.03887L4.12214748 18.0901699 5.20774985 11.5570965 0.489434837 6.90983006 7.03822653 5.92346854 10 0 12.9617735 5.92346854 19.5105652 6.90983006 14.7922501 11.5570965 15.8778525 18.0901699z"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
    )
}
export default StarActivated