import React from "react";
import Styles from "./watchedSearches.module.css";
import ProfilePicture from "../profilePicture/profilePicture";
import Iso3166 from "iso-3166-2";
import abbrState from "../../../utils/apis/getAbbrForState";
import {
  titleCase,
  capitalizeSearchTermsTag,
  capitalizeCase,
} from "../../../utils/utils";
import RemoveAdvanceSearch from "../../../utils/removeAdvanceSearch";

const WatchedSearchesCard = ({ item }) => {
  let fullName = item.name + (item.lastName ? " " + item.lastName : "");
  let address = "";

  if (
    item?.searchContext &&
    (item?.searchContext.city ||
      item?.searchContext.state ||
      item?.searchContext.country)
  ) {
    address =
      (item.searchContext.city ? item.searchContext.city + " " : "") +
      (item.searchContext.state ? item.searchContext.state + " " : "") +
      (item.searchContext.country ?? "");
  }

  const tags = () => {
    const { searchTerm, metaData, id, searchContext, age, akaList } = item;

    let advancedLine1 = "";
    let advancedLine2 = "";

    let city = searchContext?.city ?? "";

    let country = searchContext?.country
      ? Iso3166.country(searchContext?.country)?.code ?? searchContext?.country
      : "";

    let state =
      country == "US" && searchContext?.state
        ? abbrState(searchContext?.state, "abbr") ?? searchContext?.state
        : searchContext?.state ?? "";

    if (searchContext) {
      advancedLine1 = searchContext?.phone ?? "";
      advancedLine2 = searchContext?.age?.toString()
        ? "Approx. " + searchContext?.age?.toString() + " y/o"
        : "";
      advancedLine1 = titleCase(
        advancedLine1 != "" ? advancedLine1 + " " + city : city
      );
      advancedLine1 = advancedLine1 != "" ? advancedLine1 + " " + state : state;
      advancedLine1 =
        advancedLine1 != "" ? advancedLine1 + " " + country : country;
    }

    let place = "";
    metaData?.Place?.map((item, index) => {
      if (index == 0)
        place = RemoveAdvanceSearch(searchContext, metaData?.Place[0]);
      else {
        place = place + ";" + item;
      }
    });
    metaData?.Company?.map((item) => {
      place =
        place != ""
          ? place + ", " + item.replace(";", " ")
          : item.replace(";", " ");
    });
    metaData?.Location?.map((item) => {
      place =
        place != ""
          ? place + ", " + item.replace(";", " ")
          : item.replace(";", " ");
    });

    const placeData = place ? place.split(";") : "";

    let updatedPlaceData = "";

    if (age) {
      updatedPlaceData = `Est Age ${age}`;
    }

    if (akaList && akaList.length) {
      akaList?.map((item) => {
        if (item)
          updatedPlaceData = updatedPlaceData
            ? updatedPlaceData + ", AKA " + capitalizeSearchTermsTag(item)
            : "AKA " + capitalizeSearchTermsTag(item);
      });
    }

    placeData &&
      placeData?.map((item) => {
        updatedPlaceData = updatedPlaceData
          ? updatedPlaceData + " " + capitalizeSearchTermsTag(item)
          : capitalizeSearchTermsTag(item);
      });

    return (
      <div key={id}>
        <span className={Styles.cardTagTxt}>
          {capitalizeCase(searchTerm, true)}
        </span>
        {advancedLine1 != "" && (
          <div className={Styles.scrollmenuCategory}>
            <span className={Styles.cardTagTxt}>{advancedLine1}</span>
          </div>
        )}
        {advancedLine2 != "" && (
          <div className={Styles.scrollmenuCategory}>
            <span className={Styles.cardTagTxt}>{advancedLine2}</span>
          </div>
        )}
        {updatedPlaceData != "" && (
          <div className={Styles.scrollmenuCategory}>
            <div id="recentPlaceData" className={Styles.cardTagTxt}>
              {updatedPlaceData}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={Styles.rowsCard}>
      <ProfilePicture
        avatarStyle={{
          width: "60px",
          height: "60px",
          backgroundColor: "#C0C7D6",
          borderRadius: 30,
          borderColor: "#078167",
          borderWidth: 2,
          borderStyle: "solid",
          isDarkPic: true,
        }}
        profilePhoto={item.photo}
      />
      <div className={Styles.rightTxt}>
        <span className={Styles.cardNameTxt}>{fullName}</span>
        {tags()}
      </div>
    </div>
  );
};

export default WatchedSearchesCard;
