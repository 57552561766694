import * as React from "react"
const ChangePotentialMatchIcon = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.5 6.5a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM12.5 14v1.05a.26.26 0 0 0 .19.25.26.26 0 0 0 .28-.13l1.92-3.66a2.94 2.94 0 0 1 2.61-1.57 2.2 2.2 0 0 1 .58.07.28.28 0 0 0 .27-.1.28.28 0 0 0 0-.28 4.56 4.56 0 0 0-7.46-.37.26.26 0 0 0 0 .31A7 7 0 0 1 12.5 14ZM.5 18h1.81a.25.25 0 0 1 .25.23L3 23.54a.5.5 0 0 0 .5.46h4a.5.5 0 0 0 .5-.46l.44-5.31a.25.25 0 0 1 .25-.23h1.81a.5.5 0 0 0 .5-.5V14a5.5 5.5 0 0 0-11 0v3.5a.5.5 0 0 0 .5.5ZM5.5 7a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7ZM11.16 21.91a1.43 1.43 0 0 0 .58 1.908c.216.121.461.184.71.182h10.11a1.43 1.43 0 0 0 1.28-2.09l-5.06-9.64a1.45 1.45 0 0 0-2.56 0l-5.06 9.64Zm5.34-.66a1 1 0 1 1 2.001 0 1 1 0 0 1-2.001 0Zm1.75-2.75a.75.75 0 0 1-1.5 0V16a.75.75 0 1 1 1.5 0v2.5Z"
      fill="#FB8100"
    />
  </svg>
)

export default ChangePotentialMatchIcon
