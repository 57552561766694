import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import {
  forwardRef,
  useEffect,
  useState,
  useImperativeHandle,
  useRef,
} from "react";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import Styles from "./searchSelect.module.css";

const highlightedCountries = ["United States", "Canada", "Australia", "India"];

const SearchSelect = (
  {
    value,
    setValue,
    icon,
    label,
    options,
    type,
    inputType = "none",
    lableKey,
    defaultValue,
    country,
    setState,
  },
  ref
) => {

  const [stateAbbr, setStateAbbr] = useState();
  const [open, setOpen] = useState(false);
  const refObj = useRef();

  useEffect(() => {
    setStateAbbr();
  }, [country]);

  useEffect(() => {
    if (type == "state" && value == "") {
      setStateAbbr(value);
    }
  }, [value]);

  useImperativeHandle(ref, () => ({
    handleOpen: () => {
      setOpen(true);
    },
    focus: () => {
      refObj?.current?.focus();
    },
  }));

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div className={Styles.searchtextField}>
      {icon && <img className={Styles.searchFiledIcon} src={icon} />}
      <FormControl fullWidth size="small">
        <Select
          displayEmpty={true}
          labelId="select-standard-label"
          inputRef={refObj}
          type={inputType}
          placeholder="State"
          label={label}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={value == "" || value == undefined ? "" : value}
          onChange={(event, child) => {
            setStateAbbr(child?.props["data-abbr"]);
            if (type != "state") {
              setState();
            }
            setValue(event.target.value);
          }}
          defaultValue={defaultValue}
          inputProps={{
            sx: {
              id: "uncontrolled-native",
              color: value == "" || value == undefined ? "#142030" : "#142030",
              fontFamily: "Proxima Nova",
              fontStyle: "normal",
              fontWeight: "400",
              marginLeft: type == "state" ? "-10px" : "10px",
              fontSize: "16px",
            },
          }}
          renderValue={() => {
            return type == "state" ? (
              country == "United States" && stateAbbr ? (
                stateAbbr
              ) : value == "" || value == undefined ? (
                label
              ) : (
                options.filter((item) => {
                  return item.value == value;
                })[0]?.inputLabel
              )
            ) : value ? (
              value
            ) : (
              <div style={{ paddingTop: 6 }}>{stateAbbr}</div>
            );
          }}
        >
          {options.map((item, index) => {
            return (
              <MenuItem
                key={`ss_menu_${index}`}
                style={{
                  fontWeight: highlightedCountries.includes(item.value)
                    ? "bold"
                    : "normal",
                }}
                value={item.value}
                data-abbr={lableKey ? item[lableKey] : item.label}
              >
                {item.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};
export default forwardRef(SearchSelect);
