import react, { useState } from "react";
import tickGreen from "../../../images/svg/tick-green.svg";
import termService from "../../../images/svg/term_service.svg";
import { GreenBgButton } from "../../../components/buttons/greenBgButton";
import { useNavigate } from "react-router-dom";
import TwoBtnAlert from "../../../components/alert/twoBtnAlert";
import Styles from "./acceptTermsOfServiceWeb.module.css";
import Footer from "../../layout/Footer";

const AcceptTermsOfServiceWeb = () => {
  const navigate = useNavigate();
  const [isAgree, setAgree] = useState(false);

  return (
    <div className={Styles.container}>
      <div className={Styles.bodyAcceptTerms}>
        <div className={Styles.main}>
          <img
            src={termService}
            className={Styles.termService}
            alt="termService"
          />
          <div className={Styles.termCondition}>Terms and Conditions</div>
          <div className={Styles.lastReview}>Last Revised on July 7, 2022</div>
          <div className={Styles.termText}>
            By choosing “Accept” below, you agree with the{" "}
            <a
              className={Styles.termTextGreen}
              style={{ textDecoration: "none" }}
              href="https://www.ferret.ai//terms-of-use"
              target="_blank"
            >
              Terms and Conditions
            </a>{" "}
            and that you have read and understand the{" "}
            <a
              className={Styles.termTextGreen}
              style={{ textDecoration: "none" }}
              href="https://www.ferret.ai//privacy-policy"
              target="_blank"
            >
              Privacy Policy.
            </a>{" "}
            <br></br>
            <br></br> The Terms and Conditions contain important provisions for
            you to consider, including:
          </div>
          <div className={Styles.termTextLine}>
            <img src={tickGreen} className={Styles.tickGreen} />
            Ferret is not a consumer reporting agency and does not produce the
            consumer reports under the Fair Credit Reporting Act (FCRA) and
            related laws.
          </div>
          <div className={Styles.termTextLine}>
            <img src={tickGreen} className={Styles.tickGreen} />
            You may not use Ferret or the information available through Ferret
            to make decisions about consumer credit, employment, insurance,
            tenant screening, or any other purpose prohibited by the FCRA and
            related laws.
          </div>
          <div className={Styles.termTextLine}>
            <img src={tickGreen} className={Styles.tickGreen} />
            Ferret is not responsible for the accuracy of information made
            available through Ferret.
          </div>
          <div className={Styles.termTextLine}>
            <img src={tickGreen} className={Styles.tickGreen} />
            You agree to an arbitration agreement and class action waiver.
          </div>
          <div className={Styles.btView}>
            <GreenBgButton
              onClick={() => setAgree(true)}
              variant="contained"
              style={{
                width: 160,
                marginLeft: 20,
                marginRight: 20,
              }}
            >
              <div className={Styles.acceptBtnTxt}>Accept</div>
            </GreenBgButton>
          </div>
        </div>
      </div>

      {isAgree && (
        <TwoBtnAlert
          isCapitalize={true}
          title={"Accept Terms"}
          description={
            "By continuing, you agree to Ferret's Terms & Conditions"
          }
          leftBtnTxt={"Cancel"}
          rightBtnTxt={"Agree"}
          onLeftBtnClick={() => {
            setAgree(false);
          }}
          onRightBtnClick={() => {
            setAgree(false);
            navigate("/signUpLanding");
          }}
        />
      )}

      <div className={Styles.footer}>
        <Footer isCenterCopyright={true} />
      </div>
    </div>
  );
};

export default AcceptTermsOfServiceWeb;
