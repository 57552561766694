// Constants
import { useContext, useEffect, useState } from "react";
import NotificationListView from "../../components/NotificationListView/notificationListView";
import ImportContactsBanner from "../../components/importContactsBanner/importContactsBanner";
import Styles from "./dashboard.module.css";

import { useMutation } from "@apollo/react-hooks";
import { useLazyQuery } from "react-apollo";
import { useLocation, useNavigate } from "react-router-dom";
import PushNotificationStatus from "../../../components/pushNotification/PushNotificationStatus";
import {
  OnboardingContext,
  OnboardingStates,
  getNextOnboardingStatus,
} from "../../../contexts/onboardingContext";
import { UserContext } from "../../../contexts/userContext";
import { FingerPrintLogo } from "../../../images/svg/fingerPrintLogo";
import {
  GET_SAVED_SEARCHES,
  ME,
  UPDATE_DEVICE_TOKEN,
  UPDATE_FERRET_USER,
} from "../../../queries/queries";
import { setUserInfo, userInfo } from "../../../root-navigation";
import { accountOverlayStore } from "../../../store/accountOverlayStore";
import { closestContactsStore } from "../../../store/closestContactsStore";
import { dashboardStore } from "../../../store/dashboardStore";
import { getDeviceDetails } from "../../../utils/DeviceDetails";
import Variables from "../../../utils/Variables";
import DarkCardItem from "../../components/cardItem/darkCardItem";
import ContactsCard from "../../components/contactsImported/contactsCard";
import RecentlyActivty from "../../components/recentlyActivtyCard/recentlyActivty";
import RecentlyUpdatedContacts from "../../components/recentlyUpdatedContacts/recentlyUpdatedContacts";
import ShareFerret from "../../components/shareFerrerCard/shareferret";
import WatchedSearches from "../../components/watchedSearchesCard/watchedSearches";
import { notificationStore } from "../../../store/notificationStore";
import { searchFormStore } from "../../../store/searchFormStore";
import { searchResultStore } from "../../../store/searchResultStore";
import NotificationTooltip from "../../../components/onboardingTooltips/notificationTooltip";
import CompleteTooltip from "../../../components/onboardingTooltips/completeTooltip/completeTooltip";
import { appStore } from "../../../store/appStore";
import { fetchFirebaseToken, initializeMessaging } from "../../../utils/firebase";
import { setFirebaseToken } from "../../../utils/safe-storage";

let searchTitle = "";
let isCalled = false

/**
 * Dashboard Page
 * @constructor
 */
const DashboardPage = ({ isDesktop }) => {

  const openSubscriptionAppStore = appStore((state) => state.openSubscription)
  const setOpenSubscriptionAppStore = appStore((state) => state.setOpenSubscription)

  const setOpenNotification = notificationStore(
    (state) => state.setOpenNotification
  );

  const openSubscription = accountOverlayStore(
    (state) => state.openSubscription
  );
  const setOpenSubscription = accountOverlayStore(
    (state) => state.setOpenSubscription
  );

  const selectedBtn = dashboardStore((state) => state.selectedBtn);
  const setSelectedBtn = dashboardStore((state) => state.setSelectedBtn);
  const removeAllSearchForm = searchFormStore((state) => state.removeAll);
  const removeAllSearchResult = searchResultStore((state) => state.removeAll);
  const { user, setUser } = useContext(UserContext);
  const [isSearchLimitExceed, setSearchLimitExceed] = useState(false);
  const [isHighlightSearchMe, setHighlightSearchMe] = useState(true);
  const [showImportContactBanner, setShowImportContactBanner] = useState();
  const [updateUser] = useMutation(UPDATE_FERRET_USER);
  const navigate = useNavigate();
  const { state } = useLocation();
  const location = useLocation();
  const [showSplash, setShowSplash] = useState(false); //state?.showSplash - code commmented, it's showing again when go back to dashboard.
  const [getUserInfo, { loading, error, data }] = useLazyQuery(ME);
  const { onboardingState, setOnboardingState } = useContext(OnboardingContext);
  const changeOnboardingState = () =>
    setOnboardingState(getNextOnboardingStatus(onboardingState, true));
  const contactData = location?.state?.contactData ?? {};

  useEffect(() => {
    if (state?.showSplash === true) setShowSplash(state?.showSplash);
    else setShowSplash(false);

    if (state?.screen == "Searches") {
      setSelectedBtn(state?.screen);
      searchTitle = state?.searchTitle;
    }

    return () => {
      searchTitle = "";
    };
  }, [state]);

  useEffect(() => {
    if (showSplash) {
      setTimeout(() => {
        navigate({ state: { ...state, showSplash: false } });
        setShowSplash(false);
      }, 1000);
    }
  }, [showSplash]);

  const onBoardingStateDashboard = [
    null,
    OnboardingStates.newSearch,
    OnboardingStates.recentActivity,
    OnboardingStates.savedSearches,
    OnboardingStates.completed,
    OnboardingStates.notificationBanner,
    OnboardingStates.notificationBannerEnd,
  ];

  const startFreeTrial = async () => {
    setOpenSubscription(true);
    setSearchLimitExceed(false);
  };
  const navigateToDashboard = async () => {
    setOpenSubscription(false);
    setSearchLimitExceed(false);
  };

  useEffect(() => {
    if (openSubscriptionAppStore) {
      setOpenSubscriptionAppStore(false)
      setOpenSubscription(true)
    }
    
    updateDeviceInfo();
    removeAllSearchForm();
    removeAllSearchResult();
  }, []);

  const [updateUserDevice] = useMutation(UPDATE_DEVICE_TOKEN);

  const updateDeviceInfo = async () => {
    const deviceInfo = await getDeviceDetails();
    updateUserDevice({
      variables: {
        deviceDetails: deviceInfo,
      },
    });
  };

  useEffect(() => {
    getUserInfo();

    fetchFirebaseToken(setFirebaseToken)
      .then((response) => {
        if (!isCalled) {
          isCalled = true
          updateDeviceInfo()
          initializeMessaging()
        }
      })
      .catch(error => console.error(error))

    return () => {
      isCalled = false
    }
  }, []);

  useEffect(() => {
    if (!loading && data) {
      let localUser = setUserInfo(data.me)
      setUser(localUser);
    }
  }, [loading, data]);

  useEffect(() => {
    // window.scrollTo(0,0);
  }, [selectedBtn]);

  useEffect(() => {
    async function fetchData() {
      if (
        (!userInfo.isOnboarded &&
          onboardingState === OnboardingStates.filterHeader) ||
        (!userInfo.notificationOnboardingDone &&
          (onboardingState === OnboardingStates.end ||
            onboardingState === OnboardingStates.filterHeader))
      ) {
        const res = await updateUser({
          variables: {
            advancedSearchOnboardingDone: true,
            isOnboarded: true,
            notificationOnboardingDone: true, //showing notification tile by default - C4R-5655
            // notificationOnboardingDone: Variables.isContactImported, //expected - if contacts imported then notification boarding will be shown already.
          },
        });
        const newUserInfo = {
          ...userInfo,
          isOnboarded: res.data.updateUser.isOnboarded,
          notificationOnboardingDone:
            res.data.updateUser.notificationOnboardingDone,
        };
        let u = setUserInfo(res.data.updateUser);
        setUser({ ...u });
      }
    }
    fetchData();
  }, [onboardingState]);

  useEffect(() => {
    getStarContact();
  }, [selectedBtn]);

  const [getStarContact, { loading: loadingContacts, data: dataContacts }] =
    useLazyQuery(GET_SAVED_SEARCHES, { fetchPolicy: "no-cache" });

  useEffect(() => {
    if (!loadingContacts && dataContacts) {
      // let mItem = [...cardItems].filter(item => item.type != 'onboardingContact' && item.type != 'onboardingStar' && item.type != 'closestContacts' && item.type != 'inTheNews')
      if (dataContacts && dataContacts?.getSavedSearches?.totalContacts) {
        if (
          dataContacts &&
          dataContacts?.getSavedSearches?.contacts &&
          dataContacts?.getSavedSearches?.contacts?.length <
          dataContacts?.getSavedSearches?.totalContacts
        ) {
          setShowImportContactBanner(false);
          Variables.isContactImported = true;
        } else {
          Variables.isContactImported = false;
          setHighlightSearchMe(false);
          setShowImportContactBanner(true);
        }
      } else {
        Variables.isContactImported = false;
        setHighlightSearchMe(false);
        setShowImportContactBanner(true);
        // setCardItems([onBoardingContactItem].concat([inTheNews].concat([closestContacts].concat(mItem))))
      }
    }
  }, [loadingContacts, dataContacts]);

  useEffect(() => {
    if (contactData) {
      if (contactData?.getSavedSearches?.totalContacts) {
        if (
          contactData &&
          contactData?.getSavedSearches?.contacts &&
          contactData?.getSavedSearches?.contacts?.length <
          contactData?.getSavedSearches?.totalContacts
        ) {
          setShowImportContactBanner(false);
          Variables.isContactImported = true;
        } else {
          Variables.isContactImported = false;
          setHighlightSearchMe(false);
          setShowImportContactBanner(true);
        }
      } else {
        Variables.isContactImported = false;
        // setHighlightSearchMe(false)
        // setShowImportContactBanner(true);
        // setCardItems([onBoardingContactItem].concat([inTheNews].concat([closestContacts].concat(mItem))))
      }
    }
  }, []);

  const onOpenNotification = () => {
    setOpenNotification(true);
  };

  useEffect(() => {
    if (location?.pathname == "/notification") setOpenNotification(true);
  }, []);

  return (
    <div className={Styles.container}>
      <h1 className={Styles.headerText}>
        Dashboard
      </h1>
      <div className={Styles.containerBody}>
        <div className={Styles.containerCenter}>
          {user?.isSubscribed == false ? (
            <div style={{ margin: "0 3px 16px" }}>
              <DarkCardItem
                title="Unlock More with Premium"
                description="Unlock the full power of Ferret with additional searches and monitoring"
                name="Start 7-day Free Trial"
                descriptionIcon={<FingerPrintLogo className="fingerprintLogo" />}
                imgSpanStyle={{ height: "20%", marginTop: -25 }}
                descriptionTextBoxStyle={{ minHeight: "90px" }}
                onClick={() => {
                  setOpenSubscription(true);
                }}
              />
            </div>
          ) : (
            <></>
          )}
          {showImportContactBanner &&
            <div style={{ margin: "0 3px 16px" }}>
              <ImportContactsBanner />
            </div>
          }
          <div>
            {onboardingState === OnboardingStates.notificationBanner ||
              onboardingState === OnboardingStates.notificationBannerEnd ? (
              <NotificationTooltip
                onClick={() => changeOnboardingState()}
                isWeb={true}
              >
                <NotificationListView
                  onOpenNotification={onOpenNotification}
                />
              </NotificationTooltip>
            ) : (
              <NotificationListView
                onOpenNotification={onOpenNotification}
              />
            )}
          </div>
          {/* <RecentlyUpdatedContacts /> */}
        </div>
        <div className={Styles.containerRightparant}>
          <div className={Styles.containerRight}>
            <ContactsCard />
            <WatchedSearches />
            <RecentlyActivty />
            <ShareFerret />
          </div>
        </div>
      </div>
      {(!onBoardingStateDashboard.includes(onboardingState) ||
        (showImportContactBanner &&
          onboardingState === OnboardingStates.notificationBannerEnd)) &&
        user.neverShowNotifications === false && <PushNotificationStatus />}
      <CompleteTooltip isWeb={true} />
    </div>
  );
};

// Page export
export default DashboardPage;
