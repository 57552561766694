import { create } from 'zustand'

export const savedSearchesStore = create((set) => ({
  savedSearches: null,
  isProcessRefresh: false,
  potentialModalVisible: false,
  potentialData: [],
  contactID: undefined,
  nsavedSearches: [],
  modalVisible: false,
  contactImported: 0,
  contacts: [],
  categories: [],
  orignalContacts: [],
  riskLevelsStatuses: {
    high: true,
    medium: true,
    low: true,
  },
  searchValue: undefined,
  appliedFilter: false,
  showConfirmModel: false,
  isProcessed: false,
  isMissingName: false,
  topRiskHeight: 150,
  availableFilterList: [],
  selectedFilter: '',
  isSearchLimitExceed: false,
  subscriptionModalVisible: false,
  statusesOfFilters: [],
  temporaryStatusesOfFilters: [],
  filterList: [],
  filterPossion: 0,
  availableCategory: [],
  selectedCategory: [],
  fromMultipleSavedSearch: false,
  selectedPage: undefined,
  savedSearchPosition: 0,
  searchValue: '',
  googleCheck: true,
  phoneCheck: true,
  savedCheck: true,
  iCloudCheck: true,
  providers: [],
  oldPageName: '',
  setSearchValue: (data) => set(() => ({ searchValue: data })),
  setSavedSearchPosition: (data) => set(() => ({ savedSearchPosition: data })),
  setSelectedPage: (data) => set(() => ({ selectedPage: data})),
  setFromMultipleSavedSearch: (data) => set(() => ({ fromMultipleSavedSearch: data})),
  setSelectedCategory: (data) => set(() => ({ selectedCategory: data })),
  setAvailableCategory: (data) => set(() => ({ availableCategory: data })),
  setFilterPossion: (data) => set(() => ({ filterPossion: data })),
  setFilterList: (data) => set(() => ({ filterList: data })),
  setStatusesOfFilters: (data) => set(() => ({ statusesOfFilters: data })),
  setTemporaryStatusesOfFilters: (data) => set(() => ({ temporaryStatusesOfFilters: data })),
  setNsavedSearches: (data) => set(() => ({ nsavedSearches: data })),
  setModalVisible: (data) => set(() => ({ modalVisible: data })),
  setContactImported: (data) => set(() => ({ contactImported: data })),
  setContacts: (data) => set(() => ({ contacts: data })),
  setCategories: (data) => set(() => ({ categories: data })),
  setOrignalContacts: (data) => set(() => ({ orignalContacts: data })),
  setRiskLevelsStatuses: (data) => set(() => ({ riskLevelsStatuses: data })),
  setSearch: (data) => set(() => ({ searchValue: data })),
  setAppliedFilter: (data) => set(() => ({ appliedFilter: data })),
  setShowConfirmModel: (data) => set(() => ({ showConfirmModel: data })),
  setProcessed: (data) => set(() => ({ isProcessed: data })),
  setMissingName: (data) => set(() => ({ isMissingName: data })),
  setTopRiskHeight: (data) => set(() => ({ topRiskHeight: data })),
  setAvailableFilter: (data) => set(() => ({ availableFilterList: data })),
  setSelectedFilter: (data) => set(() => ({ selectedFilter: data })),
  setSearchLimitExceed: (data) => set(() => ({ isSearchLimitExceed: data })),
  setSubscriptionModalVisible: (data) => set(() => ({ subscriptionModalVisible: data })),
  setSavedSearches: (data) => set(() => ({ savedSearches: data })),
  setProcessRefresh: (data) => set(() => ({ isProcessRefresh: data })),
  setPotentialModalVisible: (data) => set(() => ({ potentialModalVisible: data })),
  setPotentialData: (data) => set(() => ({ potentialData: data })),
  setContactID: (data) => set(() => ({ contactID: data})),

  setGoogleCheck: (data) => set(() => ({ googleCheck: data})),
  setPhoneCheck: (data) => set(() => ({ phoneCheck: data})),
  setSavedCheck: (data) => set(() => ({ savedCheck: data})),
  setICloudCheck: (data) => set(() => ({ iCloudCheck: data})),
  setProviders: (data) => set(() => ({ providers: data})),
  setOldPageName: (data) => set(() => ({ oldPageName: data})),

  removeAll: () => set({ 
    savedSearches: null,
    isProcessRefresh: false,
    potentialModalVisible: false,
    potentialData: [],
    contactID: undefined,
    nsavedSearches: [],
    modalVisible: false,
    contactImported: 0,
    contacts: [],
    categories: [],
    orignalContacts: [],
    riskLevelsStatuses: {
      high: true,
      medium: true,
      low: true,
    },
    searchValue: undefined,
    appliedFilter: false,
    showConfirmModel: false,
    isProcessed: false,
    isMissingName: false,
    topRiskHeight: 150,
    availableFilterList: [],
    selectedFilter: '',
    isSearchLimitExceed: false,
    subscriptionModalVisible: false,
    filterList: [],
    filterPossion: 0,
    availableFilter: [],
    selectedCategory: [0],
    fromMultipleSavedSearch: false,
    savedSearchPosition: 0,
    searchValue: '',
    googleCheck: true,
    phoneCheck: true,
    savedCheck: true,
    iCloudCheck: true,
    providers: [],
    oldPageName: ''
  }),
}))