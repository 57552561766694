const mockArticles = {
  1: {
    //title - summary
    itemType: "CC",
    pageURL: "https://www.courtlistener.com/opinion",
    imageUrl: null,
    source: "Court Cases",
    timestamp: null,
    scriptName: null,
    title: "John Winston Ono Lennon v. Immigration and Naturalization Service",
    summary:
      "The holding here will undoubtedly and unfortunately result in the abandonment of Lennon's claim of selective prosecution now pending in the Southern District Court. If others found guilty of the same crime have been permitted entry and Lennon has been barred because he is John Lennon, the jongleur, and not John Doe, then that contention should be litigated not only in the interests of Lennon and INS but the public as well.",
  },
  2: {
    //title - details
    itemType: "OC",
    pageURL: "https://opencorporates.com/companies/gb/05915216",
    imageUrl: null,
    source: "Open Corporates",
    timestamp: null,
    scriptName: null,
    title: "GAMTRAIN LIMITED | ALEX JOHN LENNON",
    details: [
      { key: "Current Status", value: "Active" },
      { key: "Registered Address", value: "19 - 21 Bridgeman Terrace" },
      { key: "Company Type", value: "Private Limited Company" },
      {
        key: "Incorporation Date",
        value: "24 August 2006 (about 14 years ago)",
      },
    ],
  },
  3: {
    // title summary details
    itemType: "OC",
    pageURL: "https://www.courtlistener.com/recap/",
    imageUrl: null,
    source: "Open Corporates",
    timestamp: null,
    scriptName: null,
    title: "GAMTRAIN LIMITED | ALEX JOHN LENNON",
    summary:
      "The holding here will undoubtedly and unfortunately result in the abandonment of Lennon's claim of selective prosecution now pending in the Southern District Court. If others found guilty of the same crime have been permitted entry and Lennon has been barred because he is John Lennon, the jongleur, and not John Doe, then that contention should be litigated not only in the interests of Lennon and INS but the public as well.",
    details: [
      { key: "Current Status", value: "Active" },
      { key: "Registered Address", value: "19 - 21 Bridgeman Terrace" },
      { key: "Company Type", value: "Private Limited Company" },
      {
        key: "Incorporation Date",
        value: "24 August 2006 (about 14 years ago)",
      },
    ],
  },
  4: {
    // justitle
    itemType: "OC",
    pageURL: "https://offshoreleaks.icij.org/nodes/10024097",
    imageUrl: null,
    source: "Open Corporates",
    timestamp: null,
    scriptName: null,
    title: "GAMTRAIN LIMITED | ALEX JOHN LENNON",
  },
};

export { mockArticles };
