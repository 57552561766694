import React, { useState } from 'react'
import { useMutation } from 'react-apollo'
import { SAVE_SELF_SEARCH } from '../../queries/queries'
import { setUserSearchMe } from '../../root-navigation';
import Variables from '../../utils/Variables'

const SaveSearchQuery = (setUser) => {
    const [saveSelfSearch, { data, loading, error }] = useMutation(SAVE_SELF_SEARCH, {
        fetchPolicy: 'no-cache'
    })
    const saveSearchMe = reqVal => { saveSelfSearch(reqVal); }

    const saveSearchAndParse = (saveSearchdata, initialSearchString) => {

        const {
            Company = '',
            Location = '',
            Nationality = '',
            Person = '',
            Place = '',
        } = saveSearchdata;

        let name = Person && Person !== '' ? Person : initialSearchString;

    let data = {
      name:
        name.split(" ").length === 1 ? name : name.substr(0, name.indexOf(" ")),
      lastName:
        name.split(" ").length > 1 ? name.substr(name.indexOf(" ") + 1) : "",
      company: Company,
      city: Place,
      region: Location,
      country: Nationality,
    };
    let u = setUserSearchMe(data);
    setUser(u);

        Variables.isSearchMeChanged = true;
        saveSelfSearch({
            variables: {
                data: data
            }
        })
    }
    return {
        saveSearchMe,
        saveSearchAndParse,
    }
}

export default SaveSearchQuery
