import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useLayoutEffect,
} from "react";
import CheckFilter from "./checkFilter";
import CheckFilterRecent from "./checkFilter/checkFilterRecent";
import createStyle from "./styles";
import { useMutation } from "react-apollo";
import Variables from "../../../utils/Variables";
import { UPDATE_FERRET_USER } from "../../../queries/queries";
import { setUserInfo } from "../../../root-navigation";
import { UserContext } from "../../../contexts/userContext";
import { LinearProgress, Box } from "@mui/material";
import {
  OnboardingContext,
  getNextOnboardingStatus,
} from "../../../contexts/onboardingContext";
import "./style.css";
import Styles from "./filterView.module.css";
import { searchResultStore } from "../../../store/searchResultStore";

const FiltersView = (props) => {
  const {
    onFilterCompleted,
    articleType,
    statusesOfFiltersOld,
    resetFilter = false,
    selectedFilter,
    setSelectedFilter,
    isAllShow = false,
    processingArticle,
    filterRef,
    setFilterHeight,
  } = props;
  const scrollRef = useRef();
  const style = createStyle();
  const [statusesOfFilters, setStatusesOfFilters] =
    useState(statusesOfFiltersOld);
  const [showFilter, setShowFilter] = useState(false);
  const [filterClicked, setFilterClicked] = useState(false);
  const [selectedfilterIndex, setSelectedfilterIndex] = useState(-1);
  const [isGraterthen, setGraterthen] = useState(false);
  const [showFilterHeaderToolTip, setShowFilterHeaderToolTip] = useState(false);

  const openFromMap = searchResultStore((state) => state.openFromMap);
  const setOpenFromMap = searchResultStore((state) => state.setOpenFromMap);
  const filteredArticles2 = searchResultStore(
    (state) => state.filteredArticles2
  );

  const { onboardingState, setOnboardingState } = useContext(OnboardingContext);
  const changeOnboardingState = () =>
    setOnboardingState(getNextOnboardingStatus(onboardingState));

  const { user, setUser } = useContext(UserContext);
  const [userUpdaterMutation, { loading: loadingDontShow }] = useMutation(
    UPDATE_FERRET_USER,
    {
      fetchPolicy: "no-cache",
      onCompleted: ({ updateUser }) => {
        let u = setUserInfo(updateUser);
        setUser({ ...u });
      },
    }
  );

  useEffect(() => {
    setShowFilterHeaderToolTip(isGraterthen && !user.adhocSortFilterTooltip);
  }, [isGraterthen, user.adhocSortFilterTooltip]);

  useEffect(() => {
    if (articleType) {
      let filterData = createFilters();
      const { label, updatedData } = checkFilterHasData(filterData, selectedFilter)
      Variables.statusesOfFilters = updatedData;
      setStatusesOfFilters(updatedData);
      if (!resetFilter) {
        onFilterCompleted(updatedData);
        // setSelectedFilter(label)
        // setSelectedfilterIndex(0);
      }
      setShowFilter(true);
      setGraterthen(articleType?.filter((item) => item?.count > 100)?.length);
    }
  }, [articleType]);


  useEffect(() => {
    if (openFromMap) {
      let filterData = createFilters();
      const { label, updatedData } = checkFilterHasData(filterData, selectedFilter)
      onFilterCompleted(updatedData);
      if (selectedFilter?.length > 0) {
        setStatusHandler?.("Type of Article", selectedFilter)
      }
      setTimeout(() => {
        setOpenFromMap(false)
      }, 1500)
    }
  }, [selectedFilter])

  const checkFilterHasData = (data, label) => {
    const filters = data[0].filters;

    let selectedFilter = filters.find(filter => filter.label === label && filter.count > 0);

    if (!selectedFilter) {
      selectedFilter = filters.find(filter => filter.count > 0);
    }

    if (selectedFilter) {
      filters.forEach(filter => filter.status = (filter.label === selectedFilter.label));
      return { label: selectedFilter.label, updatedData: data };
    }

    return { label: null, updatedData: data };
  };


  useEffect(() => {
    if (selectedfilterIndex >= 0) {
      let scrollToVal = 0;
      if (selectedfilterIndex > 0) {
        scrollToVal = selectedfilterIndex * 120;
      }
      scrollRef?.current?.scrollTo({ left: scrollToVal });
    }
  }, [filterClicked, selectedfilterIndex]);

  const createFilterSection = (title, articleType) => {
    let selectedIndex = -1;
    let allCount = 0;
    let itemType = ["ALL"];
    let riskCode = ["ALL"];
    let isFirst = true;
    let filterList = articleType.map((item, index) => {
      if (selectedFilter && selectedFilter == item.category)
        selectedIndex = index;
      else if (statusesOfFilters?.[0]?.filters?.[index + 1]?.status) {
        selectedIndex = index;
        isFirst = false;
      }
      allCount = allCount + item.count;
      return {
        label: item.category,
        itemType: item.itemTypes,
        riskCode: item.riskCodes,
        count: item.count,
        status:
          statusesOfFilters?.[0]?.filters[index + (isAllShow ? 1 : 0)]
            ?.status === undefined
            ? selectedFilter
              ? selectedFilter == item.category
              : isAllShow
                ? false
                : index == 0
            : statusesOfFilters?.[0]?.filters[index + (isAllShow ? 1 : 0)]
              ?.status,
      };
    });
    let allType = {
      label: "All",
      count: allCount,
      itemType: itemType,
      riskCode: riskCode,
      status: selectedIndex == -1 ? true : false,
    };
    if (isAllShow) filterList.unshift(allType);
    const filterItems = {
      title: title,
      filters: filterList,
    };
    if (isFirst) setSelectedfilterIndex(selectedIndex + (isAllShow ? 1 : 0)); // for auto scroll tabbar
    return filterItems;
  };

  const createFilters = () => {
    return [createFilterSection("Type of Article", articleType)];
  };

  const createArticleFilters =
    (key) =>
      ({ label, status, count }) => {
        if (count > 0) {
          if (isAllShow)
            return (
              <CheckFilterRecent
                count={count}
                label={label}
                status={status}
                iconType="blackWhite"
                setStatus={() => setStatusHandler(key, label, count)}
              />
            );
          return (
            <CheckFilter
              count={count}
              label={label}
              status={status}
              iconType="blackWhite"
              setStatus={() => setStatusHandler(key, label, count)}
            />
          );
        }
      };

  const setStatusHandler = (key, label, count) => {
    let selectedIndex = -1;
    let filterLabel = "";
    const newStatusesOfFilters = statusesOfFilters?.map((filtersOfSection) => {
      const { title, filters } = filtersOfSection;
      if (title === key) {
        return {
          ...filtersOfSection,
          filters: filters.map((filter, index) => {
            if (filter.label === label) {
              selectedIndex = index;
              filterLabel = filter.label;
              return {
                ...filter,
                status: true,
              };
            } else {
              return {
                ...filter,
                status: false,
              };
            }
            return filter;
          }),
        };
      }
      return filtersOfSection;
    });
    if (statusesOfFilters?.length > 0) {
      setSelectedfilterIndex(selectedIndex);
      setSelectedFilter(filterLabel);
      setStatusesOfFilters(newStatusesOfFilters);
      onFilterCompleted(newStatusesOfFilters);
    }
  };

  const riskLevel = Variables.riskLevelsStatuses;
  const updatedStatusesOfFilters =
    statusesOfFilters &&
    statusesOfFilters.map(({ title, filters }) => {
      const filteredData = filters.map((item) => {
        return { ...item, count: 0 };
      });
      return { title: title, filters: filteredData };
    });
  let renderedFilterStatus = statusesOfFilters;
  if (riskLevel) {
    if (!riskLevel?.high && !riskLevel?.low && !riskLevel?.medium) {
      renderedFilterStatus = updatedStatusesOfFilters;
    }
  }

  const sections =
    renderedFilterStatus &&
    renderedFilterStatus.map(({ title, filters }) => {
      return (
        <>
          {filterClicked ? (
            <div style={style.filters}>
              {filters.map(createArticleFilters(title))}
            </div>
          ) : (
            <div
              id="filterViewSections"
              style={
                isAllShow
                  ? {
                    display: "flex",
                    flexDirection: "column",
                    width: "-webkit-fill-available",
                  }
                  : { display: "flex", flexDirection: "row", overflowX: "auto" }
              }
              ref={scrollRef}
            >
              {filters.map(createArticleFilters(title))}
            </div>
          )}
        </>
      );
    });

  const onCloseFilter = () => {
    const requestData = {
      adhocSortFilterTooltip: true,
    };
    userUpdaterMutation({
      variables: requestData,
    });
  };

  useLayoutEffect(() => {
    setFilterHeight?.(filterRef.current.clientHeight);
  }, [filterClicked]);

  return (
    <div
      style={{
        ...style.main,
        backgroundColor: isAllShow ? "#EFF1F5" : "#ffffff",
      }}
      ref={filterRef}
    >
      {showFilter && (
        <div className={Styles.filterShadow} style={style.content}>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              overflowX: "scroll",
            }}
          >
            {sections}
          </div>
          {!isAllShow && (
            <div
              style={{ paddingTop: 9, paddingLeft: 6, paddingRight: 10 }}
              onClick={() => setFilterClicked(!filterClicked)}
            >
              <img
                style={
                  filterClicked
                    ? { transform: `rotate(180deg)`, width: 30, height: 30 }
                    : { width: 30, height: 30 }
                }
                src={require("../../../images/filter-close.png")}
              />
            </div>
          )}
        </div>
      )}
      {processingArticle && (
        <div style={style.processingArticleDiv}>
          <span style={style.processingArticleTxt}>
            Ferret is processing new results and will notify you when content is
            ready...
          </span>
          <Box
            className={style.progressBox}
            sx={{
              width: 30,
              "& .MuiLinearProgress-colorPrimary": {
                backgroundColor: "#91CB6C",
              },
              "& .MuiLinearProgress-barColorPrimary": {
                backgroundColor: "#EFF1F5",
              },
            }}
          >
            <LinearProgress style={{ borderRadius: 16 }} />
          </Box>
        </div>
      )}
    </div>
  );
};
export default FiltersView;
