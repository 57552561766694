// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".searchResults_containerRightparantProgressbar__9cfI4 {\n  overflow-y: scroll;\n  width: -webkit-fill-available;\n  margin-right: 20px;\n  margin-top: 85px;\n  /* max-width: ; */\n  height: calc(100vh - 200px);\n  overflow-x: hidden;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: absolute;\n  z-index: 1;\n}\n", "",{"version":3,"sources":["webpack://./src/web/components/searchResult/searchResults.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,6BAA6B;EAC7B,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,2BAA2B;EAC3B,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,UAAU;AACZ","sourcesContent":[".containerRightparantProgressbar {\n  overflow-y: scroll;\n  width: -webkit-fill-available;\n  margin-right: 20px;\n  margin-top: 85px;\n  /* max-width: ; */\n  height: calc(100vh - 200px);\n  overflow-x: hidden;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: absolute;\n  z-index: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerRightparantProgressbar": "searchResults_containerRightparantProgressbar__9cfI4"
};
export default ___CSS_LOADER_EXPORT___;
