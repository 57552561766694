import * as React from "react"

 const PreSignUpBackground = (props) => {
  const style =  {
    gradientStartColor: '#DCE5F9',
    gradientEndColor: '#F9FBFF',
    gradientOpacity: 1,
    fingerprintOpacity: 0.073746,
    fingerprintColor: '#C0C7D6',
  }
  const {fingerprint = true, ...otherProps} = props
  //backgroundColor:'rgb(241,244,252)'
  return (
    <svg width={'100%'} height={'100%'} viewBox="0 0 375 812" {...otherProps}  preserveAspectRatio="none" style={props.style}>
      <defs>
        <radialGradient
          cx="47.519%"
          cy="-17.153%"
          fx="47.519%"
          fy="-17.153%"
          r="129.016%"
          gradientTransform="matrix(0 .45566 -1 0 .304 -.388)"
          id="prefix__b"
        >
          <stop stopColor={style.gradientStartColor} offset="0%" stopOpacity={style.gradientOpacity}/>
          <stop stopColor={style.gradientEndColor} offset="100%" stopOpacity={style.gradientOpacity}/>
        </radialGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          d="M5 23.795l86.39 79.827c18.994 17.55 46.99 21.217 69.863 9.15l151.764-80.07c20.516-10.824 45.921-2.967 56.745 17.548a42 42 0 014.853 19.83l-.599 108.952V794.29c-66.915 17.49-127.926 22.025-183.032 13.604-55.106-8.422-117.101-11.66-185.984-9.717V23.795z"
          fill="url(#prefix__b)"
          transform="matrix(-1 0 0 1 380 0)"
        />
        {/* 0.073746 */}
        <div style={{marginTop:150,alignItems:'center'}}>
        {fingerprint &&
                <svg width="373" height="448"  viewBox="0 0 373 448" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g  id="Texture-fingerprint" opacity={style.fingerprintOpacity} fill={style.fingerprintColor} >
                <path id="Stroke 1" d="M61.2209 37.2003C55.9425 40.7344 54.4127 48.0586 57.804 53.5593C61.1953 59.0599 68.2236 60.6542 73.502 57.12C139.675 12.8146 236.36 12.2582 302.441 57.0819C307.7 60.6486 314.737 59.0979 318.159 53.6183C321.582 48.1387 320.094 40.8052 314.836 37.2385C241.196 -12.7121 134.854 -12.1 61.2209 37.2003Z" fill="#C0C7D6"/>
                <path id="Stroke 3" d="M373.171 154.842C335.572 95.1962 268.257 57.4555 196.27 54.2277C120.011 50.8083 48.6513 85.9653 2.00325 155.677C-1.56193 161.005 -0.241138 168.289 4.95332 171.945C10.1478 175.602 17.2489 174.247 20.8141 168.919C62.9318 105.978 126.754 74.5342 195.274 77.6065C260.042 80.5107 320.52 114.419 354.011 167.548C357.432 172.974 364.494 174.529 369.785 171.02C375.076 167.512 376.591 160.268 373.171 154.842V154.842Z" fill="#C0C7D6"/>
                <path id="Stroke 5" d="M69.2979 190.003C96.6506 152.864 140.908 131.372 186.629 131.372C272.818 131.372 336.261 202.684 336.261 282.792C336.261 306.829 317.303 326.314 293.914 326.314C270.499 326.314 251.454 306.798 251.454 282.792H240.084H228.715V284.416H240.084H251.454C251.454 247.472 222.313 217.521 186.368 217.521C150.991 217.521 123.109 246.751 121.3 283.831C120.131 307.814 127.754 339.916 143.733 368.028C164.694 404.904 198.056 432.94 244.153 447.483C250.156 449.376 256.516 445.91 258.358 439.74C260.201 433.57 256.828 427.033 250.825 425.139C210.308 412.357 181.509 388.156 163.366 356.237C149.616 332.047 143.062 304.448 144.01 285.001C145.229 260.015 163.469 240.893 186.368 240.893C209.755 240.893 228.715 260.381 228.715 284.416C228.715 299.997 251.454 299.997 251.454 284.416V282.792C251.454 267.21 228.715 267.21 228.715 282.792C228.715 319.722 257.957 349.686 293.914 349.686C329.86 349.686 359 319.738 359 282.792C359 190.219 285.853 108 186.629 108C133.949 108 82.925 132.778 51.1681 175.896C15.3404 224.543 6.57555 291.529 30.1766 369.263C32.0471 375.424 38.4226 378.86 44.4166 376.937C50.4107 375.015 53.7534 368.462 51.8829 362.301C30.3789 291.473 38.1161 232.341 69.2979 190.003Z" fill="#C0C7D6"/>
                <path id="Stroke 7" d="M148.447 423.364C123.137 401.243 100.248 356.313 93.8159 312.988C86.428 263.221 100.524 222.774 137.989 200.075C204.881 159.549 283.074 208.593 283.074 286.149C283.074 292.624 288.206 297.873 294.537 297.873C300.868 297.873 306 292.624 306 286.149C306 190.836 208.842 129.897 126.304 179.902C79.9096 208.01 62.4718 258.049 71.1499 316.507C78.378 365.198 103.879 415.254 133.553 441.189C138.366 445.395 145.601 444.815 149.714 439.892C153.827 434.97 153.26 427.57 148.447 423.364V423.364Z" fill="#C0C7D6"/>
                <path id="Stroke 9" d="M176.042 281.417C175.099 313.465 190.212 353.039 216.316 375.932C239.952 396.657 274.324 404.705 316.984 399.427C323.214 398.656 327.663 392.796 326.919 386.338C326.175 379.881 320.521 375.271 314.29 376.042C277.434 380.602 249.316 374.018 230.994 357.952C210.47 339.953 198.014 307.337 198.756 282.135C198.947 275.634 194.017 270.204 187.745 270.006C181.472 269.807 176.233 274.917 176.042 281.417V281.417Z" fill="#C0C7D6"/>
                </g>
                </svg>
        }
        </div>
      </g>
    </svg>
  )
}

export default PreSignUpBackground
