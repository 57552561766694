import moment from "moment-timezone";
import React, { useContext, useEffect, useState } from "react";
import { useSubscription } from "react-apollo";
import { UserContext } from "../../../contexts/userContext";
import {
  CHECK_META_SEARCH_DATA,
  GET_CONFIGURATION,
  USER_UPDATES,
} from "../../../queries/queries";
import Variables from "../../../utils/Variables";
import SaveSearchQuery from "../../../components/dashboard/saveSearchQuery";
import createStyle from "./styles";
import { Box } from "@mui/material";
import { useLazyQuery } from "react-apollo";
import { useNavigate } from "react-router-dom";
import { searchResultStore } from "../../../store/searchResultStore";
import {
  filterArticles,
  formatArticleAttributes,
  returnIntialTags,
  sortArticles,
} from "../../../utils/filterUtils";
import savedSearchData from "../../../utils/savedSearchData";
import {
  capitalizeCase,
  parseDate,
  removeDuplicateTags,
  titleCase,
} from "../../../utils/utils";
import PersoneInfo from "../PersonInfo/personeInfo";
import useIntialModalData from "../../../components/searchModalV2/useIntialModalData";
import useRefineSearch from "../../../components/searchModalV2/useRefineSearch";
import "./style.css";
import ProcessingResultModal from "./processingResultModal";
import LoadingBar from "../loadingBar/locadingBar";
import Styles from "./searchResults.module.css";
import { savedSearchesStore } from "../../../store/savedSearchesStore";
import MapView from "../../../components/map/mapView";

var _ = require("lodash");

const defaultProfilePicture = require("../../../images/default-avatar.png");
let originalArticles = [];
let theme;
let riskLevelsStatuses = {
  high: true,
  medium: true,
  low: true,
};
let temporaryStatusesOfFilters = null;
let hasData = false;
let showMetaSearchDialog = false;
let metaAPICount = 0;
let hasDataTimer;
let metaSearchArticleCount = 0;

const SearchResults = (props) => {
  const feed = props?.route?.params?.feed ?? props.feed;

  const {
    mPhoneNumberInput,
    mCountryInput,
    mCityInput,
    mStateInput,
    mAgeInput,
    queryData,
    savedTracerQueryId,
    savedTahoeId,
    mOldMetaData,
    navigatedFrom,
    lastQuery,
    searchIds,
    linkedInData,
    feedbackOptions,
    profilePic,
    isFromSavedSearch,
    fromMultipleSavedSearch,
    isEditedSavedsearch,
    fullName,
  } = props?.route?.params?.feed ?? props.feed;
  const updatedOldData = {
    ...mOldMetaData,
    Person: mOldMetaData?.Person?.trim(),
  };

  const searchTerm = feed?.searchTerm;
  const style = createStyle(theme);
  const navigate = useNavigate();
  let initialSearchSettings = {};
  if (!!feed && feed.hasOwnProperty("searchSettings"))
    initialSearchSettings = feed.searchSettings;

  const categoryFilterData = props?.feed?.categoryFilterData;

  const openMapDashboard = searchResultStore((state) => state.openMapDashboard);
  const setOpenMapDashboard = searchResultStore((state) => state.setOpenMapDashboard);

  const showArticleDetails = searchResultStore(
    (state) => state.showArticleDetails
  );
  const setOldPageName = savedSearchesStore((state) => state.setOldPageName);

  const profilePic1 = searchResultStore((state) => state.profilePic1);
  const setProfilePic1 = searchResultStore((state) => state.setProfilePic1);

  const openMetaSearch = searchResultStore((state) => state.openMetaSearch);
  const setOpenMetaSearch = searchResultStore((state) => state.setOpenMetaSearch);

  const feedbackOptions1 = searchResultStore((state) => state.feedbackOptions1);
  const setFeedbackOptions1 = searchResultStore(
    (state) => state.setFeedbackOptions1
  );

  const processArticleDone = searchResultStore(
    (state) => state.processArticleDone
  );
  const setProcessArticleDone = searchResultStore(
    (state) => state.setProcessArticleDone
  );

  const processArticleInProgress = searchResultStore(
    (state) => state.processArticleInProgress
  );
  const setProcessArticleInProgress = searchResultStore(
    (state) => state.setProcessArticleInProgress
  );

  const showProcessArticlesModal = searchResultStore(
    (state) => state.showProcessArticlesModal
  );
  const setShowProcessArticlesModal = searchResultStore(
    (state) => state.setShowProcessArticlesModal
  );

  const linkedInUserData = searchResultStore((state) => state.linkedInUserData);
  const setLinkedInUserData = searchResultStore(
    (state) => state.setLinkedInUserData
  );

  const setConfigurationData = searchResultStore(
    (state) => state.setConfigurationData
  );

  const configurationData = searchResultStore(
    (state) => state.configurationData
  );

  const setConfigurationLoading = searchResultStore(
    (state) => state.setConfigurationLoading
  );

  const ConfigurationLoading = searchResultStore(
    (state) => state.ConfigurationLoading
  );


  const searchSettings = searchResultStore((state) => state.searchSettings);
  const setSearchSettings = searchResultStore(
    (state) => state.setSearchSettings
  );

  const filteredArticles = searchResultStore((state) => state.filteredArticles);
  const setFilteredArticles = searchResultStore(
    (state) => state.setFilteredArticles
  );

  const filteredArticles2 = searchResultStore(
    (state) => state.filteredArticles2
  );
  const setFilteredArticles2 = searchResultStore(
    (state) => state.setFilteredArticles2
  );

  const originalfilteredArticles = searchResultStore(
    (state) => state.originalfilteredArticles
  );
  const setOriginalFilteredArticles = searchResultStore(
    (state) => state.setOriginalFilteredArticles
  );

  const setEnableFilter = searchResultStore((state) => state.setEnableFilter);

  const articleFilterData = searchResultStore(
    (state) => state.articleFilterData
  );
  const setArticleFilterData = searchResultStore(
    (state) => state.setArticleFilterData
  );

  const personalInfo = searchResultStore((state) => state.personalInfo);
  const setPersonalInfo = searchResultStore((state) => state.setPersonalInfo);

  const aKAList = searchResultStore((state) => state.aKAList);
  const setAKAList = searchResultStore((state) => state.setAKAList);

  const articleTypeCount = searchResultStore((state) => state.articleTypeCount);
  const setArticleTypeCount = searchResultStore(
    (state) => state.setArticleTypeCount
  );

  const setIsFilterApplied = searchResultStore(
    (state) => state.setIsFilterApplied
  );

  const setRecentSearchId = searchResultStore(
    (state) => state.setRecentSearchId
  );

  const setTotalArticle = searchResultStore((state) => state.setTotalArticle);

  const setSaveSearchVariables = searchResultStore(
    (state) => state.setSaveSearchVariables
  );

  const saveSearchVariables = searchResultStore(
    (state) => state.saveSearchVariables
  );

  const savedContactID = searchResultStore((state) => state.savedContactID);
  const setSavedContactID = searchResultStore(
    (state) => state.setSavedContactID
  );

  const isShowPersonalInfo = searchResultStore(
    (state) => state.isShowPersonalInfo
  );
  const setShowPersonalInfo = searchResultStore(
    (state) => state.setShowPersonalInfo
  );

  const selectedFilter = searchResultStore((state) => state.selectedFilter);
  const setSelectedFilter = searchResultStore(
    (state) => state.setSelectedFilter
  );

  const sortState = searchResultStore((state) => state.sortState);

  const showStrongMatchArticles = searchResultStore(
    (state) => state.showStrongMatchArticles
  );

  const showWeakMatchArticles = searchResultStore(
    (state) => state.showWeakMatchArticles
  );

  const filterAttributes = searchResultStore((state) => state.filterAttributes);

  const readFilter = searchResultStore((state) => state.readFilter);

  const unreadFilter = searchResultStore((state) => state.unreadFilter);

  const setAttributes = searchResultStore((state) => state.setAttributes);

  const setHeaderTags = searchResultStore((state) => state.setHeaderTags);

  const saveRelevancyData = searchResultStore(
    (state) => state.saveRelevancyData
  );

  const verified = searchResultStore((state) => state.verified);
  const setVerified = searchResultStore((state) => state.setVerified);

  const partial = searchResultStore((state) => state.partial);
  const setPartial = searchResultStore((state) => state.setPartial);

  const needsReview = searchResultStore((state) => state.needsReview);
  const setNeedsReview = searchResultStore((state) => state.setNeedsReview);

  const refreshList = searchResultStore((state) => state.refreshList);
  const setRefreshList = searchResultStore((state) => state.setRefreshList);

  const showHeader = searchResultStore((state) => state.showHeader);

  const setShowSaveConfirmationAlert = searchResultStore(
    (state) => state.setShowSaveConfirmationAlert
  );

  const setModalFilterVisible = searchResultStore(
    (state) => state.setModalFilterVisible
  );

  const summaryPosition = searchResultStore((state) => state.summaryPosition);
  const setSummaryPosition = searchResultStore(
    (state) => state.setSummaryPosition
  );

  const articlePosition = searchResultStore((state) => state.articlePosition);
  const setArticlePosition = searchResultStore(
    (state) => state.setArticlePosition
  );
  const setHasData = searchResultStore((state) => state.setHasData);

  const mapFilteredArticles = searchResultStore((state) => state.mapFilteredArticles);
  const setMapFilteredArticles = searchResultStore((state) => state.setMapFilteredArticles);


  useEffect(() => {
    if (
      linkedInData &&
      Object.getOwnPropertyNames(linkedInUserData).length == 0
    )
      setLinkedInUserData(linkedInData);

    if (feedbackOptions && !feedbackOptions1)
      setFeedbackOptions1(feedbackOptions);

    if (profilePic && !profilePic1) setProfilePic1(profilePic);
  }, []);

  useEffect(() => {
    if (openMetaSearch) {
      onArticleTypeClick("Metasearch")
      setOpenMetaSearch(false)
    }
  }, [openMetaSearch])

  const broadcast = new BroadcastChannel("background-linkedin-message");

  useEffect(() => {
    if (broadcast) {
      broadcast.onmessage = (res) => {
        updateLinkedInView(res.data.data);
      };
    }
  }, []);

  const { data: apolloSubscriptionData, error: apolloSubscriptionError } =
    useSubscription(USER_UPDATES);

  useEffect(() => {
    if (apolloSubscriptionData) {
      updateLinkedInView(apolloSubscriptionData.userUpdates);
    } else if (apolloSubscriptionError) {
      console.error("apolloSubscriptionError", apolloSubscriptionError);
    }
  }, [apolloSubscriptionData, apolloSubscriptionError]);

  const updateLinkedInView = (payload) => {
    if (
      payload?.type === "linkedin-profile-match" &&
      payload?.potentialMatchId === Variables.potentialMatchId
    ) {
      if (payload?.linkedinProfile) {
        let data = JSON.parse(payload?.linkedinProfile);
        let jsonData = data && data.length > 0 && JSON.parse(data);
        setProfilePic1(jsonData?.profile_picture_src);
        if (jsonData) {
          jsonData.education = jsonData.education?.map((item) => {
            let dates = parseDate(item.dates);
            return { ...item, ...dates };
          });
          jsonData.experience = jsonData.experience?.map((item) => {
            let dates = parseDate(item.dates);
            return { ...item, ...dates };
          });
        }
        setLinkedInUserData(jsonData);
      }
    }
  };

  useEffect(() => {
    if (
      originalfilteredArticles == null ||
      originalfilteredArticles.length == 0
    ) {
      setOriginalFilteredArticles(originalArticles);
      setFilteredArticles2(filteredArticles);
      setArticleFilterData(
        props?.feed?.articleFilterData.filter((item) => item.count)
      );
      setPersonalInfo(props?.feed?.personalInfo);
      setFeedbackOptions1(props?.feed?.feedbackOptions);
      setProfilePic1(props?.feed?.profilePic);
      setSearchSettings(initialSearchSettings);
      setSavedContactID(feed?.savedContactID);
      setRecentSearchId(feed?.irrelevantArticleId);
      setArticleTypeCount(props?.feed?.articleTypeCount);
      setAKAList(
        props?.feed?.aKAList?.map((item) => {
          return { param: item, aka: true };
        })
      );
      setModalFilterVisible(Variables.modalFilterVisible);
      filterArticleTypeCount();
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    if (!showArticleDetails) {
      if (isShowPersonalInfo) {
        window.scrollTo(0, summaryPosition);
        setArticlePosition(0);
      } else {
        window.scrollTo(0, articlePosition);
      }
    }
  }, [showArticleDetails, isShowPersonalInfo]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isShowPersonalInfo]);

  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position > 0 && !showArticleDetails) {
      if (isShowPersonalInfo) {
        setSummaryPosition(position);
      } else {
        setArticlePosition(position);
      }
    }
  };

  useEffect(() => {
    return () => {
      originalArticles = [];
      temporaryStatusesOfFilters = null;
      hasData = false;
      showMetaSearchDialog = false;
      metaAPICount = 0;
      localStorage.setItem("reload", false);
      clearInterval(hasDataTimer);
    };
  }, []);

  const timezone = moment.tz.guess();

  const {
    refineSearch,
    data,
    loading,
    getArticleDetailsFromRefineSearch,
    mapSingleArticle,
    getReadArticlesIds,
    getPersonalInfo,
    getArticleFilterData,
    getPotentialMatchId,
    getContactId,
    getFeedbackOptions,
    getProfilePic,
  } = useRefineSearch();

  let isFirstOpen = false;

  const {
    loadingData,
    searchSettings: searchSettingsRelevancy,
    getModalDataForMetaTag,
  } = useIntialModalData({}, "", "");

  useEffect(() => {
    if (refreshList == true) {
      setRefreshList(false);

      let query = JSON.parse(JSON.stringify(lastQuery));
      let meta_data = JSON.parse(query.variables.fullname.meta_data);
      if (
        saveRelevancyData?.Organizations &&
        saveRelevancyData?.Organizations?.length
      ) {
        meta_data.Company =
          meta_data.Company && meta_data.Company != ""
            ? meta_data.Company +
            ";" +
            saveRelevancyData?.Organizations?.join(";")?.toString()
            : saveRelevancyData?.Organizations?.join(";")?.toString();
      }
      if (saveRelevancyData?.Places && saveRelevancyData?.Places?.length) {
        meta_data.Place =
          meta_data.Place && meta_data.Place != ""
            ? meta_data.Place +
            ";" +
            saveRelevancyData?.Places?.join(";")?.toString()
            : saveRelevancyData?.Places?.join(";")?.toString();
      }
      query.variables.fullname.meta_data = JSON.stringify(meta_data);

      query.variables.fullname.refresh = true

      if (
        query.variables.fullname?.contactId == undefined &&
        Variables.savedRelevancyContactId?.length > 0
      )
        query.variables.fullname.contactId = Variables.savedRelevancyContactId;

      const name =
        searchSettings?.initialSearchString ??
        feed.searchSettings?.initialSearchString ??
        fullName ??
        "";
      const searchType =
        meta_data?.Person && meta_data?.Person != ""
          ? "PERSON"
          : meta_data?.Company?.toLowerCase() == name?.toLowerCase()
            ? "COMPANY"
            : meta_data?.Location?.toLowerCase() == name?.toLowerCase()
              ? "LOCATION"
              : meta_data?.Place?.toLowerCase() == name?.toLowerCase()
                ? "PLACE"
                : meta_data?.Nationality?.toLowerCase() == name?.toLowerCase()
                  ? "PLACE"
                  : "PERSON";
      getModalDataForMetaTag(
        searchType,
        meta_data,
        name,
        mPhoneNumberInput,
        mCountryInput,
        mCityInput,
        mStateInput,
        mAgeInput
      );
      refineSearch(query);

      if (Variables.isAddSearches && Variables.savedSearchId) {
        setSavedContactID(Variables.savedSearchId);
        Variables.savedSearchId = undefined;
      }
    }
  }, [refreshList]);

  useEffect(() => {
    if (!!data && !loading && data && data.hasOwnProperty("refine_search")) {
      if (
        data?.refine_search?.result_type?.toUpperCase() ===
        "SUBSCRIPTION LIMIT REACHED"
      ) {
        // Alert.alert(
        //   "Error",
        //   data?.refine_search?.result_type?.toUpperCase(),
        //   [
        //     {
        //       text: "OK",
        //       style: "default",
        //     },
        //   ],
        //   { cancelable: true }
        // );
        return;
      }
      Variables.potentialMatchId = getPotentialMatchId();
      Variables.contactId = getContactId();
      const articleFilterData = getArticleFilterData();
      setArticleFilterData(articleFilterData.filter((item) => item.count));
      const parsedArticles = getArticleDetailsFromRefineSearch()
        ?.filter((item) => {
          return item;
        })
        .map((item) =>
          mapSingleArticle(
            item,
            searchSettings?.initialSearchString ??
            feed.searchSettings?.initialSearchString ??
            fullName ??
            "",
            getReadArticlesIds()
          )
        );
      originalArticles = getFormatedArticles(parsedArticles);
      filterAction();
      setPersonalInfo(getPersonalInfo());
      setFeedbackOptions1(getFeedbackOptions());
      setProfilePic1(getProfilePic());

      const metaArticleCount = articleFilterData
        ?.filter((item) => item.category == "MetaSearch")
        .map((item) => item.count)
        ?.toString();

      if (showMetaSearchDialog && processArticleDone.length > 0) {
        const metaSearchArray = articleFilterData?.filter(
          (item) => item.category == "MetaSearch"
        );
        setShowSaveConfirmationAlert({ status: false, msg: "" });
        setShowProcessArticlesModal({
          status: true,
          count: metaArticleCount ?? 0,
          isSameCount: metaSearchArticleCount == metaArticleCount,
        });
        showMetaSearchDialog = false;
      }

      metaSearchArticleCount = metaArticleCount;
    }
  }, [data, loading]);

  useEffect(() => {
    if (searchSettings)
      setSaveSearchVariables(
        savedSearchData(
          searchSettings?.intitialMetadata,
          searchSettings?.initialSearchString,
          feed?.irrelevantArticleId,
          props?.feed?.articleTypeCount,
          savedContactID,
          savedTracerQueryId,
          savedTahoeId,
          queryData,
          aKAList
        )
      );
  }, [searchSettings, savedContactID, aKAList]);

  const { user, setUser } = useContext(UserContext);
  const { saveSearchAndParse } = SaveSearchQuery(setUser);

  const [getConfigData, { loading: configLoading, data: configData }] =
    useLazyQuery(GET_CONFIGURATION);

  useEffect(() => {
    if (configData && !configLoading) {
      setConfigurationData(configData);
      setConfigurationLoading(configLoading);
    }
  }, [configData, configLoading]);

  const [
    checkMetaSearch,
    {
      loading: checkMetaSearchLoading,
      data: checkMetaSearchData,
      error: checkMetaSearchError,
    },
  ] = useLazyQuery(CHECK_META_SEARCH_DATA, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (!checkMetaSearchLoading && checkMetaSearchData) {
      let data = checkMetaSearchData.checkMetaSearchData;
      switch (true) {
        case data.hasData == true && data.refresh == false:
          callGetMetaSearchAfter10sec(data);
          break;
        case data.hasData == true && data.refresh == true:
          callGetMetaSearchAfter10sec(data);
          onGetMetaSearchResponse(false);
          break;
        case data.hasData == false && data.refresh == false:
          onGetMetaSearchResponse(true);
          break;
      }
      metaAPICount++;
    }
  }, [checkMetaSearchLoading, checkMetaSearchData]);

  const onGetMetaSearchResponse = (finalOutputReceive) => {
    if (finalOutputReceive) {
      hasData = false;
      setHasData?.(false);
      setProcessArticleDone(processArticleInProgress);
      setProcessArticleInProgress([]);
    }
    if (metaAPICount > 0) {
      showMetaSearchDialog = true;
      var query = JSON.parse(JSON.stringify(lastQuery));
      query.variables.fullname.refresh = true
      refineSearch(query);
    }
  };

  const callGetMetaSearchAfter10sec = (data) => {
    if (!hasData) {
      setHasData(data.hasData);
      hasData = data.hasData;
    }

    setProcessArticleInProgress(data.source);
    setProcessArticleDone(data.source);
    hasDataTimer = setTimeout(() => {
      callGetMetaSearchData();
    }, 10000);
  };

  const callGetMetaSearchData = () => {
    checkMetaSearch({
      variables: {
        searchIds: searchIds,
      },
    });
  };

  useEffect(() => {
    callGetMetaSearchData();
  }, []);

  useEffect(() => {
    getConfigData();
    Variables.savedRelevancyContactId = "";
  }, []);

  useEffect(() => {
    if (
      feed?.searchMe &&
      searchSettings &&
      searchSettings?.intitialMetadata &&
      searchSettings?.initialSearchString
    ) {
      saveSearchAndParse(
        searchSettings?.intitialMetadata,
        searchSettings?.initialSearchString
      );
    }
  }, [searchSettings]);

  useEffect(() => {
    async function fetchData() {
      reloadScreenData();
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (categoryFilterData) {
      setAttributes(
        returnIntialTags(
          categoryFilterData?.Places,
          categoryFilterData?.Organizations,
          searchSettings,
          aKAList,
          categoryFilterData?.People,
          categoryFilterData?.Parties,
          categoryFilterData?.Attorneys
        )
      );
    }
  }, [categoryFilterData]);

  const reloadScreenData = () => {
    if (Variables.articleIdMarkNo && filteredArticles.length > 0) {
      setTimeout(() => {
        let riskCategory = "";
        const mmArticle = filteredArticles.filter((item) => {
          if (
            item?.id
              ? item.id == Variables.articleIdMarkNo
              : item.articleId == Variables.articleIdMarkNo
          ) {
            riskCategory = item?.riskCategory;
            return false;
          }
          return true;
        });
        originalArticles = originalArticles.filter((item) => {
          if (
            item?.id
              ? item.id == Variables.articleIdMarkNo
              : item.articleId == Variables.articleIdMarkNo
          ) {
            riskCategory = item?.riskCategory;
            return false;
          }
          return true;
        });
        setFilteredArticles(formatArticleAttributes(mmArticle));
        const countArticle = {
          highRisk:
            articleTypeCount?.highRisk - (riskCategory == "high" ? 1 : 0),
          news: articleTypeCount?.news - (riskCategory == "low" ? 1 : 0),
          alerts: articleTypeCount?.alerts - (riskCategory == "medium" ? 1 : 0),
        };
        setArticleTypeCount(countArticle);
        Variables.articleIdMarkNo = false;
      }, 200);
    }

    if (Variables.isAddSearches && Variables.savedSearchId) {
      setSavedContactID(Variables.savedSearchId);
      Variables.savedSearchId = undefined;
    }
  };

  function getObjectDiff(obj1, obj2) {
    const diff = Object.keys(obj1).reduce((result, key) => {
      if (!obj2.hasOwnProperty(key)) {
        result.push(key);
      } else if (_.isEqual(obj1[key], obj2[key])) {
        const resultKeyIndex = result.indexOf(key);
        result.splice(resultKeyIndex, 1);
      }
      return result;
    }, Object.keys(obj2));

    return diff;
  }

  const onArticleTypeClick = (data) => {
    setSelectedFilter(data.category);

    let dataArticle = {
      mPhoneNumberInput,
      mCountryInput,
      mCityInput,
      mStateInput,
      mAgeInput,
      queryData,
      savedTracerQueryId,
      savedTahoeId,
      lastQuery,
      fullName,
      searchTerm,
      searchSettings,
      categoryFilterData,
      irrelevantArticleId: feed?.irrelevantArticleId,
      articles: feed?.articles,
      articleSource: "SEARCH",
      linkedInData,
      hasData: hasData,
      searchIds: searchIds,
      potentialMatchId: feed?.potentialMatchId,
      isFromSavedSearch: isFromSavedSearch,
      fromMultipleSavedSearch: fromMultipleSavedSearch,
      isEditedSavedsearch: isEditedSavedsearch,
    };

    if (isFromSavedSearch) {
      setOldPageName('/articles')
    } else {
      setOldPageName('')
    }
    navigate("/articles", { state: dataArticle });
  };

  const getSaveSearchData = () => {
    if (!isShowPersonalInfo) {
      setShowPersonalInfo(true);
      return;
    }
    if (!feed?.searchMe) {
      if (navigatedFrom == "SavedSearchesPreview") {
        navigate(-1, { replace: true });
      } else if (updatedOldData && navigatedFrom === "SavedSearches") {
        const isMetaDataSimilar = getObjectDiff(
          updatedOldData,
          initialSearchSettings?.intitialMetadata
        );
        if (isMetaDataSimilar.length) {
          saveMySearchAndShowConfirmation(searchSettings?.initialSearchString);
        } else {
          navigate(-1, { replace: true });
        }
      } else {
        saveMySearchAndShowConfirmation(searchSettings?.initialSearchString);
      }
    } else {
      navigate(-1, { replace: true });
    }
  };

  const saveMySearchAndShowConfirmation = (initialSearchString) => {
    setShowSaveConfirmationAlert({ status: true, msg: initialSearchString });
  };

  const filterAction = (isRiskRankingApplied, fromMap) => {
    if (!originalArticles || originalArticles.length === 0) {
      setFilteredArticles([]);
      return;
    }
    const selectedParentCategory = getSelectedParentCategory();
    let filterByArticleType = [...originalArticles];

    filterByArticleType = filterByArticleType.filter(
      (item) => riskLevelsStatuses[item.riskCategory]
    );

    setTotalArticle(filterByArticleType.length);

    if (
      selectedParentCategory &&
      (selectedParentCategory.length || isFirstOpen)
    ) {
      filterByArticleType = filterByArticleType.filter(
        (item) =>
          selectedParentCategory.includes(item.parentCategory) ||
          selectedParentCategory.includes("All")
      );
    }

    if (!isRiskRankingApplied) {
      setFilteredArticles(formatArticleAttributes(filterByArticleType));
      if (fromMap) {
        setMapFilteredArticles(formatArticleAttributes(filterByArticleType))
      }
    } else {
      filterArticleTypeCount();
    }

    setEnableFilter(true);
  };

  const getSelectedParentCategory = () => {
    let categoryType = [];
    if (temporaryStatusesOfFilters) {
      temporaryStatusesOfFilters[0].filters.forEach((type) => {
        if (type.status) {
          categoryType = [...categoryType, type?.label];
        } else if (type.count && type.count > 0) isFirstOpen = true;
      });
    }
    return categoryType;
  };

  const getRandomDate = () => {
    const start = new Date(2012, 0, 1);
    const end = new Date();
    const randomDate = new Date(
      start.getTime() + Math.random() * (end.getTime() - start.getTime())
    );

    return moment.tz(randomDate, timezone);
  };

  const getFormatedArticles = (articles) => {
    const now = moment();
    const riskCategories = {
      HIGH: "high",
      MEDIUM: "medium",
      LOW: "low",
    };

    return articles.map((article) => {
      if (article.createdAt) {
        article.date = moment(parseInt(article.createdAt)).fromNow();
        article.originalDate = moment(parseInt(article.createdAt)).tz(timezone);
      } else {
        if (!article.date) {
          article.date = getRandomDate().format();
        }
        let mDate = moment.parseZone(article.date).tz(timezone);
        article.originalDate = mDate;
        if (mDate.isValid()) {
          if (now.diff(mDate, "years") > 0) {
            article.date = mDate.format("L - LT");
          } else {
            article.date = mDate.fromNow();
          }
        } else {
          article.date = null;
        }
      }

      if (!article.profilePicture) {
        article.profilePicture = defaultProfilePicture;
      }
      article.riskCategory = riskCategories[article.riskCategory] ?? "low";
      return article;
    });
  };

  useEffect(() => {
    if (!feed) return;
    let testArticles =
      feed?.articles?.map((item) => {
        return { ...item };
      }) ?? [];

    if (originalArticles?.length == 0) {
      originalArticles = getFormatedArticles(testArticles);

      // This will call in case of browser reload
      let reload = localStorage.getItem("reload");
      if (reload == "true") {
        localStorage.setItem("reload", false);
        setRefreshList(true);
      }
    }
    filterAction();
  }, [feed]);

  useEffect(() => {
    filterAction();
  }, [riskLevelsStatuses]);

  useEffect(() => {
    let tags = [
      ...(searchSettings?.initialSearchParams ??
        feed?.searchSettings?.initialSearchParams ??
        []),
    ];
    if (aKAList) tags?.splice(1, 0, ...aKAList);
    setHeaderTags(removeDuplicateTags(tags));
  }, [aKAList]);

  const filterArticleTypeCount = () => {
    // if (articleFilterData) {
    //   let mArticleFilter = [...articleFilterData];
    //   const updatedArray = mArticleFilter.map((itemType) => {
    //     return {
    //       ...itemType,
    //       count: originalfilteredArticles.filter(
    //         (itemArticle) =>
    //           itemType?.category === (itemArticle.parentCategory ?? null)
    //       ).length,
    //     };
    //   });
    //   setArticleFilterData(updatedArray);
    // }
  };

  const onFilterCompleted = (
    riskLevel,
    filterData,
    isRiskRankingApplied = false,
    fromMap
  ) => {
    riskLevelsStatuses = riskLevel;
    temporaryStatusesOfFilters = filterData;
    let applyFilter = false;
    if (
      !riskLevelsStatuses?.high ||
      !riskLevelsStatuses?.low ||
      !riskLevelsStatuses?.medium
    ) {
      applyFilter = true;
    }
    if (temporaryStatusesOfFilters) {
      temporaryStatusesOfFilters[0].filters.forEach((type) => {
        if (type.count && type.count > 0 && !type.status) {
          applyFilter = true;
        }
      });
    }
    setIsFilterApplied(applyFilter);
    // setModalFilterVisible(false)
    filterAction(isRiskRankingApplied, fromMap);
    const selectedParentCategory = getSelectedParentCategory();
    if (!selectedParentCategory.includes(selectedFilter)) {
      window.scrollTo(0, 0);
    }
  };
  useEffect(() => {
    setFilteredArticles2(sortArticles(filteredArticles2, sortState));
  }, [sortState]);

  useEffect(() => {
    setFilteredArticles2(
      filterArticles(
        showStrongMatchArticles,
        showWeakMatchArticles,
        readFilter,
        unreadFilter,
        filterAttributes,
        filteredArticles
      )
    );
  }, [
    showStrongMatchArticles,
    showWeakMatchArticles,
    readFilter,
    unreadFilter,
    filterAttributes,
    filteredArticles,
  ]);

  useEffect(() => {
    filterArticleTypeCount();
  }, [originalfilteredArticles]);

  return (
    <div>
      {(loading || loadingData) && (
        <div className={Styles.containerRightparantProgressbar}>
          <LoadingBar />
        </div>
      )}
      <Box
        component="main"
        style={{
          ...style.contactsListContainer,
        }}
      >
        {personalInfo && (
          <PersoneInfo
            profileName={capitalizeCase(
              searchSettings?.initialSearchString ??
              feed.searchSettings?.initialSearchString ??
              fullName ??
              "",
              true
            )}
            linkedInData={linkedInUserData}
            verified={verified}
            setVerified={setVerified}
            partial={partial}
            setPartial={setPartial}
            needsReview={needsReview}
            setNeedsReview={setNeedsReview}
            personalInfo={personalInfo}
            onClick={onArticleTypeClick}
            processArticleInProgress={processArticleInProgress}
            feedbackOptions={feedbackOptions1}
            profilePic={profilePic1}
            setProfilePic={setProfilePic1}
            potentialMatchId={Variables.potentialMatchId}
            PDFFerretAISummary={props?.feed?.ferretAISummary}
            PDFFerretAI={props?.feed?.ferretAI}
            educationPDFMore={props?.feed?.educationMore}
            experiencePDFMore={props?.feed?.experienceMore}
            showPDFOthers={props?.feed?.showOthers}
            showPDFMore={props?.feed?.showMore}
          />
        )}
      </Box>
      {showProcessArticlesModal.status && (
        <ProcessingResultModal
          processArticleDone={processArticleDone}
          name={titleCase(
            searchSettings?.initialSearchString ??
            feed.searchSettings?.initialSearchString ??
            fullName ??
            ""
          )}
          showProcessArticlesModal={showProcessArticlesModal}
          setShowProcessArticlesModal={setShowProcessArticlesModal}
          onArticleTypeClick={onArticleTypeClick}
        />
      )}
      {openMapDashboard && (
        <MapView
          onFilterCompleted={(filter) => {
            onFilterCompleted(riskLevelsStatuses, filter, false, true);
          }}
          articleFilterData={articleFilterData}
          articles={mapFilteredArticles}
          setOpenMapView={setOpenMapDashboard}
          configData={configurationData}
          configLoading={ConfigurationLoading}
          isFromSavedSearch={isFromSavedSearch}
          isWeb={true}
          articleDetails={{
            mPhoneNumberInput,
            mCountryInput,
            mCityInput,
            mStateInput,
            mAgeInput,
            queryData,
            savedTracerQueryId,
            savedTahoeId,
            lastQuery,
            fullName,
            searchTerm,
            searchSettings,
            categoryFilterData,
            irrelevantArticleId: feed?.irrelevantArticleId,
            articles: feed?.articles,
            articleSource: "SEARCH",
            linkedInData,
            hasData: hasData,
            searchIds: searchIds,
            potentialMatchId: feed?.potentialMatchId,
            isFromSavedSearch: isFromSavedSearch,
            fromMultipleSavedSearch: fromMultipleSavedSearch,
            isEditedSavedsearch: isEditedSavedsearch,
          }}
          reloadScreenData={reloadScreenData}
          articleSource={"SEARCH"}
          searchTerm={searchTerm}
          savedContactID={feed?.savedContactID}
          recentSearchId={feed?.irrelevantArticleId}
          contactData={saveSearchVariables?.data}
          setRefreshList={setRefreshList}
        />
      )}
    </div>
  );
};

export default SearchResults;
