// Icons
import LogoIconWhite from '../images/icons/navigation/logo-icon-white.svg';
import LogoIconColor from '../images/icons/navigation/logo-icon-color.svg';
import AccountIcon from '../images/icons/navigation/account.svg';

/**
 * This constant defines the navigation items and routes
 * for use in the different layout components.
 * @type {[{path: string, name: string}]}
 */
export const NAV_ITEMS = {
  dashboard: {
    name: 'Dashboard',
    path: '/dashboard',
    icons: {
      normal: LogoIconWhite,
      active: LogoIconColor
    }
  },
  search: {
    name: 'Search',
    path: null,
    icons: {
      normal: `
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.4008 8.02944C13.4008 10.9909 10.9912 13.4003 8.02958 13.4003C5.06795 13.4003 2.6584 10.9909 2.6584 8.02944C2.6584 5.06797 5.06795 2.65881 8.02958 2.65881C10.9912 2.65881 13.4008 5.06797 13.4008 8.02944ZM19.6113 17.731L14.565 12.6849C15.5317 11.329 16.0595 9.69395 16.0595 8.02943C16.0595 3.60196 12.4573 0 8.02959 0C3.60189 0 0 3.60196 0 8.02943C0 12.4569 3.60189 16.0589 8.02959 16.0589C9.69448 16.0589 11.3293 15.5312 12.6853 14.5647L17.7314 19.6105C17.9824 19.8615 18.3162 20 18.6713 20C19.0265 20 19.3603 19.8618 19.6113 19.6105C20.1296 19.0923 20.1296 18.2492 19.6113 17.731Z" fill="var(--fill)"/>
      </svg>`,
    }
  },
  saved: {
    name: 'Contacts',
    path: '/savedSearch',
    icons: {
      normal: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3309 3.31884L11.3953 0.416099C11.2217 0.156115 10.9297 0 10.6167 0H2.31273C2.2454 0 2.17808 0.00746366 2.11231 0.021458C1.38171 0.181616 0 1.04709 0 3.17828V21.12C0 22.8298 0.96 24 2.88 24H21.12C22.2742 24 24 23.4095 24 21.12V6.70299C24 4.22008 22.2411 3.31884 21.0592 3.31884H13.3309Z" fill="var(--fill)"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.517 12.3048C12.517 14.0099 11.1302 15.3971 9.42511 15.3971C7.72006 15.3971 6.33319 14.0099 6.33319 12.3048C6.33319 10.6 7.72006 9.21281 9.42511 9.21281C11.1302 9.21281 12.517 10.6 12.517 12.3048ZM13.1897 14.9861C13.7464 14.205 14.0502 13.2632 14.0502 12.3048C14.0502 9.75481 11.9754 7.67993 9.42511 7.67993C6.87487 7.67993 4.80005 9.75481 4.80005 12.3048C4.80005 14.8551 6.87487 16.93 9.42511 16.93C10.3838 16.93 11.3252 16.6262 12.106 16.0701L15.0113 18.9752C15.156 19.1202 15.3486 19.1999 15.5533 19.1999C15.7581 19.1999 15.9503 19.1202 16.0953 18.9752C16.24 18.8305 16.32 18.6379 16.32 18.4332C16.32 18.2288 16.24 18.0362 16.0953 17.8915L13.1897 14.9861Z" fill="#000"/>
      </svg>`
    }
  },
  watched: {
    name: 'Watched',
    path: '/watchedSearch',
    icons: {
      normal: `<svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 18.0466L4.94658 21.7082L6.2493 13.8685L0.587322 8.2918L8.44587 7.10816L12 0L15.5541 7.10816L23.4127 8.2918L17.7507 13.8685L19.0534 21.7082L12 18.0466Z" fill="var(--fill)"/>
      </svg>`
    }
  },
  recentSearch: {
    name: 'Recently Viewed',
    path: '/recentViewActivity',
    icons: {
      normal: `<svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 13.7083C1 16.0816 1.70379 18.4017 3.02236 20.3751C4.34094 22.3485 6.21508 23.8866 8.4078 24.7948C10.6005 25.7031 13.0133 25.9407 15.3411 25.4777C17.6689 25.0147 19.807 23.8718 21.4853 22.1935C23.1635 20.5153 24.3064 18.3771 24.7694 16.0493C25.2324 13.7216 24.9948 11.3088 24.0866 9.11606C23.1783 6.92334 21.6402 5.0492 19.6668 3.73062C17.6934 2.41205 15.3734 1.70826 13 1.70826C9.56241 1.70446 6.25589 3.02706 3.76923 5.40057M3.76923 5.40057L4.69231 1.70826M3.76923 5.40057L7.46154 6.32364M13 7.24672V14.6313L8.2 17.0313" stroke="var(--fill)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>`
    }
  },
  notifications: {
    name: 'Notifications',
    path: '/notifications',
    icons: {
      normal: `<svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.625 20C9.74219 20 10.6562 19.0769 10.6562 17.9487H6.59375C6.59375 19.0769 7.49766 20 8.625 20ZM14.7188 13.8462V8.71795C14.7188 5.56923 13.0531 2.93333 10.1484 2.2359V1.53846C10.1484 0.68718 9.46797 0 8.625 0C7.78203 0 7.10156 0.68718 7.10156 1.53846V2.2359C4.18672 2.93333 2.53125 5.55897 2.53125 8.71795V13.8462L0.5 15.8974V16.9231H16.75V15.8974L14.7188 13.8462Z" fill="var(--fill)"/>
      </svg>`
    }
  },
  account: {
    name: 'Account',
    path: null,
    icons: {
      normal: AccountIcon
    }
  }
};

export default NAV_ITEMS;
