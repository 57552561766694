import { GreenBgButton } from "../../../components/buttons/greenBgButton";
import Styles from "./contactImportAlert.module.css";
import NoContact from "../../images/svg/no_contact.svg";

const ContactImportAlert = (props) => {
  const { startedContact = false, onPress, IS_WATCHED_SEARCH = false } = props;

  return (
    <div className={Styles.bodyNoContact}>
      <div className={Styles.mainNoContact}>
        <span className={Styles.savedTXT}>
          {IS_WATCHED_SEARCH ? "Watched Contacts" : "Contacts & Saved Searches"}
        </span>
        <img
          src={NoContact}
          className={Styles.noContactLogo}
          alt="No Contact"
        />
        <div className={Styles.no_contact_text}>
          {startedContact
            ? "Are you ready to select your starred contacts?"
            : "You haven’t added any contacts yet."}
        </div>
        {!startedContact && (
          <div className={Styles.no_text}>
            Connect your contact lists and start saving your New Searches
          </div>
        )}
        <GreenBgButton
          style={{ marginTop: 27, paddingLeft: 40, paddingRight: 40 }}
          onClick={() => onPress(startedContact)}
        >
          <span className={Styles.no_contact_text_green}>
            {startedContact ? "Get Started" : "Connect Contact Lists"}
          </span>
        </GreenBgButton>
      </div>
    </div>
  );
};

export default ContactImportAlert;
