import React, { useEffect, useState } from "react";
import { generatePdf } from "../../../utils/pdfGeneratorUtils";
import { searchResultStore } from "../../../store/searchResultStore";
import Article from "./article";

let isDonwnloaded = false;

const ArticlePDF = (props) => {

  const [webViewData, setWebViewData] = useState();
  
  const setIsPdfGenerating = searchResultStore(
    (state) => state.setIsPdfGenerating
  );

  useEffect(() => {
    // Add event listener to handle messages sent from React Native
    const handleMessageFromReactNative = (event) => {
      const dataFromRN = JSON.parse(event.data);
      setWebViewData(dataFromRN);
      // Do something with the received data
    };
    
    document.addEventListener('message', handleMessageFromReactNative);
  
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('message', handleMessageFromReactNative);
    };
  }, []);


  useEffect(()=>{
    if(!isDonwnloaded && webViewData){
      isDonwnloaded = true;
      setIsPdfGenerating(true);
      const fetchData = async () => {
        setTimeout(()=>{
          generatePdf(
            'article-content-div',
            webViewData?.newsItem?.fullName,
          () => {
            if (window.ReactNativeWebView) { // ensure window.ReactNativeWebView is there, otherwise, web app might crash if is not there
              window.ReactNativeWebView.postMessage('PDF created successfully and stored in your file system')
            }
            setWebViewData();
            setIsPdfGenerating(false);
            isDonwnloaded = false;
          })
        },[1000])
      };
      fetchData();
    }
  },[webViewData])


  return(
    <>
    {webViewData && <Article {...webViewData} isPDF={true}/> }
    </>
  )
};

export default ArticlePDF;
