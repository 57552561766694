const formatUserName = (userName) => {
   
      const words = userName.split(" ");
      const formattedName = words?.map((word) => { 
        if(word==""){
          return null;
        }
        return word[0]?.toUpperCase() + word?.substring(1); 
      }).join(" ")
    return formattedName;
}

export default formatUserName;