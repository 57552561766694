import React, { useState, useEffect, useRef } from "react";
import createStyle from "./styles";
import { useLazyQuery } from "react-apollo";
import {
  GET_ARTICLE_HISTORY,
  GET_HISTORY_ARTICLE_TYPE,
  GET_CONFIGURATION,
} from "../../../queries/queries";
import getFormatedArticles from "../../../utils/formatters/articleFormatter";
import Variables from "../../../utils/Variables";
import useRefineSearch from "../../../components/searchModalV2/useRefineSearch";
import {
  sortArticles,
  formatArticleAttributes,
  filterArticles,
  returnIntialTags,
} from "../../../utils/filterUtils";
import filterSection from "./filterSection";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Backdrop } from "@mui/material";
import AlertPopup from "../../../components/AlertPopup/AlertPopup";
import { recentViewArticles } from "../../../store/recentViewActivityStore";
import SectionListView from "../../components/articleList/SectionListView";
import Styles from "./recentViewActivity.module.css";
import FilterLeftSection from "../../components/filterLeftSection/FilterLeftSection";
import FilterByVerificationType from "../../components/filterByVerificationType/filterByVerificationType";
import FiltersView from "../../components/filtersView/filtersView";

let clearFilterArticleType = false;
let clearFilterItemType = false;
let riskLevelsStatuses = {
  high: true,
  medium: true,
  low: true,
};
let temporaryStatusesOfFilters = null;
let originalArticles = [];

const RecentViewActivity = (props) => {
  const navigate = useNavigate();
  const style = createStyle();
  const filterRef = useRef(null);

  const [isArticleOpened, setIsArticleOpened] = useState(false);
  const [showArticleDetails, setArticleDetails] = useState(false);
  const [openArticleDetail, setOpenArticleDetail] = useState({
    status: false,
    data: {},
  });

  const [verifiedCount, setVerifiedCount] = useState(0);
  const [needsReviewCount, setNeedsReviewCount] = useState(0);
  const [partialCount, setPartialCount] = useState(0);

  const configurationData = recentViewArticles(
    (state) => state.configurationData
  );
  const setConfigurationData = recentViewArticles(
    (state) => state.setConfigurationData
  );

  const ConfigurationLoading = recentViewArticles(
    (state) => state.ConfigurationLoading
  );
  const setConfigurationLoading = recentViewArticles(
    (state) => state.setConfigurationLoading
  );

  const searchSettings = recentViewArticles((state) => state.searchSettings);
  const setSearchSettings = recentViewArticles(
    (state) => state.setSearchSettings
  );

  const filteredArticles = recentViewArticles(
    (state) => state.filteredArticles
  );
  const setFilteredArticles = recentViewArticles(
    (state) => state.setFilteredArticles
  );

  const filteredArticles2 = recentViewArticles(
    (state) => state.filteredArticles2
  );
  const setFilteredArticles2 = recentViewArticles(
    (state) => state.setFilteredArticles2
  );

  const originalfilteredArticles = recentViewArticles(
    (state) => state.originalfilteredArticles
  );
  const setOriginalFilteredArticles = recentViewArticles(
    (state) => state.setOriginalFilteredArticles
  );

  const isEnableFilter = recentViewArticles((state) => state.isEnableFilter);
  const setEnableFilter = recentViewArticles((state) => state.setEnableFilter);

  const articleFilterData = recentViewArticles(
    (state) => state.articleFilterData
  );
  const setArticleFilterData = recentViewArticles(
    (state) => state.setArticleFilterData
  );

  const personalInfo = recentViewArticles((state) => state.personalInfo);
  const setPersonalInfo = recentViewArticles((state) => state.setPersonalInfo);

  const selectedFilter = recentViewArticles((state) => state.selectedFilter);
  const setSelectedFilter = recentViewArticles(
    (state) => state.setSelectedFilter
  );

  const sortState = recentViewArticles((state) => state.sortState);
  const setSortState = recentViewArticles((state) => state.setSortState);

  const showStrongMatchArticles = recentViewArticles(
    (state) => state.showStrongMatchArticles
  );
  const setShowStrongMatchArticles = recentViewArticles(
    (state) => state.setShowStrongMatchArticles
  );

  const showWeakMatchArticles = recentViewArticles(
    (state) => state.showWeakMatchArticles
  );
  const setShowWeakMatchArticles = recentViewArticles(
    (state) => state.setShowWeakMatchArticles
  );

  const filterAttributes = recentViewArticles(
    (state) => state.filterAttributes
  );
  const setFilterAttributes = recentViewArticles(
    (state) => state.setFilterAttributes
  );

  const readFilter = recentViewArticles((state) => state.readFilter);
  const setReadFilter = recentViewArticles((state) => state.setReadFilter);

  const unreadFilter = recentViewArticles((state) => state.unreadFilter);
  const setUnreadFilter = recentViewArticles((state) => state.setUnreadFilter);

  const attributes = recentViewArticles((state) => state.attributes);
  const setAttributes = recentViewArticles((state) => state.setAttributes);

  const verified = recentViewArticles((state) => state.verified);
  const setVerified = recentViewArticles((state) => state.setVerified);

  const partial = recentViewArticles((state) => state.partial);
  const setPartial = recentViewArticles((state) => state.setPartial);

  const needsReview = recentViewArticles((state) => state.needsReview);
  const setNeedsReview = recentViewArticles((state) => state.setNeedsReview);

  const refreshList = recentViewArticles((state) => state.refreshList);
  const setRefreshList = recentViewArticles((state) => state.setRefreshList);

  const showHeader = recentViewArticles((state) => state.showHeader);
  const setShowHeader = recentViewArticles((state) => state.setShowHeader);

  const modalFilterVisible = recentViewArticles(
    (state) => state.modalFilterVisible
  );
  const setModalFilterVisible = recentViewArticles(
    (state) => state.setModalFilterVisible
  );

  const articleCount = recentViewArticles((state) => state.articleCount);
  const setArticleCount = recentViewArticles((state) => state.setArticleCount);

  const articles = recentViewArticles((state) => state.articles);
  const setArticles = recentViewArticles((state) => state.setArticles);

  const applyFilter = recentViewArticles((state) => state.applyFilter);
  const setApplyFilter = recentViewArticles((state) => state.setApplyFilter);

  const isNoResult = recentViewArticles((state) => state.isNoResult);
  const setNoResult = recentViewArticles((state) => state.setNoResult);

  const alertPopupVisible = recentViewArticles(
    (state) => state.alertPopupVisible
  );
  const setAlertPopupVisible = recentViewArticles(
    (state) => state.setAlertPopupVisible
  );

  const refreshing = recentViewArticles((state) => state.refreshing);
  const setRefreshing = recentViewArticles((state) => state.setRefreshing);

  const refreshingFilter = recentViewArticles(
    (state) => state.refreshingFilter
  );
  const setRefreshingFilter = recentViewArticles(
    (state) => state.setRefreshingFilter
  );

  const categoryFilterData = recentViewArticles(
    (state) => state.categoryFilterData
  );
  const setCategoryFilterData = recentViewArticles(
    (state) => state.setCategoryFilterData
  );

  const articlePosition = recentViewArticles((state) => state.articlePosition);
  const setArticlePosition = recentViewArticles(
    (state) => state.setArticlePosition
  );

  const removeAll = recentViewArticles((state) => state.removeAll);

  const { mapSingleArticle } = useRefineSearch();

  let isFirstOpen = false;

  const [getConfigData, { loading: configLoading, data: configData }] =
    useLazyQuery(GET_CONFIGURATION);
  useEffect(() => {
    if (!configLoading && configData) {
      setConfigurationData(configData);
      setConfigurationLoading(configLoading);
    }
  }, [configLoading, configData]);

  const [getFeed, { loading, data }] = useLazyQuery(GET_ARTICLE_HISTORY, {
    fetchPolicy: "no-cache",
  });

  const [getArticleType, { loading: loadingType, data: articleData }] =
    useLazyQuery(GET_HISTORY_ARTICLE_TYPE);

  useEffect(() => {
    removeAll();
    getConfigData();
    getArticleType({ variables: { riskRanking: [] } });
  }, []);

  useEffect(() => {
    if (!showArticleDetails) {
      setTimeout(() => {
        window.scrollTo(0, articlePosition);
      }, 100);
    }
  }, [showArticleDetails]);

  useEffect(() => {
    if (refreshList) {
      getArticleType({ variables: { riskRanking: [] } });
      setRefreshList(false);
    }
  }, [refreshList]);

  useEffect(() => {
    async function fetchData() {
      reloadScreenData();
    }
    fetchData();
  }, []);

  const reloadScreenData = () => {
    if (Variables.articleIdMarkNo && filteredArticles.length > 0) {
      setTimeout(() => {
        let riskCategory = "";
        const mmArticle = filteredArticles.filter((item) => {
          if (
            item?.id
              ? item.id == Variables.articleIdMarkNo
              : item.articleId == Variables.articleIdMarkNo
          ) {
            riskCategory = item?.riskCategory;
            return false;
          }
          return true;
        });
        originalArticles = originalArticles.filter((item) => {
          if (
            item?.id
              ? item.id == Variables.articleIdMarkNo
              : item.articleId == Variables.articleIdMarkNo
          ) {
            riskCategory = item?.riskCategory;
            return false;
          }
          return true;
        });
        setFilteredArticles(formatArticleAttributes(mmArticle));
        filterArticleTypeCount();
        const countArticle = {
          highRisk: articleCount?.highRisk - (riskCategory == "high" ? 1 : 0),
          news: articleCount?.news - (riskCategory == "low" ? 1 : 0),
          alerts: articleCount?.alerts - (riskCategory == "medium" ? 1 : 0),
        };
        setArticleCount(countArticle);
        Variables.articleIdMarkNo = false;
        setAlertPopupVisible(mmArticle.length == 0);
      }, 200);
    }
  };

  useEffect(() => {
    fetchArticlesTypeByRiskRanking();
  }, []);

  const fetchArticlesTypeByRiskRanking = () => {
    const riskLevel = Variables.riskLevelsStatuses;
    const riskLevelFilter = [];
    if (riskLevel) {
      if (riskLevel?.high) {
        riskLevelFilter.push("HIGH");
      }
      if (riskLevel?.low) {
        riskLevelFilter.push("LOW");
      }
      if (riskLevel?.medium) {
        riskLevelFilter.push("MEDIUM");
      }
    }
  };

  useEffect(() => {
    if (applyFilter) onHandleRefresh(true);
  }, [applyFilter]);

  useEffect(() => {
    if (!loadingType && articleData) {
      getFeed({
        variables: { chunkSize: 2000 },
      });
      let articleFilterData = articleData?.getHistoryArticleType?.filter(
        (item) => item.count
      );
      setArticleFilterData(articleFilterData);
      filterArticleTypeCount(articleFilterData);
    }
  }, [loadingType, articleData]);

  useEffect(() => {
    if (!loading && data && Array.isArray(data?.getArticleHistory?.articles)) {
      if (data?.getArticleHistory?.categoryFilterData !== null) {
        setCategoryFilterData(data?.getArticleHistory?.categoryFilterData);
      }
      fetchArticlesTypeByRiskRanking();
      const parsedArticles = getFormatedArticles(
        data.getArticleHistory.articles
      );
      originalArticles = parsedArticles;
      setFilteredArticles(parsedArticles);
      if (refreshing || refreshingFilter) {
        setRefreshing(false);
        setRefreshingFilter(false);
        const latestArticles = parsedArticles;
        setArticles(latestArticles);
      } else {
        const latestArticles = parsedArticles;
        const newArticles = articles.concat(latestArticles);
        setArticles(newArticles);
      }
      setAlertPopupVisible(parsedArticles.length == 0);
    } else if (
      !loading &&
      data &&
      Array.isArray(data?.getArticleHistory?.articles) &&
      data?.getArticleHistory?.articles?.length == 0
    ) {
      setNoResult(true);
      setAlertPopupVisible(true);
    }
  }, [loading, data]);

  const getSelectedParentCategory = () => {
    let categoryType = [];
    if (temporaryStatusesOfFilters) {
      temporaryStatusesOfFilters[0].filters.forEach((type) => {
        if (type.status) {
          categoryType = [...categoryType, type?.label];
        } else if (type.count && type.count > 0) isFirstOpen = true;
      });
    }
    return categoryType;
  };

  const filterAction = (isRiskRankingApplied) => {
    if (!originalArticles || originalArticles.length === 0) {
      setFilteredArticles([]);
      return;
    }

    const selectedParentCategory = getSelectedParentCategory();

    let filterByArticleType = [...originalArticles];
    filterByArticleType = filterByArticleType.filter(
      (item) => riskLevelsStatuses[item?.riskCategory?.toLowerCase()]
    );

    if (
      selectedParentCategory &&
      (selectedParentCategory.length || isFirstOpen)
    ) {
      filterByArticleType = filterByArticleType.filter(
        (item) =>
          selectedParentCategory.includes(item.parentCategory) ||
          selectedParentCategory.includes("All")
      );
    }

    if (!isRiskRankingApplied) {
      setFilteredArticles(formatArticleAttributes(filterByArticleType));
    } else {
      filterArticleTypeCount();
    }

    setEnableFilter(true);
  };

  useEffect(() => {
    let intialTags = returnIntialTags(
      categoryFilterData?.Places,
      categoryFilterData?.Organizations,
      undefined,
      [],
      categoryFilterData?.People,
      categoryFilterData?.Parties,
      categoryFilterData?.Attorneys
    )
    setAttributes(intialTags);
  }, [categoryFilterData])

  const filterArticleTypeCount = (data) => {
    const Filterdata =
      data && data?.length > 0
        ? data
        : articleFilterData && articleFilterData.length > 0
          ? articleFilterData
          : [];
    if (Filterdata) {
      let mArticleFilter = [...Filterdata];
      const updatedArray = mArticleFilter.map((itemType) => {
        return {
          ...itemType,
          count: filterArticles(
            showStrongMatchArticles,
            showWeakMatchArticles,
            readFilter,
            unreadFilter,
            filterAttributes,
            originalArticles,
            itemType?.category
          ).filter(
            (itemArticle) =>
              itemType?.category === (itemArticle?.parentCategory ?? null) &&
              isRiskCategorySelected(itemArticle?.riskCategory?.toLowerCase())
          ).length,
        };
      });
      setArticleFilterData(updatedArray);

      if (Variables.articleIdMarkNo && filteredArticles.length > 0) {
        setPersonalInfo((personalInfo) => ({
          ...personalInfo,
          dataCategories: updatedArray,
        }));
      }
    }
  };

  const onContinuePress = () => {
    setAlertPopupVisible(false);
    navigate('/dashboard');
  };

  const isRiskCategorySelected = (category) => {
    const riskLevel = Variables.riskLevelsStatuses;
    if (category && riskLevel?.[category]) {
      return true;
    } else {
      return false;
    }
  };

  const onHandleRefresh = (clearData = false) => {
    const variables = {
      chunkSize: 50,
    };
    if (applyFilter) {
      variables.articleType = clearFilterArticleType
        ? []
        : applyFilter.seletedArticleType;
      variables.itemType = clearFilterItemType
        ? []
        : applyFilter.seletedItemType;
      variables.riskCode = clearFilterItemType
        ? []
        : applyFilter.seletedRiskType;
    }

    setRefreshing(!clearData);
    setRefreshingFilter(clearData);
    if (clearData) {
      setArticles([]);
    }
  };

  const onFilterCompleted = (
    riskLevel,
    filterData,
    isRiskRankingApplied = false
  ) => {
    riskLevelsStatuses = riskLevel;
    temporaryStatusesOfFilters = filterData;
    let applyFilter = false;
    if (
      !riskLevelsStatuses?.high ||
      !riskLevelsStatuses?.low ||
      !riskLevelsStatuses?.medium
    ) {
      applyFilter = true;
    }
    if (temporaryStatusesOfFilters) {
      temporaryStatusesOfFilters[0].filters.forEach((type) => {
        if (type.count && type.count > 0 && !type.status) {
          applyFilter = true;
        }
      });
    }
    filterAction(isRiskRankingApplied);
    const selectedParentCategory = getSelectedParentCategory();
    if (!selectedParentCategory.includes(selectedFilter)) {
      window.scrollTo(0, 0);
    }
  };

  //sorting articles
  useEffect(() => {
    setFilteredArticles2(sortArticles(filteredArticles2, sortState));
  }, [sortState]);

  //filtering articles
  useEffect(() => {
    setFilteredArticles2(
      filterArticles(
        showStrongMatchArticles,
        showWeakMatchArticles,
        readFilter,
        unreadFilter,
        filterAttributes,
        filteredArticles,
        getSelectedParentCategory()
      )
    );
  }, [
    showStrongMatchArticles,
    showWeakMatchArticles,
    readFilter,
    unreadFilter,
    filterAttributes,
    filteredArticles,
  ]);

  useEffect(() => {
    filterArticleTypeCount();
  }, [originalfilteredArticles]);

  //filtering for article count
  useEffect(() => {
    setOriginalFilteredArticles(
      filterArticles(
        showStrongMatchArticles,
        showWeakMatchArticles,
        readFilter,
        unreadFilter,
        filterAttributes,
        originalArticles
      )
    );
  }, [
    showStrongMatchArticles,
    showWeakMatchArticles,
    readFilter,
    unreadFilter,
    filterAttributes,
    originalArticles,
  ]);

  return (
    <div className={Styles.articleMain}>
      <aside>
        <div className={Styles.bodySearch}>
          <FilterLeftSection
            sortState={sortState}
            setSortState={setSortState}
            showStrongMatchArticles={showStrongMatchArticles}
            setShowStrongMatchArticles={setShowStrongMatchArticles}
            showWeakMatchArticles={showWeakMatchArticles}
            setShowWeakMatchArticles={setShowWeakMatchArticles}
            filterAttributes={filterAttributes}
            setFilterAttributes={setFilterAttributes}
            readFilter={readFilter}
            setReadFilter={setReadFilter}
            unreadFilter={unreadFilter}
            setUnreadFilter={setUnreadFilter}
            isAllShow={true}
            attributes={attributes}
            setAttributes={setAttributes}
            filterByVerificationType={
              <FilterByVerificationType
                isAllShow={true}
                verified={verified}
                setVerified={setVerified}
                partial={partial}
                setPartial={setPartial}
                needsReview={needsReview}
                setNeedsReview={setNeedsReview}
                verifiedCount={verifiedCount}
                needsReviewCount={needsReviewCount}
                partialCount={partialCount}
              />
            }
            filtersView={
              <div className={Styles.mainFilterCat}>
                <span className={Styles.filterTXT}>Filter By Record Type</span>
                <FiltersView
                  isAllShow={true}
                  statusesOfFiltersOld={null}
                  onFilterCompleted={(filter) => {
                    Variables.statusesOfFilters = filter;
                    onFilterCompleted(riskLevelsStatuses, filter);
                  }}
                  articleType={articleFilterData}
                  selectedFilter={selectedFilter}
                  setSelectedFilter={setSelectedFilter}
                  sortState={sortState}
                  setSortState={setSortState}
                  showStrongMatchArticles={showStrongMatchArticles}
                  setShowStrongMatchArticles={setShowStrongMatchArticles}
                  showWeakMatchArticles={showWeakMatchArticles}
                  setShowWeakMatchArticles={setShowWeakMatchArticles}
                  filterAttributes={filterAttributes}
                  setFilterAttributes={setFilterAttributes}
                  readFilter={readFilter}
                  setReadFilter={setReadFilter}
                  unreadFilter={unreadFilter}
                  setUnreadFilter={setUnreadFilter}
                  attributes={attributes}
                  setAttributes={setAttributes}
                  verified={verified}
                  setVerified={setVerified}
                  partial={partial}
                  setPartial={setPartial}
                  needsReview={needsReview}
                  setNeedsReview={setNeedsReview}
                  processingArticle={false}
                  filterRef={filterRef}
                />
              </div>
            }
          />
        </div>
      </aside>
      <SectionListView
        selectedParentCategory={getSelectedParentCategory()}
        isHeader={true}
        setSelectedFilter={setSelectedFilter}
        selectedFilter={selectedFilter}
        isVisible={modalFilterVisible}
        onFilterCompleted={onFilterCompleted}
        articleType={articleFilterData}
        articleCount={articleCount}
        articleRelevacnyData={JSON.stringify(props)}
        articles={filterSection(filteredArticles2)}
        style={style}
        articlesLoading={loading || loadingType}
        refreshing={refreshing}
        articleSource={"RECENT"}
        noBtnPressDelay={true}
        isShowName={false}
        reloadScreenData={reloadScreenData}
        configData={configurationData}
        configLoading={ConfigurationLoading}
        sortState={sortState}
        setSortState={setSortState}
        showStrongMatchArticles={showStrongMatchArticles}
        setShowStrongMatchArticles={setShowStrongMatchArticles}
        showWeakMatchArticles={showWeakMatchArticles}
        setShowWeakMatchArticles={setShowWeakMatchArticles}
        filterAttributes={filterAttributes}
        setFilterAttributes={setFilterAttributes}
        readFilter={readFilter}
        setReadFilter={setReadFilter}
        unreadFilter={unreadFilter}
        setUnreadFilter={setUnreadFilter}
        attributes={attributes}
        setAttributes={setAttributes}
        setRefreshList={setRefreshList}
        setShowHeader={setShowHeader}
        verified={verified}
        setVerified={setVerified}
        partial={partial}
        setPartial={setPartial}
        needsReview={needsReview}
        setNeedsReview={setNeedsReview}
        recentArticle={filteredArticles2}
        navFrom="recentActivity"
        setArticleDetails={setArticleDetails}
        openArticleDetail={openArticleDetail}
        setOpenArticleDetail={setOpenArticleDetail}
        setVerifiedCountRecent={setVerifiedCount}
        setNeedsReviewCountRecent={setNeedsReviewCount}
        setPartialCountRecent={setPartialCount}
        isArticleOpened={isArticleOpened}
        setIsArticleOpened={setIsArticleOpened}
      />
      {(loading || loadingType) && (
        <Backdrop
          sx={{
            backgroundColor: "#00000000",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: "#00000000",
          }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {alertPopupVisible && <AlertPopup onBackdropPress={onContinuePress} />}
    </div>
  );
};

export default RecentViewActivity;
