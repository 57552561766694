// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".accountOverlay_list__xKyH3 {\n  /* height: calc(96% - 360px);\n  overflow-y: scroll;\n  overflow-x: hidden;\n    */\n}\n\n.accountOverlay_footer__5omMr {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding-top: 10px;\n  padding-bottom: 40px;\n}\n\n.accountOverlay_version__7kZ7l {\n  font-family: 'Proxima Nova';\n  font-style: normal;\n  font-weight: 400;\n  font-size: 14px;\n  text-align: center;\n  color: #697080;\n}", "",{"version":3,"sources":["webpack://./src/web/components/account/accountOverlay.module.css"],"names":[],"mappings":"AAAA;EACE;;;KAGG;AACL;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,2BAA2B;EAC3B,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,cAAc;AAChB","sourcesContent":[".list {\n  /* height: calc(96% - 360px);\n  overflow-y: scroll;\n  overflow-x: hidden;\n    */\n}\n\n.footer {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding-top: 10px;\n  padding-bottom: 40px;\n}\n\n.version {\n  font-family: 'Proxima Nova';\n  font-style: normal;\n  font-weight: 400;\n  font-size: 14px;\n  text-align: center;\n  color: #697080;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"list": "accountOverlay_list__xKyH3",
	"footer": "accountOverlay_footer__5omMr",
	"version": "accountOverlay_version__7kZ7l"
};
export default ___CSS_LOADER_EXPORT___;
