const { windowWidth, windowHeight } = window

const getContactsListContainerStyle = () => {
  return {
    contactsListContainer: {
      backgroundColor: '#F8FAFF',
      flex: 1,
      flexDirection: "column",
      marginTop: 50,
      borderTopLeftRadius: 12,
      borderTopRightRadius: 12,
    },
  }
}
const createStyle = (theme) => {
  const headerTextColor = '#FFF'
  const contactsListContainerStyle = getContactsListContainerStyle(theme)
  return {
    ...contactsListContainerStyle,
    container: {
      flex: 1,
      backgroundColor: '#253662',
      flexDirection: 'column',
    },
    headerContainer: {
      flexDirection: 'column'
    },
    titleContainer: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginLeft: 17,
      marginRight: 17,
      marginTop: 20,
    },
    titleText: {
      fontSize: 21,
      color: headerTextColor,
      fontWeight: 'bold',
    },
    profilePicture: {
      height: 44,
      width: 44,
      borderRadius: 22,
      borderColor: '#FFF',
      borderWidth: 3,
    },
    filtersIcon: {
      tintColor: '#0D1324',
      height: 20,
      width: 20,
      alignSelf: 'center',
      marginRight: 15,
    },
    contactsText: {
      color: headerTextColor,
      fontSize: 16,
      marginTop: 5,
    },
    boldContactsText: {
      fontSize: 31,
      fontWeight: 'bold',
    },
    contactsListContainerWithoutRiskBar: {
      ...contactsListContainerStyle.contactsListContainer,
      marginTop: 16,
      paddingTop: 9,
    },
    contactsListContainerBody: {
      flex: 8,
      flexDirection: "column",
      marginTop: 19,
    },
    contactListHeaderContainer:{
      flexDirection: 'row',
      marginVertical: 15,
      alignItems: 'center'
    },
    contactListTitle: {
      fontSize: 21,
      fontWeight: "bold",
      marginLeft: 15,
      marginRight: 15,
      flex: 1,
      color: "#FFF",
    },
    contactListFiltersContainer: {
      borderColor: '#0D1324',
      borderRadius: 5,
      marginEnd: 16,
      borderWidth: 1,
      paddingLeft: 8,
      paddingVertical: 8,
      flexDirection: 'row',
      alignItems: 'center',
    },
    contactListFiltersTitle: {
      marginRight: 15,
      color: '#0D1324',
      fontSize: 14,
      fontWeight: '600'
    },
    riskBarContainer: {
      marginTop: 15,
    },
    mainIcon: {
      alignSelf: 'center',
      resizeMode: 'contain',
      
    },
    subTitle: {
      color: "#FFF",
      fontSize: windowWidth / 23,
      textAlign: "center",
      marginTop:15
  },
    title:{
      color: "#FFF",
      //fontSize: isEqualOrHigherThanIphoneX ? 28 : 24,
      fontSize: windowWidth / 18,
      textAlign: "center",
      fontWeight: 'bold',
      marginLeft: 46,
      marginRight: 46,
      flex: 2,
    },
    footer: {
      width: windowWidth / 1.1,
      marginTop: windowWidth / 18,
      marginLeft: 16,
      marginRight: 16,
      marginBottom: windowWidth / 4,
  },
  sectionView: {
    marginLeft: 16,
    paddingTop: 10,
    paddingBottom: 10,
  },
  contactSectionListTitle: {
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: "700",
    flex: 1,
    color: "#697080",
  },
  scrollHeader:{
    marginBottom: windowWidth / 9
  },
  centeredContentView: {
    alignSelf: "center",
    position: "absolute",
    justifyContent: "center",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  }
}

export default createStyle
