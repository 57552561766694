import { useState, useEffect, useContext } from "react";
import { Stack } from "@mui/material";
import ferretLogo from "../../../images/svg/ferret-logo.svg";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-apollo";
import { AUTHENTICATE_FERRET_USER } from "../../../queries/queries";
import MobileStepper from "@mui/material/MobileStepper";
import { GreenBgButton } from "../../../components/buttons/greenBgButton";
import { WhiteBgButtonGreenBorder } from "../../../components/buttons/whiteBgButtonGreenBorder";
import Styles from "./signUpUserDetails.module.css";
import "./dots-override.css";
import { EmailField } from "../../../components/fields/emailField";
import { PasswordField } from "../../../components/fields/passwordField";
import { SimpleField } from "../../../components/fields/simpleField";
import { DOBField } from "../../../components/fields/dobField";
import { PhoneNumberField } from "../../../components/fields/phoneNumberField";
import { saveUserDataAndRedirectToInitialScreen } from "../../../root-navigation";
import SingleBtnAlert from "../../../components/alert/singleBtnAlert";
import { UserContext } from "../../../contexts/userContext";
import { matchIsValidTel } from "mui-tel-input";
import moment from "moment";
import { getDeviceDetails } from "../../../utils/DeviceDetails";
import AppWalkthourgh from "../../components/appWalkthrough/appWalkthrough";
import Footer from "../../layout/Footer";
import { signupStore } from "../../../store/signupStore";

const SignUpUserDetails = () => {
  const { setUser } = useContext(UserContext);

  const [checkUserDetailFormValidation, setCheckUserDetailFormValidation] =
    useState(false);
  const [checkSignUpFormValidation, setCheckSignUpFormValidation] =
    useState(false);

  const activeStepper = signupStore((state) => state.activeStepper);
  const setActiveStepper = signupStore((state) => state.setActiveStepper);

  const dob = signupStore((state) => state.dob);
  const setDob = signupStore((state) => state.setDob);

  const phone = signupStore((state) => state.phone);
  const setPhone = signupStore((state) => state.setPhone);

  const countryCode = signupStore((state) => state.countryCode);
  const setCountryCode = signupStore((state) => state.setCountryCode);

  const firstName = signupStore((state) => state.firstName);
  const setFirstName = signupStore((state) => state.setFirstName);

  const lastName = signupStore((state) => state.lastName);
  const setLastName = signupStore((state) => state.setLastName);

  const firstNameValid = signupStore((state) => state.firstNameValid);
  const setFirstNameValid = signupStore((state) => state.setFirstNameValid);

  const lastNameValid = signupStore((state) => state.lastNameValid);
  const setLastNameValid = signupStore((state) => state.setLastNameValid);

  const dobValid = signupStore((state) => state.dobValid);
  const setDobValid = signupStore((state) => state.setDobValid);

  const phoneValid = signupStore((state) => state.phoneValid);
  const setPhoneValid = signupStore((state) => state.setPhoneValid);

  const email = signupStore((state) => state.email);
  const setEmail = signupStore((state) => state.setEmail);

  const password = signupStore((state) => state.password);
  const setPassword = signupStore((state) => state.setPassword);

  const confirmPassword = signupStore((state) => state.confirmPassword);
  const setConfirmPassword = signupStore((state) => state.setConfirmPassword);

  const emailValid = signupStore((state) => state.emailValid);
  const setEmailValid = signupStore((state) => state.setEmailValid);

  const passwordValid = signupStore((state) => state.passwordValid);
  const setPasswordValid = signupStore((state) => state.setPasswordValid);

  const confirmPasswordValid = signupStore(
    (state) => state.confirmPasswordValid
  );
  const setConfirmPasswordValid = signupStore(
    (state) => state.setConfirmPasswordValid
  );

  const accountExist = signupStore((state) => state.accountExist);
  const setAccountExist = signupStore((state) => state.setAccountExist);

  const variables = signupStore((state) => state.variables);
  const setVariables = signupStore((state) => state.setVariables);

  const navigate = useNavigate();

  useEffect(() => {
    if (checkUserDetailFormValidation) {
      checkPersonalDetailsValidation();
    }
  }, [firstName, lastName, phone, dob]);

  useEffect(() => {
    if (checkSignUpFormValidation) {
      checkPasswordMatch();
    }
  }, [password, confirmPassword]);

  const checkPersonalDetailsValidation = () => {
    let isValid = false;

    if (firstName.length == 0) {
      setFirstNameValid({ status: false, msg: "First Name is required" });
      isValid = false;
    } else if (!firstName.match(/^(([A-Za-z]|[" "]|[a-z]+['-]?)+)$/)) {
      setFirstNameValid({
        status: false,
        msg: "Please enter a valid first name.",
      });
      isValid = false;
    } else {
      setFirstNameValid({ status: true, msg: "" });
      isValid = true;
    }

    if (lastName.length == 0) {
      setLastNameValid({ status: false, msg: "Last Name is required" });
      isValid = isValid && false;
    } else if (!lastName.match(/^(([A-Za-z]|[" "]|[a-z]+['-]?)+)$/)) {
      setLastNameValid({
        status: false,
        msg: "Please enter a valid last name.",
      });
      isValid = isValid && false;
    } else {
      setLastNameValid({ status: true, msg: "" });
      isValid = isValid && true;
    }

    if (
      countryCode === undefined ||
      phone.length - countryCode?.countryCallingCode?.length - 1 === 0
    ) {
      setPhoneValid({ status: false, msg: "Phone Number is required" });
      isValid = isValid && false;
    } else if (!matchIsValidTel(`${countryCode.numberValue}`)) {
      setPhoneValid({
        status: false,
        msg: "Please enter a valid phone number.",
      });
      isValid = isValid && false;
    } else {
      setPhoneValid({ status: true, msg: "" });
      isValid = isValid && true;
    }

    return isValid;
  };

  const checkPasswordMatch = () => {
    let isValid = false;
    if (password.length > 0 && confirmPassword.length > 0) {
      if (password !== confirmPassword) {
        setConfirmPasswordValid({
          status: false,
          msg: "Your password do not match",
        });
        isValid = false;
      } else {
        setConfirmPasswordValid({ status: true, msg: "" });
        isValid = true;
      }
    }
    return isValid;
  };

  const [signUpFerretUser, { loading: emailSignUpLoader }] = useMutation(
    AUTHENTICATE_FERRET_USER,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        saveUserDataAndRedirectToInitialScreen(
          data.signUpEmail,
          null,
          () => {},
          "signup",
          variables,
          setUser,
          navigate
        );
      },
      onError: (error) => {
        if (
          error &&
          error.graphQLErrors &&
          Array.isArray(error.graphQLErrors) &&
          error.graphQLErrors.length > 0
        ) {
          console.log("data=====onError", error);
          setAccountExist({
            status: true,
            msg: error.graphQLErrors[0].message,
          });
        }
      },
    }
  );

  const onNextBtnClick = async () => {
    if (activeStepper == 0) {
      setCheckUserDetailFormValidation(true);
      let isValid = checkPersonalDetailsValidation();
      if (isValid && (dobValid.status || dobValid.status === null)) {
        setActiveStepper(1);
      }
    } else {
      setCheckSignUpFormValidation(true);
      let isValid = checkPasswordMatch();
      if (
        isValid &&
        emailValid.status &&
        passwordValid.status &&
        confirmPasswordValid.status
      ) {
        var dateString = dob
          ? moment(dob?.toString()).format("MM/DD/YYYY")
          : "";
        const deviceInfo = await getDeviceDetails();
        const variables = {
          email: email,
          password: password,
          lastName: lastName,
          name: firstName,
          dateOfBirth: dateString,
          phoneNumber: countryCode.nationalNumber,
          countryCode: countryCode.countryCallingCode,
          deviceDetails: deviceInfo,
        };

        signUpFerretUser({
          variables: variables,
        });
        setVariables(variables);
      }
    }
  };

  const onBackBtnClick = () => {
    if (activeStepper == 1) {
      setActiveStepper(0);
    } else {
      navigate("/signUpLanding");
    }
  };

  const personalDetailsUI = () => {
    return (
      <>
        <div style={{ marginBottom: 16 }}>
          <SimpleField
            title={"First Name"}
            placeholder={"e.g. John"}
            value={firstName}
            setValue={setFirstName}
            fieldValid={firstNameValid}
          />
        </div>

        <div style={{ marginBottom: 16 }}>
          <SimpleField
            title={"Last Name"}
            placeholder={"e.g. Lennon"}
            value={lastName}
            setValue={setLastName}
            fieldValid={lastNameValid}
          />
        </div>

        <div style={{ marginBottom: 16 }}>
          <DOBField
            value={dob}
            setValue={setDob}
            fieldValid={dobValid}
            setDobValid={setDobValid}
          />
        </div>

        <PhoneNumberField
          value={phone}
          setValue={setPhone}
          fieldValid={phoneValid}
          countryCode={countryCode}
          setCountryCode={setCountryCode}
        />
      </>
    );
  };

  const signUpUI = () => {
    return (
      <>
        <div style={{ marginBottom: 16 }}>
          <EmailField
            email={email}
            setEmail={setEmail}
            emailValid={emailValid}
            setEmailValid={setEmailValid}
            placeholder={"email@address.com"}
            checkFormValid={checkSignUpFormValidation}
          />
        </div>

        <div style={{ marginBottom: 16 }}>
          <PasswordField
            password={password}
            setPassword={setPassword}
            passwordValid={passwordValid}
            setPasswordValid={setPasswordValid}
            placeholder={"8 - 32 Characters; Mixed Case"}
            showPasswordStrength={true}
            checkFormValid={checkSignUpFormValidation}
            showPasswordIcon={true}
          />
        </div>

        <div style={{ marginBottom: 16 }}>
          <PasswordField
            password={confirmPassword}
            setPassword={setConfirmPassword}
            passwordValid={confirmPasswordValid}
            setPasswordValid={setConfirmPasswordValid}
            placeholder={"8 - 32 Characters; Mixed Case"}
            title={"Confirm Password"}
            checkFormValid={checkSignUpFormValidation}
            showPasswordIcon={true}
          />
        </div>
      </>
    );
  };

  return (
    <Stack className={Styles.bodySignupUserDetails}>
      <div className={Styles.signUpMain}>
        <div className={Styles.signupUserDetailsMain}>
          <img
            src={ferretLogo}
            className={Styles.ferretLogo}
            alt="ferretLogo"
          />

          <div className={Styles.navHeaderTitle}>Sign Up</div>

          <div>{activeStepper === 0 ? personalDetailsUI() : signUpUI()}</div>

          <div className={Styles.bottomSignUpUserDetails}>
            <div>
              <div className={Styles.stepper}>
                <div id="detailsDots">
                  <MobileStepper
                    variant="dots"
                    steps={2}
                    position="static"
                    activeStep={activeStepper}
                    sx={{
                      maxWidth: "100%",
                      flexGrow: 1,
                      background: "transparent",
                    }}
                  />
                </div>
              </div>
              <div className={Styles.bottomSignUpUserDetailsContainBt}>
                <WhiteBgButtonGreenBorder
                  fullWidth
                  onClick={onBackBtnClick}
                  variant="contained"
                  style={{ marginRight: 10 }}
                >
                  <div className={Styles.backBtnText}>Back</div>
                </WhiteBgButtonGreenBorder>

                <GreenBgButton
                  fullWidth
                  onClick={onNextBtnClick}
                  variant="contained"
                  loading={emailSignUpLoader}
                  style={{ marginLeft: 10 }}
                >
                  <div className={Styles.nextBtnText}>
                    {activeStepper == 0 ? "Next" : "Sign Up"}
                  </div>
                </GreenBgButton>
              </div>
            </div>
          </div>

          {accountExist.status == true && (
            <SingleBtnAlert
              description={accountExist.msg}
              btnTxt="OK"
              onBtnClick={() => {
                setAccountExist({ status: null, msg: "" });
              }}
            />
          )}
        </div>
      </div>
      {/* <NotAvailable /> */}
      <AppWalkthourgh />
      <div className={Styles.footer}>
        <Footer isLogin={true} isCenterCopyright={true} />
      </div>
    </Stack>
  );
};

export default SignUpUserDetails;
