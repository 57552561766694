import React from 'react'
import _ from 'lodash'
import ArrowApproved from '../../images/svg/arrow-approved'
import Styles from './socialNetworkCard.module.css'

const SocialNetworkCard = props => {
  const data = props.data;
  const isFinalState = props?.isFinalState
  const container = { backgroundColor: data.backgroundColor }

  const card = (data) => {
    const validNumberOfContacts = _.isInteger(data.numberOfContacts)
    const body = {}
    const arrowStyle = {}

    if (data.mode == "compact") {
      body.paddingTop = 3
      arrowStyle.bottom = 0
    } else {
      body.paddingTop = 38
      arrowStyle.top = 0
    }
    return (
      <div>
        {validNumberOfContacts && (data.fetchContactStatus || data.uploadStatus) && <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}><ArrowApproved className={Styles.arrowStyle} style={{ ...arrowStyle, top: 0 }} arrowStyle={data.arrowStyle}></ArrowApproved></div>}
        <div className={Styles.body} style={{ ...body, marginTop: (validNumberOfContacts && (data.fetchContactStatus || data.uploadStatus)) ? (isFinalState ? -20 : -40) : -24 }}>
          <img src={data.img} style={{
            width: parseInt(data.logoWidth, 10),
            height: parseInt(data.logoHeight, 10),
          }}></img>
          <span className={Styles.numberOfContactsLabel} style={{
            color: validNumberOfContacts ? data.titleColor : data.backgroundColor,
          }}>{ validNumberOfContacts && (data.fetchContactStatus || data.uploadStatus) ? data.contactSize  > 0 ? data.contactSize : " " : ' '}</span>
        </div>
        <span className={Styles.title} style={{ color: data.titleColor }}>{data.title}</span>
       <div>
          <span className={Styles.PlaceHoldertitle} style={{ color: data.titleColor }}>{data.placeHolder}</span>        
       </div>
      </div>
    )
  }
  return (
    <>
    {data.action ?
        <div
          className={`${Styles.bodySocial} ${Styles.container}`}
          style={container}
          onClick={() => data.action?.()}>
          {card(data)}
        </div>
    :
      <div
          className={`${Styles.bodySocial} ${Styles.container}`}
        style={container}>
        {card(data)}
      </div>}
    </>
    )
  // showConfirmationPermission(data){
  //   if(data?.title === 'Google'){
  //     Alert.alert(
  //       "Allow Ferret to access your contacts to:",
  //       "Import your contacts to Ferret to quickly search for contacts, share content with contacts, and invite contacts to Ferret if you choose.",
  //       [
  //         {
  //           text: "No",
  //         },
  //         { text: "Yes", onPress: () => {data.action?.()}}
  //       ],
  //       {cancelable: false}
  //     );
  //   }else{
  //     data.action?.()
  //   }
  // }
}
export default SocialNetworkCard
