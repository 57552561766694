import ferretLogo from "../../images/svg/ferret-logo.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { GreenBgButton } from "../buttons/greenBgButton";
import ImportedContactsLogo from "../../images/svg/imported-contacts-logo";
import Styles from './preferences.module.css'

const PreferencesSubmit = () => {

  const location = useLocation()
  const props = location?.state ? JSON.parse(location?.state) : {}

  const navigate = useNavigate();

  const onNext = () => {
    if(props?.showVersionA){
      navigate("/importContactsLanding", {state: props ? JSON.stringify(props) : {}})
    }else{
      navigate("/dashboard", {state: {showSplash: true}})
    }
  }

  return (
    <div className={Styles.body}>
      <div className={Styles.preferencesMain} style={{ paddingTop: 60 }}>
        <img src={ferretLogo} className={Styles.ferretLogoSubmit} alt="ferretLogo" />

        <ImportedContactsLogo
          style={{ alignSelf: "center", marginBottom: 20 }}
        />
        <div className={`${Styles.prefThankYou}`}>
          Thank you!
        </div>

        <div className={`${Styles.prefSuccessSubmit}`}>
          You have successfully submitted your app use preferences{" "}
        </div>

        <div
          style={{
            position: "absolute",
            bottom: "10%",
            display: "flex",
            width: "88%",
            justifContent: "end",
          }}
        >
          <GreenBgButton
            fullWidth
            variant="contained"
            onClick={onNext}
          >
            <div className={Styles.btnTxt}>Done</div>
          </GreenBgButton>
        </div>
      </div>
    </div>
  );
};

export default PreferencesSubmit;
