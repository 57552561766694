import "../../App.css";
import { Avatar, Button, TextField } from "@mui/material";
import infoItem from "../../images/svg/infoItem.svg";
import missingDetail from "../../images/svg/missingDetail.svg";
import starIconActive from "../../images/svg/starIconActive.svg";
import starDeactivated from "../../images/svg/starDeactivated.svg";
import startIconDeactive from "../../images/svg/startIconDeactive.svg";
import defaultAvatar from "../../images/svg/default-avatar.svg";
import combinedShape from "../../images/svg/combinedShape.svg";
import { capitalizeSearchTermsTag, capitalizeCase } from "../../utils/utils";
import { useContext, useEffect, useRef, useState } from "react";
import { getFilterImage } from "../../images/filterColorIcon/getFilterImage";
import { useMutation } from "react-apollo";
import { SET_PREFERENCE } from "./../../queries/queries";
import ContactModal from "../savedSearches/filtersModal/contactModal";
import ContactMenu from "../savedSearches/filtersModal/contactMenu";
import StylesMobile from "./savedSearchCard.module.css";
import StylesWeb from "./savedSearchCardWeb.module.css";
import { DefaultAvatar } from "../../images/svg/default-avatar";
import UnpaidMemberPopupModal from "../unpaidMemberModal/unpaidMemberModal";
import SubscriptionPlansModal from "../subscriptionPlans/subscriptionPlansModal";
import Variables from "../../utils/Variables";
import { preferredStore } from "../../store/preferredStore";
import { accountOverlayStore } from "../../store/accountOverlayStore";
import { UserContext } from "../../contexts/userContext";

const SavedSearchCard = (props) => {
  const {
    savedSearch,
    onPress,
    isFromClosest = false,
    onStartPress,
    isEditable,
    showStarred = true,
    categories,
    isWeb,
    selectedPotential,
  } = props;
  const [isPreferred, setPreferred] = useState(savedSearch?.isPreferred);
  const [anchorEl, setAnchorEl] = useState(false);
  const [contactModalVisible, setContactModalVisible] = useState(false);
  const [unpaidMemberModal, setUnpaidMemberModal] = useState(false);
  const [subscriptionModalVisible, setSubscriptionModalVisible] =
    useState(false);

  const setPreferredStore = preferredStore((state) => state.setPreferred);

  const setOpenSubscription = accountOverlayStore(
    (state) => state.setOpenSubscription
  );

  const { user, setUser } = useContext(UserContext);

  let meta_data = savedSearch.meta_data_array ?? [];
  let hasPicture = false;
  const Styles = isWeb ? StylesWeb : StylesMobile;
  const ref = useRef();

  const iconsList = [
    { title: "Negative News", total: 18 },
    { title: "Crunchbase", total: 20 },
    { title: "Positive News", total: 3 },
    { title: "Court Records", total: 1 },
  ];

  if (savedSearch?.photo)
    hasPicture = typeof savedSearch.photo == "string" ? true : false;

  const [setPreferenceContacts] = useMutation(SET_PREFERENCE, {
    onCompleted: (data) => {
      if (data?.setPreference?.message?.toLowerCase() === "limit reached") {
        let updatedValue = [
          {
            id: savedSearch.id,
            isPreferred: !savedSearch.isPreferred,
          },
        ];
        onBackdropPress(updatedValue[0]);
      }
      props?.checkWatchedLimit(data?.setPreference?.message);
    },
    onError: (error) => {
      props?.checkWatchedLimit(error?.setPreference?.message);
    },
  });

  useEffect(() => {
    if(selectedPotential)
      setPreferredStore(savedSearch?.isPreferred)
    setPreferred(savedSearch?.isPreferred);
  }, [savedSearch?.isPreferred]);

  if (!savedSearch) {
    return <></>;
  }

  const starModalVisible = () => {
    setContactModalVisible(!contactModalVisible);
  };

  const onBackdropPress = (data, isModalOpen = true) => {
    isModalOpen && setContactModalVisible(false);
    if (data?.id && data?.isPreferred != undefined) {
      if(selectedPotential)
        setPreferredStore(data.isPreferred)
      setPreferred(data.isPreferred);
      props.openContactModal(data);
    }
  };

  let userName = `${savedSearch.name}`;
  if (savedSearch?.lastName !== null) {
    userName = `${userName} ${savedSearch?.lastName}`;
  }

  let isMissingNamee = false;
  if (
    !savedSearch?.name ||
    savedSearch?.name == "" ||
    !savedSearch?.lastName ||
    savedSearch?.lastName == ""
  ) {
    isMissingNamee = true;
  }

  const CategoryIcons = ({ summary, onPress }) => {
    let iconsList = summary
      ? Object.values(summary)?.filter((item) => item?.total)
      : [];
    if (iconsList && iconsList.length) {
      let iconListWithScore = categories?.filter((item) =>
        iconsList?.some((d) => d.title == item.title)
      );
      let iconListFormatted = iconListWithScore?.sort(
        (a, b) => b.score - a.score
      );
      return (
        <div className={Styles.scrollmenuCategory}>
          {iconListFormatted?.map((item) => (
            <a key={item.title}>
              {getFilterImage(item.title, true, null, {
                transform: "scale(.75)",
              })}
            </a>
          ))}
        </div>
      );
    } else {
      <></>;
    }
  };

  const Tags = (props) => {
    const { terms, style, akaTags = [], onPress } = props;

    const tags =
      !terms.every((element) => element === null) &&
      terms?.map((item) => {
        if (item) {
          return item.split(";")?.map((itemTag, index) => (
            <span key={index} className={Styles.termTextSavedCard}>
              {capitalizeSearchTermsTag(itemTag, index)}
            </span>
          ));
        }
      });

    const akaListView = akaTags?.map((item, index) => {
      return (
        <span
          key={index}
          className={`${Styles.termTextSavedCard} ${Styles.aka}`}
        >
          {"AKA "}
          <span className={Styles.termText}>{item}</span>
        </span>
      );
    });

    if (tags && tags.length > 0) {
      return (
        <div id="scrollmenuCategory" className={Styles.scrollmenuCategory}>
          {
            savedSearch?.age && (<span className={`${Styles.termTextSavedCard}`}>
              <span className={Styles.termText}>{`Est Age ${savedSearch?.age}`}</span>
            </span>)
          }

          {akaListView}
          {tags}
        </div>
      );
    } else {
      return <></>;
    }
  };

  const onPressPreference = () => {
    let data = [
      {
        id: savedSearch.id,
        isPreferred: !savedSearch.isPreferred,
      },
    ];
    setPreferenceContacts({
      variables: {
        contacts: data,
      },
    });
    Variables.isRefreshClosestContacts = true;
    onBackdropPress(data[0], false);
  };

  const onPressPreferenceOld = () => {
    if (isEditable) {
      if(selectedPotential)
        setPreferredStore(!isPreferred)
      setPreferred((isPreferred) => {
        savedSearch.isPreferred = !isPreferred;
        savedSearch.isChanged = true;
        return !isPreferred;
      });
    }
  };
  return (
    <div
      className={Styles.body}
      style={selectedPotential ? { backgroundColor: "#E6F2F0" } : {}}
      onClick={(e) => {
        e.stopPropagation()
        onPress?.(savedSearch)
      }}
    >
      {savedSearch?.isProcessed === false && (
        <div className={Styles.warningView}>
          <img src={infoItem} />
          <span className={Styles.processText}>
            Ferret results are processing...
          </span>
        </div>
      )}

      {savedSearch?.isProcessed !== false && isMissingNamee && (
        <div className={Styles.warningView}>
          <img src={missingDetail} />
          <span className={Styles.missingText}>
            Use a full name for this contact to improve results.
          </span>
        </div>
      )}

      <div className={Styles.titleView}>
        <div className={Styles.profileDiv}>
          <Avatar
            ref={ref}
            className={Styles["profilePicture"]}
            src={
              savedSearch?.linkedinProfile?.profile_picture_src ??
              savedSearch.photo
            }
          >
            <DefaultAvatar className={Styles.profilePicture} />
          </Avatar>
          <span
            className={Styles.title}
            style={selectedPotential ? { color: "#078167" } : {}}>
            {capitalizeCase(userName, true)}
          </span>
        </div>
        <div className={Styles.starDiv}>
          {showStarred ? (
            <img
              style={{ padding: 10, marginEnd: isFromClosest ? -10 : 0 }}
              src={isPreferred ? starIconActive : starDeactivated}
              onClick={(e) => {
                e.stopPropagation();
                isFromClosest
                  ? onStartPress?.(savedSearch)
                  : onPressPreference();
              }}
            />
          ) : null}

          {!isFromClosest ? (
            // isWeb ? (
            //   <div>
            //     <img
            //       style={{ padding: 10, marginEnd: isFromClosest ? -10 : 0 }}
            //       src={combinedShape}
            //       onClick={(e) => {
            //         e.stopPropagation();
            //         setAnchorEl(e.currentTarget);
            //       }}
            //     />
            //     <ContactMenu
            //       anchorEl={anchorEl}
            //       setAnchorEl={setAnchorEl}
            //       savedSearch={savedSearch}
            //       isVisible={contactModalVisible}
            //       onDeleteContact={props.onDeleteContact}
            //       checkWatchedLimit={props?.checkWatchedLimit}
            //       onBackdropPress={onBackdropPress}
            //       onChangePotentialMatch={props?.onChangePotentialMatch}
            //       showChangePotentialMatch={props?.showChangePotentialMatch}
            //     />
            //   </div>
            // ) : (
              <img
                style={{ padding: 10, marginEnd: isFromClosest ? -10 : 0 }}
                src={combinedShape}
                onClick={(e) => {
                  e.stopPropagation();
                  starModalVisible();
                }}
              />
            // )
          ) : null}
        </div>
        {isFromClosest && !showStarred ? (
          <img
            style={{ padding: 10, marginEnd: isFromClosest ? -10 : 0 }}
            src={isPreferred ? starIconActive : startIconDeactive}
            onClick={(e) => {
              e.stopPropagation();
              onPressPreferenceOld();
            }}
          />
        ) : null}
      </div>

      {savedSearch?.isProcessed &&
        savedSearch?.multiplePotentialMatchesFound && (
          <div className={Styles.multiplePotential}>
            <img src={missingDetail} />
            <span className={Styles.missingText}>
              Multiple potential matches found. Tap to fix.
            </span>
          </div>
        )}

      <CategoryIcons
        onPress={() => onPress?.(savedSearch)}
        summary={savedSearch.summary}
      />

      <Tags
        terms={meta_data}
        onPress={() => onPress?.(savedSearch)}
        akaTags={savedSearch?.akaList}
      />

      {unpaidMemberModal && (
        <UnpaidMemberPopupModal
          onBackdropPress={() => {
            setUnpaidMemberModal(false);
          }}
          upgradeNow={() => {
            setOpenSubscription(true);
          }}
        />
      )}

      {contactModalVisible && (
        <ContactModal
          savedSearch={savedSearch}
          isVisible={contactModalVisible}
          onDeleteContact={props.onDeleteContact}
          checkWatchedLimit={props?.checkWatchedLimit}
          onBackdropPress={onBackdropPress}
          setUnpaidMemberModal={setUnpaidMemberModal}
          onChangePotentialMatch={props?.onChangePotentialMatch}
          showChangePotentialMatch={props?.showChangePotentialMatch}
        />
      )}
    </div>
  );
};

export default SavedSearchCard;
