// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cardItemDataCategory_gridCard__P34J1 {\n  max-width: 160px;\n  margin-right: 20px;\n  margin-top: 8px;\n  margin-bottom: 8px;\n}\n\n.cardItemDataCategory_text__AYQfv {\n  width: -webkit-fill-available\n}\n\n.cardItemDataCategory_itemViewData__wjMY- {\n  background-color: #ffffff;\n  width: 100%;\n  flex-direction: row;\n  padding: 10px;\n  border-color: #EFF1F5;\n  border-radius: 12px;\n  border-width: 1px;\n  border-style: solid;\n  align-self: center;\n  margin-top: 6px;\n  justify-content: center;\n  align-items: center;\n  display: flex;\n  height: 60px;\n}", "",{"version":3,"sources":["webpack://./src/web/components/PersonInfo/cardItemDataCategory.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE;AACF;;AAEA;EACE,yBAAyB;EACzB,WAAW;EAEX,mBAAmB;EACnB,aAAa;EAEb,qBAAqB;EACrB,mBAAmB;EACnB,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;EACf,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,YAAY;AACd","sourcesContent":[".gridCard {\n  max-width: 160px;\n  margin-right: 20px;\n  margin-top: 8px;\n  margin-bottom: 8px;\n}\n\n.text {\n  width: -webkit-fill-available\n}\n\n.itemViewData {\n  background-color: #ffffff;\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  padding: 10px;\n  margin-top: 8px;\n  border-color: #EFF1F5;\n  border-radius: 12px;\n  border-width: 1px;\n  border-style: solid;\n  align-self: center;\n  margin-top: 6px;\n  justify-content: center;\n  align-items: center;\n  display: flex;\n  height: 60px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gridCard": "cardItemDataCategory_gridCard__P34J1",
	"text": "cardItemDataCategory_text__AYQfv",
	"itemViewData": "cardItemDataCategory_itemViewData__wjMY-"
};
export default ___CSS_LOADER_EXPORT___;
