// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".checkFilter_container__t4Nf4 {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n}\n\n\n.checkFilter_containerCount__kQrnb {\n  align-items: center;\n  flex-direction: row;\n}\n\n\n.checkFilter_countText__4aqw1 {\n  color: #222F4B;\n  margin-right: 5px;\n}\n\n\n.checkFilter_lableText__\\+29Eo {\n  font-family: 'Proxima Nova';\n  font-style: normal;\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 22px;\n  color: #142030;\n}\n\n\n.checkFilter_checkBox__2j6s- {\n  margin: 0;\n  margin-left: 0;\n  margin-right: 0;\n  padding: 0;\n}", "",{"version":3,"sources":["webpack://./src/components/savedSearches/filtersModal/checkFilter/checkFilter.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;AACrB;;;AAGA;EACE,mBAAmB;EACnB,mBAAmB;AACrB;;;AAGA;EACE,cAAc;EACd,iBAAiB;AACnB;;;AAGA;EACE,2BAA2B;EAC3B,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB;;;AAGA;EACE,SAAS;EACT,cAAc;EACd,eAAe;EACf,UAAU;AACZ","sourcesContent":[".container {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n}\n\n\n.containerCount {\n  align-items: center;\n  flex-direction: row;\n}\n\n\n.countText {\n  color: #222F4B;\n  margin-right: 5px;\n}\n\n\n.lableText {\n  font-family: 'Proxima Nova';\n  font-style: normal;\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 22px;\n  color: #142030;\n}\n\n\n.checkBox {\n  margin: 0;\n  margin-left: 0;\n  margin-right: 0;\n  padding: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "checkFilter_container__t4Nf4",
	"containerCount": "checkFilter_containerCount__kQrnb",
	"countText": "checkFilter_countText__4aqw1",
	"lableText": "checkFilter_lableText__+29Eo",
	"checkBox": "checkFilter_checkBox__2j6s-"
};
export default ___CSS_LOADER_EXPORT___;
