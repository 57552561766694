import React, {useState} from 'react';
import NoSearchResult from '../noResults/NoSearchResult';
import QuestionInterface from '../questionInterface';
import SavedSearchMatch from '../../savedSearchMatch';

const QuestionAnswerInterface = props => {
  const {
    fromBackSearch = false,
    isVisible = false,
    questions,
    searchName,
    onSelectAnswer,
    onEditingOtherInput,
    onSubmitEditingOtherInput,
    potentialMatch,
    onPotentialSelected,
    isSavedSearch = false,
    searchType = '',
    isAutoFocus = true,
    showConfirmSearch = false,
    showAdvancedSearch,
    isDesktop
  } = props;

  const [animationFinished, setAnimationFinished] = useState(false)
  let questionsView;
  if(fromBackSearch && potentialMatch && potentialMatch.length){
    return (
    <SavedSearchMatch
        isDesktop={isDesktop}
        showAdvancedSearch={showAdvancedSearch}
        potentialMatch = {potentialMatch}
        onPress={onSelectAnswer(1)}
        onPotentialSelected={onPotentialSelected}
      />
      )
  }else{
    questionsView = questions.map((question, indexOfQuestion) => {
    const {identifier, label, answers } = question;
    return (
      <div>
       {(identifier === 'potential_matches') &&
          answers &&
          answers.length > 0 &&
          !answers[0].status && 
          potentialMatch && potentialMatch.length && (
            <SavedSearchMatch
              isDesktop={isDesktop}
              showAdvancedSearch={showAdvancedSearch}
              potentialMatch = {potentialMatch}
              onPress={onSelectAnswer(1)}
              onPotentialSelected={onPotentialSelected}
            />
          )}

      {(showConfirmSearch && ((identifier === 'noResultFound_details' && indexOfQuestion <= 1) || (identifier === 'noResultFound_SearchType'  && indexOfQuestion == 0))) && (
          <NoSearchResult
            showConfirmSearch={showConfirmSearch}
            questions={questions}
            searchName={searchName}
            isSavedSearch={isSavedSearch}
            searchType={searchType}
            animationFinished = {animationFinished}
            setAnimationFinished = {setAnimationFinished}
          />
        )}
       {(identifier != 'potential_matches') && (identifier != 'noResultFound_details' || animationFinished || isSavedSearch) && !showConfirmSearch &&  <QuestionInterface
          isAutoFocus={isAutoFocus}
          identifier={identifier}
          key={`${identifier}-${label}`}
          question={label}
          answers={answers}
          onSelectAnswer={onSelectAnswer(indexOfQuestion)}
          onEditingOther={onEditingOtherInput(indexOfQuestion)}
          onSubmitEditingOther={onSubmitEditingOtherInput(indexOfQuestion)}
        />}
      </div>
    );
  });
}

  if (isVisible) {
    return questionsView
  } else {
    return <div />;
  }
};

export default QuestionAnswerInterface;
