// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".layout_bodySearch__v8LB- {\n\twidth: clamp(300px, 23vw, 390px);\n\tpadding: 30px;\n\theight: 100%;\n}\n.layout_footerMain__NgcZn {\n\twidth: -webkit-fill-available;\n\topacity: 100;\n\tposition: absolute;\n\tbottom: 0;\n}\n  ", "",{"version":3,"sources":["webpack://./src/web/layout/layout.module.css"],"names":[],"mappings":"AAAA;CACC,gCAAgC;CAChC,aAAa;CACb,YAAY;AACb;AACA;CACC,6BAA6B;CAC7B,YAAY;CACZ,kBAAkB;CAClB,SAAS;AACV","sourcesContent":[".bodySearch {\n\twidth: clamp(300px, 23vw, 390px);\n\tpadding: 30px;\n\theight: 100%;\n}\n.footerMain {\n\twidth: -webkit-fill-available;\n\topacity: 100;\n\tposition: absolute;\n\tbottom: 0;\n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bodySearch": "layout_bodySearch__v8LB-",
	"footerMain": "layout_footerMain__NgcZn"
};
export default ___CSS_LOADER_EXPORT___;
