export default function DotSlider({ dots, page, onDotpress }) {
  let renderDot = [];

  for (let i = 1; i <= dots; i++) {
    renderDot = [
      ...renderDot,
      <div style={{margin: 5}}>
      <div
        onClick={()=>onDotpress?.(i)}
        key={i}
        style={
          page == i
            ? {
                width: 13,
                height: 13,
                borderRadius: 100,
                backgroundColor: "#142030",
              }
            : {
                width: 10,
                height: 10,
                borderRadius: 100,
                backgroundColor: "grey",
              }
        }
      ></div>
      </div>,
    ];
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignSelf: "center",
        alignItems: "center",
      }}
    >
      {renderDot}
    </div>
  );
}
