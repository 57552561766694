const { innerWidth: windowWidth, innerHeight: windowHeight } = window

const riskColors = {
  high: '#DA011B',
  medium: '#FB8100',
  low: '#0F9125',
  HIGH: '#FF3D3D',
  MEDIUM: '#FFAD57',
  LOW: '#56DC6D',
  null: '#56DC6D',
}

const getContactsListContainerStyle = () => {
  return {
    contactsListContainer: {
      backgroundColor: '#F8FAFF',
      flex: 1,
      display: "flex", 
      flexDirection: 'column',
      marginTop: 20,
      borderTopLeftRadius: 12,
      borderTopRightRadius: 12,
    },
  };
};
const createStyle = (riskCategory, zoomSize = 0, maxZoom = 4) => {
  const titleTextColor = '#FFF';
  const backgroundColor = '#142030';
  const contactsListContainerStyle = getContactsListContainerStyle();
  return {
    ...contactsListContainerStyle,
    background: {
      backgroundColor: backgroundColor,
    },
    container: {
      backgroundColor: backgroundColor,
      height: '100vh',
      overflow: "hidden"
    },
    mainContainer: {
      display: "flex", 
      flexDirection: 'column',
      margin: 16,
    },
    matchTypeContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      display: "flex"
    },
    matchTypeText: {
      color: '#FFF',
      fontSize: 13,
      fontWeight: '400'
    },
    categoryText: {
      color: '#FFF',
      fontSize: 13,
      fontWeight: 'bold'
    },
    urlContainer: {
      backgroundColor: '#283147',
      paddingTop: 3,
      paddingBottom: 3,
      paddingLeft: 6,
      paddingRight: 6,
      borderRadius: 3,
      marginLeft: 8,
    },
    urlText: {
      color: '#FFF',
      textDecorationColor: '#FFF',
      fontSize: 13,
    },
    articleCategory: {
      color: '#fff',
      fontSize: 14,
      paddingTop: 1,
      letterSpacing: -0.1,
      width: "90%",
      marginLeft: 4,
    },
    articleSource: {
      display: "flex", 
      flexDirection: 'row',
      justifyContent: 'flex-start',
      marginTop: 24,
      alignItems: 'center'
    },
    articleSrcImg: {
      height: 25,
      width: 25,
      marginRight: 5
    },
    articleSourcePicture: {
      width: 22,
      height: 22,
      borderRadius: 11,
    },
    sourceContainer: {
      display: "flex", 
      flexDirection: 'row',
      marginTop: 10,
      alignItems: 'center',
    },
    source: {
      color: titleTextColor,
      fontSize: 14,
      marginLeft: 10,
    },
    articleSourcePicture: {
      width: 22,
      height: 22,
      borderRadius: 11,
    },
    segmentedControlButton: {
      backgroundColor: '#FFF',
    },
    segmentedControlText: {
      color: '#697080',
      fontSize: 14,
      fontWeight: 500,
      marginLeft: 20,
      marginRight: 20,
      textTransform: 'capitalize'
    },
    segmentedControlSelectedButton: {
      backgroundColor: '#E6F2F0',
    },
    segmentedControlSelectedText: {
      color: '#078167',
      fontSize: 14,
      fontWeight: 500,
      marginLeft: 20,
      marginRight: 20,
      textTransform: 'capitalize'
    },
    contentContainer: {
      backgroundColor: '#FFF',
      borderTopLeftRadius: 12,
      borderTopRightRadius: 12,
    },
    titleContainer: {
      position: "sticky",
      display: "flex", 
      flexDirection: 'column',
      zIndex: 1,
      top: 0,
      paddingTop: 10,
      paddingBottom: 10,
      backgroundColor: backgroundColor,
      paddingLeft: 16,
      paddingRight: 16,
    },
    titleDecorator: {
      width: 10,
      height: 10,
      borderRadius: 5,
      marginEnd: 8,
      backgroundColor: riskColors.riskCategory
    },
    title: {
      color: titleTextColor,
      fontFamily: 'Proxima Nova',
      fontSize: 18,
      fontWeight: 'bold',
    },
    attributesContainer: {
      minHeight: windowHeight - windowHeight / 3,
      backgroundColor: '#FFF',
      borderTopRightRadius: 12,
      borderTopLeftRadius: 12,
      paddingLeft: 20,
      paddingRight: 20,
      flex: 1,
    },
    noAttributeMainView: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      paddingTop: 35
    },
    noAttributeImgDiv:{
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    noAttributeImg: {
      width: 350,
      height: 180,
    },
    noAttributeTitle: {
      fontFamily: 'Proxima Nova',
      fontSize: 24,
      fontWeight: 'bold',
      color: '#1A233B',
      textAlign: "center",
      width: "90%",
      alignSelf: "center",
      marginTop: 24,
    },
    noAttributeDesc: {
      fontFamily: 'Proxima Nova',
      fontSize: 16,
      color: '#222F4B',
      textAlign: "center",
      width: "90%",
      alignSelf: "center",
      marginTop: 16,
    },
    zoomText: {
      padding: 8,
      fontSize: 20,
      marginEnd: 18,
      fontWeight: '800',
      color: '#fff',
    },
    zoomView: {
      display: "flex", flexDirection: 'row', alignItems: 'center'
    },
    triangle: {
      width: 0,
      height: 0,
      backgroundColor: "transparent",
      borderStyle: "solid",
      borderLeftWidth: 7,
      borderRightWidth: 7,
      borderBottomWidth: 14,
      marginRight: 14,
      borderLeftColor: "transparent",
      borderRightColor: "transparent",
      borderBottomColor: '#fff',
    },
    zoomTopView: {
      display: "flex",
      flexDirection: "column",
      position: 'absolute',
      right: 6,
      alignItems: 'flex-end',
      zIndex: 10,
    },
    zoomPopupView: {
      display: "flex", flexDirection: 'row', backgroundColor: '#FFF', borderRadius: 8
    },
    zoomTextALess: {
      color: zoomSize <= 0 ? '#AAA' : '#1C2642', padding: 14, fontSize: 16, fontWeight: '600'
    },
    zoomTextAPlus: {
      color: zoomSize >= maxZoom ? '#AAA' : '#1C2642', padding: 14, fontSize: 16, fontWeight: '600'
    },
    board: { width: 1, backgroundColor: '#9EA9C1' },
    tracersContainer: {
      backgroundColor: '#FFF',
      minHeight: windowHeight / 1.3,
      borderTopRightRadius: 12,
      borderTopLeftRadius: 12,
      flex: 1,
    },
    tracersTitle: {
      color: '#078167',
      fontFamily: 'Proxima Nova',
      fontSize: 18,
      fontWeight: 700,
      marginTop: 10,
      marginBottom: 10,
      paddingLeft: 16,
      paddingRight: 16
    },
    tracersKey: {
      color: '#142030',
      fontFamily: 'Proxima Nova',
      fontSize: 14,
      fontWeight: '600',
      paddingLeft: 16,
      paddingRight: 16
    },
    tracersValue: {
      color: '#142030',
      fontFamily: 'Proxima Nova',
      fontSize: 14,
      fontWeight: 400,
      marginTop: 4,
      marginBottom: 12,
      paddingLeft: 16,
      paddingRight: 16,
      whiteSpace: 'break-spaces'
    },
    headerLeft: {
      container: {
        marginTop: 0,
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#253662"
      },
      fullName: {
        marginLeft: 10,
        color: '#FFF',
        fontSize: 22,
        fontWeight: '700',
        textTransform: 'capitalize'
      },
    },
    profilePicture: {
      height: 30,
      width: 30,
      borderRadius: 15,
      borderColor: "#FFF",
      borderWidth: 3
    },
    leftHeaderContainer: {
      display: "flex",
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    headerContainer: {
      backgroundColor: backgroundColor,
      display: "flex",
      flexDirection: 'row',
      alignItems: "center",
      justifyContent: "space-between",
      padding: 16
    },
    addressIconView: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
    }
  }
};

export default createStyle;
