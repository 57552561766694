import React from "react";

function TickGreen() {
  return (
    <svg
      width="13"
      height="10"
      fill="none"
      viewBox="0 0 13 10"
    >
      <path
        fill="#91CB6C"
        fillRule="evenodd"
        d="M12.644.42a1.21 1.21 0 00-1.724 0L4.407 7.026 1.964 4.55a1.143 1.143 0 00-1.628 0 1.183 1.183 0 000 1.65l3.352 3.4c.448.454 1.181.454 1.629 0l.022-.025.026-.023 7.28-7.382a1.252 1.252 0 000-1.748z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default TickGreen;