import React, { useEffect, useRef, useState } from "react";
import Styles from "./contactsImported.module.css";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import LoadingBar from "../loadingBar/locadingBar";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  bottom: {
    color: "#C0C7D6 !important",
  },
  top: {
    color: "#91CB6C !important",
    animationDuration: "550ms",
    position: "absolute",
    left: 0,
  },
}));

const ContactsImported = ({ isProcessRefresh, savedSearches }) => {
  const circlRef = useRef();
  const isLarge = circlRef?.current?.clientWidth >= 240;
  const myClasses = useStyles();
  const [processed, setProcessed] = useState(0);

  useEffect(() => {
    if (!isProcessRefresh && savedSearches && savedSearches.length > 0) {
      const processedItem =
        savedSearches.filter((item) => item?.isProcessed)?.length ?? 0;
      setProcessed(Math.floor((processedItem * 100) / savedSearches.length));
    }
  }, [isProcessRefresh, savedSearches]);
  return (
    <div className={Styles.containerMain}>
      <span className={Styles.titleMain}>Contacts Imported</span>
      {isProcessRefresh ? (
        <LoadingBar />
      ) : (
        <div className={isLarge ? Styles.circleViewLarge : Styles.circleView}>
          {isLarge ? (
            <div className={myClasses.root}>
              <CircularProgress
                variant="determinate"
                className={myClasses.bottom}
                size={176}
                thickness={9.5}
                value={100}
              />
              <CircularProgress
                variant="determinate"
                disableShrink
                className={myClasses.top}
                classes={{
                  circle: myClasses.circle,
                }}
                size={176}
                thickness={9.5}
                value={processed}
              />
            </div>
          ) : (
            <div className={myClasses.root}>
              <CircularProgress
                variant="determinate"
                className={myClasses.bottom}
                size={150}
                thickness={9.5}
                value={100}
              />
              <CircularProgress
                variant="determinate"
                disableShrink
                className={myClasses.top}
                classes={{
                  circle: myClasses.circle,
                }}
                size={150}
                thickness={9.5}
                value={processed}
              />
            </div>
          )}
          <div className={isLarge ? Styles.textViewLarge : Styles.textView}>
            <snap className={Styles.progressTxt}>{processed + "%"}</snap>
            <snap className={Styles.progressing}>Processed</snap>
          </div>
        </div>
      )}
      <div className={Styles.demoView} ref={circlRef} />
    </div>
  );
};

export default ContactsImported;
