
const createStyle = () => {
  const backgroundColor = '#FFF'

  return {
    itemView: {
      height: "auto",
      padding: 8,
      paddingLeft: "5px",
      borderColor: '#C0C7D6',
      borderRadius: 6,
      borderWidth: 1,
      borderStyle: "solid",
      alignSelf: 'center',
      marginTop: 8,
      marginRight: 10,
      justifyContent: 'center',
      alignItems: 'center',
      marginEnd: 10,
      display: "flex",
      flexDirection: "row"
    },
    filterLable: {
      marginTop: 10,
      marginLeft: 16,
      fontFamily: 'Proxima Nova',
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: 14,
      textTransform: 'capitalize',
      color: '#142030',
    },
    itemLabel: {
      fontFamily: 'Proxima Nova',
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: 14,
      color: "#697080",
      marginLeft: 6,
      display: "flex",
      width: "max-content"
    },
    dialogTitle: {
      fontFamily: 'Proxima Nova',
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: 18,
      textAlign: "center",
      color: "#142030",
      marginTop: 12,
      paddingLeft: 6,
      paddingRight: 6
    },
    container: {
      justifyContent: 'flex-end',
      margin: 0,
    },
    containerData: {
      position: 'absolute',
      bottom: 0,
      width: '100%',
      borderTopRightRadius: 12,
      borderTopLeftRadius: 12,
      alignSelf: 'center',
      backgroundColor: backgroundColor,
      paddingBottom: 40
    },
    containerDataText: {
      paddingLeft: 32,
      paddingRight: 32,
    },
    crossButton: {
      width: "100%",
      top: 8,
      right: 10,
      padding: 10,
      display: "flex",
      justifyContent: "flex-end",
      backgroundColor: "#00000000"
    },
    closeIcon: {
      padding: 6,
    },
    dialogSubTitle: {
      fontFamily: 'Proxima Nova',
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: 14,
      textAlign: "center",
      color: "#142030",
      marginLeft: 10
    },
    dialogMessage: {
      fontFamily: 'Proxima Nova',
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: 14,
      color: "#142030",
      marginTop: 8
    }
  }
}

export default createStyle
