import React, { useState } from 'react';
import CardItem from './cardItem'
import DropdownArrow from '../../images/svg/dropdown-arrow'
import { ButtonBase, Paper } from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import CardItemDataCategory from './cardItemDataCategory';
import Styles from './personeInfo.module.css'

const Item = styled(Paper)(({ theme }) => ({
  textAlign: 'center',
}));


const ArticleCategoriesList = (props) => {
  const {
    data,
    isClickable,
    onClick,
    isDataCategory,
    verified,
    partial,
    needsReview
  } = props;
  const [showMore, setShowMore] = useState(true);

  return (
    <div className={Styles.categoryContainer}>
      <div
        style={{
          justifyContent: "space-between",
          display: "flex",
          flexDirection: "row",
        }}
      >
        {!isClickable && (
          <span className={Styles.textCategoriesHeader}>
            {"What we didn’t find"}
          </span>
        )}
        {!isClickable && (
          <ButtonBase
            style={{ paddingHorizontal: 15 }}
            onClick={() => setShowMore(!showMore)}
          >
            <DropdownArrow
              style={showMore ? { transform: `rotate(180deg)` } : {}}
              type={"more"}
            />
          </ButtonBase>
        )}
      </div>
      <div style={{ marginTop: 10 }}>
        {showMore && (
          <Grid
            container
            columnSpacing={2}
            rowSpacing={1}
            columns={{
              xs: isDataCategory ? 2 : 3,
              sm: isDataCategory ? 2 : 3,
              md: isDataCategory ? 2 : 3,
            }}
          >
            {data.map((item, index) => (
              <Grid xs={1} sm={1} md={1} key={index}>
                <Item style={{ backgroundColor: '#F8FAFF',}} elevation={0}>
                  {isDataCategory ? (
                    <CardItemDataCategory
                      style={{ width: "45%" }}
                      item={item}
                      isClickable={isClickable}
                      onClick={onClick}
                      verified={verified}
                      partial={partial}
                      needsReview={needsReview}
                    />
                  ) : (
                    <CardItem
                      item={item}
                      isClickable={isClickable}
                      onClick={onClick}
                    />
                  )}
                </Item>
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    </div>
  );
}
export default ArticleCategoriesList