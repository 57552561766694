import * as React from "react"
const CourtRecords = (props) => {
  return(
    <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    >
      <g clipPath="url(#a)">
        <path
          d="M4 28h10.667A1.334 1.334 0 0 0 16 26.667v-2a2 2 0 0 0-2-2H4.667a2 2 0 0 0-2 2v2A1.333 1.333 0 0 0 4 28Z"
          fill={props.selected ? "#142030" :  "#b5b9c4"}
        />
        <path
          d="M30.667 24a2.148 2.148 0 0 0 0-3.107l-9.08-8.186a.373.373 0 0 1-.12-.24.332.332 0 0 1 .106-.24L23.92 9.88a.347.347 0 0 1 .44 0c.345.261.767.402 1.2.4a2.04 2.04 0 0 0 1.413-.587 2.013 2.013 0 0 0 0-2.826L20.72.587a1.987 1.987 0 0 0-2.827 0 2.014 2.014 0 0 0-.186 2.666.307.307 0 0 1 0 .427l-7.414 7.333a.307.307 0 0 1-.426 0A2.013 2.013 0 0 0 7.2 11.2a1.987 1.987 0 0 0 0 2.827l6.24 6.253a2.012 2.012 0 1 0 3.013-2.667.347.347 0 0 1 0-.44l2.347-2.346a.333.333 0 0 1 .24-.107.28.28 0 0 1 .24.12L27.56 24a2.148 2.148 0 0 0 3.107 0Z"
          fill={props.selected ? props.iconType =="blackWhite"? "#1E1E1E": "#FB8100" : '#b5b9c4'}
        />
        <path
          d="M16.667 29.333H2A1.333 1.333 0 0 0 2 32h14.667a1.334 1.334 0 0 0 0-2.667Z"
          fill={props.selected ? "#142030" :  "#b5b9c4"}
        />
      </g>
      <defs>
        <clip-path id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clip-path>
      </defs>
    </svg>
)}

export default CourtRecords

