import React, { useState } from "react";
import CardItem from "./cardItem";
import DropdownArrow from "../../../images/svg/dropdown-arrow";
import { ButtonBase, Paper } from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";
import CardItemDataCategory from "./cardItemDataCategory";
import createStyle from "./styles";
import Styles from "./articleCategoriesList.module.css";

const Item = styled(Paper)(({ theme }) => ({
  textAlign: "center",
}));

const ArticleCategoriesList = (props) => {
  const {
    data,
    isClickable,
    onClick,
    isDataCategory,
    verified,
    partial,
    needsReview,
  } = props;
  const [showMore, setShowMore] = useState(true);
  const style = createStyle();

  return (
    <div className={Styles.gridContainerMain}>
      <div
        style={{
          justifyContent: "space-between",
          display: "flex",
          flexDirection: "row",
        }}
      >
        {!isClickable && (
          <span style={style.textCategoriesHeader}>
            {"What we didn’t find"}
          </span>
        )}
        {!isClickable && (
          <ButtonBase
            style={{ paddingHorizontal: 15, marginRight: 20 }}
            onClick={() => setShowMore(!showMore)}
          >
            <DropdownArrow
              style={showMore ? { transform: `rotate(180deg)` } : {}}
              type={"more"}
            />
          </ButtonBase>
        )}
      </div>
      <div className={Styles.gridContainer}>
        {showMore &&
          data.map((item, index) => (
            <>
              {isDataCategory ? (
                <CardItemDataCategory
                  style={{ width: "45%" }}
                  item={item}
                  isClickable={isClickable}
                  onClick={onClick}
                  verified={verified}
                  partial={partial}
                  needsReview={needsReview}
                  index={index}
                />
              ) : (
                <CardItem
                  item={item}
                  isClickable={isClickable}
                  onClick={onClick}
                  index={index}
                />
              )}
            </>
          ))}
      </div>
    </div>
  );
};
export default ArticleCategoriesList;
