function saveToken(token) {
    try {
        localStorage.setItem('authUserToken', token);
      } catch (e) {
        // saving error
      }
}

function saveRefreshToken(refreshToken) {
    try {
        localStorage.setItem('authRefreshToken', refreshToken);
      } catch (e) {
        // saving error
      }
}

function getToken() {
    try {
        const token = localStorage.getItem('authUserToken');
        return token;
      } catch (e) {
        return '';
      }
    
}

function getRefreshToken() {
    try {
        const token = localStorage.getItem('authRefreshToken');
        return token;
      } catch (e) {
        return '';
      }
    
}

function removeToken() {
    try {
      localStorage.removeItem('authRefreshToken')
      localStorage.removeItem('authUserToken')
    } catch (e) {
    }
}

function setFirebaseToken(fcm_token){
    try {
        localStorage.setItem('FCMToken', fcm_token);
    } catch (e) {
    }
}

function getFirebaseToken(){
    try {
        const token = localStorage.getItem('FCMToken');
        return token;
    } catch (e) {
        return '';
    }
}

function setAskPermissionContact() {
  try {
    localStorage.setItem('AskPermissionContact', 'asked');
  } catch (e) {}
}

function getAskPermissionContact() {
  try {
    const res = localStorage.getItem('AskPermissionContact');
    return res;
  } catch (e) {
    return '';
  }
}

function setIntialLogin() {
  try {
      localStorage.setItem('isInitialLoginWeb', "true");
    } catch (e) {
      // saving error
    }
}

function getIntialLogin() {
  try {
    const res = localStorage.getItem('isInitialLoginWeb');
    return res;
  } catch (e) {
    return '';
  }
}


function setDisclaimer(flag) {
  try {
      localStorage.setItem('disclaimerVisible', flag);
    } catch (e) {
      // saving error
    }
}

function getDisclaimer() {
  try {
    const res = localStorage.getItem('disclaimerVisible');
    return res;
  } catch (e) {
    return 'false';
  }
}

function setShowNotificationStatusAlert(flag) {
  try {
    localStorage.setItem('notificationAlertStatus', flag);
  } catch (e) {
    // saving error
  }
}

function getShowNotificationStatusAlert() {
  try {
    const res = localStorage.getItem('notificationAlertStatus') ?? true;
    return res;
  } catch (e) {
    return 'false';
  }
}

function saveBaseURL(urls) {
  try {
      localStorage.setItem('baseUrl', urls);
    } catch (e) {
      // saving error
    }
}
function getBaseURL() {
  try {
      let baseUrl = localStorage.getItem('baseUrl');

      if(baseUrl == undefined || baseUrl == null){
        baseUrl = {
          auth: null,
          contacts: null,
          activity: null,
          search: null,
          notification: null,
          subscription: null,
        }
      }
      return baseUrl;
    } catch (e) {
      return '';
    }
  
}

function getSubscriptionURL() {
  try {
    let baseUrl = localStorage.getItem('baseUrl')
    let baseURLJSON = JSON.parse(baseUrl)
    return baseURLJSON?.subscription ?? process.env.REACT_APP_DEFAULT_SUBSCRIPTION_URL
  } catch (e) {
    return process.env.REACT_APP_DEFAULT_SUBSCRIPTION_URL
  }
}

export {
  saveToken,
  getToken,
  removeToken,
  setFirebaseToken,
  getFirebaseToken,
  getRefreshToken,
  saveRefreshToken,
  setAskPermissionContact,
  getAskPermissionContact,
  getIntialLogin,
  setIntialLogin,
  setDisclaimer,
  getDisclaimer,
  saveBaseURL,
  getBaseURL,
  getSubscriptionURL,
  getShowNotificationStatusAlert,
  setShowNotificationStatusAlert
};
