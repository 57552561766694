import * as React from "react"

const MissingDetails = (props) => (
  <svg
    width={20}
    height={21}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 14.875a.417.417 0 0 0 .417.417h1.508a.208.208 0 0 1 .208.191l.367 4.425a.416.416 0 0 0 .417.384H6.25a.417.417 0 0 0 .417-.384l.366-4.408a.209.209 0 0 1 .209-.192H8.75a.417.417 0 0 0 .417-.416v-2.917a4.583 4.583 0 1 0-9.167 0v2.9ZM4.583 6.542a2.917 2.917 0 1 0 0-5.834 2.917 2.917 0 0 0 0 5.834ZM15.65 10.5a1.209 1.209 0 0 0-2.133 0L9.3 18.533a1.192 1.192 0 0 0 1.067 1.742H18.8a1.191 1.191 0 0 0 1.067-1.742L15.65 10.5ZM14.583 13a.634.634 0 0 1 .625.625v1.667a.625.625 0 0 1-1.25 0v-1.667a.634.634 0 0 1 .625-.625Zm0 5.833a.833.833 0 1 1 0-1.666.833.833 0 0 1 0 1.666Z"
      fill="#fff"
    />
  </svg>
)

export default MissingDetails
