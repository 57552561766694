import { create } from 'zustand'

export const accountOverlayStore = create((set) => ({
  openAccount: false,
  openSubscription: false,
  openEditProfile: false,
  openChangePassword: false,
  setOpenAccount: (data) => set(() => ({ openAccount: data })),
  setOpenSubscription: (data) => set(() => ({ openSubscription: data })),
  setOpenEditProfile: (data) => set(() => ({ openEditProfile: data })),
  setChangePassword: (data) => set(() => ({ openChangePassword: data })),
  removeAll: () => set({
    openAccount: false,
    openSubscription: false,
    openEditProfile: false,
  })
}))