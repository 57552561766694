import React from "react";
import createStyle from "./styles";
import NewPasswordSuccessLogo from "../../../images/svg/NewPasswordSuccessLogo";
import { GreenBgButton } from "../../../components/buttons/greenBgButton";
import { useNavigate, useLocation } from "react-router-dom";
import Styles from "./resetPasswordSuccess.module.css";
import AppWalkthourgh from "../../components/appWalkthrough/appWalkthrough";
import Footer from "../../layout/Footer";

export default function ResetPasswordSuccess() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const styles = createStyle();
  const TextInfo = () => {
    const styles = createStyle();
    return (
      <>
        <div style={styles.textQuestion}>New Password set</div>
        <div style={styles.textSimple}>
          Your Password has been reset successfully
        </div>
      </>
    );
  };

  return (
    <div className={Styles.body}>
      <div className={Styles.bodyMain}>
        <NewPasswordSuccessLogo style={{ ...styles.logo }} />
        <TextInfo />
        <div style={styles.separator}>
          <GreenBgButton
            fullWidth
            variant="contained"
            onClick={() => {
              navigate(-4, { replace: true });
            }}
          >
            <div
              style={{
                fontFamily: "Proxima Nova",
                fontSize: 16,
                fontWeight: "600",
              }}
            >
              Go to Sign In
            </div>
          </GreenBgButton>
        </div>
      </div>
      <AppWalkthourgh />
      <div className={Styles.footer}>
        <Footer isLogin={true} isCenterCopyright={true} />
      </div>
    </div>
  );
}
