import { create } from 'zustand'

export const searchFormStore = create((set) => ({
  searchFormData: {
    country: 'United States'
  },
  searchResultData: null,
  selectedPotentialIndex: -1,
  savedSearchData: null,
  savedPotentialMatch: [],
  openEditSearch: false,
  editSearchData: {},
  editSearchComplete: null,
  selectedPotentialMatchData: null,
  isEditedSavedsearch: false,
  progressStatus: false,
  setPotentialMatch: (data) => set(() => ({ savedPotentialMatch: data})),
  setSearchFormData: (data) => set(() => ({ searchFormData: data})),
  setSearchData: (data) => set(() => ({ searchResultData: data})),
  setSelectedPotentialIndex: (data) => set(() => ({ selectedPotentialIndex: data})),
  setSavedSearchData: (data) => set(() => ({ savedSearchData: data})),
  setSelectedPotentialMatchData: (data) => set(() => ({ selectedPotentialMatchData: data})),
  setOpenEditSearch: (data) => set(() => ({ openEditSearch: data})),
  setEditSearchData: (data) => set(() => ({ editSearchData: data})),
  setEditSearchComplete: (data) => set(() => ({ editSearchComplete: data})),
  setEditedSavedsearch: (data) => set(() => ({ isEditedSavedsearch: data})),
  setProgressStatus: (data) => set(() => ({ progressStatus: data})),
  removeAll: () => set({ 
    searchFormData: {
      country: 'United States'
    },
    searchResultData: null,
    selectedPotentialIndex: -1,
    savedSearchData: null,
    savedPotentialMatch: [],
    openEditSearch: false,
    editSearchData: {},
    editSearchComplete: null,
    selectedPotentialMatchData: null,
    fromSavedSearch: false,
    progressStatus: false,
  }),
}))