import { Checkbox } from "@mui/material";
import Styles from './preferences.module.css'

const Preferences3 = ({ personalReasonsSurvey, setPersonalReasonsSurvey }) => {
  const data = [
    {
      label: "Career Vetting",
    },
    {
      label: "Community Tracking",
    },
    {
      label: "Contractors/Service Providers",
    },
    {
      label: "Dating",
    },
    {
      label: "Media Monitoring",
    },
    {
      label: "News Tracking",
    },
    {
      label: "Personal Network",
    },
    {
      label: "Social",
    },
    {
      label: "Other",
    },
  ];

  const CheckBoxView = ({
    label,
    personalReasonsSurvey,
    setPersonalReasonsSurvey,
  }) => {
    return (
      <div
        style={{ flexDirection: "row", alignItems: "center", display: "flex" }}
      >
        <Checkbox
          disabled={false}
          checked={personalReasonsSurvey.includes(label)}
          color="success"
          sx={{
            color: "#078167",
            "&.Mui-checked": {
              color: "#078167",
            },
            paddingLeft: 0,
          }}
          onChange={() => {
            if (personalReasonsSurvey.includes(label)) {
              let temp = [...personalReasonsSurvey];
              temp = temp.filter((t) => t !== label);
              setPersonalReasonsSurvey(temp);
            } else setPersonalReasonsSurvey((prev) => [...prev, label]);
          }}
        />
        <span>{label}</span>
      </div>
    );
  };

  return (
    <div className={Styles.pref2Main}>
      <div className={`${Styles.preferencesQtn} ${Styles.preferencesQtn2}`}>Personal Use</div>
      <div className={Styles.pref2radioBtn}>
        {data.map(({ label }) => (
          <CheckBoxView
            label={label}
            key={label}
            personalReasonsSurvey={personalReasonsSurvey}
            setPersonalReasonsSurvey={setPersonalReasonsSurvey}
          />
        ))}
      </div>
    </div>
  );
};

export default Preferences3;
