import React from 'react';
import { xml } from '../../images/svg/terms-conditions';
import { GreenBgButton } from '../buttons/greenBgButton';
import { Buffer } from "buffer"
import { Modal } from '@mui/material';
import Slide from '@mui/material/Slide';
import starredContacts from '../../images/starred-contacts-Illustration.png'
import CloseIconWhite from '../../images/svg/closeWhite';
import Styles from './starredContact.module.css';

const StarredContactModal = (props) => {
  const {
    onContinuePress,
  } = props;

  const buff = Buffer(xml);

  return (
    <Modal
      open={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Slide in={true} direction="up">
          <div className={Styles.container}>
            <div onClick={onContinuePress}><CloseIconWhite color='#697080' style={{ width: 14, height: 14, position: 'absolute', marginTop: 20, marginLeft: 20,}} /></div>
            <span className={Styles.headerTitle}>{'Contact Notifications'}</span>
            <div className={Styles.content}>
              <img src={starredContacts} className={Styles.mainIcon} />
              <span className={Styles.textQuestion}>{'Select Contacts To Automatically Check for Updates'}</span>
              <span className={Styles.textSimple}>{'Put a star next to the name of the contacts with '}
              <span className={Styles.textSimpleGreen}>{'whom you wish to be notified about, in real-time'}</span>
              {', when there are updates.'}</span>
              <div className={Styles.button}>
                 <GreenBgButton
                  loading={false}
                  onClick={onContinuePress}
                  fullWidth
                  variant="contained"
                  >
                    <div className={Styles.btnTxt}>Continue</div>
                </GreenBgButton>
              </div>
            </div>
          </div>
      </Slide>
    </Modal>
  );
};

export default StarredContactModal;