// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".locadingBar_loadingbar__kveHu {\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    align-items: center;\n    height: 70px;\n  }", "",{"version":3,"sources":["webpack://./src/web/components/loadingBar/locadingBar.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,sBAAsB;IACtB,mBAAmB;IACnB,YAAY;EACd","sourcesContent":[".loadingbar {\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    align-items: center;\n    height: 70px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingbar": "locadingBar_loadingbar__kveHu"
};
export default ___CSS_LOADER_EXPORT___;
