import React, { useContext } from 'react';
import createStyle from './styles';
import NewPasswordSuccessLogo from '../../images/svg/NewPasswordSuccessLogo';
import { saveUserDataAndRedirectToInitialScreen } from '../../root-navigation';
import { GreenBgButton } from '../buttons/greenBgButton';
import { useNavigate, useLocation } from "react-router-dom";
import { logout } from '../../utils/auth';
import { OnboardingContext, getOnboardingInitialStatus } from '../../contexts/onboardingContext';

export default function ResetPasswordSuccess({ goToDashboard, handleCloseClick }) {
  const navigate = useNavigate()
  const { state } = useLocation()
  const user = state?.user
  const openDashboard = state?.openDashboard
  const { setOnboardingState } = useContext(OnboardingContext);
  const styles = createStyle();
  const TextInfo = () => {
    const styles = createStyle();
    return (
      <>
        <div style={styles.textQuestion}>New Password set</div>
        <div style={styles.textSimple}>
          Your Password has been reset successfully
        </div>
      </>
    );
  };

  return (
    <>
      <div
        style={{
          ...styles.container,
          marginTop: styles.container.marginTop,
        }}>
        <NewPasswordSuccessLogo style={{ ...styles.logo }} />
        <TextInfo />
        <div style={styles.separator}>
          <GreenBgButton
            fullWidth
            variant="contained"
            onClick={() => {
              if (openDashboard) {
                navigate('/dashboard', { replace: true })
              } else {
                navigate(-4, { replace: true })
              }
              // saveUserDataAndRedirectToInitialScreen(user, null);
            }}
          >
            <div style={{ fontFamily: "Proxima Nova", fontSize: 16, fontWeight: "600" }}>
              {openDashboard ? "Go to Dashboard" : "Go to Sign In"}
            </div>
          </GreenBgButton>
        </div>
      </div>
    </>
  );
}
