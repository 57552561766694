import React, { useContext, useEffect, useState } from 'react';

import RelevantModal from './relevantModal';
import Styles from './relevancyQuestion.module.css'
import { SAVE_ARTICLE_RELEVANCY } from '../../queries/queries';
import { useMutation } from 'react-apollo';
import { UserContext } from "../../contexts/userContext";
import Variables from '../../utils/Variables';
import { removeDuplicateTags } from '../../utils/utils';
let relevacyTags = [], removeRelevancyTags = [], relevacyTagsRequest, deleteRelevacyTagsRequest;
const RelevancyQuestion = props => {
  const { user } = useContext(UserContext);
  const {
    attributes,
    setAttributes,
    fullName,
    modalVisible,
    setModalVisible,
    backButton,
    articleId,
    contactId,
    recentSearchId,
    searchTerm,
    articleSource,
    refId,
    contactData,
    setShowLoader = () => { },
    setHeaderTags,
    headerTags,
    saveRelevancyData,
    setSaveRelevancyData,
    deleteRelevancyData,
    setDeleteRelevancyData,
    setRefreshList,
    goBack,
    potentialMatchId
  } = props;
  const [isNoPress, setNoPress] = useState(false)

  useEffect(()=>{
    setNoPress(false)
  },[])

  const [saveArticleRelevancy, { loading }] = useMutation(SAVE_ARTICLE_RELEVANCY, {
    onCompleted: data => {
      if (contactId == undefined || contactId != data?.saveArticleRelevancy?.contactId) {
        Variables.savedSearchId = data?.saveArticleRelevancy?.contactId
      }
      Variables.isAddSearches = true
      Variables.isRefreshClosestContacts = true
      Variables.savedRelevancyContactId = data?.saveArticleRelevancy?.contactId
      if (relevacyTags.length) {
        setSaveRelevancyData(relevacyTagsRequest)
      }
      if (removeRelevancyTags.length) {
        setDeleteRelevancyData(deleteRelevacyTagsRequest)
      }
      setRefreshList(true)
      setShowLoader(false)
      onBackdropPressHandler(true);
    },
    onError: error => {
      console.log(error);
      setShowLoader(false)
      onBackdropPressHandler(true);
    }
  });

  const parseAttributes = (attr) => {
    let headerTagStrings = []
    let isObject = false
    if (headerTags) {
      headerTagStrings = [...headerTags].map(item => {
        if (typeof (item) == 'string') {
          isObject = false
          return item?.toLowerCase()
        } else {
          isObject = true
          return item.param?.toLowerCase()
        }
      })
    }

    let relevanceInfo = {};
    let relevanceAttributes = [];
    attr?.map(item => {
      let arr = ''
      let others = false;
      let know = false

      if (item.groupName?.toLowerCase() == 'Organizations'.toLowerCase() || item.groupName == 'Places') {
        item?.attributes?.map((child) => {
          if (child.status == true && !headerTagStrings.includes(child.label?.toLowerCase())) {
            if (child.type && child.type === 'textbox') {
              others = child.value;
            } else if (child.type && child.type === 'dont') {
              know = true;
            } else {
              arr = arr.length ? arr + ';' + child.label : child.label
            }
          }
        });
      }

      if (item.groupName?.toLowerCase() === 'Organizations'.toLowerCase() && others) {
        relevanceInfo.companyOthers = others;
      } else if (item.groupName === 'Places' && others) {
        relevanceInfo.placesOthers = others;
      }

      if (item.groupName?.toLowerCase() === 'Organizations'.toLowerCase()) {
        relevanceInfo.companyKnow = know;
      } else if (item.groupName === 'Places') {
        relevanceInfo.placesKnow = know;
      }

      if (arr.length > 0) relevanceAttributes = [...relevanceAttributes, { key: item.groupName, value: arr }]
    })

    relevanceInfo.relevanceAttributes = relevanceAttributes;

    return relevanceInfo;
  }


  const checkRelevancy = (attr) => {
    let obj = attr.find(a => a.groupName === 'People')
    if (obj) {
      let r = obj.attributes.find(o => o.status == true);
      if (r?.label == 'Not Sure') return 'MAYBE'
      else if (r?.label == 'No') return 'NO'
      else if (r?.label == 'Yes') return 'YES'
      else return undefined
    }
  }

  const onBackdropPressHandler = (isSaved = false) => {
    setModalVisible({
      ...modalVisible,
      postArticleRelevant: false,
    });
    backButton(isSaved)
    // if(isNoPress)
    goBack?.()
  }

  const onClose = () =>{
    setModalVisible({
      ...modalVisible,
      postArticleRelevant: false,
    });
  }

  const applySearchHEader = (attributes) => {
    if (headerTags == null || headerTags == undefined) {
      return
    }
    let isObject = false
    let headerTagStrings = [...headerTags].map(item => {
      if (typeof (item) == 'string') {
        isObject = false
        return item?.toLowerCase()
      } else {
        isObject = true
        return item.param?.toLowerCase()
      }
    })
    relevacyTags = []
    removeRelevancyTags = []
    relevacyTagsRequest = { Places: saveRelevancyData?.Places, Organizations: saveRelevancyData?.Organizations }
    deleteRelevacyTagsRequest = { Places: deleteRelevancyData?.Places, Organizations: deleteRelevancyData?.Organizations }
    attributes?.forEach(item => item?.attributes?.forEach(lableItem => {
      if (lableItem.status
        && (lableItem.type == 'radio_button' || (lableItem.type == 'textbox' && lableItem?.value?.length))
        && !headerTagStrings.includes(lableItem.label?.toLowerCase())) {
        relevacyTagsRequest[item.groupName] = relevacyTagsRequest[item.groupName] ? [...relevacyTagsRequest[item.groupName], (lableItem.type == 'radio_button' ? lableItem.label : lableItem.value)] : [(lableItem.type == 'radio_button' ? lableItem.label : lableItem.value)]
        relevacyTags.push(isObject ? {
          "param": lableItem.type == 'radio_button' ? lableItem.label : lableItem.value,
          "relevacyTags": true
        } : lableItem.type == 'radio_button' ? lableItem.label : lableItem.value)
      }

      if (lableItem.removeHeader
        && (lableItem.type == 'radio_button' || (lableItem.type == 'textbox' && lableItem?.value?.length))) {
        deleteRelevacyTagsRequest[item.groupName] = deleteRelevacyTagsRequest[item.groupName] ? [...deleteRelevacyTagsRequest[item.groupName], (lableItem.type == 'radio_button' ? lableItem.label : lableItem.value)] : [(lableItem.type == 'radio_button' ? lableItem.label : lableItem.value)]
        removeRelevancyTags.push(lableItem.type == 'radio_button' ? lableItem.label?.toLowerCase() : lableItem.value?.toLowerCase())
      }
    }))

    if (relevacyTags.length || removeRelevancyTags.length) {
      let tags = removeDuplicateTags([...headerTags, ...relevacyTags]).filter(item => {
        if (typeof (item) == 'string') {
          return !removeRelevancyTags.includes(item?.toLowerCase())
        } else {
          return !removeRelevancyTags.includes(item?.param?.toLowerCase())
        }
      })
      setHeaderTags(tags)
    }
  }

  const onDonePressHandler = (attributes) => {
    setAttributes(attributes);
    let r = parseAttributes(attributes);
    let variables = {
      relevanceInfo: r,
      articleId,
      articleSource,
      searchTerm
    };

    if (contactId) variables.contactId = contactId;

    if ((contactId == undefined || contactId == "") && Variables.contactId) {
      variables.contactId = Variables.contactId
    }

    if (recentSearchId) variables.recentSearchId = recentSearchId;
    if (refId != articleId) variables.refId = refId;

    let isRelevanthasValue = checkRelevancy(attributes);
    if (isRelevanthasValue) variables.isRelevant = isRelevanthasValue;
    if (isRelevanthasValue && isRelevanthasValue === 'NO') {
      Variables.articleIdMarkNo = refId;
      setNoPress(true)
    }
    if (contactData) {
      let contactDataRequest = { ...contactData }
      // delete contactDataRequest['searchContext']
      variables.contactData = contactDataRequest
    }
    // if (Variables.potentialMatchId) {
    //   variables.potentialMatchId = Variables.potentialMatchId
    // }
    variables.potentialMatchId = potentialMatchId

    setShowLoader(true)
    saveArticleRelevancy({
      variables,
    });
    applySearchHEader(attributes)
    setModalVisible({
      ...modalVisible,
      postArticleRelevant: false,
    });
  };

  return (
    <div className={Styles.relevancyContainer}>
      <RelevantModal
        isVisible={modalVisible.postArticleRelevant}
        onBackdropPress={onBackdropPressHandler}
        onClose={onClose}
        onDonePress={onDonePressHandler}
        initialAttributes={attributes}
        fullName={fullName}
        setHeaderTags={setHeaderTags}
        headerTags={headerTags}
      />
    </div>
  );
};

export default RelevancyQuestion;
