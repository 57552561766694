// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cardItem_gridCard__ug0mW {\n}\n.cardItem_filterLable__pweE\\+ {\n  align-self: center;\n  margin-top: 8px;\n  font-family: \"Proxima Nova\";\n  font-style: normal;\n  font-weight: 700;\n  font-size: 14px;\n  text-align: center;\n  text-transform: capitalize;\n  color: #142030;\n  display: flex;\n  justify-content: center;\n  max-width: 90px;\n  white-space: break-spaces;\n  flex-direction: column;\n}\n.cardItem_text__4JYVH {\n  width: -webkit-fill-available\n}\n", "",{"version":3,"sources":["webpack://./src/web/components/PersonInfo/cardItem.module.css"],"names":[],"mappings":"AAAA;AACA;AACA;EACE,kBAAkB;EAClB,eAAe;EAEf,2BAA2B;EAC3B,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,kBAAkB;EAClB,0BAA0B;EAC1B,cAAc;EACd,aAAa;EACb,uBAAuB;EACvB,eAAe;EACf,yBAAyB;EACzB,sBAAsB;AACxB;AACA;EACE;AACF","sourcesContent":[".gridCard {\n}\n.filterLable {\n  align-self: center;\n  margin-top: 8px;\n  text-align: center;\n  font-family: \"Proxima Nova\";\n  font-style: normal;\n  font-weight: 700;\n  font-size: 14px;\n  text-align: center;\n  text-transform: capitalize;\n  color: #142030;\n  display: flex;\n  justify-content: center;\n  max-width: 90px;\n  white-space: break-spaces;\n  flex-direction: column;\n}\n.text {\n  width: -webkit-fill-available\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gridCard": "cardItem_gridCard__ug0mW",
	"filterLable": "cardItem_filterLable__pweE+",
	"text": "cardItem_text__4JYVH"
};
export default ___CSS_LOADER_EXPORT___;
