import * as React from "react"
// import Svg, {G, Path } from "react-native-svg"

const CloseCloset = (props) => {
  return (
    <svg
      height={20}
      viewBox="0 0 32 32"
      width={20}
      {...props}
    >
    <g fill={'#FFF'} fillRule="evenodd">
      <path d="M28 14H18V4a2 2 0 00-4 0v10H4a2 2 0 000 4h10v10a2 2 0 004 0V18h10a2 2 0 000-4z" />
    </g>
    </svg>
  )
}

export default CloseCloset