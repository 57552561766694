import * as React from "react"
const PapersLeaks = (props) => {
    return (
        <svg
        width={32}
        height={32}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#a)">
          <rect
            x={6.667}
            y={9}
            width={9.334}
            height={2.667}
            rx={1.333}
            fill={props.selected ? "#142030" :  "#b5b9c4"}
          />
          <rect
            x={6.667}
            y={13.667}
            width={9.334}
            height={2.667}
            rx={1.333}
            fill={props.selected ? "#142030" :  "#b5b9c4"}
          />
          <rect
            x={6.667}
            y={18.333}
            width={9.334}
            height={2.667}
            rx={1.333}
            fill={props.selected ?  "#142030" :  "#b5b9c4"}
          />
          <path
            d="M31.613 25.253a10.254 10.254 0 0 0-8.106-4.4 10.255 10.255 0 0 0-8.107 4.4 2 2 0 0 0 0 2.347 10.28 10.28 0 0 0 8.107 4.4 10.253 10.253 0 0 0 8.106-4.4 1.96 1.96 0 0 0 0-2.347ZM19.4 27.92a8.707 8.707 0 0 1-1.133-1.027.667.667 0 0 1 0-.906 7.092 7.092 0 0 1 5.427-2.44.32.32 0 0 1 .293.2.32.32 0 0 1 0 .36l-3.64 3.746a.667.667 0 0 1-.947.054v.013Zm9.334-1.027a7.068 7.068 0 0 1-5.4 2.44.333.333 0 0 1-.307-.2.36.36 0 0 1 .08-.36l3.627-3.746a.666.666 0 0 1 .88-.067 8.88 8.88 0 0 1 1.146 1.027.666.666 0 0 1 0 .893l-.027.013Z"
            fill={props.selected ? props.iconType =="blackWhite"? "#1E1E1E": "#DA3528" : '#b5b9c4'}
          />
          <path
            d="M19.613 6.827a1.332 1.332 0 0 1 .387.946V18a1.333 1.333 0 0 0 2.667 0V7.213a2.667 2.667 0 0 0-.787-1.88L17.333.787A2.667 2.667 0 0 0 15.453 0H2.667A2.667 2.667 0 0 0 0 2.667v24a2.667 2.667 0 0 0 2.667 2.666H12a1.333 1.333 0 1 0 0-2.666H3.333A.667.667 0 0 1 2.667 26V3.333a.667.667 0 0 1 .666-.666h11.56a1.333 1.333 0 0 1 .947.386l3.773 3.774Z"
            fill={props.selected ?  "#142030" :  "#b5b9c4"}
          />
        </g>
        <defs>
          <clip-path id="a">
            <path fill="#fff" d="M0 0h32v32H0z" />
          </clip-path>
        </defs>
      </svg>
)}

export default PapersLeaks
