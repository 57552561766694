import * as React from "react"
const CourtFillings = (props) => {
  return (
    <svg
      width={24}
      height={32}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill={props.selected ? props.iconType =="blackWhite"? "#1E1E1E": "#FB8100" : props.iconType == 'white' ? '#fff' :  '#b5b9c4'}
        d="M5.115 15.333H8.96a.648.648 0 00.59-.38 3.997 3.997 0 00.357-1.65.303.303 0 00-.23-.294L7.607 6.844a14.342 14.342 0 013.33-.57v9.729h-.914a.686.686 0 000 1.372h3.2a.686.686 0 000-1.372h-.913V6.274a14.34 14.34 0 013.33.57l-2.072 6.165a.303.303 0 00-.229.293c0 .57.122 1.132.357 1.65.105.232.335.381.59.381h3.845a.647.647 0 00.59-.38 3.997 3.997 0 00.357-1.65.303.303 0 00-.23-.294L16.63 6.41a.446.446 0 00-.284-.282 14.977 14.977 0 00-9.446 0 .446.446 0 00-.285.282l-2.218 6.6a.303.303 0 00-.229.292c0 .57.122 1.132.357 1.65.105.232.335.381.59.381zm12.789-2.334h-3.392l1.696-5.047 1.696 5.047zM7.038 7.952l1.696 5.047H5.342l1.696-5.047z"
      ></path>
      <path
        fill={props.selected ? "#000" : props.iconType == 'white' ? '#fff' :  '#b5b9c4'}
        d="M4.115 18.747h15.012v1.715H4.115v-1.715zM4.115 21.834h15.012v1.715H4.115v-1.715zM4.115 24.921h15.012v1.715H4.115v-1.715z"
      ></path>
      <path
        fill={props.selected ? "#000" : props.iconType == 'white' ? '#fff' :  '#b5b9c4'}
        d="M21.871 0H1.372A1.372 1.372 0 000 1.372v29.256A1.372 1.372 0 001.372 32h20.5a1.372 1.372 0 001.371-1.372V1.372A1.372 1.372 0 0021.871 0zM20.5 29.255H2.744V2.744h17.755v26.511z"
      ></path>
    </svg>
  );
}

export default CourtFillings

