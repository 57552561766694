import { useMemo, useState } from 'react'
import { TextField, IconButton, InputAdornment, LinearProgress, capitalize } from "@mui/material";
import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import Styles from './passwordField.module.css';
import Eye from '../../images/svg/eye';
import EyeClosed from '../../images/svg/eye-closed';

export const PasswordField = ({
  password,
  setPassword,
  checkFormValid,
  passwordValid,
  setPasswordValid,
  placeholder,
  showPasswordStrength = false,
  title = "Password",
  disabled,
  isIcloud = false,
  screenName,
  showPasswordIcon
}) => {
  const [showPassword, setShowPassword] = useState();

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const [validate, setValidate] = useState({
    hasLow: false,
    hasCap: false,
    hasNumber: false,
    hasSpecialChar: false,
    has8digit: false
  });

  const passStrength = Object.values(validate).reduce((a, item) => a + item, 0);
  const passLabels = {
    0: 'Weak Password', 1: 'Weak Password', 2: 'Fair Password', 3: 'Fair Password', 4: 'Strong Password'
  }[passStrength];

  const validatePassword = (password) => {
    if (password.match(/\d+/g)) {
      setValidate((o) => ({ ...o, hasNumber: true }));
    } else {
      setValidate((o) => ({ ...o, hasNumber: false }));
    }

    if (password.match(/[A-Z]+/g)) {
      setValidate((o) => ({ ...o, hasCap: true }));
    } else {
      setValidate((o) => ({ ...o, hasCap: false }));
    }

    if (password.match(/[a-z]+/g)) {
      setValidate((o) => ({ ...o, hasLow: true }));
    } else {
      setValidate((o) => ({ ...o, hasLow: false }));
    }

    if (password.match(/[?=.*?[#?!@$%^&*-]]+/g)) {
      setValidate((o) => ({ ...o, hasSpecialChar: true }));
    } else {
      setValidate((o) => ({ ...o, hasSpecialChar: false }));
    }

    if (password.length > 7) {
      setValidate((o) => ({ ...o, has8digit: true }));
    } else {
      setValidate((o) => ({ ...o, has8digit: false }));
    }
  };

  const passwordValidation = (value) => {
    let error = "";
    if (screenName == "Change Password") {
      if (value == undefined || value.length == 0) {
        error = capitalize(title.toLowerCase()) + " is required"
      }
      else if (title == "Confirm New Password") {
        return
      }
        // else if (value.length < 8) {
        //   error = "  "
        // }
      else if (!/[A-Z]/.test(value)) {
        error =
          'Your password needs to contain at least one upper case character';
      }
      else if (!/[0-9]/.test(value)) {
        error = 'Your password needs to contain at least one digit';
      }
      else if (!/[●!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/.test(value)) {
        error = 'Your password needs to contain at least one special character';
      }
      else if (/(.)\1\1/.test(value)) {
        error =
          'Your password cannot contain more than three sequential characters';
      }
    } else {
      if (value == undefined || value.length == 0) {
        error = "Password is required"
      }
      else if (isIcloud) {
        error = ''
      }
      else if (/[^\x21-\x7E]+/.test(value)) {
        error = 'Your password cannot contain any invisible characters';
      } else if (!/[A-Z]/.test(value)) {
        error =
          'Your password needs to contain at least one upper case character';
      } else if (!/[a-z]/.test(value)) {
        error =
          'Your password needs to contain at least one lower case character';
      } else if (!/[0-9]/.test(value)) {
        error = 'Your password needs to contain at least one digit';
      } else if (!/[●!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/.test(value)) {
        error = 'Your password needs to contain at least one special character';
      } else if (/(.)\1\1/.test(value)) {
        error =
          'Your password cannot contain more than three sequential characters';
      } else if (!/^.{8,32}$/.test(value)) {
        error = 'Your password needs to be no longer than 32 characters long';
      } 
    }

    if (error.length > 0) {
      setPasswordValid({ status: false, msg: error })
      return false
    } else {
      setPasswordValid({ status: true, msg: "" })
      return true
    }

  };

  useMemo(() => {
    if ((passwordValid.status !== undefined) || (password?.length === 0)) {
      passwordValidation(password)
    }
  }, [password])

  return (
    <>
      <div className={Styles.viewApple}>
        {isIcloud ? (
          <div className={`${Styles.appleId} ${Styles.fieldLabel}`}>
            {title}
          </div>
        ) : (
          <div className={Styles.fieldLabel}>{title}</div>
        )}
        {showPasswordStrength && passStrength > 0 ? (
          <span className={Styles.fieldLabel}>{passLabels}</span>
        ) : null}
      </div>

      <TextField
        disabled={disabled}
        value={password}
        fullWidth
        variant="outlined"
        placeholder={
          placeholder ? placeholder : "At Least 8 Characters; Mixed Cases"
        }
        className="fieldInput"
        type={showPassword ? "text" : "password"}
        InputProps={{
          sx: {
            color: "#142030",
            height: 48,
            marginTop: 1,
            backgroundColor: "#FFFFFF",
            border:
              screenName == "Change Password" && passwordValid.status === true
                ? "1px solid #078167"
                : "1px solid #ced4da",
            "& ::placeholder": {
              color: "#8E96A8",
            },
          },
          endAdornment: showPasswordIcon && (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
              >
                {showPassword ? (
                  <EyeClosed className="visibilityIcon" />
                ) : (
                  <Eye className="visibilityIcon" />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
        FormHelperTextProps={{
          style: {
            backgroundColor: "transparent",
            padding: 0,
            margin: 0,
            marginTop: 4,
          },
        }}
        error={passwordValid.status === false && checkFormValid}
        color={
          screenName == "Change Password" && passwordValid.status === true
            ? "success"
            : null
        }
        helperText={checkFormValid && passwordValid.msg}
        onChange={(e) => {
          setPassword(e.target.value);
          if (showPasswordStrength) {
            validatePassword(e.target.value);
          }
        }}
      />

      {showPasswordStrength && passStrength > 0 ? (
        <>
          <span className={Styles.passMeterLbl}>
            Password must be at least 8 characters, include a capital letter and
            a symbol.
          </span>
          <div className={Styles.passMeter}>
            <LinearProgress
              variant="determinate"
              hidden={password.length === 0}
              value={passStrength * 25}
              sx={{
                "& .MuiLinearProgress-bar1Determinate": {
                  backgroundColor:
                    passStrength == 1
                      ? "#FF2A3D"
                      : passStrength == 2
                      ? "#EFB21C"
                      : "#0F9125",
                },
              }}
            />
          </div>
        </>
      ) : null}
    </>
  );
}
