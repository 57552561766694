import { flatMap, stubFalse } from 'lodash';
import React from 'react';
import SearchResults from '../searchResults/searchResults';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const SearchResultsWithQuery = props => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!location || !location.state) {
      navigate("/search", { replace: true });
    }
  }, [location, navigate]);

  const {
    inNewSearch,
    articles = "",
    searchSettings,
    irrelevantArticleId,
    irrelevantArticles,
    metadata,
    searchTerm,
    searchType,
    searchMe = false,
    articleFilterData = [],
    articleTypeCount = {},
    savedContactID,
    mPhoneNumberInput,
    mCountryInput,
    mCityInput,
    mStateInput,
    mAgeInput,
    queryData,
    savedTracerQueryId,
    savedTahoeId,
    mOldMetaData,
    navigatedFrom,
    personalInfo,
    aKAList,
    fromEditSearch,
    categoryFilterData,
    clickedPotential,
    onBackOpenModal,
    articleSource,
    lastQuery,
    scrollPosition = 0,
    searchIds,
    linkedInData,
    potentialMatchId,
    feedbackOptions,
    profilePic,
    searchList,
    articleDetail,
  } = location.state || {};

  for (let i = 0; i < articles?.length; i++) {
    for (let j = 0; j < irrelevantArticles?.length; j++) {
      if (articles[i].articleId == irrelevantArticles[j]) {
        articles.splice(i, 1)
      }
    }
  }
  const feed = {
    inNewSearch,
    fullName: searchSettings?.intialSearchString,
    irrelevantArticleId,
    irrelevantArticles,
    articles,
    metadata,
    searchSettings,
    searchTerm,
    searchType,
    searchMe,
    articleFilterData,
    personalInfo,
    articleTypeCount,
    savedContactID,
    mPhoneNumberInput,
    mCountryInput,
    mCityInput,
    mStateInput,
    mAgeInput,
    queryData,
    savedTracerQueryId,
    savedTahoeId,
    mOldMetaData,
    navigatedFrom,
    aKAList,
    fromEditSearch,
    categoryFilterData,
    clickedPotential,
    onBackOpenModal,
    articleSource,
    lastQuery,
    scrollPosition,
    searchIds,
    linkedInData,
    potentialMatchId,
    feedbackOptions,
    profilePic,
    searchList,
    articleDetail,
  };

  return (!location || !location.state) ? <></> : <SearchResults feed={feed} />;
};

export default SearchResultsWithQuery;
