import Variables from "./Variables";

const savedSearchData = (saveSearchdata, initialSearchString,
  recentSearchId, articleTypeCount,
  savedContactID, savedTracerQueryId,
  savedTahoeId, queryData, aKAList
  ) => {

    if(!saveSearchdata)
      return undefined

    let {
      Company = '',
      Location = '',
      Nationality = '',
      Person = '',
    } = saveSearchdata;
  
    let name = Person && Person !== '' ? Person : initialSearchString;
    let mPlace = '';
    let {Place = ''} = saveSearchdata
    if(queryData && queryData != ''){
      if(queryData?.city && queryData?.city != '')
        mPlace = queryData?.city
      if(queryData?.state && queryData?.state != '')
        mPlace = mPlace != '' ? mPlace+', '+queryData?.state : queryData?.state
      if(queryData?.country && queryData?.country != '')
        mPlace = mPlace != '' ? mPlace+', '+queryData?.country : queryData?.country 
    }

    if(!Place.includes(mPlace))
      Place = Place == '' ? mPlace : Place+';'+mPlace

    if(Place != '' && Place == mPlace){
      Place = ''
      Nationality = Place
    }
  
    let variables = {data: {
      "name": (name.split(' ').length === 1 ? name : name.substr(0,name.indexOf(' '))).toLowerCase(),
      "lastName": (name.split(' ').length > 1 ? name.substr(name.indexOf(' ')+1) : '').toLowerCase(),
      "company": Company?.toLowerCase(),
      "city": Place?.toLowerCase(),
      "region": Location?.toLowerCase(),
      "country": Nationality?.toLowerCase(),
      "searchType": Person && Person !== '' ? 'PERSON' : Company?.toLowerCase() === initialSearchString?.toLowerCase() ? 'COMPANY' : Place?.toLowerCase() === initialSearchString?.toLowerCase() ? 'PLACE' : 'PERSON'
    }}
    if(recentSearchId) variables.data.recentSearchId = recentSearchId;

    variables.data.riskCategory =
      articleTypeCount ? (articleTypeCount?.highRisk > 0 ? "HIGH" : 
        (articleTypeCount?.alerts > 0 ? "MEDIUM" : "LOW")) :  "LOW"

    if(savedContactID && savedContactID != ''){
      variables.data.id = savedContactID
    } else if (Variables.contactId) {
      variables.data.id = Variables.contactId
    }
  
    if(savedTracerQueryId && savedTracerQueryId != ''){
      variables.data.tracerQueryId = savedTracerQueryId
    }
  
    if(savedTahoeId && savedTahoeId != ''){
      variables.data.tahoeId = savedTahoeId
    }

    if(queryData?.city)
      queryData.city = queryData.city.toLowerCase()

      if(queryData && queryData != ''){
        let newQueryData = {...queryData}
        delete newQueryData['stateAbbr']
        delete newQueryData['countryAbbr']
        delete newQueryData['originalPhone']
        variables.data.searchContext = newQueryData
      }
    
    if(aKAList && aKAList.length > 0){
      variables.data.akaList = aKAList.map(item=> item.param)
    }
    return variables
  }
  export default savedSearchData