// React
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

// Images
import FingerprintBG from "../images/backgrounds/layout-fingerprint.svg";

// Styles
import "../styles/Global.scss";

// Layout
import MainNavigation from "./MainNavigation";
import Footer from "./Footer";

// Components
import SearchOverlay from "../components/search/SearchOverlay";
import SearchForm from "../components/search/SearchForm";
import AccountOverlay from "../components/account/AccountOverlay";
import Styles from "./layout.module.css";
import { searchFormStore } from "../../store/searchFormStore";
import { accountOverlayStore } from "../../store/accountOverlayStore";
import NotificationOverlay from "../components/notifications/NotificationOverlay";
import { notificationStore } from "../../store/notificationStore";
import { useNavigate } from "react-router-dom";

/**
 * Layout Component
 * @constructor
 */
function Layout({ children, isDesktop }) {
  const openSubscription = accountOverlayStore(
    (state) => state.openSubscription
  );
  const navigate = useNavigate();

  const openAccount = accountOverlayStore((state) => state.openAccount);
  const setOpenAccount = accountOverlayStore((state) => state.setOpenAccount);

  // State management for search overlay
  const [isSearchOverlayVisible, setIsSearchOverlayVisible] = useState(false);

  const openNotification = notificationStore((state) => state.openNotification);
  const setOpenNotification = notificationStore(
    (state) => state.setOpenNotification
  );

  // Gets router current location
  const LOCATION = useLocation();

  // Flags if the currently displayed page is the dashboard
  const IS_DASHBOARD =
    LOCATION.pathname === "/dashboard" ||
    LOCATION.pathname === "/search" ||
    LOCATION.pathname === "/notification";
  const IS_Import_Contacts_Landing =
    LOCATION.pathname === "/importContactsLanding" ||
    LOCATION.pathname === "/importedContacts" ||
    LOCATION.pathname === "/importClosestContacts";

  const removeAllSearchFormData = searchFormStore((state) => state.removeAll);

  const openEditSearch = searchFormStore((state) => state.openEditSearch);
  /**
   * Renders the main content along with the search component
   * @returns {JSX.Element}
   */
  const renderContentWithSearchComponent = () => {
    return (
      <>
        <aside className=" bg-gray-light">
          <div className={Styles.bodySearch}>
            <SearchForm />
          </div>
        </aside>

        <div className="flex flex-col h-full w-full">
          <div className="grow pb-0">
            <div className="h-full">{children}</div>
          </div>
        </div>

        <div className={Styles.footerMain}>
          <Footer isCenterCopyright={true} />
        </div>
      </>
    );
  };

  /**
   * Renders the main content with no search component
   * @returns {JSX.Element}
   */
  const renderContent = () => {
    return (
      <div className="h-full flex flex-col overflow-y-auto">
        <div className="grow pb-0">
          <div className="h-full">{children}</div>
        </div>
        <div className={Styles.footerMain}>
          <Footer isSearchOverlayVisible={true} isCenterCopyright={true} />
        </div>
      </div>
    );
  };

  /**
   * Toggles visibility for the search overlay
   */
  const toggleSearchOverlay = () => {
    removeAllSearchFormData();
    setOpenAccount(false); // If the account overlay is visible we need to close it.
    setOpenNotification(false);
    // If the current location is /dashboard then force hide the overlay
    setIsSearchOverlayVisible(IS_DASHBOARD ? false : !isSearchOverlayVisible);
  };

  /**
   * Toggles visibility for the search overlay
   */
  const toggleNotificationOverlay = () => {
    setOpenAccount(false); // If the account overlay is visible we need to close it.
    setIsSearchOverlayVisible(false);
    setOpenNotification(!openNotification);
  };

  useEffect(() => {
    setIsSearchOverlayVisible(IS_DASHBOARD ? false : isSearchOverlayVisible);
  }, [LOCATION.pathname]);

  useEffect(() => {
    if (openSubscription) {
      openAccountOverlay();
    }
  }, [openSubscription]);

  /**
   * Toggles visibility for the account overlay
   */
  const openAccountOverlay = () => {
    setIsSearchOverlayVisible(false); // If the search overlay is visible we need to close it.
    setOpenAccount(true);
    setOpenNotification(false);
  };

  useEffect(() => {
    if (
      LOCATION.pathname === "/account" ||
      LOCATION.pathname === "/editProfile"
    ) {
      openAccountOverlay();
    }
  }, []);

  useEffect(()=>{
    if(!openAccount && (LOCATION.pathname === "/account" || LOCATION.pathname === "/editProfile")){
      navigate('/dashboard', { replace: true })
    }
  },[openAccount])

  /**
   * Main render method
   */
  return (
    <div className="flex h-full overflow-hidden">
      <nav className="w-[94px] h-full pt-[30px] bg-blue-dark overflow-y-auto no-scrollbar">
        <MainNavigation
          isSearchOverlayVisible={isSearchOverlayVisible}
          setIsSearchOverlayVisible={setIsSearchOverlayVisible}
          searchClick={toggleSearchOverlay}
          notificationClick={toggleNotificationOverlay}
        />
      </nav>

      <main
        className={`w-full bg-right-top bg-fixed bg-no-repeat relative ${
          IS_DASHBOARD ? "flex" : "block"
        }`}
        style={{ backgroundImage: `url(${FingerprintBG})` }}
      >
        {IS_DASHBOARD ? renderContentWithSearchComponent() : renderContent()}
        {isSearchOverlayVisible && (
          <SearchOverlay
            setIsSearchOverlayVisible={setIsSearchOverlayVisible}
          />
        )}
        {openEditSearch && (
          <SearchOverlay
            setIsSearchOverlayVisible={setIsSearchOverlayVisible}
          />
        )}
        {openAccount && <AccountOverlay isDesktop={isDesktop} />}
        {openNotification && <NotificationOverlay isDesktop={isDesktop} />}
      </main>
    </div>
  );
}

// Component export
export default Layout;
