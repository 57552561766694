
const createStyle = () => {
  const titleTextColor = "#1A233B";
  const paragraphTextColor = '#222F4B';
  return {
    container: {
      display: "flex",
      flexDirection: "column",
      marginLeft: 16,
      marginRight: 16,
      flex: 1,
      marginTop: 40,
      
    },
    backgroundImage: {
      position: 'absolute',
    },
    logo: {
      display: "flex",
      resizeMode: 'center',
      alignSelf: 'center',
      marginTop: 15,
    },
    textQuestion: {
      color: titleTextColor,
      fontFamily: 'Proxima Nova',
      fontSize: 24,
      marginLeft: 10,
      marginRight: 10,
      fontWeight: 'bold',
      textAlign: 'center',
      marginBottom: 30,
    },
    textSimple: {
      marginLeft: 10,
      marginRight: 10,
      color: paragraphTextColor,
      fontFamily: 'Proxima Nova',
      fontSize: 16,
      textAlign: 'center',
    },
    separator: {
      marginTop: 35,
    },

    buttonHeight: {
      height: 40,
    },
    errorMessage: {
      marginTop: 15,
      color: "#FF2A3D",
    },
    inputHeight: {
      height: 40,
    },
  }
};

export default createStyle;
