const truncateText = (text, numOfChars) => {
  let truncatedText = text;
  if (text.length > numOfChars) {
    truncatedText = `${text.substring(0, numOfChars - 1)}...`;
  }

  return truncatedText;
};

export default truncateText;
