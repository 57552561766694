import { styled } from "@mui/material/styles";
import LoadingButton from '@mui/lab/LoadingButton';

export const GreenBgButton = styled(LoadingButton)((props) => ({
  fontFamily: 'Proxima Nova',
  fontWeight: 'normal',
  alignItems: "center",
  textAlign: "center",
  textTransform: 'none',
  color: "#FFFFFF",
  backgroundColor: "#078167",
  borderRadius: 6,
  padding: props?.padding ? props.padding : '15px',
  fontSize: '16px',
  lineHeight: '19px',
  '&:hover': {
    backgroundColor: '#078167',
    color: '#ffffff',
  }
}));
