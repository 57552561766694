import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { LinearProgress } from '@material-ui/core';

class ColoredLinearProgress extends Component {
  render() {
    const { classes } = this.props;
    return <div style={{
      flex: 1,
      paddingBottom: 30,
      display: 'flex',
      flexDirection: 'column',
      display: 'flex'}}>
        <div style={{marginTop: 70, height: 14, width: '85%', borderRadius: 7, alignSelf: 'center', justifyContent: 'center', backgroundColor: '#078167'}}>
          <LinearProgress {...this.props} classes={{colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary}}/>
        </div>
        <span style={
          {
            alignSelf: 'center',
            fontSize: 12,
            fontWeight: '600',
            fontFamily: "Proxima Nova",
            marginTop:4}}>Loading...</span>
      </div>;
  }
}

const styles = props => ({
  colorPrimary: {
    backgroundColor: '#aaa',
  },
  barColorPrimary: {
    backgroundColor: '#078167',
  },
});

export default  withStyles(styles)(ColoredLinearProgress);