import ProfilePicture from '../profilePicture/profilePicture';
import Styles from './dashboardHeader.module.css';

const DashboardHeader = ({ user, navigate }) => {

  return <div>

    <div className={ Styles.headerHolder }>
      <div className={ Styles.header }>
        <div className={ Styles.greeting }>Hello, {user.givenName }</div>
        <div className={ Styles.profilePicture }>
          <ProfilePicture profilePhoto={ user?.profilePhoto } onClick={ () => navigate('/account') } />
        </div>
      </div>
    </div>

    <div className={ Styles.headerHolderRoundedCorner }><div className={ Styles.headerHolderRoundedCornerInner }></div></div>

  </div>;
};

export default DashboardHeader;
