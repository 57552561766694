import React, { useEffect, useState } from 'react'
import ArticleCard from '../articleCard/articleCard'
import { useLazyQuery, useMutation } from 'react-apollo'
import { GET_ARTICLE_BY_ID, SAVE_ARTICLE_HISTORY } from '../../queries/queries'
import { importContactsSharedStyle } from './styles'
import createStyle from './styles'
import { mockArticles } from './mockValues'
import parseSaveHistory from './parseSaveHistoryData'
// import RelevancyQuestion from '../relevancyQuestion'
import { checkParbFunctionVal, filterAttributeResult } from '../../utils/utils';
import { Backdrop, CircularProgress } from '@mui/material'
import RelevancyQuestion from '../relevancyQuestion/relevancyQuestion'


const ArticleCardWithQuery = (props) => {
  const style = createStyle()
  const { newsItem, onPress, isRecent = false, isShowName = true, recentSearchId, contactDataID, articles,
    searchTerm, articleSource, articleCount, articleRelevacnyData, contactData, savedContactID,
    setRelevantArticle, setShowLoader, configData, configLoading, openFilter, dontShow,
    setHeaderTags, headerTags, setSaveRelevancyData, deleteRelevancyData, setDeleteRelevancyData, saveRelevancyData, filterAttributes, setRefreshList, feed , key, isMap, isWeb, height } = props;

  let displayPostArticleRelevanceBot = false
  if (configData && !configLoading) {
    displayPostArticleRelevanceBot = checkParbFunctionVal(configData, configLoading, newsItem, isRecent)
  }

  const [click, setClick] = useState(false)
  const [selectedDoc, setSelectedDoc] = useState()
  const [modalVisible, setModalVisible] = useState({
    preArticleRelevant: false,
    postArticleRelevant: false,
  });
  const [showRelevanceModal, setShowRelevancyModal] = useState(false);
  const [isZoomVisible, setZoomVisible] = useState(false);
  const [article, setArticle] = useState();
  const setAttributes = attributes =>
    setArticle({ ...newsItem?.article, attributes: attributes });
  const [getArticleById, { loading, error, data }] = useLazyQuery(GET_ARTICLE_BY_ID, {
    fetchPolicy: 'no-cache'
  })

  useEffect(() => {
    if (newsItem) {
      const attributesVal = filterAttributeResult(newsItem) ?? [];
      setArticle({
        ...newsItem,
        content: mockArticles,
        attributes: attributesVal,
      })
    }
  }, [newsItem])

  const searchSettings = props.searchSettings ?? {};

  const backButton = (isSaved = false) => {
    newsItem.displayPostArticleRelevanceBot = isSaved
    setShowRelevancyModal(!showRelevanceModal);
    setModalVisible({
      ...modalVisible,
      postArticleRelevant: false,
    });
  }

  const [saveArticleHistory] = useMutation(SAVE_ARTICLE_HISTORY, {
    onCompleted(data) {
    },
    onError(ee) {
    }
  })

  if (data && click) {
    setClick(false)
    onPress?.({ ...data.article, selectedDoc: selectedDoc })
  }
  if (error && click) {
    setClick(false)
    const mockedArticle = mockArticles[newsItem.id]
    if (mockedArticle) {
      onPress?.(mockedArticle)
    } else {
      onPress?.()
    }
  }
  const enableRelevanceModal = () => {
    setShowRelevancyModal(!showRelevanceModal);
    setModalVisible({
      ...modalVisible,
      postArticleRelevant: true,
    });
  }

  return (
    <>
      { showRelevanceModal && <RelevancyQuestion
                recentSearchId={recentSearchId}
                searchTerm={searchTerm ?? newsItem.fullName}
                articleSource={articleSource}
                refId={newsItem.id ? newsItem.id : newsItem.articleId}
                articleCount={articleCount}
                articleRelevacnyData={articleRelevacnyData}
                articleId={newsItem.articleId}
                contactId={newsItem.contactID ?? savedContactID}
                attributes={article?.attributes}
                setAttributes={setAttributes}
                fullName={newsItem.fullName}
                searchSettings={searchSettings}
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
                backButton={backButton}
                contactData={contactData}
                setShowLoader={setShowLoader}
                setHeaderTags={setHeaderTags}
                headerTags={headerTags}
                setSaveRelevancyData={setSaveRelevancyData}
                saveRelevancyData={saveRelevancyData}
                deleteRelevancyData={deleteRelevancyData}
                setDeleteRelevancyData={setDeleteRelevancyData}
                setRefreshList={setRefreshList}
                potentialMatchId={newsItem?.potentialMatchId ?? props?.feed?.potentialMatchId}
              />}
              
      <ArticleCard
        isMap={isMap}
        isWeb={isWeb}
        height={height}
        key={key}
        dontShow={dontShow}
        isRecent={isRecent}
        openFilter={openFilter}
        article={newsItem}
        style={style.articleSeparator}
        isShowName={isShowName}
        enableRelevanceModal={() => enableRelevanceModal(newsItem)}
        displayPostArticleRelevanceBot={displayPostArticleRelevanceBot}
        setRelevantArticle={setRelevantArticle}
        filterAttributes={filterAttributes}
        onPress={(newsItem, selectedDocument) => {
          if(!isMap){
            newsItem.isRead = true;
            if (!isRecent && newsItem.itemType !== "Unicourt") {
              saveArticleHistory({
                variables: {
                  data: parseSaveHistory(newsItem, recentSearchId, contactDataID, displayPostArticleRelevanceBot, newsItem?.potentialMatchId ?? props?.feed?.potentialMatchId)
                }
              })
            }
          }
          onPress?.(selectedDocument)
          // setSelectedDoc(selectedDocument)
          // setClick(true)
          // getArticleById({
          //   variables: {
          //     id: newsItem.id
          //   }
          // })
        }}
      />

      <div style={style.separator}></div>


      {loading && (
        <Backdrop
          sx={{ backgroundColor: "#00000000", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )
      }
    </>
  )
}

export default ArticleCardWithQuery