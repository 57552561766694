import { create } from 'zustand'

export const signupStore = create((set) => ({
  activeStepper: 0,
  dob: null,
  phone: "",
  countryCode: undefined,
  firstName: "",
  lastName: "",
  firstNameValid: { status: null, msg: "" },
  lastNameValid: { status: null, msg: "" },
  dobValid: { status: null, msg: "" },
  phoneValid: { status: null, msg: "" },
  email: "",
  password: "",
  confirmPassword: "",
  emailValid: { status: null, msg: "" },
  passwordValid: { status: null, msg: "" },
  confirmPasswordValid: { status: null, msg: "" },
  accountExist: { status: null, msg: "" },
  variables: '',
  setVariables: (data) => set(() => ({ variables: data })),
  setAccountExist: (data) => set(() => ({ accountExist: data })),
  setConfirmPasswordValid: (data) => set(() => ({ confirmPasswordValid: data })),
  setPasswordValid: (data) => set(() => ({ passwordValid: data })),
  setEmailValid: (data) => set(() => ({ emailValid: data })),
  setConfirmPassword: (data) => set(() => ({ confirmPassword: data })),
  setPassword: (data) => set(() => ({ password: data })),
  setEmail: (data) => set(() => ({ email: data })),
  setPhoneValid: (data) => set(() => ({ phoneValid: data })),
  setDobValid: (data) => set(() => ({ dobValid: data })),
  setLastNameValid: (data) => set(() => ({ lastNameValid: data })),
  setFirstNameValid: (data) => set(() => ({ firstNameValid: data })),
  setLastName: (data) => set(() => ({ lastName: data })),
  setFirstName: (data) => set(() => ({ firstName: data })),
  setCountryCode: (data) => set(() => ({ countryCode: data })),
  setPhone: (data) => set(() => ({ phone: data })),
  setDob: (data) => set(() => ({ dob: data })),
  setActiveStepper: (data) => set(() => ({ activeStepper: data })),
  removeAll: () => set({
    activeStepper: 0,
    dob: null,
    phone: "",
    countryCode: undefined,
    firstName: "",
    lastName: "",
    firstNameValid: { status: null, msg: "" },
    lastNameValid: { status: null, msg: "" },
    dobValid: { status: null, msg: "" },
    phoneValid: { status: null, msg: "" },
    email: "",
    password: "",
    confirmPassword: "",
    emailValid: { status: null, msg: "" },
    passwordValid: { status: null, msg: "" },
    confirmPasswordValid: { status: null, msg: "" },
    accountExist: { status: null, msg: "" },
    variables: '',
  })
}))