import React from "react";

function InfoGreen() {
  return (
    <svg
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
    >
      <path
        fill="#8E96A8"
        fillRule="evenodd"
        d="M12 6A6 6 0 110 6a6 6 0 0112 0zM6 2.692a1 1 0 100 2 1 1 0 000-2zM6.538 6a.538.538 0 10-1.076 0v3.23a.538.538 0 001.076 0V6z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default InfoGreen;