const mockContent = `
Washington DC Reuters\n
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam hendrerit, elit eget viverra cursus, neque turpis lobortis nisi, ac tincidunt justo tortor eu velit. Maecenas sed lectus accumsan, scelerisque erat id, ullamcorper felis. Ut vel magna ac dolor sagittis congue. Maecenas vel risus tellus. Curabitur et nisi mauris. Etiam vulputate sagittis felis porttitor egestas. Curabitur dui neque, tristique pretium augue vel, vulputate varius leo. Pellentesque sit amet tellus eget urna faucibus commodo. Fusce aliquam lacus sed tortor porta sagittis. Cras at dui sed sapien vestibulum elementum. Phasellus tincidunt rutrum felis, vitae pharetra nulla mollis sed. Nulla convallis diam volutpat magna bibendum pulvinar. Cras dignissim purus at nunc ullamcorper molestie. Integer consequat vehicula porttitor.\n
Mauris a eros in ipsum viverra dignissim. Etiam eu lacus at urna ultricies gravida at non orci. Sed varius eros et tempus dictum. Donec in ex et arcu gravida dignissim ac non arcu. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Suspendisse porta sapien nisl, commodo facilisis magna rhoncus ut. Nunc vulputate, mauris nec laoreet dictum, dolor metus ultricies felis, a lobortis massa felis id quam. Sed vehicula lobortis nisl quis fringilla. Aliquam a tortor congue, sagittis sapien non, molestie leo. Suspendisse potenti. Phasellus consectetur, justo sed mollis vulputate, sem libero congue est, vitae interdum lorem dui nec enim. Morbi vestibulum vitae ipsum vel suscipit. Integer et magna in mauris viverra tincidunt.\n
Fusce tristique, ante ultrices rutrum facilisis, diam nibh feugiat neque, nec dignissim purus libero eget nulla. Aliquam nec mauris at massa laoreet posuere. Fusce et leo nunc. Nunc egestas metus dolor, sit amet luctus dolor condimentum vitae. Integer vel elit ullamcorper, hendrerit nisi eget, dignissim erat. Nam eu commodo risus. Aliquam tristique, dolor at dapibus vestibulum, ligula purus euismod ligula, nec mollis massa est in nulla. Aenean tempor nunc id dui sodales blandit. Fusce aliquam, elit eget sagittis eleifend, arcu lorem tempus ipsum, a vestibulum leo dolor a risus. Sed lobortis tempor nisi. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Praesent pharetra blandit ex nec accumsan. Ut ipsum dolor, bibendum ac justo id, porttitor sagittis justo. Donec dui libero, ultrices at tincidunt sed, pretium a ante. Pellentesque sodales, purus et pharetra pretium, turpis purus tincidunt velit, id facilisis odio ex efficitur nisi. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
`;

const Attribute = (label, status) => {
  return {
    label,
    status: status,
  };
};

const AttributesGroup = (groupName, attributes) => {
  return {
    groupName,
    attributes: attributes.map((label) => Attribute(label, false)),
  };
};

const mockAttributes = [
  AttributesGroup("People", ["Scott", "Albert"]),
  {
    groupName: "Places",
    attributes: [
      {
        label: "West Virginia",
        status: false,
      },
      {
        label: "Philadelphia",
        status: true,
      },
      {
        label: "San Diego",
        status: false,
      },
    ],
  },
  AttributesGroup("Companies", ["Seamgen", "Ziggo", "Vodafone", "Zed Conect"]),
];
const tracers = [
  {
    header: "Debtor Info",
    body: '[{"Address":"2101 Legacy CT; Keller, TX 76248-8449","County":"Tarrant"},{"Address":"2101 Legacy CT; Keller, TX 76248-8449","County":"Tarrant"}]',
  },
  {
    header: "Case Details",
    body: '{"courtId":"TXN","division":"4","district":"TX - FT. WORTH - NORTHERN","address":"501 W 10th ST, Ste 147; Ft Worth, TX 76102-3638","county":"Tarrant","phone":"8173336000"}',
  },
];

export { mockContent, mockAttributes, tracers };
