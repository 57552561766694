import * as React from "react"

const UserAvatar = (props) => (
  <svg
    width={props?.size ? props.size : 16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.457 5.429V5.21a.319.319 0 0 0-.171-.285A2.56 2.56 0 0 1 8.743 2.57 4.274 4.274 0 0 1 9.2.663a.32.32 0 0 0 0-.229.32.32 0 0 0-.16-.171A4 4 0 1 0 7.6 8a3.977 3.977 0 0 0 2.983-1.337.286.286 0 0 0 .057-.297 2.538 2.538 0 0 1-.183-.937ZM7.177 10.572a.274.274 0 0 0 0-.263.251.251 0 0 0-.32-.103 7.588 7.588 0 0 0-6.446 5.028.537.537 0 0 0 .069.526.56.56 0 0 0 .491.24H6a.252.252 0 0 0 .24-.137.262.262 0 0 0 0-.274l-.309-.629a.263.263 0 0 1 0-.206l1.246-4.182ZM14.777 15.234a7.588 7.588 0 0 0-6.446-5.028.285.285 0 0 0-.251.08.274.274 0 0 0 0 .263l1.257 4.183a.32.32 0 0 1 0 .205l-.32.629a.297.297 0 0 0 0 .274.252.252 0 0 0 .24.137h5.029a.582.582 0 0 0 .468-.24.573.573 0 0 0 .023-.503ZM13.886 5.429a.65.65 0 0 1 .354-.583 2.571 2.571 0 1 0-3.783-2.286.857.857 0 1 0 1.714 0 .857.857 0 1 1 1.269.754 2.4 2.4 0 0 0-1.269 2.103.857.857 0 1 0 1.715 0v.012ZM13.029 9.714a1.143 1.143 0 1 0 0-2.285 1.143 1.143 0 0 0 0 2.285Z"
      fill="#5391F7"
    />
  </svg>
)

export default UserAvatar
