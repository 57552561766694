import { useMutation } from 'react-apollo';
import {Save_Recent_Search} from '../../queries/queries';

const useSaveRecentSearch = () => {
  const [saveRecentSearch, {data: dataSaveSeaches, loading: loadingSaveSeaches, error: errorSaveSeaches}] = useMutation(Save_Recent_Search);

  const parseSaveRecentSearch = (name, newMetadata, eventType, id) => {
    
    const query = {
      variables: {
        data: {
          "searchTerm": name,
          "searchType": newMetadata.Person && newMetadata.Person != '' ? 'PERSON' : newMetadata?.Company?.toLowerCase() == name?.toLowerCase() ? 'COMPANY' : newMetadata?.Location?.toLowerCase() == name?.toLowerCase() ? 'LOCATION' : newMetadata?.Place?.toLowerCase() == name?.toLowerCase() ? 'PLACE' : newMetadata?.Nationality?.toLowerCase() == name?.toLowerCase() ? 'PLACE' : "PERSON",
          "metaData": {
            "Person": [newMetadata.Person ? newMetadata.Person : ""],
            "Company": [newMetadata.Company ? newMetadata.Company : ""],
            "Location": [newMetadata.Location ? newMetadata.Location : ""],
            "Place": [newMetadata.Place ? newMetadata.Place : ""],
            "Nationality": [newMetadata.Nationality ? newMetadata.Nationality : ""]
          }
        },
      }
    };
    
    if(id)
      query.variables.data.id = id
    if(eventType)
      query.variables.data.eventType = eventType

    saveRecentSearch(query);
  }

  return {
      saveRecentSearch,
      parseSaveRecentSearch,
      dataSaveSeaches,
      loadingSaveSeaches,
      errorSaveSeaches,
    }
}

export default useSaveRecentSearch