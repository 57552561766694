export const getTitle = (key) => {
  switch (key) {
    case "school":
      return "School";
    case "program_type":
      return "Degree";

    case "school":
      return "School";

    case "title":
      return "Title";

    case "company":
      return "Organization";

    case "location":
      return "Location";

    case "startDate":
      return "Start Date";

    case "endDate":
      return "End Date";
    default:
      return key;
  }
};
