import _ from 'lodash'
import { extractContactsFromGoogleContactsReponse, isLastPage } from '../../utils/google-contacts-parser'

const MAX_RESULTS = 1000
let googleContacts = []

const getGoogleContacts = async (preGetContactsAction, postGetContactsAction, updateUser, syncToken, updateOnly = false, googleUserData) => {
  try {
    preGetContactsAction?.()
    if (googleUserData && googleUserData?.access_token) {
      retrieveGoogleContacts(null, googleUserData?.access_token, postGetContactsAction, updateUser, syncToken, updateOnly)
    }
  } catch (error) {
    postGetContactsAction?.(error, undefined)
  }
}

function retrieveGoogleContacts(startIndex, googleToken, postGetContactsAction, updateUser, syncToken, updateOnly = false) {
  if (startIndex === null) {
    googleContacts = []
  }

  let params = {
    "personFields": "names,emailAddresses,phoneNumbers,addresses,photos,organizations,metadata",
    "pageSize": MAX_RESULTS,
    "access_token": googleToken,
    "sortOrder": "FIRST_NAME_ASCENDING",
    "requestSyncToken": true
  }
  if (syncToken) params["syncToken"] = syncToken;
  if (startIndex != null)
    params.pageToken = startIndex;

  let url = "https://people.googleapis.com/v1/people/me/connections?"
  const suburl = transformRequest(params)
  url = url + suburl
  window.gapi.load('client', () => {
    window.gapi.client
      .request({
        path: url
      })
      .then((response) => {
        setTimeout(() => { let a = 0 }, 0)
        return response.result
      }).then(async (responseJson) => {
        if (responseJson?.error) {
          postGetContactsAction?.(undefined, undefined, updateOnly)
          let message = ''
          if (responseJson.error?.status === 'EXPIRED_SYNC_TOKEN') {
            // Sync token expired. Make full sync request.
            retrieveGoogleContacts(startIndex, googleToken, postGetContactsAction, updateUser, null, false)
          }
          if (responseJson.error?.status === 'PERMISSION_DENIED') {
            message = 'Please allow access to your Google Contacts in order to be able to import them into Ferret'
          }
        } else {
          if (!updateOnly) {
            await updateUser({
              variables: {
                userConsentGoogleContacts: true,
              },
            });
          }
          if (responseJson?.nextSyncToken) {
            await updateUser({
              variables: {
                syncToken: responseJson.nextSyncToken,
              },
            });
          }
          googleContacts = _.concat(googleContacts, extractContactsFromGoogleContactsReponse(responseJson))
          if (isLastPage(responseJson)) {
            if (googleContacts && googleContacts.length > 0)
              postGetContactsAction?.(undefined, googleContacts, updateOnly)
            else
              postGetContactsAction?.()
            googleContacts = []
          } else {
            retrieveGoogleContacts(responseJson.nextPageToken, googleToken, postGetContactsAction, updateUser, updateOnly)
          }
        }
      }).catch((error) => {
        postGetContactsAction?.(error, undefined, updateOnly)
        if (!updateOnly) {
          console.log('Access to Google Contacts is forbidden', error)
        }
      });
  })
}

function transformRequest(obj) {
  let str = []
  for (var p in obj) {
    str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`)
  }
  return str.join("&")
}

export default getGoogleContacts