import { create } from 'zustand'

export const savedSearchArticlesStore = create((set) => ({
  recentSearchId: "",
  showProcessArticlesModal: { status: false, count: 0 },
  processArticleDone: [],
  processArticleInProgress: [],
  profilePic: "",
  chkBxNotifyUser: false,
  saveSearch: false,
  monitorSearch: false,
  feedbackOptions: [],
  editSearchSetting: {},
  configurationData: {},
  ConfigurationLoading: false,
  filteredArticles: [],
  filteredArticles2: [],
  originalfilteredArticles: [],
  isEnableFilter: false,
  articleFilterData: undefined,
  personalInfo: {},
  isShowPersonalInfo: true,
  selectedFilter: undefined,
  sortState: 2,
  showStrongMatchArticles: false,
  showWeakMatchArticles: false,
  filterAttributes: {
    "People": [],
    "Organizations": [],
    "Places": [],
    "Parties": [],
    "Attorneys": []
  },
  readFilter: false,
  unreadFilter: false,
  attributes: [],
  headerTags: [],
  saveRelevancyData: undefined,
  deleteRelevancyData: undefined,
  verified: true,
  partial: true,
  needsReview: true,
  refreshList: false,
  showHeader: true,
  modalFilterVisible: true,
  summaryPosition: 0,
  articlePosition: 0,
  articleCount: 0,
  akaList: undefined,
  articles: [],
  applyFilter: null,
  refreshing: false,
  refreshingFilter: false,
  isSearchLimitExceed: false,
  searchLimitExceedTitle: null,
  openPersonalInfo: false,
  subscriptionModalVisible: false,
  originalArticles: [],
  linkedInData: null,
  setRecentSearchId: (data) => set(() => ({ recentSearchId: data })),
  showSaveConfirmationAlert: { status: false, msg: "" },
  setProcessArticleInProgress: (data) => set(() => ({ processArticleInProgress: data })),
  setProcessArticleDone: (data) => set(() => ({ processArticleDone: data })),
  setProfilePic: (data) => set(() => ({ profilePic: data })),
  setFeedbackOptions: (data) => set(() => ({ feedbackOptions: data })),
  setEditSearchSetting: (data) => set(() => ({ editSearchSetting: data })),
  setLinkedInData: (data) => set(() => ({ linkedInData: data})),
  setOriginalArticles: (data) => set(() => ({ originalArticles: data})),
  setArticleCount: (data) => set(() => ({ articleCount: data})),
  setAKAlist: (data) => set(() => ({ akaList: data})),
  setArticles: (data) => set(() => ({ articles: data})),
  setApplyFilter: (data) => set(() => ({ applyFilter: data})),
  setRefreshing: (data) => set(() => ({ refreshing: data})),
  setRefreshingFilter: (data) => set(() => ({ refreshingFilter: data})),
  setSearchLimitExceed: (data) => set(() => ({ isSearchLimitExceed: data})),
  setSearchLimitExceedTitle: (data) => set(() => ({ searchLimitExceedTitle: data})),
  setOpenPersonalInfo: (data) => set(() => ({ openPersonalInfo: data})),
  setSubscriptionModalVisible: (data) => set(() => ({ subscriptionModalVisible: data})),
  setConfigurationData: (data) => set(() => ({ configurationData: data})),
  setConfigurationLoading: (data) => set(() => ({ ConfigurationLoading: data})),
  setFilteredArticles: (data) => set(() => ({ filteredArticles: data})),
  setFilteredArticles2: (data) => set(() => ({ filteredArticles2: data})),
  setOriginalFilteredArticles: (data) => set(() => ({ originalfilteredArticles: data})),
  setArticleFilterData: (data) => set(() => ({ articleFilterData: data})),
  setPersonalInfo: (data) => set(() => ({ personalInfo: data})),
  setShowPersonalInfo: (data) => set(() => ({ isShowPersonalInfo: data})),
  setSelectedFilter: (data) => set(() => ({ selectedFilter: data})),
  setSortState: (data) => set(() => ({ sortState: data})),
  setShowStrongMatchArticles: (data) => set(() => ({ showStrongMatchArticles: data})),
  setShowWeakMatchArticles: (data) => set(() => ({ showWeakMatchArticles: data})),
  setFilterAttributes: (data) => set(() => ({ filterAttributes: data})),
  setReadFilter: (data) => set(() => ({ readFilter: data})),
  setUnreadFilter: (data) => set(() => ({ unreadFilter: data})),
  setAttributes: (data) => set(() => ({ attributes: data})),
  setHeaderTags: (data) => set(() => ({ headerTags: data})),
  setSaveRelevancyData: (data) => set(() => ({ saveRelevancyData: data })),
  setDeleteRelevancyData: (data) => set(() => ({ deleteRelevancyData: data })),
  setVerified: (data) => set(() => ({ verified: data})),
  setPartial: (data) => set(() => ({ partial: data})),
  setNeedsReview: (data) => set(() => ({ needsReview: data})),
  setRefreshList: (data) => set(() => ({ refreshList: data})),
  setShowHeader: (data) => set(() => ({ showHeader: data})),
  setModalFilterVisible: (data) => set(() => ({ modalFilterVisible: data})),
  setSummaryPosition: (data) => set(() => ({ summaryPosition: data})),
  setArticlePosition: (data) => set(() => ({ articlePosition: data })),
  setShowSaveConfirmationAlert: (data) => set(() => ({ showSaveConfirmationAlert: data })),
  setChkBxNotifyUser: (data) => set(() => ({ chkBxNotifyUser: data })),
  setSaveSearch: (data) => set(() => ({ saveSearch: data })),
  setMonitorSearch: (data) => set(() => ({ monitorSearch: data })),
  setShowProcessArticlesModal: (data) => set(() => ({ showProcessArticlesModal: data })),
  removeAll: () => set({ 
    configurationData: {},
    ConfigurationLoading: false,
    filteredArticles: [],
    filteredArticles2: [],
    originalfilteredArticles: [],
    articleFilterData: undefined,
    personalInfo: {},
    isShowPersonalInfo: true,
    selectedFilter: undefined,
    sortState: 2,
    showStrongMatchArticles: false,
    showWeakMatchArticles: false,
    filterAttributes: {
      "People": [],
      "Organizations": [],
      "Places": [],
      "Parties": [],
      "Attorneys": []
    },
    readFilter: false,
    unreadFilter: false,
    attributes: [],
    headerTags: [],
    saveRelevancyData: undefined,
    deleteRelevancyData: undefined,
    verified: true,
    partial: true,
    needsReview: true,
    refreshList: false,
    showHeader: true,
    modalFilterVisible: true,
    summaryPosition: 0,
    articlePosition: 0,
    articleCount: 0,
    akaList: undefined,
    articles: [],
    applyFilter: null,
    refreshing: false,
    refreshingFilter: false,
    isSearchLimitExceed: false,
    searchLimitExceedTitle: null,
    openPersonalInfo: false,
    subscriptionModalVisible: false,
    originalArticles: [],
    linkedInData: null,
    editSearchSetting: {},
    feedbackOptions: [],
    profilePic: "",
    showSaveConfirmationAlert: { status: false, msg: "" },
    chkBxNotifyUser: false,
    saveSearch: false,
    monitorSearch: false,
    processArticleDone: [],
    processArticleInProgress: [],
    showProcessArticlesModal: { status: false, count: 0 },
  }),
}))