import Variables from '../../utils/Variables';
import { questionsTypes } from './Question';
import addQueryData from '../../utils/addQueryData'
import {
  getNoResultMetaTags,
} from './questionsAnswers';

const setAnswerStatus = (questions, indexOfQuestion, indexOfAnswer, status, isSingleSelection = false, slice = false) => {
  const updatedQuestions = questions.map((question, index) => {
    if (index !== indexOfQuestion) return question;
    return {
      ...question,
      answers: question.answers.map((answer, index) => {
        let newStatus = index === indexOfAnswer ? status : (isSingleSelection ? false : answer.status)
        let updated = index === indexOfAnswer && status ? Date.now() : answer?.updated
        return {
          ...answer,
          status: newStatus,
          updated
        }
      }),
    };
  });
  let updatedQuestionsAnswered;
  if (slice == true) {
    updatedQuestionsAnswered = updateQuestionAnswered(
      updatedQuestions.slice(0, indexOfQuestion + 1),
      indexOfQuestion,
    );
  }
  if (slice == false) {
    updatedQuestionsAnswered = updateQuestionAnswered(
      updatedQuestions,
      indexOfQuestion,
    );
  }
  return updatedQuestionsAnswered;
};

const updateQuestionAnswered = (questions, indexOfQuestion) =>
  questions.map((question, index) => {
    if (index !== indexOfQuestion) return question;
    return {
      ...question,
      answered: question.answers.reduce(
        (acc, { status }) => acc || status,
        false,
      ),
    };
  });

const getSelectedAnswer = question =>
  question.answers.filter(answer => answer.status)[0];

const getSelectedEditingOtherAnswer = question =>
  question.answers.filter(answer => answer.status && answer.type == questionsTypes.OTHER)?.[0];

const getSelectedOtherAnswer = question =>
  question.answers.filter(answer => answer.status && answer.type == questionsTypes.OTHER)?.[0]?.value === ''

const getSelectedMultiAnswer = (question, separator = Variables.metaDataSeparator) => {
  let value;
  question.answers.filter(answer => answer.status && answer.value != '').forEach(item => {
    value = value ? value + separator + (item.value) : (item.value)
  });
  return value
}

const getSelectedType = question =>
  question.answers.filter(answer => answer.status)[0];

const setValueToOhetAnswer = (questions, indexOfQuestion, value) => {
  return questions.map((question, index) => {
    if (index !== indexOfQuestion) return question;
    const updateQuestion = {
      ...question,
      answers: question.answers.map(answer => {
        const { type } = answer;
        if (type !== questionsTypes.OTHER) return answer;
        return {
          ...answer,
          value,
        };
      }),
    };
    return updateQuestion;
  });
};

const setValueToOhetAnswerSavedSearch = (questions, indexOfQuestion, fieldName, value) => {
  return questions.map((question, index) => {
    if (index !== indexOfQuestion) return question;
    const updateQuestion = {
      ...question,
      answers: question.answers.map(answer => {
        const { label } = answer;
        if (label !== fieldName) return answer;
        return {
          ...answer,
          value,
        };
      }),
    };
    return updateQuestion;
  });
};

const getNoSelectedQuestions = (questions, indexOfSelectedQuestion) => {
  return questions.filter((_, index) => index !== indexOfSelectedQuestion);
};

const getMetadataFromQuestions = (questions, meta_data, queryData) => {
  let newMetadata = {}
  if (questions?.length && questions[questions.length - 1]?.meta_data) {
    newMetadata = questions[questions.length - 1]?.meta_data ?? {};
    let qusMetaData = {};
    questions.forEach((question, index) => {
      if (index != 0) {
        const { identifier } = question;
        const value = getSelectedMultiAnswer(question)
        if (value && identifier) {
          qusMetaData = { ...qusMetaData, ...{ [`${identifier}`]: value } }
        }
      }
    })
    if ((typeof (newMetadata) == 'string' ? newMetadata : JSON.stringify(newMetadata)) === '{}' && (typeof (qusMetaData) == 'string' ? qusMetaData : JSON.stringify(qusMetaData)) === '{}') {
      newMetadata = typeof (meta_data) == 'string' ? { ...JSON.parse(meta_data), ...qusMetaData } : { ...meta_data, ...qusMetaData }
    }
    else
      newMetadata = { ...newMetadata, ...qusMetaData }
  } else if (questions?.length) {
    let qusMetaData = {};
    questions.forEach(question => {
      const { identifier } = question;
      const value = getSelectedMultiAnswer(question)
      if (identifier) {
        qusMetaData = { ...qusMetaData, ...{ [`${identifier}`]: (value?.length > 0) ? value : "" } }
      }
    })
    newMetadata = typeof (meta_data) == 'string' ? { ...JSON.parse(meta_data), ...qusMetaData } : { ...meta_data, ...qusMetaData }
  } else {
    newMetadata = typeof (meta_data) == 'string' ? JSON.parse(meta_data) : meta_data
  }

  addQueryData(queryData, newMetadata)

  return newMetadata;
}

const getNoResultMetadataFromQuestions = (questions, searchString) => {
  let newMetadata = { Name: searchString }
  if (questions?.length) {
    questions.forEach(question => {
      if (question?.identifier != 'noResultFound_details') {
        const identifier = getNoResultMetaTags(question?.identifier)
        const value = question.answers.filter(answer => answer.status && answer.value != '' && answer.value != "I don't know ")?.[0]?.value
        if (value && identifier) {
          newMetadata = { ...newMetadata, ...{ [`${identifier}`]: identifier === 'SearchType' ? value.toUpperCase() : value } }
        }
      }
    })
  }
  return newMetadata;
}

const getCompleteSearchQuery = (questions, meta_data, queryData, savedTahoeId, savedTracerQueryId) => {
  let query = {}
  if (questions?.length) {
    query = {
      variables: {
        fullname: {
          meta_data: JSON.stringify(getMetadataFromQuestions(questions, meta_data, queryData)),
          search: "search",
          source: "ADHOC_SEARCH"
        },
      },
    }

    if (savedTahoeId && savedTahoeId != '') query.variables.fullname.tahoeId = savedTahoeId
    if (savedTracerQueryId && savedTracerQueryId != '') query.variables.fullname.tracerQueryId = savedTracerQueryId

  } else if (meta_data && meta_data != null && (typeof (meta_data) == 'string' ? meta_data : JSON.stringify(meta_data)) != '{}') {
    query = {
      variables: {
        fullname: {
          meta_data: meta_data,
          search: "search",
          source: "ADHOC_SEARCH"
        },
      },
    }

    if (savedTahoeId && savedTahoeId != '') query.variables.fullname.tahoeId = savedTahoeId
    if (savedTracerQueryId && savedTracerQueryId != '') query.variables.fullname.tracerQueryId = savedTracerQueryId

  } else if (queryData?.title) {
    query = {
      variables: {
        fullname: {
          meta_data: "{\"Person\":\"" + (queryData.title) + "\"}",
          search: "search",
          source: "ADHOC_SEARCH"
        },
      },
    }

    if (savedTahoeId && savedTahoeId != '') query.variables.fullname.tahoeId = savedTahoeId
    if (savedTracerQueryId && savedTracerQueryId != '') query.variables.fullname.tracerQueryId = savedTracerQueryId

  }
  if (Variables.contactId) {
    query.variables.fullname.contactId = Variables.contactId
  }
  return query;
}

const getSavedSearchParams = (metadata) => {
  let savedSearchParams = []
  if (metadata.Person) savedSearchParams.push({
    "param": metadata.Person,
    "indexOfQuestion": "",
    "indexOfAnswer": ""
  })
  if (metadata.Company) savedSearchParams.push({
    "param": metadata.Company,
    "indexOfQuestion": "",
    "indexOfAnswer": ""
  })
  if (metadata.Location) savedSearchParams.push({
    "param": metadata.Location,
    "indexOfQuestion": "",
    "indexOfAnswer": ""
  })
  if (metadata.Place) savedSearchParams.push({
    "param": metadata.Place,
    "indexOfQuestion": "",
    "indexOfAnswer": ""
  })
  if (metadata.Nationality) savedSearchParams.push({
    "param": metadata.Nationality,
    "indexOfQuestion": "",
    "indexOfAnswer": ""
  })

  return savedSearchParams;
}

const getUpperCaseMeta = (metaString) => {
  try {
    const arr = metaString.split(" ")
    arr.forEach((item, index) => {
      arr[index] = item.charAt(0).toUpperCase() + item.slice(1)
    })
    return arr.join(" ")
  } catch (e) { return metaString }
}

export {
  setAnswerStatus,
  getSelectedAnswer,
  getSelectedMultiAnswer,
  getSelectedOtherAnswer,
  getSelectedEditingOtherAnswer,
  getSelectedType,
  setValueToOhetAnswer,
  getNoSelectedQuestions,
  getMetadataFromQuestions,
  getCompleteSearchQuery,
  setValueToOhetAnswerSavedSearch,
  getSavedSearchParams,
  getNoResultMetadataFromQuestions,
  getUpperCaseMeta,
};
