// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".articleCardWithQuery_articleCardView__-vtcs {\n    border-radius: 12px;\n    margin-left: 16px;\n    margin-right: 16px;\n    margin-top: 10px;\n}", "",{"version":3,"sources":["webpack://./src/web/components/articleCardWithQuery/articleCardWithQuery.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;AACpB","sourcesContent":[".articleCardView {\n    border-radius: 12px;\n    margin-left: 16px;\n    margin-right: 16px;\n    margin-top: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"articleCardView": "articleCardWithQuery_articleCardView__-vtcs"
};
export default ___CSS_LOADER_EXPORT___;
