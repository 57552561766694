import * as React from "react"
const Debts = (props) => { 
    return (
    <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
      >
        <g clipPath="url(#a)">
          <path
            d="M32 9.333a9.334 9.334 0 1 0-18.668 0 9.334 9.334 0 0 0 18.668 0ZM21.667 15a.333.333 0 0 0-.334-.333h-.826a1 1 0 0 1 0-2h2.933a.947.947 0 0 0 .347-1.814L20.8 9.667A2.933 2.933 0 0 1 21.333 4a.333.333 0 0 0 .28-.32V3.2a1 1 0 1 1 2 0v.467A.333.333 0 0 0 24 4h.827a1 1 0 1 1 0 2h-2.934a.947.947 0 0 0-.346 1.813L24.533 9A2.934 2.934 0 0 1 24 14.667a.333.333 0 0 0-.28.32v.533a1 1 0 1 1-2 0l-.053-.52Z"
            fill={props.selected ?  "#142030" :  props.iconType == 'white' ? '#fff' :  '#b5b9c4'}
          />
          <path
            d="M30.666 31.333A.667.667 0 0 1 30 32h-6.667a.667.667 0 0 1-.613-.413.68.68 0 0 1 .147-.72l1.893-1.894a.387.387 0 0 0 .093-.24.332.332 0 0 0-.107-.24l-6.08-5.626a.347.347 0 0 0-.44 0L14.36 25.96a2.761 2.761 0 0 1-1.333.587 2.84 2.84 0 0 1-2.36-.707l-.294-.253-9.813-8.68A1.667 1.667 0 0 1 1.667 14a1.6 1.6 0 0 1 1.106.427l9.534 8.48a.347.347 0 0 0 .44 0l3.866-3.094a2.907 2.907 0 0 1 3.747.134l6.667 6.213a.347.347 0 0 0 .466 0l1.947-1.947a.653.653 0 0 1 .467-.2.64.64 0 0 1 .266 0 .68.68 0 0 1 .4.614l.093 6.706Z"
            fill={props.selected ? props.iconType =="blackWhite"? "#1E1E1E": "#DA3528" : props.iconType == 'white' ? '#fff' :  '#b5b9c4'}
          />
        </g>
        <defs>
          <clip-path id="a">
            <path fill="#fff" d="M0 0h32v32H0z" />
          </clip-path>
        </defs>
      </svg>
)}

export default Debts
