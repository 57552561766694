import React from 'react';
import GroupTags from '../groupTags';
import { capitalizeQuestionSameCase, capitalizeCase } from '../../utils/utils';

const Attributes = props => {
  const {
    attributes,
    fullName,
    onSelectAttribute,
    selectable = false,
    theme = 'light',
    from = '',
    newsItem,
    isFromBot = false,
    isFromArticle = false,
    matchedAttributesOriginal,
  } = props;

  let name = fullName ? fullName : 'person';

  const groupNameChge = (groupName) => {
    if (from == 'relevant') {
      if (groupName == 'People') return `Was this article about your ${capitalizeCase(name, true)}?`
      else if (groupName?.toLowerCase() == 'Organizations'.toLowerCase()) return `Is or was your ${name} associated with any of these organizations?`
      else if (groupName == 'Places') return `Did or does your ${name} live or work in the following locations?`
      else if (groupName == 'Parties') return `Is or was your ${name} associated with any of these parties?`
      else if (groupName == 'Attorneys') return `Is or was your ${name} associated with any of these attorneys?`
      else return groupName
    }
    return `${groupName}`
  }

  const groupsView = attributes?.map((group, index) => {
    const { groupName, attributes } = group;
    return (
      <GroupTags
        isAttributeView={true}
        key={`${index}-${groupName}`}
        title={capitalizeQuestionSameCase(groupNameChge(groupName), true)}
        groupName={groupName}
        tags={attributes}
        lastChild={index === props.attributes.length - 1}
        onPressTag={onSelectAttribute}
        selectable={selectable}
        theme={theme}
        isFromBot={isFromBot}
        isFromArticle={isFromArticle}
      />
    );
  });

  const matchedGroupsView = matchedAttributesOriginal?.map((group, index) => {
    const { groupName, attributes } = group;
    return (
      <GroupTags
        isAttributeView={true}
        key={`${index}-${groupName}`}
        title={capitalizeQuestionSameCase(groupNameChge(groupName), true)}
        groupName={groupName}
        tags={attributes}
        lastChild={index === matchedAttributesOriginal?.length - 1}
        onPressTag={onSelectAttribute}
        selectable={selectable}
        theme={theme}
        isFromBot={isFromBot}
        isFromArticle={isFromArticle}
      />
    );
  });

  return (
    <div style={{ paddingTop: 16, paddingBottom: 10 }}>
      <div>
        {groupsView}
      </div>
      {(!isFromBot && matchedAttributesOriginal?.length > 0) && <>
        <p style={{ fontSize: 14, fontWeight: 600, color: "#142030", paddingTop: 20, paddingBottom: 20, textAlign: "center" }}>
          Matched Attributes
        </p>
        <div>
          {matchedGroupsView}
        </div>
        <p style={{ fontSize: 12, color: "#aaaaaa", paddingTop: 20, paddingBottom: 20, textAlign: "center" }}>
          {newsItem?.articleId}-{newsItem?.sourceIdentifier}-{newsItem?.verifiedBy}-{newsItem?.matchingLogic}
        </p>
      </>}
    </div>
  );
};

export default Attributes;
