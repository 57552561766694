import * as React from "react"
const Assets = (props) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
    <path d="M16.9558 19.1342C14.8416 18.5903 14.1617 18.028 14.1617 17.1523C14.1617 16.1475 15.1024 15.4469 16.6764 15.4469C18.3343 15.4469 18.949 16.2305 19.0049 17.3827H21.0632C20.998 15.7972 20.0201 14.3407 18.0735 13.8706V11.8519H15.2793V13.843C13.4725 14.2301 12.0195 15.3916 12.0195 17.1707C12.0195 19.3001 13.7984 20.3602 16.397 20.9778C18.7255 21.5309 19.1912 22.3421 19.1912 23.1993C19.1912 23.8354 18.7348 24.8494 16.6764 24.8494C14.7578 24.8494 14.0033 24.0013 13.9009 22.9136H11.8519C11.9636 24.9323 13.4911 26.0662 15.2793 26.4441V28.4444H18.0735V26.4626C19.8897 26.1215 21.3333 25.0798 21.3333 23.1901C21.3333 20.5722 19.0701 19.678 16.9558 19.1342Z" fill={props.selected ? props.iconType == 'map' ? '#fff' : (props.iconType =="blackWhite"? "#1E1E1E": "#91CB6C") : props.iconType == 'white' ? '#fff' :  '#b5b9c4'}/>
    <path d="M27.2 9.95556V1.58025H22.4V5.68889L16 0L0 14.2222H4.8L16 4.25086L27.2 14.2222H32L27.2 9.95556Z" fill={props.selected ? props.iconType == 'map' ? '#fff' : "#142030" : props.iconType == 'white' ? '#fff' :  '#b5b9c4'}/>
    </svg>
  );
}

export default Assets

