import React, { useState, useRef, useContext } from "react";
import createStyle from "./styles";
import {
  AUTHENTICATE_FERRET_USER,
  AUTHENTICATE_FERRET_EMAIL,
} from "../../../queries/queries";
import { saveUserDataAndRedirectToInitialScreen } from "../../../root-navigation";
import { useMutation } from "react-apollo";
import OTPIllustrationLogo from "../../../images/svg/otp-Illustration";
import { UserContext } from "../../../contexts/userContext";
import OtpInput from "react-otp-input";
import { useNavigate, useLocation } from "react-router-dom";
import { GreenBgButton } from "../../../components/buttons/greenBgButton";
import SingleBtnAlert from "../../../components/alert/singleBtnAlert";
import Styles from "./signUpOtp.module.css";
import NavHeaderLight from "../../../components/navHeader/navHeaderLight";
import AppWalkthourgh from "../../components/appWalkthrough/appWalkthrough";
import Footer from "../../layout/Footer";

function EmailOtpVerifyForm({
  authToken,
  email,
  password,
  lastName,
  name,
  dateOfBirth,
  phoneNumber,
  address,
  deviceDetails,
}) {
  const styles = createStyle();
  const [displayError, setDisplayError] = useState(false);
  const [submitButtonState, setSubmitButtonState] = useState(true);
  const errorMessage = "Incorrect OTP";
  const { setUser } = useContext(UserContext);
  const input1 = useRef();
  const [otpInput, setOtpInput] = useState();
  const [resendOtp, setResendOtp] = useState();
  const [showSingleBtnAlert, setShowSingleBtnAlert] = useState({
    status: false,
    msg: "",
  });
  const navigate = useNavigate();

  //verify otp
  const [verifyOtp, { loading, error }] = useMutation(
    AUTHENTICATE_FERRET_EMAIL,
    {
      onCompleted(data) {
        const variables = {
          email: email,
          password: password,
          lastName: lastName,
          name: name,
          dateOfBirth: dateOfBirth,
          phoneNumber: phoneNumber,
          deviceDetails: deviceDetails,
          address: address,
        };
        setSubmitButtonState(true);
        saveUserDataAndRedirectToInitialScreen(
          data.verifyEmail,
          null,
          () => setSubmitButtonState(true),
          "signup",
          variables,
          setUser,
          navigate
        );

      },
      onError(err) {
        setDisplayError(true);
        setTimeout(() => {
          setSubmitButtonState(true);
          setDisplayError(false);
        }, 1500);
      },
    }
  );

  //resend otp
  const [requestOtp, { loading: requestOtpLoading }] = useMutation(
    AUTHENTICATE_FERRET_USER,
    {
      onCompleted(data) {
        setResendOtp(data.signUpEmail.id);
        setShowSingleBtnAlert({ status: true, msg: "OTP sent successfully!" });
        setSubmitButtonState(true);
      },
      onError(e) {
        console.log(e);
        setShowSingleBtnAlert({ status: true, msg: "Error!" });
        setSubmitButtonState(true);
      },
    }
  );

  const disabled = submitButtonState ? null : { disabled: true };

  return (
    <>
      <div style={styles.otpContainer}>
        <OtpInput
          ref={input1}
          focusStyle={styles.focusStyle}
          inputStyle={styles.roundedTextInput}
          onChange={(text) => setOtpInput(text)}
          value={otpInput}
          numInputs={6}
          isInputNum={true}
        />
      </div>
      {displayError && <span style={styles.errorMessage}>{errorMessage}</span>}
      <div style={styles.resendTextBlock}>
        <span style={styles.resendQuestion}>Didn’t receive the code? </span>
        <button
          style={styles.resendText}
          onClick={() => {
            requestOtp({
              variables: {
                email: email,
                password: password,
                lastName: lastName,
                name: name,
                dateOfBirth: dateOfBirth,
                phoneNumber: phoneNumber,
              },
            });
            setSubmitButtonState(false);
          }}
        >
          Resend Code
        </button>
      </div>
      <div style={styles.separator}>
        {displayError ? null : (
          <GreenBgButton
            disabled={!submitButtonState || otpInput?.length !== 6}
            fullWidth
            variant="contained"
            loading={loading || requestOtpLoading}
            onClick={() => {
              verifyOtp({
                variables: {
                  id: resendOtp ? resendOtp : authToken,
                  code: otpInput,
                },
              });
              setSubmitButtonState(false);
            }}
          >
            <div
              style={{
                fontFamily: "Proxima Nova",
                fontSize: 16,
                fontWeight: "600",
                textTransform: "capitalize",
              }}
            >
              Verify & Proceed
            </div>
          </GreenBgButton>
        )}
      </div>
      {showSingleBtnAlert.status == true && (
        <SingleBtnAlert
          description={showSingleBtnAlert.msg}
          btnTxt="OK"
          onBtnClick={() => {
            setShowSingleBtnAlert({ status: false, msg: "" });
          }}
        />
      )}
    </>
  );
}

export default function EmailOtpVerify() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const authT = state?.id;

  const {
    email,
    password,
    lastName,
    name,
    dateOfBirth,
    phoneNumber,
    address,
    deviceDetails,
  } = state?.variables;

  const [authToken, setAuthToken] = useState(authT);

  return (
    <div className={Styles.bodySignupOtp}>
      <div className={Styles.otpMain}>
        <NavHeaderLight
          headerName="Verify your E-mail"
          navigate={navigate}
          customTextStyle={{ fontSize: 22 }}
          customRightBtn={<div style={{ color: "transparent" }}>d</div>}
        />
        <div className={Styles.otpMainNew}>
          <div className={Styles.illustration}>
            <OTPIllustrationLogo />
          </div>
          <span className={Styles.headline}>Enter your Code</span>
          <span className={Styles.description}>
            Please use the Code sent to your registered E-mail to create Ferret
            account
          </span>
          <div>
            <EmailOtpVerifyForm
              authToken={authToken}
              setAuthToken={setAuthToken}
              email={email}
              password={password}
              lastName={lastName}
              name={name}
              dateOfBirth={dateOfBirth}
              phoneNumber={phoneNumber}
              address={address}
              deviceDetails={deviceDetails}
            />
          </div>
        </div>
      </div>
      {/* <NotAvailable /> */}
      <AppWalkthourgh />
      <div className={Styles.footer}>
        <Footer isLogin={true} isCenterCopyright={true} />
      </div>
    </div>
  );
}
