// Icons
import EditIcon from "../../images/icons/account-avatar/edit.svg";
import { UserContext } from "../../../contexts/userContext";
import { useContext } from "react";
import ProfilePicture from "../../../components/profilePicture/profilePicture";
import { useMutation } from "react-apollo";
import { UPDATE_FERRET_USER } from "./../../../queries/queries";
import { setUserInfo } from "../../../root-navigation";
import { getBase64, resizeImage } from "../../../utils/utils";
import { Backdrop, CircularProgress } from "@mui/material";
/**
 * AccountAvatar Component
 * @returns {JSX.Element}
 * @constructor
 */
function AccountAvatar() {
  const { user, setUser } = useContext(UserContext);

  const [userUpdaterMutation, { loading }] = useMutation(UPDATE_FERRET_USER, {
    fetchPolicy: "no-cache",
    onCompleted: ({ updateUser }) => {
      let u = setUserInfo(updateUser);
      u.tempProfilePhoto = null;
      setUser({ ...u });
    },
    onError: (error) => {
      alert("Error saving your profile picture");
      let u = user;
      u.tempProfilePhoto = null;
      setUser({ ...u });
    },
  });

  const onImageChange = (e) => {
    let file = e.target.files[0];
    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        resizeImage(result).then((response) => updateUserImage(response));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateUserImage = (base64String) => {
    let variables = { base64String };
    userUpdaterMutation({
      variables: variables,
    });
    let u = user;
    u.tempProfilePhoto = base64String;
    setUser({ ...u });
  };

  /**
   * Main render method
   */
  return (
    <>
      <div className="w-[136px] h-[136px] m-auto text-center relative">
        <ProfilePicture
          avatarStyle={{ width: "140px", height: "140px" }}
          profilePhoto={user?.profilePhoto}
        />
        <input
          className="hidden"
          id="iconButton"
          type="file"
          accept="image/*"
          onChange={onImageChange}
        />
        <label htmlFor="iconButton">
          <div className="cursor-pointer absolute pt-2 pb-1.5 px-2 bottom-2 right-0 rounded-full bg-gray-light">
            <img src={EditIcon} alt="Edit" />
          </div>
        </label>

        {loading && (
          <Backdrop
            sx={{
              position: "absolute",
              backgroundColor: "#00000000",
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </div>
      <h3 className="mt-[16px] mb-[16px] text-center">
        {user.givenName} {user.familyName}
      </h3>
    </>
  );
}

// Component export
export default AccountAvatar;
