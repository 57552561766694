
import * as React from "react"

const ResetIcon = (props) => (
  <svg
    width={12}
    height={14}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.196 1.439 2.053 3.582m0 0 2.143 2.143M2.053 3.582h4.072a4.5 4.5 0 1 1-4.5 4.5"
      stroke="#8E96A8"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ResetIcon
