import LeftGreenChevron from "../../images/svg/leftGreenChevron";
import { IconButton } from "@mui/material";
import Styles from './navHeader.module.css';

export default function NavHeaderLight(props) {
  const { navigate, headerName, customRightBtn, showBack = true, customTextStyle } = props;
  return (
    <div className={Styles.navHeader}>
      {showBack ? (
        <IconButton
          className={Styles.backBtn}
          onClick={() => {
            if (props?.onClick) props.onClick();
            else navigate(-1);
          }}
        >
          <LeftGreenChevron />
        </IconButton>
      ) : (
        <div style={{ color: "transparent" }}>XXXX</div>
      )}
      <div className={Styles.title} style={customTextStyle}>{headerName}</div>
      {customRightBtn}
    </div>
  );
}
