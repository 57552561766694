import React from "react"

const HomeIconWhite = (props) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.404 6.787 9.097.481A1.63 1.63 0 0 0 7.936 0c-.438 0-.85.17-1.161.481L.477 6.779c-.637.64-.636 1.68.003 2.317a1.625 1.625 0 0 0 1.142.482h.03v4.41c0 1.039.845 1.884 1.884 1.884h2.463a.674.674 0 0 0 .673-.673v-3.631c0-.297.241-.538.538-.538h1.452c.297 0 .538.241.538.538v3.63c0 .372.302.674.673.674h2.463a1.886 1.886 0 0 0 1.884-1.883V9.578h.011a1.644 1.644 0 0 0 1.173-2.79Z"
      fill="#fff"
    />
  </svg>
)

export default HomeIconWhite
