import { Button, IconButton, Stack } from "@mui/material";
import { Buffer } from "buffer";
import { useContext, useEffect, useState } from "react";
import { useMutation } from "react-apollo";
import { useNavigate } from "react-router-dom";
import TwoBtnAlert from "../../../components/alert/twoBtnAlert";
import { AppleBtn } from "../../../components/buttons/appleBtn";
import { GoogleBtn } from "../../../components/buttons/googleBtn";
import { GreenBgButton } from "../../../components/buttons/greenBgButton";
import { EmailField } from "../../../components/fields/emailField";
import { PasswordField } from "../../../components/fields/passwordField";
import { UserContext } from "../../../contexts/userContext";
import { AppleIcon } from "../../../images/svg/apple_icon";
import { BackIcon } from "../../../images/svg/back";
import ferretLogo from "../../../images/svg/ferret-logo.svg";
import { GoogleIcon } from "../../../images/svg/google-icon";
import {
  AUTHENTICATE_APPLE_USER,
  AUTHENTICATE_GOOGLE_USER,
  SIGN_IN_FERRET_USER,
} from "../../../queries/queries";
import { saveUserDataAndRedirectToInitialScreen } from "../../../root-navigation";
import { signInApple } from "../../../utils/apple-login-helper-web";
import { getDeviceDetails } from "../../../utils/DeviceDetails";
import { fetchFirebaseToken } from "../../../utils/firebase";
import { googleDefaultLogin } from "../../../utils/google-login-helper";
import { setFirebaseToken } from "../../../utils/safe-storage";
import Variables from "../../../utils/Variables";
import AppWalkthourgh from "../../components/appWalkthrough/appWalkthrough";
import Footer from "../../layout/Footer";
import Styles from "./login.module.css";

const LoginWeb = () => {
  const [checkFormValid, setCheckFormValid] = useState(false);
  const [email, setEmail] = useState();
  const [emailValid, setEmailValid] = useState({ status: null, msg: "" });
  const [password, setPassword] = useState();
  const [passwordValid, setPasswordValid] = useState({ status: null, msg: "" });
  const [accountExist, setAccountExist] = useState({ status: null, msg: "" });
  const [displayError, setDisplayError] = useState(false);
  const errorMessage = "Email or password incorrect";

  const { setUser } = useContext(UserContext);

  const navigate = useNavigate();

  useEffect(() => {
    fetchFirebaseToken(setFirebaseToken);
  }, []);

  useEffect(() => {
    setDisplayError(false);
  }, [password, email]);

  const [signInFerret, { loading: emailLoginLoader }] = useMutation(
    SIGN_IN_FERRET_USER,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        saveUserDataAndRedirectToInitialScreen(
          data.signInEmail,
          null,
          () => {},
          "login",
          null,
          setUser,
          navigate,
          true
        );
      },
      onError: (error) => {
        if (
          error &&
          error.graphQLErrors &&
          Array.isArray(error.graphQLErrors) &&
          error.graphQLErrors.length > 0
        ) {
          console.log("data=====onError", error);
          setDisplayError(true);
        }
      },
    }
  );

  const [authenticateGoogleUser, { loading: googleLoginLoader }] = useMutation(
    AUTHENTICATE_GOOGLE_USER,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        saveUserDataAndRedirectToInitialScreen(
          data.authenticateGoogleUser,
          null,
          () => {},
          "login",
          null,
          setUser,
          navigate,
          true
        );
      },
      onError: (error) => {
        if (
          error &&
          error.graphQLErrors &&
          Array.isArray(error.graphQLErrors) &&
          error.graphQLErrors.length > 0
        ) {
          console.log("data=====onError", error);
          setAccountExist({
            status: false,
            msg: error.graphQLErrors[0].message,
          });
        }
      },
    }
  );

  const onGoogleLogin = async (response) => {
    const deviceInfo = getDeviceDetails();
    authenticateGoogleUser({
      variables: {
        accessToken: response?.access_token,
        requestType: "LOGIN",
        deviceDetails: deviceInfo,
      },
    });
  };

  const [authenticateAppleUser, { loading: appleLoginLoader }] = useMutation(
    AUTHENTICATE_APPLE_USER,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        saveUserDataAndRedirectToInitialScreen(
          data.authenticateAppleUser,
          null,
          () => {},
          "login",
          null,
          setUser,
          navigate,
          true
        );
      },
      onError: (error) => {
        if (
          error &&
          error.graphQLErrors &&
          Array.isArray(error.graphQLErrors) &&
          error.graphQLErrors.length > 0
        ) {
          console.log("data=====onError", error.graphQLErrors[0].message);
          setAccountExist({
            status: false,
            msg: error.graphQLErrors[0].message,
          });
        }
      },
    }
  );

  const onAppleLogin = async () => {
    const response = await signInApple();
    const id_token = response.authorization?.id_token;
    if (id_token) {
      const { email, name } = response.user ?? [];
      let mEmail = email;
      let firstName = name?.firstName;
      let lastName = name?.lastName;

      if (mEmail == null) {
        const data = JSON.parse(
          Buffer.from(id_token.split(".")[1], "base64").toString("ascii")
        );
        mEmail = data.email;
        firstName = data.email
          .split("@")[0]
          .replaceAll(/[^\w\s]/g, " ")
          .replaceAll(/_/g, " ");
        lastName = " ";
      }
      const deviceInfo = getDeviceDetails();
      authenticateAppleUser({
        variables: {
          code: id_token,
          email: mEmail,
          name: firstName,
          lastName: lastName,
          requestType: "LOGIN",
          deviceDetails: deviceInfo,
        },
      });
    }
  };

  const handleLogin = async () => {
    setCheckFormValid(true);
    setDisplayError(false);
    if (emailValid.status && passwordValid.status) {
      const deviceInfo = getDeviceDetails();
      const data = {
        email: email,
        password: password,
        deviceDetails: deviceInfo,
      };

      signInFerret({
        variables: data,
      });
    }
  };

  const onGoogleClick = () => {
    const link =
      "https://play.google.com/store/apps/details?id=ai.ferret.prod.mobile";
    window.open(link, "_blank");
  };

  return (
    <Stack className={Styles.bodyLogin}>
      <div className={Styles.loginMain}>
        {Variables.isFromLandingpage && (
          <div className={Styles.loginBackButton}>
            <IconButton onClick={() => navigate(-1, { replace: true })}>
              <BackIcon />
            </IconButton>
          </div>
        )}

        <img src={ferretLogo} className={Styles.ferretLogo} alt="ferretLogo" />

        <div className={Styles.welcomeBackText}>Welcome Back</div>

        <div className={Styles.loginFormField}>
          <EmailField
            email={email}
            setEmail={setEmail}
            checkFormValid={checkFormValid}
            emailValid={emailValid}
            setEmailValid={setEmailValid}
          />
        </div>

        <div className={Styles.loginFormField}>
          <PasswordField
            password={password}
            setPassword={setPassword}
            checkFormValid={checkFormValid}
            passwordValid={passwordValid}
            setPasswordValid={setPasswordValid}
            showPasswordIcon={true}
          />
        </div>

        {displayError && (
          <div className={Styles.errorMessage}>{errorMessage}</div>
        )}

        <button
          className={Styles.forgotPasswordButton}
          onClick={() => {
            navigate("/forgotPassword");
          }}
        >
          Forgot Password?
        </button>

        <GreenBgButton
          onClick={handleLogin}
          fullWidth
          variant="contained"
          loading={googleLoginLoader || emailLoginLoader || appleLoginLoader}
        >
          <div>Log In</div>
        </GreenBgButton>

        <Button
          fullWidth
          variant="text"
          style={{
            marginTop: 16,
          }}
          onClick={onGoogleClick}
        >
          <div className={Styles.signupTxt}>Download the Android App</div>
        </Button>

        <div className={Styles.or}>OR</div>

        <GoogleBtn
          onClick={() => googleDefaultLogin(onGoogleLogin)}
          fullWidth
          variant="contained"
          startIcon={<GoogleIcon />}
          style={{
            marginTop: 16,
          }}
        >
          <div className={Styles.signinGoogleTxt}>Sign in with Google</div>
        </GoogleBtn>

        <AppleBtn
          onClick={onAppleLogin}
          fullWidth
          variant="contained"
          startIcon={<AppleIcon />}
          style={{
            marginTop: 16,
          }}
        >
          <div className={Styles.signinAppleTxt}>Sign in with Apple</div>
        </AppleBtn>

        <Button
          fullWidth
          variant="text"
          style={{
            marginTop: 16,
          }}
          onClick={() => navigate("/acceptTermsOfService")}
        >
          <div className={Styles.signupTxt}>Not a member yet? Sign up</div>
        </Button>

        {accountExist.status == false && (
          <TwoBtnAlert
            description={accountExist.msg}
            leftBtnTxt={"Cancel"}
            rightBtnTxt={"Sign Up"}
            onLeftBtnClick={() => {
              setAccountExist({ status: null, msg: "" });
            }}
            onRightBtnClick={() => {
              setAccountExist({ status: null, msg: "" });
              navigate("/acceptTermsOfService");
            }}
          />
        )}

        <div className={Styles.mainView}>
          <div className={Styles.titleView}>
            <span className={Styles.title}>ferreter </span>
            <span className={Styles.titleNoun}>(noun)</span>
          </div>
          <div>
            <span className={Styles.subtitle}>fer•ret•er</span>
          </div>
          <div className={Styles.definition}>
            <span className={Styles.definitionNumber}>1.</span>{" "}
            <span className={Styles.definitionContent}>
              A person who hunts with{" "}
              <span className={Styles.highlight}>ferrets.</span>
            </span>
          </div>
          <div className={Styles.definition}>
            <span className={Styles.definitionNumber}>2.</span>{" "}
            <span className={Styles.definitionContent}>
              One who uncovers or brings to light; one who{" "}
              <span className={Styles.highlight}>ferrets out</span>.
            </span>
          </div>
        </div>
      </div>
      {/* <NotAvailable /> */}
      <AppWalkthourgh />
      <div className={Styles.footer}>
        <Footer isLogin={true} isCenterCopyright={true} />
      </div>
    </Stack>
  );
};

export default LoginWeb;
