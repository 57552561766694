import React, {createContext, useState, useMemo} from 'react';
import {userInfo} from '../root-navigation';

export const UserContext = createContext();

export const UserContextProvider = ({children}) => {
  const [user, setUser] = useState(userInfo);
  const value = useMemo(() => ({user, setUser}), [user, setUser]);
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
