import React from 'react';
import Styles from './filterSection.module.css'

const FilterSection = (props) => {
  const { title, filters } = props;

  return (
    <div className={Styles.body}>
      <span className={Styles.sourceTitle}>{title}</span>
      <div className={Styles.scrollContainer}>
        <div className={Styles.filters}>{filters}</div>
      </div>
      <hr className={Styles.separator} />
    </div>
  )
}

export default FilterSection;
