import React from 'react';
import createStyle from './styles';

const Label = props => {
  const {text} = props;
  const style = createStyle();
  return (
    <span style={style.label}>
      {text}
    </span>
  );
};

export default Label;
