import AddOrgModal from "./addOrgModal";

export const checkCompanyInMetaData = (query, setShowAddOrgsModal, onRequestComplete, meta_data, isWebSavedSearch, openNotification) => {
  if(isWebSavedSearch){
    onRequestComplete(query)
    return false
  }
  let metaData = meta_data ? meta_data : query?.variables?.fullname?.meta_data && JSON.parse(query?.variables?.fullname?.meta_data)
  if (openNotification || (metaData && metaData?.Company && metaData?.Company?.length > 0)) {
    onRequestComplete(query)
    return false
  }
  else {
    return (<AddOrgModal
      setShowAddOrgsModal={setShowAddOrgsModal}
      onRequestComplete={onRequestComplete}
      query={query}
      metaData={metaData}
    />)
  }
}