const getNoResultMetaTags = (type)=>{
  switch(type){
    case 'noResultFound_details':
      return 'PROVIDED'
      case 'noResultFound_SearchType':
        return 'SearchType'
      case 'noResultFound_Person_Live':
        return 'Place'
      case 'noResultFound_Person_Live_Work':
        return 'Company'
      case 'noResultFound_Company_Called':
        return 'Name'
      case 'noResultFound_Company_Based':
          return 'Place'
      default:
        return 'notFound'
}
}

const shouldSearchQuestion = {
    question: '',
    attribute: 'noResultFound_details',
    options: [
      {
        item: 'Provide Details',
        type: 'radio_button',
        status: false,
        value: 'Provide Details',
        identifier: 'providedetails',
      },
      {
        item: 'Not Now',
        type: 'radio_button',
        status: false,
        value: 'Not Now',
        identifier: 'searchNotNow',
      },
    ],
  };

const mainQuestion = {
    question: `Are you searching for a ...?`,
    attribute: 'noResultFound_SearchType',
    options: [
      {
        item: 'Person',
        type: 'radio_button',
        status: false,
        value: 'Person',
        identifier: 'Person',
      },
      {
        item: 'Company',
        type: 'radio_button',
        status: false,
        value: 'Company',
        identifier: 'Company',
      },
      {
        item: 'Place',
        type: 'radio_button',
        status: false,
        value: 'Place',
        identifier: 'Place',
      },
      {
        item: 'Topic',
        type: 'radio_button',
        status: false,
        value: 'Topic',
        identifier: 'Topic',
      },
    ],
  }

const person = [
  {
    question: `Where do they live?`,
    attribute: 'noResultFound_Person_Live',
    options: [
      {
        item: "I don't know",
        status: false,
        type: 'radio_button',
        value: '',
        identifier: 'placeIdontknow',
      },
      {
        item: 'Other',
        type: 'textbox',
        status: false,
        value: '',
        identifier: 'placeother',
      },
    ],
  },
  {
    question: `Where do they work?`,
    attribute: 'noResultFound_Person_Live_Work',
    options: [
      {
        item: "I don't know ",
        status: false,
        type: 'radio_button',
        value: '',
        identifier: 'workidontknow',
      },
      {
        item: 'Other',
        type: 'textbox',
        status: false,
        value: '',
        identifier: 'workother',
      },
    ],
  },
];

const company = (searchName)=>[
  {
    question: `What is the company called?`,
    attribute: 'noResultFound_Company_Called',
    options: [
      {
        item: searchName,
        status: false,
        type: 'radio_button',
        value: searchName,
        identifier: 'companyNameval',
      },
      {
        item: 'Other',
        type: 'textbox',
        status: false,
        value: '',
        identifier: 'companynameother',
      },
    ],
  },
  {
    question: `Where is the company based?`,
    attribute: 'noResultFound_Company_Based',
    options: [
      {
        item: "I don't know ",
        status: false,
        type: 'radio_button',
        value: '',
        identifier: 'companybasedidontknow',
      },
      {
        item: 'Other',
        type: 'textbox',
        status: false,
        value: '',
        identifier: 'companybasedother',
      },
    ],
  },
];

export {shouldSearchQuestion, mainQuestion, person, company, getNoResultMetaTags};