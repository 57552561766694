
const createStyle = (theme) => {
  return {
    container: {
      paddingTop: 8,
      paddingBottom: 4,
      marginRight: 12,
      flexDirection: 'row',
      display: "flex",
      whiteSpace: "nowrap",
      alignItems: "center"
    },
    containerCount: {
      alignItems:'center',
      flexDirection: 'row',
    },
    countText: {
      fontSize: 14,
      color: '#222F4B',
      marginEnd: 5,
    },
    lableText: {
      letterSpacing: -0.01,
      fontSize: 14,
      marginStart: 4,
      color: '#8E96A8',
      fontWeight: '600',
      fontSize: 14,
      fontStyle: 'normal',
      fontFamily: "Proxima Nova",
    },
    checkBox: {
      margin: 0,
      marginLeft: 0,
      marginRight: 0,
      padding: 0
    },
  }
};

export default createStyle;