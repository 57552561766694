import React, { useState } from "react";
import { useMutation } from "react-apollo";
import { FORGOT_PASSWORD_REQUEST_OTP } from "../../../queries/queries";
import ForgotEmailIllustrationLogo from "../../../images/svg/forgot-email-Illustration";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
import { BackIcon } from "../../../images/svg/back";
import { EmailField } from "../../../components/fields/emailField";
import { GreenBgButton } from "../../../components/buttons/greenBgButton";

import Styles from "./forgotPassword.module.css";
import AppWalkthourgh from "../../components/appWalkthrough/appWalkthrough";
import Footer from "../../layout/Footer";

const TextInfo = () => {
  return (
    <>
      <h1 className={Styles.textQuestion}>Forgot your password?</h1>
      <p className={Styles.textContent}>
        Don't worry!, It happens... To reset your password, enter your address
        associated with your Ferret account
      </p>
    </>
  );
};

export default function ForgotPassword({}) {
  const navigate = useNavigate();

  const [email, setEmail] = useState();
  const [emailValid, setEmailValid] = useState({ status: null, msg: "" });
  const [checkFormValid, setCheckFormValid] = useState(false);

  const [sendOtpToFerretUser, { loading }] = useMutation(
    FORGOT_PASSWORD_REQUEST_OTP,
    {
      fetchPolicy: "no-cache",
      onCompleted: ({ requestOtp }) => {
        navigate("/forgotPasswordOtpVerify", {
          state: {
            authT: requestOtp.authToken,
            userEmail: email,
          },
        });
      },
      onError: (error) => {
        if (
          error &&
          error.graphQLErrors &&
          Array.isArray(error.graphQLErrors) &&
          error.graphQLErrors.length > 0
        ) {
          console.log("data=====onError", error);
          setEmailValid({ status: false, msg: error.graphQLErrors?.[0]?.message ?? "Email incorrect" });
          setTimeout(() => {
            setEmailValid({ status: true, msg: "" });
          }, 3000);
        }
      },
    }
  );

  const onClick = () => {
    setCheckFormValid(true);
    if (emailValid.status) {
      sendOtpToFerretUser({
        variables: {
          email: email,
        },
      });
    }
  };

  return (
    <Stack className={Styles.bodyForgotPassoword}>
      <div className={Styles.forgotPasswordMain}>
        <div className={Styles.content}>
          <div className={Styles.navHeaderTitle}>Password Reset</div> 
          <ForgotEmailIllustrationLogo />
          <TextInfo />
          <div className={Styles.emailInput}>
            <EmailField
              email={email}
              setEmail={setEmail}
              checkFormValid={checkFormValid}
              emailValid={emailValid}
              setEmailValid={setEmailValid}
              placeholder={"email@address.com"}
            />
          </div>
          <GreenBgButton
            onClick={onClick}
            fullWidth
            loading={loading}
            variant="contained"
          >
            <span className={Styles.btnTxt}>Send Email</span>
          </GreenBgButton>
        </div>
      </div>

      {/* <NotAvailable /> */}
      <AppWalkthourgh />
      <div className={Styles.footer}>
        <Footer isLogin={true} isCenterCopyright={true} />
      </div>
    </Stack>
  );
}
