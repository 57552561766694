import * as React from 'react';

export default class ImportedContactsLogo extends React.Component {
  render() {
    return (
      <svg width={this.props?.style?.width ?? 242} height={this.props?.style?.height ?? 228} viewBox="0 0 244 232" {...this.props}>
        <g transform="translate(0 4)" fill="none" fillRule="evenodd">
          <path
            d="M206.832 121.523c-6.88 9.61-4.609 23.33-.633 34.258 1.332 3.659 2.845 7.529 2.154 11.51-.843 4.865-4.807 8.512-8.927 10.497-7.512 3.62-16.385 3.042-23.104-1.519-5.798-3.935-9.88-10.476-15.79-14.224-9.892-6.27-22.735-3.47-33.744 1.318-7.79 3.387-16.321 7.749-23.912 4.621-5.341-2.2-8.938-7.802-10.83-13.79-.914-2.89-1.554-6.029-3.374-8.292-1.082-1.345-2.512-2.282-3.983-3.066-13.422-7.156-30.687-2.684-43.7-10.668-8.785-5.392-14.253-15.856-16.525-26.857-2.271-11.001-1.71-22.614-.804-34.028.643-8.111 1.66-16.78 6.45-23.154 5.065-6.739 13.555-9.417 21.148-8.362 7.592 1.056 14.399 5.35 20.353 10.475 7.448 6.394 14.3 14.422 23.564 16.85 6.31 1.653 13.013.483 19.572-.035 10.964-.862 21.889.131 32.748 1.253 10.394 1.073 20.861 2.272 30.706 5.791 6.966 2.49 12.489 7.197 19.048 10.414 4.275 2.097 8.978 2.583 13.002 5.292 4.965 3.337 9.453 9.247 7.996 16.341-1.375 6.743-7.76 10.275-11.415 15.375z"
            fill="#00A585"
            fillRule="nonzero"
            opacity={0.1}
          />
          <ellipse
            fill="#48D2A0"
            opacity={0.162}
            cx={206.162}
            cy={30.317}
            rx={4.325}
            ry={4.324}
          />
          <ellipse
            fill="#48D2A0"
            opacity={0.17}
            cx={192.323}
            cy={51.071}
            rx={2.595}
            ry={2.594}
          />
          <circle
            fill="#48D2A0"
            opacity={0.301}
            cx={215.099}
            cy={66.349}
            r={4.036}
          />
          <circle
            fill="#48D2A0"
            opacity={0.184}
            cx={4.036}
            cy={37.722}
            r={4.036}
          />
          <ellipse
            fill="#48D2A0"
            opacity={0.15}
            cx={11.912}
            cy={67.383}
            rx={2.595}
            ry={2.594}
          />
          <g
            opacity={0.364}
            stroke="#48D2A0"
            strokeLinecap="round"
            strokeWidth={5.929}>
            <path d="M54.692 14.228l-5.69-9.857M46.919 12.145l9.857-5.69" />
          </g>
          <g
            opacity={0.464}
            stroke="#48D2A0"
            strokeLinecap="round"
            strokeWidth={5.082}>
            <path d="M101.485 200.023l10.956 6.326M110.126 197.707l-6.326 10.956" />
          </g>
          <ellipse
            fill="#48D2A0"
            opacity={0.224}
            cx={72.085}
            cy={223.676}
            rx={4.325}
            ry={4.324}
          />
          <ellipse
            fill="#48D2A0"
            opacity={0.135}
            cx={35.628}
            cy={200.528}
            rx={2.595}
            ry={2.594}
          />
          <g
            opacity={0.391}
            stroke="#48D2A0"
            strokeLinecap="round"
            strokeWidth={5.082}>
            <path d="M231.27 133.843l8.024-2.15M234.213 128.76l2.15 8.024" />
          </g>
          <circle fill="#11987B" cx={120.698} cy={111.963} r={49.55} />
          <path
            d="M146.72 92.57a5.128 5.128 0 00-7.303 0l-27.585 27.971-10.344-10.49a4.842 4.842 0 00-6.896 0c-1.896 1.923-1.896 5.07 0 6.993l14.199 14.398a4.842 4.842 0 006.895 0c.035-.035.064-.073.097-.108.035-.034.072-.063.106-.098l30.83-31.262c2.008-2.036 2.008-5.369 0-7.405"
            fill="#FFF"
          />
        </g>
      </svg>
    );
  }
}
