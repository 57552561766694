import * as React from "react"

function ForgotEmailIllustrationLogo(props) {
    return (
        <svg width="355px" height="180px" viewBox="0 0 355 180" {...props}>
            <defs>
                <polygon id="path-1" points="0.178100016 0.0272000024 43.6194039 0.0272000024 43.6194039 42.2182038 0.178100016 42.2182038"></polygon>
                <polygon id="path-3" points="0.077860007 0.187400017 72.6554665 0.187400017 72.6554665 145.742413 0.077860007 145.742413"></polygon>
            </defs>
            <g id="Sign-up" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Password-Recovery" transform="translate(-10.000000, -108.000000)">
                    <g id="Forgot-Email-Illustration" transform="translate(10.000000, 108.000000)">
                        <rect id="Content-box" x="0" y="0" width="355" height="180"></rect>
                        <g id="Bg" transform="translate(75.000000, 17.000000)">
                            <path d="M198.129059,81.192653 C191.277808,90.5938286 193.538809,104.012655 197.499059,114.703405 C198.825384,118.282155 200.332309,122.067406 199.644559,125.961156 C198.804559,130.721331 194.856559,134.287657 190.752808,136.230157 C183.271558,139.770407 174.434057,139.205157 167.742057,134.744582 C161.967056,130.894581 157.901806,124.496406 152.014806,120.830156 C142.162305,114.696405 129.371554,117.436905 118.406053,122.119906 C110.648303,125.432656 102.150302,129.699331 94.5903018,126.640156 C89.2703014,124.487656 85.6880512,119.008406 83.803301,113.151155 C82.893301,110.323155 82.2563009,107.253655 80.4433008,105.04008 C79.3653007,103.723904 77.9408006,102.807079 76.4760506,102.040404 C63.1077996,95.0404039 45.9122985,99.4154042 32.9517976,91.6051537 C24.201797,86.3306533 18.7557966,76.0949026 16.4930465,65.3341519 C14.2302963,54.5734011 14.7902963,43.2141504 15.6915464,32.0491496 C16.3320465,24.1146491 17.3452965,15.6358985 22.1157968,9.40064807 C27.1610472,2.80839762 35.6170478,0.188647445 43.1787983,1.22132252 C50.7405488,2.25364759 57.5200493,6.45364787 63.4507997,11.4673982 C70.8690502,17.7218986 77.6940506,25.5741492 86.9200513,27.9488993 C93.2043017,29.5658994 99.8805521,28.4213994 106.413303,27.9156493 C117.333303,27.0721493 128.214804,28.0433993 139.029805,29.1406494 C149.382806,30.1906495 159.807556,31.3631496 169.612807,34.8053998 C176.551557,37.2414 182.051808,41.8456503 188.584558,44.9921505 C192.842308,47.0431506 197.527059,47.5191507 201.534559,50.1686508 C206.480059,53.4324011 210.94956,59.2144015 209.49881,66.1531519 C208.12856,72.7489024 201.769059,76.2034026 198.129059,81.192653" id="Fill-1" fill="#D8EFE8"></path>
                            <ellipse id="Oval-11" fill="#48D2A0" opacity="0.162430037" cx="79.8293651" cy="4.65689863" rx="3.82936508" ry="3.82874864"> </ellipse>
                            <ellipse id="Oval-11" fill="#48D2A0" opacity="0.170184235" cx="190.297619" cy="32.1253992" rx="2.29761905" ry="2.29724919"> </ellipse>
                            <ellipse id="Oval-11" fill="#48D2A0" opacity="0.301364272" cx="37.5740741" cy="115.401649" rx="3.57407407" ry="3.57349873"> </ellipse>
                            <g id="Cross" opacity="0.400000006" transform="translate(0.053848, 12.591290)" stroke="#48D2A0" stroke-linecap="round" stroke-width="5.25">
                                <line x1="1.31237055e-12" y1="5.27110798" x2="10.0783379" y2="5.27110798" id="Line"> </line>
                                <line x1="-0.000217632768" y1="5.27105455" x2="10.0781203" y2="5.27105455" id="Line" transform="translate(5.038951, 5.271055) rotate(-270.000000) translate(-5.038951, -5.271055) "> </line>
                            </g>
                            <g id="Cross" opacity="0.400000006" transform="translate(202.005294, 101.483882)" stroke="#48D2A0" stroke-linecap="round" stroke-width="3.67499994">
                                <line x1="9.18659372e-13" y1="3.68977552" x2="7.0548364" y2="3.68977552" id="Line"> </line>
                                <line x1="-0.000152342935" y1="3.68973812" x2="7.05468406" y2="3.68973812" id="Line" transform="translate(3.527266, 3.689738) rotate(-270.000000) translate(-3.527266, -3.689738) "> </line>
                            </g>
                        </g>
                        <g id="Illustration" transform="translate(87.000000, 12.000000)">
                            <path d="M159.348654,120.485391 C155.714054,127.578591 151.103854,134.438392 145.270853,140.646993 C145.141453,140.787193 145.010653,140.923793 144.878453,141.062993 L131.058452,134.520392 C131.142852,134.379792 131.235652,134.222192 131.335252,134.049592 C137.271852,123.892391 173.108255,61.9313855 171.975855,47.8979843 C172.107855,49.0555844 176.355456,87.3317878 159.348654,120.485391" id="Fill-1" fill="#E6E6E6"></path>
                            <g id="Group-5" transform="translate(136.800012, 100.012609)">

                                <g id="Clip-4"></g>
                                <path d="M11.137101,41.8966037 C10.943701,42.0066038 10.745301,42.1142038 10.5457009,42.2182038 L0.178100016,37.3100033 C0.323100029,37.2074033 0.491700044,37.0856033 0.685900061,36.9490033 C3.86230035,34.6684031 13.3071012,27.8262025 22.548702,20.4728018 C32.4803029,12.5706011 42.1791038,4.08000036 43.6195039,0.0272000024 C43.3269039,0.956600086 34.2365031,29.2066026 11.137101,41.8966037" id="Fill-3" fill="#E6E6E6" mask="url(#mask-2)"></path>
                            </g>
                            <path d="M53.4000048,138.038312 C82.8920074,138.038312 106.80001,140.405513 106.80001,143.325513 C106.80001,146.245513 82.8920074,148.612513 53.4000048,148.612513 C23.9080021,148.612513 0,146.245513 0,143.325513 C0,140.405513 23.9080021,138.038312 53.4000048,138.038312" id="Fill-6" fill="#E6E6E6"></path>
                            <path d="M24.1604407,113.81019 C32.997043,113.81019 40.1604407,120.973591 40.1604407,129.81019 C40.1604407,138.646792 32.997043,145.81019 24.1604407,145.81019 C15.3238414,145.81019 8.16044073,138.646792 8.16044073,129.81019 C8.16044073,120.973591 15.3238414,113.81019 24.1604407,113.81019" id="Fill-8" fill="#12A485"></path>
                            <path d="M30.5695427,135.303752 L17.7513416,135.303752 C17.2459415,135.303152 16.8363415,134.893352 16.8357415,134.388152 L16.8357415,125.314551 L23.7779421,130.896352 C24.0007421,131.075152 24.3177422,131.075752 24.5409422,130.897752 L31.4851428,125.358351 L31.4851428,134.388152 C31.4845428,134.893352 31.0749428,135.303152 30.5695427,135.303752 L30.5695427,135.303752 Z M30.5695427,124.316551 C30.6459427,124.316951 30.7221427,124.326951 30.7961428,124.346351 L24.1619422,129.638552 L17.5663416,124.335551 C17.6273416,124.322951 17.6891416,124.316551 17.7513416,124.316551 L30.5695427,124.316551 Z M30.5695427,123.095951 L17.7513416,123.095951 C16.5721415,123.097351 15.6163414,124.052951 15.6151414,125.232351 L15.6151414,134.388152 C15.6163414,135.567352 16.5719415,136.523152 17.7513416,136.524552 L30.5695427,136.524552 C31.7487428,136.523152 32.7045429,135.567352 32.7059429,134.388152 L32.7059429,125.232351 C32.7045429,124.052951 31.7487428,123.097351 30.5695427,123.095951 L30.5695427,123.095951 Z" id="Fill-10" fill="#FFFFFF"></path>
                            <polygon id="Fill-12" fill="#2F2E41" points="68.0814461 89.256088 55.0078449 90.0966881 51.5012446 77.6844869 62.9920456 74.9916867"></polygon>
                            <path d="M85.9081077,105.510189 L89.488108,105.539589 C90.8637081,105.550989 91.9881082,104.445189 91.9995511,103.069589 C92.0003082,102.930389 91.9899082,102.791189 91.9677082,102.653789 C91.7495082,101.295589 90.4715081,100.371589 89.113308,100.589789 C88.8677079,100.629389 88.6295079,100.705389 88.4065079,100.815389 L85.0303076,102.481189 L73.4831066,105.056389 L68.5361061,99.9985889 L65.6943059,104.678989 L72.3699065,109.83639 L85.9081077,105.510189 Z" id="Fill-14" fill="#FFB8B8"></path>
                            <path d="M85.2371076,142.498093 L81.0193072,143.309293 C78.138107,134.227292 75.0167067,125.627091 75.0169067,118.975091 C70.9397063,123.512891 65.8835059,126.109091 59.9297054,126.924291 L60.5787054,116.86629 L65.4455059,114.27049 L72.8941065,110.83269 C73.5461066,110.53169 74.2497066,110.35769 74.9669067,110.31989 C77.854907,110.16809 80.3925072,112.21889 80.8495072,115.07469 L85.2371076,142.498093 Z" id="Fill-16" fill="#FFB8B8"></path>
                            <path d="M84.5882876,138.442412 C84.5882876,138.442412 84.9926876,138.271012 86.6078877,140.384613 C86.6078877,140.384613 91.7660882,141.345213 90.9128881,143.671013 C90.0596881,145.996813 83.4276875,145.923613 82.7974874,145.568413 C82.1672873,145.213213 77.1508869,145.220413 77.4976869,143.640013 C77.844287,142.059813 79.8424871,140.293613 79.8424871,140.293613 C79.8424871,140.293613 80.7612872,142.155213 82.1892873,142.163213 C84.1014875,142.173413 84.7504876,140.551213 84.4258875,138.929012 C84.4228875,138.913812 84.6004876,138.452212 84.5882876,138.442412" id="Fill-18" fill="#2F2E41"></path>
                            <path d="M52.1320447,132.910772 C52.1320447,132.910772 52.7640447,134.638172 49.9188445,135.678972 C49.9188445,135.678972 47.1280442,140.962773 44.887644,139.247772 C42.6472438,137.532372 44.972244,130.345372 45.5720441,129.780372 C46.1716441,129.215172 47.8620443,123.757971 49.4630444,124.670171 C51.0640446,125.582171 52.3080447,128.352971 52.3080447,128.352971 C52.3080447,128.352971 47.1530442,130.008372 49.5916444,133.839972 C49.5916444,133.839972 50.6548445,133.911972 52.1320447,132.910772" id="Fill-20" fill="#2F2E41"></path>
                            <path d="M58.0277452,76.4695668 C60.9615455,76.4695668 63.3399457,78.847967 63.3399457,81.7817673 C63.3399457,84.7155676 60.9615455,87.0939678 58.0277452,87.0939678 C55.0939449,87.0939678 52.7155447,84.7155676 52.7155447,81.7817673 C52.7155447,78.847967 55.0939449,76.4695668 58.0277452,76.4695668" id="Fill-22" fill="#FFB8B8"></path>
                            <path d="M62.6064456,95.8578486 L53.3596448,95.8578486 L51.4128446,90.1798481 C54.7876449,89.149648 53.9994448,86.2242477 53.3596448,83.3662475 L62.6064456,83.3662475 C60.8138454,86.9418478 58.5866452,92.0018482 62.9310456,92.9376483 L62.6064456,95.8578486 Z" id="Fill-24" fill="#FFB8B8"></path>
                            <path d="M65.0398458,125.707451 L63.3588457,126.985251 C61.8878455,128.103251 59.9346454,128.357651 58.2264452,127.654251 C53.4112448,124.044851 49.4842444,125.126651 46.3838441,130.574252 C42.6648438,126.377451 42.2536438,120.298651 45.5726441,114.02725 L63.9854457,114.43285 L67.7166461,113.29705 L65.0398458,125.707451 Z" id="Fill-26" fill="#2F2E41"></path>
                            <path d="M58.9078253,144.503353 L58.9078253,144.503353 C56.7302251,145.173553 54.3854249,144.185553 53.3438248,142.159153 C51.6892246,140.701553 50.2930245,138.614952 49.0820244,136.077352 C48.2130243,134.215952 47.4670242,132.299552 46.8486242,130.340752 C46.0106241,127.752751 47.4118242,124.925351 50.0196245,124.151151 C50.0970245,124.128151 50.1754245,124.106551 50.2550245,124.085951 C53.7362248,123.192151 57.3112251,125.200151 58.5012252,128.591551 L61.9614255,138.453352 C62.8210256,140.903353 61.5318255,143.586353 59.0818253,144.446153 C59.0242253,144.466153 58.9662253,144.485353 58.9078253,144.503353" id="Fill-28" fill="#FFB8B8"></path>
                            <path d="M48.4927843,134.954532 C48.4927843,134.954532 52.5483847,139.496932 53.3595848,142.092333" id="Fill-30" fill="#000000"></path>
                            <path d="M32.7632829,113.51741 L31.4058828,116.83021 C30.8842828,118.103211 31.4934828,119.557811 32.7664829,120.079411 C32.8952829,120.132211 33.028283,120.174011 33.164083,120.204811 C34.5062831,120.506411 35.8388832,119.663211 36.1406832,118.321211 C36.1952832,118.078611 36.2132832,117.829211 36.1938832,117.581011 L35.9014832,113.82781 L37.8006834,102.150209 L44.334284,99.4362089 L41.0448837,95.0592085 L33.776283,99.3410089 L32.7632829,113.51741 Z" id="Fill-32" fill="#FFB8B8"></path>
                            <path d="M65.2021458,114.02719 C60.5409454,121.856591 53.0447447,120.482391 45.085944,114.35159 C48.4425443,111.84199 46.3837441,104.617989 43.4637439,104.131389 C40.4441436,103.628189 40.0811436,97.4529887 38.9213435,94.3977884 L53.0351447,89.206388 L56.8475451,92.2075882 L60.6597454,91.3153882 L62.2227456,91.8699882 C64.3059457,92.6091883 65.7089459,94.5669885 65.7391459,96.7773887 C62.5363456,101.404589 60.1653454,106.34039 65.2021458,114.02719" id="Fill-34" fill="#12A485"></path>
                            <path d="M66.986626,107.70043 C66.986626,107.70043 66.2682259,103.996229 63.9102257,105.158829 C61.5520255,106.32123 61.3898255,103.076829 61.3898255,103.076829 L63.2554257,92.2886283 L70.8800263,100.400229 L66.986626,107.70043 Z" id="Fill-36" fill="#12A485"></path>
                            <path d="M62.9894656,74.9449467 C62.9894656,74.9449467 65.6816659,75.9545468 65.7192659,79.5385471 C65.7178659,80.1413472 65.6318659,80.7413472 65.4642659,81.3207473 C65.2834658,81.9777473 65.1302658,83.4055475 66.2910659,85.3207476 C67.049666,86.5639477 67.7340661,87.8507479 68.3406661,89.174748 L65.2970658,89.441348 L64.8386658,84.3955475 C64.6164658,81.9491473 64.0352657,79.5489471 63.1138656,77.2717469 L63.0946656,77.2241469 C63.0946656,77.2241469 61.6778655,79.6367471 59.0270653,79.5387471 L60.0088654,78.626947 C60.0088654,78.626947 56.6764651,79.8483471 54.8682649,80.0833472 C54.2418648,80.1649472 53.8002648,80.7389472 53.8816648,81.3653473 C53.9114648,81.5935473 54.0094648,81.8075473 54.1626648,81.9791473 C54.1730648,81.9907473 54.1838648,82.0025473 54.1946648,82.0145473 C55.2888649,83.1925474 57.4630651,85.9277477 56.060265,87.9617479 C54.6576649,89.995548 55.772865,90.6057481 55.772865,90.6057481 L53.7318648,89.392348 L52.8060647,88.7893479 L52.7780647,89.546748 L47.8758643,88.7963479 C47.8758643,88.7963479 46.5572642,88.5579479 49.5168644,85.4439476 C49.5168644,85.4439476 50.3132645,84.2217475 50.0250645,83.1313474 C49.7856645,82.3019474 49.7472644,81.4275473 49.9124645,80.5803472 C50.6802645,77.0539469 53.4382648,70.0887463 62.9894656,74.9449467" id="Fill-38" fill="#2F2E41"></path>
                            <g id="Group-42" transform="translate(87.200008, 0.012600)">

                                <g id="Clip-41"></g>
                                <path d="M72.6554665,34.7764031 L71.8554664,34.7764031 L71.8554664,12.8674012 C71.8554664,5.86440052 66.1782659,0.187400017 59.1754653,0.187400017 L12.7580611,0.187400017 C5.75506051,0.187400017 0.077860007,5.86440052 0.077860007,12.8674012 L0.077860007,133.062212 C0.077860007,140.065213 5.75506051,145.742413 12.7580611,145.742413 L59.1752653,145.742413 C66.1780659,145.742413 71.8550664,140.065213 71.8550664,133.062212 L71.8550664,50.3716045 L72.6554665,50.3716045 L72.6554665,34.7764031 Z" id="Fill-40" fill="#3F3D56" mask="url(#mask-4)"></path>
                            </g>
                            <path d="M146.886933,3.49866031 L140.827933,3.49866031 C141.766733,5.79906052 140.662933,8.42526075 138.362332,9.36406084 C137.822732,9.58426086 137.245332,9.69746087 136.662332,9.69746087 L110.07053,9.69746087 C107.58593,9.69726087 105.571729,7.68266069 105.571929,5.19806046 C105.572129,4.61546041 105.685129,4.03866036 105.905129,3.49866031 L100.245129,3.49866031 C95.0153285,3.49866031 90.7755281,7.73886069 90.7755281,12.9688612 L90.7755281,12.9688612 L90.7755281,132.986472 C90.7755281,138.216672 95.0153285,142.456273 100.245129,142.456273 L146.885133,142.456273 C152.115134,142.456273 156.354734,138.216672 156.354734,132.986472 L156.354734,12.9682612 C156.354734,7.73826069 152.115134,3.49866031 146.885133,3.49866031 L146.886933,3.49866031 Z" id="Fill-43" fill="#FFFFFF"></path>
                            <path d="M102.550989,106.45961 C102.059389,106.45961 101.659389,106.91621 101.659389,107.47721 C101.659389,108.03841 102.059389,108.49481 102.550989,108.49481 L144.579593,108.49481 C145.070993,108.49481 145.470993,108.03841 145.470993,107.47721 C145.470993,106.91621 145.070993,106.45961 144.579593,106.45961 L102.550989,106.45961 Z" id="Fill-45" fill="#E6E6E6"></path>
                            <path d="M102.550989,94.3848084 C102.059389,94.3848084 101.659389,94.8414085 101.659389,95.4024085 C101.659389,95.9636086 102.059389,96.4200086 102.550989,96.4200086 L144.579593,96.4200086 C145.070993,96.4200086 145.470993,95.9636086 145.470993,95.4024085 C145.470993,94.8414085 145.070993,94.3848084 144.579593,94.3848084 L102.550989,94.3848084 Z" id="Fill-47" fill="#E6E6E6"></path>
                            <path d="M102.550989,100.490269 C102.059389,100.490269 101.659389,100.946869 101.659389,101.507869 C101.659389,102.069069 102.059389,102.525469 102.550989,102.525469 L120.635991,102.525469 C121.127391,102.525469 121.527391,102.069069 121.527391,101.507869 C121.527391,100.946869 121.127391,100.490269 120.635991,100.490269 L102.550989,100.490269 Z" id="Fill-49" fill="#E6E6E6"></path>
                            <path d="M110.65293,24.6650622 L136.815532,24.6650622 C137.417332,24.6656622 137.904932,25.1532622 137.905732,25.7550623 L137.905732,53.0024647 C137.904932,53.6042648 137.417332,54.0918648 136.815532,54.0926648 L110.65293,54.0926648 C110.05113,54.0918648 109.56353,53.6042648 109.56273,53.0024647 L109.56273,25.7550623 C109.56353,25.1532622 110.05113,24.6656622 110.65293,24.6650622" id="Fill-51" fill="#12A485"></path>
                            <path d="M131.240832,48.4737644 C131.649632,48.4739643 131.981032,48.1427643 131.981232,47.7339643 C131.981432,47.3251642 131.650232,46.9937642 131.241632,46.9935641 L131.240832,46.9935641 L116.22763,46.9935641 C115.81903,46.9933642 115.48723,47.3245642 115.48703,47.7333643 C115.48683,48.1419643 115.81823,48.4735643 116.22703,48.4737644 L116.22763,48.4737644 L131.240832,48.4737644 Z" id="Fill-53" fill="#FFFFFF"></path>
                            <path d="M131.240832,44.1133039 C131.649632,44.1131039 131.980632,43.7815039 131.980432,43.3729039 C131.980232,42.9643038 131.649232,42.6333038 131.240832,42.6331037 L116.22763,42.6331037 C115.81903,42.6329038 115.48723,42.9641038 115.48703,43.3729039 C115.48683,43.7815039 115.81823,44.1131039 116.22703,44.1133039 L116.22763,44.1133039 L131.240832,44.1133039 Z" id="Fill-55" fill="#FFFFFF"></path>
                            <path d="M123.734191,30.2839227 C126.306991,30.2839227 128.392591,32.3697229 128.392591,34.9423231 C128.392591,37.5153234 126.306991,39.6009235 123.734191,39.6009235 C121.161391,39.6009235 119.075591,37.5153234 119.075591,34.9423231 C119.078591,32.3709229 121.162591,30.2869227 123.734191,30.2839227" id="Fill-57" fill="#FFFFFF"></path>
                            <path d="M99.2312489,79.7254271 L147.638853,79.7254271 C148.219853,79.7254271 148.690653,80.2612272 148.690653,80.9222272 L148.690653,88.0674279 C148.690653,88.7284279 148.219853,89.264228 147.638853,89.264228 L99.2312489,89.264228 C98.6504488,89.264228 98.1794488,88.7284279 98.1794488,88.0674279 L98.1794488,80.9222272 C98.1794488,80.2612272 98.6504488,79.7254271 99.2312489,79.7254271" id="Fill-59" fill="#FFFFFF"></path>
                            <path d="M99.2312489,79.7254271 L147.638853,79.7254271 C148.219853,79.7254271 148.690653,80.2612272 148.690653,80.9222272 L148.690653,88.0674279 C148.690653,88.7284279 148.219853,89.264228 147.638853,89.264228 L99.2312489,89.264228 C98.6504488,89.264228 98.1794488,88.7284279 98.1794488,88.0674279 L98.1794488,80.9222272 C98.1794488,80.2612272 98.6504488,79.7254271 99.2312489,79.7254271 Z" id="Stroke-61" stroke="#2F2E41" stroke-width="0.5"></path>
                            <path d="M99.2312489,64.8673258 L147.638853,64.8673258 C148.219853,64.8673258 148.690653,65.4033258 148.690653,66.0643259 L148.690653,73.2093265 C148.690653,73.8705266 148.219853,74.4063267 147.638853,74.4063267 L99.2312489,74.4063267 C98.6504488,74.4063267 98.1794488,73.8705266 98.1794488,73.2093265 L98.1794488,66.0643259 C98.1794488,65.4033258 98.6504488,64.8673258 99.2312489,64.8673258" id="Fill-63" fill="#FFFFFF"></path>
                            <path d="M99.2312489,64.8673258 L147.638853,64.8673258 C148.219853,64.8673258 148.690653,65.4033258 148.690653,66.0643259 L148.690653,73.2093265 C148.690653,73.8705266 148.219853,74.4063267 147.638853,74.4063267 L99.2312489,74.4063267 C98.6504488,74.4063267 98.1794488,73.8705266 98.1794488,73.2093265 L98.1794488,66.0643259 C98.1794488,65.4033258 98.6504488,64.8673258 99.2312489,64.8673258 Z" id="Stroke-65" stroke="#2F2E41" stroke-width="0.5"></path>
                            <path d="M145.403533,128.491451 L102.064929,128.491451 C101.213129,128.491451 100.522529,127.800851 100.522529,126.949051 L100.522529,121.808251 C100.522529,120.956451 101.213129,120.265851 102.064929,120.265851 L145.403533,120.265851 C146.255533,120.265851 146.945933,120.956451 146.945933,121.808251 L146.945933,126.949051 C146.945933,127.800851 146.255533,128.491451 145.403533,128.491451" id="Fill-67" fill="#12A485"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default ForgotEmailIllustrationLogo