import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-apollo'
import { SAVE_ADHOC_SEARCH } from '../../../queries/queries'
import Variables from '../../../utils/Variables'


const SaveAdHocQuery = () => {
    const [saveAdhocSearch, { data, loading, error }] = useMutation(SAVE_ADHOC_SEARCH, {
        fetchPolicy: 'no-cache'
    })

    const saveSaveAdHoc = reqVal => {  saveAdhocSearch(reqVal); }

    const saveAdHocAndParse = (saveSearchdata,initialSearchString,recentSearchId) => {
        
        const {
            Company = '',
            Location = '',
            Nationality = '',
            Person = '',
            Place = '',
        } = saveSearchdata;

        let name = Person && Person !== '' ? Person : initialSearchString;

        let variables = {
            data : {
            "name": name.split(' ').length === 1 ? name : name.substr(0,name.indexOf(' ')),
            "lastName": name.split(' ').length > 1 ? name.substr(name.indexOf(' ')+1) : '',
            "company": Company,
            "city": Place,
            "region": Location,
            "country": Nationality,
            }
        }
        if(recentSearchId)  variables.contactId = recentSearchId;

        saveAdhocSearch({
            variables
        })

    }
    return {
        saveSaveAdHoc,
        saveAdHocAndParse,
        data,
        loading,
        error,
    }
}

export default SaveAdHocQuery
