import AddressProfile from "../../images/svg/address-profile"
import EmailProfile from "../../images/svg/email-profile"
import MobileProfile from "../../images/svg/mobile-profile"

export const getTracerIcon = (key) =>{
    // return <></>
    switch(key){
        case 'estimatedAge':
            return <></>
         case 'otherObservedNames':
            return <></>
         case 'emailAddresses':
            return <EmailProfile />
        case 'possibleRelatives':
            return <></>
        case 'possibleAssociates':
            return <></>
        case 'counties':
            return <AddressProfile />
        case 'addressHistory':
            return <AddressProfile />
        case 'phoneNumbers':
            return <MobileProfile />
        case 'mobilePhoneNumbers':
                return <MobileProfile />
        case 'locationSummary':
            return <AddressProfile />
        default:
            return <></>
    }
}