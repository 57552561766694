import React, { useState, useContext, useRef, useEffect } from 'react';
import createStyle from './styles';
import { stripHtml } from "string-strip-html";
import Highlighter from 'react-highlight-words';
// import Article404ToolTip from '../errorTooltips/article404ToolTip/article404ToolTip';
import ChevronDropdownIcon from '../../images/svg/chevron-dropdown-icon';
import ChevronUpIcon from '../../images/svg/chevron-up-icon';
import UserAvatar from '../../images/svg/user-Avatar'
import Variables from '../../utils/Variables';
import { UserContext } from "../../contexts/userContext";
// import RelevacyNoTooltip from '../onboardingTooltips/relevacyNoTooltip/relevacyNoTooltip';
import { capitalizeCase, getCollectionNames, titleCase } from '../../utils/utils';
import { Button, ButtonBase } from '@mui/material';
import { useNavigate } from "react-router-dom";
import "./style.css"
import SaveArticleRelevancy from '../articleList/saveArticleRelevancy';
import FilterHeaderTooltip from '../onboardingTooltips/filterHeaderTooltip';
import { WhiteBgButtonGreenBorder } from '../buttons/whiteBgButtonGreenBorder';
import TypeWriter from '../PersonInfo/TypeWriter';
import SingleBtnAlert from '../alert/singleBtnAlert';
import { searchResultStore } from '../../store/searchResultStore';

const { deviceWidth, deviceHeight } = window

const ArticleParts = props => {

  const { relevantArticle } = SaveArticleRelevancy();

  const {
    article: { title, summary, details, fulltext = '', selectedDocument },
    article,
    riskCategory,
    setIsHigherThanScreen,
    setIsSummaryExpanded,
    isSummaryExpanded,
    screenHeight,
    isTitleFixed,
    setTitleHeight,
    fullName,
    zoomSize,
    isWebViewError,
    isTracers = false,
    articleType = '',
    category = '',
    setRelevantArticle = relevantArticle,
    backButton,
    newsItem,
    onArticleAccept,
    displayRelevancyArticle,
    selectRemoveNotification = () => { },
    setIsExpandable,
    isExpandable,
    scrollPosition,
    setScrollPosition,
    openFilter,
    dontShow,
    articleSummary,
    setArticleSummary,
    showArticleSummary,
    setShowArticleSummary
  } = props;
  const regexTag = /(<([^>]+)>)/ig;
  const regexLineBrack = /[\r\n]+/gm;
  const regexLineBrackScroll = /[\f]+/gm;
  const style = createStyle(riskCategory, details, zoomSize, isTracers);
  const [totalLines, setTotalLines] = useState();
  const [numberOfLines, setNumberOfLines] = useState(4);
  const [alertsOnboard, setAlertsOnboard] = useState(false)
  const [loading, setLoading] = useState(false);
  const [showSingleBtnAlert, setShowSingleBtnAlert] = useState({ status: false, msg: '' })
  const [withNameTextDivVisible, setWithNameTextDivVisible] = useState(true)
  const { user } = useContext(UserContext)
  const refSummary = useRef()
  const titleDiv = useRef()
  const firstHighlightText = useRef()

  let displayText = isTracers ? title : selectedDocument ? selectedDocument.details?.description : summary;
  let findFirstText = '';
  if (!isTracers && fulltext !== '' && fulltext !== 'not found') displayText = fulltext;

  let withNameText = displayText;
  let highlightWord = [];
  let highlightWord_1 = [];

  if (displayText !== '' && displayText) {
    // displayText = displayText.replace(/�/g, '');
    // displayText = displayText.replace(regexTag, '');

    // displayText = stripHtml(displayText, {
    //   stripTogetherWithTheirContents: [
    //     "script", // default
    //     "style", // default
    //     "xml", // default
    //     "pre", // <-- custom-added
    //   ],
    // }).result;
    // displayText = displayText.replace(/document.getElementById/gi, '');
    // displayText = displayText.replace(regexLineBrack, '\n')
    // displayText = displayText.replace(regexLineBrackScroll, '')
    // displayText = displayText.replace(/  /g, '')
    // if (displayText.startsWith('\n')) displayText = displayText.replace('\n', '')

    if (fullName) {
      //change below line code for jira - C4R-5551 as Larry suggested
      // highlightWord = [...fullName.split(' '), fullName.replace(/ /g, '\u200C \u200C')]
      // highlightWord_1 = [...[...fullName.split(' ')]?.filter(item => item?.length > 2), fullName.replace(/ /g, '\u200C \u200C')]

      highlightWord = [fullName, fullName.replace(/ /g, '\u200C \u200C')]
      highlightWord_1 = [fullName, fullName.replace(/ /g, '\u200C \u200C')]
    }
    highlightWord = highlightWord.map(item => {
      let mItem = titleCase(item) //First letter should be capital in Person Name
      // if (mItem.includes('.')) {
      //   mItem = mItem.replace(/\./, '\.')
      //   let dotReplaceItem = mItem.replace(/\./, '')
      //   let exp = new RegExp("\\b" + dotReplaceItem + '\\.(?=)', 'ig') // /for\b/g; // extra space for seperate dot(.)
      //   displayText = displayText.replace(exp, '\u200C' + mItem + '\u200C') // extra space added which is removed in top line
      // } else {
      mItem = mItem.replace(/[^a-zA-Z0-9 ]/g, "")
      let exp = new RegExp('\\b' + mItem + '\\b', 'gi') // /for\b/g;
      displayText = displayText.replace(exp, '\u200C' + mItem + '\u200C')
      // }
      return '\u200C' + mItem + '\u200C'
    })

    // if (displayText.split('. ').length > 5) {
    //   let newDisplayText = "";
    //   let type = 0;
    //   displayText.split('. ').forEach((item, index) => {
    //     if (type > 2) {
    //       type = 0
    //       newDisplayText = newDisplayText + '.\n\n' + item
    //     } else {
    //       type++;
    //       newDisplayText = newDisplayText + (index === 0 ? '' : '. ') + item
    //     }
    //   })
    //   displayText = newDisplayText;
    // }

    if (fullName) {
      let firstIndex = displayText.substr(0, displayText.toUpperCase().indexOf(('\u200C' + fullName.split(' ')[0] + '\u200C').toUpperCase())).lastIndexOf('\n') + 1;
      withNameText = displayText.substr(firstIndex, displayText.length);
      findFirstText = displayText.substr(0, displayText.toLowerCase().indexOf(('\u200C' + fullName.split(' ')[0] + '\u200C').toLowerCase()))

      // Try to find second search term word if first word not found in cache
      if ((findFirstText == null || findFirstText?.length == 0) && fullName.split(' ')?.length > 1)
        findFirstText = displayText.substr(0, displayText.toLowerCase().indexOf(('\u200C' + fullName.split(' ')[1] + '\u200C').toLowerCase()))

      // Try to find third search term word if first and second word not found in cache
      if ((findFirstText == null || findFirstText?.length == 0) && fullName.split(' ')?.length > 2)
        findFirstText = displayText.substr(0, displayText.toLowerCase().indexOf(('\u200C' + fullName.split(' ')[2] + '\u200C').toLowerCase()))
    }
    if (withNameText?.length > 999) {
      withNameText = withNameText?.slice(0, 1000);
    }
  }

  useEffect(() => {
    if (!totalLines) {
      const height = refSummary?.current?.clientHeight;
      const lines = height / 14;
      setWithNameTextDivVisible(!(height > 0))
      setTotalLines(lines);
      if (lines > 3) {
        setIsExpandable(true);
        setNumberOfLines(3);
      }
      if (height >= screenHeight - 275) {
        setIsHigherThanScreen(true);
      }
    }
  }, [])

  useEffect(() => {
    if (isSummaryExpanded == true) {
      var element = document.querySelector('mark');
      var bodyRect = document?.body?.getBoundingClientRect()
      var elemRect = element?.getBoundingClientRect()
      var offset = elemRect?.top - bodyRect?.top - 200
      setScrollPosition(offset)
    }
  }, [isSummaryExpanded])

  useEffect(() => {
    const height = titleDiv?.current?.clientHeight;
    setTitleHeight(height)

    return () => {
      if (setArticleSummary) {
        setArticleSummary?.("")
      }
    }
  }, [])

  const showMoreHandler = () => {
    setNumberOfLines(Math.ceil(totalLines));
    setIsSummaryExpanded(true, scrollPosition);
  };
  const showLessHandler = () => {
    setNumberOfLines(3);
    setIsSummaryExpanded(false);
  };

  const setRelevantArticleFunc = (article, isRelevant) => {
    setRelevantArticle(article, isRelevant, article?.potentialMatchId)
    selectRemoveNotification(article)
    backButton(false);
  }

  const dontShowView = () => {
    setAlertsOnboard(false)
    dontShow?.()
    setRelevantArticleNoFunc()
    openFilter?.(false)
  }
  const skip = (no) => {
    if (no && !user.relevancySortFilterTooltip) {
      setAlertsOnboard(true)
    } else {
      setAlertsOnboard(false)
      setRelevantArticleNoFunc()
    }
    openFilter?.(false)
  }
  const openFilterSort = () => {
    setAlertsOnboard(false)
    setRelevantArticleNoFunc()
    openFilter?.(true)
  }

  const setRelevantArticleNoFunc = () => {
    setRelevantArticleFunc(newsItem, "NO", article?.potentialMatchId)
    Variables.isAddSearches = true
    Variables.isRefreshClosestContacts = true
  }

  const strSplitOnLength = () => {
    let data = displayText
    if (data.length <= 0)
      return [];

    var splitData = data.split(/([\s\n\r]+)/);
    var arr = [];
    var cnt = 0;
    for (var i = 0; i < splitData.length; ++i) {
      if (!arr[cnt]) arr[cnt] = '';

      if (deviceWidth < (splitData[i].length + arr[cnt].length))
        cnt++;

      arr[cnt] += splitData[i];
    }
    return arr;
  }

  const isRelevantArticle = () => (
    <>
      <div style={style.aboutMainView}>
        <UserAvatar />
        <span style={style.aboutYour}>{`Is this about your ${capitalizeCase(newsItem.fullName, true)}?`}</span>
      </div>
      <div style={style.aboutMainView}>
        <ButtonBase style={style.btnView} onClick={() => onArticleAccept()}>
          <span style={style.btnText}>Yes</span>
        </ButtonBase>
        <ButtonBase style={style.btnView} onClick={() => { skip(true) }}>
          <span style={style.btnText}>No</span>
        </ButtonBase>
        {alertsOnboard && <FilterHeaderTooltip
          onPress={skip}
          isFilter={true}
          dontShow={dontShowView}
          openFilter={openFilterSort}></FilterHeaderTooltip>}
      </div>
    </>
  )

  const callSummarizerApi = async () => {
    try {
      setLoading(true)
      setArticleSummary("")

      const response = await fetch(`${process.env.REACT_APP_SUMMARIZER_URL}/summarize`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: [newsItem.articleId],
          collection_name: getCollectionNames(newsItem.parentCategory)
        })
      });

      const data = await response.json();
      if (data.summary?.length > 0) {
        let summarizeData = data.summary?.filter((item) => {
          return item.article_id == newsItem.articleId
        })

        setShowArticleSummary(true)
        setArticleSummary(summarizeData?.[0]?.summary)
      } else {
        setShowSingleBtnAlert({ status: true, msg: "Unable to generate summary for the article. Please try again later." })
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error('Error:', error);
    }
  }

  return (
    <div>
      {/* <div 
        ref={firstHighlightText}
        style={style.summary2}>{findFirstText} 
      </div> */}
      {!isTitleFixed && (
        <div
          ref={titleDiv}
          style={style.titleContainer}>
          <span numberOfLines={2} style={style.title}>
            {isTracers ? articleType : selectedDocument ? selectedDocument.details?.title : title}
          </span>
        </div>
      )}
      {selectedDocument && selectedDocument.details &&
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span
            style={{ ...style.summaryDocument, fontWeight: '700', marginStart: 16 }}
            numberOfLines={numberOfLines}
            ellipsizeMode="tail">{'Entry ID: '}<span style={style.summaryDocument}>
              {selectedDocument.details.entry_id}
            </span>
          </span>
          <span
            style={{ ...style.summaryDocument, fontWeight: '700', marginStart: 16 }}
            numberOfLines={numberOfLines}
            ellipsizeMode="tail">{'Entry Number: '}<span style={style.summaryDocument}>
              {selectedDocument.details.entry_number}
            </span>
          </span>
          <span
            style={{ ...style.summaryDocument, fontWeight: '700', marginStart: 16, marginBottom: 10 }}
            numberOfLines={numberOfLines}
            ellipsizeMode="tail">{'Entry Filed: '}<span style={style.summaryDocument}>
              {selectedDocument.details.entryFiled}
            </span>
          </span>
        </div>
      }

      {displayText !== '' && (
        !isSummaryExpanded ? <>
          {withNameTextDivVisible && <div
            ref={refSummary}
            style={{ ...style.summary2 }}>
            {withNameText}
          </div>}
          <div
            className='truncate'
            style={style.summary}
            numberOfLines={numberOfLines}
            ellipsizeMode="tail">
            {withNameText}
          </div>
        </>
          : false ?
            strSplitOnLength().map((item) =>
              <Highlighter
                autoEscape={false}
                style={style.summary}
                highlightStyle={style.summaryHighlight}
                searchWords={highlightWord_1}
                textToHighlight={item} />
            ) : <Highlighter
              autoEscape={false}
              style={style.summary}
              highlightStyle={style.summaryHighlight}
              searchWords={highlightWord_1}
              textToHighlight={displayText} />
      )}

      {isExpandable && !isSummaryExpanded && (
        <>
          <ButtonBase style={style.touchView} onClick={showMoreHandler}>
            <span style={style.buttonNotifi}>Show Cache</span>
            <ChevronDropdownIcon style={style.cacheBtnIcon} />
          </ButtonBase>
          {/* {isExpandable && isWebViewError && (
            <Article404ToolTip>
              <div />
            </Article404ToolTip>
          )} */}
        </>
      )}
      {details &&
        details.map(({ key: label, value }, index) => (
          <div style={style.detailContainer} key={`${index}-${label}`}>
            <span style={style.detailLabel}>{`${label}: `}</span>
            <span style={style.detailValue}>{value}</span>
          </div>
        ))}
      {isExpandable && isSummaryExpanded && (
        <>
          <button style={{ ...style.touchView, paddingBottom: 16 }} onClick={showLessHandler}>
            <span style={style.buttonNotifi}>Hide Cache</span>
            <ChevronUpIcon style={style.cacheBtnIcon} />
          </button>
        </>
      )}
      {displayRelevancyArticle && isRelevantArticle()}

      {getCollectionNames(newsItem.parentCategory, newsItem.itemType ?? newsItem.category)?.length > 0 &&
        <div style={{ display: "flex", justifyContent: "flex-start", marginTop: 20 }}>
          {/* Hide Generate Article Summary button on MetaSearch: https://x2xi.atlassian.net/browse/C4R-6197 */}
          {articleType !== 'Web Search' && <WhiteBgButtonGreenBorder
              loading={loading}
              onClick={() => {
                callSummarizerApi()
              }}
            >
              <span style={{ fontSize: 14, fontWeight: 700, textTransform: "capitalize" }}>Generate Article Summary</span>
            </WhiteBgButtonGreenBorder>
          }

          {articleSummary?.length > 0 &&
            <div style={{ paddingTop: 9, paddingLeft: 6, paddingRight: 10 }}
              onClick={() => setShowArticleSummary(!showArticleSummary)}
            >
              <img
                style={
                  showArticleSummary
                    ? { transform: `rotate(180deg)`, width: 30, height: 30 }
                    : { width: 30, height: 30 }
                }
                src={require("../../images/filter-close.png")}
              />
            </div>
          }
        </div>
      }

      {(articleSummary?.length > 0 && showArticleSummary) &&
        <div style={{ borderWidth: "1px", borderStyle: "solid", borderColor: "black", marginTop: 10, marginBottom: 10, borderRadius: 8, backgroundColor: "white" }}>
          <TypeWriter fullTxt={articleSummary} />
        </div>
      }


      {showSingleBtnAlert.status && <SingleBtnAlert
        description={showSingleBtnAlert.msg}
        btnTxt="OK"
        onBtnClick={() => {
          setShowSingleBtnAlert({ status: false, msg: "" })
        }}
      />}

    </div>
  );
};

export default ArticleParts;

