import { create } from 'zustand'

export const viewOnlyListStore = create((set) => ({
  maxCountVisible: 100,
  minCountVisible: 0,
  totalContacts: 100,
  setMaxCountVisible: (data) => set(() => ({ maxCountVisible: data})),
  setMinCountVisible: (data) => set(() => ({ minCountVisible: data})),
  setTotalContacts: (data) => set(() => ({ totalContacts: data})),
  removeAll: () => set({ 
    maxCountVisible: 100,
    minCountVisible: 0,
    totalContacts: 100,
  }),
}))
