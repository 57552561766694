import _ from 'lodash'
import { GOOGLE_CONTACTS_SCOPE } from '../../utils/google-login-helper'
import { extractContactsFromIcloudContactsReponse } from '../../utils/iCloud-contacts-parser'

const MAX_RESULTS = 1000
let pageCount = 0
let iCloudContacts = []

const getIcloudContacts = async (preGetContactsAction, postGetContactsAction, updateUser, syncToken, updateOnly=false, iCloudUserData) => {
  try {
    preGetContactsAction?.()
    if(iCloudUserData && iCloudUserData?.access_token){
      retrieveIcloudContacts(null, iCloudUserData.access_token, postGetContactsAction, updateUser, syncToken, updateOnly)
    }
  }catch (error) {
    postGetContactsAction?.(error, undefined)
  }
}

function retrieveIcloudContacts(startIndex, icloudToken, postGetContactsAction, updateUser, syncToken, updateOnly = false, preGetContactsAction) {
  if (startIndex == null) {
    iCloudContacts = []
    pageCount = 0
  } else {
    preGetContactsAction?.()
  }
  let params={
    "limit": MAX_RESULTS,
    "offset": MAX_RESULTS * pageCount,
  }

  var defaultheader = () => {
    return {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        "Content-Type": "application/json",
        "Authorization":"Bearer "+icloudToken,
        "Accept": "application/json",
      },
    }
  }
  
  let url = "https://api.nylas.com/contacts?"
  let header = defaultheader()
  const suburl = transformRequest(params)
  url = url + suburl
  fetch(url, header).then((response) => {
  return response.json()
  }).then((responseJson) => {
      if(responseJson && responseJson.length == 0) {
        if (iCloudContacts && iCloudContacts.length > 0)
            postGetContactsAction?.(undefined, iCloudContacts, updateOnly)
        else
            postGetContactsAction?.(undefined, undefined, updateOnly)
      } else {
        iCloudContacts = _.concat(iCloudContacts, extractContactsFromIcloudContactsReponse(responseJson))
        if (responseJson && responseJson.length < MAX_RESULTS) {
          if (iCloudContacts && iCloudContacts.length > 0)
            postGetContactsAction?.(undefined, iCloudContacts, updateOnly)
          else
            postGetContactsAction?.()
            iCloudContacts = []
        } else {
          retrieveIcloudContacts(pageCount++, icloudToken, postGetContactsAction, updateUser, updateOnly, preGetContactsAction)
        }
      }
  }).catch((error) => {
    postGetContactsAction?.(error, undefined, updateOnly)
  });

}

function transformRequest(obj) {
  let str = []
  for (var p in obj) {
    str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`)
  }
  return str.join("&")
}

export default getIcloudContacts