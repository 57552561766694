import React, { useState, useEffect, useContext, useRef } from "react";
import SubscriptionPlanCard from "../subscriptionPlanCard/subscriptionPlanCard";
import {
  CANCEL_STRIPE_SUBSCRIPTION,
  CREATE_STRIPE_SUBSCRIPTION,
  GET_SUBSCRIPTION_PLAN,
  SETUP_PAYMENT_INTENT,
  UPDATE_STRIPE_SUBSCRIPTION,
} from "../../queries/queries";
import { useMutation, useLazyQuery } from "react-apollo";
import { ME } from "../../queries/queries";
import { setUserInfo, userInfo } from "../../root-navigation";
import { UserContext } from "../../contexts/userContext";
import CloseIconGray from "../../images/svg/close_gray";
import moment from "moment-timezone";
import {
  Backdrop,
  Button,
  ButtonBase,
  ButtonGroup,
  CircularProgress,
  Dialog
} from "@mui/material";
// import {AppEventsLogger} from "react-native-fbsdk-next";
import { Modal } from "@mui/material";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../stripeCheckout/CheckoutForm";
import { GreenBgButton } from "../buttons/greenBgButton";
import SubscriptionPreview from "../subscriptionPreview/subscriptionPreview";
import ImportedContactsLogo from "../../images/svg/imported-contacts-logo";
import OkArrowGray from "../../images/svg/ok-arrow-gray";
import { useNavigate } from "react-router-dom";
import { WhiteBgButtonGreenBorder } from "../buttons/whiteBgButtonGreenBorder";
import StylesMobile from "./subscriptionPlansModal.module.css";
import StylesWeb from "./subscriptionPlansModalWeb.module.css";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);

var alertMsgForDowngradePlan = null;
var isDownGradingPlan = false;
var cancelPlan = false;
var currentPlan = null;
var newPlan = null;
const FREE_PLAN_ID = "free";
var freePlan = null;
var planChangeEffectMsg = null;
var callCreateSubsApi = false;

const SUBSCRIPTION_SCREEN = {
  MODAL: "MODAL",
  PREVIEW: "PREVIEW",
  CHECKOUT: "CHECKOUT",
  SUCCESS: "SUCCESS",
};

const SubscriptionPlansModal = ({
  subscriptionModalVisible,
  setSubscriptionModalVisible,
  isDesktop,
  hideAccountOverlay,
  isWeb,
}) => {

  const Styles = isDesktop ? StylesWeb : StylesMobile

  const navigate = useNavigate();
  const [buttonTitle, setButtonTitle] = useState({});
  const [buttonTitlePrice, setButtonTitlePrice] = useState({});
  const [selectedPlan, setSelectedPlan] = useState({});
  const [sectionIndex, setSectionIndex] = useState(0);
  const [subscriptionPlan, setSubscriptionPlan] = useState([]);
  const [showDowngradePlanAlert, setShowDowngradePlanAlert] = useState(false);
  const [showChangeWarningAlert, setShowChangeWarningAlert] = useState(false);
  const { user, setUser } = useContext(UserContext);
  const subsMainRef = useRef([])

  const [clientSecret, setClientSecret] = useState("");
  const [selectedViewScreen, setSelectedViewScreen] = useState(
    SUBSCRIPTION_SCREEN.MODAL
  );

  const [
    setupPaymentIntent,
    {
      data: setupPaymentIntentData,
      loading: setupPaymentIntentLoading,
      error: setupPaymentIntentError,
    },
  ] = useMutation(SETUP_PAYMENT_INTENT);

  const [
    createStripeSubscription,
    {
      data: createStripeSubsData,
      loading: createStripeSubsLoading,
      error: createStripeSubsError,
    },
  ] = useMutation(CREATE_STRIPE_SUBSCRIPTION);

  const [
    cancelStripeSubscription,
    {
      data: cancelStripeSubsData,
      loading: cancelStripeSubsLoading,
      error: cancelStripeSubsError,
    },
  ] = useMutation(CANCEL_STRIPE_SUBSCRIPTION);

  const [
    updateStripeSubscription,
    {
      data: updateStripeSubsData,
      loading: updateStripeSubsLoading,
      error: updateStripeSubsError,
    },
  ] = useMutation(UPDATE_STRIPE_SUBSCRIPTION);

  const [
    getUserInfo,
    { data: userData, loading: userLoading, error: userError },
  ] = useLazyQuery(ME, {
    fetchPolicy: "no-cache",
  });

  const [
    getSubscriptionPlan,
    { loading: subscriptionLoading, data: subscriptionData },
  ] = useLazyQuery(GET_SUBSCRIPTION_PLAN);

  useEffect(() => {
    if (!subscriptionLoading && subscriptionData) {
      let subscriptionPlan = subscriptionData?.getSubsriptionPlan?.sort(
        (a, b) => a.score - b.score
      );
      let userCurrentPlan = subscriptionPlan?.filter(
        (item) => userInfo.itemId === item.productId && userInfo.isSubscribed
      );
      freePlan = subscriptionPlan?.filter(
        (item) => item.productId === FREE_PLAN_ID
      );
      if (userCurrentPlan && userCurrentPlan.length > 0) {
        let filteredPlan = subscriptionPlan?.filter(
          (item) => item.productId !== FREE_PLAN_ID
        );
        setSubscriptionPlan(filteredPlan);
      } else {
        setSubscriptionPlan(subscriptionPlan);
      }
    }
  }, [subscriptionData]);

  const getButtonTitle = (selectedPlan) => {
    isDownGradingPlan = false;
    alertMsgForDowngradePlan = null;

    let selectedPlanArray = selectedPlan.productId.split(".").splice(-2);
    let buttonTitle = [];

    if (userInfo.itemId != undefined && userInfo.isSubscribed) {
      buttonTitle = ["Change Subscription", null];

      let currentPlanArray = userInfo.itemId.split(".").splice(-2);

      switch (true) {
        case currentPlanArray[0] == "premium" && selectedPlanArray[0] == "free":
          break;
        case currentPlanArray[0] == "premium" && selectedPlanArray[0] == "pro":
          if (
            currentPlanArray[1] == "annual" &&
            selectedPlanArray[1] == "monthly"
          ) {
            downgradeMsg(selectedPlan);
          }
          break;
        case currentPlanArray[0] == "pro" && selectedPlanArray[0] == "premium":
          if (
            (currentPlanArray[1] == "annual" &&
              selectedPlanArray[1] == "monthly") ||
            (currentPlanArray[1] == "annual" &&
              selectedPlanArray[1] == "annual") ||
            (currentPlanArray[1] == "monthly" &&
              selectedPlanArray[1] == "monthly")
          ) {
            downgradeMsg(selectedPlan);
          }
          break;
        case (currentPlanArray[0] == "premium" &&
          selectedPlanArray[0] == "premium") ||
          (currentPlanArray[0] == "pro" && selectedPlanArray[0] == "pro"):
          {
            if (
              currentPlanArray[1] == "annual" &&
              selectedPlanArray[1] == "monthly"
            ) {
              downgradeMsg(selectedPlan);
            }
          }
          break;
      }
    } else {
      buttonTitle = getDefaultBtnText(selectedPlanArray[1], selectedPlan);
    }
    if (buttonTitle.length > 0) {
      setButtonTitle(buttonTitle[0]);
      setButtonTitlePrice(buttonTitle[1]);
    }
  };

  const getDefaultBtnText = (periodicity, selectedPlan) => {
    const dateFormat = "MM/DD/YY";

    const trialDate = moment(parseInt(user.trialEndDate)).format(dateFormat);
    const currentDate = moment().format(dateFormat);

    // Check if the current date is greater than the trial date
    const isCurrentDateGreaterThanTrialDate = moment(currentDate, dateFormat).isAfter(moment(trialDate, dateFormat));

    if (!isCurrentDateGreaterThanTrialDate) {
      return [
        `Start your 7-Day Free Trial`,
        null,
      ];
    } else {
      return [
        `Start your Subscription`,
        null,
      ];
    }
  };

  const downgradeMsg = (selectedPlan) => {
    isDownGradingPlan = true;
    if (
      userInfo.monitoredContactCount < selectedPlan.monitorContactLimitPerMonth
    ) {
      alertMsgForDowngradePlan = `Are you sure you want to downgrade? You’ll only be able to watch up to ${selectedPlan.monitorContactLimitPerMonth} searches. What will you miss?`;
    } else {
      alertMsgForDowngradePlan = `You are currently watching ${userInfo.monitoredContactCount} searches. The Premium plan only supports up to ${selectedPlan.monitorContactLimitPerMonth}. Go to your Watched Contacts tab and choose who to stop watching, or we'll keep your first ${selectedPlan.monitorContactLimitPerMonth} watched searches in alphabetical order for you. If you cancel you'll loose all your watched searches and need to start over.`;
    }
  };

  useEffect(() => {
    if (subscriptionModalVisible) {
      // Get User Info
      getUserInfo();
    }
  }, [subscriptionModalVisible]);

  useEffect(() => {
    if (subscriptionPlan && subscriptionPlan.length > 0) {
      let userCurrentPlan = subscriptionPlan?.filter(
        (item) => userInfo.itemId === item.productId && userInfo.isSubscribed
      );
      if (userCurrentPlan && userCurrentPlan.length > 0) {
        currentPlan = userCurrentPlan[0];
        setSectionIndex(currentPlan.periodicity !== "MONTHLY" ? 0 : 1);
        setSelectedPlan(currentPlan);
      } else {
        userCurrentPlan = subscriptionPlan?.filter(
          (item) => item.productId === FREE_PLAN_ID
        );
        setSelectedPlan(userCurrentPlan[0]);
      }
    }
  }, [subscriptionPlan, user]);

  useEffect(() => {
    if (!updateStripeSubsLoading && updateStripeSubsData) {
      let data = updateStripeSubsData.updateStripeSubscription.data;
      if (data === "success") {
        setSelectedViewScreen(SUBSCRIPTION_SCREEN.SUCCESS);
        getUserInfo();
      }
    }
  }, [updateStripeSubsData, updateStripeSubsLoading, updateStripeSubsError]);

  useEffect(() => {
    if (!cancelStripeSubsLoading && cancelStripeSubsData) {
      let data = cancelStripeSubsData.cancelStripeSubscription.data;
      if (data === "success") {
        newPlan = freePlan && freePlan[0]
        setSelectedViewScreen(SUBSCRIPTION_SCREEN.SUCCESS);
        getUserInfo();
      }
    }
  }, [cancelStripeSubsData, cancelStripeSubsLoading, cancelStripeSubsError]);

  useEffect(() => {
    if (!createStripeSubsLoading && createStripeSubsData) {
      let clientSecret =
        createStripeSubsData.createStripeSubscription.clientSecret;
      callCreateSubsApi = false;
      setClientSecret(clientSecret);
      setSelectedViewScreen(SUBSCRIPTION_SCREEN.CHECKOUT);
    }
  }, [createStripeSubsData, createStripeSubsError, createStripeSubsLoading]);

  useEffect(() => {
    if (!setupPaymentIntentLoading && setupPaymentIntentData) {
      let clientSecret = setupPaymentIntentData.setupPaymentIntent.clientSecret;
      callCreateSubsApi = true;
      setClientSecret(clientSecret);
      setSelectedViewScreen(SUBSCRIPTION_SCREEN.CHECKOUT);
    }
  }, [
    setupPaymentIntentData,
    setupPaymentIntentError,
    setupPaymentIntentLoading,
  ]);

  /**
   * @param {productId} productId
   * Purchase Subscription
   */
  const purchaseSubscription = async (stripePriceId) => {
    // if (userInfo.paymentMethod?.length > 0) {
    //   createStripeSubscription({
    //     variables: {
    //       priceId: stripePriceId,
    //       paymentMethodId: userInfo.paymentMethod?.[0]
    //     },
    //   });
    // } else {
    setupPaymentIntent();
    // }
  };

  /**
   * @param {productId} productId
   * Update Subscription
   */
  const updateSubscription = async (stripePriceId, subscriptionId) => {
    updateStripeSubscription({
      variables: {
        priceId: stripePriceId,
        subscriptionId: subscriptionId,
      },
    });
  };

  /**
   * set user info
   */
  useEffect(() => {
    if (
      !userError &&
      !userLoading &&
      !!userData &&
      userData.hasOwnProperty("me")
    ) {
      let u = setUserInfo(userData.me);
      setUser({ ...u });
      getSubscriptionPlan();
    } else if (!userLoading && userError) {
      // showErrorAlert()
    }
  }, [userData, userLoading, userError]);

  /**
   * onBackButton click
   */
  const onBackDropPressHandler = (e) => {
    setSelectedPlan({});
    setSubscriptionPlan([]);
    setSectionIndex(0);
    setSubscriptionModalVisible(false);
    setShowDowngradePlanAlert(false);
  };

  const goToDashboard = () => {
    cancelPlan = false;
    onBackDropPressHandler(true);
    navigate("/dashboard");
    hideAccountOverlay && hideAccountOverlay()
  };

  /**
   * On Purchase Button Click (Buy plan)
   */
  const previewBuyBtn = () => {
    updateSubscription(selectedPlan.stripePriceId, userInfo.subscriptionId);
  };

  const modalBuyBtn = () => {
    if (!userInfo.isSubscribed) {
      purchaseSubscription(selectedPlan.stripePriceId);
      return;
    }

    if (userInfo.platform !== "web") {
      setShowChangeWarningAlert(true)
      return
    }

    if (isDownGradingPlan) {
      setShowDowngradePlanAlert(true);
      getButtonTitle(selectedPlan);
      getNextRenewalDate();
      return;
    }

    setSelectedViewScreen(SUBSCRIPTION_SCREEN.PREVIEW);
  };

  /**
   * Alert button click
   */
  const handleAlertOkButtonPress = () => {
    setShowDowngradePlanAlert(false);
    planChangeEffectMsg = null;
    if (cancelPlan) {
      // Cancellation performed
      cancelStripeSubscription({
        variables: {
          subscriptionId: userInfo.subscriptionId,
        },
      });
    } else {
      setSelectedViewScreen(SUBSCRIPTION_SCREEN.PREVIEW);
    }
  };

  const handleAlertCancelButtonPress = () => {
    planChangeEffectMsg = null;
    cancelPlan = false;
    setShowDowngradePlanAlert(false);
  };

  /**
   * on click subscription
   */
  const onPressSubscription = (plan) => {
    newPlan = plan;
    setSelectedPlan(plan);
    getButtonTitle(plan);
    if (!isDesktop)
      setTimeout(() => {
        subsMainRef.current[plan.productId].scrollIntoView({ behavior: 'smooth' });
      }, 120);
  };

  const onCancelSubscription = () => {
    // alertMsgForDowngradePlan = `Are you sure you want to downgrade? You'll only do ${
    //   freePlan && freePlan[0].adhocSearchLimitPerMonth
    //   } number of free searches month. What will you miss?`;
    if (userInfo.platform !== "web") {
      setShowChangeWarningAlert(true)
      return
    }

    // alertMsgForDowngradePlan = `If you cancel your ${currentPlan && currentPlan.title
    //   } subscription, you’ll only be able to monitor up to ${freePlan && freePlan[0].monitorContactLimitPerMonth
    //   } searches instead of ${currentPlan && currentPlan.monitorContactLimitPerMonth
    //   }. You’ll also only be able to perform ${freePlan && freePlan[0].adhocSearchLimitPerMonth
    //   } searches per month.`;

    if (userInfo.isInTrialPeriod) {
      alertMsgForDowngradePlan = ` If you cancel your ${currentPlan && currentPlan.title} subscription, you’ll be able to search and monitor only up to 7-day free trial period.`
    } else {
      const dateFormat = "MM/DD/YY";
      let date = user.isInTrialPeriod
        ? moment(parseInt(userInfo.trialEndDate)).format(dateFormat)
        : moment(parseInt(userInfo.expirationDateMs)).format(dateFormat)
      alertMsgForDowngradePlan = `If you cancel your ${currentPlan && currentPlan.title} subscription, you’ll be able to search and monitor only up to ${date}.`
    }

    // alertMsgForDowngradePlan = `If you cancel your subscription, you'll lose your watched searches and be subject ${freePlan && freePlan[0].adhocSearchLimitPerMonth} searches per month.`
    cancelPlan = true;
    getNextRenewalDate();
    setShowDowngradePlanAlert(true);
  };

  const getNextRenewalDate = () => {
    const dateFormat = "MM/DD/YY";
    let date = user.isInTrialPeriod
      ? moment(parseInt(userInfo.trialEndDate)).format(dateFormat)
      : moment(parseInt(userInfo.expirationDateMs)).format(dateFormat)
    planChangeEffectMsg = `Your changes will take effect next billing cycle: ${date}`;
  };

  const sections = [
    {
      title: "Annual",
      content: (
        <div className={Styles.planList}>
          {subscriptionPlan &&
            subscriptionPlan
              ?.filter(
                (item) =>
                  item.periodicity === "YEARLY" ||
                  item.productId === FREE_PLAN_ID
              )
              .map((plan) => (
                <SubscriptionPlanCard
                  subsMainRef={subsMainRef}
                  selected={
                    plan.productId === selectedPlan?.productId ||
                    (userInfo.itemId === plan.productId &&
                      userInfo.isSubscribed &&
                      selectedPlan?.productId === undefined)
                  }
                  plan={plan}
                  onPress={(e) => {
                    e.stopPropagation();
                    onPressSubscription(plan);
                  }}
                />
              ))}
        </div>
      ),
    },
    {
      title: "Monthly",
      content: (
        <div className={Styles.planList}>
          {subscriptionPlan &&
            subscriptionPlan
              ?.filter(
                (item) =>
                  item.periodicity === "MONTHLY" ||
                  item.productId === FREE_PLAN_ID
              )
              .map((plan) => (
                <SubscriptionPlanCard
                  subsMainRef={subsMainRef}
                  selected={
                    plan.productId === selectedPlan?.productId ||
                    (userInfo.itemId === plan.productId &&
                      userInfo.isSubscribed &&
                      selectedPlan?.productId === undefined)
                  }
                  plan={plan}
                  onPress={() => {
                    onPressSubscription(plan);
                  }}
                />
              ))}
        </div>
      ),
    },
  ];

  const appearance = {
    theme: "stripe",
    variables: {
      colorPrimary: "#078167",
      colorBackground: "#ffffff",
      colorText: "#142030",
      colorDanger: "#df1b41",
      spacingUnit: "4px",
    },
    rules: {
      ".Label": {
        fontWeight: "600",
        fontSize: "13px",
        paddingBottom: "4px",
      },
      ".Input": {
        borderRadius: "6px",
        borderColor: "#C0C7D6",
      },
    },
  };

  const options = {
    clientSecret,
    appearance,
  };

  const subViewStateWise = () => {
    return (
      <>
        {/* SUBSCRIPTION MODAL VIEW */}

        {selectedViewScreen == SUBSCRIPTION_SCREEN.MODAL && (
          <div className={Styles.container}>
            <header>
              <ButtonBase
                className={Styles["crossButton"]}
                onClick={onBackDropPressHandler}
              >
                <CloseIconGray className={Styles.closeIcon} />
              </ButtonBase>

              {subscriptionData && <>
                <h2 className={Styles.title}>
                  {userInfo.isSubscribed ?
                    selectedPlan?.productId == currentPlan?.productId ?
                      `Manage Subscription`
                      :
                      `Start your ${selectedPlan?.title} Subscription`
                    :
                    selectedPlan?.productId == FREE_PLAN_ID ?
                      "Manage Subscription" :
                      `Start your ${selectedPlan?.title} Subscription`
                  }
                </h2>
                {/* {!userInfo.isSubscribed && <span className={Styles.planExpireInfo}>You’re about to lose access to premium benefits. Select a subscription plan today.</span>} */}

                <div className={Styles.buttonGroup}>
                  <ButtonGroup
                    disableElevation
                    variant="contained"
                    className={Styles["segmentedControlContainer"]}
                  >
                    <Button
                      fullWidth
                      className={
                        sectionIndex == 0
                          ? `${Styles["annualBtnSelected"]} ${Styles["segmentedControlSelectedButton"]}`
                          : `${Styles["annualBtnUnselected"]} ${Styles["segmentedControlButton"]}`
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                        setSectionIndex(0)
                      }}
                    >
                      <span
                        className={
                          sectionIndex == 0
                            ? Styles.segmentedControlSelectedText
                            : Styles.segmentedControlText
                        }
                      >
                        {sections[0].title}
                      </span>
                    </Button>
                    <Button
                      fullWidth
                      className={
                        sectionIndex == 1
                          ? `${Styles["monthlyBtnSelected"]} ${Styles["segmentedControlSelectedButton"]}`
                          : `${Styles["monthlyBtnUnselected"]} ${Styles["segmentedControlButton"]}`
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                        setSectionIndex(1)
                      }}
                    >
                      <span
                        className={
                          sectionIndex == 1
                            ? Styles.segmentedControlSelectedText
                            : Styles.segmentedControlText
                        }
                      >
                        {sections[1].title}
                      </span>
                    </Button>
                  </ButtonGroup>
                </div>
              </>
              }
            </header>
            <main className={Styles.mainPlanCard}>
              {sections[sectionIndex].content}

              {subscriptionData &&
                <div
                  className={Styles.enterprisePlanContainer}>
                  <div className={Styles.enterprisePlanTitle}>
                    Enterprise
                  </div>
                  <div className={Styles.enterprisePlanContactSales}>
                    Contact Sales
                  </div>
                  <div className={Styles.enterprisePlanBenefit}>
                    For monitoring saved searches over 5000, contact <a href="mailto:sales@ferret.ai?subject=Enterprise Subscription - Request for Information" className={Styles.enterprisePlanContactEmail}>sales@ferret.ai</a>
                  </div>
                </div>
              }

            </main>
            <footer className={Styles.footer}>
              {selectedPlan?.productId !== undefined &&
                selectedPlan?.productId !== FREE_PLAN_ID &&
                (selectedPlan?.productId !== userInfo.itemId ||
                  !userInfo.isSubscribed) && (
                  <Button
                    className={Styles["buyButtonStyle"]}
                    onClick={(e) => {
                      e.stopPropagation();
                      modalBuyBtn()
                    }
                    }
                  >
                    <span className={Styles.buyButtonTextStyleBold}>
                      {buttonTitle}
                    </span>
                    {buttonTitlePrice && (
                      <span className={Styles.buyButtonTextStyle}>
                        {buttonTitlePrice}
                      </span>
                    )}
                  </Button>
                )}
              {userInfo.isSubscribed && userInfo.willAutoRenew && subscriptionPlan.length > 0 && !userInfo.isCancelled && (
                <ButtonBase
                  className={Styles["cancelBtn"]}
                  onClick={onCancelSubscription}
                >
                  <span className={Styles["cancelBtnTxt"]}>
                    Cancel Subscription
                  </span>
                </ButtonBase>
              )}
            </footer>
          </div>
        )}

        {/* SUBSCRIPTION PREVIEW VIEW */}

        {selectedViewScreen === SUBSCRIPTION_SCREEN.PREVIEW && (
          <div className={isDesktop ? Styles.containerWeb : Styles.container}>
            <header>
              <ButtonBase
                className={Styles["crossButton"]}
                onClick={onBackDropPressHandler}
              >
                <CloseIconGray className={Styles.closeIcon} />
              </ButtonBase>
            </header>
            <SubscriptionPreview
              isDesktop={isDesktop}
              freePlan={freePlan}
              currentPlan={currentPlan}
              newPlan={selectedPlan}
              isDownGradingPlan={isDownGradingPlan}
              handleBuyButtonPress={previewBuyBtn}
            />
          </div>
        )}

        {/* SUBSCRIPTION CHECKOUT VIEW */}

        {selectedViewScreen === SUBSCRIPTION_SCREEN.CHECKOUT && (
          <div className={isDesktop ? Styles.containerWeb : Styles.container}>
            <div
              style={{ flex: "none", height: isDesktop ? 4 : 20, backgroundColor: "#FFFFFF" }}
            >
              <ButtonBase
                className={Styles.crossButton}
                onClick={onBackDropPressHandler}
              >
                <CloseIconGray className={Styles.closeIcon} />
              </ButtonBase>
            </div>
            <div className={Styles.checkoutForm}>
              <Elements options={options} stripe={stripePromise}>
                <CheckoutForm
                  isDesktop={isDesktop}
                  selectedPlan={selectedPlan}
                  getUserInfo={getUserInfo}
                  callCreateSubsApi={callCreateSubsApi}
                  setSelectedViewScreen={setSelectedViewScreen}
                />
              </Elements>
            </div>
          </div>
        )}

        {/* SUBSCRIPTION SUCCESS VIEW */}

        {selectedViewScreen === SUBSCRIPTION_SCREEN.SUCCESS && (
          <div className={isDesktop ? Styles.containerWeb : Styles.container}>
            <header>
              <ButtonBase
                className={Styles["crossButton"]}
                onClick={onBackDropPressHandler}
              >
                <CloseIconGray className={Styles.closeIcon} />
              </ButtonBase>
            </header>
            <div className={Styles.successContainer}>
              <span className={Styles.paymentSuccessTitle}>
                {isDesktop ? "Success" : "Success!"}
              </span>
              <ImportedContactsLogo
                style={{ marginTop: 40, width: "90%", height: isWeb ? 400 : "100%", paddingBottom: isWeb ? 150 : 0 }}
              />
              <div className={Styles.divBenefitsContainer}>
                <p className={Styles.paymentSuccessSubTitle}>
                  {cancelPlan ? `Your plan has been cancelled successfully.` : `Check out all that ${newPlan.title} has to offer!`}
                </p>
                {!cancelPlan && <div className={Styles.benefitsContainer}>
                  {newPlan.benefits.map((benefit, index) => (
                    <div key={index} className={Styles.benefitsContainer}>
                      <OkArrowGray />
                      <span
                        className={
                          index == 2 ? Styles.benefitBold : Styles.benefit
                        }
                      >
                        {benefit}
                      </span>
                    </div>
                  ))}
                </div>}
                <GreenBgButton
                  style={{ marginTop: 16, marginBottom: 16, height: 40 }}
                  onClick={goToDashboard}
                  fullWidth
                >
                  <span className={Styles.buyButtonTextStyleBold}>
                    {cancelPlan ? "Continue" : "Get Started"}
                  </span>
                </GreenBgButton>
              </div>
            </div>
          </div>
        )}

        {/* DOWNGRADE ALERT VIEW */}

        {showDowngradePlanAlert && alertMsgForDowngradePlan && (
          <Dialog
            open={true}
            PaperProps={{
              style: {
                backgroundColor: "transparent",
                boxShadow: "none",
                margin: 16,
              },
            }}
          >
            <div className={Styles.alertBox}>
              <ButtonBase
                className={Styles.crossButton}
                onClick={(e) => {
                  e.stopPropagation();
                  handleAlertCancelButtonPress()
                }}
              >
                <CloseIconGray className={Styles.closeIcon} />
              </ButtonBase>

              <span className={Styles.alertTextBold}>Before Changing...</span>

              <span className={Styles.alertTextMsg}>
                {alertMsgForDowngradePlan}
              </span>

              <div className={Styles.divAlertBtn}>
                <WhiteBgButtonGreenBorder
                  fullWidth
                  onClick={(e) => {
                    handleAlertCancelButtonPress();
                  }}
                >
                  <span className={Styles.alertBtnTextStyleGreen}>
                    Keep Current Subscription
                  </span>
                </WhiteBgButtonGreenBorder>

                <GreenBgButton
                  fullWidth
                  style={{ marginTop: 16 }}
                  onClick={() => {
                    handleAlertOkButtonPress();
                  }}
                >
                  <span className={Styles.alertBtnTextStyle}>
                    {cancelPlan ? "Cancel" : "Change"} Subscription
                  </span>
                </GreenBgButton>
              </div>

              {planChangeEffectMsg && (
                <span className={Styles.alertTextBillingCycle}>
                  {planChangeEffectMsg}
                </span>
              )}
            </div>
          </Dialog>
        )}

        {/* ALERT VIEW FOR CHANGE SUBSCRIPTION ON DIFFERENT PLATFORM */}

        {showChangeWarningAlert && (
          <Dialog
            open={true}
            PaperProps={{
              style: {
                backgroundColor: "transparent",
                boxShadow: "none",
                margin: 16,
              },
            }}
          >
            <div className={Styles.alertBox}>
              <ButtonBase
                className={Styles.crossButton}
                onClick={(e) => {
                  setShowChangeWarningAlert(false)
                }}
              >
                <CloseIconGray className={Styles.closeIcon} />
              </ButtonBase>

              <span className={Styles.alertTextBold}>Your Subscription</span>

              <span className={Styles.alertTextSemiBold}>
                {currentPlan.title}
              </span>

              <span className={Styles.alertTextMsg}>
                You are being billed for this plan by Google Play.
                For billing information, or to make changes to your subscription,
                please open the native Ferret App from your Android device.
              </span>

              <div className={Styles.divAlertBtn}>
                <ButtonBase
                  fullWidth
                  onClick={() => {
                    window.open("https://www.ferret.ai/mobile-support", "_blank")
                  }}
                >
                  <span className={Styles.changeWarningBtnTxtGreen}>
                    Contact Support
                  </span>
                </ButtonBase>

              </div>
            </div>
          </Dialog>
        )}

        {/* LOADER VIEW */}
        {(subscriptionLoading ||
          userLoading ||
          updateStripeSubsLoading ||
          createStripeSubsLoading ||
          cancelStripeSubsLoading ||
          setupPaymentIntentLoading) && (
            <Backdrop
              sx={{
                position: 'absolute',
                backgroundColor: "#00000000",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
      </>
    )
  }

  return (
    <>
      {
        isDesktop ?
          <>
            {subViewStateWise()}
          </>
          :
          <Modal open={true} sx={{ justifyContent: 'center', display: 'flex', }}>
            {subViewStateWise()}
          </Modal>
      }
    </>
  );
};


export default SubscriptionPlansModal;
export { FREE_PLAN_ID, SUBSCRIPTION_SCREEN };
