import { removeToken, setDisclaimer } from './safe-storage';
import { getApolloClient } from './cloud-config';
import Variables from './Variables';
import { setShowNotificationStatusAlert } from '../utils/safe-storage';

async function logout() {
  try {
    
    removeToken();
    getApolloClient()?.clearStore()
    setDisclaimer('false')
    setShowNotificationStatusAlert("true")
  }catch (error) {
    console.log(JSON.stringify(error))
  }finally {
    Variables.isFromLandingpage = false
    Variables.navigateRef('/login')
  }
}

export {
  logout
}
