
const createStyle = () => {
  return {
    titleGroup: {
      color: '#142030',
      fontFamily: 'Proxima Nova',
      fontSize: 14,
      fontWeight: '700',
    },
    titleGroupDark: {
      color: '#142030',
      fontFamily: 'Proxima Nova',
      fontSize: 14,
      fontWeight: '700',
      marginBottom: 0,
    },
  }
}
export default createStyle;

