import React from 'react'
import createStyle from './styles'
import TagAnimation from '../tagAnimation/tagAnimation'
import NoResultFound from '../../images/svg/no_result';

const createTagsConfiguration = () => {
  const isIOS = true
  const theme = {dark: false}
  return theme.dark ? {
    'watchlists': {
      source: require('../../assets/animations/loadFeed/dark/watchlists.json'),
    },
    'negativeNews': {
      source: require('../../assets/animations/loadFeed/dark/negative-news.json'),
    },
    'peps': {
      source: require('../../assets/animations/loadFeed/dark/peps.json'),
    },
    'papersAndLeaks': {
      source: isIOS ? require('../../assets/animations/loadFeed/dark/papers-and-leaks.json') : require('../../assets/animations/loadFeed/dark/papers-and-leaks-android.json'),
    },
    'corporateBeneficialOwnership': {
      source: require('../../assets/animations/loadFeed/dark/corporate-beneficial-ownership.json'),
    },
    'mostWanted': {
      source: require('../../assets/animations/loadFeed/dark/most-wanted.json'),
    },
    'corporations': {
      source: require('../../assets/animations/loadFeed/dark/corporate-records.json'),
    },
    'news': {
      source: require('../../assets/animations/loadFeed/dark/news.json'),
    },
    'courtCases': {
      source: require('../../assets/animations/loadFeed/dark/court-cases.json'),
    },
    'recap': {
      source: require('../../assets/animations/loadFeed/dark/recap-only.json'),
    },
    'recapWaiting': {
      source: require('../../assets/animations/loadFeed/dark/recap-waiting.json'),
    },
  } : {
    'watchlists': {
      source: require('../../assets/animations/loadFeed/light/watchlists.json'),
    },
    'negativeNews': {
      source: require('../../assets/animations/loadFeed/light/negative-news.json'),
    },
    'peps': {
      source: require('../../assets/animations/loadFeed/light/peps.json'),
    },
    'papersAndLeaks': {
      source: isIOS ? require('../../assets/animations/loadFeed/light/papers-and-leaks.json') : require('../../assets/animations/loadFeed/light/papers-and-leaks-android.json'),
    },
    'corporateBeneficialOwnership': {
      source: require('../../assets/animations/loadFeed/light/corporate-beneficial-ownership.json'),
    },
    'mostWanted': {
      source: require('../../assets/animations/loadFeed/light/most-wanted.json'),
    },
    'corporations': {
      source: require('../../assets/animations/loadFeed/light/corporate-records.json'),
    },
    'news': {
      source: require('../../assets/animations/loadFeed/light/news.json'),
    },
    'courtCases': {
      source: require('../../assets/animations/loadFeed/light/court-cases.json'),
    },
    'recap': {
      source: require('../../assets/animations/loadFeed/light/recap-only.json'),
    },
    'recapWaiting': {
      source: require('../../assets/animations/loadFeed/light/recap-waiting.json'),
    },
  }
}
const LoadFeedAnimation = (props) => {

  let timer;
  const tags = [
    'watchlists', 'negativeNews',
    'peps','papersAndLeaks',
    'news', 'mostWanted',
    'corporations',
  ]

  const {style: externalStyle, articleLoading = true, isNoResult = false, isSearchModal = false,refreshing,onHandleRefresh} = props
  const style = createStyle()
  const {finishAnimation, onFinishAnimation} = props
  const tagsConfiguration = createTagsConfiguration()
  
  const finalAnimation ='recap'

  clearInterval(timer)
  timer = setTimeout(()=>{
    onFinishAnimation(true)
  },2000)

  const tagsView = tags.map((tag, index) => {

    return (
      <div style={style.tagContainer}>
        <TagAnimation
          speed={tag === 'news' ? 5 : tag === 'corporations' ? 1 : 3}
          {...tagsConfiguration[tag]}
          style={style.tagAnimationStyle}
        />
      </div>
    )
  })

  return (
  <div style={{...externalStyle}}>
    <div style={{...style.container, ...externalStyle}}>
      {tagsView}
      <div style={style.tagContainer}>
        {!articleLoading ? 
        <TagAnimation
          speed={3}
          {...tagsConfiguration[finalAnimation]}
          style={style.tagAnimationStyle}
          loop={false}
        /> :
        <TagAnimation
          speed={0.5}
          {...tagsConfiguration[finalAnimation]}
          style={style.tagAnimationStyle}
          loop={true}
        />
        }
        </div>
      </div>
      { finishAnimation && isNoResult && <div>
        <NoResultFound/>
        { !isSearchModal && <span style={style.no_text}>No Matches Found</span>}
      </div>
      }
  </div>
  )
}

export default LoadFeedAnimation
