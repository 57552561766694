// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".article_articleTitle__GGesI{\n    color: #142030;\n    font-size: clamp(16px, 1.1vw, 18px);\n    font-weight: 700;\n    display: inline-block;\n}\n.article_selectDocument__29OPF {\n    font-size: 14px;\n    font-weight: 600;\n    font-family: \"Proxima Nova\";\n    margin-top: 12px;\n}", "",{"version":3,"sources":["webpack://./src/web/components/articleCard/article.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,mCAAmC;IACnC,gBAAgB;IAChB,qBAAqB;AACzB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,2BAA2B;IAC3B,gBAAgB;AACpB","sourcesContent":[".articleTitle{\n    color: #142030;\n    font-size: clamp(16px, 1.1vw, 18px);\n    font-weight: 700;\n    display: inline-block;\n}\n.selectDocument {\n    font-size: 14px;\n    font-weight: 600;\n    font-family: \"Proxima Nova\";\n    margin-top: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"articleTitle": "article_articleTitle__GGesI",
	"selectDocument": "article_selectDocument__29OPF"
};
export default ___CSS_LOADER_EXPORT___;
