import * as React from "react"

const EducationProfile = (props) => {
  return (
    <svg width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg"  {...props}>
    <path d="M10.5208 14.2438L2.12999 9.88428L10.5216 5.31959L19.7368 9.88421L10.5208 14.2438Z" stroke="#078167" stroke-width="2"/>
    <path d="M5 15.5859V11.5527L10.1895 13.9104L10.5943 14.0944L11.002 13.917L16.4248 11.5582L16.234 15.5707L10.6031 18.6436L5 15.5859Z" stroke="#078167" stroke-width="2"/>
    </svg>
  )
}
export default EducationProfile
