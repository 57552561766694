
import {SAVE_ARTICLE_RELEVANCY} from '../../queries/queries';
import { useMutation } from 'react-apollo';
import Variables from '../../utils/Variables';

const SaveArticleRelevancy = (reloadScreenData, setShowLoader, articleSource,
    searchTerm, savedContactID, recentSearchId, contactData, setRefreshList) => {

    const [saveArticleRelevancy] = useMutation(SAVE_ARTICLE_RELEVANCY, {
        onCompleted: data => {
            if (savedContactID == undefined || savedContactID != data?.saveArticleRelevancy?.contactId) {
                Variables.savedSearchId = data?.saveArticleRelevancy?.contactId
            }
            Variables.isAddSearches = true
            Variables.isRefreshClosestContacts = true
            Variables.savedRelevancyContactId = data?.saveArticleRelevancy?.contactId

            setRefreshList(true)
            setShowLoader?.(false)
        },
        onError: error => {
            console.log(error);
            setShowLoader?.(false)
            reloadScreenData?.()
        }
    });

    const parseAttributes = (attr) => {

        let relevanceInfo = {};
        let relevanceAttributes = [];
        attr?.map(item => {
            let arr = [];
            let others = false;
            let know = false

            if (item.groupName?.toLowerCase() == 'Organizations'.toLowerCase() || item.groupName == 'Places') {
                item.attributes.map((child) => {
                    if (child.status == true) {
                        if (child.type && child.type === 'textbox') {
                            others = child.value;
                        } else if (child.type && child.type === 'dont') {
                            know = true;
                        } else {
                            arr = [...arr, child.label];
                        }
                    }
                });
            }

            if (item.groupName?.toLowerCase() === 'Organizations'.toLowerCase() && others) {
                relevanceInfo.companyOthers = others;
            } else if (item.groupName === 'Places' && others) {
                relevanceInfo.placesOthers = others;
            }

            if (item.groupName?.toLowerCase() === 'Organizations'.toLowerCase()) {
                relevanceInfo.companyKnow = know;
            } else if (item.groupName === 'Places') {
                relevanceInfo.placesKnow = know;
            }

            if (arr.length > 0) relevanceAttributes = [...relevanceAttributes, { key: item.groupName, value: arr }]
        })

        relevanceInfo.relevanceAttributes = relevanceAttributes;

        return relevanceInfo;
    }


    const checkRelevancy = (attr) => {
        let obj = attr.find(a => a.groupName === 'People')
        if (obj) {
            let r = obj.attributes.find(o => o.status == true);
            if (r?.label == 'Not Sure') return 'MAYBE'
            else if (r?.label == 'No') return 'NO'
            else if (r?.label == 'Yes') return 'YES'
            else return undefined
        }
    }

    const relevantArticle = (article, isRelevantValue, potentialMatchId) => {
        setShowLoader?.(true);
        let r = parseAttributes(article?.attributes);
        let articleId = article.articleId
        let refId = article.id ? article.id : article.articleId
        let variables = {
            relevanceInfo: r,
            articleId: articleId,
            articleSource: articleSource ?? "FEED",
            searchTerm: searchTerm ? searchTerm : "",
            articleCategory : article?.category ?? ""
        };


        variables.contactId = savedContactID ? savedContactID : article.contactID ? article.contactID : "";

        if ((variables.contactId == undefined || variables.contactId == "") && Variables.contactId) {
            variables.contactId = Variables.contactId
        }

        if (recentSearchId) variables.recentSearchId = recentSearchId;

        if (refId != articleId) variables.refId = refId;

        // let isRelevanthasValue= checkRelevancy(article.attributes);
        // if (isRelevanthasValue) variables.isRelevant = isRelevanthasValue;
        // if(isRelevanthasValue && isRelevanthasValue === 'NO') Variables.articleIdMarkNo = refId;
        if (isRelevantValue) variables.isRelevant = isRelevantValue;
        if (isRelevantValue && isRelevantValue === 'NO') Variables.articleIdMarkNo = refId;
        if (contactData) variables.contactData = contactData;

        if (Variables.potentialMatchId) {
            variables.potentialMatchId = Variables.potentialMatchId
        }
        
        if (potentialMatchId) {
            variables.potentialMatchId = potentialMatchId
        }

        saveArticleRelevancy({
            variables,
        });
    }

    return {
        relevantArticle
    }
}

export default SaveArticleRelevancy