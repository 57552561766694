import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
} from "react";
import { UserContext } from "../../../contexts/userContext";
import SearchTextField from "./searchTextField";
import AdvancedSearch from "../advancedSearch/advancedSearch";
import back_white from "../../../images/svg/back_white.svg";
import ResetIcon from "../../../images/svg/resetIcon";
import AdvancedSearchUpArrowIcon from "../../../images/svg/advanced-search-up-arrow-icon";
import AdvancedSearchDownArrowIcon from "../../../images/svg/advanced-search-down-arrow-icon";
import RecentSearch from "../../recentSearch/recentSearch";
import { ShowSearchContext } from "../../../contexts/showSearchModal";
import DisclaimerModal from "../../disclaimer/disclaimerModal";
import { useMutation } from "react-apollo";
import useRefineSearch from "../useRefineSearch";
import {
  Save_Recent_Search,
  UPDATE_USER_DISCLAIMER,
} from "../../../queries/queries";
import { Question, optionsToAnswers } from "../Question";
import useQuestions from "../useQuestions";
import QuestionAnswerInterface from "../questionAnswerInterface/questionAnswerInterface";
import {
  setAnswerStatus,
  setValueToOhetAnswer,
  getMetadataFromQuestions,
  getSavedSearchParams,
  getCompleteSearchQuery,
  getNoResultMetadataFromQuestions,
} from "../questionsController";
import useIntialModalData from "../useIntialModalData";
import {
  shouldSearchQuestion,
  mainQuestion,
  person,
  company,
} from "../questionsAnswers";
import SaveAdHocQuery from "../noResults/saveAdHocQuery";
import addQueryData from "../../../utils/addQueryData";
import formatUserName from "../../../utils/formatUserName";
import Variables from "../../../utils/Variables";
import { capitalizeCase, validateNameHasTwoWords } from "../../../utils/utils";
import ColoredLinearProgress from "../../loadingBar/ColoredLinearProgress";
import { Button } from "@mui/material";
import SearchParams from "../../searchParams/searchParams";
import { useLocation, useNavigate } from "react-router-dom";
import SearchLimitExceedModal from "../../searchLimitExceedModal";
import { ModelType } from "../../searchLimitExceedModal/searchLimitExceedModal";
import SubscriptionPlansModal, {
  FREE_PLAN_ID,
} from "../../subscriptionPlans/subscriptionPlansModal";
import { searchResultStore } from "../../../store/searchResultStore";
import Styles from "./searchComponent.module.css";
import { checkCompanyInMetaData } from "../../addOrgModal/checkCompanyInMetaData";
import { searchFormStore } from "../../../store/searchFormStore";
import SingleBtnAlert from "../../alert/singleBtnAlert";
let timer = null;

const SearchComponent = () => {
  const location = useLocation();
  let props = location.state ?? {};

  const navigate = useNavigate();

  const removeAll = searchResultStore((state) => state.removeAll);

  const {
    fromClosestContact = false,
    inNewSearch = false,
    fromBackSearch = false,
    fromEditSearch = false,
    isRecentSearch = true,
    buttonText = props?.isEdit ? "Complete Search" : "Proceed with Search",
    isEdit = false,
    searchType = "",
    contactID,
    mPhoneNumberInput,
    mCityInput,
    mStateInput,
    mAgeInput = "",
    mQueryData,
    mSavedTracerQueryId,
    mSavedTahoeId,
    navigatedFrom = "",
    oldMetaValue = {},
    recentSearchId,
    searchSettings: {
      initialSearchString = "",
      intitialMetadata = {},
      qaMetaData = {},
      initialSearchParams = [],
      initialQuestions = [],
      initialPotentialMatch = [],
      isAdhocSearch = false,
    } = {},
    scrollPosition = 0,
    searchList,
  } = props;

  const mCountryInput =
    props?.isEdit || fromBackSearch
      ? props?.mCountryInput && props?.mCountryInput != ""
        ? props.mCountryInput
        : "United States"
      : "United States";
  const searchBarRef = useRef();
  const scrollQuestionsViewRef = useRef();
  const advancedSearchRef = useRef();
  const searchView = useRef();
  const searchViewBottom = useRef();
  const phoneRef = useRef();
  const [showSingleBtnAlert, setShowSingleBtnAlert] = useState({
    status: false,
    msg: "",
  });
  const BackIcon = useCallback(() => {
    return (
      <img
        src={back_white}
        className={Styles.back_white_search}
        onClick={onBackDropPressHandler}
      />
    );
  }, []);

  // save scroll Index
  const [scrollPositionResult, setScrollPosition] = useState(
    scrollPosition ?? 0
  );
  useEffect(() => {
    window.scrollTo(0, scrollPositionResult);
  }, []);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position > 0) setScrollPosition(position);
  };
  //////

  const {
    refineSearch,
    data,
    loading,
    error,
    resultTypes,
    getResultTypeFromRefineSearch,
    getQuestionFromRefineSearch,
    getArticleDetailsFromRefineSearch,
    getArticleFilterData,
    getPersonalInfo,
    getArticleTypeCount,
    mapSingleArticle,
    getReadArticlesIds,
    getAKAList,
    getCategoryFilterData,
    getPotentialMatchId,
    getContactId,
    getSearchId,
    getLinkedInData,
    getFeedbackOptions,
    getProfilePic,
  } = useRefineSearch();

  const {
    setQuestions,
    questions,
    addNewQuestion,
    cleanQuestions,
    potentialMatch,
    addPotentialMatches,
    addMultipleNewQuestion,
    getSearchParams,
  } = useQuestions(initialQuestions, initialPotentialMatch);

  const saveSearch = searchResultStore((state) => state.saveSearch);

  const [searchString, setSearchString] = useState(
    initialSearchString ? formatUserName(initialSearchString.trim()) : ""
  );
  const [recentSearchTerm, setRecentSearchTerm] = useState();
  const [phoneNumberInput, setPhoneNumber] = useState(mPhoneNumberInput);
  const [countryInput, setCountry] = useState(mCountryInput);
  const [cityInput, setCity] = useState(mCityInput);
  const [stateAbbr, setStateAbbr] = useState();
  const [stateInput, setState] = useState(mStateInput);
  const [ageInput, setAge] = useState(mAgeInput);
  const [searchArticleVisible, onHideSearchArticle] = useState(isRecentSearch);
  const [isClearActive, setCleanActive] = useState();
  const [searchName, setsearchName] = useState();
  const [agePotentialMatch, setAgePotentialMatch] = useState();
  const [searchParams, setSearchParams] = useState(initialSearchParams);
  const [irrelevantArticleId, setIrrelevantArticleId] =
    useState(recentSearchId);
  const [irrelevantArticles, setIrrelevantArticles] = useState();
  const [saveSearchButtonDisabled, setSaveSearchButtonDisabled] =
    useState(true);
  const [searchCompleted, setSearchCompleted] = useState(false);
  const [clickedPotential, setClickedPotential] = useState(false);
  const [isClickedPotential, setClickedPotentialList] = useState(false);
  const [clickedSavedSearch, setClickedSavedSearch] = useState(false);
  const [clickedEditSearch, setClickedEditSearch] = useState(false);
  const [savedContactID, setSavedContactId] = useState(contactID);
  const [savedTahoeId, setSavedTahoeId] = useState(mSavedTahoeId);
  const [savedTracerQueryId, setSavedTracerQueryId] =
    useState(mSavedTracerQueryId);
  const [queryData, setQueryData] = useState(mQueryData);
  const [oldMetaData, setOldMetaData] = useState(intitialMetadata);

  const [metaData, setMetaData] = useState(qaMetaData);
  const [metaDataArray, setMetaDataArray] = useState();
  const [keyboardEnabled, setKeyboardEnabled] = useState(false);
  const [submitButtonText, setButtonText] = useState(buttonText);
  const [submitButtonDisabled, setButtonDisabled] = useState(false);
  const [showConfirmSearch, setShowConfirmSearch] = useState(false);
  const isSaveSearch =
    questions[0]?.identifier === "form" ||
    questions[1]?.identifier === "form" ||
    questions[0]?.identifier === "noResultFound_SearchType";
  const [sectionIndex, setSectionIndex] = useState(0);
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(
    fromBackSearch ? false : true
  );
  const [keyboardShow, setKeyboardShow] = useState();
  const [allowScroll, setAllowScroll] = useState(true);
  const [showAdvancedSearchTooltip2, setShowAdvancedSearchTooltip2] =
    useState(false);
  const [showAdvancedSearchTooltip3, setShowAdvancedSearchTooltip3] =
    useState(false);
  const [showDisclaimer, setDisclaimerVisible] = useState(false);
  const [isSearchLimitExceed, setSearchLimitExceed] = useState(false);
  const [searchLimitExceedTitle, setSearchLimitExceedTitle] = useState(null);
  const [openPotentialMatch, setOpenPotentialMatch] = useState(false);
  const [mFromBackSearch, setFromBackSearch] = useState(fromBackSearch);
  const { user, setUser } = useContext(UserContext);
  const { isShowSearchModal, setShowSearchModal } =
    useContext(ShowSearchContext);

  const [progress, setProgress] = useState(0);
  const [subscriptionModalVisible, setSubscriptionModalVisible] =
    useState(false);
  const [eventTypeSaved, setEventTypeSaved] = useState("complete-search");
  const [lastQuery, setLastQuery] = useState();
  const [showAddOrgsModal, setShowAddOrgsModal] = useState();

  const setEditSearchData = searchFormStore((state) => state.setEditSearchData);

  let getAdvanceSearchFieldStateCount = () => {
    let count = 0;
    if (phoneNumberInput && phoneNumberInput != "") count++;
    if (countryInput && countryInput != "") count++;
    if (cityInput && cityInput != "") count++;
    if (stateInput && stateInput != "") count++;
    if (ageInput && ageInput != "" && ageInput != 0) count++;
    return count;
  };
  const [advanceSearchFieldStateCount, setAdvanceSearchFieldStateCount] =
    useState(getAdvanceSearchFieldStateCount());

  const normalise = (value) => ((value - 0) * 100) / (100 - 0);

  useEffect(() => {
    setAdvanceSearchFieldStateCount(getAdvanceSearchFieldStateCount());
    if (isClearActive && !clickedPotential && !clickedSavedSearch)
      restartStates(true, false);
  }, [phoneNumberInput, cityInput, countryInput, ageInput, searchString]);

  useEffect(() => {
    setAdvanceSearchFieldStateCount(getAdvanceSearchFieldStateCount());
    if (
      isClearActive &&
      stateInput != null &&
      !clickedPotential &&
      !clickedSavedSearch
    )
      restartStates(true, false);
  }, [stateInput]);

  const { saveAdHocAndParse } = SaveAdHocQuery();

  const [updateUserDisclaimer, { loading: userDisclaimerloading }] =
    useMutation(UPDATE_USER_DISCLAIMER, {
      fetchPolicy: "no-cache",
      onCompleted: ({ updateUserDisclaimer }) => {
        if (updateUserDisclaimer && updateUserDisclaimer.status === 200) {
          const updateUserInfo = { ...user, isDisclaimerAccepted: true };
          setUser(updateUserInfo);
          setDisclaimerVisible(false);
        }
      },
    });

  const { loadingData, searchSettings, getModalDataForMetaTag } =
    useIntialModalData({}, "", "", potentialMatch, mFromBackSearch);

  useEffect(() => {
    if (
      (clickedSavedSearch || clickedPotential || clickedEditSearch) &&
      !loadingData &&
      searchSettings &&
      searchSettings?.initialSearchString?.length
    ) {
      goToResults();
      stopProgressBar();

      let searchData = { ...searchSettings };
      let data = {
        inNewSearch: true,
        fromEditSearch: true,
        isRecentSearch: false,
        fromBackSearch: false,
        searchSettings: searchData,
        cleanStateOnBlur: false,
        isAutoFocus: false,
        isEdit: true,
        mPhoneNumberInput: phoneNumberInput,
        mCountryInput: countryInput,
        mCityInput: cityInput,
        mStateInput: stateInput,
        mAgeInput: ageInput,
        mQueryData: queryData,
        mSavedTracerQueryId: savedTracerQueryId,
        mSavedTahoeId: savedTahoeId,
        recentSearchId: irrelevantArticleId,
        qaMetaData: metaData,
      };

      setEditSearchData(data);
    }
  }, [searchSettings]);

  useEffect(() => {
    if (questions && questions?.length)
      setSearchParams(
        getSearchParams(
          searchName ?? searchString,
          null,
          phoneNumberInput,
          countryInput,
          cityInput,
          stateInput,
          ageInput
        )
      );
  }, [questions]);

  const onBackdropPress = () => {
    if (!isEdit) {
      Variables.potentialMatchId = null;
      Variables.contactId = null;
    }
    setShowSearchModal(false);
    navigate(-1, { replace: true });
  };

  const startProgressBar = () => {
    {
      let prog = 0;
      clearInterval(timer);
      timer = setInterval(() => {
        prog += 1;
        if (prog > 90) {
          return;
        } else {
          setProgress(prog);
        }
      }, 150);
    }
  };

  const stopProgressBar = () => {
    clearInterval(timer);
    setProgress(0);
  };

  useEffect(() => {
    if (!!data && !loading) {
      if (
        data?.refine_search?.result_type?.toUpperCase() ===
        "SUBSCRIPTION LIMIT REACHED"
      ) {
        stopProgressBar();
        setOpenPotentialMatch(false);
        setSearchLimitExceedTitle(`You Have Reached Your Search Limit`);
        setSearchLimitExceed(true);
        const updateUserInfo = { ...user, searchLimitExceed: true };
        setUser(updateUserInfo);
      } else {
        if (clickedPotential) {
          let currentMonthAdhocSearchCount =
            user.adhocSearchLimitPerMonth -
            data?.refine_search?.currentMonthAdhocSearchCount;
          let divideByThree = parseInt(user.adhocSearchLimitPerMonth / 3);
          if (
            currentMonthAdhocSearchCount == divideByThree ||
            currentMonthAdhocSearchCount < 3
          ) {
            stopProgressBar();
            setSearchLimitExceed(true);
            setOpenPotentialMatch(true);
            switch (true) {
              case currentMonthAdhocSearchCount === divideByThree:
                setSearchLimitExceedTitle(
                  `You Have ${currentMonthAdhocSearchCount} Free Searches Left This Month`
                );
                break;
              case currentMonthAdhocSearchCount === 2:
                setSearchLimitExceedTitle(
                  `You Have 2 Searches Left This Month`
                );
                break;
              case currentMonthAdhocSearchCount === 1:
                setSearchLimitExceedTitle(
                  `You Have 1 Free Searches Left This Month`
                );
                break;
              case currentMonthAdhocSearchCount === 0:
                setOpenPotentialMatch(false);
                setSearchLimitExceedTitle(`You Have Reached Your Search Limit`);
                const updateUserInfo = { ...user, searchLimitExceed: true };
                setUser(updateUserInfo);
              default:
                break;
            }
          } else {
            openPotentialMatchScreen();
          }
        } else {
          openPotentialMatchScreen();
        }
      }
    }
  }, [data, loading]);

  useEffect(() => {
    if (!loading && error) {
      stopProgressBar()
      setShowSingleBtnAlert({
        status: true,
        msg: "Did not connect to server. Please try again.",
      });
    }
  }, [error, loading])

  const openPotentialMatchScreen = () => {
    if (searchCompleted) {
      saveSearches(clickedSavedSearch ? eventTypeSaved : "complete-search"); // change potential-match-search to complete-search for ticket 4124
      return;
    }
    stopProgressBar();
    const resultType = getResultTypeFromRefineSearch();
    if (resultType === resultTypes.QUESTION) {
      setShowAdvancedSearch(false);
      const {
        question: label,
        options,
        attribute,
        meta_data,
        potentialMatches,
        QA_data,
      } = getQuestionFromRefineSearch();
      setMetaData(meta_data);
      if (attribute === "form") {
        onHideSearchArticle(false);
        let {
          question: label,
          options,
          attribute,
          meta_data = "{}",
          potentialMatches = null,
        } = shouldSearchQuestion;
        const answers = options.map(optionsToAnswers);
        const newQuestion = Question(
          attribute,
          label,
          answers,
          JSON.parse(meta_data),
          potentialMatches
        );
        setButtonText("Save Search");
        setButtonDisabled(true);
        addNewQuestion(newQuestion);
      } else if (options !== null) {
        setButtonText("Complete Search");
        const answers = options.map(optionsToAnswers);
        const newQuestion = Question(
          attribute,
          label,
          answers,
          JSON.parse(meta_data),
          potentialMatches
        );
        addNewQuestion(newQuestion);
      } else if (QA_data != null && QA_data.length) {
        setButtonText("Proceed With Search");
        let mNewQuestion = [];
        QA_data.forEach((item) => {
          const { question: label, options, attribute } = item;
          const answers = options.map(optionsToAnswers);
          mNewQuestion.push(Question(attribute, label, answers));
        });
        addMultipleNewQuestion(mNewQuestion);
      }

      if (potentialMatches !== null) {
        addPotentialMatches(potentialMatches);
      }
    } else if (resultType === resultTypes.ARTICLES && metaData) {
      setSearchParams(
        getSearchParams(
          searchName ?? searchString,
          null,
          phoneNumberInput,
          countryInput,
          cityInput,
          stateInput,
          ageInput
        )
      );
      setSearchCompleted(true);
      saveSearches("complete-search");
    }
  };

  useEffect(() => {
    setDisclaimerVisible(!user.isDisclaimerAccepted);

    if (
      initialQuestions?.[0]?.identifier === "form" ||
      initialQuestions?.[1]?.identifier === "form" ||
      initialQuestions?.[0]?.identifier === "noResultFound_SearchType"
    ) {
      setButtonText("Save Search");
    }

    setTimeout(() => {
      setCleanActive(true);
    }, 200);
  }, []);

  const onContinuePress = async () => {
    updateUserDisclaimer({
      variables: {
        data: {
          isDisclaimerAccepted: true,
        },
      },
    });
  };

  const startFreeTrial = () => {
    setSubscriptionModalVisible(true);
    setSearchLimitExceed(false);
  };

  const navigateToDashboard = () => {
    setSearchLimitExceed(false);
    if (!openPotentialMatch) {
      navigate(-1, { replace: true });
    } else {
      setOpenPotentialMatch(false);
      startProgressBar();
      openPotentialMatchScreen();
    }
  };

  const restartStates = (forceReset = false, isResetField = true) => {
    if (isResetField) {
      setSearchString(
        forceReset
          ? ""
          : initialSearchString
            ? formatUserName(initialSearchString.trim())
            : ""
      );
      setPhoneNumber("");
      setCountry("United States");
      setCity("");
      setState("");
      setAge("");
      setStateAbbr("");
      setClickedSavedSearch(false);
      setMetaData(forceReset ? {} : qaMetaData);
      if (advancedSearchRef?.current) advancedSearchRef.current.onResetForm();
      setIrrelevantArticleId();
    }
    addPotentialMatches([]);
    setsearchName();
    setSearchParams(forceReset ? [] : initialSearchParams);
    setQuestions(forceReset ? [] : initialQuestions);
    setSearchCompleted(false);
    setClickedPotential(false);
    setButtonText(forceReset ? "Proceed with Search" : buttonText);
    setButtonDisabled(false);
    setShowConfirmSearch(false);
    setQueryData();
    setSavedTahoeId();
    setSavedTracerQueryId();
  };

  const focusSearchBar = (data) => {
    const { updated = 0 } = data; // updated => fieldType
    if (updated == 0) {
      if (!showAdvancedSearch) searchBarRef.current.focus();
      else advancedSearchRef?.current?.setFocus(updated);
    } else {
      if (showAdvancedSearch) advancedSearchRef?.current?.setFocus(updated);
      else {
        setShowAdvancedSearch(true);
        setTimeout(() => {
          advancedSearchRef?.current?.setFocus(updated);
        }, 100);
      }
    }
  };

  const searchData = (
    searchTerm,
    resp,
    id,
    irrelevantArticles,
    contact,
    searchType = "",
    tracerQueryId,
    tahoeId,
    searchContext,
    searchCompleted,
    age,
    akaList,
    eventType
  ) => {
    if (searchContext) {
      setAge(searchContext?.age?.toString() ?? "");
      setCity(capitalizeCase(searchContext?.city ?? ""));
      setCountry(searchContext?.country ?? "");
      setPhoneNumber(searchContext?.phone ?? "");
      setState(searchContext?.state ?? "");
    }
    setAgePotentialMatch(age);
    setEventTypeSaved(eventType);
    setIrrelevantArticleId(id);
    setSearchString(searchTerm ? formatUserName(searchTerm.trim()) : "");

    if (searchCompleted != true) {
      setMetaData();
      onSubmitEditingSearchbarHandler(
        true,
        searchTerm,
        searchContext?.phone ?? "",
        searchContext?.country ?? "",
        searchContext?.city ?? "",
        searchContext?.state ?? "",
        searchContext?.age?.toString() ?? "",
        id
      );
      return;
    }

    setRecentSearchTerm(formatUserName(searchContext.title.trim()));
    setSavedContactId(contact);
    setSavedTahoeId(tahoeId);
    setSavedTracerQueryId(tracerQueryId);
    setIrrelevantArticles(irrelevantArticles);
    setsearchName(searchTerm);
    setClickedPotential(eventType == "potential-match-search");

    let placeL = resp?.Place?.join(";")?.toString();
    let mPlace = "";
    if (searchContext?.city && searchContext?.city != "")
      mPlace = searchContext.city;
    if (searchContext?.state && searchContext?.state != "")
      mPlace =
        mPlace != ""
          ? mPlace + ", " + searchContext.state
          : searchContext.state;
    if (searchContext?.country && searchContext?.country != "")
      mPlace =
        mPlace != ""
          ? mPlace + ", " + searchContext.country
          : searchContext.country;

    var metaDataLocal = {
      Person: resp?.Person[0] ?? (searchType === "PERSON" ? searchTerm : ""),
      Company: resp?.Company?.join(";")?.toString(),
      Place: placeL,
    };

    var metaDataRequest = {
      Person: resp?.Person[0] ?? (searchType === "PERSON" ? searchTerm : ""),
      Company: resp?.Company?.join(";")?.toString(),
      Place:
        placeL && placeL != ""
          ? mPlace
            ? placeL + ";" + mPlace
            : placeL
          : mPlace,
    };

    if (searchContext?.phone) {
      metaDataLocal.Phone = searchContext?.phone;
      metaDataRequest.Phone = searchContext?.phone;
    }

    setMetaData(metaDataLocal);

    let metaArrayLocal = {
      Person: resp?.Person[0] ?? (searchType === "PERSON" ? searchTerm : ""),
      Company: resp?.Company,
      Place: resp?.Place,
    };
    setMetaDataArray(metaArrayLocal);

    if (!validateNameHasTwoWords(searchTerm)) {
      setShowSingleBtnAlert({
        status: true,
        msg: "Please enter First name and Last name in the search",
      });
      return;
    }

    setSearchCompleted(true);
    const query = {
      variables: {
        fullname: {
          search: "search",
          meta_data: JSON.stringify(metaDataRequest),
          source: "ADHOC_SEARCH",
        },
      },
    };

    if (contact) query.variables.fullname.contactId = contact;

    if (tracerQueryId) query.variables.fullname.tracerQueryId = tracerQueryId;

    if (tahoeId) query.variables.fullname.tahoeId = tahoeId;

    setShowAddOrgsModal(
      checkCompanyInMetaData(query, setShowAddOrgsModal, (query, company) => {
        startProgressBar();
        setClickedSavedSearch(true);
        setLastQuery(query);
        refineSearch(query);

        if (company && company?.length > 0) {
          setMetaData({
            ...metaDataLocal,
            Company: company?.join(";")?.toString(),
          });
          setMetaDataArray({ ...metaArrayLocal, Company: company });
        }
      })
    );
  };

  const saveSearches = (eventType, mQueryData, id) => {
    const name = searchName
      ? searchName
      : searchString
        ? searchString
        : mQueryData?.title ?? "";
    let newMetadata =
      clickedPotential || clickedSavedSearch
        ? metaData
        : getMetadataFromQuestions(questions, metaData);
    if (JSON.stringify(newMetadata) === "{}") {
      newMetadata = {
        Person: name,
      };
      setMetaData(JSON.stringify(newMetadata));
    }

    const query = {
      variables: {
        data: {
          searchTerm: name,
          searchType:
            newMetadata.Person && newMetadata.Person != ""
              ? "PERSON"
              : newMetadata?.Company?.toLowerCase() == name?.toLowerCase()
                ? "COMPANY"
                : newMetadata?.Location?.toLowerCase() == name?.toLowerCase()
                  ? "LOCATION"
                  : newMetadata?.Place?.toLowerCase() == name?.toLowerCase()
                    ? "PLACE"
                    : newMetadata?.Nationality?.toLowerCase() == name?.toLowerCase()
                      ? "PLACE"
                      : "PERSON",
          eventType: eventType,
          metaData: {
            Person: [newMetadata.Person ? newMetadata.Person : ""],
            Company:
              clickedSavedSearch && metaDataArray.Company
                ? metaDataArray.Company
                : [newMetadata.Company ? newMetadata.Company : ""],
            Location: [newMetadata.Location ? newMetadata.Location : ""],
            Place:
              clickedSavedSearch && metaDataArray.Place
                ? metaDataArray.Place
                : [newMetadata.Place ? newMetadata.Place : ""],
            Nationality: [
              newMetadata.Nationality ? newMetadata.Nationality : "",
            ],
          },
        },
      },
    };

    if (savedTahoeId) query.variables.data.tahoeId = savedTahoeId;
    if (savedTracerQueryId)
      query.variables.data.tracerQueryId = savedTracerQueryId;
    if (mQueryData) {
      let searchC = { ...mQueryData };
      delete searchC["stateAbbr"];
      delete searchC["countryAbbr"];
      delete searchC["originalPhone"];
      query.variables.data.searchContext = searchC;
    } else if (queryData) {
      let searchC = { ...queryData };
      delete searchC["stateAbbr"];
      delete searchC["countryAbbr"];
      delete searchC["originalPhone"];
      query.variables.data.searchContext = searchC;
    } else {
      let recentQueryData = {
        title: recentSearchTerm ? recentSearchTerm : searchString,
        entityType: sectionIndex == 0 ? "PERSON" : "BUSINESS",
      };

      if (ageInput && ageInput != "") recentQueryData.age = parseInt(ageInput);

      if (cityInput && cityInput != "") recentQueryData.city = cityInput;

      if (stateInput && stateInput != "") recentQueryData.state = stateInput;

      if (countryInput && countryInput != "")
        recentQueryData.country = countryInput;

      if (phoneNumberInput && phoneNumberInput != "")
        recentQueryData.phone = phoneNumberInput;

      query.variables.data.searchContext = recentQueryData;
      setQueryData(recentQueryData);
    }

    const akaList = getAKAList();
    if (akaList && akaList.length) {
      query.variables.data.akaList = akaList;
    }

    if (agePotentialMatch) {
      query.variables.data.age = agePotentialMatch;
    }

    if (
      irrelevantArticleId &&
      (clickedPotential || clickedSavedSearch || isEdit)
    )
      query.variables.data.id = irrelevantArticleId;

    Variables.potentialMatchId = getPotentialMatchId();
    if (
      Variables.potentialMatchId &&
      Object.getOwnPropertyNames(Variables.potentialMatchId).length != 0
    )
      query.variables.data.uuid = Variables.potentialMatchId;

    save_Article(query);
  };

  const onGetQABotOption = () => {
    const name = searchName ? searchName : searchString;
    const searchType =
      metaData?.Person && metaData?.Person != ""
        ? "PERSON"
        : metaData?.Company?.toLowerCase() == name?.toLowerCase()
          ? "COMPANY"
          : metaData?.Location?.toLowerCase() == name?.toLowerCase()
            ? "LOCATION"
            : metaData?.Place?.toLowerCase() == name?.toLowerCase()
              ? "PLACE"
              : metaData?.Nationality?.toLowerCase() == name?.toLowerCase()
                ? "PLACE"
                : "PERSON";
    let newMetadata =
      clickedPotential || clickedSavedSearch
        ? metaData
        : getMetadataFromQuestions(questions, metaData);
    getModalDataForMetaTag(
      searchType,
      newMetadata,
      name,
      phoneNumberInput,
      countryInput,
      cityInput,
      stateInput,
      ageInput
    );
  };

  const [save_Article, { loading: loadingSaveSeaches }] = useMutation(
    Save_Recent_Search,
    {
      fetchPolicy: "no-cache",
      onCompleted(data) {
        setIrrelevantArticleId(data.saveRecentSearch.id);
        setIrrelevantArticles(data.saveRecentSearch.irrelevantArticles);
        if (searchCompleted && !clickedPotential && !clickedSavedSearch) {
          setClickedEditSearch(true);
          onGetQABotOption();
        } else if (clickedSavedSearch || clickedPotential) {
          onGetQABotOption();
        }
      },
      onError(error) {
        if (searchCompleted && !clickedPotential && !clickedSavedSearch) {
          setClickedEditSearch(true);
          onGetQABotOption();
        } else if (clickedSavedSearch || clickedPotential) {
          onGetQABotOption();
        }
      },
    }
  );

  const onPotentialSelected = (item) => {
    // setSavedContactId(item.ContactId) //removed contactID because it's not related to login user
    setSavedTahoeId(item.TahoeId);
    setSavedTracerQueryId(item.TracerQueryId);
    setsearchName(item.Person);
    setAgePotentialMatch(item.age);

    let metadata = {};
    if (item?.Company && item?.Company?.length) {
      item?.Company?.forEach((item) => {
        if (item)
          metadata.Company = metadata.Company
            ? metadata.Company + ";" + item
            : item;
      });
    }
    if (item?.Person) metadata.Person = item.Person;

    if (item?.Place && item?.Place?.length) {
      item?.Place?.forEach((item) => {
        let place;
        if (item) {
          if (
            item?.city &&
            queryData?.city?.toLowerCase() != item?.city?.toLowerCase()
          )
            place = item.city;
          if (
            item?.state &&
            queryData?.state?.toLowerCase() != item?.state?.toLowerCase()
          )
            place = place ? place + ", " + item.state : item.state;
          if (
            item?.country &&
            queryData?.country?.toLowerCase() != item?.country?.toLowerCase()
          )
            place = place ? place + ", " + item.country : item.country;
        }
        if (place)
          metadata.Place = metadata.Place
            ? metadata.Place + ";" + place
            : place;
      });
    }

    if (queryData?.city) queryData.city = queryData.city.toLowerCase();

    setMetaData(metadata);


    let metadataQuery = addQueryData(queryData, { ...metadata });

    let variables = {
      fullname: {
        search: "search",
        meta_data: JSON.stringify(metadataQuery),
        source: "ADHOC_SEARCH",
      },
    };

    // if(item?.ContactId) variables.fullname.contactId=item.ContactId //Removed contactID because it's not related to login user
    if (item?.TahoeId) variables.fullname.tahoeId = item.TahoeId;
    if (item?.TracerQueryId)
      variables.fullname.tracerQueryId = item.TracerQueryId;

    let query = {
      variables,
    };

    setShowAddOrgsModal(
      checkCompanyInMetaData(query, setShowAddOrgsModal, (query, company) => {
        startProgressBar();
        setSearchCompleted(true);
        setClickedPotential(true);
        setClickedPotentialList(true);
        setLastQuery(query);
        refineSearch(query);

        if (company && company?.length > 0) {
          setMetaData({ ...metadata, Company: company?.join(";")?.toString() });
        }
      })
    );
  };

  const goToResults = (id, articles, contactId) => {
    const articleDetails = getArticleDetailsFromRefineSearch();
    if (!articleDetails) {
      setSearchCompleted(false);
      return;
    }
    Variables.potentialMatchId = getPotentialMatchId();
    Variables.contactId = getContactId();
    const readArticles = getReadArticlesIds();
    const articleFilterData = getArticleFilterData();
    const aKAList = getAKAList();
    const personalInfo = getPersonalInfo();
    const articleTypeCount = getArticleTypeCount();
    const parsedArticles = articleDetails
      .filter((item) => {
        return item;
      })
      .map((item) =>
        mapSingleArticle(item, searchName ?? searchString, readArticles)
      ); // filter null article
    const metaTags = clickedPotential
      ? metaData
      : getMetadataFromQuestions(questions, metaData);
    const paramsTag =
      clickedSavedSearch || clickedPotential
        ? getSavedSearchParams(metaData)
        : searchParams;
    const name = searchName ? searchName : searchString;
    const data = {
      inNewSearch: inNewSearch,
      lastQuery: lastQuery,
      irrelevantArticleId: id ? id : irrelevantArticleId,
      irrelevantArticles: articles ? articles : irrelevantArticles,
      metadata: metaTags,
      searchTerm: searchName,
      searchType:
        metaTags?.Person && metaTags?.Person != ""
          ? "PERSON"
          : metaTags?.Company?.toLowerCase() == name?.toLowerCase()
            ? "COMPANY"
            : metaTags?.Location?.toLowerCase() == name?.toLowerCase()
              ? "LOCATION"
              : metaTags?.Place?.toLowerCase() == name?.toLowerCase()
                ? "PLACE"
                : metaTags?.Nationality?.toLowerCase() == name?.toLowerCase()
                  ? "PLACE"
                  : "PERSON",
      articles: parsedArticles,
      savedContactID: contactId ?? savedContactID,
      savedTahoeId: savedTahoeId,
      savedTracerQueryId: savedTracerQueryId,
      articleFilterData: articleFilterData,
      personalInfo: personalInfo,
      articleTypeCount: articleTypeCount,
      clickedPotential: clickedPotential,
      potentialsearch: searchString,
      aKAList: aKAList,
      searchSettings:
        clickedSavedSearch || clickedPotential || clickedEditSearch
          ? { ...searchSettings, isAdhocSearch: true }
          : {
            initialSearchString: name,
            intitialMetadata: metaTags,
            initialSearchParams: paramsTag,
            initialQuestions: questions,
            qaMetaData: metaData,
            initialPotentialMatch: potentialMatch,
            isAdhocSearch: true,
          },
      mPhoneNumberInput: phoneNumberInput,
      mCountryInput: countryInput,
      mCityInput: cityInput,
      mStateInput: stateInput,
      mAgeInput: ageInput,
      queryData: queryData,
      potentialMatchId: getPotentialMatchId(),
      mOldMetaData: oldMetaData,
      navigatedFrom: navigatedFrom,
      categoryFilterData: getCategoryFilterData(),
      articleSource: clickedSavedSearch ? "SEARCH" : "SEARCH",
      navigatedFrom: !inNewSearch && isEdit ? "SavedContactsArticles" : "",
      scrollPosition: scrollPositionResult,
      searchIds: getSearchId(),
      linkedInData: getLinkedInData(),
      feedbackOptions: getFeedbackOptions(),
      profilePic: getProfilePic(),
    };

    if (fromClosestContact) {
      Variables.newAddHocAdded = data;
      navigate(-1, { replace: true });
      return;
    }
    removeAll();
    if (fromEditSearch) {
      setTimeout(() => {
        navigate("/SearchResultsWithQuery", { state: data });
      }, 100);
      if (searchList) {
        navigate(-3);
      } else {
        navigate(-2);
      }
    } else if (isClickedPotential) {
      navigate("/SearchResultsWithQuery", { state: data, replace: true });
    } else {
      navigate("/SearchResultsWithQuery", { state: data });
    }
  };

  const onPressAnswerHandler = (indexOfQuestion) => (indexOfAnswer) => {
    setAllowScroll(false);
    const question = questions[indexOfQuestion];
    const oldQuestion = question?.answers[indexOfAnswer];
    if (question && oldQuestion) {
      let newQuestions = setAnswerStatus(
        questions,
        indexOfQuestion,
        indexOfAnswer,
        !oldQuestion.status,
        question?.identifier === "next_attribute" ||
        question?.identifier === "potential_matches" ||
        question?.identifier === "entity_select" ||
        question?.identifier === "noResultFound_details" ||
        question?.identifier === "noResultFound_SearchType" ||
        question?.identifier === "noResultFound_Company_Called" ||
        question?.identifier === "noResultFound_Company_Based" ||
        question?.identifier === "noResultFound_Person_Live" ||
        question?.identifier === "noResultFound_Person_Live_Work",
        question?.identifier === "next_attribute" ||
        question?.identifier === "potential_matches" ||
        question?.identifier === "entity_select" ||
        question?.identifier === "noResultFound_details" ||
        question?.identifier === "noResultFound_SearchType"
      );
      if (
        !oldQuestion.status &&
        (question?.identifier === "next_attribute" ||
          question?.identifier === "potential_matches" ||
          question?.identifier === "entity_select")
      ) {
        setQuestions(newQuestions);
        const query = {
          variables: {
            fullname: {
              attribute: question.identifier,
              value: oldQuestion.value,
              meta_data: JSON.stringify(question.meta_data),
              source: "ADHOC_SEARCH",
            },
          },
        };
        setSearchCompleted(false);
        setClickedPotential(false);
        setClickedSavedSearch(false);
        setsearchName(null);
        if (submitButtonText != "Complete Search") setButtonText(buttonText);
        startProgressBar();
        setLastQuery(query);
        refineSearch(query);
      } else if (question?.identifier === "noResultFound_details") {
        if (!oldQuestion.status) {
          let mNewQuestion = [];
          newQuestions.forEach((item) => mNewQuestion.push(item));
          if (oldQuestion?.label === "Provide Details") {
            setButtonDisabled(true);
            setButtonText("Save Search");
            const {
              question: label,
              options,
              attribute,
              meta_data = "{}",
              potentialMatches = null,
            } = mainQuestion;
            const answers = options.map(optionsToAnswers);
            const newQuestion = Question(
              attribute,
              label,
              answers,
              JSON.parse(meta_data),
              potentialMatches
            );
            mNewQuestion.push(newQuestion);
          } else {
            setButtonDisabled(false);
            setButtonText("Close");
          }
          addMultipleNewQuestion(mNewQuestion, false);
        } else {
          setButtonDisabled(true);
          setButtonText("Save Search");
          setQuestions(newQuestions);
        }
      } else if (question?.identifier === "noResultFound_SearchType") {
        setButtonDisabled(oldQuestion.status);
        if (!oldQuestion.status) {
          let mNewQuestion = [];
          newQuestions.forEach((item) => mNewQuestion.push(item));
          if (oldQuestion?.label === "Person") {
            person.forEach((item) => {
              const { question: label, options, attribute } = item;
              const answers = options.map(optionsToAnswers);
              mNewQuestion.push(Question(attribute, label, answers));
            });
          } else if (oldQuestion?.label === "Company") {
            company(searchString).forEach((item) => {
              const { question: label, options, attribute } = item;
              const answers = options.map(optionsToAnswers);
              mNewQuestion.push(Question(attribute, label, answers));
            });
          }
          addMultipleNewQuestion(mNewQuestion, false);
        } else {
          setQuestions(newQuestions);
        }
      } else {
        setQuestions(newQuestions);
      }
    }
  };

  const onEditingOtherInputHandler = (indexOfQuestion) => (value) => {
    if (!loading) {
      const updatedQuestions = setValueToOhetAnswer(
        questions,
        indexOfQuestion,
        value
      );
      setQuestions(updatedQuestions);
    }
  };

  const onReset = () => {
    if (!loading) {
      restartStates(true);
      onHideSearchArticle(true);
    }
  };

  const onSubmitEditingOtherInputHandler =
    (indexOfQuestion) => (indexOfAnswer) => { };

  const onBackDropPressHandler = () => {
    onBackdropPress();
  };

  const onSubmitEditingSearchbarHandler = (
    focus = true,
    initialSearchString,
    mPhoneNumberInput,
    mCountryInput,
    mCityInput,
    mStateInput,
    mAgeInput,
    id
  ) => {
    setFromBackSearch(false);
    setAllowScroll(false);
    if (loading || loadingData) return;
    setOldMetaData(oldMetaValue);
    let searchValue = "";
    if (initialSearchString && initialSearchString != "") {
      searchValue = initialSearchString;
    } else searchValue = searchString?.trim() || "";

    if (!validateNameHasTwoWords(searchValue)) {
      setShowSingleBtnAlert({
        status: true,
        msg: "Please enter First name and Last name in the search",
      });
      return;
    }

    if (searchValue && searchValue != "") {
      const query = {
        variables: {
          fullname: {
            queryData: {
              title: searchValue,
              entityType: sectionIndex == 0 ? "PERSON" : "BUSINESS",
            },
            source: "ADHOC_SEARCH",
          },
        },
      };
      if (ageInput && ageInput != "")
        query.variables.fullname.queryData.age = parseInt(ageInput);
      else if (mAgeInput && mAgeInput != "")
        query.variables.fullname.queryData.age = parseInt(mAgeInput);

      if (cityInput && cityInput != "")
        query.variables.fullname.queryData.city = cityInput;
      else if (mCityInput && mCityInput != "")
        query.variables.fullname.queryData.city = mCityInput;

      if (stateInput && stateInput != "")
        query.variables.fullname.queryData.state = stateInput;
      else if (mStateInput && mStateInput != "")
        query.variables.fullname.queryData.state = mStateInput;

      if (countryInput && countryInput != "")
        query.variables.fullname.queryData.country = countryInput;
      else if (mCountryInput && mCountryInput != "")
        query.variables.fullname.queryData.country = mCountryInput;

      if (phoneNumberInput && phoneNumberInput != "")
        query.variables.fullname.queryData.phone = phoneNumberInput;
      else if (mPhoneNumberInput && mPhoneNumberInput != "")
        query.variables.fullname.queryData.phone = mPhoneNumberInput;

      // setButtonText(buttonText);
      setButtonDisabled(false);
      setShowConfirmSearch(false);
      setQueryData(query.variables.fullname.queryData);
      if (!isEdit) {
        setSavedContactId();
      }
      setSavedTahoeId();
      setSavedTracerQueryId();
      startProgressBar();
      setLastQuery(query);
      refineSearch(query);
      if (focus) {
        setTimeout(() => {
          saveSearches("adhoc-search", query.variables.fullname.queryData, id);
        }, 400);
      }
    }
    cleanQuestions();
    onHideSearchArticle(false);
    setsearchName(null);
    setSearchCompleted(false);
    setClickedPotential(false);
    setClickedSavedSearch(false);
    setMetaData({});
  };
  const onSubmitModalHandler = () => {
    setFromBackSearch(false);
    if (loading || loadingData) return;
    if (!submitButtonDisabled && submitButtonText === "Save Search") {
      saveAdHocAndParse(
        getNoResultMetadataFromQuestions(questions, searchString),
        savedContactID
      );
      setShowConfirmSearch(true);
      setButtonText("Close");
    } else if (!submitButtonDisabled && submitButtonText === "Close") {
      onBackdropPress(true);
    } else {
      setSearchCompleted(true);
      startProgressBar();
      let queryParms = getCompleteSearchQuery(
        questions,
        metaData,
        queryData,
        savedTahoeId,
        savedTracerQueryId
      );
      setLastQuery(queryParms);
      refineSearch(queryParms);
    }
  };

  const onClickSearchParamHandler = (data) => {
    if (data.isEditable) focusSearchBar(data);
    else {
      onPressAnswerHandler(data.indexOfQuestion)(data.indexOfAnswer);
    }
  };

  const updateKeyboardStatus = (value) => {
    setKeyboardEnabled(value);
  };

  const onHandleSubmit = () => {
    setFromBackSearch(false);
    advancedSearchRef.current.onHandleSubmit();
  };

  const onValidAdvancedSearch = (isValid) => {
    setButtonDisabled(!isValid);
  };

  const [resultsViewHeight, setResultsViewHeight] = useState();

  // useEffect(() => {
  //   setTimeout(() => { setResultsViewHeight(window.outerHeight - searchView.current?.clientHeight - searchViewBottom.current?.clientHeight + 20); }, 200)
  // }, [showAdvancedSearch, searchParams])

  return (
    <div className={Styles.searchBody}>
      <div className={Styles.searchView} ref={searchView}>
        <div className={Styles.backIconHolder}>
          <BackIcon />
        </div>

        <h1 className={Styles.searchHeader}>Search</h1>

        {showAdvancedSearch ? (
          <AdvancedSearch
            modalVisible={true}
            isEdit={isEdit}
            isAdhocSearch={isAdhocSearch}
            showAdvancedSearch={showAdvancedSearch}
            ref={advancedSearchRef}
            onSubmitEditingSearchbarHandler={onSubmitEditingSearchbarHandler}
            onValidAdvancedSearch={onValidAdvancedSearch}
            searchString={searchString}
            setSearchString={setSearchString}
            phoneNumberInput={phoneNumberInput}
            setPhoneNumber={setPhoneNumber}
            countryInput={countryInput}
            setCountry={setCountry}
            cityInput={cityInput}
            stateAbbr={stateAbbr}
            setCity={setCity}
            setStateAbbr={setStateAbbr}
            stateInput={stateInput}
            setState={setState}
            ageInput={ageInput}
            setAge={setAge}
            selectedTab={""}
            onPressBackButton={onBackDropPressHandler}
            showAdvancedSearchTooltip2={showAdvancedSearchTooltip2}
            setShowAdvancedSearchTooltip2={setShowAdvancedSearchTooltip2}
            showAdvancedSearchTooltip3={showAdvancedSearchTooltip3}
            setShowAdvancedSearchTooltip3={setShowAdvancedSearchTooltip3}
            advancedSearchOnboardingDone={user.advancedSearchOnboardingDone}
            showDisclaimer={showDisclaimer}
            phoneRef={phoneRef}
          />
        ) : (
          <SearchTextField
            refObj={searchBarRef}
            value={capitalizeCase(searchString, true)}
            setValue={setSearchString}
            labelValue="Name"
            placeHolderValue="First, middle, last name if possible"
          />
        )}

        <div className={Styles.advanceView}>
          <div
            className={Styles.advanceTextView}
            onClick={() => {
              setShowAdvancedSearch(!showAdvancedSearch);
            }}
          >
            <span className={Styles.advancedBtnTxt}>
              Advanced
              {advanceSearchFieldStateCount != 0 &&
                "(+" + advanceSearchFieldStateCount + ")"}
            </span>
            {showAdvancedSearch ? (
              <AdvancedSearchUpArrowIcon />
            ) : (
              <AdvancedSearchDownArrowIcon />
            )}
          </div>
          <div className={Styles.resetView} onClick={onReset}>
            <span className={Styles.resetBtnTxt}>Reset</span>
            <ResetIcon />
          </div>
        </div>

        {potentialMatch.length == 0 && progress == 0 && (
          <Button
            variant="contained"
            style={
              submitButtonDisabled || searchString?.length == 0
                ? {
                  textTransform: "none",
                  borderRadius: 8,
                  backgroundColor: "grey",
                  color: "#fff",
                  fontFamily: "Proxima Nova",
                  fontWeight: 600,
                  fontSize: 16,
                  marginBottom: 14,
                  marginTop: 6,
                  width: '-webkit-fill-available'
                }
                : {
                  fontFamily: "Proxima Nova",
                  fontWeight: 600,
                  fontSize: 16,
                  borderRadius: 8,
                  textTransform: "none",
                  backgroundColor: "#078167",
                  color: "#fff",
                  marginBottom: 14,
                  marginTop: 6,
                  width: '-webkit-fill-available'
                }
            }
            onClick={
              searchParams.length == 0
                ? showAdvancedSearch
                  ? onHandleSubmit
                  : onSubmitEditingSearchbarHandler
                : onSubmitModalHandler
            }
            disabled={
              submitButtonDisabled || searchString?.length == 0 || loading
            }
          >
            {submitButtonText}
          </Button>
        )}

      </div>

      <div className={Styles.resultsView}>
        <div className={Styles.resultsViewContainer}>
          {progress > 0 ? (
            <ColoredLinearProgress
              variant="determinate"
              value={normalise(progress)}
              style={{
                height: 14,
                width: "100%",
                borderRadius: 7,
                alignSelf: "center",
                marginLeft: progress > 0 ? 10 : 0,
              }}
            />
          ) : (
            <>
              {searchArticleVisible ? (
                <RecentSearch searchData={searchData} />
              ) : null}
              <div className={Styles.qna}>
                <QuestionAnswerInterface
                  fromBackSearch={mFromBackSearch}
                  showAdvancedSearch={showAdvancedSearch}
                  isAutoFocus={false}
                  onPotentialSelected={onPotentialSelected}
                  potentialMatch={potentialMatch}
                  isVisible={true}
                  questions={questions}
                  searchName={searchString}
                  onSelectAnswer={onPressAnswerHandler}
                  onEditingOtherInput={onEditingOtherInputHandler}
                  onSubmitEditingOtherInput={onSubmitEditingOtherInputHandler}
                  isSavedSearch={isAdhocSearch ? false : isEdit}
                  searchType={searchType}
                  updateKeyboardStatus={updateKeyboardStatus}
                  showConfirmSearch={showConfirmSearch}
                />
              </div>
            </>
          )}
        </div>
        {searchParams.length > 0 && <div className={Styles.recentBG}>
          <div className={Styles.searchButton} ref={searchViewBottom}>
            {progress == 0 && (
              <SearchParams
                isVisible={searchParams.length > 0}
                searchParams={searchParams}
                onClickSearchParam={onClickSearchParamHandler}
              />
            )}
          </div>
        </div>}
      </div>

      {showDisclaimer && (
        <DisclaimerModal
          onBackdropPress={onBackdropPress}
          onContinuePress={onContinuePress}
        />
      )}

      {isSearchLimitExceed && (
        <SearchLimitExceedModal
          title={searchLimitExceedTitle}
          onBackdropPress={() => {
            setSearchLimitExceed(false);
          }}
          startFreeTrial={startFreeTrial}
          goback={navigateToDashboard}
          modelType={ModelType.SEARCH_LIMIT_MODAL}
        />
      )}

      {subscriptionModalVisible && (
        <SubscriptionPlansModal
          subscriptionModalVisible={subscriptionModalVisible}
          setSubscriptionModalVisible={setSubscriptionModalVisible}
        />
      )}

      {showSingleBtnAlert.status == true && (
        <SingleBtnAlert
          description={showSingleBtnAlert.msg}
          btnTxt="OK"
          onBtnClick={() => {
            setShowSingleBtnAlert({ status: false, msg: "" });
          }}
        />
      )}

      {showAddOrgsModal && showAddOrgsModal}
    </div>
  );
};
export default SearchComponent;
