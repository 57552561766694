import React from 'react';
import CloseIconWhite from '../../images/svg/closeWhite';

import createStyle from './styles';

const ModalHeader = (props) => {
  const {
    onPress,
    title,
    darkFont = false,
    left = 0,
    isSearch = false,
    showcloseIcon = false,
    mainStyle,
    leftTitle,
    rightTitle,
    onResetPress,
    color
  } = props;
  const style = createStyle(left);

  return (
    <div style={style.headerContainer}>
      <div
        onClick={onPress}
        style={style.headerButton}
      >
      {leftTitle ? <span style={style.altHeaderTitle}>{`${leftTitle}`}</span> : <div></div>}
      {(showcloseIcon || isSearch) ? <CloseIconWhite style={style.closeIcon} color={color}/> : <></> }
      </div>
      
      <span style={style.headerTitle}>{`${title}`}</span>
      
      {rightTitle ? <div
        onClick={onResetPress}
        style={style.headerButton}
      >
      <span style={style.altHeaderTitle}>{`${rightTitle}`}</span> 
      </div>
        : <div></div>}
      
      {/* <View/> */}
    </div>
  )
};

export default ModalHeader;
