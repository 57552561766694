import { TextField } from "@mui/material";
import Styles from './simpleField.module.css'

export const SimpleField = ({
  title,
  placeholder,
  value,
  setValue,
  type,
  fieldValid,
  setFieldValid,
  inputProps,
  fieldLabel,
}) => {
  return (
    <>
      <div className={Styles.fieldLabel}>{title}</div>

      <TextField
        value={value}
        fullWidth
        variant="outlined"
        type={type ? type : "text"}
        placeholder={placeholder}
        className="field"
        inputProps={inputProps}
        InputProps={{
          sx: {
            color: "#142030",
            height: 48,
            marginTop: 1,
            backgroundColor: "#FFFFFF",
            "& ::placeholder": {
              color: "#8E96A8"
            },
          }
        }}
        FormHelperTextProps={{ style: { backgroundColor: 'transparent', padding: 0, margin: 0, marginTop: 4 } }}
        error={fieldValid.status === false}
        helperText={fieldValid.msg}
        onChange={
          (e) => setValue(e.target.value)
        }
      />

    </>
  )
}
