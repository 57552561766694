import React, { useEffect, useContext } from "react";
import SavedSearchCardWithQuery from "../../../components/savedSearchCardWithQuery/savedSearchCardWithQuery";
import parseFerretContactsForContactsComponent from "../../../utils/ferret-contact-parser";
import _ from "lodash";
import ContactImportAlert from "../../../components/noContactsAlert/ContactImportAlert";
import Variables from "../../../utils/Variables";
import ImportContactsConfirmModal from "../importContactsConfirmModel/importContactsConfirmModel";
import InfoIcon from "../../../images/svg/info";
import MissingDetails from "../../../images/svg/missingDetails";
import { UserContext } from "../../../contexts/userContext";
import CloseIconWhite from "../../../images/svg/closeWhite";
import { UPDATE_CONTACT_IMPORT_FLAG } from "../../../queries/queries";
import { useMutation } from "react-apollo";
import { CircularProgress } from "@mui/material";
import parseSectionListHeight from "../../../utils/parseSectionListHeight";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { savedSearchesStore } from "../../../store/savedSearchesStore";
import Styles from "./savedSearchesList.module.css";
import LoadingBar from "../loadingBar/locadingBar";
import ViewOnlyList from "../ViewOnlyList/ViewOnlyList";
import SearchLimitExceedModal from "../../../components/searchLimitExceedModal";
import { ModelType } from "../../../components/searchLimitExceedModal/searchLimitExceedModal";
import { accountOverlayStore } from "../../../store/accountOverlayStore";

const defaultProfilePicture = require("../../../images/svg/default-avatar.svg");
const FILTER_TIMEOUT = 500;
let lastTimeout = null;

let sectionListItemOffset = [];
const SavedSearchesList = (props) => {
  let {
    getPotentialMatches,
    searchTitle,
    categories,
    searchValue,
    setSearchValue: setSearch,
    selectedProviders,
    onContactSelected,
    refsList,
    refs,
    refsViewOnlyList,
    selectedPotentialIndex,
    IS_WATCHED_SEARCH,
    savedSearches,
    setSavedSearches,
    loadingCard,
    handleScroll,
    filterReset,
  } = props;

  const navigate = useNavigate();

  const oldPageName = savedSearchesStore((state) => state.oldPageName);
  const setOldPageName = savedSearchesStore((state) => state.setOldPageName);

  const nsavedSearches = savedSearchesStore((state) => state.nsavedSearches);
  const setNsavedSearches = savedSearchesStore(
    (state) => state.setNsavedSearches
  );

  const contactImported = savedSearchesStore((state) => state.contactImported);
  const setContactImported = savedSearchesStore(
    (state) => state.setContactImported
  );

  const savedSearchPosition = savedSearchesStore(
    (state) => state.savedSearchPosition
  );

  const contacts = savedSearchesStore((state) => state.contacts);
  const setContacts = savedSearchesStore((state) => state.setContacts);

  const orignalContacts = savedSearchesStore((state) => state.orignalContacts);
  const setOrignalContacts = savedSearchesStore(
    (state) => state.setOrignalContacts
  );

  const riskLevelsStatuses = savedSearchesStore(
    (state) => state.riskLevelsStatuses
  );

  const setAppliedFilter = savedSearchesStore(
    (state) => state.setAppliedFilter
  );

  const showConfirmModel = savedSearchesStore(
    (state) => state.showConfirmModel
  );
  const setShowConfirmModel = savedSearchesStore(
    (state) => state.setShowConfirmModel
  );

  const isProcessed = savedSearchesStore((state) => state.isProcessed);
  const setProcessed = savedSearchesStore((state) => state.setProcessed);

  const isMissingName = savedSearchesStore((state) => state.isMissingName);
  const setMissingName = savedSearchesStore((state) => state.setMissingName);

  const topRiskHeight = savedSearchesStore((state) => state.topRiskHeight);
  const setTopRiskHeight = savedSearchesStore(
    (state) => state.setTopRiskHeight
  );

  const setAvailableFilter = savedSearchesStore(
    (state) => state.setAvailableFilter
  );

  const selectedFilter = savedSearchesStore((state) => state.selectedFilter);
  const setSelectedFilter = savedSearchesStore(
    (state) => state.setSelectedFilter
  );

  const isSearchLimitExceed = savedSearchesStore((state) => state.isSearchLimitExceed);
  const setSearchLimitExceed = savedSearchesStore(
    (state) => state.setSearchLimitExceed
  );

  const setOpenSubscription = accountOverlayStore(
    (state) => state.setOpenSubscription
  );

  const cancelPopup = () => {
    setOpenSubscription(false)
    setSearchLimitExceed(false);
  };

  const startFreeTrial = async () => {
    setOpenSubscription(true);
    setSearchLimitExceed(false);
  };

  const { user, setUser } = useContext(UserContext);
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });

  const createFilterSection = (title, labels, status = true) => {
    return {
      title: title,
      filters: labels.map((label) => ({
        label: label,
        status: status,
      })),
    };
  };

  const selectedCategory = savedSearchesStore(
    (state) => state.selectedCategory
  );

  const [updateContactImportFlag, { data, loading, error }] = useMutation(
    UPDATE_CONTACT_IMPORT_FLAG,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        if (data) {
          const updateUserInfo = {
            ...user,
            contactImportFlagActive: data.contactImportFlagActive,
          };
          setUser(updateUserInfo);
        }
      },
      onError: (err) => {
        console.log(err, "err");
      },
    }
  );

  const callUpdateContactImportFlag = () => {
    updateContactImportFlag({
      variables: {
        contactImportFlagActive: false,
      },
    });
  };

  const filterAction = () => {
    if (
      nsavedSearches == null ||
      nsavedSearches == undefined ||
      nsavedSearches.length === 0
    )
      return;

    // if (IS_WATCHED_SEARCH) return setContacts({ ...orignalContacts });

    const selectedSources = selectedProviders;
    let riskCategorySelected = false;
    setAppliedFilter(selectedSources.length < 3);

    let contacts = { ...orignalContacts };
    let countPush = 0;
    let offsetList = [];
    let availableFilter = [];
    Object.keys(contacts)?.forEach((itemKeys) => {
      let mItem = contacts[itemKeys]?.filter((item) => {
        if (item.typeEmpty) return true;

        const itemData =
          item?.firstName?.toUpperCase() + " " + item?.lastName?.toUpperCase();

        if (searchValue && searchValue.length) {
          const valueCriteria = searchValue.toUpperCase();
          return (
            selectedSources.includes(item.originalContact.provider) &&
            itemData?.indexOf(valueCriteria) > -1 &&
            (riskCategorySelected
              ? riskLevelsStatuses[item.originalContact.riskCategory] &&
                item?.isProcessed != false
              : riskLevelsStatuses[item.originalContact.riskCategory])
          );
        } else {
          return (
            selectedSources.includes(item.originalContact.provider) &&
            (riskCategorySelected
              ? riskLevelsStatuses[item.originalContact.riskCategory] &&
                item?.isProcessed != false
              : riskLevelsStatuses[item.originalContact.riskCategory])
          );
        }
      });
      mItem.forEach((item) => {
        if (item?.originalContact?.summary) {
          let avFiltre = Object.values(item?.originalContact?.summary)
            .filter((item) => item?.total)
            .map((item) => item?.title);
          item.originalContact.availableFilter = avFiltre;
          availableFilter = [...new Set([...availableFilter, ...avFiltre])];
        }
      });

      if (selectedFilter && selectedFilter.length)
        mItem = mItem.filter((item) => {
          if (item.originalContact?.availableFilter) {
            for (
              let i = 0;
              i < item.originalContact?.availableFilter.length;
              i++
            ) {
              if (
                selectedFilter.includes(
                  item.originalContact?.availableFilter[i]
                )
              ) {
                return true;
              }
            }
            return false;
          }
          return false;
        });

      if (mItem && mItem.length) {
        countPush = parseSectionListHeight(
          mItem,
          offsetList,
          countPush,
          topRiskHeight,
          null,
          null,
          Variables?.saved_searches_tags_enabled
        );
        contacts[itemKeys] = mItem;
      } else delete contacts[itemKeys];
    });
    setAvailableFilter(availableFilter);
    setContacts(contacts);
  };

  const providers = [
    "",
    "Personal Contacts",
    "LinkedIn",
    "Google",
    "iCloud",
    "Facebook",
    "Saved on Ferret",
  ];

  const getFormatedArticles = (articles) => {
    const riskCategories = {
      HIGH: "high",
      MEDIUM: "medium",
      LOW: "low",
      high: "high",
      medium: "medium",
      low: "low",
    };

    return articles
      .sort((a, b) => {
        const aa = `${a.name} ${a.lastName}`.toLowerCase().trim();
        const bb = `${b.name} ${b.lastName}`.toLowerCase().trim();
        return aa.localeCompare(bb);
      })
      .map((article) => {
        if (!article.profilePicture) {
          article.profilePicture = defaultProfilePicture;
        }
        article.riskCategory = riskCategories[article.riskCategory] ?? "low";
        article.provider = Number.isInteger(article.provider)
          ? providers[article?.provider ?? 1]
          : article.provider;
        return article;
      });
  };

  const calcMissingNameinSavedSearches = (savedSearches) => {
    let isMissingNamee = false;
    savedSearches?.map(({ name, lastName }) => {
      if (!name || name == "" || !lastName || lastName == "") {
        isMissingNamee = true;
        return;
      }
    });
    return isMissingNamee;
  };

  useEffect(() => {
    Variables.savedSearchFilter = false;
    Variables.navigationStackNameForContacts = false;
    setSearch(searchTitle ?? null);
  }, []);

  useEffect(() => {
    clearTimeout(lastTimeout);
    lastTimeout = setTimeout(() => {
      filterAction();
    }, FILTER_TIMEOUT);
  }, [
    selectedFilter,
    selectedCategory,
    selectedProviders,
    orignalContacts,
    searchValue,
    filterReset,
  ]);

  useEffect(() => {
    let isFound =
      [...(savedSearches ?? [])]?.filter((item) => item.isProcessed === false)
        ?.length > 0;
    let isFoundMissingName = calcMissingNameinSavedSearches(savedSearches);
    setTopRiskHeight(isFound || isFoundMissingName ? 200 : 170);
    setProcessed(isFound);
    setNsavedSearches(savedSearches);
    setMissingName(isFoundMissingName);
  }, [savedSearches, IS_WATCHED_SEARCH]);

  useEffect(()=> {
    setTimeout(()=> {
      refsList?.current?.scrollTo(0, savedSearchPosition);
    }, 600)
  }, [savedSearches])

  useEffect(() => {
    const isContactImported = nsavedSearches?.filter(
      (item) => item.provider !== providers[6]
    )?.length;
    setContactImported(IS_WATCHED_SEARCH ? true : isContactImported);
  }, [nsavedSearches, orignalContacts, IS_WATCHED_SEARCH]);

  useEffect(() => {
    if (nsavedSearches && oldPageName != '/articles') {
      setOldPageName('')
      let tsavedSearches = getFormatedArticles(nsavedSearches ?? []);
      const contacts = parseFerretContactsForContactsComponent(tsavedSearches);

      let dict = {};
      let countPush = 0;
      for (var i = 0; i < contacts.length; i++) {
        let outerObject = contacts[i];
        const coontactSection = outerObject.data;
        countPush = parseSectionListHeight(
          coontactSection,
          sectionListItemOffset,
          countPush,
          topRiskHeight
        );
        dict[outerObject.title.toUpperCase()] = coontactSection;
      }

      setContacts(dict);
      setOrignalContacts(dict);
    }
  }, [nsavedSearches]);

  const onChangePreferred = (data) => {
    if (data) {
      let mContact = { ...contacts };
      Object.values(mContact)?.forEach((item) => {
        const selectedContact = item.findIndex(
          (item) => item?.originalContact?.id === data.id
        );
        if (selectedContact !== -1) {
          item[selectedContact].isPreferred = data.isPreferred;
          item[selectedContact].originalContact.isPreferred = data.isPreferred;
        }
      });
      setOrignalContacts(mContact);
    }
  };

  const onDeleteContact = (data) => {
    if (data) {
      let tsavedSearches = nsavedSearches;
      setSavedSearches(
        tsavedSearches.filter((savedSearch) => savedSearch.id != data?.id)
      );      
    }
  };
  const onChangePotentialMatch = (data, key) => {
    if (data) {
      getPotentialMatches(data?.id, key);
    }
  };

  const checkWatchedLimit = (message) => {
    if (message?.toLowerCase() === "limit reached") {
      const updateUserInfo = { ...user, watchedLimitExceed: true };
      setUser(updateUserInfo);
      setSearchLimitExceed(true);
    }
  };

  const onPressImport = () => {
    Variables.navigationStackNameForContacts = "SavedSearches";
    const data = {
      screenName: "SavedSearches",
    };
    navigate("/importContactsLanding", { state: JSON.stringify(data) });
  };

  let alphaArray = [];
  Object.keys(contacts).map((key) => {
    alphaArray = [...alphaArray, key];
  });

  return (
    <div className={Styles.body}>
      <main className={Styles.main}>
        <span className={Styles.headerTXT}>
          {IS_WATCHED_SEARCH ? "Watched Contacts" : "Contacts & Saved Searches"}
        </span>
        {nsavedSearches == null ? null : nsavedSearches &&
          nsavedSearches.length == 0 ? (
          <ContactImportAlert
            onPress={onPressImport}
            style={{ marginTop: 80 }}
          />
        ) : (
          <>
            {isProcessed && (
              <div className={Styles.processContainer}>
                <div>
                  <InfoIcon color={"#fff"} />
                </div>
                <span className={Styles.processText}>
                  Ferret is processing imported contacts for matches across
                  multiple databases. Some search results, verifications, and
                  tags are pending.
                </span>
              </div>
            )}

            {!isProcessed && isMissingName && user.contactImportFlagActive && (
              <div className={Styles.processContainer2}>
                <div>
                  <MissingDetails color={"#fff"} />
                </div>
                <span
                  className={`${Styles.processText} ${Styles.processTextCSS}`}
                >
                  Some contacts are missing a full name, reducing accuracy of
                  results. Please update these contacts with a full name to
                  improve results.
                </span>
                <div
                  className={Styles.crossButton}
                  onClick={callUpdateContactImportFlag}
                >
                  <CloseIconWhite className={Styles.closeIcon} />
                </div>
              </div>
            )}

            {nsavedSearches &&
            nsavedSearches.length !== 0 &&
            !contactImported ? (
              <div
                className={Styles.contactMainView}
                onClick={() => setShowConfirmModel(true)}
              >
                <span className={`${Styles.titleMain} ${Styles.importTextCSS}`}>
                  Connect your contact lists to start monitoring
                </span>
                <span
                  className={`${Styles.buttonNotifi} ${Styles.importTextCSS}`}
                >
                  Connect Contacts Lists
                </span>
              </div>
              ) : null}
            <ViewOnlyList
              contacts={contacts}
              refsList={refsList}
              refsSection={refs}
              ref={refsViewOnlyList}
              onScroll={handleScroll}
              renderHeader={(key) => (
                <p className={Styles.alphaHeaderText}>{key}</p>
              )}
              renderView={(item) => (
                <div>
                  {loadingCard == item.originalContact.id && (
                    <div className={Styles.containerRightparantProgressbar}>
                      <LoadingBar />
                    </div>
                  )}
                  <SavedSearchCardWithQuery
                    getPotentialMatches={(data) =>
                      getPotentialMatches(data, item.originalContact.id)
                    }
                    newsItem={item.originalContact}
                    style={{
                      marginBottom: 10,
                      height: item.customSize,
                    }}
                    contactModal={onChangePreferred}
                    onDeleteContact={onDeleteContact}
                    checkWatchedLimit={checkWatchedLimit}
                    onChangePotentialMatch={(data) =>
                      onChangePotentialMatch(data, item.originalContact.id)
                    }
                    showChangePotentialMatch={
                      item?.originalContact?.hasTracerPotentialMatches
                    }
                    key={item.originalContact.id}
                    categories={categories}
                    isWeb={true}
                    selectedPotential={
                      selectedPotentialIndex == item.originalContact.id
                    }
                    onContactSelected={(data) =>
                      onContactSelected(data, item.originalContact.id)
                    }
                    IS_WATCHED_SEARCH={IS_WATCHED_SEARCH}
                  />
                </div>
              )}
            />
          </>
        )}
      </main>

      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "100vh",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      )}
      {showConfirmModel && (
        <ImportContactsConfirmModal
          onBackdropPress={() => setShowConfirmModel(false)}
        />
      )}

      {isSearchLimitExceed && (
        <SearchLimitExceedModal
          onBackdropPress={() => {
            setSearchLimitExceed(false);
          }}
          startFreeTrial={startFreeTrial}
          goback={cancelPopup}
          modelType={ModelType.WATCHED_SEARCH_LIMIT_MODAL}
        />
      )}
    </div>
  );
};

export default SavedSearchesList;
