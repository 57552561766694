// Layout
import { searchFormStore } from "../../../store/searchFormStore";
import SideOverlay from "../../layout/SideOverlay";

// Components
import SearchForm from "./SearchForm";

/**
 * SearchOverlay Component
 * @returns {JSX.Element}
 * @constructor
 */
const SearchOverlay = ({ setIsSearchOverlayVisible }) => {
  const setOpenEditSearch = searchFormStore((state) => state.setOpenEditSearch);
  const editSearchData = searchFormStore((state) => state.editSearchData);

  /**
   * Hides the search overlay
   */
  const hideSearchOverlay = () => {
    setIsSearchOverlayVisible(false);
    setOpenEditSearch(false);
  };

  /**
   * Main render method
   */
  return (
    <SideOverlay handleCloseClick={hideSearchOverlay} isSearch={true}>
      <div className="p-[30px] h-full" style={{ backgroundColor: "#EFF1F5" }}>
        <SearchForm {...editSearchData} />
      </div>
    </SideOverlay>
  );
};

// Component export
export default SearchOverlay;
