import back_white from "../../images/svg/back_white.svg";
import { IconButton } from "@mui/material";
import Styles from './navHeader.module.css';

export default function NavHeaderDark({ navigate, headerName, customRightBtn }) {
  return (
    <div className={Styles.navHeader}>
      <IconButton
        onClick={() => {
          navigate(-1);
        }}
      >
        <img src={back_white} alt="back_white" />
      </IconButton>
      <div className={Styles.title} style={{ color: "white" }}>
        {headerName}
      </div>
      {customRightBtn}
    </div>
  )
}
