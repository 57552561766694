import React, { useState, useRef, useContext } from 'react';
import { AUTHENTICATE_FERRET_USER, AUTHENTICATE_FERRET_EMAIL } from '../../queries/queries';
import { saveUserDataAndRedirectToInitialScreen } from '../../root-navigation';
import { useMutation } from 'react-apollo';
import OTPIllustrationLogo from '../../images/svg/otp-Illustration';
import { UserContext } from "../../contexts/userContext";
import OtpInput from 'react-otp-input';
import { AppBar, Grid, IconButton } from '@mui/material';
import { useNavigate, useLocation } from "react-router-dom";
import { GreenBgButton } from '../buttons/greenBgButton';
import SingleBtnAlert from '../alert/singleBtnAlert';

import Styles from './signUpOtp.module.css';
import NavHeaderLight from '../navHeader/navHeaderLight';

function EmailOtpVerifyForm({ authToken, setAuthToken, email,
  password,
  lastName,
  name,
  dateOfBirth,
  phoneNumber,
  address,
  deviceDetails }) {
  const [displayError, setDisplayError] = useState(false);
  const [submitButtonState, setSubmitButtonState] = useState(true);
  const errorMessage = 'Incorrect OTP';
  const { setUser } = useContext(UserContext);


  const input1 = useRef();
  const [otpInput, setOtpInput] = useState();
  const [resendOtp, setResendOtp] = useState();
  const [showSingleBtnAlert, setShowSingleBtnAlert] = useState({ status: false, msg: "" });
  const navigate = useNavigate();

  //verify otp
  const [verifyOtp, { loading, error }] = useMutation(AUTHENTICATE_FERRET_EMAIL, {
    onCompleted(data) {
      const variables = {
        email: email,
        password: password,
        lastName: lastName,
        name: name,
        dateOfBirth: dateOfBirth,
        phoneNumber: phoneNumber,
        deviceDetails: deviceDetails,
        address: address,
      };
      setSubmitButtonState(true);
      saveUserDataAndRedirectToInitialScreen(
        data.verifyEmail,
        null,
        () => setSubmitButtonState(true),
        'signup',
        variables,
        setUser,
        navigate
      )

      // navigation.navigate('ResetPassword', {
      //   verifiedAuthToken: data.verifyOtp.authToken,
      // });
    },
    onError(err) {
      // alert(err.message)
      setDisplayError(true);
      setTimeout(() => {
        setSubmitButtonState(true);
        setDisplayError(false);
      }, 1500);
    },
  });

  //resend otp
  const [requestOtp, { loading: requestOtpLoading }] = useMutation(AUTHENTICATE_FERRET_USER, {
    onCompleted(data) {

      setResendOtp(data.signUpEmail.id)
      setShowSingleBtnAlert({ status: true, msg: "OTP sent successfully!" })
      // setAuthToken(data.requestOtp.authToken);
      setSubmitButtonState(true);
    },
    onError(e) {
      console.log(e)
      setShowSingleBtnAlert({ status: true, msg: "Error!" })
      setSubmitButtonState(true);
    },
  });

  const disabled = submitButtonState ? null : { disabled: true };

  return (
    <>
      <div className={Styles.otpContainer}>
        <OtpInput
          ref={input1}
          focusStyle={Styles.focusStyle}
          inputStyle={Styles.roundedTextInput}
          onChange={(text) => setOtpInput(text)}
          value={otpInput}
          numInputs={6}
          isInputNum={true}
        />
      </div>
      {displayError && <span className={Styles.errorMessage}>{errorMessage}</span>}
      <div className={Styles.resendTextBlock}>
        <span className={Styles.resendQuestion}>Didn’t receive the code? </span>
        <span
          className={Styles.resendText}
          onClick={() => {
            requestOtp({
              variables: {
                email: email,
                password: password,
                lastName: lastName,
                name: name,
                dateOfBirth: dateOfBirth,
                phoneNumber: phoneNumber
              }
            });
            setSubmitButtonState(false);
          }}>
          Resend Code
        </span>
      </div>
      <div className={Styles.separator}>
        {displayError ? null
          :
          <GreenBgButton
            disabled={!submitButtonState || otpInput?.length !== 6}
            fullWidth
            variant="contained"
            loading={loading || requestOtpLoading}
            onClick={() => {
              verifyOtp({ variables: { id: resendOtp ? resendOtp : authToken, code: otpInput } });
              setSubmitButtonState(false);
            }}
          >
            <div style={{ fontFamily: "Proxima Nova", fontSize: 16, fontWeight: "600", textTransform: "capitalize" }}>Verify & Proceed</div>
          </GreenBgButton>
        }
      </div>
      {(showSingleBtnAlert.status == true) && <SingleBtnAlert
        description={showSingleBtnAlert.msg}
        btnTxt="OK"
        onBtnClick={() => {
          setShowSingleBtnAlert({ status: false, msg: "" })
        }}
      />
      }
    </>
  );
}

export default function EmailOtpVerify() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const authT = state?.id;

  const { email,
    password,
    lastName,
    name,
    dateOfBirth,
    phoneNumber,
    address,
    deviceDetails } = state?.variables ?? [];

  const [authToken, setAuthToken] = useState(authT);

  return (<div className={ Styles.bodySignupOtp }>

    <div className={ Styles.signupOtpMain }>

      <NavHeaderLight headerName="Verify your E-mail" navigate={navigate} customTextStyle={{fontSize:16}} customRightBtn={<div style={{color:"transparent"}}>d</div>}/>

      <div>

        <div className={ Styles.illustration }>
          <OTPIllustrationLogo />
        </div>

        <span className={ Styles.headline }>Enter your Code</span>
        <span className={ Styles.description }>Please use the Code sent to your registered E-mail to create Ferret account</span>

        <div>

          <EmailOtpVerifyForm
            authToken={authToken}
            setAuthToken={setAuthToken}
            email={email}
            password={password}
            lastName={lastName}
            name={name}
            dateOfBirth={dateOfBirth}
            phoneNumber={phoneNumber}
            address={address}
            deviceDetails={deviceDetails}
          />

        </div>

      </div>

    </div>

  </div>);
}
