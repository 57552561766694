const contactStyles = () => {
return {
    container: {
        backgroundColor: 'white',
        borderColor: '#EDEDED',
        borderWidth: 1,
        marginRight: 10,
        borderRadius: 6,
        paddingHorizontal: 16,
        paddingTop: 10,
        paddingBottom: 0,
        shadowColor: 'black',
        shadowOpacity: 0.1,
        shadowRadius: 4,
        shadowOffset: {
            width: 0,
            height: 0,
        }
    },
    topSection: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingBottom: 10,
        paddingRight: 10,
        paddingLeft: 10,
        borderBottomColor: '#f1f1f1',
        display: 'flex'
    },
    avatar: {
        marginRight: 10,
        height: 30,
        width: 30,
        borderRadius: 15,
        borderColor: '#FFF',
        borderWidth: 2,
    },
    name: {
        fontSize: 14,
        fontWeight: 'bold',
        marginLeft: 10,
        color: '#3D4763',
        flexGrow: 1,
    },
    bottomSection: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
    tag: {
        color: '#1A233B',
        fontSize: 13,
        fontWeight: '600',
        backgroundColor: '#DCE3F2',
        paddingVertical: 3,
        paddingHorizontal: 7,
        borderRadius: 3,
        marginRight: 10,
        marginBottom: 10,
    },
    tagView: {
        flexWrap: 'wrap',
    }
}}

export default contactStyles