const StarDeactivated = () =>{
    return(<svg 
        width="20px"
        height="20px"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g transform="translate(-328 -432)">
            <g transform="translate(15 420)">
              <g transform="translate(313 12)">
                <path d="M0 0H20V20H0z"></path>
                <path
                  stroke="#FB8100"
                  d="M10 1.118l2.63 5.261 5.817.876-4.19 4.128.964 5.803L10 14.476l-5.22 2.71.964-5.803-4.191-4.128 5.816-.876L10 1.118z"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </svg>)
}
export default StarDeactivated