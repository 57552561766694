import logoWhite from "../../../images/svg/logo-white.svg";
import Styles from "./darkCardItem.module.css";

const DarkCardItem = ({ description, title, name, onClick }) => {
  return (
    <div className={Styles.body}>
      <div className={Styles.descriptionBoxStyle}>
        {title && (
          <span className={Styles.title} style={{ color: "#EFF1F5" }}>
            {title}
          </span>
        )}
        <span className={Styles.smallCopyDark}>{description}</span>
        <span onClick={onClick} className={Styles.btview}>
          {name}
        </span>
      </div>
      <div className={Styles.iconRight}>
        <img
          className={Styles.iconlogo}
          src={logoWhite}
          alt="finger-print-logo"
        />
      </div>
    </div>
  );
};

export default DarkCardItem;
