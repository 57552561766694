import React, { forwardRef, useImperativeHandle, useState } from "react";
import Styles from "./alphabetScroll.module.css";

const AlphabetScroll = ({ alphaArray, onClick, customStyle, styles }, ref) => {
  useImperativeHandle(ref, () => ({
    setSelectedAlphabetScroll: (data) => {
      setSelected(data);
    },
  }));

  const [selected, setSelected] = useState("");
  let arr = [1, 2, 3, 4, 5, 6, 7, 8];
  return (
    <div>
      <ul
        className={styles ? styles : Styles.letterSidebar}
        style={customStyle}
      >
        {alphaArray.map((a) => (
          <li
            key={a}
            onClick={() => {
              setSelected(a);
              onClick(a);
            }}
            className={
              selected == a
                ? Styles.alphaHeaderSelected
                : Styles.alphaHeaderText
            }
          >
            {a}
          </li>
        ))}
      </ul>
    </div>
  );
};
export default forwardRef(AlphabetScroll);
