import moment from 'moment-timezone';

const filterArticlesByBothMatchStrength = (articles) =>
  articles.filter(
    ({ matchType }) => matchType === 'Strong Match' || matchType === 'Weak Match',
  );

const filterArticlesByStrongMatch = (articles) =>
  articles.filter(({ matchType }) => matchType === 'Strong Match');

const filterArticlesByWeakMatch = (articles) =>
  articles.filter(({ matchType }) => matchType === 'Weak Match');

const filterArticlesByAttributes = (articles, filterAttributes) => {
  const defaultAttributes = ["People", "Organizations", "Places", "Parties", "Attorneys"];

  return articles.filter(article => {
    const attributes = article?.attributes || [];

    // Ensure default attributes are present
    defaultAttributes.forEach(key => {
      if (!attributes?.some(attr => attr?.key === key)) {
        attributes?.push({ key, value: [] });
      }
    });

    // Check if the article matches the filter
    return attributes?.every(({ key, value }) => {
      const filterValues = filterAttributes?.[key];
      if (!filterValues || filterValues?.length === 0) return true;
      return value.some(val => filterValues?.map(fv => fv.toLowerCase()).includes(val.toLowerCase()));
    });
  });
};

const orderArticlesByStrongMatch = (articles) =>
  articles.sort((a, b) => b.matchStrength - a.matchStrength);

const orderArticlesByWeakestMatch = (articles) =>
  articles.sort((a, b) => a.matchStrength - b.matchStrength);

const orderArticlesByMostRecent = (articles) =>
  articles.sort((a, b) =>
    moment
      .utc(b?.date?.substring(0, 10))
      .diff(moment.utc(a?.date?.substring(0, 10))),
  );

const orderArticlesByOldest = (articles) =>
  articles.sort((a, b) =>
    moment
      .utc(a?.date?.substring(0, 10))
      .diff(moment.utc(b?.date?.substring(0, 10))),
  );

const sortArticles = (articles, sortState) => {
  let tempFilteredArticles = [...articles];
  // if (sortState == 0)
  //   tempFilteredArticles = orderArticlesByStrongMatch(tempFilteredArticles);
  // else if (sortState == 1) {
  //   tempFilteredArticles = orderArticlesByWeakestMatch(tempFilteredArticles);
  // }
  // else if (sortState == 2)
  //   tempFilteredArticles = orderArticlesByMostRecent(tempFilteredArticles);
  // else if (sortState == 3)
  //   tempFilteredArticles = orderArticlesByOldest(tempFilteredArticles);
  return tempFilteredArticles;
};

const formatArticleAttributes = (articles) =>
  articles?.map((article) => {
    let attributesArr = [];
    article?.attributes?.map(
      (a) => {
        if (a && a.value)
          attributesArr = [...attributesArr, ...a.value]
      }
    );
    article.attributesArr = attributesArr;
    return article;
  });

const filterArticlesByAllVerificationType = (articles) =>
  articles.filter(
    ({ verificationType }) =>
      verificationType === 'verified' ||
      verificationType === 'needs_review' ||
      verificationType === 'partial',
  );

const filterArticlesByVerified = (articles) =>
  articles.filter(({ verificationType }) => verificationType !== 'verified');

const filterArticlesByNeedsReview = (articles) =>
  articles.filter(({ verificationType }) => verificationType !== 'needs_review');

const filterArticlesByPartial = (articles) =>
  articles.filter(({ verificationType }) => verificationType !== 'partial');

const filterArticlesByVerifiedAndPartial = (articles) =>
  articles.filter(({ verificationType }) => verificationType !== 'verified' && verificationType !== 'partial');

const filterArticlesByVerifiedAndNeedsReview = (articles) =>
  articles.filter(({ verificationType }) => verificationType !== 'verified' && verificationType !== 'needs_review');

const filterArticlesByPartialAndNeedsReview = (articles) =>
  articles.filter(({ verificationType }) => verificationType !== 'partial' && verificationType !== 'needs_review');

const filterArticlesByRead = (articles) =>
  articles.filter(({ isRead }) => isRead === true);

const filterArticlesByUnread = (articles) =>
  articles.filter(({ isRead }) => isRead === false);

const filterArticlesByBothReadAndUnread = (articles) =>
  articles.filter(
    ({ isRead }) => isRead === true || isRead === false,
  );

const filterArticles = (
  showStrongMatchArticles,
  showWeakMatchArticles,
  readFilter,
  unreadFilter,
  filterAttributes,
  filteredArticles,
) => {
  let tempFilteredArticles = [...filteredArticles];
  if (showStrongMatchArticles && showWeakMatchArticles)
    tempFilteredArticles =
      filterArticlesByBothMatchStrength(tempFilteredArticles);
  else {
    if (showStrongMatchArticles)
      tempFilteredArticles =
        filterArticlesByStrongMatch(tempFilteredArticles);
    if (showWeakMatchArticles)
      tempFilteredArticles = filterArticlesByWeakMatch(tempFilteredArticles);
  }
  if (readFilter && unreadFilter)
    tempFilteredArticles =
      filterArticlesByBothReadAndUnread(tempFilteredArticles);
  else {
    if (readFilter)
      tempFilteredArticles = filterArticlesByRead(tempFilteredArticles);
    if (unreadFilter)
      tempFilteredArticles = filterArticlesByUnread(tempFilteredArticles);
  }
  if (filterAttributes && (filterAttributes.People?.length || filterAttributes.Places?.length || filterAttributes.Organizations?.length || filterAttributes.Parties?.length || filterAttributes.Attorneys?.length))
    tempFilteredArticles = filterArticlesByAttributes(
      tempFilteredArticles,
      filterAttributes
    );
  return tempFilteredArticles;
};

const filterArticlesByVerificationType = (
  filteredArticles,
  showVerified,
  showPartial,
  showNeedsReview,
) => {
  let tempFilteredArticles = [...filteredArticles];
  if (showVerified && showPartial && showNeedsReview)
    tempFilteredArticles = filterArticlesByAllVerificationType(tempFilteredArticles)
  else if (showVerified && showPartial) tempFilteredArticles = filterArticlesByVerifiedAndPartial(tempFilteredArticles)
  else if (showVerified && showNeedsReview) tempFilteredArticles = filterArticlesByVerifiedAndNeedsReview(tempFilteredArticles)
  else if (showPartial && showNeedsReview) tempFilteredArticles = filterArticlesByPartialAndNeedsReview(tempFilteredArticles)
  else {
    if (showVerified)
      tempFilteredArticles = filterArticlesByVerified(tempFilteredArticles);
    if (showPartial)
      tempFilteredArticles = filterArticlesByPartial(tempFilteredArticles);
    if (showNeedsReview)
      tempFilteredArticles = filterArticlesByNeedsReview(tempFilteredArticles);
  }
  return tempFilteredArticles;
};

const filterChangesCounter = (
  showStrongMatchArticles,
  showWeakMatchArticles,
  sortState,
  readFilter,
  unreadFilter,
  filterAttributes
) => {
  let count = 0;
  if (sortState != 2) count += 1;
  if (
    (showStrongMatchArticles && showWeakMatchArticles) ||
    (!showStrongMatchArticles && !showWeakMatchArticles)
  )
    count = count;
  else {
    if (showStrongMatchArticles) count += 1;
    if (showWeakMatchArticles) count += 1;
  }
  if ((readFilter && unreadFilter) || (!readFilter && !unreadFilter))
    count = count;
  else {
    if (readFilter) count += 1;
    if (unreadFilter) count += 1;
  }
  let arrDiff = 0
  if (filterAttributes) {
    arrDiff = (filterAttributes.People?.length ?? 0) + (filterAttributes.Places?.length ?? 0) + (filterAttributes.Organizations?.length ?? 0) + (filterAttributes.Parties?.length ?? 0) + (filterAttributes.Attorneys?.length ?? 0)
  }
  if (arrDiff > 0) count = count + arrDiff;
  return count;
};

const returnIntialTags = (placeArray, organizationArray, searchSettings, aKAList, peopleArray = [], partiesArray, attorneysArray) => {
  let initialAttributes = [];
  let names = peopleArray ?? [];

  // if(searchSettings?.initialSearchString && !(names && names.includes(searchSettings.initialSearchString)))
  //   names.splice(1, 0, searchSettings.initialSearchString)

  if (aKAList && aKAList.length) {
    if (names.indexOf('AKA_PLACEHOLDER') >= 0) {
      let akaName = []
      aKAList.map(item => {
        akaName.push({
          label: item.param,
          status: false,
          isAKA: item.aka
        })
      })
      names.splice(names.indexOf('AKA_PLACEHOLDER'), 1, ...akaName);
    } else {
      aKAList.map(item => {
        if (names && names.includes(item.param)) {
          names.splice(names.indexOf(item.param), 1)
        }
        names = [
          {
            label: item.param,
            status: false,
            isAKA: item.aka
          }, ...names
        ]
      })
    }
  } else if (names.indexOf('AKA_PLACEHOLDER') >= 0) {
    names.splice(names.indexOf('AKA_PLACEHOLDER'), 1);
  }

  initialAttributes.push({
    title: 'Name(s)',
    tags: uniq(names),
  });

  initialAttributes.push({
    title: 'Place(s)',
    tags: uniq(placeArray),
  });

  initialAttributes.push({
    title: 'Organization(s)',
    tags: uniq(organizationArray),
  });


  if (partiesArray && partiesArray.length)
    initialAttributes.push({
      title: 'Parties',
      tags: uniq(partiesArray),
    });

  if (attorneysArray && attorneysArray.length)
    initialAttributes.push({
      title: 'Attorneys',
      tags: uniq(attorneysArray),
    });
  return initialAttributes;
}

function uniq(a) {
  var seen = {};
  return a?.filter(function (item) {
    var itemText = item
    if (typeof itemText !== "string") {
      itemText = item?.label
    }
    return seen.hasOwnProperty(itemText?.toLowerCase()) ? false : (seen[itemText?.toLowerCase()] = true);
  });
}



export {
  filterArticlesByBothMatchStrength,
  filterArticlesByStrongMatch,
  filterArticlesByWeakMatch,
  filterArticlesByAttributes,
  orderArticlesByStrongMatch,
  orderArticlesByWeakestMatch,
  orderArticlesByMostRecent,
  orderArticlesByOldest,
  sortArticles,
  formatArticleAttributes,
  filterArticlesByRead,
  filterArticlesByUnread,
  filterArticlesByBothReadAndUnread,
  filterArticles,
  filterArticlesByVerificationType,
  filterChangesCounter,
  returnIntialTags
};
