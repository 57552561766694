const RemoveAdvanceSearch = (searchContext, city, isFullSplit) => {

     let mPlace = ''
    if(searchContext?.city && searchContext?.city != '')
      mPlace = searchContext.city
    if(searchContext?.state && searchContext?.state != '')
      mPlace = mPlace != '' ? mPlace +', '+searchContext.state : searchContext.state
    if(searchContext?.country && searchContext?.country != '')
      mPlace = mPlace != '' ? mPlace +', '+searchContext.country : searchContext.country

    let mPlaceArray = ''
    if(mPlace && mPlace !='' && city && !isFullSplit){
        mPlace = mPlace.toLowerCase()
        city.split(';')?.forEach((item)=>{
          if(item?.toLowerCase() != mPlace){
            mPlaceArray = mPlaceArray != '' ? mPlaceArray + ';'+item : item
          }
        })
    }else if (mPlace && mPlace !='' && city && city.split(';')?.length <= 1){
      mPlace = mPlace.toLowerCase()
        city.split(';')?.forEach((item)=>{
          if(item?.toLowerCase() != mPlace){
            mPlaceArray = mPlaceArray != '' ? mPlaceArray + ';'+item : item
          }
        })
    }else{
      mPlaceArray = city
    }
    return mPlaceArray;
}
export default RemoveAdvanceSearch;