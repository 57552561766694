const createStyle = () => {
  const titleTextColor = "#1A233B";
  const paragraphTextColor = '#222F4B';
  return {
    container: {
      marginLeft: 16,
      marginRight: 16,
      flex: 1,
      height: '90%',
      marginTop: 30,
    },
    backgroundImage: {
      position: 'absolute',
    },
    signUpButtonContainer: {
      marginTop: 10,
      flex: 1,
    },
    logo: {
      display: "flex",
      resizeMode: 'center',
      alignSelf: 'center',
      marginTop: 30,
    },
    textQuestion: {
      color: titleTextColor,
      fontFamily: 'Proxima Nova',
      fontSize: 24,
      marginLeft: 10,
      marginRight: 10,
      fontWeight: 'bold',
      textAlign: 'center',
      marginBottom: 20,
    },
    textSimple: {
      marginLeft: 10,
      marginRight: 10,
      color: paragraphTextColor,
      fontFamily: 'Proxima Nova',
      fontSize: 16,
      textAlign: 'center',
    },
    formContainer: {
      marginTop: 10,
    },
    separator: {
      marginTop: 8,
    },
    resetButton: {
      marginTop: 16,
      color: '#078167',
    },
    buttonHeight: {
      height: 40,
    },
    activityIndicator: {
      display: "flex",
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      alignItems: 'center',
      justifyContent: 'center',
    },
    errorMessage: {
      marginTop: 15,
      color: "#FF2A3D",
    },
    headerText: {
      fontFamily: 'Proxima Nova',
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: 22,
      textAlign: "center",
      color: "#142030",
      display: "flex",
      justifyContent: "center"
    },
    header: {
      backgroundColor: "#f9faff",
      width: "100%",
      display: "flex",
      alignItems: "center",
      position: "relative"
    },
  }
};

export default createStyle;
