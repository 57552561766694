
const createStyle = (left) => {
  const titleTextColor = '#1A233B';
  const altTextColor = '#078167'
  return {
    headerContainer: {
      display: "flex",
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop:8,
    },
    headerButton: {
      cursor: 'pointer',
      // position: 'absolute',
      // left: left,
      // top: 24,
      paddingTop: 10,
      paddingBottom: 10,
      // paddingHorizontal:16,
    },
    closeIcon: {
      // position: 'absolute',
      left: left,
      // top: 24,
      width: 16,
      height: 16,
      paddingTop: 10,
      paddingBottom: 10,
      // paddingHorizontal:16,
    },
    headerTitle: {
      color: titleTextColor,
      fontFamily: "Proxima Nova",
      fontSize: 15,
      fontWeight: '700',
      textAlign: 'center',
    },
    altHeaderTitle: {
      color: altTextColor,
      fontFamily: "Proxima Nova",
      fontSize: 15,
      fontWeight: '400',
      textAlign: 'center',
    },
  };
};

export default createStyle;