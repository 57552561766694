import React, { useContext, useEffect, useRef, useState } from "react";
import Styles from "./watchedSearches.module.css";
import starIconActive from "../../images/svg/star-icon-active.svg";
import WatchedSearchesCard from "./watchedSearchesCard";
import LoadingBar from "../loadingBar/locadingBar";
import { useLazyQuery } from "react-apollo";
import { GET_SAVED_SEARCHES } from "../../../queries/queries";
import { closestContactsStore } from "../../../store/closestContactsStore";
import Variables from "../../../utils/Variables";
import { useNavigate } from "react-router-dom";
import UnpaidMemberPopupModal from "../../../components/unpaidMemberModal/unpaidMemberModal";
import { UserContext } from "../../../contexts/userContext";
import { accountOverlayStore } from "../../../store/accountOverlayStore";

const WatchedSearches = (props) => {
  const circlRef = useRef();
  const navigate = useNavigate();

  const { user } = useContext(UserContext);

  const [unpaidMemberModal, setUnpaidMemberModal] = useState(false);
  const contactList = closestContactsStore((state) => state.contactList);
  const setContactList = closestContactsStore((state) => state.setContactList);
  const setClosestCategories = closestContactsStore(
    (state) => state.setCategories
  );

  const setOpenSubscription = accountOverlayStore(
    (state) => state.setOpenSubscription
  );

  const [
    getSavedSearches,
    { loading: loadingContacts, data: dataContacts, error: errorContacts },
  ] = useLazyQuery(GET_SAVED_SEARCHES, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (!loadingContacts && dataContacts) {
      Variables.isRefreshClosestContacts = false;
      if (dataContacts && dataContacts?.getSavedSearches?.contacts) {
        setContactList(dataContacts.getSavedSearches.contacts);
        setClosestCategories(dataContacts.getSavedSearches.categories);
      }
    }
  }, [dataContacts, loadingContacts, errorContacts]);

  useEffect(() => {
    if (
      Variables.isRefreshClosestContacts ||
      contactList == null ||
      contactList == false ||
      contactList.length == 0
    ) {
      getSavedSearches({
        variables: { showPreferred: true },
      });
    }
  }, []);

  const openWatchedSearches = () => {
      navigate("/watchedSearch");
  }

  return (
    <>
      {/* {!(!loadingContacts && contactList?.length == 0) &&  */}
      <>
        {unpaidMemberModal && (
          <UnpaidMemberPopupModal
            onBackdropPress={() => {
              setUnpaidMemberModal(false);
            }}
            upgradeNow={() => {
              setOpenSubscription(true);
            }}
          />
        )}

        <div
          className={Styles.containerMain}
          onClick={() => {
            openWatchedSearches()
          }}
        >
          <div className={Styles.containerChild}>
            <div className={Styles.rowsHeader}>
              <img src={starIconActive} />
              <span className={Styles.titleMain}>Watched Contacts</span>
            </div>
            {loadingContacts ? (
              <LoadingBar />
            ) : (
              <>
                {contactList && contactList.length > 0 ? (
                  (contactList.length > 2
                    ? contactList.slice(0, 2)
                    : contactList
                  ).map((item) => <WatchedSearchesCard item={item} />)
                ) : (
                  <div className={Styles.descriptionDiv}>
                    {" "}
                    <span className={Styles.description}>
                      Your short list of close contacts, and other saved
                      searches with notifications
                    </span>
                  </div>
                )}
              </>
            )}
          </div>
          <div
            onClick={() => {
              openWatchedSearches()
            }}
            className={Styles.viewAll}
          >
            View all
          </div>
          <div className={Styles.demoView} ref={circlRef} />
        </div>
      </>
      {/* } */}
    </>
  );
};

export default WatchedSearches;
