import React, { useState, useEffect } from 'react';
import CheckFilter from './checkFilter'
import FilterSection from './filterSection'
import { ButtonBase, Modal } from '@mui/material';
import Slide from '@mui/material/Slide';
import CloseIconWhite from '../../../images/svg/closeWhite';
import { GreenBgButton } from '../../buttons/greenBgButton';
import { GreenBorderButton } from '../../buttons/greenBorderButton';
import Styles from './filtersModal.module.css'

const FiltersModal = (props) => {
  const {
    isVisible,
    onBackdropPress,
    statusesOfFilters,
    setSourceFilterStatus,
    onClearPress,
    onApplyPress,
    riskLevels,
    isArticleFilter = false
  } = props;

  const [riskLevelsStatuses, setRiskLevelsStatuses] = useState(riskLevels)
  const [riskLevel, setRiskLevel] = useState({
    high: true,
    medium: true,
    low: true,
  });

  useEffect(() => {
    setRiskLevelsStatuses(riskLevels)
  }, [riskLevels])

  // const onPress = touchedRiskLevel => {
  //   let riskLevels = {...riskLevelsStatuses};
  //     riskLevels[touchedRiskLevel].isSelected = !riskLevels[touchedRiskLevel].isSelected

  //   let riskLevelStatus = {...riskLevel}
  //     riskLevelStatus[touchedRiskLevel] = !riskLevelStatus[touchedRiskLevel]

  //   setRiskLevelsStatuses(riskLevels)
  //   setRiskLevel(riskLevelStatus)
  // }

  const createFilters = (key) => ({ label, status, count = '' }) => {
    return (
      <div>
        <CheckFilter
          count={count}
          label={label}
          status={status}
          setStatus={() => setSourceFilterStatus(key, label)}
        />
      </div>
    )
  }
  const createArticleFilters = (key) => ({ label, status, count }) => {
    return (
      <div>
        <CheckFilter
          count={count}
          label={label}
          status={status}
          setStatus={() => setSourceFilterStatus(key, label)}
        />
      </div>
    )
  }
  const sections = statusesOfFilters.map(({ title, filters }) => {

    let filtersOfSections;
    if (isArticleFilter)
      filtersOfSections = filters.map(createArticleFilters(title))
    else
      filtersOfSections = filters.map(createFilters(title))

    return <FilterSection title={title} filters={filtersOfSections} />
  })

  return (
    <Modal
      backdropOpacity={0.3}
      open={isVisible}
    >
      <Slide in={true} direction="up">
        <div className={Styles.container}>
          <ButtonBase className={Styles.crossButton} onClick={() => onBackdropPress(riskLevel)}>
            <CloseIconWhite style={{ width: 14, height: 14 }} color={'#697080'} />
          </ButtonBase>
          <span className={Styles.dialogTitle}>
            Filters
          </span>
          {sections}
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <GreenBorderButton
              fullWidth
              variant="contained"
              onClick={onApplyPress}
              style={{
                marginLeft: 20,
              }}>
              <div className={Styles.backBtnTxt}>Select  All</div>
            </GreenBorderButton>

            <GreenBgButton
              fullWidth
              onClick={onClearPress}
              variant="contained"
              style={{
                marginLeft: 20,
                marginRight: 20,
              }}>
              <div className={Styles.acceptBtnTxt}>Clear</div>
            </GreenBgButton>
          </div>
        </div>
      </Slide>
    </Modal>
  );
};

export default FiltersModal;
