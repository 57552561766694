const EditPhoto = (props) => (
  <svg
    width={30}
    height={30}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={15} cy={15} r={15} fill="#EFF1F5" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m21.194 7.648 1.31 1.31c.23.23.356.534.356.857 0 .324-.126.628-.355.857l-1.464 1.464a.208.208 0 0 1-.295 0l-2.938-2.938a.208.208 0 0 1 0-.295l1.255-1.255a1.51 1.51 0 0 1 2.131 0Zm-3.857 2.02a.214.214 0 0 0-.295 0l-7.435 7.436a.208.208 0 0 0 0 .295l2.936 2.938a.209.209 0 0 0 .295 0l7.437-7.437a.208.208 0 0 0 0-.295l-2.938-2.938ZM9.106 17.9a.209.209 0 0 0-.35.102l-.743 3.334a.54.54 0 0 0 .15.506.538.538 0 0 0 .507.14l3.274-.794a.208.208 0 0 0 .098-.35L9.106 17.9Z"
      fill="#283147"
    />
  </svg>
)

export default EditPhoto

