import React, { useState, useEffect, useContext, useRef, useLayoutEffect } from 'react';
import CheckFilter from './checkFilter'
import createStyle from './styles';
import SortIcon from '../../images/svg/sort-icon'
import SortIconBg from '../../images/svg/sort-icon-bg'
import { useLazyQuery, useMutation } from 'react-apollo';
import Variables from '../../utils/Variables';
import FilterModal from '../filterModal/filterModal';
import { UPDATE_FERRET_USER } from '../../queries/queries';
import { setUserInfo } from '../../root-navigation';
import { UserContext } from "../../contexts/userContext";
import { ButtonBase, LinearProgress, Box } from '@mui/material';
import FilterByVerificationType from '../filterByVerificationType/filterByVerificationType';
import FilterHeaderTooltip from '../onboardingTooltips/filterHeaderTooltip';
import {
  OnboardingContext,
  OnboardingStates,
  getNextOnboardingStatus,
} from '../../contexts/onboardingContext';
import './style.css'
import Styles from './filterView.module.css'
import { searchResultStore } from '../../store/searchResultStore';

const FiltersView = (props) => {
  const {
    isVisible,
    riskLevels,
    onPress,
    onFilterCompleted,
    articleType,
    statusesOfFiltersOld,
    resetFilter = false,
    noBtnPressDelay = false,
    selectedFilter,
    setSelectedFilter,
    isShowPersonalInfo,
    isAllShow = false,
    sortState,
    setSortState,
    showStrongMatchArticles,
    setShowStrongMatchArticles,
    showWeakMatchArticles,
    setShowWeakMatchArticles,
    filterAttributes,
    setFilterAttributes,
    readFilter,
    setReadFilter,
    unreadFilter,
    setUnreadFilter,
    filterChangesCount,
    attributes,
    setAttributes,
    selectedParentCategory,
    openFilterVisible,
    verifiedCount,
    needsReviewCount,
    partialCount,
    verified,
    setVerified,
    partial,
    setPartial,
    needsReview,
    setNeedsReview,
    processingArticle,
    filterRef,
    setFilterHeight,
    navFrom
  } = props;

  const selectedfilterIndex = searchResultStore((state) => state.selectedfilterIndex);
  const setSelectedfilterIndex = searchResultStore((state) => state.setSelectedfilterIndex);

  const divScrollRef = useRef();
  const scrollRef = useRef([]);
  const style = createStyle();
  const [statusesOfFilters, setStatusesOfFilters] = useState(statusesOfFiltersOld)
  const [showFilter, setShowFilter] = useState(false);
  const [filterClicked, setFilterClicked] = useState(false);
  const [filterModalVisible, setFilterModalVisible] = useState(false)
  const [openFilterVisibleView, setOpenFilterVisible] = useState(false);
  const [isGraterthen, setGraterthen] = useState(false);
  const [showFilterHeaderToolTip, setShowFilterHeaderToolTip] = useState(false);

  const { onboardingState, setOnboardingState } = useContext(OnboardingContext);
  const changeOnboardingState = () =>
    setOnboardingState(getNextOnboardingStatus(onboardingState));

  const { user, setUser } = useContext(UserContext);
  const [userUpdaterMutation, { loading: loadingDontShow }] =
    useMutation(UPDATE_FERRET_USER,
      {
        fetchPolicy: 'no-cache',
        onCompleted: ({ updateUser }) => {
          let u = setUserInfo(updateUser);
          setUser({ ...u });
        }
      });


  const callUpdateUser = (requestData) => {
    userUpdaterMutation({
      variables: requestData,
    });
  }

  useEffect(() => {
    setShowFilterHeaderToolTip(isGraterthen && !user.adhocSortFilterTooltip)
  }, [isGraterthen, user.adhocSortFilterTooltip])

  useEffect(() => {
    if (articleType) {
      let filterData = createFilters()
      const { label, updatedData } = checkFilterHasData(filterData, selectedFilter)
      Variables.statusesOfFilters = updatedData;
      setStatusesOfFilters(updatedData);
      if (!resetFilter) {
        onFilterCompleted(updatedData);
        // setSelectedFilter(label)
      }
      setShowFilter(true)
      setGraterthen(articleType?.filter(item => item?.count > 100)?.length)
    }
  }, [articleType])

  const checkFilterHasData = (data, label) => {
    const filters = data[0].filters;

    let selectedFilter = filters.find(filter => filter.label === label && filter.count > 0);

    if (!selectedFilter) {
      selectedFilter = filters.find(filter => filter.count > 0);
    }

    if (selectedFilter) {
      filters.forEach(filter => filter.status = (filter.label === selectedFilter.label));
      return { label: selectedFilter.label, updatedData: data };
    }

    return { label: null, updatedData: data };
  };

  useEffect(() => {
    if ((!isShowPersonalInfo || isAllShow) && selectedfilterIndex >= 0) {
      setTimeout(() => {
        const element = divScrollRef?.current?.children[selectedfilterIndex];
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'center'
          });
        }
      }, 600)
    }
  }, [filterClicked, selectedfilterIndex, divScrollRef?.current])

  useEffect(() => {
    if (openFilterVisible != false && openFilterVisible != openFilterVisibleView) {
      setOpenFilterVisible(openFilterVisible)
      setFilterModalVisible(true)
    }
  }, [openFilterVisible])


  const createFilterSection = (title, articleType) => {
    let selectedIndex = -1
    let allCount = 0
    let itemType = ['ALL']
    let riskCode = ['ALL']
    let isFirst = true
    let filterList = articleType.map((item, index) => {
      if (selectedFilter && selectedFilter == item.category)
        selectedIndex = index
      else if (statusesOfFilters?.[0]?.filters?.[index + 1]?.status) {
        selectedIndex = index
        isFirst = false
      }
      allCount = allCount + item.count
      return (
        {
          label: item.category,
          itemType: item.itemTypes,
          riskCode: item.riskCodes,
          count: item.count,
          status: (statusesOfFilters?.[0]?.filters[index + (isAllShow ? 1 : 0)]?.status === undefined ? (selectedFilter ? selectedFilter == item.category : isAllShow ? false : index == 0) : statusesOfFilters?.[0]?.filters[index + (isAllShow ? 1 : 0)]?.status),
        })
    })
    let allType = {
      label: 'All',
      count: allCount,
      itemType: itemType,
      riskCode: riskCode,
      status: selectedIndex == -1 ? true : false,
    }
    if (isAllShow)
      filterList.unshift(allType)

    filterList = filterList.filter(item => item.count > 0)
    let isSelectedFilter = [...filterList].filter(item => item.status)?.length > 0

    if (!isSelectedFilter) {
      filterList = filterList.map((item, index) => ({ ...item, status: index == 0 }))
    }

    const filterItems = {
      title: title,
      filters: filterList
    }
    if (isFirst)
      setSelectedfilterIndex(selectedIndex + (isAllShow ? 1 : 0)) // for auto scroll tabbar
    return filterItems
  }

  const createFilters = () => {
    return [
      createFilterSection('Type of Article', articleType),
    ]
  }

  const createArticleFilters = (key) => ({ label, status, count }, index) => {
    return (
      <CheckFilter
        index={index}
        scrollRef={scrollRef}
        count={count}
        label={label}
        status={status}
        iconType="blackWhite"
        setStatus={() => setStatusHandler(key, label, count)}
      />
    )
  }

  const setStatusHandler = (key, label, count) => {
    let selectedIndex = -1
    let filterLabel = ""
    const newStatusesOfFilters = statusesOfFilters
      .map(filtersOfSection => {
        const { title, filters } = filtersOfSection
        if (title === key) {
          return {
            ...filtersOfSection,
            filters: filters.map((filter, index) => {
              if (filter.label === label) {
                selectedIndex = index
                filterLabel = filter.label
                return {
                  ...filter,
                  status: true
                }
              } else {
                return {
                  ...filter,
                  status: false
                }
              }
              return filter
            })
          }
        }
        return filtersOfSection
      })
    setSelectedfilterIndex(selectedIndex)
    setSelectedFilter(filterLabel)
    setStatusesOfFilters(newStatusesOfFilters)
    onFilterCompleted(newStatusesOfFilters)
  }

  const riskLevel = Variables.riskLevelsStatuses
  const updatedStatusesOfFilters = statusesOfFilters && statusesOfFilters.map(({ title, filters }) => {
    const filteredData = filters.map(item => {
      return { ...item, count: 0 }
    })
    return { title: title, filters: filteredData }
  })
  let renderedFilterStatus = statusesOfFilters
  if (riskLevel) {
    if (!riskLevel?.high && !riskLevel?.low && !riskLevel?.medium) {
      renderedFilterStatus = updatedStatusesOfFilters
    }
  }

  const sections =
    renderedFilterStatus &&
    renderedFilterStatus.map(({ title, filters }) => {
      return (
        <>
          {filterClicked ? (
            <div ref={divScrollRef}
              style={style.filters}>
              {filters.map(createArticleFilters(title))}
            </div>
          ) : (
            <div
              ref={divScrollRef}
              id="filterViewSections"
              style={{
                display: "flex",
                flexDirection: "row",
                overflowX: "auto",
              }}
            >
              {filters.map(createArticleFilters(title))}
            </div>
          )}
        </>
      );
    });

  const onCloseFilter = () => {
    const requestData = {
      adhocSortFilterTooltip: true
    }
    userUpdaterMutation({
      variables: requestData,
    });
  }

  const SortIconAndCount = () => {
    return (
      <>
        {filterChangesCount > 0 ? <SortIconBg /> : <SortIcon />}
        {filterChangesCount > 0 && (
          <div style={style.filterCount}>
            <span style={style.filterCountTxt}>{filterChangesCount}</span>
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    setFilterHeight(filterRef.current.clientHeight);
    const timeout = setTimeout(() => {
      setFilterHeight(filterRef.current.clientHeight);
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [filterClicked, processingArticle, filterRef, showFilter, articleType]);

  return (
    <div
      style={{
        ...style.main,
        ...{ backgroundColor: "#FFFFFF", marginTop: navFrom == "recentActivity" ? 15 : 5, zIndex: 1299 },
      }}
      ref={filterRef}
    >
      {showFilter && (
        <div
          className={Styles.filterShadow}
          style={style.content}>
          <ButtonBase
            onClick={() => setFilterModalVisible(true)}
            style={{
              backgroundColor: "#078167",
              paddingLeft: 16,
              paddingRight: 16,
              paddingTop: filterChangesCount > 0 ? 7 : 12,
              paddingBottom: filterChangesCount > 0 ? 7 : 12,
              height: "fit-content",
            }}
          >
            <SortIconAndCount />
          </ButtonBase>
          {(((navFrom !== "recentActivity" && user.filterTooltips) ||
            (navFrom == "recentActivity" && user.recentFilterTooltips)) && articleType?.length > 0) && (
              <FilterHeaderTooltip
                isAllShow={isAllShow}
                onPress={() => {
                  changeOnboardingState()
                  let requestData;
                  if (navFrom == "recentActivity") {
                    requestData = {
                      recentFilterTooltips: false
                    }
                  } else {
                    requestData = {
                      filterTooltips: false
                    }
                  }
                  callUpdateUser(requestData)
                }}
              ></FilterHeaderTooltip>
            )}
          <FilterModal
            selectedParentCategory={selectedParentCategory}
            isAllShow={isAllShow}
            filterModalVisible={filterModalVisible}
            setFilterModalVisible={setFilterModalVisible}
            sortState={sortState}
            setSortState={setSortState}
            showStrongMatchArticles={showStrongMatchArticles}
            setShowStrongMatchArticles={setShowStrongMatchArticles}
            showWeakMatchArticles={showWeakMatchArticles}
            setShowWeakMatchArticles={setShowWeakMatchArticles}
            filterAttributes={filterAttributes}
            setFilterAttributes={setFilterAttributes}
            readFilter={readFilter}
            setReadFilter={setReadFilter}
            unreadFilter={unreadFilter}
            setUnreadFilter={setUnreadFilter}
            attributes={attributes}
            setAttributes={setAttributes}
            filterChangesCount={filterChangesCount}
          />
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              overflowX: "scroll",
            }}
          >
            {sections}
          </div>
          <div
            style={{ paddingTop: 9, paddingLeft: 6, paddingRight: 10 }}
            onClick={() => setFilterClicked(!filterClicked)}
          >
            <img
              style={
                filterClicked
                  ? { transform: `rotate(180deg)`, width: 30, height: 30 }
                  : { width: 30, height: 30 }
              }
              src={require("../../images/filter-close.png")}
            />
          </div>
        </div>
      )}
      {processingArticle && <div style={style.processingArticleDiv}>
        <span style={style.processingArticleTxt}>Ferret is processing new results and will notify you when content is ready...</span>
        <Box
          className={style.progressBox}
          sx={{
            width: 40,
            "& .MuiLinearProgress-colorPrimary": {
              backgroundColor: "#91CB6C",
            },
            "& .MuiLinearProgress-barColorPrimary": {
              backgroundColor: "#EFF1F5",
            },
          }}
        >
          <LinearProgress
            style={{ borderRadius: 16, }}
          />
        </Box>
      </div>}
      <div style={style.filterContainer}>
        <FilterByVerificationType
          verified={verified}
          setVerified={setVerified}
          partial={partial}
          setPartial={setPartial}
          needsReview={needsReview}
          setNeedsReview={setNeedsReview}
          verifiedCount={verifiedCount}
          needsReviewCount={needsReviewCount}
          partialCount={partialCount}
        />
      </div>
      <div
        style={{
          backgroundColor: "rgb(239, 241, 245)",
          height: "4px",
          marginTop: 4,
        }}
      ></div>
    </div>
  );
};
export default FiltersView;

