import React, { useEffect, useState } from "react";
import {
  Update_Recent_Searches_Status,
  Get_Recent_Search,
} from "../../../queries/queries";
import { useMutation, useLazyQuery } from "react-apollo";
import Iso3166 from "iso-3166-2";
import abbrState from "../../../utils/apis/getAbbrForState";
import RemoveAdvanceSearch from "../../../utils/removeAdvanceSearch";
import {
  titleCase,
  capitalizeSearchTermsTag,
  capitalizeCase,
} from "../../../utils/utils";
import RecentIcon from "../../../images/svg/recent-icon";
import CloseIconGrey from "../../../images/svg/close-grey-icon";
import { Avatar } from "@mui/material";
import "./style.css";
import Styles from "./recentsearch.module.css";
import { searchFormStore } from "../../../store/searchFormStore";
import LoadingBar from "../loadingBar/locadingBar";

const RecentSearch = (props) => {
  const {} = props;
  const [recentSearch, setRecentSearch] = useState([]);
  const [imageLoadError, setImageLoadError] = useState(true);
  const searchResultData = searchFormStore((state) => state.searchResultData);
  const searchFormData = searchFormStore((state) => state.searchFormData);
  const savedSearchData = searchFormStore((state) => state.savedSearchData);

  const [refetch, { data, loading }] = useLazyQuery(Get_Recent_Search, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (data?.getRecentSearch) setRecentSearch([...data?.getRecentSearch]);
  }, [data, loading]);

  useEffect(() => {
    refetch();
  }, [searchResultData, searchFormData]);

  const [updateRecentSearchesStatus, { loading: loadingDelete }] = useMutation(
    Update_Recent_Searches_Status,
    {
      onCompleted(data) {
        refetch();
      },
      onError(ee) {},
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  const handleClearARecentSearch = (searchId) => {
    updateRecentSearchesStatus({
      variables: {
        searches: [searchId],
      },
    });
  };

  const handleClearAllRecentSearch = () => {
    let rSearches = [];
    recentSearch.map(({ id }) => (rSearches = [...rSearches, id]));
    updateRecentSearchesStatus({
      variables: {
        searches: rSearches,
      },
    });
  };

  const tags = recentSearch.map(
    (
      {
        searchTerm,
        metaData,
        id,
        irrelevantArticles,
        contact,
        searchType,
        tracerQueryId,
        tahoeId,
        searchContext,
        searchCompleted,
        age,
        akaList,
        eventType,
        linkedinProfile,
      },
      index
    ) => {
      if (index >= 25) return;

      let advancedLine1 = "";
      let advancedLine2 = "";
      let profilePictureSrc = linkedinProfile?.profile_picture_src ?? "";

      let city = searchContext?.city ?? "";

      let country = searchContext?.country
        ? Iso3166.country(searchContext?.country)?.code ??
          searchContext?.country
        : "";

      let state =
        country == "US" && searchContext?.state
          ? abbrState(searchContext?.state, "abbr") ?? searchContext?.state
          : searchContext?.state ?? "";

      if (searchContext) {
        advancedLine1 = searchContext?.phone ?? "";
        advancedLine2 = searchContext?.age?.toString()
          ? "Approx. " + searchContext?.age?.toString() + " y/o"
          : "";
        advancedLine1 = titleCase(
          advancedLine1 != "" ? advancedLine1 + " " + city : city
        );
        advancedLine1 =
          advancedLine1 != "" ? advancedLine1 + " " + state : state;
        advancedLine1 =
          advancedLine1 != "" ? advancedLine1 + " " + country : country;
      }

      let place = "";
      metaData?.Place?.map((item, index) => {
        if (index == 0)
          place = RemoveAdvanceSearch(searchContext, metaData?.Place[0]);
        else {
          place = place + ";" + item;
        }
      });
      metaData?.Company?.map((item) => {
        place =
          place != ""
            ? place + ", " + item.replace(";", " ")
            : item.replace(";", " ");
      });
      metaData?.Location?.map((item) => {
        place =
          place != ""
            ? place + ", " + item.replace(";", " ")
            : item.replace(";", " ");
      });

      const placeData = place ? place.split(";") : "";

      let updatedPlaceData = "";

      if (age) {
        updatedPlaceData = `Est Age ${age}`;
      }

      if (akaList && akaList.length) {
        akaList?.map((item) => {
          if (item)
            updatedPlaceData = updatedPlaceData
              ? updatedPlaceData + ", AKA " + capitalizeSearchTermsTag(item)
              : "AKA " + capitalizeSearchTermsTag(item);
        });
      }

      placeData &&
        placeData?.map((item) => {
          updatedPlaceData = updatedPlaceData
            ? updatedPlaceData + " " + capitalizeSearchTermsTag(item)
            : capitalizeSearchTermsTag(item);
        });

      const onClickCard = () => {
        props?.searchData(
          capitalizeCase(searchTerm, true),
          metaData,
          id,
          irrelevantArticles,
          contact,
          searchType,
          tracerQueryId,
          tahoeId,
          searchContext,
          searchCompleted,
          age,
          akaList,
          eventType
        );
      };

      return (
        <div key={id}>
          {searchTerm ? (
            <div className={Styles.tagListContainer}>
              <button onClick={onClickCard}>
                {profilePictureSrc && imageLoadError ? (
                  <Avatar
                    src={profilePictureSrc}
                    avatarStyle={{
                      width: 40,
                      height: 40,
                      backgroundColor: "#FFF",
                      borderRadius: 20,
                    }}
                  >
                    <RecentIcon className={Styles.recentIcon} />
                  </Avatar>
                ) : (
                  <RecentIcon className={Styles.recentIcon} />
                )}
              </button>
              <button className={Styles.container2} onClick={onClickCard}>
                <span className={Styles.tagListTitle}>
                  {capitalizeCase(searchTerm, true)}
                </span>
                {advancedLine1 != "" && (
                  <span className={Styles.advanceText}>{advancedLine1}</span>
                )}
                {advancedLine2 != "" && (
                  <span className={Styles.advanceText}>{advancedLine2}</span>
                )}
                {place != "" && (
                  <div id="recentPlaceData" className={Styles.advanceTextTags}>
                    {updatedPlaceData}
                  </div>
                )}
              </button>
              <button
                className={Styles.closeBtn}
                onClick={() => handleClearARecentSearch(id)}
              >
                <CloseIconGrey className={Styles.closeBtnIcon} />
              </button>
            </div>
          ) : null}
        </div>
      );
    }
  );

  return (
    <>
      {loadingDelete && (
        <div className={Styles.progressbar}>
          <LoadingBar />
        </div>
      )}
      <div className={Styles.containerMain}>
        <div className={Styles.header}>
          <span className={Styles.searchParamsContainer}>Recent Searches</span>
          <button
            className={Styles.clearAllBtn}
            onClick={handleClearAllRecentSearch}
          >
            <span className={Styles.clearAllBtnTxt}>Clear all</span>
          </button>
        </div>
        <div className={Styles.clearView}>{tags}</div>
      </div>
    </>
  );
};
export default RecentSearch;
