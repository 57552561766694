import * as React from "react"

const ArrowApproved = (props) => {
    const ArrowStyles = {
      regular: '#FFF',
      dark: '#0D1324',
    }
    const {arrowStyle = ArrowStyles.regular} = props
    return (
      <svg width={props?.style?.width ?? 25} height={props?.style?.height ?? 25} viewBox="0 0 27 27" {...props}>
        <g fill="#FFF" fillRule="evenodd">
          <circle opacity={0.168} cx={13.5} cy={13.5} r={13.5} fill={arrowStyle}/>
          <path d="M20.59 8.216a1.397 1.397 0 00-1.99 0l-7.515 7.621-2.819-2.858a1.32 1.32 0 00-1.878 0 1.364 1.364 0 000 1.905l3.868 3.923a1.32 1.32 0 001.879 0l.026-.03.029-.026 8.4-8.518a1.445 1.445 0 000-2.017" />
        </g>
      </svg>
    )
}

export default ArrowApproved
