import React, {useEffect, useRef, useState} from 'react';
import SavedSearchCard from '../savedSearchCard/savedSearchCard';
import parseFerretContactsForContactsComponent from '../../utils/ferret-contact-parser';
import getMetaFromContacts from '../../utils/getMetaFromContacts'
import useSaveRecentSearch from '../../utils/apis/useSaveRecentSearch';
import { SET_PREFERENCE } from '../../queries/queries'
import { useMutation } from 'react-apollo'; 
import Variables from '../../utils/Variables'
import "./style.css";
import { useNavigate } from 'react-router-dom';
import { AlphabetScroll } from '../alphabetScroll/alphabetScroll';
import { savedSearchArticlesStore } from '../../store/savedSearchArticlesStore';
import { closestContactsEditListStore } from '../../store/closestContactsEditListStore';
import { closestContactsStore } from '../../store/closestContactsStore';

let sectionListItemOffset = [];

const ClosertContactList = (props) => {
  const refsList = useRef();
  const { contactsList = [], title = 'Starred Contacts', customListID = false, setContactList, getPotentialMatches, categories } = props
  const [filteredContacts, setFilteredContacts] = useState()
  const [searchValue, setSearchValue] = useState('');
  const [setPreferenceContacts] = useMutation(SET_PREFERENCE)
  const navigate = useNavigate();

  const articlePosition = closestContactsStore((state) => state.articlePosition)
  const setArticlePosition = closestContactsStore((state) => state.setArticlePosition)

  const removeAll = savedSearchArticlesStore((state) => state.removeAll)
  const removeAllClosetContactsState = closestContactsEditListStore((state) => state.removeAllClosetContactsState)  
  const {
    parseSaveRecentSearch,
  } = useSaveRecentSearch();
  const refs = useRef([]);

  useEffect(()=>{
    const valueCriteria = searchValue.toUpperCase();
    const contacts = parseFerretContactsForContactsComponent(
      contactsList,
    );
    let dict = {};
    let countPush = 0

    contacts.forEach(section => {
      const filteredSectionContacts = section.data.filter(contact => {
        const terms =
          Array.isArray(contact.terms) && Boolean(contact.terms.length)
            ? contact.terms.join(' ')
            : '';
        const fullName = ` ${contact.firstName} ${contact.lastName}`;
        const fullString = terms.concat(fullName);
        return fullString.toUpperCase().includes(valueCriteria);
      });
      if (filteredSectionContacts.length) {
        dict[section.title] = filteredSectionContacts;
      }
    });
    setFilteredContacts(dict)
  },[contactsList])

  const onStartPress = (data) =>{
    if(data){
      removeAllClosetContactsState()
      Variables.isAddSearches = true
      let requestData = [
        {
          id: data.id,
          isPreferred: false,
        },
      ];
      setPreferenceContacts({
        variables: {
          contacts: requestData,
        },
      });

      let tsavedSearches= contactsList;
      let mContactList = tsavedSearches.filter((savedSearch)=>savedSearch.id!=data?.id)
      setContactList(mContactList)
    }
  }


  const onPressContact = (newsItem) => {
    if(newsItem?.isProcessed && newsItem?.multiplePotentialMatchesFound){
      getPotentialMatches(newsItem?.id)
      return
    }
    removeAll?.()
    const { metadata,
      searchTerm,
      searchType,
      searchContext,
      tahoeId,
      tracerQueryId,
      requestMetadata,} = getMetaFromContacts(newsItem)
    parseSaveRecentSearch(searchTerm, metadata, 'saved-search')
    let arrayData = []
    Object.values(metadata)?.forEach(item => {
      arrayData = [...arrayData,...item?.split(';')]
    })

    if(searchContext?.age){
      let age = `App. ${searchContext?.age} y/o`
      arrayData.splice(1, 0, age+'')
    }
    if(searchContext?.state)
      arrayData.splice(1, 0, searchContext?.state)
    if(searchContext?.city)
      arrayData.splice(1, 0, searchContext?.city)
    if(searchContext?.country)
      arrayData.splice(1, 0, searchContext?.country)
    if(searchContext?.phone)
      arrayData.splice(1, 0, searchContext?.phone)
      
    const data = {
      contactItem: newsItem,
      metadata,
      searchTerm,
      searchType,
      searchContext,
      tahoeId,
      tracerQueryId,
      requestMetadata,
      feed: {
        fullName: `${newsItem.name} ${newsItem.lastName != null ? newsItem.lastName : ''}`,
        metadata: [],
        searchSettings: {initialSearchParams: arrayData}
      },
      navigateFrom: 'watchedList' 
    }

    navigate('/savedContactsArticles', { state: JSON.stringify(data) })
  }

  useEffect(() => {
    setTimeout(()=>{
      refsList?.current?.scrollTo(0, articlePosition);
    },200)
  }, []);

  const handleScroll = () => {
    const position = refsList?.current?.scrollTop
    if (position > 0) {
      setArticlePosition(position);
      }
  };

  let alphaArray=[];
  if(filteredContacts) Object.keys(filteredContacts).map((key) => {alphaArray=[...alphaArray,key]})

  return(
    <li className='listContact'  onScroll={handleScroll} ref={refsList}>
		{filteredContacts && Object.entries(filteredContacts).map(([key,values])=><>{
			<>
				<span className='alphaHeaderText' ref={(element) => {
                          refs.current[key] = element;
                        }}>{key}</span>
				{values && values.map(item => <SavedSearchCard 
					showCheckbox={false}
					savedSearch={item.originalContact}
					isFromClosest={true}
					onPress={onPressContact}
          onStartPress={onStartPress}
          categories={categories}
				/>)}
        <AlphabetScroll
          onClick={(a) => {
            refs.current[a].scrollIntoView({ behavior: 'smooth' })
          }
        }
        alphaArray={alphaArray}
      />
			</>
		}</>)}
    </li>
  );
};
export default ClosertContactList;