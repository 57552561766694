import * as React from "react"

const RecentIcon = (props) => {
  return(
  <svg
    width={40}
    height={40}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.571 20A7.428 7.428 0 1 0 20 12.571a8.263 8.263 0 0 0-5.714 2.286m0 0 .571-2.286m-.571 2.286 2.285.572M20 16v4.571l-2.971 1.486"
      stroke={'#697080'}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x={0.5} y={0.5} width={39} height={39} rx={19.5} stroke={'#C0C7D6'} />
  </svg>
)}

export default RecentIcon
