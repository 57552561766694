import React from "react";
import createStyle from "./styles";
import { getFilterImage } from "../../../images/filterColorIcon/getFilterImage";

const CheckFilter = (props) => {
  const { index, label, iconType, setStatus, count = "" , selectedMapCategory} = props;
  const status = selectedMapCategory == index;
  const style = createStyle();
  let disabled = false;

  return (
    <div onClick={setStatus} key={index} style={style.container}>
      {getFilterImage(
        label,
        status,
        iconType,
        { transform: "scale(.60)" },
        true
      )}
      <span style={{...style.lableText, color: status ? '#fff' : '#8E96A8'}}>{label + " (" + count + ")"}</span>
    </div>
  );
};

export default CheckFilter;
