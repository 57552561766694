import React, { useEffect, useState } from 'react';
import './App.css';
import {
  Route,
  Routes,
  useLocation
} from "react-router-dom";
import Login from './components/login/login';
import LoginWeb from './web/pages/login/loginWeb';
import SignUpLandingWeb from './web/pages/signUp/signUpLanding';
import SignUpUserDetailsWeb from './web/pages/signUp/signUpUserDetails';
import EmailOtpVerifyWeb from './web/pages/signUpOtp/signUpOtp';
import PreferencesWeb from './web/pages/preferences/preferences';
import PreferencesSubmitWeb from './web/pages/preferences/preferencesSubmit';
import ForgotPasswordWeb from './web/pages/forgotPassword/forgotPassword';
import ForgotPasswordOtpVerifyWeb from './web/pages/forgotPasswordOtpVerify/forgotPasswordOtpVerify';
import ResetPasswordWeb from './web/pages/resetPassword/resetPassword';
import ResetPasswordSuccessWeb from './web/pages/resetPasswordSuccess/resetPasswordSuccess';
import SearchResultsWeb from './web/pages/search/Search';
import ArticleLayoutWeb from './web/pages/articleLayout/articleLayout';
import RecentViewActivityWeb from './web/pages/recentActivity/recentViewActivity'
import SavedSearchWeb from './web/pages/savedSearch/SavedSearch'
import { useLazyQuery, useMutation } from 'react-apollo';
import { ME, REFRESH_SESSION } from './queries/queries';
import {
  getRefreshToken,
  saveToken,
  saveRefreshToken,
} from './utils/safe-storage';
import { saveBaseUrls } from './utils/cloud-config'
import { UserContextProvider } from './contexts/userContext';
import OnboardingContextProvider, {
  getOnboardingInitialStatus,
} from './contexts/onboardingContext';
import NewDashboard from './components/dashboard/dashboard';
import SearchComponent from './components/searchModalV2/searchComponent/searchComponent';
import ClosestContacts from './components/closestContacts/closestContacts'
import AcceptTermsOfService from './components/termsOfService/acceptTermsOfService'
import RecentViewActivity from './components/recentActivity/recentViewActivity'
import { setUserInfo, resetNavigationTo } from './root-navigation'
import { isMePayloadValid } from './utils/meQueryHelper'
import { useNavigate } from "react-router-dom";
import SignUpLanding from './components/signUp/signUpLanding';
import SignUpUserDetails from './components/signUp/signUpUserDetails';
import SearchResults from './components/searchResults/searchResults';
import Variables from './utils/Variables';
import { ShowSearchContextProvider } from './contexts/showSearchModal';
import SearchResultsWithQuery from './components/searchResultsWithQuery/searchResultsWithQuery';
import Article from './components/article/article';
import ArticleWeb from './web/components/article/articlePDF';
import SavedContactsArticles from './components/savedContactsArticle/savedContactsArticles';
import Notification from './components/notificationList/notification';
import Account from './components/account/account';
import EditProfile from './components/editProfile/editProfile';
import ForgotPassword from './components/forgotPassword/forgotPassword';
import ForgotPasswordOtpVerify from './components/forgotPasswordOtpVerify/forgotPasswordOtpVerify';
import ImportContactsLanding from './components/importContactsLanding/importContactsLanding';
import ImportedContacts from './components/importedContacts/importedContacts'
import ImportClosestContacts from './components/importClosestContacts/ImportClosestContacts'
import LandingPage from './components/landingPage/landingPage'
import LaunchScreenTransition from './components/launchScreenTransition/launchScreenTransition'
import EmailOtpVerify from './components/signUpOtp/signUpOtp';
import ChangePassword from './components/changePassword/changePassword';
import Preferences from './components/preferences/preferences';
import AcceptTermsOfServiceWeb from './web/pages/termsOfService/acceptTermsOfServiceWeb';

/** START WEB APP IMPORTS **/
// Layout
import Layout from './web/layout/Layout';

// Pages
import DashboardPage from './web/pages/dashboard/Dashboard';
import ResetPassword from './components/resetPassword/resetPassword';
import ResetPasswordSuccess from './components/resetPasswordSuccess/resetPasswordSuccess';
import PreferencesSubmit from './components/preferences/preferencesSubmit';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClosestContactsEditList from './components/closestContactsEditList/closestContactsEditList';
import SetToggleSettings from './utils/setToggleSetting';
import ImportLayoutPage1 from './web/pages/importContacts/importLayoutPage1';
import ImportLayoutPage2 from './web/pages/importContacts/importLayoutPage2';
import ImportLayoutPage3 from './web/pages/importContacts/importLayoutPage3';
import { appStore } from './store/appStore';
import { GlobalDebug } from './remove-consoles';
import SearchResultsWebPDF from './web/components/searchResultsWithQuery/searchResultsWeb';

import { Provider, ErrorBoundary } from '@rollbar/react'

// Will work only for prod env
const rollbarConfig = {
  accessToken: process.env.REACT_APP_ENV == "prod" ? process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN : "",
  environment: process.env.REACT_APP_ENV == "prod" ? "production" : "development",
}

const App = () => {

  const location = useLocation();

  /**
   * State management for window dimensions in order to
   * display a different layout for web or mobile sizes.
   */
  const dimensions = appStore((state) => state.dimensions)
  const setDimensions = appStore((state) => state.setDimensions)
  const setOpenSubscription = appStore((state) => state.setOpenSubscription)

  useEffect(() => {
    setDimensions({
      height: window.innerHeight,
      width: window.innerWidth,
      isDesktop: window.innerWidth >= 768
    });
    if (process.env.REACT_APP_ENV === "prod") {
      GlobalDebug(true);
    } else {
      GlobalDebug(false);
    }

    const queryParams = new URLSearchParams(location.search);
    const path = queryParams.get('path');

    if (path == "subscription") {
      setOpenSubscription(true)
    }

  }, [])

  /**
   * This effect updates the dimensions state every time the window is resized
   * in order to kick in the right layout at the specified dimensions.
   */
  useEffect(() => {

    const handleResize = () => {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
        isDesktop: window.innerWidth >= 768
      });
    }

    window.addEventListener('resize', handleResize);

  }, [window.innerWidth]);


  Variables.navigateRef = useNavigate();
  const navigate = useNavigate();
  const [loadingSplash, setLoadingSplash] = useState(true)
  const [getUserInfo, { loading, data, error }] = useLazyQuery(ME, {
    fetchPolicy: 'network-only',
  });

  const [refreshToken] = useMutation(REFRESH_SESSION, {
    onCompleted(data) {
      const { accessToken, refreshToken } = data.refreshSession;
      saveToken(accessToken);
      saveRefreshToken(refreshToken);
      getUserInfo()
      getToggleSettingsData();
    },
    onError(error) {
      getUserInfo()
      getToggleSettingsData();
    }
  });

  const getToke = () => {
    const refresh_token = getRefreshToken();
    refreshToken(
      { variables: { refreshToken: refresh_token } }
    );
  }

  useEffect(() => {
    let pathName = window.location.pathname;
    if (pathName == '/articleWebPDF' ||
      pathName == '/searchResultsWebPDF') {
      setLoadingSplash(false);
    } else {
      getToke()
    }
  }, [])


  useEffect(() => {
    if (!loading && data) {
      if (data?.me?.config) {
        const config = data?.me?.config
        saveBaseUrls(config?.authBaseUrl, config?.contactsBaseUrl, config?.activityBaseUrl,
          config?.searchBaseUrl, config?.notificationBaseUrl, config?.subscriptionBaseUrl, config?.contactsHandlerBaseUrl)
      }
      goNext()
    } else if (!loading && error) {
      setLoadingSplash(false)
      navigate('/landingPage')
    }
  }, [loading, data, error])

  const { feTogglesdata, getToggleSettingsData, setToggleSettingsData } = SetToggleSettings();

  // useEffect(() => {
  //   getToggleSettingsData();
  // }, []);

  useEffect(() => {
    if (feTogglesdata) {
      setToggleSettingsData(feTogglesdata);
    }
  }, [feTogglesdata]);

  const goNext = () => {
    let pathName = window.location.pathname
    setLoadingSplash(false)
    if (isMePayloadValid(data)) {
      setUserInfo(data.me)
      if (pathName == '/' ||
        pathName == '/login' ||
        pathName == '/landingPage' ||
        pathName == '/signUpLanding' ||
        pathName == '/acceptTermsOfService') {
        navigate('/dashboard')
      }
      // if(data?.me?.isOnboarded){
      // resetNavigationTo('LaunchScreenTransition',{
      //   previousScreen: 'preSignUp'
      // });
      // }else{
      // SplashScreen.hide()
      // resetNavigationTo("ImportContactsLanding");
      // }
    } else if (pathName != '/acceptTermsOfService') {
      navigate('/landingPage')
    }
  }


  /**
   * Returns mobile app layout on mobile breakpoints
   * @returns {JSX.Element}
   */
  const getMobileApp = () => {

    return (
      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          closeOnClick={false}
          pauseOnFocusLoss
          limit={1}
        />
        {loadingSplash ?
          <LaunchScreenTransition /> :
          <Provider config={rollbarConfig}>
            <ErrorBoundary>
              <UserContextProvider>
                <ShowSearchContextProvider>
                  <OnboardingContextProvider value={getOnboardingInitialStatus()}>
                    <Routes>
                      <Route path="/*" element={<LandingPage />} />
                      <Route path="/closestContacts" element={<ClosestContacts />} />
                      <Route path="/watchedSearch" element={<ClosestContacts />} />
                      <Route path="/login" element={<Login />} />
                      <Route path="/signUpLanding" element={<SignUpLanding />} />
                      <Route path='/signUpUserDetails' element={<SignUpUserDetails />} />
                      <Route path="/dashboard" element={<NewDashboard />} />
                      <Route path="/savedSearch" element={<NewDashboard />} />
                      <Route path="/search" element={<SearchComponent />} />
                      <Route path="/articles" element={<SearchResultsWithQuery />} />
                      <Route path="/acceptTermsOfService" element={<AcceptTermsOfService />} />
                      <Route path="/emailOtpVerify" element={<EmailOtpVerify />} />
                      <Route path="/searchResult" element={<SearchResults />} />
                      <Route path="/SearchResultsWithQuery" element={<SearchResultsWithQuery />} />
                      <Route path="/SearchResultsDetails" element={<SearchResultsWithQuery />} />
                      <Route path="/SearchResultsArticle" element={<SearchResultsWithQuery />} />
                      <Route path="/searchComponent" element={<SearchComponent />} />
                      <Route path="/searchComponentEdit" element={<SearchComponent />} />
                      <Route path="/article" element={<Article />} />
                      <Route path="/searchResultsWebPDF" element={<SearchResultsWebPDF />} />
                      <Route path="/articleWebPDF" element={<ArticleWeb />} />
                      <Route path="/savedContactsArticles" element={<SavedContactsArticles />} />
                      <Route path="/savedContactsArticlesList" element={<SavedContactsArticles />} />
                      <Route path="/savedContactsArticlesDetails" element={<SavedContactsArticles />} />
                      <Route path="/recentViewActivity" element={<RecentViewActivity />} />
                      <Route path="/notification" element={<Notification />} />
                      <Route path="/account" element={<Account />} />
                      <Route path="/forgotPassword" element={<ForgotPassword />} />
                      <Route path="/editProfile" element={<EditProfile />} />
                      <Route path="/forgotPasswordOtpVerify" element={<ForgotPasswordOtpVerify />} />
                      <Route path="/importContactsLanding" element={<ImportContactsLanding />} />
                      <Route path="/importedContacts" element={<ImportedContacts />} />
                      <Route path="/importClosestContacts" element={<ImportClosestContacts />} />
                      <Route path="/landingPage" element={<LandingPage />} />
                      <Route path="/changePassword" element={<ChangePassword />} />
                      <Route path="/resetPassword" element={<ResetPassword />} />
                      <Route path="/resetPasswordSuccess" element={<ResetPasswordSuccess />} />
                      <Route path="/preferences" element={<Preferences />} />
                      <Route path="/preferencesSubmit" element={<PreferencesSubmit />} />
                      <Route path="/closestContactsEditList" element={<ClosestContactsEditList />} />
                    </Routes>
                  </OnboardingContextProvider>
                </ShowSearchContextProvider>
              </UserContextProvider>
            </ErrorBoundary>
          </Provider>
        }
      </div>
    );

  }

  /**
   * Returns web app layout on larger breakpoints
   * @returns {JSX.Element}
   */
  const getWebApp = () => {

    /**
     * Returns loading screen transition
     * @returns {JSX.Element}
     */
    const getLoadingScreen = () => {

      return <LaunchScreenTransition />

    }

    /**
     * Returns the app's web layout
     * @returns {JSX.Element}
     */
    const getWebLayout = () => {
      let pathName = window.location.pathname
      if (pathName == '/' ||
        pathName == '/login' ||
        pathName == '/landingPage' ||
        pathName == '/signUpLanding' ||
        pathName == '/signUpUserDetails' ||
        pathName == '/emailOtpVerify' ||
        pathName == '/acceptTermsOfService' ||
        pathName == '/preferences' ||
        pathName == '/preferencesSubmit' ||
        pathName == '/forgotPassword' ||
        pathName == '/forgotPasswordOtpVerify' ||
        pathName == '/resetPasswordSuccess' ||
        pathName == '/resetPassword') {
        return (
          <Provider config={rollbarConfig}>
            <ErrorBoundary>
              <UserContextProvider>
                <OnboardingContextProvider value={getOnboardingInitialStatus()}>
                  <Routes>
                    <Route path="/*" element={<LoginWeb />} />
                    <Route path="/signUpLanding" element={<SignUpLandingWeb />} />
                    <Route path="/signUpUserDetails" element={<SignUpUserDetailsWeb />} />
                    <Route path="/emailOtpVerify" element={<EmailOtpVerifyWeb />} />
                    <Route path="/acceptTermsOfService" element={<AcceptTermsOfServiceWeb />} />
                    <Route path="/preferences" element={<PreferencesWeb />} />
                    <Route path="/preferencesSubmit" element={<PreferencesSubmitWeb />} />
                    <Route path="/forgotPassword" element={<ForgotPasswordWeb />} />
                    <Route path="/forgotPasswordOtpVerify" element={<ForgotPasswordOtpVerifyWeb />} />
                    <Route path="/resetPassword" element={<ResetPasswordWeb />} />
                    <Route path="/resetPasswordSuccess" element={<ResetPasswordSuccessWeb />} />
                  </Routes>
                </OnboardingContextProvider>
              </UserContextProvider>
            </ErrorBoundary>
          </Provider>
        )
      } else {
        return (
          <>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              closeOnClick={false}
              pauseOnFocusLoss
              limit={1}
            />
            <Provider config={rollbarConfig}>
              <ErrorBoundary>
                <UserContextProvider>
                  <ShowSearchContextProvider>
                    <OnboardingContextProvider value={getOnboardingInitialStatus()}>
                      <Layout isDesktop={dimensions.isDesktop}>
                        <Routes>
                          <Route path="/dashboard" element={<DashboardPage isDesktop={dimensions.isDesktop} />} />
                          <Route path="/notification" element={<DashboardPage isDesktop={dimensions.isDesktop} />} />
                          <Route path="/search" element={<SearchResultsWeb isDesktop={dimensions.isDesktop} />} />
                          <Route path="/searchResult" element={<SearchResultsWeb isDesktop={dimensions.isDesktop} />} />
                          <Route path="/SearchResultsDetails" element={<ArticleLayoutWeb isDesktop={dimensions.isDesktop} />} />
                          <Route path="/searchComponent" element={<ArticleLayoutWeb isDesktop={dimensions.isDesktop} />} />
                          <Route path="/SearchResultsWithQuery" element={<ArticleLayoutWeb isDesktop={dimensions.isDesktop} />} />
                          <Route path="/importContactsLanding" element={<ImportLayoutPage1 />} />
                          <Route path="/importedContacts" element={<ImportLayoutPage2 />} />
                          <Route path="/importClosestContacts" element={<ImportLayoutPage3 />} />
                          <Route path="/articles" element={<ArticleLayoutWeb />} />
                          <Route path="/SearchResultsArticle" element={<ArticleLayoutWeb />} />
                          <Route path="/recentViewActivity" element={<RecentViewActivityWeb />} />
                          <Route path="/savedSearch" element={<SavedSearchWeb />} />
                          <Route path="/closestContacts" element={<SavedSearchWeb />} />
                          <Route path="/watchedSearch" element={<SavedSearchWeb />} />
                          <Route path="/savedContactsArticles" element={<SavedSearchWeb />} />
                          <Route path="/savedContactsArticlesList" element={<SavedSearchWeb />} />
                          <Route path="/savedContactsArticlesDetails" element={<SavedSearchWeb />} />
                          <Route path="/articleWebPDF" element={<ArticleWeb />} />
                          <Route path="/searchResultsWebPDF" element={<SearchResultsWebPDF />} />
                        </Routes>
                      </Layout>
                    </OnboardingContextProvider>
                  </ShowSearchContextProvider>
                </UserContextProvider>
              </ErrorBoundary>
            </Provider>
          </>
        );
      }
    }

    return loadingSplash ? getLoadingScreen() : getWebLayout();

  }

  // Holds a reference to the root element in order to modify some css attributes
  // for the desktop layout to work
  const html = document.getElementsByTagName('html')[0];
  const body = document.getElementsByTagName('body')[0];
  const root = document.getElementById('root');
  dimensions.isDesktop ? html.classList.add('desktop-html') : html.classList.remove('desktop-html');
  dimensions.isDesktop ? body.classList.add('desktop-body') : body.classList.remove('desktop-body');
  dimensions.isDesktop ? root.classList.add('desktop-root') : root.classList.remove('desktop-root');

  /**
   * Main render method
   */
  // let domain = window.location.protocol + "//" + window.location.hostname
  // if (window.location.hostname != 'localhost') {
  //   if (dimensions.isDesktop == true && domain != process.env.REACT_APP_WEB_URL) {
  //     let oldUrl = window.location.pathname
  //     let newDomain = process.env.REACT_APP_WEB_URL + oldUrl;
  //     window.location.replace(newDomain);
  //   } else if (dimensions.isDesktop == false && domain != process.env.REACT_APP_PWA_URL) {
  //     let oldUrl = window.location.pathname
  //     let newDomain = process.env.REACT_APP_PWA_URL + oldUrl;
  //     window.location.replace(newDomain);
  //   }
  // }
  return dimensions.isDesktop ? getWebApp() : getMobileApp();
  // return getMobileApp();

}

export default App;

