import { height } from "@mui/system";

const createStyle = () => {
  return {
    container: {
      display: "flex",
      flexDirection: "column",
    },
    borderContainerNew: {
      borderColor: "#EFF1F5",
      borderTopLeftRadius: 12,
      borderRightWidth: 1,
      borderTopWidth: 0,
      borderLeftWidth: 0,
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      borderStyle: "solid",
    },
    borderContainer: {
      marginLeft: 16,
      marginRight: 16,
      marginTop: 24,
      borderColor: "#EFF1F5",
      borderRadius: 12,
      borderWidth: 2,
      display: "flex",
      flexDirection: "column",
      borderStyle: "solid",
    },
    borderContainerMain: {
      marginTop: 6,
      marginBottom: 16,
      backgroundColor: "#ffffff",
      display: "flex",
      flexDirection: "column",
    },
    borderContainerNoDetails: {
      marginHorizontal: 16,
      marginTop: 24,
      borderColor: "#EFF1F5",
      borderRadius: 12,
      borderWidth: 2,
      borderStyle: "solid",
      backgroundColor: "rgba(7, 129, 103, 0.1)",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    textNoDetails: {
      fontFamily: "Proxima Nova",
      fontStyle: "normal",
      marginHorizontal: 16,
      marginTop: 16,
      marginBottom: 10,
      fontWeight: "400",
      fontSize: 14,
      letterSpacing: -0.01,
      color: "#142030",
      flexGrow: 0,
    },
    searchesIcon: {
      marginStart: 12,
    },
    categoryContainer: {
      marginTop: 14,
      borderColor: "#EFF1F5",
    },
    textNoInfo: {
      textTransform: "uppercase",
      fontFamily: "Proxima Nova",
      fontStyle: "normal",
      marginHorizontal: 16,
      marginTop: 16,
      marginBottom: 10,
      fontWeight: "600",
      fontSize: 13,
      letterSpacing: 0.05,
      color: "#142030",
      flexGrow: 0,
    },
    textNoInfoDetail: {
      marginHorizontal: 16,
      marginBottom: 18,
      fontFamily: "Proxima Nova",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: 15,
      letterSpacing: -0.01,
      color: "#142030",
      flexGrow: 0,
    },
    sectionHeader: {
      textTransform: "capitalize",
      fontFamily: "Proxima Nova",
      fontStyle: "normal",
      paddingTop: 18,
      fontWeight: "600",
      fontSize: 13,
      letterSpacing: 0.05,
      color: "#142030",
      flexGrow: 0,
    },
    sectionDrop: {
      fontFamily: "Proxima Nova",
      fontStyle: "normal",
      marginHorizontal: 16,
      fontWeight: "600",
      letterSpacing: 0.05,
      color: "#142030",
      flexGrow: 0,
      fontSize: 16,
      marginTop: 16,
      marginBottom: 16,
      marginLeft: 16,
      display: "inline-block",
    },
    sectionDropInner: {
      textTransform: "capitalize",
      fontFamily: "Proxima Nova",
      fontStyle: "normal",
      marginHorizontal: 16,
      fontWeight: "600",
      letterSpacing: 0.05,
      color: "#142030",
      flexGrow: 0,
      fontSize: 16,
      marginTop: 16,
      marginBottom: 16,
      marginLeft: 32,
      display: "inline-block",
    },
    sectionView: {
      marginHorizontal: 20,
      fontFamily: "Proxima Nova",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: 14,
      letterSpacing: -0.01,
      paddingLeft: 6,
      color: "#142030",
      flexGrow: 0,
    },
    sectionViewNextLine: {
      marginHorizontal: 20,
      fontFamily: "Proxima Nova",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: 15,
      letterSpacing: -0.01,
      color: "#8E96A8",
      flexGrow: 0,
    },
    sectionViewGray: {
      paddingLeft: 4,
      fontFamily: "Proxima Nova",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: 15,
      letterSpacing: -0.01,
      color: "#8E96A8",
      flexGrow: 0,
    },
    moreView: {
      backgroundColor: "#EFF1F5",
      flexDirection: "row",
      marginTop: 10,
      borderBottomEndRadius: 10,
      borderBottomStartRadius: 10,
    },
    moreViewNew: {
      paddingRight: 20,
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
      borderTopWidth: 0,
      borderRightWidth: 1,
      borderLeftWidth: 0,
      borderColor: "#EFF1F5",
      borderStyle: "solid",
    },
    moreViewCenter: {
      paddingRight: 20,
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
      borderTopWidth: 0,
      borderRightWidth: 1,
      borderLeftWidth: 0,
      borderColor: "#EFF1F5",
      borderStyle: "solid",
    },
    moreViewCenterInner: {
      paddingRight: 20,
      zIndex: 1,
      borderRadius: 12,
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
      backgroundColor: "#fff",
    },
    moreViewCenterInner2: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
      borderTopWidth: 0,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      borderColor: "#EFF1F5",
      borderStyle: "solid",
    },
    moreViewCenterBottom: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
      borderTopWidth: 0,
      borderBottomWidth: 0,
      borderLeftWidth: 0,
      borderColor: "#EFF1F5",
      borderStyle: "solid",
    },
    showOthers: {
      fontFamily: "Proxima Nova",
      fontStyle: "normal",
      marginHorizontal: 8,
      marginTop: 16,
      fontWeight: "700",
      fontSize: 15,
      letterSpacing: 0.05,
      color: "#5391F7",
      flexGrow: 0,
      marginLeft: 8,
    },
    textCategoriesHeader: {
      fontFamily: "Proxima Nova",
      fontStyle: "normal",
      marginTop: 10,
      marginHorizontal: 4,
      fontWeight: "700",
      fontSize: 20,
      letterSpacing: -0.01,
      color: "#142030",
    },
    itemView: {
      width: 64,
      height: 64,
      borderColor: "#EFF1F5",
      borderRadius: 12,
      borderWidth: 2,
      borderStyle: "solid",
      alignSelf: "center",
      marginTop: 16,
      backgroundColor: "#fff",
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
    },
    itemViewData: {
      backgroundColor: "#fff",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      padding: 10,
      marginTop: 8,
      borderColor: "#EFF1F5",
      borderRadius: 12,
      borderWidth: 1,
      borderStyle: "solid",
      alignSelf: "center",
      marginTop: 6,
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
    },
    filterLable: {
      alignSelf: "center",
      marginTop: 8,
      textAlign: "center",
      fontFamily: "Proxima Nova",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: 14,
      textAlign: "center",
      letterSpacing: -0.01,
      textTransform: "capitalize",
      color: "#142030",
    },
    itemLabel: {
      fontFamily: "Proxima Nova",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: 10,
      color: "#697080",
      textAlign: "initial",
    },
    cardDiv: {
      paddingLeft: 6,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      maxWidth: 90,
      marginRight: 4,
    },
    profileName: {
      marginTop: 16,
      fontFamily: "Proxima Nova",
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: 18,
      color: "#142030",
      whiteSpace: "break-spaces",
      textTransform: "capitalize",
    },
    profileTitle: {
      marginBottom: 16,
      fontFamily: "Proxima Nova",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: 14,
      color: "#697080",
      whiteSpace: "break-spaces",
      textTransform: "capitalize",
      textAlign: "center",
    },
    addressProfile: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingBottom: 16,
    },
    infoContProfile: {
      display: "flex",
      paddingTop: 8,
      paddingBottom: 8,
      flexDirection: "column",
      justifyContent: "center",
      alignTtems: "flex-start",
      alignSelf: "stretch",
      maxWidth: 140,
    },
    addressText: {
      marginLeft: 10,
      whiteSpace: "break-spaces",
      fontFamily: "Proxima Nova",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: 14,
      color: "#142030",
      lineHeight: 1.2,
      display: "inline-block",
      width: "fit-content",
    },
    infoText: {
      marginLeft: 10,
      fontFamily: "Proxima Nova",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: 14,
      color: "#078167",
      flex: "none",
      lineHeight: 1.2,
      display: "inline-block",
      width: 115,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      display: "inline-block",
    },
    innerTextContainer: {
      marginTop: -2,
      zIndex: 0,
      alignItems: "center",
      marginLeft: 32,
      marginRight: 20,
      paddingBottom: 4,
      paddingTop: 16,
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      marginBottom: 10,
      borderColor: "#EFF1F5",
      borderStyle: "solid",
      borderBottomWidth: 0,
      borderRightWidth: 0,
      borderTopWidth: 0,
      borderLeftWidth: 0,
    },
    linkedInSectionView: {
      flexDirection: "row",
      display: "flex",
      justifyContent: "space-between",
      width: "-webkit-fill-available",
    },
    profileSection: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    addressSection: {
      alignSelf: "flex-start",
      marginLeft: 30,
    },
    processingView: {
      backgroundColor: "#EFF1F5",
      height: 82,
    },
  };
};

export default createStyle;
