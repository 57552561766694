import React, { useEffect, useState } from "react";
import { useLazyQuery } from "react-apollo";
import ContactsImported from "./contactsImported";
import ContactsProcessed from "../contactsProcessedCard/contactsProcessed";
import { GET_FERRET_CONTACT_LIST } from "../../../queries/queries";
import Variables from "../../../utils/Variables";
import { savedSearchesStore } from "../../../store/savedSearchesStore";

const REFRESH_TIMEOUT = 2000;
let lastTimeout = null;
const ContactsCard = (props) => {
  const [isProcessRefresh, setProcessRefresh] = useState(false);
  const savedSearches = savedSearchesStore((state) => state.savedSearches);
  const setSavedSearches = savedSearchesStore(
    (state) => state.setSavedSearches
  );
  const setSavedStoreCategories = savedSearchesStore(
    (state) => state.setCategories
  );

  const broadcast = new BroadcastChannel("background-contact-process-message");

  useEffect(() => {
    if (broadcast) {
      broadcast.onmessage = (res) => {
        console.log("Received Foreground-BG notification", res.data);
        refreshContacts(res.data);
      };
    }
  }, []);

  const refreshContacts = (payload) => {
    if (payload?.data?.type === "import-contact-process-notification") {
      setProcessRefresh(true);
      clearTimeout(lastTimeout);
      lastTimeout = setTimeout(() => {
        // To avoid multiple API call once received multiple notification
        getFerretContactList({
          variables: {
            showSavedSearches: true,
          },
        });
      }, REFRESH_TIMEOUT);
    }
  };

  const [
    getFerretContactList,
    { loading: loadingContacts, data: dataContacts, error: errorContacts },
  ] = useLazyQuery(GET_FERRET_CONTACT_LIST, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (!loadingContacts && dataContacts) {
      Variables.isAddSearches = false;
      setProcessRefresh(false);
      setSavedSearches(dataContacts?.contactList?.contacts);
      setSavedStoreCategories(dataContacts?.contactList?.categories);
    }
  }, [loadingContacts, dataContacts, errorContacts]);

  useEffect(() => {
    if (
      Variables.isAddSearches ||
      savedSearches == null ||
      savedSearches?.length == 0
    ) {
      getFerretContactList({
        variables: {
          showSavedSearches: true,
        },
      });
    }
  }, []);

  return (
    <>
      {/* {!(!loadingContacts && savedSearches?.length == 0) && */}
      <>
        <ContactsImported
          isProcessRefresh={!isProcessRefresh && loadingContacts}
          savedSearches={savedSearches}
        />

        {/* <ContactsProcessed
          isProcessRefresh={!isProcessRefresh && loadingContacts}
          savedSearches={savedSearches}
        /> */}
      </>
      {/* } */}
    </>
  );
};

export default ContactsCard;
