import { InputLabel, Select, MenuItem, FormControl } from "@mui/material";
import Styles from './selectOptionField.module.css'

export const SelectOptionField = ({
  title,
  value,
  setValue,
  type,
  fieldValid,
  setFieldValid,
  inputProps,
  fieldLabel,
  options,
  optionsLabel
}) => {

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <>
      <FormControl fullWidth>
        <div className={Styles.fieldLabel}>{title}</div>

        <Select
          value={value}
          onChange={handleChange}
          label={optionsLabel}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          sx={{
            padding: 0
          }}
        >
          {options?.map((item) => (
            <MenuItem
              key={item.id}
              value={item.id}
            >
              {item.value ?? item.reason}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  )
}
