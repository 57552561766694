const addQueryData = (queryData, newMetadata) => {
    if(queryData){
        if(queryData?.phone && queryData?.phone != '')
          newMetadata.Phone = queryData?.phone
        if(queryData?.age && queryData?.age != '')
          newMetadata.Age = queryData?.age
    
        let Place = ''
        if(queryData?.city && queryData?.city != '')
          Place = queryData?.city
        if(queryData?.state && queryData?.state != '')
          Place = Place != '' ? Place+', '+queryData?.state : queryData?.state
        if(queryData?.country && queryData?.country != '' && !newMetadata?.Place?.includes(queryData?.country))
          Place = Place != '' ? Place+', '+queryData?.country : queryData?.country 
        
        if(newMetadata?.Place && newMetadata?.Place != '')
          newMetadata.Place = newMetadata.Place+';'+Place
        else
          newMetadata.Place = Place
      }
    return newMetadata;
}
export default addQueryData