import * as React from "react"

const IOSShareIcon = (props) => (
  <svg
    width={15}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.91 3.636 9.617 4.927 8.173 3.482v10.154h-1.8V3.482L4.927 4.927l-1.29-1.29L7.272 0l3.636 3.636Zm3.636 4.546v10c0 1-.819 1.818-1.819 1.818H1.818A1.818 1.818 0 0 1 0 18.182v-10c0-1.01.81-1.818 1.818-1.818h2.727v1.818H1.818v10h10.91v-10H10V6.364h2.727c1 0 1.819.809 1.819 1.818Z"
      fill="#697080"
    />
  </svg>
)

export default IOSShareIcon
