import { useContext } from "react";
import OnboardingTooltip from "../onboardingTooltip";
import OnboardingPopover from "../onboardingPopover";
import WhiteSearchIcon from "../../../images/svg/whiteSearchIcon";
import { UPDATE_FERRET_USER } from "../../../queries/queries";
import { useMutation } from "react-apollo";
import { UserContext } from "../../../contexts/userContext";
import { setUserInfo } from "../../../root-navigation";
import Styles from './advancedSearchTooltip.module.css'


const AdvancedSearchTooltip3 = ({
  showAdvancedSearchTooltip3,
  setShowAdvancedSearchTooltip3,
  isWeb,
  ...props
}) => {
  const { user, setUser } = useContext(UserContext);

  const [userUpdaterMutation] = useMutation(UPDATE_FERRET_USER, {
    fetchPolicy: "no-cache",
    onCompleted: ({ updateUser }) => {
      let u = setUserInfo(updateUser);
      setUser({ ...u });
    },
  });

  const updateUserStatus = () => {
    const requestData = {
      advancedSearchOnboardingDone: true,
    };
    userUpdaterMutation({
      variables: requestData,
    });
  };

  return (
    <>
      <OnboardingTooltip
        title={
          <OnboardingPopover
            title={isWeb ? "Advanced Search (2 of 2)" : "Advanced Search (3 of 3)"}
            content={
              <div>
                Add a <span className={Styles.highlightedContentText}>state</span> to
                search with more detail and improve results.
              </div>
            }
            icon={<WhiteSearchIcon />}
            onClick={() => {
              setShowAdvancedSearchTooltip3(false);
              if (isWeb) {
                setUser({ ...user, advancedSearchOnboardingLocal: true })
              } else
                updateUserStatus();
            }}
          />
        }
        componentStyle={{ marginTop: "10px !important", marginRight: "10px !important" }}
        arrowStyles={{ overflow: "inherit" }}
        isVisible={
          showAdvancedSearchTooltip3 && user.advancedSearchOnboardingDone === false
        }
        placement="bottom"
      >
        {props.children}
      </OnboardingTooltip>

      {(isWeb && showAdvancedSearchTooltip3 && user.advancedSearchOnboardingDone === false) &&
        <div style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          display: "flex",
          alignItems: "flex-end",
          inset: "0px auto 0px -100px",
          transform: "translate3d(0px, 0px, 0px)",
          zIndex: 8
        }} />
      }
    </>
  );
};

export default AdvancedSearchTooltip3;
