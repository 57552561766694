import { Backdrop, CircularProgress, Dialog } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useMutation } from "react-apollo";
import "../../../App.css";
import {
  DELETE_USER_ACCOUNT,
  UPDATE_FERRET_USER,
} from "../../../queries/queries";
import { savedSearchesStore } from "../../../store/savedSearchesStore";
import Styles from "./editProfile.module.css";
import { EmailField } from "../../../components/fields/emailField";
import { SimpleField } from "../../../components/fields/simpleField";
import { UserContext } from "../../../contexts/userContext";
import { logout } from "../../../utils/auth";
import { setUserInfo } from "../../../root-navigation";
import { accountOverlayStore } from "../../../store/accountOverlayStore";
import {
  OnboardingContext,
  getOnboardingInitialStatus,
} from "../../../contexts/onboardingContext";
import { closestContactsStore } from "../../../store/closestContactsStore";
import { searchFormStore } from "../../../store/searchFormStore";
import { searchResultStore } from "../../../store/searchResultStore";
import { notificationStore } from "../../../store/notificationStore";

/**
 * ProfileEditForm Component
 * @returns {JSX.Element}
 * @constructor
 */
function ProfileEditForm({ changePassword }) {
  const setOpenEditProfile = accountOverlayStore(
    (state) => state.setOpenEditProfile
  );

  const removeAll = savedSearchesStore((state) => state.removeAll);
  const closestRemoveAll = closestContactsStore((state) => state.removeAll);
  const searchFormRemoveAll = searchFormStore((state) => state.removeAll);
  const searchResultRemoveAll = searchResultStore((state) => state.removeAll);
  const removeAllAccount = accountOverlayStore((state) => state.removeAll);
  const removeAllNotification = notificationStore((state) => state.removeAll);

  const { user, setUser } = useContext(UserContext);
  const { setOnboardingState } = useContext(OnboardingContext);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [saveBtnEnabled, setSaveBtnEnabled] = useState(false);
  const [firstNameValid, setFirstNameValid] = useState({
    status: null,
    msg: "",
  });
  const [lastNameValid, setLastNameValid] = useState({ status: null, msg: "" });
  const [firstName, setFirstName] = useState(user.givenName);
  const [lastName, setLastName] = useState(user.familyName);

  const [userUpdaterMutation, { data, loading, error }] =
    useMutation(UPDATE_FERRET_USER);

  useEffect(() => {
    if (!loading && data) {
      let u = setUserInfo(data.updateUser);
      setUser({ ...u });
      setOpenEditProfile(false);
    } else if (!loading && error) {
      console.log(error);
    }
  }, [data, loading, error]);

  const checkPersonalDetailsValidation = () => {
    let isValid = false;

    if (firstName?.length == 0) {
      setFirstNameValid({ status: false, msg: "First Name is required" });
      isValid = false;
    } else if (!firstName?.match(/^(([A-Za-z]|[" "]|[a-z]+['-]?)+)$/)) {
      setFirstNameValid({
        status: false,
        msg: "Please enter a valid first name.",
      });
      isValid = false;
    } else {
      setFirstNameValid({ status: true, msg: "" });
      isValid = true;
    }

    if (lastName?.length == 0) {
      setLastNameValid({ status: false, msg: "Last Name is required" });
      isValid = false;
    } else if (!lastName?.match(/^(([A-Za-z]|[" "]|[a-z]+['-]?)+)$/)) {
      setLastNameValid({
        status: false,
        msg: "Please enter a valid last name.",
      });
      isValid = false;
    } else {
      setLastNameValid({ status: true, msg: "" });
      isValid = true;
    }
    return isValid;
  };

  useEffect(() => {
    if (firstName !== user.givenName || lastName !== user.familyName)
      setSaveBtnEnabled(true);
    else setSaveBtnEnabled(false);
    checkPersonalDetailsValidation();
  }, [firstName, lastName, user.givenName, user.familyName]);

  function submitForm() {
    const variables = {
      lastName: lastName,
      name: firstName,
    };
    if (user.userType !== "email") variables.email = user.email;
    userUpdaterMutation({
      variables: variables,
    });
  }

  const [DeleteUser, { loading: loadingVal }] = useMutation(
    DELETE_USER_ACCOUNT,
    {
      onCompleted: (data) => {
        setOpenEditProfile(false);
        searchFormRemoveAll();
        searchResultRemoveAll();
        closestRemoveAll();
        removeAll();
        removeAllAccount();
        removeAllNotification();
        logout().then(() => setOnboardingState(getOnboardingInitialStatus()));
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const renderDeleteDialog = () => {
    return (
      <Dialog
        open={true}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            margin: 0,
          },
        }}
      >
        <div className={Styles.deleteModal}>
          <div style={{ fontWeight: "bold" }}>Delete Account?</div>

          <p
            style={{
              paddingRight: "22px",
              paddingLeft: "22px",
              textAlign: "center",
            }}
          >
            Once you delete your account, your profile is permanently removed
            from Ferret and your account record and personal data are deleted
            from your account.
          </p>

          <button
            onClick={() => {
              DeleteUser();
              setShowDeleteDialog(false);
            }}
            className={Styles.deleteEditBtn}
          >
            Yes, Delete
          </button>

          <button
            style={{ color: "#007AFF", paddingBottom: 8, paddingTop: 16 }}
            onClick={() => setShowDeleteDialog(false)}
          >
            Cancel
          </button>
        </div>
      </Dialog>
    );
  };

  return (
    <>
      <h3 className="text-center mb-5">Edit Profile</h3>

      <div className={Styles.inputFieldBox}>
        <SimpleField
          title="First Name"
          placeholder={"e.g. John"}
          value={firstName}
          setValue={setFirstName}
          fieldValid={firstNameValid}
        />
        <div className={Styles.field}>
          <SimpleField
            title="Last Name"
            placeholder={"e.g. Doe"}
            value={lastName}
            setValue={setLastName}
            fieldValid={lastNameValid}
          />
        </div>
        <div className={Styles.field}>
          <EmailField
            fieldLabel={"Email"}
            email={user.email}
            setEmail={() => {}}
            emailValid={true}
            setEmailValid={() => {}}
            disabled={true}
          />
        </div>
        {user.userType === "email" && (
          <div className={Styles.changePassword} onClick={changePassword}>
            Change Password
          </div>
        )}
      </div>

      <div className="mt-4">
        <button
          className="btn btn--small block w-full text-red-500 border-0 mb-4"
          onClick={() => setShowDeleteDialog(true)}
        >
          Delete Account
        </button>

        <button
          className="btn btn--primary block w-full"
          onClick={() => {
            if (saveBtnEnabled) submitForm();
          }}
        >
          Save
        </button>
      </div>

      {showDeleteDialog && renderDeleteDialog()}

      {(loading || loadingVal) && (
        <Backdrop
          sx={{
            position: "absolute",
            backgroundColor: "#00000000",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
    </>
  );
}

// Component export
export default ProfileEditForm;
