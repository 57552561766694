const { windowWidth, windowHeight } = window;
const getContactsListContainerStyle = () => {
  return {
    contactsListContainer: {
      backgroundColor: "#FFFFFF",
      position: "relative",
    },
    loadingPageIndicator: {
      marginTop: 99,
      position: "absolute",
      zIndex: 1,
      backgroundColor: "transparent",
      height: "100%",
      width: "100%",
      borderTopLeftRadius: 12,
      borderTopRightRadius: 12,
    },
  };
};
const createStyle = () => {
  const contactsListContainerStyle = getContactsListContainerStyle();
  return {
    ...contactsListContainerStyle,
    container: {
      flex: 1,
      backgroundColor: "#253662",
      flexDirection: "column",
      overflowY: "hidden",
      overflowX: "hidden",
    },
    filtersIcon: {
      height: 20,
      width: 20,
      alignSelf: "center",
      tintColor: "#057E64",
    },
    contactsListContainerBody: {
      flex: 1,
      flexDirection: "column",
      marginTop: 19,
    },
    contactListHeaderContainer: {
      flexDirection: "row",
      marginTop: 15,
      marginHorizontal: 15,
      alignItems: "center",
    },
    contactListTitleContainer: {
      flexDirection: "row",
      flex: 1,
      alignItems: "center",
    },
    contactListTitle: {
      fontSize: 21,
      fontWeight: "bold",
      marginRight: 10,
      color: "#FFFFFF",
    },
    tagListContainer: {
      display: "inline-block",
      flexDirection: "row",
      marginTop: 20,
      margin: 5,
      marginBottom: 10,
      alignItems: "center",
      height: windowHeight / 30,
      borderRadius: 4,
      paddingLeft: 4,
      paddingRight: 4,
      paddingTop: 1,
      paddingBottom: 2,
      borderColor: "rgba(255, 255, 255, 0.15)",
      borderWidth: 1,
      borderRadius: 6,
      borderStyle: "solid",
    },
    tagMain: {
      flexDirection: "row",
    },
    flatlistContainer: {
      marginTop: 4,
      marginLeft: 10,
      overflowX: "auto",
      whiteSpace: "nowrap",
      scrollbarWidth: "none",
    },
    leftHeaderContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    headerContainer: {
      display: "flex",
      flexDirection: "row",
      // marginLeft: 15,
      justifyContent: "space-between",
      alignItems: "center",
      width: windowWidth,
      marginTop: 14,
    },
    tagListTitle: {
      color: "#FFFFFF",
      fontSize: 14,
      marginLeft: 0,
      fontWeight: "400",
    },
    contactListSubTitle: {
      color: "#FFFFFF",
      fontSize: 16,
      fontWeight: "600",
      marginLeft: 0,
    },
    contactListFiltersTitle: {
      marginRight: 15,
      color: "#057E64",
      fontSize: 14,
      fontWeight: "600",
    },
    contactListFiltersContainer: {
      borderColor: "#057E64",
      borderRadius: 5,
      borderWidth: 1,
      paddingHorizontal: 10,
      paddingVertical: 9,
      flexDirection: "row",
      alignItems: "center",
    },
    backStyle: {
      padding: 12,
      paddingRight: 10,
      //paddingLeft:0,
    },
    riskBarContainer: {
      marginTop: 15,
    },
    headerLeft: {
      container: {
        marginTop: 0,
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#142030",
        paddingBottom: 5,
      },
      fullName: {
        marginLeft: 10,
        color: "#FFF",
        fontSize: 22,
        fontWeight: "700",
        width: windowWidth / 2.5,
        textTransform: "capitalize",
      },
    },
    profilePicture: {
      height: 30,
      width: 30,
      borderRadius: 15,
      borderColor: "#FFF",
      borderWidth: 3,
    },
    headerRight: {
      container: {
        flexDirection: "row",
        marginRight: 16,
      },
      editSearchButton: {
        backgroundColor: "#078167",
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 3,
        paddingBottom: 3,
        borderRadius: 6,
      },
      editSearchDisabedButton: {
        backgroundColor: "#C0C7D6",
        padding: 4,
        borderRadius: 6,
      },
      editSearchText: {
        fontFamily: "Proxima Nova",
        color: "#FFF",
        textAlign: "center",
        fontSize: 14,
        fontWeight: "400",
      },
      editSearchDisabledText: {
        fontFamily: "Proxima Nova",
        color: "#697080",
        textAlign: "center",
        fontSize: 14,
        fontWeight: "400",
      },
      settings: {
        marginLeft: windowWidth / 15,
        marginRight: windowWidth / 25,
        fillColor: "#FFF",
      },
    },
    histogram: {
      container: {
        height: 97,
        borderColor: "#EEF2F8",
        borderTopWidth: 1,
        marginBottom: 10,
        backgroundColor: "#F8FAFF",
      },
      barChartContainer: {
        height: 58,
        marginTop: 5,
        borderBottomColor: "#C0C7D6",
        borderBottomWidth: 1,
      },
      xAxisLabelsContainer: {
        height: "100%",
        flexDirection: "row",
        justifyContent: "space-around",
        paddingTop: 12,
      },
      xAxisLabel: {
        color: "#8F9BB6",
        fontSize: 12,
        fontWeight: "600",
      },
    },
  };
};

export default createStyle;
