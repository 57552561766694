import html2pdf from 'html2pdf.js';

export const generatePdf = async (id, name, callback) => {

  try {
    let content = document.getElementById(id);
    console.log("111 222", content, id)
    const config = {
      margin: [10, 6, 12, 6],
      autoPaging: "text",
      filename: `${name}.pdf`,
      image: { type: 'jpeg', quality: 1 },
      html2canvas: {
        scale: 1,
        letterRendering: true,
        allowTaint: false,
        useCORS: true,
        windowHeight: content.windowHeight
      },
      pagebreak: { mode: ['css', 'legacy'] },
      jsPDF: { orientation: 'portrait', unit: 'mm', format: 'a4' },
      enableLinks: true
    }

    const year = new Date().getFullYear();
    const text = `Copyright © ${year} Ferret`;

    html2pdf().from(content).set(config).toPdf().get('pdf')
      .then((pdf) => {
        try {
          var totalPages = pdf.internal.getNumberOfPages();

        for (let i = 0; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setTextColor(150);

          const fontSize = 10;

          const pageWidth = pdf.internal.pageSize.getWidth();
          const textWidth = pdf.getStringUnitWidth(text) * fontSize / pdf.internal.scaleFactor;
          const xPosition = (pageWidth - textWidth) / 2;

          pdf.setFontSize(fontSize);
          pdf.text(text, xPosition, pdf.internal.pageSize.getHeight() - 6);

          callback()
        }
        } catch (e) {
          console.log("pdf generate error", e)
          callback()
        }
      })
      .catch((e) => {
        console.log("pdf generate error", e)
        callback()
      })
      .save();
  } catch (e) {
    console.log(e)
    callback()
  }
}