import { create } from 'zustand'

export const notificationStore = create((set) => ({
  loadingClickCard: false,
  openArticle: { status: false, item: null },
  openNotification: false,
  notificationCount: 0,
  sectionIndex: 0,
  notification: [],
  filteredNotification: [],
  filteredNotificationExist: false,
  isChanged: false,
  scrollIndex: 0,
  setLoadingClickCard: (data) => set(() => ({ loadingClickCard: data })),
  setOpenArticle: (data) => set(() => ({ openArticle: data })),
  setOpenNotification: (data) => set(() => ({ openNotification: data })),
  setNotificationCount: (data) => set(() => ({ notificationCount: data })),
  setSectionIndex: (data) => set(() => ({ sectionIndex: data})),
  setNotification: (data) => set(() => ({ notification: data})),
  setFilteredNotification: (data) => set(() => ({ filteredNotification: data})),
  setFilteredNotificationExist: (data) => set(() => ({ filteredNotificationExist: data})),
  setChanged: (data) => set(() => ({ isChanged: data})),
  setScrollIndex: (data) => set(() => ({ scrollIndex: data})),
  removeAll: () => set({ 
    loadingClickCard: false,
    openArticle: { status: false, item: null },
    sectionIndex: 0,
    notification: [],
    filteredNotification: [],
    filteredNotificationExist: false,
    isChanged: false,
    scrollIndex: 0,
    openNotification: false,
    notificationCount: 0,
  }),
}))