import { useState } from "react";
import OnboardingTooltip from "./onboardingTooltip";
import OnboardingPopover from "../onboardingTooltips/onboardingPopover";
import WhiteSearchIcon from "../../images/svg/whiteSearchIcon";

const NewSearchTooltip = (props) => {
  const [isVisible, setIsVisible] = useState(true);
  const {isWeb = false} = props
  return (
    <>
    <OnboardingTooltip
      title={
        <OnboardingPopover
          title="New Search"
          content="Create a new search with a name, company, or other information."
          icon={<WhiteSearchIcon />}
          onClick={() => {
            setIsVisible(false);
            props.onClick();
          }}
        />
      }
      isVisible={isVisible}
      placement="top"
      componentStyle={{
        marginBottom: "20px !important",
        maxWidth: isWeb ? '500px' : "90%",
        marginLeft: "5%",
        marginRight: "5%",
      }}
      arrowStyles={{ overflow: "inherit" }}
      {...props}
    >
      {props.children}
      </OnboardingTooltip>

      {isWeb &&
        <div style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          display: "flex",
          alignItems: "flex-end",
          inset: "0px auto 0px 0px",
          transform: "translate3d(0px, 0px, 0px)",
          zIndex: 8
        }} />
      }

    </>
  );
};

export default NewSearchTooltip;
