import * as React from "react"

const InfoIcon = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.143 12c0 6.154-4.989 11.143-11.143 11.143C5.846 23.143.857 18.154.857 12 .857 5.846 5.846.857 12 .857 18.154.857 23.143 5.846 23.143 12ZM12 5.857a1.857 1.857 0 1 0 0 3.714 1.857 1.857 0 0 0 0-3.714ZM13 12a1 1 0 1 0-2 0v6a1 1 0 1 0 2 0v-6Z"
      fill={props.color}
    />
  </svg>
)

export default InfoIcon
