import { Backdrop, CircularProgress } from "@mui/material";
import { useEffect, useState, useMemo } from "react";
import { useMutation } from "react-apollo";
import "../../../App.css";
import { UPDATE_PASSWORD } from "../../../queries/queries";
import Styles from "./changePassword.module.css";
import { PasswordField } from "../../../components/fields/passwordField";
import ResetPasswordSuccess from "../../../components/resetPasswordSuccess/resetPasswordSuccess";

/**
 * ProfileEditForm Component
 * @returns {JSX.Element}
 * @constructor
 */
function ProfileEditForm({ logoutUser, handleCloseClick }) {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [showSuccessView, setShowSuccessView] = useState(false);
  const [currentPasswordValid, setCurrentPasswordValid] = useState({
    status: null,
    msg: "",
  });
  const [newPasswordValid, setNewPasswordValid] = useState({
    status: null,
    msg: "",
  });
  const [confirmNewPasswordValid, setConfirmNewPasswordValid] = useState({
    status: null,
    msg: "",
  });
  const [checkFormValidation, setCheckFormValidation] = useState(false);
  const [displayError, setDisplayError] = useState("");

  const [passwordUpdate, { data, loading, error }] =
    useMutation(UPDATE_PASSWORD);

  useEffect(() => {
    if (data && !loading) {
      console.log("updateUser: ", data);
      setShowSuccessView(true);
    } else if (error && !loading) {
      if (
        error &&
        error.graphQLErrors &&
        Array.isArray(error.graphQLErrors) &&
        error.graphQLErrors.length > 0
      ) {
        setDisplayError(error.graphQLErrors[0].message);
      } else {
        setDisplayError("Unable to change your password");
      }
      setTimeout(() => {
        setDisplayError("");
      }, 5000);
    }
  }, [data, loading, error]);

  const checkPasswordMatch = () => {
    let isValid = false;
    if (newPassword.length > 0 && confirmNewPassword.length > 0) {
      if (newPassword !== confirmNewPassword) {
        setConfirmNewPasswordValid({
          status: false,
          msg: "Your new and confirm password do not match",
        });
        isValid = false;
      } else {
        setConfirmNewPasswordValid({ status: true, msg: "" });
        isValid = true;
      }
    }
    return isValid;
  };

  useMemo(() => {
    checkPasswordMatch();
  }, [confirmNewPassword]);

  const handleChangePassword = () => {
    setCheckFormValidation(true);
    let isValid = checkPasswordMatch();
    if (
      isValid &&
      currentPasswordValid.status &&
      newPasswordValid.status &&
      confirmNewPasswordValid.status
    ) {
      passwordUpdate({
        variables: {
          newPassword: newPassword,
          oldPassword: currentPassword,
        },
      });
    }
  };

  return (
    <>
      {showSuccessView ? (
        <>
          <ResetPasswordSuccess
            goToDashboard={true}
            handleCloseClick={handleCloseClick}
          />
        </>
      ) : (
        <>
          <h3 className="text-center mb-5">Change Password</h3>
          <div className={Styles.inputFieldBox}>
            <PasswordField
              title="Current Password"
              password={currentPassword}
              setPassword={setCurrentPassword}
              placeholder={"************"}
              passwordValid={currentPasswordValid}
              setPasswordValid={setCurrentPasswordValid}
              checkFormValid={checkFormValidation}
              screenName={"Change Password"}
              showPasswordIcon={true}
            />
            <div className={Styles.field}>
              <PasswordField
                title="New Password"
                password={newPassword}
                setPassword={setNewPassword}
                placeholder={"8 - 32 Characters; Mixed Case"}
                passwordValid={newPasswordValid}
                setPasswordValid={setNewPasswordValid}
                checkFormValid={checkFormValidation}
                showPasswordStrength={true}
                screenName={"Change Password"}
                showPasswordIcon={true}
              />
            </div>
            <div className={Styles.field}>
              <PasswordField
                title="Confirm New Password"
                password={confirmNewPassword}
                setPassword={setConfirmNewPassword}
                placeholder={"8 - 32 Characters; Mixed Case"}
                passwordValid={confirmNewPasswordValid}
                setPasswordValid={setConfirmNewPasswordValid}
                checkFormValid={checkFormValidation}
                screenName={"Change Password"}
                showPasswordIcon={true}
              />
            </div>
            <div className={Styles.changePasswordErrMsg}>{displayError}</div>
          </div>

          <div className="mt-4">
            <button
              className="btn btn--primary block w-full"
              onClick={handleChangePassword}
            >
              Change Password
            </button>
          </div>

          {loading && (
            <Backdrop
              sx={{
                position: "absolute",
                backgroundColor: "#00000000",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={true}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
        </>
      )}
    </>
  );
}

// Component export
export default ProfileEditForm;
