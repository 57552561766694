import * as React from "react"

const AddOrgsIcon = (props) => (

  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 0.966858C6.41775 0.966858 4.87103 1.43605 3.55544 2.3151C2.23985 3.19415 1.21447 4.44358 0.608967 5.90539C0.00346628 7.3672 -0.15496 8.97573 0.153721 10.5276C0.462403 12.0794 1.22433 13.5049 2.34315 14.6237C3.46197 15.7425 4.88743 16.5045 6.43928 16.8131C7.99113 17.1218 9.59966 16.9634 11.0615 16.3579C12.5233 15.7524 13.7727 14.727 14.6518 13.4114C15.5308 12.0958 16 10.5491 16 8.96686C15.9967 6.84612 15.1528 4.81318 13.6533 3.3136C12.1537 1.81401 10.1207 0.970113 8 0.966858V0.966858ZM11.6923 10.1976H9.84616C9.68294 10.1976 9.52642 10.2625 9.41101 10.3779C9.2956 10.4933 9.23077 10.6498 9.23077 10.813V12.6592C9.23077 12.9856 9.1011 13.2986 8.87029 13.5294C8.63947 13.7603 8.32642 13.8899 8 13.8899C7.67358 13.8899 7.36053 13.7603 7.12971 13.5294C6.8989 13.2986 6.76923 12.9856 6.76923 12.6592V10.813C6.76923 10.6498 6.7044 10.4933 6.58899 10.3779C6.47358 10.2625 6.31706 10.1976 6.15385 10.1976H4.30769C3.98127 10.1976 3.66822 10.068 3.43741 9.83714C3.2066 9.60633 3.07693 9.29328 3.07693 8.96686C3.07693 8.64044 3.2066 8.32738 3.43741 8.09657C3.66822 7.86576 3.98127 7.73609 4.30769 7.73609H6.15385C6.31706 7.73609 6.47358 7.67125 6.58899 7.55584C6.7044 7.44044 6.76923 7.28391 6.76923 7.1207V5.27455C6.76923 4.94813 6.8989 4.63508 7.12971 4.40426C7.36053 4.17345 7.67358 4.04378 8 4.04378C8.32642 4.04378 8.63947 4.17345 8.87029 4.40426C9.1011 4.63508 9.23077 4.94813 9.23077 5.27455V7.1207C9.23077 7.28391 9.2956 7.44044 9.41101 7.55584C9.52642 7.67125 9.68294 7.73609 9.84616 7.73609H11.6923C12.0187 7.73609 12.3318 7.86576 12.5626 8.09657C12.7934 8.32738 12.9231 8.64044 12.9231 8.96686C12.9231 9.29328 12.7934 9.60633 12.5626 9.83714C12.3318 10.068 12.0187 10.1976 11.6923 10.1976Z" fill="#078167" />
  </svg>

)

export default AddOrgsIcon