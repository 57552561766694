import * as React from "react"
/* SVGR has dropped some elements not supported by react-native-svg: title */

function NewPasswordSuccessLogo(props) {
  return (
    <svg
      width={288}
      height={274}
      viewBox="0 0 288 274"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g transform="translate(0 3.312)" fill="none" fillRule="evenodd">
        <path
          d="M244.193 143.475c-8.121 11.346-5.44 27.543-.746 40.446 1.572 4.32 3.358 8.889 2.543 13.588-.996 5.745-5.676 10.05-10.54 12.395-8.869 4.273-19.345 3.59-27.277-1.794-6.846-4.646-11.665-12.369-18.643-16.794-11.68-7.403-26.841-4.095-39.84 1.557-9.196 3.998-19.27 9.148-28.23 5.456-6.307-2.598-10.553-9.212-12.787-16.281-1.08-3.413-1.834-7.118-3.983-9.79-1.278-1.589-2.967-2.695-4.703-3.62-15.847-8.45-36.23-3.169-51.593-12.596-10.373-6.366-16.828-18.72-19.51-31.708-2.683-12.988-2.019-26.698-.95-40.174.759-9.577 1.96-19.81 7.615-27.337 5.98-7.956 16.004-11.118 24.968-9.872 8.963 1.246 17 6.315 24.03 12.367 8.793 7.549 16.884 17.026 27.82 19.893 7.45 1.951 15.363.57 23.107-.04 12.945-1.018 25.844.154 38.664 1.478 12.272 1.267 24.63 2.683 36.252 6.837 8.226 2.94 14.745 8.498 22.49 12.296 5.046 2.475 10.6 3.05 15.35 6.247 5.863 3.94 11.16 10.918 9.44 19.293-1.623 7.961-9.162 12.13-13.477 18.153z"
          fill="#00A585"
          fillRule="nonzero"
          opacity={0.1}
        />
        <ellipse
          fill="#48D2A0"
          opacity={0.162}
          cx={243.402}
          cy={35.793}
          rx={5.106}
          ry={5.105}
        />
        <circle
          fill="#48D2A0"
          opacity={0.17}
          cx={227.063}
          cy={60.297}
          r={3.063}
        />
        <circle
          fill="#48D2A0"
          opacity={0.301}
          cx={253.954}
          cy={78.334}
          r={4.765}
        />
        <circle
          fill="#48D2A0"
          opacity={0.184}
          cx={4.765}
          cy={44.536}
          r={4.765}
        />
        <circle
          fill="#48D2A0"
          opacity={0.15}
          cx={14.063}
          cy={79.555}
          r={3.063}
        />
        <g
          opacity={0.364}
          stroke="#48D2A0"
          strokeLinecap="round"
          strokeWidth={7}
        >
          <path d="M64.572 16.798l-6.72-11.637M55.394 14.34L67.03 7.62" />
        </g>
        <g
          opacity={0.464}
          stroke="#48D2A0"
          strokeLinecap="round"
          strokeWidth={6}
        >
          <path d="M119.817 236.154l12.935 7.468M130.018 233.42l-7.468 12.935" />
        </g>
        <ellipse
          fill="#48D2A0"
          opacity={0.224}
          cx={85.106}
          cy={264.08}
          rx={5.106}
          ry={5.105}
        />
        <circle
          fill="#48D2A0"
          opacity={0.135}
          cx={42.063}
          cy={236.751}
          r={3.063}
        />
        <g
          opacity={0.391}
          stroke="#48D2A0"
          strokeLinecap="round"
          strokeWidth={6}
        >
          <path d="M273.047 158.021l9.472-2.538M276.52 152.02l2.539 9.471" />
        </g>
        <g transform="translate(84 73.688)">
          <circle fill="#11987B" cx={58.5} cy={58.5} r={58.5} />
          <path
            d="M89.222 35.603a6.054 6.054 0 00-8.62 0L48.032 68.627 35.821 56.243a5.717 5.717 0 00-8.142 0c-2.239 2.27-2.239 5.986 0 8.256l16.763 16.998a5.717 5.717 0 008.142 0c.04-.04.075-.086.113-.127.042-.04.086-.075.126-.115l36.4-36.91c2.37-2.404 2.37-6.338 0-8.742"
            fill="#FFF"
          />
        </g>
      </g>
    </svg>
  )
}

export default NewPasswordSuccessLogo
