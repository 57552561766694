
const { windowWidth, windowHeight } = window

const isEqualOrHigherThanIphoneX = windowHeight > 800;

const createStyle = () => {
  const textColor = '#1A233B';
  return {
    modal: {
      // marginTop: 50,
      marginBottom: 0,
      marginHorizontal: 0,
    },
    parentContainer: {
      width: "100%",
      height: "100%",
      overflow: "hidden",
      position: "absolute",
      // borderTopRightRadius: 12,
      // borderTopLeftRadius: 12,
      padding: 0,
      backgroundColor: '#FFF',
    },
    container: {
      height: "85%",
      overflowY: "scroll",
      overflowX: "hidden",
      position: "relative"
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: 16,
      borderBottom: "1px solid #EFF1F5",
      paddingBottom: 10,
      paddingTop: 10,
    },
    body: {
      marginLeft: 16,
      marginRight: 16,
      alignSelf: 'center',
      paddingTop: "10px"
    },
    headerSideBtn: {
      color: '#078167',
      fontFamily: 'Proxima Nova',
      fontWeight: '400',
      fontSize: 14,
    },
    headerMiddleBtn: {
      color: textColor,
      fontFamily: 'Proxima Nova',
      fontWeight: '700',
      fontSize: 18,
    },
    filterTextHeaders: {
      color: textColor,
      fontFamily: 'Proxima Nova',
      fontWeight: '700',
      fontSize: 14,
      paddingTop: 10,
      color: '#142030',
      paddingBottom: 10,
      display: 'flex',
    },
    filterTexts: {
      color: textColor,
      fontFamily: 'Proxima Nova',
      fontWeight: '400',
      fontSize: 15,
    },
    filterRow: {
      paddingBottom: 20,
      borderBottomWidth: 1,
      borderColor: '#F1F1F1',
    },
    label: {
      fontFamily: 'Proxima Nova',
      fontWeight: '400',
      color: textColor,
      fontSize: 16,
    },
    applyBtnDiv: {
      marginTop: 10,
      marginLeft: 16,
      marginRight: 16,
    },
    applyBtn: {
      alignSelf: 'center',
      display: "flex"
    },
    showMore: {
      marginTop: 10,
      fontFamily: 'Proxima Nova',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: 14,
      letterSpacing: -0.01,
      color: '#078167'
    },
    showLess: {
      marginTop: 10,
      fontFamily: 'Proxima Nova',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: 14,
      letterSpacing: -0.01,
      color: '#697080'
    },
    headerTitleView: {
      flex: 1,
      display: "flex",
      justifyContent: "center"
    },
    cleartext: { fontWeight: "bold", color: '#142030' },
    searchTags: {
      color: '#142030',
      fontFamily: 'Proxima Nova',
      fontSize: 16,
      fontWeight: '400',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'row',
      paddingVertical: 8,
      paddingStart: 34,
      paddingEnd: 8,
      marginBottom: 12,
      marginTop: -4,
    },
    titleGroup: {
      color: '#142030',
      fontFamily: 'Proxima Nova',
      fontSize: 14,
      fontWeight: '700',
      // marginTop: 20,
      textAlign: 'center'
    },
    titleGroupDark: {
      color: '#142030',
      fontFamily: 'Proxima Nova',
      fontSize: 14,
      fontWeight: '700',
      marginBottom: 0,
      textAlign: 'center'
    },
  };
};

export default createStyle;
