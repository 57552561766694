// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".alertPopup_container__HzY30 {\n    position: absolute;\n    bottom: 0px;\n    width: -webkit-fill-available;\n    margin-left: 20px;\n    margin-right: 20px;\n    background-color: rgb(255, 255, 255);\n    border-top-right-radius: 12px;\n    border-top-left-radius: 12px;\n    margin-bottom: 0px;\n    padding: 0px;\n    max-width: 650px;\n}\n.alertPopup_containerMain__KmT5s {\n    background-color: rgba(84, 83, 83, 0.2);\n    /* position: absolute; */\n    bottom: 0px;\n    top: 0px;\n    width: 100%;\n    transform: none;\n    transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;\n    display: flex;\n    justify-content: center;\n}", "",{"version":3,"sources":["webpack://./src/components/AlertPopup/alertPopup.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,6BAA6B;IAC7B,iBAAiB;IACjB,kBAAkB;IAClB,oCAAoC;IACpC,6BAA6B;IAC7B,4BAA4B;IAC5B,kBAAkB;IAClB,YAAY;IACZ,gBAAgB;AACpB;AACA;IACI,uCAAuC;IACvC,wBAAwB;IACxB,WAAW;IACX,QAAQ;IACR,WAAW;IACX,eAAe;IACf,0DAA0D;IAC1D,aAAa;IACb,uBAAuB;AAC3B","sourcesContent":[".container {\n    position: absolute;\n    bottom: 0px;\n    width: -webkit-fill-available;\n    margin-left: 20px;\n    margin-right: 20px;\n    background-color: rgb(255, 255, 255);\n    border-top-right-radius: 12px;\n    border-top-left-radius: 12px;\n    margin-bottom: 0px;\n    padding: 0px;\n    max-width: 650px;\n}\n.containerMain {\n    background-color: rgba(84, 83, 83, 0.2);\n    /* position: absolute; */\n    bottom: 0px;\n    top: 0px;\n    width: 100%;\n    transform: none;\n    transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;\n    display: flex;\n    justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "alertPopup_container__HzY30",
	"containerMain": "alertPopup_containerMain__KmT5s"
};
export default ___CSS_LOADER_EXPORT___;
