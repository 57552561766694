import { create } from 'zustand'

export const closestContactsStore = create((set) => ({
  totalImportCount: 0,
  isContactImported: false,
  contactList: false,
  categories: [],
  potentialModalVisible: false,
  potentialData: [],
  contactID: undefined,
  articlePosition: 0,
  searchValue: undefined,
  setTotalImportCount: (data) => set(() => ({ totalImportCount: data})),
  setContactImported: (data) => set(() => ({ isContactImported: data})),
  setContactList: (data) => set(() => ({ contactList: data })),
  setCategories: (data) => set(() => ({ categories: data })),
  setPotentialModalVisible: (data) => set(() => ({ potentialModalVisible: data})),
  setPotentialData: (data) => set(() => ({ potentialData: data})),
  setContactID: (data) => set(() => ({ contactID: data})),
  setArticlePosition: (data) => set(() => ({ articlePosition: data})),
  setSearch: (data) => set(() => ({ searchValue: data })),
  removeAll: () => set({
    totalImportCount: 0,
    isContactImported: false,
    contactList: false,
    categories: false,
    potentialModalVisible: false,
    potentialData: [],
    contactID: undefined,
    articlePosition: 0,
    searchValue: undefined,
  }),
}))