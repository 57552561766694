import React from 'react';
import Styles from './personeInfo.module.css'

const NoPersoneInfo = () => {
  return (
    <div className={Styles.borderContainer} style={{ paddingLeft: 16, paddingRight: 16 }}>
      <span className={Styles.textNoInfo}>Details Not available</span>
      <span className={Styles.textNoInfoDetail}>Additional details will appear here when available.</span>
    </div>
  )
}
export default NoPersoneInfo