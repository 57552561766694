import React, { useContext, useEffect, useState } from 'react';
import UserAvatar from '../../images/svg/user-Avatar';
import { extractRootDomain } from '../../utils/getHostName';
import { capitalizeCase, capitalizeSearchTermsTag } from '../../utils/utils';
// import RelevacyNoTooltip from '../onboardingTooltips/relevacyNoTooltip/relevacyNoTooltip';
import { UserContext } from "../../contexts/userContext";
import ExclamationMarkGrey from '../../images/svg/exclamation-mark-grey';
import QuestionMarkGrey from '../../images/svg/question-mark-grey';
import TickGreen from '../../images/svg/tick-green';
import FilterHeaderTooltip from '../onboardingTooltips/filterHeaderTooltip';
import Styles from './articleCard.module.css';
import {
  GET_FAVICON,
} from '../../queries/queries';
import { useLazyQuery } from 'react-apollo';
import webPlaceholder from "../../images/svg/web-placeholder.svg";
import _ from "lodash";

const ArticleCard = (props) => {
  const { article, onPress, isShowName = true, enableRelevanceModal,
    displayPostArticleRelevanceBot, setRelevantArticle, openFilter, dontShow, filterAttributes, isRecent, isMap, isWeb, height } = props
  const isTracers = article.tracers;

  const [alertsOnboard, setAlertsOnboard] = useState(false)
  const [icon, setIcon] = useState(false)
  const { user, setUser } = useContext(UserContext);

  const [articleSubCategory, setArticleSubCategory] = useState([]);
  const [openSubCategory, setOpenSubCategory] = useState(false);

  const [getFavicon, { loading, data, error }] = useLazyQuery(GET_FAVICON, {
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (!loading && data && data.getFavIcon?.icon) {
      setIcon("data:image/png;base64," + data.getFavIcon?.icon);
      article.icon = "data:image/png;base64," + data.getFavIcon?.icon
    } else if (!loading && data && !data.getFavIcon?.icon) {
      setIcon(webPlaceholder);
      article.icon = webPlaceholder
    }
  }, [loading, data, error]);

  const getIcon = () => {
    if (article?.pageURL && article?.pageURL.includes("http") && !icon && !article.icon) {
      let parsedUrl = new URL(article.pageURL);
      let baseUrl = parsedUrl.origin;
      if (baseUrl) {
        getFavicon({
          variables: {
            iconURL: baseUrl,
          },
        });
      }
    } else if (article?.icon) {
      setIcon(article.icon);
    } else if (article && !article?.pageURL) {
      setIcon(webPlaceholder);
    }
  };
  const debouncedGetIcon = _.debounce(getIcon, 400);

  useEffect(() => {
    debouncedGetIcon();

    if (article.subCategory) {
      const transformedData = article.subCategory.flatMap(entry => {
        return entry.document.map(documentEntry => {
          const concatenatedTitle = entry.title
            ? `${entry.title}-${documentEntry.title}`
            : documentEntry.title;

          return {
            title: concatenatedTitle,
            documentNumber: concatenatedTitle?.match(/document\D*(\d+)/)?.[1],
            url: documentEntry.details.find(item => item.key === "url").value,
            details: documentEntry.details
          };
        });
      });

      const sortedData = transformedData.sort((a, b) => {
        return a.documentNumber - b.documentNumber;
      });

      setArticleSubCategory(sortedData)
    }

  }, [article]);


  if (!article) { return <></> }

  const RenderAttributes = ({ attribute, style }) => {
    if (!attribute?.value?.[0]) return <></>
    let attributearr = attribute?.value.map(a => a.toLowerCase())
    let tempArr = [];
    if (filterAttributes?.length > 0) tempArr = filterAttributes?.filter(x => attributearr.includes(x.toLowerCase()));
    if (!tempArr.length > 0 || tempArr?.[0] == '') {
      return (
        <div className={Styles.attributeItem} style={isMap ? { fontSize: 12 } : {}}>
          {capitalizeSearchTermsTag(attribute?.value?.[0], null, true)}
        </div>
      );
    }
    return (
      <div className={Styles.attributeItem} >
        {capitalizeSearchTermsTag(tempArr?.[0], null, true)}
      </div>
    );
  };

  const dontShowView = () => {
    setAlertsOnboard(false)
    dontShow?.()
    setRelevantArticle(article, "NO", article?.potentialMatchId)
    openFilter?.(false)
  }
  const skip = (no) => {
    if (no && !user.relevancySortFilterTooltip) {
      setAlertsOnboard(true)
    } else {
      setAlertsOnboard(false)
      setRelevantArticle(article, "NO", article?.potentialMatchId)
    }
    openFilter?.(false)
  }
  const openFilterSort = () => {
    setAlertsOnboard(false)
    setRelevantArticle(article, "NO", article?.potentialMatchId)
    openFilter?.(true)
  }

  let articleSrc;
  if (article?.pageURL) articleSrc = extractRootDomain(article.pageURL);

  //manually setting Favicon and souce name for Court records
  if ((article?.category === 'Court Records' && article?.riskCode === 'cl-opinion') ||
    (article?.category === 'CL RECAP')) {
    articleSrc = { srcName: "courtlistener", domain: 'www.courtlistener.com' }
  }


  let sourceName = articleSrc?.srcName || ''
  let isHyperLink = false;
  if (articleSrc?.srcName.toLowerCase() === 'crunchbase') {
    sourceName = `Powered by ${articleSrc?.srcName}`
    isHyperLink = true
  }

  const onClickCard = () => {
    if (article && (article.subCategory == null || article.subCategory == undefined || article.subCategory.length === 0)) {
      onPress?.(article)
    }
  }

  const getSelectedData = (item) => {
    let data = {}

    item?.details.forEach((item) => {
      data[item.key] = item.value
    })

    return { ...item, details: data }
  };

  return (
    <div onClick={onClickCard} disabled={!(article && (article.subCategory == undefined || article.subCategory.length === 0))}>
      <div className={Styles.searchContainer}
        style={isMap ? {
          backgroundColor: '#25262c',
          marginLeft: 10,
          marginTop: 10,
          borderRadius: 8,
          width: isWeb ? 200 : 140,
          height: height,
          borderLeftStyle: 'solid',
          borderLeftWidth: 2,
          overflowY: 'auto',
          padding: 8,
          overflowX: 'hidden',
          borderLeftColor: article?.verificationType == 'verified' ? '#91CB6C' : '#697080',
        } : {}}>
        <div className={Styles.searchTitleContainer}>
          {/* <View style={markerStyle} /> */}
          <span>
            {/* {isShowName && <span className={Styles.searchTitle}>{article.fullName + ' | '}</span>}
            <span className={Styles.articleCategoryType}>{article?.parentCategory?.toUpperCase() ?? article?.category?.toUpperCase()}</span> */}
            {/* <span className={Styles.matchType}>{article.matchType}</span> */}
          </span>
          <VerificationTypeView verificationType={article?.verificationType} className={Styles} />
        </div>
        {isRecent
          ? isTracers &&
          article?.category &&
          article?.category?.length > 0 && (
            <span className={Styles.articleTracersTitle}>
              {article.category}
            </span>
          )
          : isTracers &&
          article?.articleType &&
          article?.articleType?.length > 0 && (
            <span
              className={Styles.articleTracersTitle}
              style={
                article.title?.length > 0
                  ? {}
                  : { color: article.isRead && !isMap ? "#8E96A8" : isMap ? "#FFF"  :"#142030" }
              }
            >
              {article.articleType}
            </span>
          )}

        {article?.category == "Unicourt" &&
          <span 
          className={Styles.articleTracersTitle}
          >
            {article.category}
          </span>
        }
        {article.title?.length > 0 && (
          <span className={Styles.articleTitle} style={{ color: article.isRead && !isMap ? "#8E96A8" : isMap ? '#fff' : "#142030", fontSize: isMap ? 14 : 16 }}>
            {article.title}
          </span>
        )}
        {articleSubCategory && articleSubCategory.length > 0 ? (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                paddingBottom: 14
              }}
              onClick={() => setOpenSubCategory(!openSubCategory)}
            >
              <span className={Styles.selectDocument} style={isMap ? { color: '#fff' } : {}}>
                Select Document
              </span>
              <img
                style={
                  openSubCategory
                    ? { transform: `rotate(180deg)`, width: 30, height: 30 }
                    : { width: 30, height: 30 }
                }
                src={require("../../images/filter-close.png")}
              />
            </div>

            {openSubCategory &&
              <div className={Styles.subCategoryView}>
                {articleSubCategory.map((item) => {
                  return (
                    <div
                      className={Styles.subCategoryBtn}
                      onClick={() => onPress?.(article, getSelectedData(item))}
                    >
                      <span className={Styles.documentTitle}>
                        {item.title}
                      </span>
                      {/* <Icon name="chevron-right" size={16} color="#8E96A8" className={Styles.subCategoryBtnIcon} /> */}
                    </div>
                  );
                })}
              </div>
            }
          </>
        ) : null}

        <div>
          {!isTracers && articleSrc?.srcName ? (
            <div className={Styles.articleSource}>
              {icon && <img
                src={icon}
                className={Styles.articleSrcImg}
              />}
              <span
                onClick={(e) => {
                  e.stopPropagation()

                  if (isHyperLink)
                    window.location.href = 'http://www.crunchbase.com'
                }
                }
                className={Styles.articleCategory}
                style={isMap ? { color: '#fff' } : {}}
                numberOfLines={1}>
                {sourceName}
              </span>
            </div>
          ) : !isTracers && (
            <span className={Styles.articleCategoryType}>
              {article?.parentCategory ?? article.category}
            </span>
          )}
          {article.matchedAttributes?.length > 0 ? (
            <div className={Styles.attributes}>
              {article.matchedAttributes.map((a) => (
                <RenderAttributes attribute={a} className={Styles} key={a.key} />
              ))}
            </div>
          ) : (
            <div className={Styles.attributes}>
              {article?.attributes?.map((a) => (
                <RenderAttributes attribute={a} className={Styles} key={a.key} />
              ))}
            </div>
          )
          }
        </div>
        {!isMap && displayPostArticleRelevanceBot &&
          <>
            <div className={Styles.aboutMainView}>
              <UserAvatar size={isMap ? 40 : 16} />
              <span className={Styles.aboutYour} style={isMap ? { color: '#fff' } : {}}>{`Is this about your ${capitalizeCase(article.fullName, true)}?`}</span>
            </div>
            <div className={Styles.aboutMainView2}>
              <div className={Styles.btnView} onClick={(e) => {
                e.stopPropagation();
                enableRelevanceModal();
              }}>
                <span className={Styles.btnText}>Yes</span>
              </div>
              <div className={Styles.btnView} onClick={(e) => {
                e.stopPropagation();
                skip(true)
              }}>
                <div className={Styles.btnText}>No</div>
              </div>
              {alertsOnboard && <FilterHeaderTooltip
                onPress={skip}
                isFilter={true}
                dontShow={dontShowView}
                openFilter={openFilterSort}
                placement={isMap ? "top" : undefined}
              ></FilterHeaderTooltip>}
            </div>
          </>}

        {article.terms?.length > 0 && (
          <div className={Styles.termsSection}>
            <Tags terms={[article.terms]} className={Styles} />
          </div>
        )}

        {(!article.terms || article.terms?.length === 0) && (
          <div style={{ paddingBottom: 10 }} />
        )}
      </div>
    </div>
  );
}

// Change the atom "Tag" when data allows it
const Tags = (props) => {
  const { terms, style } = props
  return terms?.map((item) => {
    return (
      <span className={Styles.termText}>{item}</span>
    )
  })
}

const VerificationTypeView = ({ verificationType, textColor }) => {

  if (verificationType === 'partial')
    return (
      <span className={Styles.verificationType} style={{ color: textColor ?? "#697080" }}>
        <QuestionMarkGrey />
        <span style={{ paddingLeft: 5, paddingRight: 5 }}>Partial</span>
      </span>
    );
  else if (verificationType === 'needs_review')
    return (
      <span className={Styles.verificationType} style={{ color: textColor ?? "#697080" }}>
        <div style={{ marginRight: 4 }}>
          <ExclamationMarkGrey />
        </div>
        Needs Review
      </span>
    );
  else if (verificationType === 'verified')
    return (
      <span className={Styles.verificationType} style={{ color: '#91CB6C' }}>
        <div style={{ marginRight: 4 }}>
          <TickGreen />
        </div>
        Verified
      </span>
    );
  else return <></>;
};

export default ArticleCard
export { VerificationTypeView };

