import * as React from "react"

const SavedSearchesWhiteIcon = (props) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.887 2.213 7.597.277A.624.624 0 0 0 7.077 0H1.543a.643.643 0 0 0-.134.014C.921.121 0 .698 0 2.12V14.08C0 15.22.64 16 1.92 16h12.16c.77 0 1.92-.394 1.92-1.92V4.469c0-1.656-1.173-2.256-1.96-2.256H8.887Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.345 8.203a2.064 2.064 0 0 1-2.061 2.062 2.064 2.064 0 0 1-2.062-2.062c0-1.136.925-2.061 2.062-2.061 1.136 0 2.06.925 2.06 2.061m.45 1.788a3.09 3.09 0 0 0 .573-1.788c0-1.7-1.383-3.083-3.083-3.083A3.087 3.087 0 0 0 3.2 8.203c0 1.7 1.383 3.084 3.084 3.084a3.09 3.09 0 0 0 1.787-.574l1.937 1.937a.507.507 0 0 0 .722 0 .508.508 0 0 0 0-.722L8.793 9.99Z"
      fill="#5391F7"
    />
  </svg>
)

export default SavedSearchesWhiteIcon
