import {useState} from 'react';
import Variables from './Variables';
import {savedSearchesStore} from '../store/savedSearchesStore'

const filterLocal = [
  {
      "category": "Adverse Media",
      "lable": "Adverse Media",
      "count": 0,
      "status": false
  },
  {
    "category": "Potential Criminal Records",
    "lable": "Potential Criminal Records",
    "count": 0,
    "status": false
},
{
    "category": "Debts",
    "lable": "Debts",
    "count": 0,
    "status": false
},
{
    "category": "Political Exposure",
    "lable": "Political Exposure",
    "count": 0,
    "status": false
},
{
    "category": "Watchlists",
    "lable": "Watchlists",
    "count": 0,
    "status": false
},
{
    "category": "State Owned Enterprise",
    "lable": "State Owned Ent.",
    "count": 0,
    "status": false
},
{
    "category": "Papers and Leaks",
    "lable": "Papers & Leaks",
    "count": 0,
    "status": false
},
{
    "category": "Positive News",
    "lable": "Positive News",
    "count": 0,
    "status": false
},
{
    "category": "Corporate Records",
    "lable": "Corporate Records",
    "count": 0,
    "status": false
},
{
    "category": "Court Records",
    "lable": "Court Records",
    "count": 0,
    "status": false
},
// {
//     "category": "Crunchbase",
//     "lable": "Crunchbase",
//     "count": 0,
//     "status": false
// },
{
  "category": "Court Decisions",
  "lable": "Court Decisions",
    "count": 0,
    "status": false
},
{
    "category": "Assets",
    "lable": "Assets",
    "count": 0,
    "status": false
  },
  {
    "category": "MetaSearch",
    "lable": "MetaSearch",
    "count": 0,
    "status": false
  },
  {
    "category": "Property",
    "lable": "Property",
    "count": 0,
    "status": false
  }
]

const LocalFilterList = ({ categories }) => { 

  const filterList = savedSearchesStore((state) => state.filterList)
  const setFilterList = savedSearchesStore((state) => state.setFilterList)
  let availableFilterScoreWise = []

  if(categories && categories.length > 0){
    availableFilterScoreWise = filterLocal?.map(item => {
      let matchingResult = categories.filter(cat => item.category == cat.title)
      return matchingResult[0] ? { ...item, score: matchingResult[0].score } : item
    }).sort(
      (a, b) => b.score - a.score
    )
  
    if (filterList == undefined || filterList.length == 0) {
      setFilterList(availableFilterScoreWise)
    }
  }

  const updateAvailableFilterScoreWise = (categories) => {
    if(categories && categories.length > 0){
      availableFilterScoreWise = filterLocal?.map(item => {
        let matchingResult = categories.filter(cat => item.category == cat.title)
        return matchingResult[0] ? { ...item, score: matchingResult[0].score } : item
      }).sort(
        (a, b) => b.score - a.score
      )
      setFilterList(availableFilterScoreWise)
    }
  }

  const getCurrentFilter = () => {
    return filterList;
  }

  const clearFiler = () => {
    return setFilterList(availableFilterScoreWise.map((item) => { return ({ ...item, status: false }) }));
  }

  const selectAll = () => {
    return setFilterList(availableFilterScoreWise.map((item) => { return ({ ...item, status: true }) }));
  }

  const onFilterSelection = (type, status) => {
    let mFilter = filterList.map((item,index)=> {
      return{...item, status: item.category == type ? status : item.status}
    })
    return setFilterList(mFilter);
  }
  const onFilterCountUpdate = (availableFilterType) => {
    setFilterList(filterList.map((item,index)=> {
      return{...item,
        count: availableFilterType.includes(item.category) ?  1 : 0,
        status: item.status
      }
    }));
  }

  const onFilterCountUpdateWeb = (availableFilterType) => {
    setFilterList(filterList.map((item,index)=> {
      return{...item,
        count: availableFilterType.includes(item.title) ?  1 : 0,
        status: item.status
      }
    }));
  }

  return{
    filterList,
    getCurrentFilter,
    clearFiler,
    selectAll,
    onFilterSelection,
    onFilterCountUpdate,
    updateAvailableFilterScoreWise,
    onFilterCountUpdateWeb,
  }
}

export default LocalFilterList