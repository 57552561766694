import React, { useState } from 'react';
import { saveToken, saveRefreshToken } from './utils/safe-storage';
import SingleBtnAlert from './components/alert/singleBtnAlert';

// import { import { useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";

// const navigationRef = useNavigate();


const userInfo = {
  id: '',
  givenName: '',
  familyName: '',
  profilePictureUri: null,
  email: '',
  isOnboarded: false,
  notificationOnboardingDone: false,
  newsOnboardingDone: false,
  neverShowNotifications: false,
  advancedSearchOnboardingDone: false,
  userPreference: null,
  userConsentDeviceContacts: false,
  userConsentGoogleContacts: false,
  hasContacts: false,
  subscriptionId: '',
  isInIntroPeriod: false,
  isInTrialPeriod: false,
  isSubscribed: undefined,
  hasLimitedAccess: false,
  itemId: '',
  itemName: '',
  hasExpired: false,
  daysLeftToExpire: 0,
  expirationDateMs: '',
  trialEndDate: '',
  isPremiumMember: false,
  selfSearchData: null,
  monitoredContactCount: '',
  isDisclaimerAccepted: false,
  currentMonthAdhocSearchCount: 0,
  adhocSearchLimitPerMonth: 0,
  monthlyPrice: 0,
  currentSubsPlan: null,
  contactImportFlagActive: false,
  paymentMethod: [],
  willAutoRenew: false,
  isCancelled: false,
  platform: "",
  recentFilterTooltips: false,
  filterTooltips: false
};

function navigate(name, params) {
  navigate(name, params);
}

function resetNavigationTo(screenName, params) {
  if (typeof screenName != 'string' || screenName.length == 0) {
    return;
  }
  // navigationRef?.current?.dispatch(
  //   // CommonActions.reset({
  //   //   index: 0,
  //   //   routes: [
  //   //     {
  //   //       name: screenName,
  //   //       params: params,
  //   //     },
  //   //   ],
  //   // }),
  // );
}

function setUserSearchMe(selfSearchData) {
  userInfo.selfSearchData = selfSearchData;
  return userInfo;
}
function setUserInfo(newUserInfo) {
  const {
    id = '',
    name = '',
    lastName = '',
    profilePhoto,
    email = '',
    isOnboarded = false,
    notificationOnboardingDone = false,
    newsOnboardingDone = false,
    neverShowNotifications = undefined,
    advancedSearchOnboardingDone = false,
    advancedSearchOnboardingLocal = false,
    userPreference = null,
    userConsentDeviceContacts = false,
    userConsentGoogleContacts = false,
    hasContacts = false,
    userType = '',
    suscriptionPlan = '',
    subscriptionDetails = {},
    selfSearchData,
    syncToken,
    relevancySortFilterTooltip = false,
    adhocSortFilterTooltip = false,
    monitoredContactCount = '',
    disclaimerData = {},
    upgradeSubsriptionPlan = {},
    contactImportFlagActive = false,  
    recentFilterTooltips = false,
    filterTooltips = false,
  } = newUserInfo;

  userInfo.id = id;
  userInfo.givenName = name;
  userInfo.familyName = lastName;
  userInfo.profilePhoto = profilePhoto;
  userInfo.email = email;
  userInfo.isOnboarded = isOnboarded;
  userInfo.notificationOnboardingDone = notificationOnboardingDone;
  userInfo.newsOnboardingDone = newsOnboardingDone;
  userInfo.neverShowNotifications = neverShowNotifications;
  userInfo.advancedSearchOnboardingDone = advancedSearchOnboardingDone;
  userInfo.advancedSearchOnboardingLocal = advancedSearchOnboardingLocal;
  userInfo.userPreference = userPreference;
  userInfo.userConsentDeviceContacts = userConsentDeviceContacts;
  userInfo.userConsentGoogleContacts = userConsentGoogleContacts;
  userInfo.hasContacts = hasContacts;
  userInfo.suscriptionPlan = suscriptionPlan;
  userInfo.userType = userType;
  userInfo.syncToken = syncToken;
  userInfo.recentFilterTooltips = recentFilterTooltips
  userInfo.filterTooltips = filterTooltips
  userInfo.selfSearchData = selfSearchData;
  userInfo.relevancySortFilterTooltip = relevancySortFilterTooltip
  userInfo.contactImportFlagActive = contactImportFlagActive
  userInfo.adhocSortFilterTooltip = adhocSortFilterTooltip
  userInfo.monitoredContactCount = monitoredContactCount
  if (subscriptionDetails != null) {
    userInfo.isCancelled = subscriptionDetails.isCancelled;
    userInfo.willAutoRenew = subscriptionDetails.willAutoRenew;
    userInfo.paymentMethod = subscriptionDetails.paymentMethods;
    userInfo.subscriptionId = subscriptionDetails.subscriptionId;
    userInfo.isInIntroPeriod =
      subscriptionDetails.isInIntroPeriod != null
        ? subscriptionDetails.isInIntroPeriod
        : false;
    userInfo.isInTrialPeriod =
      subscriptionDetails.isInTrialPeriod != null
        ? subscriptionDetails.isInTrialPeriod
        : false;
    userInfo.isSubscribed =
      subscriptionDetails.isSubscribed != null
        ? subscriptionDetails.isSubscribed
        : false;
    userInfo.hasLimitedAccess = subscriptionDetails.hasLimitedAccess;
    userInfo.itemId =
      subscriptionDetails.itemId != null ? subscriptionDetails.itemId : '';
    userInfo.platform =
      subscriptionDetails.platform != null ? subscriptionDetails.platform : '';
    userInfo.itemName = subscriptionDetails.itemName;
    userInfo.daysLeftToExpire =
      subscriptionDetails.daysLeftToExpire != null
        ? subscriptionDetails.daysLeftToExpire
        : 0;
    userInfo.hasExpired =
      subscriptionDetails.hasExpired != null
        ? subscriptionDetails.hasExpired
        : false;
    userInfo.trialEndDate = subscriptionDetails.trialEndDate;
    userInfo.expirationDateMs = subscriptionDetails.expirationDateMs;
    userInfo.isPremiumMember = userInfo.hasExpired
      ? false
      : userInfo.itemId == process.env.REACT_APP_SUBSCRIPTION_PREMIUM;
    userInfo.currentMonthAdhocSearchCount = subscriptionDetails.currentMonthAdhocSearchCount;
    userInfo.adhocSearchLimitPerMonth = subscriptionDetails.subscriptionPlanDbId != null ?
      parseInt(subscriptionDetails?.subscriptionPlanDbId?.adhocSearchLimitPerMonth) + 1 : 0;
    userInfo.currentSubsPlan = subscriptionDetails.subscriptionPlanDbId
  }
  if (disclaimerData != null) {
    userInfo.isDisclaimerAccepted = disclaimerData.isDisclaimerAccepted != null
      ? disclaimerData.isDisclaimerAccepted
      : false
  }
  if (upgradeSubsriptionPlan != null && upgradeSubsriptionPlan.monthlyPrice) {
    userInfo.monthlyPrice = upgradeSubsriptionPlan.monthlyPrice
  }
  return userInfo
}

async function saveUserDataAndRedirectToInitialScreen(
  userData,
  accessTokenSuccessCallback,
  accessTokenFailureCallback,
  modeCheck,
  variables,
  setUser,
  navigate,
  setAccountExist,
  isWeb
) {
  const { accessToken, id, refreshToken, isNewUser, userType, ...newUserInfo } = userData;
  // Bugsnag.leaveBreadcrumb(
  //   `Token: ${accessToken}`,
  //   {
  //     method: 'saveUserDataAndRedirectToInitialScreen',
  //     userInfo: JSON.stringify(newUserInfo),
  //   },
  //   'log',
  // );
  if (!accessToken) {

    navigate('/emailOtpVerify', {
      state: {
        variables,
        id
      }
    })
    accessTokenFailureCallback?.();
    return;
  }


  if (
    !isNewUser &&
    (userType == 'google' || userType == 'apple') &&
    modeCheck != 'login'
  ) {
    setAccountExist?.({ status: true, msg: "Account already exists for this email" });
    return;
  }

  saveToken(accessToken);
  saveRefreshToken(refreshToken);
  let u = setUserInfo(newUserInfo);

  if (setUser) setUser({ ...u });
  accessTokenSuccessCallback?.();
  if (modeCheck == 'login') {
    // resetNavigationTo('LaunchScreenTransition', {
    //   previousScreen: 'ImportContactsLanding',
    // });
    navigate("/dashboard", {state: {showSplash: true}}, { replace: true })
  } else {
    // Set the user info for identify the logs
    // AppEventsLogger.setUserData({
    //   email: u?.email,
    //   firstName: u?.givenName,
    //   lastName: u?.familyName
    // })
    // Notify Register success for all type of login user
    // AppEventsLogger.logEvent(AppEventsLogger.AppEvents.CompletedRegistration, {
    //   [AppEventsLogger.AppEventParams.RegistrationMethod]: 'email',
    // });

    if (!userData?.userPreference) {
      const data = {
        showVersionA: userData?.showVersionA,
        modeCheck: modeCheck,
      }
      navigate('/preferences', { replace: true, state: JSON.stringify(data) });
    } else {
      if (userData?.showVersionA) {
        const data = {
          modeCheck: modeCheck,
        }
        navigate("/importContactsLanding", {state: JSON.stringify(data)})
      }else{
        navigate("/dashboard", {state: {showSplash: true}},  { replace: true })
      }
    }
  }
}

function authErrorHandling(error) {
  if (
    error &&
    error.graphQLErrors &&
    Array.isArray(error.graphQLErrors) &&
    error.graphQLErrors.length > 0
  ) {
    // Alert.alert(
    //   '',
    //   error.graphQLErrors[0].message,
    //   [
    //     {
    //       text: 'Cancel',
    //       onPress: () => {
    //         GoogleSignin?.signOut()
    //       }
    //     },
    //     {
    //       text: 'Sign Up',
    //       onPress: () => {
    //         GoogleSignin?.signOut()
    //         navigate('AcceptTermsOfService');
    //       },
    //     },
    //   ],
    //   { cancelable: false },
    // );
  }
}

export {
  navigate,
  resetNavigationTo,
  userInfo,
  setUserInfo,
  setUserSearchMe,
  saveUserDataAndRedirectToInitialScreen,
  authErrorHandling,
};