
const createStyle = () => {
  return {
    container: {
      marginTop: 16,
      marginHorizontal: 16,
      color: "#FFFFFF",
      fontSize: 15,
    },
  }
}

export default createStyle
