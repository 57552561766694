const mergeArray = (arrayA, arrayB) => {
    arrayB.forEach((item) => {
        var isFound = arrayA.find((fItem)=>{
            if(fItem.id == item.id)
		        return true;
        })
        if(isFound){
            item.isPreferred = true;
        }else{
            item.isPreferred = false;
        }
    });
    return arrayB;
}
export default mergeArray