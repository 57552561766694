import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useContext,
  useState,
} from "react";
import "../../../App.css";
import { UserContext } from "../../../contexts/userContext";
import SearchTextField from "../searchComponent/searchTextField";
import { PhoneIcon } from "../../../images/svg/phoneIcon";
import SearchSelect from "../searchComponent/searchSelect";
import { GlobeIcon } from "../../../images/svg/globeIcon";
import { FlagIcon } from "../../../images/svg/flagIcon";
import { StateGreen } from "../../../images/svg/state_Icon";
import { TimerIcon } from "../../../images/svg/timerIcon";
import back_white from "../../../images/svg/back_white.svg";
import Iso3166 from "iso-3166-2";
import AdvancedSearchTooltip1 from "../../onboardingTooltips/advancedSearchTooltip/advancedSearchTooltip1";
import AdvancedSearchTooltip2 from "../../onboardingTooltips/advancedSearchTooltip/advancedSearchTooltip2";
import AdvancedSearchTooltip3 from "../../onboardingTooltips/advancedSearchTooltip/advancedSearchTooltip3";
import Styles from "./advancedSearch.module.css";
import { capitalizeCase } from "../../../utils/utils";

const AdvancedSearch = (
  {
    selectedTab,
    onPressBackButton,
    showAdvancedSearch,
    searchString,
    setSearchString,
    phoneNumberInput,
    setPhoneNumber,
    countryInput,
    setCountry,
    cityInput,
    setCity,
    stateInput,
    stateAbbr,
    setStateAbbr,
    setState,
    ageInput,
    setAge,
    onSubmitEditingSearchbarHandler,
    onValidAdvancedSearch,
    modalVisible,
    isEdit,
    isAdhocSearch,
    advancedSearchOnboardingDone,
    showDisclaimer,
    phoneRef
  },
  ref
) => {
  let formRef;
  const searchRef = useRef();

  const countryRef = useRef();
  const cityRef = useRef();
  const stateRef = useRef();
  const ageRef = useRef();

  const [showAdvancedSearchTooltip1, setShowAdvancedSearchTooltip1] = useState(
    false
  );
  const [showAdvancedSearchTooltip2, setShowAdvancedSearchTooltip2] =
    useState(false);
  const [showAdvancedSearchTooltip3, setShowAdvancedSearchTooltip3] =
    useState(false);

  const [isFocusedCountry, setIsFocusedCountry] = useState(false);
  const [isFocusedState, setIsFocusedState] = useState(false);
  const [reset, setReset] = useState(false);

  // const { user } = useContext(UserContext);

  useEffect(() => {
    setShowAdvancedSearchTooltip1(!advancedSearchOnboardingDone && !showDisclaimer)
  }, [advancedSearchOnboardingDone, showDisclaimer])


  const [errorPhone, setErrorPhone] = useState(false);
  const [errorCity, setErrorCity] = useState(false);

  useEffect(() => {
    if (cityInput && cityInput.length > 0) {
      if (cityInput.match(cityRegExp)) {
        setErrorCity(false);
      } else {
        setErrorCity(true);
      }
    } else {
      setErrorCity(false);
    }
  }, [cityInput]);

  useEffect(() => {
    if (phoneNumberInput && phoneNumberInput.length > 0) {
      if (phoneNumberInput.match(phoneRegExp)) {
        setErrorPhone(false);
      } else {
        setErrorPhone(true);
      }
    } else {
      setErrorPhone(false);
    }
  }, [phoneNumberInput]);

  useEffect(() => {
    if (countryInput) {
      setState();
    }
  }, [countryInput]);

  useEffect(() => {
    if (stateInput) {
      setState(stateInput);
    }
  }, [stateInput]);

  useImperativeHandle(ref, () => ({
    onHandleSubmit: () => {
      if (!errorCity && !errorPhone) {
        onSubmitEditingSearchbarHandler();
      }
    },
    onResetForm: () => {
      // formRef.resetForm()
      // formRef.setFieldValue('state', '')
      // formRef.setFieldValue('country', '')
      setReset(true)
    },
    setFocus: (fieldType) => {
      setIsFocusedCountry(false)
      setIsFocusedState(false)
      setReset(false)
      switch (fieldType) {
        case 0:
          searchRef?.current?.focus();
          return;
        case 1:
          phoneRef?.current?.focus();
          return;
        case 2:
          setIsFocusedCountry(true)
          countryRef?.current?.focus();
          return;
        case 3:
          cityRef?.current?.focus();
          return;
        case 4:
          setIsFocusedState(true)
          stateRef?.current?.focus();
          return;
        case 5:
          ageRef?.current?.focus();
          return;
      }
    },
  }));

  let tabIsBusiness = selectedTab == "Business";

  const countries = Object.values(Iso3166.data)
    .map(({ name }) => ({
      label: name,
      value: name,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  // Add main countries to the top of the list, keeping the order defined on this array:
  const highlightedCountries = [
    "United States",
    "Canada",
    "Australia",
    "India",
  ];
  highlightedCountries.reverse().forEach((c) => {
    // Get index of existing
    let idx = countries.findIndex(
      (existingCountry) => existingCountry.label === c
    );

    if (idx !== -1) {
      // Remove it from its original position
      countries.splice(idx, 1);

      // Add it to the top of the list:
      countries.unshift({
        label: c,
        value: c,
      });
    }
  });

  countries.splice(0, 0, { label: "Country of work or residence", value: "" });

  const getCountryProvinces = (country) => {
    if (country == "")
      return [{ label: "State", value: "", inputLabel: "State" }];
    const countryData = Object.values(Iso3166.data).find(
      (c) => c.name == country
    );
    if (!countryData || !countryData.sub) return [];
    const states = Object.keys(countryData.sub)
      .map((key) => ({
        label: countryData.sub[key].name,
        value: countryData.sub[key].name,
        inputLabel:
          key?.split("-")[0] == "US"
            ? key?.split("-")[1]
            : countryData.sub[key].name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    states.splice(0, 0, { label: "State", value: "", inputLabel: "State" });

    return states;
  };

  const phoneRegExp =
    /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
  const cityRegExp = /^(([A-Za-z ]|[a-z ]+['-]?)+)$/;

  const initialValues = {
    name: "",
    phoneNumber: "",
    country: undefined,
    city: "",
    state: "",
    age: "",
  };

  if (tabIsBusiness) {
    initialValues.age = "";
  }

  const BackIcon = () => {
    return (
      <img
        src={back_white}
        className={Styles.back_white_search}
        onClick={onPressBackButton}
      />
    );
  };

  const onPhoneNumnerChange = (value) => {
    var cleaned = ("" + value).replace(/\D/g, "");
    let length = cleaned?.length;
    if (length > 10) {
      cleaned = cleaned.slice(length - 10, length);
    }
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "",
        number = [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
          ""
        );
      return number;
    }
    return cleaned;
  };

  const onAgeChange = (value) => {
    if (value.match(/^-?\d*\.?\d*$/)) setAge(value);
    else return;
  };

  return (
    <div>
      <SearchTextField
        refObj={searchRef}
        value={capitalizeCase(searchString, true)}
        setValue={setSearchString}
        labelValue="Name"
        placeHolderValue="First, middle, last name if possible"
        isBackicon={true}
        onPressBackButton={onPressBackButton}
        setIsFocusedCountry={setIsFocusedCountry}
        setIsFocusedState={setIsFocusedState}
      />
      {<SearchTextField
        refObj={phoneRef}
        isError={errorPhone}
        errorText={"Must be a Phone number"}
        value={phoneNumberInput}
        labelValue="Phone number"
        placeHolderValue="Phone number"
        setValue={(value) => {
          setPhoneNumber(onPhoneNumnerChange(value));
        }}
        icon={<PhoneIcon />}
        inputProps={{ inputMode: "numeric" }}
      />}
      <div className={Styles.container}>
        <div className={Styles.container2}>
          <SearchSelect
            refObj={countryRef}
            setValue={setCountry}
            value={countryInput}
            defaultValue={"United States"}
            icon={<GlobeIcon />}
            label="Country"
            options={countries}
            setState={setState}
            isFocused={isFocusedCountry}
            stateAbbr={stateAbbr}
            setStateAbbr={setStateAbbr}
          />
          {/* <SearchTextField
            refObj={cityRef}
            errorText={"Enter a valid city name"}
            isError={errorCity}
            value={cityInput}
            setValue={setCity}
            labelValue="City"
            placeHolderValue="City of work or residence"
            icon={<FlagIcon />}
          /> */}
        </div>


        <div className={Styles.container3}>
          <AdvancedSearchTooltip3
            showAdvancedSearchTooltip3={showAdvancedSearchTooltip3}
            setShowAdvancedSearchTooltip3={setShowAdvancedSearchTooltip3}
          > {
              <SearchSelect
                refObj={stateRef}
                setValue={setState}
                value={stateInput}
                lableKey={"inputLabel"}
                defaultValue={""}
                icon={<StateGreen />}
                label="State"
                type="state"
                country={countryInput}
                options={getCountryProvinces(countryInput)}
                isFocused={isFocusedState}
                stateAbbr={stateAbbr}
                setStateAbbr={setStateAbbr}
              />
            }
          </AdvancedSearchTooltip3>
        </div>
      </div>
    
      {/* <SearchTextField
        refObj={ageRef}
        // inputType={"number"}
        inputProps={{ inputMode: "numeric" }}
        value={ageInput}
        setValue={onAgeChange}
        labelValue="Age"
        placeHolderValue="Approx. age"
        icon={<TimerIcon />}
      /> */}
      <AdvancedSearchTooltip1
        showAdvancedSearchTooltip1={showAdvancedSearchTooltip1}
        setShowAdvancedSearchTooltip1={setShowAdvancedSearchTooltip1}
        setShowAdvancedSearchTooltip2={setShowAdvancedSearchTooltip2}
        advancedSearchOnboardingDone={advancedSearchOnboardingDone}
      />

      <AdvancedSearchTooltip2
        showAdvancedSearchTooltip2={showAdvancedSearchTooltip2}
        setShowAdvancedSearchTooltip2={setShowAdvancedSearchTooltip2}
        setShowAdvancedSearchTooltip3={setShowAdvancedSearchTooltip3}
        advancedSearchOnboardingDone={advancedSearchOnboardingDone}
      />



    </div>
  );
};

export default forwardRef(AdvancedSearch);
