import React, { useContext } from "react";
import ImportedContactsLogo from "../../../images/svg/imported-contacts-logo";
import SocialNetworksGrid from "../../../components/socialNetworksGrid/socialNetworksGrid";
import _ from "lodash";
import { GreenBgButton } from "../../../components/buttons/greenBgButton";
import { useNavigate, useLocation } from "react-router-dom";
import Styles from "./importContact.module.css";
import { UserContext } from "../../../contexts/userContext";

const ImportedContacts = (props) => {
  const location = useLocation();
  let propsState = location.state ? JSON.parse(location.state) : {};
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  let originalSocialNetworks;
  if (propsState.socialNetworks) {
    originalSocialNetworks = propsState.socialNetworks;
  } else {
    originalSocialNetworks = propsState.route?.params?.socialNetworks ?? [];
  }

  const nextButtonOnClcik = () => {
    if(user?.isSubscribed|| !user?.hasExpired){
      navigate("/importClosestContacts", {
        state: propsState ? JSON.stringify(propsState) : {},
      })
    }else{
      if(propsState?.modeCheck == 'signup'){
        navigate("/dashboard", { state: { showSplash: true } });
      }else{
        navigate("/savedSearch", {
          replace: true,
        });
      }
    }
  }

  const socialNetworks = originalSocialNetworks
    .map((item) => {
      return {
        ...item,
        mode: "compact",
        action: null,
        logoHeight: item.title == "Google Contacts" ? 16 : 20,
        logoWidth: item.title == "Google Contacts" ? 16 : 20,
      };
    })
    .filter((item) => _.isInteger(item.contactSize) && item.contactSize > 0);
  const reducer = (total, item) => {
    const contactSize = Number.isInteger(item.contactSize)
      ? item.contactSize
      : 0;
    return total + contactSize;
  };
  const importedContacts = socialNetworks.reduce(reducer, 0);
  return (
    <div className={Styles.mainContainer}>
      <div
        className={propsState.isStartContact ? Styles.container50 : Styles.container}
      >
        <div className={Styles.body}>
          <span className={Styles.title}>
            <span className={Styles.titleGreen}>{`${importedContacts}`}</span>
            {"Contacts Selected"}
          </span>
          <ImportedContactsLogo className={Styles.logo} />
          <SocialNetworksGrid
            socialNetworks={socialNetworks}
            isFinalState={true}
            isWeb={true}
          />
          <span className={Styles.description}>
            {
              (user?.isSubscribed|| !user?.hasExpired) ? "We’ll continue to monitor these sources for new contacts. Proceed to set up real-time notificatons about new search results for contact."
              : "With a paid subscription, you will have the ability to monitor these sources for new contacts, and setup real-time notifications about new search results for your contacts."
            }
          </span>
        </div>
      </div>
      {!propsState.isStartContact && (
        <div className={Styles.footer}>
          <GreenBgButton
            padding={"10px 40px 10px 40px"}
            onClick={nextButtonOnClcik}
            variant="contained"
          >
            <div>{(user?.isSubscribed|| !user?.hasExpired) ? "Next" : (propsState?.modeCheck == 'signup' ? "Go To Dashboard" : "See My Contacts")}</div>
          </GreenBgButton>
        </div>
      )}
    </div>
  );
};

export default ImportedContacts;
