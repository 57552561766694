import { useContext, useEffect, useState } from "react";
import { DashboardFooterLayoutIcon } from "../../images/svg/dashboard_footer_layout_icon";
import { FolderIcon } from "../../images/svg/folderIcon";
import { HomeIcon } from "../../images/svg/homeIcon";
import { SearchIcon } from "../../images/svg/search_icon";
import RecentActivityTooltip from "../onboardingTooltips/recentActivityTooltip";
import NewSearchTooltip from "../onboardingTooltips/newSearchToolTip";
import SavedSearchesTooltip from "../onboardingTooltips/savedSearchesTooltip";
import {
  OnboardingContext,
  OnboardingStates,
  getNextOnboardingStatus,
} from "../../contexts/onboardingContext";
import { UserContext } from "../../contexts/userContext";
import { useLazyQuery } from "react-apollo";
import { useMutation } from '@apollo/react-hooks';
import {
  ME,
  UPDATE_FERRET_USER,
  UPDATE_DEVICE_TOKEN,
  IMPORT_CONTACTS,
} from "../../queries/queries";
import { setUserInfo, userInfo } from "../../root-navigation";
import Variables from "../../utils/Variables";

import Styles from './dashboardFooter.module.css';
import { useLocation } from "react-router-dom";

export default function DashboardFooter({
  selectedBtn,
  setSelectedBtn,
  navigate,
  setSearchLimitExceed,
  disableButtonsOnScreen
}) {
  const location = useLocation();

  const [getUserInfo, { loading, error, data }] = useLazyQuery(ME);
  const [updateUser] = useMutation(UPDATE_FERRET_USER);
  const { onboardingState, setOnboardingState } = useContext(OnboardingContext);
  const { user, setUser } = useContext(UserContext);
  const [modalVisible, setModalVisible] = useState(false);
  const tooltips = {};
  const changeOnboardingState = () =>
    setOnboardingState(getNextOnboardingStatus(onboardingState));

  const navArr = [
    {
      label: "Dashboard",
      icon: <HomeIcon />,
      iconSelected: <HomeIcon selected={true} />,
    },
    {
      label: "New Search",
      icon: <div className={Styles.searchIcon}></div>,
    },
    {
      label: "Contacts",
      icon: <FolderIcon />,
      iconSelected: <FolderIcon selected={true} />,
    },
  ];

  useEffect(() => {
    if (
      !error &&
      !loading &&
      !!data &&
      data.hasOwnProperty("me") &&
      onboardingState !== OnboardingStates.end
    ) {
      if (onboardingState == null) {
        let u = setUserInfo(data.me);
        setUser({ ...u });
      }
      const isOnboarded = data.me.isOnboarded;
      const notificationOnboardingDone = data.me.notificationOnboardingDone;
      if (isOnboarded && onboardingState != OnboardingStates.filterHeader && onboardingState != OnboardingStates.recentFilterHeader) {
        Variables.filterOnBoarding = true;
        setOnboardingState(OnboardingStates.end);
      }
      if (isOnboarded && !notificationOnboardingDone) {
        setOnboardingState(OnboardingStates.notificationBannerEnd);
      }
      if (!isOnboarded) {
        setOnboardingState(OnboardingStates.recentActivity);
      }
    }
  }, [loading, data, error]);

  useEffect(() => {
    async function fetchData() {
      if (
        (!userInfo.isOnboarded &&
          onboardingState === OnboardingStates.filterHeader) ||
        (!userInfo.notificationOnboardingDone &&
          (onboardingState === OnboardingStates.end ||
            onboardingState === OnboardingStates.filterHeader))
      ) {
        const res = await updateUser({
          variables: {
            isOnboarded: true,
            notificationOnboardingDone: true, //showing notification tile by default - C4R-5655
            // notificationOnboardingDone: Variables.isContactImported, //expected - if contacts imported then notification boarding will be shown already.
          },
        });
        const newUserInfo = {
          ...userInfo,
          isOnboarded: res.data.updateUser.isOnboarded,
          notificationOnboardingDone:
            res.data.updateUser.notificationOnboardingDone,
        };
        let u = setUserInfo(res.data.updateUser);
        setUser({ ...u });
      }
    }
    fetchData();
  }, [onboardingState]);

  useEffect(() => {
    getUserInfo();

    let navigateDate = location.useState;
    console.log(navigateDate);
  }, []);

  const handleNavItemClick = (item) => {
    if (item.label === "New Search") {
      if (
        user?.currentMonthAdhocSearchCount == user?.adhocSearchLimitPerMonth
      ) {
        setSearchLimitExceed(true);
      } else {
        navigate("/search");
      }
    } else {
      setSelectedBtn(item.label);
    }
  };

  const navItemsMapper = (item) => {
    return (
      <div
        className={`${Styles.footerItem} ${
          item.label !== "New Search"
            ? Styles.footerItemOffset
            : Styles.footerSearchItemOffset
        }`}
        onClick={() => {
          if(!disableButtonsOnScreen)handleNavItemClick(item);
        }}
        key={item.label}
      >
        {selectedBtn == item.label ? item.iconSelected : item.icon}
        <div
          style={
            selectedBtn == item.label
              ? { color: "#078167" }
              : { color: "#697080" }
          }
        >
          {item.label}
        </div>
      </div>
    );
  };

  const TabBar = ({ tooltip }) => (
    <>
      {tooltip ?
        <div className={Styles.footer}>
          {navArr.map((item) => {
            if (item.label == "Dashboard") {
              return <RecentActivityTooltip fromDashboard={true} isVisible={onboardingState == OnboardingStates.recentActivity} onClick={() => changeOnboardingState()}>
                {navItemsMapper(item)}
              </RecentActivityTooltip>
            }
            if (item.label == "New Search") {
              return <NewSearchTooltip fromDashboard={true} isVisible={onboardingState == OnboardingStates.newSearch} onClick={() => changeOnboardingState()}>
                {navItemsMapper(item)}
              </NewSearchTooltip>
            }
            if (item.label == "Contacts") {
              return (
                <SavedSearchesTooltip
                  fromDashboard={true}
                  isVisible={onboardingState == OnboardingStates.savedSearches}
                  onClick={() => changeOnboardingState()}
                >
                  {navItemsMapper(item)}
                </SavedSearchesTooltip>
              );
            }
          })}
        </div>
        :
        <div className={Styles.footer}>
          {navArr.map(navItemsMapper)}
        </div>
      }
    </>
  );

  tooltips[OnboardingStates.newSearch] = NewSearchTooltip;
  tooltips[OnboardingStates.recentActivity] = RecentActivityTooltip;
  tooltips[OnboardingStates.savedSearches] = SavedSearchesTooltip;
  const Tooltip = tooltips[onboardingState];

  if (
    !modalVisible &&
    Tooltip &&
    (onboardingState !== null || onboardingState !== OnboardingStates.end)
  ) {
    return (
      <TabBar
        setModalVisible={setModalVisible}
        tooltip={true}
      />
    );
  }

  return <TabBar setModalVisible={setModalVisible} />;
}
