import { useState } from "react";
import OnboardingTooltip from "../onboardingTooltip";
import OnboardingPopover from "../onboardingPopover";
import WhiteSearchIcon from "../../../images/svg/whiteSearchIcon";
import Styles from './advancedSearchTooltip.module.css'


const AdvancedSearchTooltip2 = ({
  showAdvancedSearchTooltip2,
  setShowAdvancedSearchTooltip2,
  setShowAdvancedSearchTooltip3,
  advancedSearchOnboardingDone,
  isWeb,
}) => {
  return (
    <>
    <OnboardingTooltip
      title={
        <OnboardingPopover
          title={isWeb ? "Advanced Search (1 of 2)" : "Advanced Search (2 of 3)"}
          content={
            <div>
              Add a
              <span className={Styles.highlightedContentText}> phone number </span>
              and/or <span className={Styles.highlightedContentText}>location</span> to
              your search to find additional record types including corporate,
              criminal, debt, and more.
            </div>
          }
          icon={<WhiteSearchIcon />}
          showCloseIcon={false}
          showNextButton={true}
          onClickNext={() => {
            setShowAdvancedSearchTooltip2(false);
            setShowAdvancedSearchTooltip3(true);
          }}
        />
      }
      componentStyle={{ marginTop: isWeb ? "-170px !important" : "-19% !important" }}
      arrowStyles={{ overflow: "inherit" }}
      isVisible={showAdvancedSearchTooltip2 && advancedSearchOnboardingDone === false}
      placement="bottom"
      ></OnboardingTooltip>
      {(isWeb && showAdvancedSearchTooltip2 && advancedSearchOnboardingDone === false) &&
        <div style={{
          width: "110%",
          height: "100%",
          position: "absolute",
          display: "flex",
          alignItems: "flex-end",
          inset: "0px auto 0px -100px",
          transform: "translate3d(0px, 0px, 0px)",
          zIndex: 8
        }} />
      }
    </>
  );
};

export default AdvancedSearchTooltip2;
