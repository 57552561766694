import React, { useState, useEffect, useRef, useContext } from "react";
import SavedSearchCardWithQuery from "../savedSearchCardWithQuery/savedSearchCardWithQuery";
import parseFerretContactsForContactsComponent from "../../utils/ferret-contact-parser";
import _ from "lodash";
import ContactImportAlert from "../noContactsAlert/ContactImportAlert";
import Variables from "../../utils/Variables";
import ImportContactsConfirmModal from "../importContactsConfirmModel/importContactsConfirmModel";
import InfoIcon from "../../images/svg/info";
import MissingDetails from "../../images/svg/missingDetails";
import { UserContext } from "../../contexts/userContext";
import CloseIconWhite from "../../images/svg/closeWhite";
import { UPDATE_CONTACT_IMPORT_FLAG } from "../../queries/queries";
import {
  GET_FERRET_CONTACT_LIST,
  SET_PREFERENCE,
  GET_POTENTIAL_MATCHES,
} from "../../queries/queries";
import { useLazyQuery, useMutation } from "react-apollo";
import { CircularProgress, IconButton } from "@mui/material";
import parseSectionListHeight from "../../utils/parseSectionListHeight";
import SearchHeader from "../SearchHeader/SearchHeader";
import SavedSearchCard from "../savedSearchCard/savedSearchCard";
import useSaveRecentSearch from "../../utils/apis/useSaveRecentSearch";
import { useNavigate } from "react-router-dom";
import SearchLimitExceedModal from "../searchLimitExceedModal";
import SubscriptionPlansModal from "../subscriptionPlans/subscriptionPlansModal";
import { ModelType } from "../searchLimitExceedModal/searchLimitExceedModal";
import { AlphabetScroll } from "../alphabetScroll/alphabetScroll";
import { useInView } from "react-intersection-observer";
import { isEqualOrHigherThanIphoneX } from "../../utils/isEqualOrHigherThanIphoneX";
import Styles from "../savedSearches/savedSearches.module.css";
import { BackWhiteIcon } from "../../images/svg/back_white";
import SaveAdHocQuery from "../searchModalV2/noResults/saveAdHocQuery";
import getMetaFromContacts from "../../utils/getMetaFromContacts";
import mergeArray from "../../utils/mergeArray";
import { GreenBgButton } from "../buttons/greenBgButton";
import PotentialMatch from "../potentialMatch/potentialMatch";
import CloseCloset from "../../images/closeCloset";
import { closestContactsEditListStore } from "../../store/closestContactsEditListStore";

let theme;
const defaultProfilePicture = require("../../images/svg/default-avatar.svg");
const FILTER_TIMEOUT = 250;
let lastTimeout = null;

let sectionListItemOffset = [];
const ClosestContactsEditList = (props) => {
  const navigate = useNavigate();

  const refs = useRef([]);
  const refsList = useRef();
  let contactsList = [];
  //const {contactsList = [], title = 'Closest Contacts', customListID = false} = props?.route?.params;

  const filteredContacts = closestContactsEditListStore((state) => state.filteredContacts);
  const setFilteredContacts = closestContactsEditListStore(
    (state) => state.setFilteredContacts
  );

  const originalContacts = closestContactsEditListStore((state) => state.originalContacts);
  const setOriginalContacts = closestContactsEditListStore(
    (state) => state.setOriginalContacts
  );

  const originalParsedContacts = closestContactsEditListStore((state) => state.originalParsedContacts);
  const setOriginalParsedContacts = closestContactsEditListStore(
    (state) => state.setOriginalParsedContacts
  );

  const categories = closestContactsEditListStore((state) => state.categories)
  const setCategories = closestContactsEditListStore((state) => state.setCategories)

  const searchValue = closestContactsEditListStore((state) => state.searchValue);
  const setSearchValue = closestContactsEditListStore(
    (state) => state.setSearchValue
  );

  const potentialModalVisible = closestContactsEditListStore((state) => state.potentialModalVisible);
  const setPotentialModalVisible = closestContactsEditListStore(
    (state) => state.setPotentialModalVisible
  );

  const potentialData = closestContactsEditListStore((state) => state.potentialData);
  const setPotentialData = closestContactsEditListStore(
    (state) => state.setPotentialData
  );

  const contactID = closestContactsEditListStore((state) => state.contactID);
  const setContactID = closestContactsEditListStore(
    (state) => state.setContactID
  );

  const isSearchLimitExceed = closestContactsEditListStore((state) => state.isSearchLimitExceed);
  const setSearchLimitExceed = closestContactsEditListStore(
    (state) => state.setSearchLimitExceed
  );

  const subscriptionModalVisible = closestContactsEditListStore((state) => state.subscriptionModalVisible);
  const setSubscriptionModalVisible = closestContactsEditListStore(
    (state) => state.setSubscriptionModalVisible
  );

  const { user, setUser } = useContext(UserContext);
  const { saveAdHocAndParse, data, loading, error } = SaveAdHocQuery();

  const [potentialMatches, { loading: loadingMatches }] = useLazyQuery(
    GET_POTENTIAL_MATCHES,
    {
      onCompleted: (data) => {
        if (data && data.getPotentialMatches?.length) {
          setPotentialData(data.getPotentialMatches);
          setPotentialModalVisible(true);
        }
      },
    }
  );

  const onSavePotentialMatch = (index) => {
    Variables.isAddSearches = true;
    setPotentialModalVisible(false);
    setOriginalParsedContacts({});
    getFerretContactList({
      variables: {
        showSavedSearches: true,
      },
    });
  };

  const getPotentialMatches = (contactID) => {
    setContactID(contactID);
    potentialMatches({ variables: { contactId: contactID } });
  };

  const finalNavigate = () => {
    if (Variables.navigationStackNameForContacts == "newDashboard") {
      navigate('/dashboard')
    } else {
      navigate('/closestContacts')
    }
  };

  const [setPreferenceContacts,{loading: loadingPref }] = useMutation(SET_PREFERENCE, {
    onCompleted: (data) => {
      Variables.isAddSearches = true;
      checkWatchedLimit(data?.setPreference?.message);
    },
    onError: (error) => {
      checkWatchedLimit(error?.setPreference?.message);
    },
  });

  const [
    getFerretContactList,
    { loading: loadingContacts, data: dataContacts, error: errorContacts },
  ] = useLazyQuery(GET_FERRET_CONTACT_LIST, {
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (!loadingContacts && dataContacts) {
      setOriginalContacts(
        mergeArray(
          contactsList,
          dataContacts?.contactList?.contacts ?? [],
          "id"
        )
      );
      setCategories(dataContacts?.contactList?.categories)
    } else if (!loadingContacts && errorContacts !== undefined) {
      // Alert.alert(
      //   '',
      //   'Did not connect to server. Please try again.',
      //   [
      //     {
      //       text: 'OK',
      //       style: 'default',
      //       onPress: () => {
      //         navigation.pop()
      //       },
      //     },
      //   ],
      //   { cancelable: false },
      // );
    }
  }, [loadingContacts, dataContacts, errorContacts]);

  useEffect(() => {
    if (!loading && data) {
      setOriginalParsedContacts({});
      getFerretContactList({
        variables: {
          showSavedSearches: true,
        },
      });
    }
  }, [data, loading, error]);

  useEffect(() => {
    let contacts;
    // if (JSON.stringify(originalParsedContacts) === "{}") {
      let mContacts = parseFerretContactsForContactsComponent(originalContacts);
      let dict = {};
      let countPush = 0;
      mContacts.forEach((section) => {
        let filteredSectionContacts = section.data;
        if (filteredSectionContacts.length) {
          countPush = parseSectionListHeight(
            filteredSectionContacts,
            sectionListItemOffset,
            countPush
          );
          dict[section.title] = filteredSectionContacts;
        }
      });
      contacts = dict;
      setOriginalParsedContacts(dict);
    // } else {
      // contacts = originalParsedContacts;
    // }
    filterContacts(contacts, searchValue);
  }, [originalContacts]);

  const filterContacts = (contacts, searchValue = "") => {
    const valueCriteria = searchValue.toUpperCase().trim();
    if (valueCriteria.length > 0) {
      let dict = {};
      let countPush = 0;
      let offsetList = [];

      Object.keys(contacts)?.forEach((itemKeys) => {
        let mItem = contacts[itemKeys]?.filter((item) => {
          const itemData =
            item?.firstName?.toUpperCase() +
            " " +
            item?.lastName?.toUpperCase();
          return itemData?.indexOf(valueCriteria) > -1;
        });
        if (mItem && mItem.length) {
          countPush = parseSectionListHeight(mItem, offsetList, countPush);
          dict[itemKeys] = mItem;
        }
      });
      setFilteredContacts(dict);
    } else {
      setFilteredContacts(contacts);
    }
  };

  const onPressContact = (newsItem) => {
    if (newsItem?.isProcessed && newsItem?.multiplePotentialMatchesFound) {
      getPotentialMatches(newsItem?.id);
      return;
    }
    const {
      metadata,
      searchTerm,
      searchType,
      searchContext,
      tahoeId,
      tracerQueryId,
      requestMetadata,
    } = getMetaFromContacts(newsItem);
    // parseSaveRecentSearch(searchTerm, metadata, 'saved-search')
    let arrayData = [];
    Object.values(metadata)?.forEach((item) => {
      arrayData = [...arrayData, ...item?.split(";")];
    });

    if (searchContext?.age) {
      let age = `App. ${searchContext?.age} y/o`;
      arrayData.splice(1, 0, age + "");
    }
    if (searchContext?.state) arrayData.splice(1, 0, searchContext?.state);
    if (searchContext?.city) arrayData.splice(1, 0, searchContext?.city);
    if (searchContext?.country) arrayData.splice(1, 0, searchContext?.country);
    if (searchContext?.phone) arrayData.splice(1, 0, searchContext?.phone);

    const data = {
        contactItem: newsItem,
        metadata,
        searchTerm,
        searchType,
        searchContext,
        tahoeId,
        tracerQueryId,
        requestMetadata,
        feed: {
          fullName: `${newsItem.name} ${newsItem.lastName != null ? newsItem.lastName : ''}`,
          metadata: [],
          searchSettings: {initialSearchParams: arrayData}
        }
      }

      navigate('/savedContactsArticles', { state: JSON.stringify(data) })
    };

  const addContacts = () => {
    Variables.newAddHocAdded = false;
    const data = {
      'buttonText': 'Proceed with Search', 
      'fromClosestContact': true
    }
    navigate('/searchComponent', { state: data })
  };

  useEffect(() => {
    getFerretContactList({
      variables: {
        showSavedSearches: true,
      },
    });
  }, []);

  const submitModal = (data) => {
    if (
      data &&
      data?.searchSettings?.intitialMetadata &&
      data?.searchSettings?.initialSearchString
    ) {
      saveAdHocAndParse(
        data?.searchSettings?.intitialMetadata,
        data?.searchSettings?.initialSearchString,
        data?.irrelevantArticleId
      );
    }
  };

  const handleChange = (text) => {
    setSearchValue(text);
    filterContacts(originalParsedContacts, text);
  };

  const handleCancel = () => {
    setSearchValue("");
    filterContacts(originalParsedContacts, "");
  };

  const onSave = () => {
    let data = [];
    originalContacts.forEach((item) => {
      if (item.isChanged) {
        data.push({
          id: item.id,
          isPreferred: item.isPreferred,
        });
      }
    });

    console.log('originalContacts=====',originalContacts)
    console.log('originalContacts=====data',data)
    if (data.length > 0) {
      if (user?.watchedLimitExceed && data.isPreferred == true) {
        checkWatchedLimit("limit reached");
      } else {
        setPreferenceContacts({
          variables: {
            contacts: data,
          },
        });
        Variables.isRefreshClosestContacts = true;
      }
    } else {
      navigate('/closestContacts')
    }
  };

  const startFreeTrial = async () => {
    setSubscriptionModalVisible(true);
    setSearchLimitExceed(false);
  };
  const cancelPopup = () => {
    setSubscriptionModalVisible(false);
    setSearchLimitExceed(false);
    finalNavigate();
  };

  const checkWatchedLimit = (message) => {
    if (message?.toLowerCase() === "limit reached") {
      const updateUserInfo = { ...user, watchedLimitExceed: true };
      setUser(updateUserInfo);
      setSearchLimitExceed(true);
    } else {
      finalNavigate();
    }
  };

  let alphaArray = [];
  Object.keys(filteredContacts).map((key) => {
    alphaArray = [...alphaArray, key];
  });

  let initialized = false
  useEffect(()=>{
    if(Variables.newAddHocAdded && !initialized){
      initialized = true
      submitModal(Variables.newAddHocAdded)
    }
  },[])

  return (
    <div className={Styles.body}>
      <Header navigate={navigate} addContacts={addContacts}/>
      <main className={Styles.main}>
        <>
        <PotentialMatch
        contactID={contactID}
        potentialModalVisible={potentialModalVisible}
        setPotentialModalVisible={setPotentialModalVisible}
        potentialData={potentialData}
        onSavePotentialMatch={onSavePotentialMatch}
      />
          <div className={Styles.searchBarMainView}>
            <SearchHeader
              value={searchValue}
              onChangeText={handleChange}
              onCancel={handleCancel}
              selectAllButton={false}
              placeholder="Search Contacts"
            />
          </div>
          <div className={Styles.alphaHeaderText}>Contacts List</div>
          <div className={Styles.list} ref={refsList}>
            <div style={{ color: "transparent" }}>dummy div</div>

            <li className={Styles.listContact}>
              {filteredContacts &&
                Object.entries(filteredContacts).map(([key, values], index) => (
                  <div
                    ref={(element) => {
                      refs.current[key] = element;
                    }}
                    key={index}
                  >
                    {
                      <>
                        <p className={Styles.alphaHeaderText}>{key}</p>
                        {values &&
                          values.map((item) => (
                            <SavedSearchCard
                              key={item.originalContact.id}
                              showStarred={false}
                              showCheckbox={false}
                              savedSearch={item.originalContact}
                              isFromClosest={true}
                              onPress={()=>{}}
                              isEditable={true}
                              setSearchLimitExceed={setSearchLimitExceed}
                              categories={categories}
                            />
                          ))}
                      </>
                    }
                  </div>
                ))}
            </li>
            <div
              style={{
                position: "absolute",
                bottom: "0%",
                width: "100%",
                backgroundColor: "#f8faff",
                paddingTop: "8px",
                borderTop: "3px solid #f8faff"
              }}
            >
              <GreenBgButton
                fullWidth
                onClick={onSave}
                variant="contained"
                style={{ marginBottom: 14, width: "96%", marginLeft: "2%" }}
              >
                <div>Save Changes</div>
              </GreenBgButton>
            </div>
          </div>
        </>
        )
      </main>

      {(loadingContacts || loading || loadingPref) && (
        <div
          style={{
            position:'absolute',
            top:"50%",
            left:'46%'
          }}
        >
          <CircularProgress />
        </div>
      )}

      {isSearchLimitExceed && (
        <SearchLimitExceedModal
          onBackdropPress={() => {
            setSearchLimitExceed(false);
          }}
          startFreeTrial={startFreeTrial}
          goback={cancelPopup}
          modelType={ModelType.WATCHED_SEARCH_LIMIT_MODAL}
        />
      )}

      {subscriptionModalVisible && (
        <SubscriptionPlansModal
          subscriptionModalVisible={subscriptionModalVisible}
          setSubscriptionModalVisible={setSubscriptionModalVisible}
        />
      )}
      <AlphabetScroll
        onClick={(a) => {
          refsList?.current?.scrollTo({
            top: refs.current[a].offsetTop + 10,
            behavior: "smooth",
          });
        }}
        alphaArray={alphaArray}
        customStyle={{ top: "25%", height: "58%" }}
      />
    </div>
  );
};

const Header = (props) => {
  const { navigate, addContacts } = props;

  return (
    <div className={Styles.titleContainer}>
      <div className={Styles.signUpLadingBackButton} >
        <IconButton onClick={() => navigate(-1)} style={{left:-10}}>
          <BackWhiteIcon />
        </IconButton>
      </div>
      <span className={Styles.titleText} style={{fontSize: 16}}>Edit List</span>
      <CloseCloset onClick={addContacts}/>
    </div>
  );
};

export default ClosestContactsEditList;
