import { create } from 'zustand'

export const recentViewArticles = create((set) => ({
  configurationData: {},
  ConfigurationLoading: false,
  searchSettings: {},
  filteredArticles: [],
  filteredArticles2: [],
  originalfilteredArticles: [],
  isEnableFilter: false,
  articleFilterData: undefined,
  personalInfo: {},
  isShowPersonalInfo: true,
  selectedFilter: undefined,
  sortState: 2,
  showStrongMatchArticles: false,
  showWeakMatchArticles: false,
  filterAttributes: {
    "People": [],
    "Organizations": [],
    "Places": [],
    "Parties": [],
    "Attorneys": []
  },
  readFilter: false,
  unreadFilter: false,
  attributes: [],
  verified: true,
  partial: true,
  needsReview: true,
  refreshList: false,
  showHeader: true,
  modalFilterVisible: true,
  articleCount: 0,
  articles: [],
  applyFilter: null,
  isNoResult: false,
  alertPopupVisible: false,
  refreshing: false,
  refreshingFilter: false,
  categoryFilterData: [],
  articlePosition: 0,
  setConfigurationData: (data) => set(() => ({ configurationData: data})),
  setConfigurationLoading: (data) => set(() => ({ ConfigurationLoading: data})),
  setSearchSettings: (data) => set(() => ({ searchSettings: data})),
  setFilteredArticles: (data) => set(() => ({ filteredArticles: data})),
  setFilteredArticles2: (data) => set(() => ({ filteredArticles2: data})),
  setOriginalFilteredArticles: (data) => set(() => ({ originalfilteredArticles: data})),
  setEnableFilter: (data) => set(() => ({ isEnableFilter: data})),
  setArticleFilterData: (data) => set(() => ({ articleFilterData: data})),
  setPersonalInfo: (data) => set(() => ({ personalInfo: data})),
  setShowPersonalInfo: (data) => set(() => ({ isShowPersonalInfo: data})),
  setSelectedFilter: (data) => set(() => ({ selectedFilter: data})),
  setSortState: (data) => set(() => ({ sortState: data})),
  setShowStrongMatchArticles: (data) => set(() => ({ showStrongMatchArticles: data})),
  setShowWeakMatchArticles: (data) => set(() => ({ showWeakMatchArticles: data})),
  setFilterAttributes: (data) => set(() => ({ filterAttributes: data})),
  setReadFilter: (data) => set(() => ({ readFilter: data})),
  setUnreadFilter: (data) => set(() => ({ unreadFilter: data})),
  setAttributes: (data) => set(() => ({ attributes: data})),
  setVerified: (data) => set(() => ({ verified: data})),
  setPartial: (data) => set(() => ({ partial: data})),
  setNeedsReview: (data) => set(() => ({ needsReview: data})),
  setRefreshList: (data) => set(() => ({ refreshList: data})),
  setShowHeader: (data) => set(() => ({ showHeader: data})),
  setModalFilterVisible: (data) => set(() => ({ modalFilterVisible: data})),
  setArticleCount: (data) => set(() => ({ articleCount: data})),
  setArticles: (data) => set(() => ({ articles: data})),
  setApplyFilter: (data) => set(() => ({ applyFilter: data})),
  setNoResult: (data) => set(() => ({ isNoResult: data})),
  setAlertPopupVisible: (data) => set(() => ({ alertPopupVisible: data})),
  setRefreshing: (data) => set(() => ({ refreshing: data})),
  setRefreshingFilter: (data) => set(() => ({ refreshingFilter: data})),
  setCategoryFilterData: (data) => set(() => ({ categoryFilterData: data})),
  setArticlePosition: (data) => set(() => ({ articlePosition: data})),
  removeAll: () => set({ 
    configurationData: {},
  ConfigurationLoading: false,
  searchSettings: {},
  filteredArticles: [],
  filteredArticles2: [],
  originalfilteredArticles: [],
  isEnableFilter: false,
  articleFilterData: undefined,
  personalInfo: {},
  isShowPersonalInfo: true,
  selectedFilter: undefined,
  sortState: 2,
  showStrongMatchArticles: false,
  showWeakMatchArticles: false,
  filterAttributes: {
    "People": [],
    "Organizations": [],
    "Places": [],
    "Parties": [],
    "Attorneys": []
  },
  readFilter: false,
  unreadFilter: false,
  attributes: [],
  verified: true,
  partial: true,
  needsReview: true,
  refreshList: false,
  showHeader: true,
  modalFilterVisible: true,
  articleCount: 0,
  articles: [],
  applyFilter: null,
  isNoResult: false,
  alertPopupVisible: false,
  refreshing: false,
  refreshingFilter: false,
  categoryFilterData: [],
  articlePosition: 0,
  }),
}))


