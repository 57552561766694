import {
  saveBaseURL,
  getBaseURL,
} from './safe-storage';
let ferretApolloClient = null;


const apolloOperations = {
  me: ["me"],
  auth: [
    "LinkedIn",
    "Google",
    "authenticateAppleUser",
    "authenticateFerretUser",
    "signInFerretUser",
    //TODO: remove operations from below once the other urls work
    "getArticleById",
    "updateFerretUser",
    "inviteContactsToFerret",
    "searchResults",
    "savePurchase",
    "saveSelfSearch",
    "updateDeviceToken",
    "refreshSession",
    "deleteUser",
    "getSubsriptionPlan",
    "updateUserDisclaimer",
    "updateContactImportFlag",
    "createStripeSubscription",
    "updateStripeSubscription",
    "cancelStripeSubscription",
    "setupPaymentIntent",
  ],
  activity: [
    "searchFeed",
    "getFulltext",
    "getArticleType",
    "getArticlesByContactId",
    "saveRecentSearch",
    "getRecentSearch",
    "saveArticleRelevancy",
    "getSavedSearchArticles",
    "savedSearchArticleType",
    "getNotificationDetails",
    "saveArticleHistory",
    "getArticleHistory",
    "getHistoryArticleType",
    "syncProgress",
    "updateRecentSearchesStatus",
    "isValidUrl",
    "getRecentSearchById",
  ],
  contacts: [
    "ImportContacts",
    "addressBook",
    "queries",
    "getFerretContactList",
    "saveAdhocSearch",
    "setPreference",
    "deleteAContact",
    "getSavedSearches",
    "getContactSyncProgress",
    "getSharedContacts",
    "updateContactData",
    "getNylasAuthToken",
  ],
  search: [
    "refine_search",
    "getArbConfiguration",
    "getFEToggleSettings",
    "getPotentialMatches",
    "checkMetaSearchData",
    "SubmitFeedbackResponse",
    "GenerateSummary",
    "getUnicourtCase"
  ],
  notification: [
    "getNotifications",
    "readNotification",
    "userUpdates"
  ],
  contactsHandler:[
    "getFavIcon",
  ]
};

const getBaseUrlFor = operationName => {
  let baseUrls = undefined
  try{
    baseUrls = JSON.parse(getBaseURL())
  }catch(e){}
  const baseUrlKeyForOperation = Object.keys(apolloOperations).find(key =>
    apolloOperations[key].includes(operationName),
  );
  if (baseUrls == null || !baseUrls[baseUrlKeyForOperation]) {
    return process.env.REACT_APP_DEFAULT_BASE_URL;
  }
  return baseUrls[baseUrlKeyForOperation];
};

const saveBaseUrls = async (authUrl, contactsUrl, activityUrl, searchUrl, notificationUrl, subscriptionBaseUrl, contactsHandlerBaseUrl) => {
  const baseUrls = {
    auth: authUrl,
    contacts: contactsUrl,
    activity: activityUrl,
    search: searchUrl,
    notification: notificationUrl,
    subscription: subscriptionBaseUrl,
    contactsHandler: contactsHandlerBaseUrl,
  }
  saveBaseURL(JSON.stringify(baseUrls))
};

const clearBaseUrls = async () => {
  const baseUrls = {
    auth: null,
    contacts: null,
    activity: null,
    search: null,
    notification: null,
    subscription: null,
    contactsHandler: null,
  }
  saveBaseURL(baseUrls)
};

const getApolloClient = () => {
  return ferretApolloClient;
};

const setApolloClient = apolloClient => {
  ferretApolloClient = apolloClient;
};

export {
  getBaseUrlFor,
  saveBaseUrls,
  clearBaseUrls,
  setApolloClient,
  getApolloClient,
};
