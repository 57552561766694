import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import Styles from './preferences.module.css'

const Preferences2 = ({
  professionalReasonsSurvey,
  setProfessionalReasonsSurvey,
}) => {
  const data = [
    {
      label: "Accounting",
      index: 1,
    },
    {
      label: "Analyst",
      index: 2,
    },
    {
      label: "Business Owner / Management",
      index: 3,
    },
    {
      label: "Finance / Banking",
      index: 4,
    },
    {
      label: "Investing",
      index: 5,
    },
    {
      label: "Legal Services",
      index: 6,
    },
    {
      label: "Real Estate",
      index: 7,
    },
    {
      label: "Sales",
      index: 8,
    },
    {
      label: "Other",
      index: 9,
    },
  ];

  return (
    <FormControl className={Styles.pref2Main}>
      <div className={`${Styles.preferencesQtn} ${Styles.preferencesQtn2}`}>Professional Use</div>
      <RadioGroup
        name="radio-buttons-group"
        value={professionalReasonsSurvey}
        onChange={(e) => {
          setProfessionalReasonsSurvey(parseInt(e.target.value))
        }}
        className={Styles.pref2radioBtn}
      >
        {data.map((d) => (
          <FormControlLabel
            key={d.index}
            value={d.index}
            control={
              <Radio
                sx={{
                  color: "#078167",
                  "&.Mui-checked": {
                    color: "#078167",
                  },
                }}
              />
            }
            label={<span className={Styles.radioBtnTxt}>{d.label}</span>}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default Preferences2;
