import React, { useEffect, useState } from "react";
import { getFilterImage } from "../../images/filterColorIcon/getFilterImage";
import Styles from "./personeInfo.module.css";
import ArticleCategoriesTooltip from "../onboardingTooltips/articleCategoriesTooltip";
import { ButtonBase } from "@mui/material";
import InfoGreen from "../../images/svg/info-green";
import { articleTooltipStore } from "../../store/articleTooltipStore";

const CardItem = (props) => {
  const { item, isClickable } = props;
  const [isVisibleTooltip, setVisibleTooltip] = useState(false);
  const isTooltipOpen = articleTooltipStore((state) => state.isTooltipOpen);
  const setTooltipOpen = articleTooltipStore((state) => state.setTooltipOpen);

  useEffect(() => {
    if (isTooltipOpen) {
      setTooltipOpen(false);
      setVisibleTooltip(false);
    }
  }, [isTooltipOpen]);

  return (
    <div className={Styles.cardDiv}>
      <div className={Styles.itemView}>
        {getFilterImage(item.category, isClickable)}
      </div>
      <div
        style={{
          whiteSpace: "pre-line",
          display: "flex",
          alignItems: "center",
        }}
      >
        <span
          className={Styles.filterLable}
          style={{
            color: !isClickable ? "#8E96A8" : "#8E96A8",
          }}
        >
          {item.category}

          <span className={`${Styles.filterLable} ${Styles.filterLableCount}`} style={{ color: isClickable ? '#8E96A8' : '#b5b9c4' }}>
            {' (' + item.count + ')'}
          </span>
          <ArticleCategoriesTooltip
            style={{display: 'inline'}}
            category={item.category}
            isVisible={isVisibleTooltip}
            setIsVisible={setVisibleTooltip}
            onClick={() => {
              setTooltipOpen(false);
              setVisibleTooltip(false);
            }}
          >
            <ButtonBase
              style={{ paddingLeft: 4 }}
              onClick={() => {
                setTooltipOpen(true);
                setTimeout(() => {
                  setVisibleTooltip(true);
                }, 100);
              }}
            >
              <InfoGreen />
            </ButtonBase>
          </ArticleCategoriesTooltip>
        </span>
      </div>
    </div>
  );
};
export default CardItem;
