import { ButtonBase } from '@mui/material';
import React from 'react';
import Styles from './recordUnavailable.module.css';
import { GreenBgButton } from '../../buttons/greenBgButton';
import iconNewWindow from "../../../images/svg/icon-new-window.svg";
import IllustrationMobileBrowser from "../../../images/svg/Illustration-Mobile-Browser.svg";
import IllustrationDesktopBrowser from "../../../images/svg/Illustration-Desktop-Browser.svg";
import { appStore } from '../../../store/appStore';

const RecordUnavailable = ({ sourceUrl, errorMsg = false, screenHeight,
  setIsSummaryExpanded, isSummaryExpanded, isExpandable, isValidUrl }) => {
  
  const dimensions = appStore((state) => state.dimensions)

  const openLink = () => {
    window.open(sourceUrl, "_blank")
  }
  return (
    <div className={Styles.container} style={{ height: screenHeight }}>
      <img
        className={Styles.image}
        src={
          errorMsg ? require('../../../images/post-article-relevancy-bot-logo.png') : (dimensions.isDesktop ? IllustrationDesktopBrowser : IllustrationMobileBrowser) 
        }
        resizeMode={'contain'}
      />
      <p className={Styles.title}>
        {errorMsg ? `We Found Your Record` : `This record is unavailable`}
      </p>
      <p className={Styles.text}>
        {errorMsg ?`Good news, we found your record outside of Ferret. You can view this record by clicking the button below.` :
          `It looks like this record may have been modified or taken offline.`}
      </p>
      {errorMsg ?
        <GreenBgButton
          className={Styles["showCacheBtn"]}
          onClick={() => { openLink() }}>
          <span className={Styles.showCacheBtnTxt} >
            View Record <img src={iconNewWindow}
              className={Styles.iconNewWindow} />
          </span>
        </GreenBgButton>
        : isExpandable ?
          <GreenBgButton
            style={{
              backgroundColor: isSummaryExpanded ? "#AAAAAA" : "#078167"
            }}
            disabled={isSummaryExpanded}
            className={Styles["showCacheBtn"]}
            onClick={() => { setIsSummaryExpanded(true) }}>
            <span className={isSummaryExpanded ? `${Styles.showCacheBtnTxt} ${Styles.showCacheBtnExpanded}` : Styles.showCacheBtnTxt} >
              Show Cache
            </span>
          </GreenBgButton>
          : (sourceUrl)?
            <GreenBgButton
              className={Styles["showCacheBtn"]}
              onClick={() => { openLink() }}>
              <span className={Styles.showCacheBtnTxt} >
                Show Source Website <img src={iconNewWindow}
                  className={Styles.iconNewWindow} />
              </span>
            </GreenBgButton>
            : <></>
      }
    </div>
  );
};

export default RecordUnavailable;
