import React from 'react';
import createStyle from './styles';
import { ButtonBase, Modal } from '@mui/material';
import CloseIconGray from '../../images/svg/close_gray';
import TickGreen from '../../images/svg/tick-green';
import QuestionMarkGrey from '../../images/svg/question-mark-grey';
import ExclamationMarkGrey from '../../images/svg/exclamation-mark-grey';
import InfoGreen from '../../images/svg/info-gray';
import Slide from '@mui/material/Slide';

const VerificationTypes = ({ setShowVerificationTypes }) => {

  const style = createStyle();

  const onBackDropPressHandler = () => {
    setShowVerificationTypes(false)
  }

  return (
    <Modal
      backdropOpacity={0.3}
      open={true}
      style={style.container}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Slide in={true} direction="up">
      <div style={style.containerData}>
          <ButtonBase style={style.crossButton} onClick={onBackDropPressHandler}>
          <CloseIconGray style={style.closeIcon} />
        </ButtonBase>
        <div style={style.containerDataText}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
            <span style={style.dialogTitle}>Verification Types</span>
            <InfoGreen />
          </div>
          <div style={{ display: "flex", flexDirection: "row", marginTop: 20, alignItems: "center", }}>
            <TickGreen />
              <span style={style.dialogSubTitle}>Verified</span>
          </div>
          <span style={style.dialogMessage}>
            Records that have matched this search according to public records, been verified by the community, or both.
          </span>

          <div style={{ display: "flex", flexDirection: "row", marginTop: 20, alignItems: "center", }}>
            <QuestionMarkGrey />
              <span style={style.dialogSubTitle}>Partial</span>
          </div>
          <span style={style.dialogMessage}>
            Records that match some of your search terms, or have inconclusive community verification.
          </span>

          <div style={{ display: "flex", flexDirection: "row", marginTop: 20, alignItems: "center", }}>
            <ExclamationMarkGrey />
              <span style={style.dialogSubTitle}>Needs Review</span>
          </div>
          <span style={style.dialogMessage}>
            Records that loosely match your search terms, such as name only match.  Use the community verification tools to improve these results by marking which records do or do not match your search.
          </span>
        </div>
        </div>
      </Slide>
    </Modal>
  );
};

export default VerificationTypes;
