import React, { useState, useEffect } from 'react';
import ferretLogoLight from '../../images/ferretLogoLight.png';

import Styles from './launchScreenTransition.module.css';

const LaunchScreenTransition = (props) => {
  return (
    <div className={ Styles.bodyLaunchScreenTransition }>

      <div>

        <img className={ Styles.logo } src={ferretLogoLight } />

        <div>
          <h5 className={ Styles.title }>ferreter <span className={ Styles.title.span }>(noun)</span></h5>
          <h6 className={ Styles.subtitle }>fer•ret•er</h6>
          <div className={ Styles.definition }><span className={ Styles.definitionNumber }>1.</span> <span className={ Styles.definitionContent }>A person who hunts with <span className={ Styles.highlight }>ferrets.</span></span></div>
          <div className={ Styles.definition }><span className={ Styles.definitionNumber }>2.</span> <span className={ Styles.definitionContent }>One who uncovers or brings to light; one who <span className={ Styles.highlight }>ferrets out</span>.</span></div>
        </div>

      </div>

    </div>
  )
}


export default LaunchScreenTransition
