import React, {useState} from 'react';
import NoResultFoundConfirmSvg from './../../images/svg/no-result-found-confirm';
import Tag from '../atoms/tag';
import Label from '../atoms/label';
import Styles from './noResultsFoundConfirm.module.css'

const NoResultsFoundConfirm = ({questions, searchName}) => {

  const parseQuestionAnswer=(questions)=>{
    let questionsArr = [];
    questions.map((q) => {
      q.answers.map((a) => {
        if (a.status === true && a.label===`I don't know `) questionsArr.push({answer: a.label, question: q.label});
        else if(a.status === true && a.label != `Provide Details`) questionsArr.push({answer: a.value, question: q.label})
      });
    });
    return questionsArr;
  }

  const QuestionAnswer = ({question, answer}) => {
    return (
      <div className={Styles.questionLabelContainer}>
        <Label text={question} />
        <span className={Styles.tag}>
          <Tag label={answer} status={true} selectable={true} />
        </span>
      </div>
    );
  };
  return (
    <div className={Styles.container}>
      <NoResultFoundConfirmSvg className={Styles.image} />
      <span className={Styles.title}>Your Search has been successfully saved!</span>
      <span className={Styles.text}>
        You will be notified when Ferret has data about
        <span className={Styles.name}> {searchName}</span> that matches the details
        you provided.
      </span>
      {parseQuestionAnswer(questions).map(({answer,question})=><QuestionAnswer
        question={question}
        answer={answer}
      />)}
    </div>
  );
};

export default NoResultsFoundConfirm;
