import React from "react";
import createStyle from "./styles";
import { getFilterImage } from "../../../images/filterColorIcon/getFilterImage";

const CheckFilter = (props) => {
  const { index, label, status, iconType, setStatus, count = "" } = props;
  const style = createStyle();
  let disabled = false;

  return (
    <div onClick={setStatus} key={index}>
      <div style={style.container}>
        {getFilterImage(label, status, iconType, null, {
          transform: "scale(.55)",
        })}
        <span
          style={
            !disabled && status
              ? { ...style.lableText, color: "#142030" }
              : { ...style.lableText }
          }
        >
          {label === "MetaSearch" ? (
            <>
              {label} <span style={{ fontSize: 'smaller', color: 'green' }}>(BETA)</span> ({count})
            </>
          ) : (
            <>
              {label} ({count})
            </>
          )}
        </span>
      </div>
      <div
        style={
          !disabled && status
            ? {
                borderBottomColor: "#078167",
                borderBottomWidth: 2,
                borderBottomStyle: "solid",
                marginLeft: 10,
              }
            : {}
        }
      ></div>
    </div>
  );
};

export default CheckFilter;
