// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".savedSearchMatch_searchTitleMatch__hDLUn {\n  font-size: 18px;\n  margin-top: 6px;\n  padding-left: 10px;\n  margin-bottom: 10px;\n  font-weight: 700;\n  margin-left: 2px;\n  font-family: \"Proxima Nova\";\n  font-style: normal;\n  letter-spacing: -0.01;\n  color: #142030;\n}\n.savedSearchMatch_mainBg__eKuye {\n  padding-top: 10px;\n  background-color: #F8FAFF;\n}\n.savedSearchMatch_mainList__bT6Fx {\n  margin-top: -10px;\n padding-bottom: 180px;\n display: flex;\n flex-direction: column;\n}", "",{"version":3,"sources":["webpack://./src/components/savedSearchMatch/savedSearchMatch.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,eAAe;EACf,kBAAkB;EAClB,mBAAmB;EACnB,gBAAgB;EAChB,gBAAgB;EAChB,2BAA2B;EAC3B,kBAAkB;EAClB,qBAAqB;EACrB,cAAc;AAChB;AACA;EACE,iBAAiB;EACjB,yBAAyB;AAC3B;AACA;EACE,iBAAiB;CAClB,qBAAqB;CACrB,aAAa;CACb,sBAAsB;AACvB","sourcesContent":[".searchTitleMatch {\n  font-size: 18px;\n  margin-top: 6px;\n  padding-left: 10px;\n  margin-bottom: 10px;\n  font-weight: 700;\n  margin-left: 2px;\n  font-family: \"Proxima Nova\";\n  font-style: normal;\n  letter-spacing: -0.01;\n  color: #142030;\n}\n.mainBg {\n  padding-top: 10px;\n  background-color: #F8FAFF;\n}\n.mainList {\n  margin-top: -10px;\n padding-bottom: 180px;\n display: flex;\n flex-direction: column;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchTitleMatch": "savedSearchMatch_searchTitleMatch__hDLUn",
	"mainBg": "savedSearchMatch_mainBg__eKuye",
	"mainList": "savedSearchMatch_mainList__bT6Fx"
};
export default ___CSS_LOADER_EXPORT___;
