import * as React from "react"
const PoliticalExposure = (props) => { 
    return(
      <svg
      width={32}
      height={32}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.987 9.333a4.667 4.667 0 1 0 0-9.333 4.667 4.667 0 0 0 0 9.333Z"
        fill={props.selected ? "#142030" :  "#b5b9c4"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.276 21.724A1.333 1.333 0 0 1 29.333 24H28a.347.347 0 0 0-.307.253l-1.68 6.667a1.335 1.335 0 0 1-1.333 1.013H7.347a1.333 1.333 0 0 1-1.334-1.013l-1.68-6.667A.347.347 0 0 0 4 24H2.667a1.334 1.334 0 0 1 0-2.667h26.666c.354 0 .693.14.943.39ZM16 29a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
        fill={props.selected ? props.iconType =="blackWhite"? "#1E1E1E": "#FB8100" : '#b5b9c4'}
      />
      <path
        d="M20.265 11.182A8.003 8.003 0 0 0 8 17.773a1.333 1.333 0 0 0 .347 1.093 1.4 1.4 0 0 0 1.04.467h2.738c.03-.037.062-.073.096-.107l8.044-8.044ZM22.255 12.963l-6.37 6.37h6.968a1.133 1.133 0 0 0 .827-.36 1.16 1.16 0 0 0 .32-.84 8.002 8.002 0 0 0-1.745-5.17Z"
        fill={props.selected ? "#142030" :  "#b5b9c4"}
      />
    </svg>
)}

export default PoliticalExposure
