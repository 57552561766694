import * as React from "react"

const SortIcon = (props) => (
  <svg
    width={18}
    height={22}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.476 3.143V1.714m0 12.857v5.715m9.048-1.429v1.429m0-12.857V1.714m-6.19 7.143a2.857 2.857 0 1 1-5.715 0 2.857 2.857 0 0 1 5.714 0Zm9.047 4.286a2.857 2.857 0 1 0-5.714 0 2.857 2.857 0 0 0 5.714 0Z"
      stroke="#FFF"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SortIcon
