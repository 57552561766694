import { styled } from "@mui/material/styles";
import LoadingButton from '@mui/lab/LoadingButton';

export const GoogleBtn = styled(LoadingButton)((props) => ({
  fontFamily: 'Proxima Nova',
  fontWeight: 'normal',
  alignItems: "center",
  textAlign: "center",
  color: "#FFFFFF",
  backgroundColor: "#FFFFFF",
  borderRadius: 6,
  border: 1,
  borderStyle: "solid",
  borderColor: "#C0C7D6",
  padding: '15px',
  fontSize: '16px',
  lineHeight: '19px',
  '&:hover': {
    backgroundColor: '#FFFFFF',
    color: '#FFFFFF',
  }
}));
