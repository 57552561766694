import * as React from "react"

const ChevronDropdownIcon = (props) => (
  <svg
    width={18}
    height={16}
    fill="none"
    {...props}
  >
    <path
      d="M1.571 4.4 8.6 11.427a.549.549 0 0 0 .8 0l7.029-7.029"
      stroke="#91CB6C"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ChevronDropdownIcon
