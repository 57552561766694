import React from "react";
import { GreenBgButton } from "../../../components/buttons/greenBgButton";
import { Modal } from "@mui/material";
import starredContacts from "../../../images/starred-contacts-Illustration.png";
import CloseIconWhite from "../../../images/svg/closeWhite";
import Styles from "./starredContact.module.css";

const StarredContactModal = (props) => {
  const { onContinuePress } = props;

  return (
    <Modal
      open={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={Styles.containerMain}>
        <div className={Styles.container}>
          <div className={Styles.closeView}>
            <div onClick={onContinuePress}>
              <CloseIconWhite
                color="#697080"
                style={{ width: 14, height: 14, margin: 20 }}
              />
            </div>
          </div>
          <span className={Styles.headerTitle}>{"Contact Notifications"}</span>
          <div className={Styles.content}>
            <img src={starredContacts} className={Styles.mainIcon} />
            <span className={Styles.textQuestion}>
              {"Select Contacts To Automatically Check for Updates"}
            </span>
            <span className={Styles.textSimple}>
              {"Put a star next to the name of the contacts with "}
              <span className={Styles.textSimpleGreen}>
                {"whom you wish to be notified about, in real-time"}
              </span>
              {", when there are updates."}
            </span>
            <div className={Styles.button}>
              <GreenBgButton
                padding={"10px 40px 10px 40px"}
                loading={false}
                onClick={onContinuePress}
                variant="contained"
              >
                <div className={Styles.btnTxt}>Continue</div>
              </GreenBgButton>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default StarredContactModal;
