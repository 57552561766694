import React from 'react'
import { Snackbar, Alert, Slide } from "@mui/material";

const PushNotificationError = ({ setShowPushNotification }) => {

  function handleOnClose() {
    setShowPushNotification({ showAlert: false, showError: false })
  }

  return (
    <>
      <Snackbar open={true}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }}
        TransitionComponent={Slide}
        autoHideDuration={6000}
        onClose={handleOnClose}>
        <Alert severity="error" sx={{ width: '100%' }}>
          Notifications are blocked. Please open your browser preferences or click the lock near the address bar to change your notification preferences.
        </Alert>
      </Snackbar>
    </>
  )
}

export default PushNotificationError;