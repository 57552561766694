import * as React from "react"
export default class EditPencil extends React.Component {
    render() {
      return (
        <svg
          width={this.props?.style?.width ?? "12"}
          height={this.props?.style?.height ?? "12"}
          fill="none"
          viewBox="0 0 12 12"
          {...this.props}>
      <path
        stroke="#078167"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M4.573 9.743l-3.214.9.9-3.214 5.886-5.857a.714.714 0 011.021 0l1.264 1.271a.714.714 0 010 1.014L4.573 9.743z"
      ></path>
    </svg>
      )
    }
}
