import React, { useState } from 'react';
import { IllustrationNotificationsIcon } from "../../images/svg/illustration_notifications_icon";
import createStyle from './styles';
import { ButtonBase } from '@mui/material';

const NotificationBanner = ({ onOpenNotification, isTooltip = false }) => {
  const style = createStyle();
  return (
    <div className="shadow" style={isTooltip ? { ...style.viewBanner, paddingLeft: 16, paddingRight: 16, paddingTop: 16, marginTop: 16,paddingRight: 16 } : { ...style.viewBanner, paddingLeft: 16, paddingRight: 16, paddingTop: 16, marginTop: 16 }}>
      <div style={{ display: "flex", justifyContent: 'space-between', flexDirection: 'row' }}>
        <div style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
          <span style={style.title}>You Are Up To Date!</span>
          <span style={style.detailsTitle}>You have read all of your previous notifications.</span>
          <ButtonBase onClick={onOpenNotification}>
            <span style={style.buttonNotifi}>Show Past Notifications</span>
          </ButtonBase>
        </div>
        <IllustrationNotificationsIcon />
      </div>
    </div>
  )
}

export default NotificationBanner;
