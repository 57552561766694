import Avatar from "@mui/material/Avatar";
import DefaultAvatarProfile from "../../../images/svg/default-avatar-profile";
import { useEffect, useState } from "react";

export default function ProfilePicture({
  profilePhoto,
  avatarStyle,
  onClick,
  isDarkPic,
}) {

  const [avatarBitmap, setAvatarBitmap] = useState(null);

  const isValidURL = (url) => {
    return /^(http|https):\/\//.test(url);
  };

  useEffect(() => {
    setAvatarBitmap(profilePhoto); // Set initial URL

    try {
      if (profilePhoto && isValidURL(profilePhoto)) {
        const avatarImage = new Image();
        avatarImage.crossOrigin = 'anonymous';

        const handleDownload = () => {
          console.log('onDownload=====')
          const canvas = document.createElement('canvas');
          canvas.width = avatarImage.width;
          canvas.height = avatarImage.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(avatarImage, 0, 0);
          const dataURL = canvas.toDataURL('image/jpeg');
          console.log('onDownload=====dataURL==', dataURL)
          setAvatarBitmap(dataURL);
        };

        avatarImage.onload = () => {
          setAvatarBitmap(profilePhoto); // Set URL once image is loaded
        };
        avatarImage.src = profilePhoto;
        avatarImage.addEventListener('load', handleDownload); // Listen for download
        return () => {
          avatarImage.removeEventListener('load', handleDownload); // Cleanup
        };
      }
    } catch (e) { }

  }, [profilePhoto]);

  return (
    <>
      {avatarBitmap ? (
        <Avatar
          src={avatarBitmap}
          onClick={onClick}
          sx={avatarStyle}
          imgProps={{ referrerPolicy: "no-referrer" }}
        >
          {isDarkPic ? (
            <DefaultAvatarProfile style={avatarStyle} />
          ) : (
            <Avatar
              src="/broken-image.jpg"
              onClick={onClick}
              sx={avatarStyle}
            />
          )}
        </Avatar>
      ) : isDarkPic ? (
        <DefaultAvatarProfile style={avatarStyle} />
      ) : (
        <Avatar src="/broken-image.jpg" onClick={onClick} sx={avatarStyle} />
      )}
    </>
  );
}
