import * as React from "react"

const SearchFiter = (props) => (
  <svg
    width={18}
    height={17}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m16.428 15.929-4.285-4.286m1.817-4.377a6.194 6.194 0 1 1-12.389 0 6.194 6.194 0 0 1 12.389 0Z"
      stroke="#8E8E93"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SearchFiter
