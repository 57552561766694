class Variables {
  static navigateRef;
  static isAddSearches = false; // change to true if you want to refresh saved searched
  static isSearchMeChanged = false;
  static isRefreshClosestContacts = false;
  static isFirstTimeAnimation = true;
  static isOpenLogin = false;
  static navigationStackNameForContacts = false;
  static isContactImported = false
  static filterOnBoarding = false
  static fromInTheNews = false;
  static articleIdMarkNo = false;
  static savedSearchId;
  static metaDataSeparator = ';'; // -> ', '
  static metaDataUISeparator = ', '; // <- ';'
  static savedSearchFilter = false
  static newAddHocAdded = false
  static savedRelevancyContactId = ""
  static potentialMatchId = ""
  static contactId = ""
  static isFromLandingpage = false
  
  //Default filter option
  static statusesOfFilters = null;
  static modalFilterVisible = true;
  static riskLevelsStatuses = {
    high: true,
    medium: true,
    low: true,
  };
  //
  static riskSelected = false;
  static tracersCategory = ['BUSINESS', 'DEBT',  'COURT', 'CRIMINAL', 'NEWS'] //NEWS for testing only
  // Feature Toggle Settings
  static linkedin_enabled = false;
  static all_activity_enabled = true;
  static subscriptions_enabled = true;
  static info_summary_enabled= true;
  static potential_match_tags_enabled= true;
  static saved_searches_tags_enabled= true;
  static registration_enabled = true
}
export default Variables;
