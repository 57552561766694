import React from 'react';
import confirmNavigateToContacts from '../../images/confirmNavigateToContacts.png'
import { Modal } from '@mui/material';
import Slide from '@mui/material/Slide';
import createStyle from './styles';
import { GreenBgButton } from '../buttons/greenBgButton';
import Styles from './alertPopup.module.css'

const AlertPopup = (props) => {
  const {
    isVisible=false,
    onBackdropPress,
  } = props;

  const style = createStyle();

  return (
    <Modal
      open={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      >
        <Slide in={true} direction="up">
          <div className={Styles.containerMain}>
            <div className={Styles.container}>
              <div style={style.content}>
                <img src={confirmNavigateToContacts} style={style.mainIcon} />
                <span style={style.textQuestion}>{'There’s nothing here'}</span>
                <span style={style.textSimple}>{'As you view articles they’ll appear here so you can find them again easily.'}</span>
                
                 <div style={style.buttonContainer}>
                  <GreenBgButton
                    loading={false}
                    onClick={onBackdropPress}
                    fullWidth
                    variant="contained"
                    >
                      <div style={style.btTitle}>Back to My Dashboard</div>
                  </GreenBgButton>
                </div>
              </div>
            </div>
          </div>
        </Slide>
    </Modal>
  );
};

export default AlertPopup;