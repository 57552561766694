import React, { useEffect, useState, useRef, useContext } from "react";
import {
  OnboardingContext,
  OnboardingStates,
} from "../../../contexts/onboardingContext";
import { UserContext } from "../../../contexts/userContext";
import PhoneIcon from "../../images/icons/forms/phone.svg";
import CountryIcon from "../../images/icons/forms/country.svg";
import ResetIcon from "../../images/icons/buttons/reset.svg";
import { useNavigate } from "react-router-dom";
import formatUserName from "../../../utils/formatUserName";
import Styles from "./SearchFrom.module.css";
import Iso3166 from "iso-3166-2";
import SearchSelect from "./searchSelect";
import RecentSearch from "../recentSearch/recentSearch";
import {
  Question,
  optionsToAnswers,
} from "../../../components/searchModalV2/Question";
import useQuestions from "../../../components/searchModalV2/useQuestions";
import {
  setAnswerStatus,
  setValueToOhetAnswer,
  getCompleteSearchQuery,
} from "../../../components/searchModalV2/questionsController";
import {
  mainQuestion,
  person,
  company,
} from "../../../components/searchModalV2/questionsAnswers";
import { capitalizeCase, validateNameHasTwoWords } from "../../../utils/utils";
import SearchParams from "../../components/searchParams/searchParams";
import { searchFormStore } from "../../../store/searchFormStore";
import QuestionInterface from "../../../components/searchModalV2/questionInterface";
import DisclaimerModal from "../../../components/disclaimer/disclaimerModal";
import { UPDATE_USER_DISCLAIMER } from "../../../queries/queries";
import { useMutation } from "react-apollo";
import AdvancedSearchTooltip3 from "../../../components/onboardingTooltips/advancedSearchTooltip/advancedSearchTooltip3";
import AdvancedSearchTooltip2 from "../../../components/onboardingTooltips/advancedSearchTooltip/advancedSearchTooltip2";
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import SingleBtnAlert from "../../../components/alert/singleBtnAlert";

/**
 * SearchForm Component
 * @returns {JSX.Element}
 * @constructor
 */
const SearchForm = (props) => {
  const navigate = useNavigate();
  const searchRef = useRef();
  const phoneRef = useRef();
  const countryRef = useRef();
  const cityRef = useRef();
  const stateRef = useRef();
  const ageRef = useRef();
  const { user, setUser } = useContext(UserContext);
  const advancedSearchOnboardingDone = user.advancedSearchOnboardingDone;
  const [showAdvancedSearchTooltip2, setShowAdvancedSearchTooltip2] =
    useState(false);
  const [showAdvancedSearchTooltip4, setShowAdvancedSearchTooltip4] =
    useState(false);

  const setSearchFormData = searchFormStore((state) => state.setSearchFormData);
  const searchFormData = searchFormStore((state) => state.searchFormData);
  const setSavedSearchData = searchFormStore(
    (state) => state.setSavedSearchData
  );
  const progressStatus = searchFormStore((state) => state.progressStatus);
  const setEditSearchComplete = searchFormStore(
    (state) => state.setEditSearchComplete
  );
  const openEditSearch = searchFormStore((state) => state.openEditSearch);
  const setOpenEditSearch = searchFormStore((state) => state.setOpenEditSearch);

  const [showSingleBtnAlert, setShowSingleBtnAlert] = useState({
    status: false,
    msg: "",
  });

  const phoneRegExp =
    /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

  const {
    fromBackSearch = false,
    isRecentSearch = true,
    buttonText = props?.isEdit ? "Complete Search" : "Proceed with Search",
    mPhoneNumberInput,
    mCityInput,
    mStateInput,
    mAgeInput = "",
    mQueryData,
    mSavedTracerQueryId,
    mSavedTahoeId,
    recentSearchId,
    searchSettings: {
      initialSearchString = "",
      qaMetaData = {},
      initialSearchParams = [],
      initialQuestions = [],
      initialPotentialMatch = [],
    } = {},
  } = props;

  const regex = /[!@#$%^&*()_=+{}\[\]:;<>,?~\\/"]/g;
  const mCountryInput =
    props?.isEdit || fromBackSearch
      ? props?.mCountryInput && props?.mCountryInput != ""
        ? props.mCountryInput
        : ""
      : "United States";

  const {
    setQuestions,
    questions,
    addPotentialMatches,
    addMultipleNewQuestion,
    getSearchParams,
  } = useQuestions(initialQuestions, initialPotentialMatch);

  const [showDisclaimer, setDisclaimerVisible] = useState(false);
  const [searchString, setSearchString] = useState(
    initialSearchString
      ? formatUserName(initialSearchString.trim())
      : searchFormData?.name ?? ""
  );
  const [phoneNumberInput, setPhoneNumber] = useState(
    mPhoneNumberInput ?? searchFormData?.phone ?? ""
  );
  const [countryInput, setCountry] = useState(
    mCountryInput ?? searchFormData?.country ?? ""
  );
  const [cityInput, setCity] = useState(
    mCityInput ?? searchFormData?.city ?? ""
  );
  const [stateInput, setState] = useState(
    mStateInput ?? searchFormData?.state ?? ""
  );
  const [ageInput, setAge] = useState(mAgeInput ?? searchFormData?.age ?? "");

  const [searchArticleVisible, onHideSearchArticle] = useState(isRecentSearch);
  const [isClearActive, setCleanActive] = useState();
  const [searchName, setsearchName] = useState();
  const [searchParams, setSearchParams] = useState(initialSearchParams);
  const [irrelevantArticleId, setIrrelevantArticleId] =
    useState(recentSearchId);
  const [searchCompleted, setSearchCompleted] = useState(false);
  const [clickedPotential, setClickedPotential] = useState(false);
  const [clickedSavedSearch, setClickedSavedSearch] = useState(false);
  const [savedTahoeId, setSavedTahoeId] = useState(mSavedTahoeId);
  const [savedTracerQueryId, setSavedTracerQueryId] =
    useState(mSavedTracerQueryId);
  const [queryData, setQueryData] = useState(mQueryData);
  const [metaData, setMetaData] = useState(qaMetaData);
  const [submitButtonText, setButtonText] = useState(buttonText);
  const [submitButtonDisabled, setButtonDisabled] = useState(false);
  const [errorPhone, setErrorPhone] = useState(false);
  const { onboardingState, setOnboardingState } = useContext(OnboardingContext);

  useEffect(() => {
    if (isClearActive && !clickedPotential && !clickedSavedSearch)
      restartStates(true, false);
  }, [phoneNumberInput, cityInput, countryInput, ageInput, searchString]);

  useEffect(() => {
    setShowAdvancedSearchTooltip2(
      !advancedSearchOnboardingDone &&
      !showDisclaimer &&
      onboardingState === OnboardingStates.completed
    );
  }, [advancedSearchOnboardingDone, showDisclaimer, onboardingState]);

  useEffect(() => {
    if (
      isClearActive &&
      stateInput != null &&
      !clickedPotential &&
      !clickedSavedSearch
    )
      restartStates(true, false);
  }, [stateInput]);


  useEffect(() => {
    if (questions && questions?.length) {
      let name = searchString.trim()
      setSearchParams(
        getSearchParams(
          searchName ?? name,
          null,
          phoneNumberInput,
          countryInput,
          cityInput,
          stateInput,
          ageInput
        )
      );
    }
  }, [questions]);

  useEffect(() => {
    if (
      initialQuestions?.[0]?.identifier === "form" ||
      initialQuestions?.[1]?.identifier === "form" ||
      initialQuestions?.[0]?.identifier === "noResultFound_SearchType"
    ) {
      setButtonText("Save Search");
    }

    setTimeout(() => {
      setCleanActive(true);
    }, 200);
  }, []);

  const restartStates = (forceReset = false, isResetField = true) => {
    if (isResetField) {
      setSearchString(
        forceReset
          ? ""
          : initialSearchString
            ? formatUserName(initialSearchString.trim())
            : ""
      );
      setPhoneNumber("");
      setCountry("United States");
      setCity("");
      setState("");
      setAge("");
      setClickedSavedSearch(false);
      setMetaData(forceReset ? {} : qaMetaData);
      setIrrelevantArticleId();
    }
    addPotentialMatches([]);
    setsearchName();
    setSearchParams(forceReset ? [] : initialSearchParams);
    setQuestions(forceReset ? [] : initialQuestions);
    setSearchCompleted(false);
    setClickedPotential(false);
    setButtonText(forceReset ? "Proceed with Search" : buttonText);
    setButtonDisabled(false);
    setQueryData();
    setSavedTahoeId();
    setSavedTracerQueryId();
  };

  const focusSearchBar = (data) => {
    const { updated = 0 } = data; // updated => fieldType
    switch (updated) {
      case 0:
        searchRef?.current?.focus();
        return;
      case 1:
        phoneRef?.current?.focus();
        return;
      case 2:
        countryRef?.current?.focus();
        countryRef?.current?.handleOpen();
        return;
      case 3:
        cityRef?.current?.focus();
        return;
      case 4:
        stateRef?.current?.focus();
        stateRef?.current?.handleOpen();
        return;
      case 5:
        ageRef?.current?.focus();
        return;
    }
  };

  const searchData = (
    searchTerm,
    resp,
    id,
    irrelevantArticles,
    contact,
    searchType,
    tracerQueryId,
    tahoeId,
    searchContext,
    searchCompleted,
    age,
    akaList,
    eventType
  ) => {
    if (progressStatus) return;

    if (!validateNameHasTwoWords(searchTerm)) {
      setShowSingleBtnAlert({
        status: true,
        msg: "Please enter First name and Last name in the search",
      });
    } else {
      if (
        window.location.pathname !== "/search" &&
        window.location.pathname !== "/SearchResultsWithQuery"
      )
        navigate("/search");

      setSavedSearchData({
        searchTerm,
        resp,
        id,
        irrelevantArticles,
        contact,
        searchType,
        tracerQueryId,
        tahoeId,
        searchContext,
        searchCompleted,
        akaList,
        eventType,
        age,
      });
    }

    if (searchContext) {
      setSearchString(searchTerm ? formatUserName(searchTerm.trim()) : "");
      setPhoneNumber(searchContext?.phone ?? "");
      setCountry(searchContext?.country ?? "");
      setCity(capitalizeCase(searchContext?.city ?? ""));
      setState(searchContext?.state ?? "");
      setAge(searchContext?.age?.toString() ?? "");
    }
  };

  useEffect(() => {
    if (searchFormData) {
      setSearchString(
        initialSearchString
          ? formatUserName(initialSearchString.trim())
          : searchFormData?.name ?? ""
      );
      setPhoneNumber(searchFormData.phone ?? "");
      setCity(searchFormData.city);
      setAge(searchFormData.age);
      setCountry(searchFormData.country);
      setState(searchFormData.state);
    }
  }, [searchFormData]);

  useEffect(() => {
    if (props?.isEdit) {
      setPhoneNumber(mPhoneNumberInput);
      setCity(mCityInput);
      setAge(mAgeInput);
      setCountry(mCountryInput);
      setState(mStateInput);
    }
  }, [mPhoneNumberInput, mCityInput, mCountryInput, mAgeInput, mStateInput]);

  const onPressAnswerHandler = (indexOfQuestion) => (indexOfAnswer) => {
    const question = questions[indexOfQuestion];
    const oldQuestion = question?.answers[indexOfAnswer];
    if (question && oldQuestion) {
      let newQuestions = setAnswerStatus(
        questions,
        indexOfQuestion,
        indexOfAnswer,
        !oldQuestion.status,
        question?.identifier === "next_attribute" ||
        question?.identifier === "potential_matches" ||
        question?.identifier === "entity_select" ||
        question?.identifier === "noResultFound_details" ||
        question?.identifier === "noResultFound_SearchType" ||
        question?.identifier === "noResultFound_Company_Called" ||
        question?.identifier === "noResultFound_Company_Based" ||
        question?.identifier === "noResultFound_Person_Live" ||
        question?.identifier === "noResultFound_Person_Live_Work",
        question?.identifier === "next_attribute" ||
        question?.identifier === "potential_matches" ||
        question?.identifier === "entity_select" ||
        question?.identifier === "noResultFound_details" ||
        question?.identifier === "noResultFound_SearchType"
      );
      if (question?.identifier === "noResultFound_details") {
        if (!oldQuestion.status) {
          let mNewQuestion = [];
          newQuestions.forEach((item) => mNewQuestion.push(item));
          if (oldQuestion?.label === "Provide Details") {
            setButtonDisabled(true);
            setButtonText("Save Search");
            const {
              question: label,
              options,
              attribute,
              meta_data = "{}",
              potentialMatches = null,
            } = mainQuestion;
            const answers = options.map(optionsToAnswers);
            const newQuestion = Question(
              attribute,
              label,
              answers,
              JSON.parse(meta_data),
              potentialMatches
            );
            mNewQuestion.push(newQuestion);
          } else {
            setButtonDisabled(false);
            setButtonText("Close");
          }
          addMultipleNewQuestion(mNewQuestion, false);
        } else {
          setButtonDisabled(true);
          setButtonText("Save Search");
          setQuestions(newQuestions);
        }
      } else if (question?.identifier === "noResultFound_SearchType") {
        setButtonDisabled(oldQuestion.status);
        if (!oldQuestion.status) {
          let mNewQuestion = [];
          newQuestions.forEach((item) => mNewQuestion.push(item));
          if (oldQuestion?.label === "Person") {
            person.forEach((item) => {
              const { question: label, options, attribute } = item;
              const answers = options.map(optionsToAnswers);
              mNewQuestion.push(Question(attribute, label, answers));
            });
          } else if (oldQuestion?.label === "Company") {
            company(searchString?.trim()).forEach((item) => {
              const { question: label, options, attribute } = item;
              const answers = options.map(optionsToAnswers);
              mNewQuestion.push(Question(attribute, label, answers));
            });
          }
          addMultipleNewQuestion(mNewQuestion, false);
        } else {
          setQuestions(newQuestions);
        }
      } else {
        setQuestions(newQuestions);
      }
    }
  };

  const onEditingOtherInputHandler = (indexOfQuestion) => (value) => {
    const updatedQuestions = setValueToOhetAnswer(
      questions,
      indexOfQuestion,
      value
    );
    setQuestions(updatedQuestions);
  };

  const onSubmitEditingOtherInputHandler =
    (indexOfQuestion) => (indexOfAnswer) => { };

  const onSubmitModalHandler = () => {
    let name = searchString.trim()
    if (!validateNameHasTwoWords(name)) {
      setShowSingleBtnAlert({
        status: true,
        msg: "Please enter First name and Last name in the search",
      });
      return;
    }

    if (window.location.pathname == "/articles") navigate(-1);

    const data = {
      phone: phoneNumberInput,
      city: cityInput,
      country: countryInput,
      state: stateInput,
      age: ageInput,
      name: name,
      search: false,
    };
    setSearchFormData(data);
    let queryParms = getCompleteSearchQuery(
      questions,
      metaData,
      queryData,
      savedTahoeId,
      savedTracerQueryId
    );
    setEditSearchComplete({
      queryParms,
      questions,
      name,
      recentSearchId,
    });
  };

  const onClickSearchParamHandler = (data) => {
    if (data.isEditable) focusSearchBar(data);
    else {
      onPressAnswerHandler(data.indexOfQuestion)(data.indexOfAnswer);
    }
  };

  const countries = Object.values(Iso3166.data)
    .map(({ name }) => ({
      label: name,
      value: name,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  // Add main countries to the top of the list, keeping the order defined on this array:
  const highlightedCountries = [
    "United States",
    "Canada",
    "Australia",
    "India",
  ];
  highlightedCountries.reverse().forEach((c) => {
    // Get index of existing
    let idx = countries.findIndex(
      (existingCountry) => existingCountry.label === c
    );

    if (idx !== -1) {
      // Remove it from its original position
      countries.splice(idx, 1);

      // Add it to the top of the list:
      countries.unshift({
        label: c,
        value: c,
      });
    }
  });

  countries.splice(0, 0, { label: "Country of work or residence", value: "" });

  const getCountryProvinces = (country) => {
    if (country == "")
      return [{ label: "State", value: "", inputLabel: "State" }];
    const countryData = Object.values(Iso3166.data).find(
      (c) => c.name == country
    );
    if (!countryData || !countryData.sub) return [];
    const states = Object.keys(countryData.sub)
      .map((key) => ({
        label: countryData.sub[key].name,
        value: countryData.sub[key].name,
        inputLabel:
          key?.split("-")[0] == "US"
            ? key?.split("-")[1]
            : countryData.sub[key].name,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    states.splice(0, 0, { label: "State", value: "", inputLabel: "State" });

    return states;
  };

  useEffect(() => {
    if (phoneNumberInput && phoneNumberInput.length > 0) {
      if (phoneNumberInput.match(phoneRegExp)) {
        setErrorPhone(false);
      } else {
        setErrorPhone(true);
      }
    } else {
      setErrorPhone(false);
    }
  }, [phoneNumberInput]);

  const handlePhoneNumberChange = (value) => {
    // Parse the phone number using libphonenumber-js
    const parsedPhoneNumber = parsePhoneNumberFromString(value, 'ZZ');

    // Get the national significant number
    const cleanedPhoneNumber = parsedPhoneNumber ? parsedPhoneNumber.nationalNumber : value;

    // You can use the cleanedPhoneNumber as needed
    return cleanedPhoneNumber
  };

  const onPhoneNumnerChange = (value) => {
    var cleaned = handlePhoneNumberChange(value);
    let length = cleaned?.length;
    if (length > 10) {
      cleaned = cleaned.slice(length - 10, length);
    }
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "",
        number = [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
          ""
        );
      return number;
    }
    return cleaned;
  };

  const onSearch = () => {
    let name = searchString.trim()
    if (!validateNameHasTwoWords(name)) {
      setShowSingleBtnAlert({
        status: true,
        msg: "Please enter First name and Last name in the search",
      });
      return;
    }

    if (
      window.location.pathname !== "/search" &&
      window.location.pathname !== "/SearchResultsWithQuery"
    )
      navigate("/search");


    const data = {
      phone: phoneNumberInput,
      city: cityInput,
      country: countryInput,
      state: stateInput,
      age: ageInput,
      name: name,
      search: true,
    };
    setSearchFormData(data);
  };

  const [updateUserDisclaimer, { loading: userDisclaimerloading }] =
    useMutation(UPDATE_USER_DISCLAIMER, {
      fetchPolicy: "no-cache",
      onCompleted: ({ updateUserDisclaimer }) => {
        if (updateUserDisclaimer && updateUserDisclaimer.status === 200) {
          const updateUserInfo = { ...user, isDisclaimerAccepted: true };
          setUser(updateUserInfo);
          setDisclaimerVisible(false);
        }
      },
    });

  const disclaimerAccept = () => {
    setDisclaimerVisible(false);
    updateUserDisclaimer({
      variables: {
        data: {
          isDisclaimerAccepted: true,
        },
      },
    });
    openEditSearch && searchParams.length > 0
      ? onSubmitModalHandler()
      : onSearch();
  };

  const disclaimerReject = () => {
    setDisclaimerVisible(false);
  };

  const disclaimerValidate = () => {
    if (user.isDisclaimerAccepted) {
      openEditSearch && searchParams.length > 0
        ? onSubmitModalHandler()
        : onSearch();
    } else {
      setDisclaimerVisible(!user.isDisclaimerAccepted);
    }
  };

  const onReset = () => {
    if (openEditSearch) {
      setOpenEditSearch(false);
    } else {
      setPhoneNumber("");
      setCity("");
      setCountry("United States");
      setState("");
      setAge("");
      setSearchString("");
    }
  };


  const onChangeSearch = (data) => {
    setSearchString(data.replace(regex, ''))
  }


  /**
   * Main render method
   */
  return (
    <>
      <h2>{openEditSearch ? "Edit Search" : "Search"}</h2>

      <div
        className="mt-4"
        action=""
        style={{
          height: "90%",
          overflowY: "scroll",
          backgroundColor: "#eff1f5",
          paddingBottom: !openEditSearch && searchArticleVisible ? 0 : 100,
        }}
      >
        <div className="form-control mb-4">
          <label htmlFor="search-name">Name</label>
          <input
            style={{ textTransform: "capitalize" }}
            ref={searchRef}
            name="search-name"
            id="search-name"
            type="text"
            value={capitalizeCase(searchString, true)}
            onChange={(data) => onChangeSearch(data?.target?.value)}
          />
        </div>

        {
          <div className="form-control form-control--icon mb-4">
            <img src={PhoneIcon} alt="Phone" />
            <label htmlFor="search-phone">Phone</label>
            <input
              ref={phoneRef}
              className={errorPhone && Styles.phoneLable}
              name="search-phone"
              id="search-phone"
              type="tel"
              value={phoneNumberInput}
              onChange={(data) =>
                setPhoneNumber(onPhoneNumnerChange(data?.target?.value))
              }
            />
          </div>
        }

        <div className="form-control form-control--icon mb-4">
          <label htmlFor="search-country">Country</label>
          <SearchSelect
            ref={countryRef}
            setValue={setCountry}
            icon={CountryIcon}
            value={countryInput}
            defaultValue={"United States"}
            label="Country"
            options={countries}
            setState={setState}
          />
        </div>

        <AdvancedSearchTooltip3
          showAdvancedSearchTooltip3={showAdvancedSearchTooltip4}
          setShowAdvancedSearchTooltip3={setShowAdvancedSearchTooltip4}
          isWeb={true}
        >
          {
            <div className="form-control mb-4">
              <label htmlFor="search-state">State</label>
              <SearchSelect
                ref={stateRef}
                setValue={setState}
                value={stateInput}
                lableKey={"inputLabel"}
                defaultValue={""}
                label="Select"
                type="state"
                country={countryInput}
                options={getCountryProvinces(countryInput)}
              />
            </div>
          }
        </AdvancedSearchTooltip3>

        <AdvancedSearchTooltip2
          showAdvancedSearchTooltip2={showAdvancedSearchTooltip2}
          setShowAdvancedSearchTooltip2={setShowAdvancedSearchTooltip2}
          setShowAdvancedSearchTooltip3={setShowAdvancedSearchTooltip4}
          advancedSearchOnboardingDone={advancedSearchOnboardingDone}
          isWeb={true}
        />
        {openEditSearch && questions && questions.length > 0 && (
          <>
            <span className={Styles.lableRefineTXT}>Refine Your Search</span>
            <div className={Styles.qaView}>
              {questions.map((question, indexOfQuestion) => {
                const { identifier, label, answers } = question;
                return (
                  <>
                    {identifier != "potential_matches" &&
                      identifier != "noResultFound_details" && (
                        <QuestionInterface
                          isAutoFocus={true}
                          isWeb={true}
                          identifier={identifier}
                          key={`${identifier}-${label}`}
                          question={label}
                          answers={answers}
                          onSelectAnswer={onPressAnswerHandler(indexOfQuestion)}
                          onEditingOther={onEditingOtherInputHandler(
                            indexOfQuestion
                          )}
                          onSubmitEditingOther={onSubmitEditingOtherInputHandler(
                            indexOfQuestion
                          )}
                        />
                      )}
                  </>
                );
              })}
            </div>
          </>
        )}

        {openEditSearch && (
          <div className="pb-[100px]">
            <SearchParams
              isVisible={searchParams.length > 0}
              searchParams={searchParams}
              isWeb={true}
              onClickSearchParam={onClickSearchParamHandler}
            />
          </div>
        )}

        <div
          className={
            openEditSearch
              ? Styles.editButtonView
              : "flex items-center justify-between gap-4"
          }
        >
          <button className="w-1/2 btn btn--icon" onClick={onReset}>
            {!openEditSearch && <img src={ResetIcon} alt="Reset" />}
            {openEditSearch ? "Cancel" : "Reset"}
          </button>
          <button
            className="w-1/2 btn btn--primary"
            disabled={submitButtonDisabled || searchString?.trim().length == 0}
            onClick={disclaimerValidate}
            style={
              submitButtonDisabled || searchString?.trim().length == 0
                ? {
                  backgroundColor: "#aaaaaa",
                  borderColor: "#aaaaaa",
                  padding: 10,
                }
                : { padding: 10 }
            }
          >
            {openEditSearch && searchParams.length > 0
              ? "Complete Search"
              : "Search"}
          </button>
        </div>

        {!openEditSearch && searchArticleVisible ? (
          <RecentSearch searchData={searchData} />
        ) : null}

      </div>


      {showDisclaimer && (
        <DisclaimerModal
          onBackdropPress={disclaimerReject}
          onContinuePress={disclaimerAccept}
        />
      )}

      {showSingleBtnAlert.status == true && (
        <SingleBtnAlert
          description={showSingleBtnAlert.msg}
          btnTxt="OK"
          onBtnClick={() => {
            setShowSingleBtnAlert({ status: false, msg: "" });
          }}
        />
      )}

    </>
  );
};

// Component export
export default SearchForm;
