const { innerWidth: windowWidth, innerHeight: windowHeight } = window

const createStyle = () => {
  const titleTextColor = '#1A233B';
  const paragraphTextColor = '#222F4B';
  return {
    container: {
      display: "flex",
      flexDirection: "column",
      marginLeft: 16,
      marginRight: 16,
      flex: 1,
      marginTop: 10,
    },
    header: {
      backgroundColor: "#f9faff",
      width: "100%",
      display: "flex",
      alignItems: "center",
      position: "relative"
    },
    backgroundImage: {
      position: 'absolute',
    },
    textQuestion: {
      color: titleTextColor,
      fontFamily: 'Proxima Nova',
      fontSize: 24,
      marginLeft: 10,
      marginRight: 10,
      fontWeight: 'bold',
      textAlign: 'center',
      marginBottom: 10,
    },
    textSimple: {
       marginLeft: 10,
      marginRight: 10,
      color: paragraphTextColor,
      fontFamily: 'Proxima Nova',
      fontSize: 16,
      textAlign: 'center',
      marginTop: 10
    },
    logo: {
      resizeMode: 'center',
      alignSelf: 'center',
      marginTop: 30,
    },
    separator: {
      marginTop:  5,
    },
    resendTextBlock: {
      padding: 10,
      paddingTop: 15,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    focusStyle: {
      borderRadius: 8,
      borderWidth: 3,
      borderStyle: "solid",
      borderColor: "#3CB371",
      outline: "none",
      boxShadow: "",
      shadowColor: '#000',
      shadowOpacity: 1,
      shadowRadius: 10,  
      elevation: 5,
      boxShadow: "1px 3px 1px #3CB371"
    },
    roundedTextInput: {
      backgroundColor: '#F5FCFF',
      borderRadius: 8,
      borderWidth: 2,
      borderColor: "#C0C7D6",
      borderStyle: "solid",
      margin: 4,
      fontSize: 18,
      padding: 8,
      width: "2.4rem",
      color: "#000000",
      fontWeight: "bold",
      fontFamily: "Proxima Nova",
      boxShadow: "1px 3px 1px #9E9E9E"
    },
    resendQuestion: {
      fontSize: 16,
      textAlign: 'center',
      fontFamily: 'Proxima Nova',
      color: paragraphTextColor,
    },
    resendText: {
      marginLeft: 4,
      fontSize: 16,
      textAlign: 'center',
      fontFamily: 'Proxima Nova',
      color: '#078167',
    },
    formContainer: {
      marginTop:  5,
    },
    buttonHeight: {
      height:  40,
    },
    activityIndicator: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    errorMessage: {
      marginTop: 15,
      marginLeft: 16,
      color: "#FF2A3D",
    },
    inputHeight: {
      height: 40,
      width: 40,
      borderRadius: 3,
    },
    otpContainer: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    buttonWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      width: '60%',
    },
    textInput: {
      height: 40,
      width: '80%',
      borderColor: '#000',
      borderWidth: 1,
      padding: 10,
      fontSize: 16,
      letterSpacing: 5,
      textAlign: 'center',
    },
    buttonStyle: {
      marginLeft: 20,
      marginRight: 20,
    },
    headerText: {
      fontFamily: 'Proxima Nova',
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: 22,
      textAlign: "center",
      color: "#142030",
      display: "flex",
      justifyContent: "center"
    },
  }
};

export default createStyle;
