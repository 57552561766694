import React from "react";

function QuestionMarkGrey() {
  return (
    <svg
      width="7"
      height="10"
      fill="none"
      viewBox="0 0 7 10"
    >
      <path
        fill="#8E96A8"
        d="M2.884 5.184c.405-.731 1.184-1.163 1.637-1.81.479-.68.21-1.948-1.147-1.948-.89 0-1.327.674-1.51 1.232L.5 2.084C.874.964 1.89 0 3.368 0c1.237 0 2.085.563 2.516 1.268.369.606.584 1.737.016 2.58-.632.931-1.237 1.215-1.563 1.815-.132.242-.184.4-.184 1.18H2.632c-.006-.411-.069-1.08.252-1.659zm1.542 3.763c0 .58-.473 1.053-1.052 1.053A1.056 1.056 0 012.32 8.947c0-.579.474-1.052 1.053-1.052s1.052.473 1.052 1.052z"
      ></path>
    </svg>
  );
}

export default QuestionMarkGrey;