window.AppleID.auth.init({
  clientId: process.env.REACT_APP_SERVICE_ID,
  scope: "name email",
  redirectURI: process.env.REACT_APP_APPLE_RETURN_URL,
  state: "origin:web",
  usePopup: true,
});

export const signInApple = () => {
  const response = window.AppleID.auth.signIn();
  return response;
};