import * as React from "react"

function DefaultAvatarProfile(props) {
  return (
    <svg width={props.style?.width ?? 36} height={props.style?.height ?? 36} viewBox="0 0 36 36" {...props}>
    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <circle
        cx={18}
        cy={18}
        r={18}
        fill="#C0C7D6"
        transform="translate(-31 -811) translate(31 811)"
      />
      <g fill="#142030">
        <path
          d="M8.6.6c-2.206 0-4 1.794-4 4s1.794 4 4 4c2.205 0 4-1.794 4-4s-1.795-4-4-4M14.585 12.325l-.079-.079a7.263 7.263 0 00-.428-.407 6.979 6.979 0 00-.466-.368A6.545 6.545 0 009.74 10.2H7.453c-1.826 0-3.547.755-4.846 2.125A7.34 7.34 0 00.6 17.4l10.662-.005H12.42l4.179-.003a7.377 7.377 0 00-2.015-5.067"
          transform="translate(-31 -811) translate(31 811) translate(9 8)"
        />
      </g>
    </g>
  </svg>
  );
}

export default DefaultAvatarProfile