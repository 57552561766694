// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".passwordField_passMeterLbl__MLcBg {\n  display: block;\n  margin-top: 10px;\n  font-size: 16px;\n  line-height: 19px;\n  font-family: \"Proxima Nova\";\n}\n\n.passwordField_passMeter__Ryl0T {\n  margin-top: 10px;\n}\n\n.passwordField_fieldLabel__xPOuH {\n  font-family: 'Proxima Nova';\n  font-style: normal;\n  font-weight: 600;\n  font-size: 13px;\n  line-height: 16px;\n  color: #142030;\n}\n\n.passwordField_appleId__4DmSj {\n  width: -webkit-fill-available;\n  position: relative;\n  color: #142030;\n  font-size: 13px;\n  margin-top: 16px;\n  font-weight: 600;\n}\n\n.passwordField_viewApple__uW1v0 {\n  width: -webkit-fill-available;\n  display: flex;\n  justify-content: space-between\n}", "",{"version":3,"sources":["webpack://./src/components/fields/passwordField.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,2BAA2B;AAC7B;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,2BAA2B;EAC3B,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,6BAA6B;EAC7B,kBAAkB;EAClB,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,6BAA6B;EAC7B,aAAa;EACb;AACF","sourcesContent":[".passMeterLbl {\n  display: block;\n  margin-top: 10px;\n  font-size: 16px;\n  line-height: 19px;\n  font-family: \"Proxima Nova\";\n}\n\n.passMeter {\n  margin-top: 10px;\n}\n\n.fieldLabel {\n  font-family: 'Proxima Nova';\n  font-style: normal;\n  font-weight: 600;\n  font-size: 13px;\n  line-height: 16px;\n  color: #142030;\n}\n\n.appleId {\n  width: -webkit-fill-available;\n  position: relative;\n  color: #142030;\n  font-size: 13px;\n  margin-top: 16px;\n  font-weight: 600;\n}\n\n.viewApple {\n  width: -webkit-fill-available;\n  display: flex;\n  justify-content: space-between\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"passMeterLbl": "passwordField_passMeterLbl__MLcBg",
	"passMeter": "passwordField_passMeter__Ryl0T",
	"fieldLabel": "passwordField_fieldLabel__xPOuH",
	"appleId": "passwordField_appleId__4DmSj",
	"viewApple": "passwordField_viewApple__uW1v0"
};
export default ___CSS_LOADER_EXPORT___;
