// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".checkFilter_containerFilter__H\\+fZX {\n    display: flex;\n    flex-direction: row;\n    align-items: flex-start;\n    background: #FFFFFF;\n    border: 1px solid #EFF1F5;\n    border-radius: 6px;\n    margin-top: 10px;\n}\n.checkFilter_categoryView__iYsnK {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n}\n.checkFilter_mainCategory__5YAy2 {\n    cursor: pointer;\n    padding-top: 8px;\n    padding-bottom: 4px;\n    margin-right: 12px;\n    flex-direction: row;\n    display: flex;\n    white-space: nowrap;\n    align-items: center;\n    width: -webkit-fill-available;\n    justify-content: space-between;\n}", "",{"version":3,"sources":["webpack://./src/web/components/filtersView/checkFilter/checkFilter.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,6BAA6B;IAC7B,8BAA8B;AAClC","sourcesContent":[".containerFilter {\n    display: flex;\n    flex-direction: row;\n    align-items: flex-start;\n    background: #FFFFFF;\n    border: 1px solid #EFF1F5;\n    border-radius: 6px;\n    margin-top: 10px;\n}\n.categoryView {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n}\n.mainCategory {\n    cursor: pointer;\n    padding-top: 8px;\n    padding-bottom: 4px;\n    margin-right: 12px;\n    flex-direction: row;\n    display: flex;\n    white-space: nowrap;\n    align-items: center;\n    width: -webkit-fill-available;\n    justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerFilter": "checkFilter_containerFilter__H+fZX",
	"categoryView": "checkFilter_categoryView__iYsnK",
	"mainCategory": "checkFilter_mainCategory__5YAy2"
};
export default ___CSS_LOADER_EXPORT___;
