import _ from 'lodash';
import {providers} from './contact-providers';

function extractContactsFromIcloudContactsReponse(originalContacts) {
  return Array.isArray(originalContacts) ? originalContacts : []
}

function parseIcloudContactsForContactsComponent(contacts) {
  if (!Array.isArray(contacts) || contacts.length == 0) {
    return [];
  }
  const parsedContacts = [];
  contacts.forEach(contact => {
    try{
      const givenName = contact.given_name ?? '' ;
      if(givenName && givenName.length > 0){
        const familyName = contact.surname ?? '';
        let photo = require('./../images/default-avatar.png');
        if(contact?.picture_url && contact?.picture_url)
          photo = contact?.picture_url;
        const newContact = {
          firstName: givenName,
          lastName:  familyName,
          photo: photo,
          selected: true,
          isPreferred: false,
          terms: [],
          originalContact: contact,
        }
        const addressData = contact?.physical_addresses
        if (addressData && Array.isArray(addressData) && addressData.length > 0) {
          const address = addressData[0]
          const region = address?.state ?? ''
          let newContactAddress = address?.city ?? ''
  
          newContactAddress += newContactAddress.length == 0 ? region : region.length > 0 ? `, ${region}` : ''
          if(newContactAddress.length > 0) {
            newContact.terms.push(newContactAddress);
          }
        }
  
        const organizations = contact?.company_name
        if (organizations) {
          const companyName = organizations
          if (companyName?.length > 0) {
            newContact.terms.push(companyName)
          }
        }
  
        const contactId = contact?.id
        if (contactId?.length > 0) {
          let realContactId = _.last(contactId.split('/'))
          newContact.idProvider = realContactId
        }
        const initialLetter = newContact.firstName.toUpperCase()
        // const lastSection = parsedContacts[parsedContacts.length - 1]
        // if (parsedContacts.length == 0 || lastSection.title != initialLetter) {
          parsedContacts.push({
            title: initialLetter,
            data: [newContact],
          })
      }
      // } else {
      //   lastSection.data.push(newContact)
      // }
    }catch(ee){
      console.log(ee)
    }
  })
  return parsedContacts
}

function parseIcloudContactsForMutation(contactsWithinSection,mPhoneEmails, onlyUpdate=false) {
  let phoneEmails = [...mPhoneEmails]
  const contactsForMutation = [];
  contactsWithinSection.forEach(section => {
    section.data.forEach(contact => {

      if(contact.firstName && contact.firstName.length > 0){

        if (contact.selected === false) {
          return;
        }
  
        let emailFound = false
        let phoneFound = false
  
        const originalContact = contact.originalContact;
        const newContact = {
          name: contact.firstName,
          lastName: contact.lastName,
          provider: providers.iCloud,
          isPreferred: contact?.isPreferred ?? false,
          photo: contact.photo,
        };
        const organizations = originalContact.company_name
        if (organizations) {
          const company = organizations
          const companyName = company
          if (companyName?.length > 0) {
            newContact.company = companyName
          }
          const jobTitle = ''
          if (jobTitle?.length > 0) {
            newContact.jobTitle = jobTitle
          }
        }
        const emails = originalContact?.emails
        if (emails && Array.isArray(emails) && emails.length > 0) {
          newContact.emailAddresses = emails.map((emailData) => {
            const newEmail = {}
            if (emailData?.email?.length > 0 && !phoneEmails.includes((emailData.email?.trim()+'--'+contact.firstName?.trim()+' '+(contact.lastName ?? '')?.trim()))) {
              emailFound = true
              phoneEmails.push((emailData.email?.trim()+'--'+contact.firstName?.trim()+' '+(contact.lastName ?? '')?.trim()))
            }
            newEmail.email = emailData.email
            return newEmail;
          }).filter((emailAddress) => emailAddress && Object.keys(emailAddress).length > 0)
        }
        const addresses = originalContact?.physical_addresses
        if (addresses && Array.isArray(addresses) && addresses.length > 0) {
          newContact.postalAddresses = addresses.map((addressData) => {
            const newAddress = {}
            if (addressData?.city?.length > 0) {
              newAddress.city = addressData.city
            }
            if (addressData?.state?.length > 0) {
              newAddress.region = addressData.state
            }
            if (addressData?.country?.length > 0) {
              newAddress.country = addressData.country
            }
            return newAddress
          }).filter((address) => Object.keys(address).length > 0)
        }
        const phoneNumbers = originalContact?.phone_numbers
        if (phoneNumbers && Array.isArray(phoneNumbers) && phoneNumbers.length > 0) {
          newContact.phoneNumbers = phoneNumbers.map((phoneNumberData) => {
            const newPhoneNumber = {}
            if (phoneNumberData?.number?.length > 0 && !phoneEmails.includes((phoneNumberData.number?.trim() + '--' + contact.firstName?.trim() + ' ' + (contact.lastName ?? '')?.trim()))) {
              phoneFound = true
              phoneEmails.push((phoneNumberData.number?.trim() + '--' + contact.firstName?.trim() + ' ' + (contact.lastName ?? '')?.trim()))
            }
            newPhoneNumber.number = phoneNumberData.number
            return newPhoneNumber
          }).filter((phoneNumber) => phoneNumber && Object.keys(phoneNumber).length > 0)
        }
        const contactId = originalContact?.id
        if (contactId?.length > 0) {
          newContact.idProvider = contactId
        }
        if(emailFound || phoneFound)
          contactsForMutation.push(newContact);
      }
    });
  });
  return contactsForMutation;
}

function isLastPage(contactsReponse) {
  if (contactsReponse?.nextPageToken) { return false }
  return true
}

export { parseIcloudContactsForContactsComponent, parseIcloudContactsForMutation, extractContactsFromIcloudContactsReponse, isLastPage }
