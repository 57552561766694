import { Button, Stack } from "@mui/material";
import { Buffer } from "buffer";
import { useContext, useEffect, useState } from 'react';
import { useMutation } from 'react-apollo';
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts/userContext";
import appWalk4 from '../../images/appWalk4.png';
import { AppleIcon } from "../../images/svg/apple_icon";
import ferretLogo from "../../images/svg/ferret-logo.svg";
import googlePlayImage from '../../images/svg/get-it-on-play.svg';
import { GoogleIcon } from "../../images/svg/google-icon";
import { AUTHENTICATE_APPLE_USER, AUTHENTICATE_GOOGLE_USER, SIGN_IN_FERRET_USER } from '../../queries/queries';
import { saveUserDataAndRedirectToInitialScreen } from '../../root-navigation';
import { signInApple } from "../../utils/apple-login-helper";
import { getDeviceDetails } from "../../utils/DeviceDetails";
import { fetchFirebaseToken } from '../../utils/firebase';
import { googleDefaultLogin } from "../../utils/google-login-helper";
import { checkPWAInstall, isThisDeviceRunningiOS } from "../../utils/installPWAHelper";
import { getIntialLogin, setFirebaseToken } from "../../utils/safe-storage";
import Variables from "../../utils/Variables";
import TwoBtnAlert from "../alert/twoBtnAlert";
import AppWalkthourgh from "../appWalkthrough/appWalkthrough";
import { AppleBtn } from "../buttons/appleBtn";
import { GoogleBtn } from "../buttons/googleBtn";
import { GreenBgButton } from "../buttons/greenBgButton";
import { GreenBorderButton } from "../buttons/greenBorderButton";
import InstallPWA from "../installPWA/installPWA";
import InstallPWAIOS from "../installPWA/installPWAIOS";
import Styles from "./landingPage.module.css";
import FerretFavicon from "../../images/svg/ferret-favicon";
import { browserName } from 'react-device-detect';

const LandingPage = () => {

  const [checkFormValid, setCheckFormValid] = useState(false)
  const [email, setEmail] = useState();
  const [emailValid, setEmailValid] = useState({ status: null, msg: "" });
  const [password, setPassword] = useState();
  const [passwordValid, setPasswordValid] = useState({ status: null, msg: "" });
  const [accountExist, setAccountExist] = useState({ status: null, msg: "" });
  const [isAppWalkthroughDone, setAppWalkthroughDone] = useState(false)
  const [showWalkthrough, setShowWalkthrough] = useState(false)
  const [showInstallPWA, setShowInstallPWA] = useState(false)
  const [showInstallPWAIOS, setShowInstallPWAIOS] = useState(false)

  const { setUser } = useContext(UserContext);

  const navigate = useNavigate();

  useEffect(() => {
    fetchFirebaseToken(setFirebaseToken)
    setAppWalkthroughDone(getIntialLogin())

    setTimeout(() => {
      const isPWAInstalled = checkPWAInstall()
      setShowInstallPWA(isPWAInstalled)
    }, 1000)
  }, [])


  const [signInFerret, { loading: emailLoginLoader }] = useMutation(SIGN_IN_FERRET_USER,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        saveUserDataAndRedirectToInitialScreen(
          data.signInEmail,
          null,
          () => { },
          "login",
          null,
          setUser,
          navigate
        )
      },
      onError: (error) => {
        if (
          error &&
          error.graphQLErrors &&
          Array.isArray(error.graphQLErrors) &&
          error.graphQLErrors.length > 0
        ) {
          console.log('data=====onError', error)
          setAccountExist({ status: false, msg: error.graphQLErrors[0].message })
        }
      }
    });

  const [authenticateGoogleUser, { loading: googleLoginLoader }] = useMutation(AUTHENTICATE_GOOGLE_USER,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        saveUserDataAndRedirectToInitialScreen(
          data.authenticateGoogleUser,
          null,
          () => { },
          'login',
          null,
          setUser,
          navigate
        )
      },
      onError: (error) => {
        if (
          error &&
          error.graphQLErrors &&
          Array.isArray(error.graphQLErrors) &&
          error.graphQLErrors.length > 0
        ) {
          console.log('data=====onError', error)
          setAccountExist({ status: false, msg: error.graphQLErrors[0].message })
        }
      }
    });

  const onGoogleLogin = (response) => {
    const deviceInfo = getDeviceDetails();

    authenticateGoogleUser({
      variables: {
        accessToken: response?.access_token,
        requestType: 'LOGIN',
        deviceDetails: deviceInfo,
      }
    })
  }

  const [authenticateAppleUser, { loading: appleLoginLoader }] = useMutation(AUTHENTICATE_APPLE_USER,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        saveUserDataAndRedirectToInitialScreen(
          data.authenticateAppleUser,
          null,
          () => { },
          "login",
          null,
          setUser,
          navigate
        )
      },
      onError: (error) => {
        if (
          error &&
          error.graphQLErrors &&
          Array.isArray(error.graphQLErrors) &&
          error.graphQLErrors.length > 0
        ) {
          console.log('data=====onError', error.graphQLErrors[0].message)
          setAccountExist({ status: false, msg: error.graphQLErrors[0].message })
        }
      }
    });

  const onAppleLogin = async () => {
    const response = await signInApple()
    const id_token = response.authorization?.id_token
    if (id_token) {
      const { email, name } = response.user ?? [];
      let mEmail = email;
      let firstName = name?.firstName;
      let lastName = name?.lastName;

      if (mEmail == null) {
        const data = JSON.parse(
          Buffer.from(id_token.split('.')[1], 'base64').toString(
            'ascii',
          ),
        );
        mEmail = data.email;
        firstName = data.email
          .split('@')[0]
          .replaceAll(/[^\w\s]/g, ' ')
          .replaceAll(/_/g, ' ');
        lastName = ' ';
      }
      const deviceInfo = await getDeviceDetails();
      authenticateAppleUser({
        variables: {
          code: id_token,
          email: mEmail,
          name: firstName,
          lastName: lastName,
          requestType: 'LOGIN',
          deviceDetails: deviceInfo,
        },
      });
    }
  }


  return (
    <Stack className={Styles.bodyLanding}>
      <div>
        {(!isAppWalkthroughDone ||
          isAppWalkthroughDone == "false" ||
          isAppWalkthroughDone == null) &&
          showWalkthrough ? (
          <AppWalkthourgh />
        ) : (
          <div className={Styles.landingMain}>
            <img
              src={ferretLogo}
              className={Styles.ferretLogo}
              alt="ferretLogo"
            />
            <img
              src={appWalk4}
              className={Styles.ferretBanner}
              alt="ferretBanner"
            />

            <span className={Styles.signUpfree}>Sign Up For Free</span>
            <GreenBgButton
              onClick={() => {
                if (!isAppWalkthroughDone || isAppWalkthroughDone == "false" || isAppWalkthroughDone == null) {
                  setShowWalkthrough(true)
                } else {
                  navigate('/acceptTermsOfService')
                }
              }}
              fullWidth
              variant="contained"
              loading={googleLoginLoader || emailLoginLoader || appleLoginLoader}>
              <div className={Styles.btnTxt}>Sign Up</div>
            </GreenBgButton>

            <span className={Styles.alreadyMember}>Already a member?</span>

            <GreenBorderButton
              fullWidth
              variant="contained"
              onClick={() => {
                Variables.isFromLandingpage = true
                navigate('/login')
              }}>
              <div className={Styles.btnTxt}>Login</div>
            </GreenBorderButton>

            <AppleBtn
              onClick={onAppleLogin}
              fullWidth
              variant="contained"
              startIcon={<AppleIcon />}
              style={{
                marginTop: 16
              }}>
              <div className={Styles.signinAppleTxtld}>Login with Apple</div>
            </AppleBtn>

            <GoogleBtn
              onClick={() => googleDefaultLogin(onGoogleLogin)}
              fullWidth
              variant="contained"
              startIcon={<GoogleIcon />}
              style={{
                marginTop: 16
              }}>
              <div className={Styles.signinGoogleTxtld}>Login with Google</div>
            </GoogleBtn>

            {isThisDeviceRunningiOS() ?
              <Button
                onClick={() => {
                  // if (browserName.indexOf("Safari") != -1) {
                  setShowInstallPWAIOS(true)
                  // } else {
                  // }
                }}
                fullWidth
                variant="contained"
                className={Styles.installFerretBtn}
              >
                <div className={Styles.installFerretBtnDiv}>
                  <FerretFavicon className={Styles.installFerretBtnIcon} />
                  <div className={Styles.installFerretBtnTxt}>Install the App</div>
                </div>
              </Button>
              :
              <a
                style={{
                  marginTop: 16,
                  display: "flex",
                  justifyContent: "center",
                  textDecoration: "none",
                }}
                href="https://play.google.com/store/apps/details?id=ai.ferret.prod.mobile"
              >
                <img
                  src={googlePlayImage}
                  className="googleplay"
                  alt="Google Play"
                />
              </a>
            }

            {accountExist.status == false && (
              <TwoBtnAlert
                description={accountExist.msg}
                leftBtnTxt={"Cancel"}
                rightBtnTxt={"Sign Up"}
                onLeftBtnClick={() => {
                  setAccountExist({ status: null, msg: "" });
                }}
                onRightBtnClick={() => {
                  setAccountExist({ status: null, msg: "" });
                  navigate("/acceptTermsOfService");
                }}
              />
            )}
          </div>
        )}
      </div>

      {/* {showInstallPWA && (
        <div className={Styles.divInstallFerret}>
          <InstallPWA
            setShowInstallPWA={setShowInstallPWA}
            setShowInstallPWAIOS={setShowInstallPWAIOS}
          />
        </div>
      )} */}

      {
        showInstallPWAIOS && (
          <div className={Styles.divInstallFerret}>
            <InstallPWAIOS setShowInstallPWAIOS={setShowInstallPWAIOS} />
          </div>
        )
      }
    </Stack >
  );
};

export default LandingPage;
