import OnboardingTooltip from "./onboardingTooltip";
import OnboardingPopover from "./onboardingPopover";
import WhiteSearchIcon from "../../images/svg/whiteSearchIcon";
import { useState } from "react";

const FilterHeaderTooltip = ({
  onPress,
  dontShow,
  openFilter,
  isFilter,
  isWeb,
  isAllShow,
  placement,
}) => {
  const [isVisible, setIsVisible] = useState(true);
    
  return (
    <OnboardingTooltip
      title={
        <OnboardingPopover
          isFilter={isFilter}
          title={isAllShow ? "Filter Results" : "Sort & Filter Results"}
          content={
            isFilter ? "Using sort and filter can give you more detailed and improved results." :( isAllShow ? "Use the filter to narrow your results." : "Use sort and filter to re-order or narrow your results.")
          }
          icon={<WhiteSearchIcon />}
          onClick={(e) => {
            e.stopPropagation();
            setIsVisible(false);
            onPress();
          }}
          dontShow={dontShow}
          openFilter={openFilter}
          isWeb={isWeb}
        />
      }
      componentStyle={ isFilter ?{
        marginTop: "30px !important",
        marginBottom: "30px !important",
        maxWidth: "90%",
        marginLeft: "5%",
        marginRight: "5%",
      } :{ marginTop: "0px !important" }}
      arrowStyles={{ left: "-20px !important", overflow: "inherit" }}
      isVisible={isVisible}
      placement={placement ? placement : "bottom"}
    ></OnboardingTooltip>
  );
};

export default FilterHeaderTooltip;
