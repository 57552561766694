import React from "react";
const LockCross = (props) => {
  return (
    <svg
      width="17"
      height="20"
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.30955 20C1.9167 20 1.58039 19.8601 1.30062 19.5804C1.02086 19.3006 0.880981 18.9643 0.880981 18.5714V8.2381C0.880981 7.84524 1.02086 7.50893 1.30062 7.22917C1.58039 6.9494 1.9167 6.80952 2.30955 6.80952H3.97622V4.52381C3.97622 3.27222 4.41754 2.20536 5.30017 1.32321C6.18281 0.441072 7.25027 0 8.50255 0C9.75484 0 10.8215 0.441072 11.7024 1.32321C12.5834 2.20536 13.0238 3.27222 13.0238 4.52381V6.80952H14.6905C15.0834 6.80952 15.4197 6.9494 15.6994 7.22917C15.9792 7.50893 16.1191 7.84524 16.1191 8.2381V18.5714C16.1191 18.9643 15.9792 19.3006 15.6994 19.5804C15.4197 19.8601 15.0834 20 14.6905 20H2.30955ZM2.30955 18.5714H14.6905V8.2381H2.30955V18.5714ZM8.50403 15.2381C9.0093 15.2381 9.44051 15.0632 9.79765 14.7135C10.1548 14.3638 10.3334 13.9435 10.3334 13.4524C10.3334 12.9762 10.1535 12.5437 9.79365 12.1548C9.43384 11.7659 9.0013 11.5714 8.49603 11.5714C7.99076 11.5714 7.55955 11.7659 7.20241 12.1548C6.84527 12.5437 6.6667 12.9802 6.6667 13.4643C6.6667 13.9484 6.8466 14.3651 7.20641 14.7143C7.56622 15.0635 7.99876 15.2381 8.50403 15.2381ZM5.40479 6.80952H11.5953V4.52381C11.5953 3.66402 11.2946 2.9332 10.6933 2.33136C10.092 1.7295 9.36184 1.42857 8.50284 1.42857C7.64382 1.42857 6.91273 1.7295 6.30955 2.33136C5.70638 2.9332 5.40479 3.66402 5.40479 4.52381V6.80952Z"
        fill="#697080"
      />
    </svg>
  );
};
export default LockCross;
