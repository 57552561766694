// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".articleParts_ferretSummary__jA0kx {\n  font-family: 'Proxima Nova';\n  font-style: normal;\n  font-weight: 400;\n  font-size: 14px;\n  padding: 0 16px 16px 16px;\n  line-height: 17px;\n  color: #142030;\n  caret-color: #142030;\n}", "",{"version":3,"sources":["webpack://./src/web/components/articleParts/articleParts.module.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,yBAAyB;EACzB,iBAAiB;EACjB,cAAc;EACd,oBAAoB;AACtB","sourcesContent":[".ferretSummary {\n  font-family: 'Proxima Nova';\n  font-style: normal;\n  font-weight: 400;\n  font-size: 14px;\n  padding: 0 16px 16px 16px;\n  line-height: 17px;\n  color: #142030;\n  caret-color: #142030;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ferretSummary": "articleParts_ferretSummary__jA0kx"
};
export default ___CSS_LOADER_EXPORT___;
