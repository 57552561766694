import React, {useEffect, useRef} from 'react';
import EditPencil from '../../images/svg/edit-pencil--green';
import EditPencilWhite from '../../images/svg/edit-pencil--white';
import CloseIcon from '../../images/svg/closeGreen';
import CloseWhite from '../../images/svg/closeWhite';
import Variables from '../../utils/Variables';
import { capitalizeCase } from '../../utils/utils'
import Styles from './searchParams.module.css'

const SearchParams = props => {
  const {searchParams, onClickSearchParam, isVisible = true, disabled} = props;
  const scrollParamsViewRef = useRef();

  useEffect(()=>{
    if(scrollParamsViewRef && searchParams){
      scrollParamsViewRef.current?.scrollIntoView({behavior: 'smooth'});
    }
  },[searchParams])

  const onClickSearchParamHandler = param => {
    if (!disabled) {
      onClickSearchParam(param);
    }
  };
  const paramTags = searchParams?.filter(item => item?.onlyForHeader != true)?.map(p => {
    const {param, isEditable, updated} = p;
    let value= param;
    if(value && value !== ''){
    const itemKey = `param-tag-${value}`;
    if(updated==5) value=`App. ${value} y/o`
    return (
      <div
        onClick={() => onClickSearchParamHandler(p)}
        className={Styles.paramContainer}
        key={itemKey}>
          <span className={Styles.paramLabel}>{capitalizeCase(value.replace(/;/g, Variables.metaDataUISeparator), true)}</span>
          {isEditable ? (
            <EditPencil className={Styles.pencil} />
          ) : (
            <CloseIcon className={Styles.pencil} />
          )}
      </div>
    );
    }
  });


  if (isVisible)
    return (
      <div className={Styles.backgroudView}>
        <span className={Styles.searchParamsContainer}>EDIT YOUR SEARCH:</span>
        <div
          ref={scrollParamsViewRef}
          className={Styles.paramsContainer}>
          {paramTags}
        </div>
        <div className={Styles.strengthContainer}>
          <span className={Styles.strengthTitle}>Search Strength: </span>
          <span className={Styles.strengthValue}>{searchParams.length > 3 ? "Strong" : searchParams.length === 3 ? "Medium" : "Weak"}</span>
        </div> 
      </div>
    );
  else return <div />;
};

export default SearchParams;
