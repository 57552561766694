import * as React from "react"
const WatchList = (props) => {
    return (
    <svg
    width={33}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
      >
        <g clipPath="url(#a)">
          <path
            d="M16.327 24.413a1.973 1.973 0 1 0 0-3.946 1.973 1.973 0 0 0 0 3.946Z"
            fill={props.selected ? "#142030" :  props.iconType == 'white' ? '#fff' :  '#b5b9c4'}
          />
          <path
            d="M29.127 18.467a.32.32 0 0 0-.374 0 10.386 10.386 0 0 1-5.16 1.333 11.021 11.021 0 0 1-2.213-.24.294.294 0 0 0-.32.12.334.334 0 0 0 0 .347 5.2 5.2 0 0 1 .587 2.413 5.334 5.334 0 1 1-5.334-5.333.12.12 0 0 0 .107-.08.093.093 0 0 0 0-.12 10.667 10.667 0 0 1-2.427-3.36.333.333 0 0 0-.36-.187 24.334 24.334 0 0 0-12.373 7.2 2.667 2.667 0 0 0 0 3.747c3.453 3.573 9.147 7.36 14.867 7.36h.373c5.733 0 11.427-3.787 14.88-7.36a2.667 2.667 0 0 0 0-3.747 25.017 25.017 0 0 0-2.253-2.093Z"
            fill={props.selected ? "#142030" :  props.iconType == 'white' ? '#fff' :  '#b5b9c4'}
          />
          <path
            d="M32.5 9.24a.986.986 0 0 0-.987-.987h-.453A.346.346 0 0 1 30.727 8a7.267 7.267 0 0 0-5.867-5.88.334.334 0 0 1-.28-.32v-.467a.987.987 0 0 0-1.973 0v.454a.334.334 0 0 1-.28.32A7.294 7.294 0 0 0 16.5 8a.333.333 0 0 1-.32.28h-.453a.987.987 0 1 0 0 1.973h.453a.334.334 0 0 1 .32.267 7.266 7.266 0 0 0 5.88 5.88.347.347 0 0 1 .28.333v.454a.987.987 0 1 0 1.973 0v-.454a.347.347 0 0 1 .28-.333 7.227 7.227 0 0 0 5.867-5.88.346.346 0 0 1 .333-.267h.454A.986.986 0 0 0 32.5 9.24Zm-7.48 4.387a.36.36 0 0 1-.307 0c-.146-.107-.133-.24-.133-.374a.987.987 0 0 0-1.973 0c0 .134 0 .267-.147.374a.32.32 0 0 1-.293 0 4.602 4.602 0 0 1-3-2.96.333.333 0 0 1 0-.294.348.348 0 0 1 .266-.133h.12a.986.986 0 1 0 0-1.973h-.12a.333.333 0 0 1-.266-.454 4.613 4.613 0 0 1 2.96-2.96.293.293 0 0 1 .293 0 .32.32 0 0 1 .147.267v.213a.987.987 0 1 0 1.973 0V5.16a.348.348 0 0 1 .133-.267.32.32 0 0 1 .307 0 4.613 4.613 0 0 1 2.96 2.96.32.32 0 0 1 0 .294.347.347 0 0 1-.267.146h-.12a.987.987 0 0 0 0 1.974h.12a.372.372 0 0 1 .267.133.333.333 0 0 1 0 .293 4.6 4.6 0 0 1-2.92 2.934Z"
            fill={props.selected ? props.iconType =="blackWhite"? "#1E1E1E": "#DA3528" : props.iconType == 'white' ? '#fff' :  '#b5b9c4'}
          />
          <path
            d="M23.593 10.56a1.32 1.32 0 1 0 0-2.64 1.32 1.32 0 0 0 0 2.64Z"
            fill={props.selected ? "#DA3528" : props.iconType == 'white' ? '#fff' :  '#b5b9c4'}
          />
        </g>
        <defs>
          <clip-path id="a">
            <path fill="#fff" transform="translate(.5)" d="M0 0h32v32H0z" />
          </clip-path>
        </defs>
      </svg>
)}

export default WatchList
