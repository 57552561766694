// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".filterSection_body__85mwc {\n  padding: 20px 24px 0 24px;\n}\n\n.filterSection_sourceTitle__2SZ1K {\n  font-family: 'Proxima Nova';\n  font-style: normal;\n  font-weight: 700;\n  font-size: 16px;\n  line-height: 19px;\n  text-align: center;\n  color: #142030;\n}\n\n.filterSection_filters__tbJLo {\n  margin-top: 20px;\n  flex-direction: column;\n  width: 100%;\n}\n\n.filterSection_separator__4Xsti {\n  border-bottom-color: #EFF1F5;\n  border-bottom-width: 1;\n  margin: 24px 10px 20px 0;\n}\n\n.filterSection_scrollContainer__vp6Hc {\n  width: 100%;\n  padding-right: 20px;\n}", "",{"version":3,"sources":["webpack://./src/components/savedSearches/filtersModal/filterSection/filterSection.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;EACE,2BAA2B;EAC3B,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,4BAA4B;EAC5B,sBAAsB;EACtB,wBAAwB;AAC1B;;AAEA;EACE,WAAW;EACX,mBAAmB;AACrB","sourcesContent":[".body {\n  padding: 20px 24px 0 24px;\n}\n\n.sourceTitle {\n  font-family: 'Proxima Nova';\n  font-style: normal;\n  font-weight: 700;\n  font-size: 16px;\n  line-height: 19px;\n  text-align: center;\n  color: #142030;\n}\n\n.filters {\n  margin-top: 20px;\n  flex-direction: column;\n  width: 100%;\n}\n\n.separator {\n  border-bottom-color: #EFF1F5;\n  border-bottom-width: 1;\n  margin: 24px 10px 20px 0;\n}\n\n.scrollContainer {\n  width: 100%;\n  padding-right: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "filterSection_body__85mwc",
	"sourceTitle": "filterSection_sourceTitle__2SZ1K",
	"filters": "filterSection_filters__tbJLo",
	"separator": "filterSection_separator__4Xsti",
	"scrollContainer": "filterSection_scrollContainer__vp6Hc"
};
export default ___CSS_LOADER_EXPORT___;
