import * as React from "react"

const DashboardBetaIllustration = (props) => (
  <svg
    width={345}
    height={130}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle opacity={0.162} cx={152.5} cy={15.5} r={2.5} fill="#48D2A0" />
    <circle opacity={0.17} cx={222.5} cy={20.5} r={1.5} fill="#48D2A0" />
    <circle opacity={0.301} cx={122.5} cy={93.5} r={2.5} fill="#48D2A0" />
    <g
      stroke="#48D2A0"
      strokeWidth={5.25}
      strokeLinecap="round"
      opacity={0.364}
    >
      <path d="m106.434 26.078-3.55-6.148M101.652 24.797l6.149-3.55" />
    </g>
    <g
      stroke="#48D2A0"
      strokeWidth={3.675}
      strokeLinecap="round"
      opacity={0.364}
    >
      <path d="m240.456 84.381 1.462-4.781M238.796 81.26l4.782 1.461" />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M231.9 48.1c-2.7 22.4-38.3 29.4-46.3 28.1-8.1-1.3-61.9 21-63.8 7.5-1.9-13.5 15-15.7 12-27.5s-18.8-19.4-12-28.9c7.3-10 31.8-11.8 52.9-5.9 21.1 5.9 59.9 4.2 57.2 26.7Z"
      fill="#D8EFE8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M136.3 19.9h.5c.1 0 .2.1.2.2v4c0 .1-.1.2-.2.2h-.5c-.1 0-.2-.1-.2-.2v-4c-.1-.1 0-.2.2-.2M136.3 28.147h.5c.2 0 .3.1.3.3v7.3c0 .1-.1.3-.3.3h-.5c-.2-.1-.3-.3-.3-.4v-7.3c0-.1.1-.2.3-.2M136.3 38.6h.5c.1 0 .3.1.3.3v7.3c0 .1-.1.3-.3.3h-.5c-.1 0-.3-.1-.3-.3v-7.3c0-.2.1-.3.3-.3"
      fill="#3F3D56"
    />
    <path
      clipRule="evenodd"
      d="M188.2 114.4h-47.1c-.6 0-1.1-.2-1.1-.5v-8.7h49.3v8.7c0 .3-.5.5-1.1.5Z"
      stroke="#F2F2F2"
      strokeWidth={0.5}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M142.388 5.1h44.5c3.2 0 5.8 2.6 5.8 5.7v100.8c0 3.2-2.6 5.7-5.8 5.7h-44.5c-3.2 0-5.8-2.6-5.8-5.7V10.8c0-3.1 2.6-5.7 5.8-5.7"
      fill="#3F3D56"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M159.6 8.5h7c.2 0 .4.2.4.4v.8c0 .1-.1.3-.4.3h-7c-.2 0-.4-.2-.4-.4v-.7c0-.3.2-.4.4-.4M169.1 8.3c.5 0 .9.4.9.9s-.4.9-.9.9-.9-.4-.9-.9.4-.9.9-.9"
      fill="#E6E8EC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M189.2 12.4V110c0 2.4-2 4.4-4.5 4.4h-40.2c-2.5 0-4.5-2-4.5-4.4V12.4c0-2.4 2-4.4 4.5-4.4h6v.8c0 2 1.6 3.6 3.7 3.6h20.5c2 0 3.7-1.6 3.7-3.6V8h6.4c2.4 0 4.4 2 4.4 4.4Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M189.2 106.7V12.4c0-2.4-2-4.4-4.5-4.4h-6.4v.8c0 2-1.6 3.6-3.7 3.6h-20.4c-2 0-3.7-1.6-3.7-3.6V8h-6c-2.5 0-4.5 2-4.5 4.4v94.3h49.2Z"
      fill="#4A4966"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M141 24.7h47.3c.5 0 1 .4 1 1V109H140V25.7c0-.6.5-1 1-1"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M184 15.4c1.3 0 2.3 1 2.3 2.2 0 1.2-1 2.2-2.3 2.2-1.3 0-2.3-1-2.3-2.2 0-1.2 1-2.2 2.3-2.2"
      fill="#F2F2F2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M143.4 18.9h21.2v-2.5h-21.2v2.5Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M143.4 29.7h21.2v-2.6h-21.2v2.6Z"
      fill="#303030"
    />
    <path
      d="M163.9 65.6h-20c-.3 0-.5-.4-.5-.8V49.9h20.9v14.9c0 .5-.2.8-.4.8Z"
      fill="#078167"
    />
    <path
      clipRule="evenodd"
      d="M186 65.6h-20c-.3 0-.5-.4-.5-.8V49.9h20.9v14.9c0 .5-.2.8-.4.8Z"
      stroke="#F2F2F2"
      strokeWidth={0.5}
    />
    <path
      clipRule="evenodd"
      d="M163.9 83.7h-20c-.3 0-.5-.4-.5-.8v-15h20.9v14.9c0 .5-.2.9-.4.9Z"
      stroke="#F2F2F2"
      strokeWidth={0.5}
      strokeLinecap="round"
    />
    <path
      clipRule="evenodd"
      d="M186 83.7h-20c-.3 0-.5-.4-.5-.8v-15h20.9v14.9c0 .5-.2.9-.4.9Z"
      stroke="#F2F2F2"
      strokeWidth={0.5}
    />
    <path
      clipRule="evenodd"
      d="M163.9 101.7h-20c-.3 0-.5-.4-.5-.8V86h20.9v14.9c0 .5-.2.8-.4.8Z"
      stroke="#F2F2F2"
      strokeWidth={0.5}
      strokeLinecap="round"
    />
    <path
      clipRule="evenodd"
      d="M185.3 46.6h-40.9c-.5 0-.9-.6-.9-1.3V33.9c0-.7.4-1.3.9-1.3h40.9c.5 0 .9.6.9 1.3v11.3c.1.8-.4 1.4-.9 1.4Z"
      stroke="#F2F2F2"
      strokeWidth={0.5}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M147.7 35.1c1.3 0 2.3 1 2.3 2.2 0 1.2-1 2.2-2.3 2.2-1.3 0-2.3-1-2.3-2.2 0-1.2 1.1-2.2 2.3-2.2"
      fill="#FCB322"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M147.7 35.4c1.1 0 1.9.8 1.9 1.9 0 1.1-.9 1.9-1.9 1.9-1 0-1.9-.8-1.9-1.9 0-1.1.9-1.9 1.9-1.9"
      fill="#F2F2F2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M147.7 60c1.3 0 2.3 1 2.3 2.2 0 1.2-1 2.2-2.3 2.2-1.3 0-2.3-1-2.3-2.2 0-1.2 1.1-2.2 2.3-2.2"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M147.7 60.3c1.1 0 1.9.8 1.9 1.9 0 1-.9 1.9-1.9 1.9-1 0-1.9-.8-1.9-1.9 0-1.1.9-1.9 1.9-1.9"
      fill="#F2F2F2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M169.4 60.3c1.1 0 1.9.8 1.9 1.9 0 1-.9 1.9-1.9 1.9-1 0-1.9-.8-1.9-1.9 0-1.1.9-1.9 1.9-1.9"
      fill="#B7B7B7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M152.8 69c1.1 0 1.9.8 1.9 1.9 0 1-.9 1.9-1.9 1.9-1 0-1.9-.8-1.9-1.9-.1-1.1.8-1.9 1.9-1.9"
      fill="#B8DFD8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M150 69c1.1 0 1.9.8 1.9 1.9 0 1-.9 1.9-1.9 1.9-1 0-1.9-.8-1.9-1.9 0-1.1.8-1.9 1.9-1.9"
      fill="#E8F6EF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M147.4 69c1.1 0 1.9.8 1.9 1.9 0 1-.9 1.9-1.9 1.9-1 0-1.9-.8-1.9-1.9-.1-1.1.8-1.9 1.9-1.9"
      fill="#FFE194"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M152.2 37.5h9.4v-1.7h-9.4v1.7ZM146.1 75.8h16.1v-1.6h-16.1v1.6Z"
      fill="#303030"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M163.1 37.5h18.4v-1.7h-18.4v1.7ZM152.2 40h29.3v-1.6h-29.3V40ZM146 78.3h16.2v-1.6H146v1.6Z"
      fill="#E2E2E2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M154 44.6h20.7V43H154v1.6ZM148.4 82.2h13.8v-1.7h-13.8v1.7Z"
      fill="#078167"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M151.3 63h10.3v-1.6h-10.3V63ZM145.4 53.4h16.2v-1.6h-16.2v1.6ZM145.4 56.2h16.2v-1.7h-16.2v1.7Z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M172.5 63h10.3v-1.6h-10.3V63ZM167.9 53.4h16.2v-1.6h-16.2v1.6ZM167.9 56.2h16.2v-1.7h-16.2v1.7Z"
      fill="#E2E2E2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M169.4 78.2c1.1 0 1.9.8 1.9 1.9 0 1.1-.9 1.9-1.9 1.9-1 0-1.9-.8-1.9-1.9 0-1.1.9-1.9 1.9-1.9"
      fill="#B7B7B7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M172.5 80.9h10.3v-1.7h-10.3v1.7ZM167.9 71.3h16.2v-1.6h-16.2v1.6ZM167.9 74.1h16.2v-1.7h-16.2v1.7Z"
      fill="#E2E2E2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M147.2 96.4c1.1 0 1.9.8 1.9 1.9 0 1.1-.9 1.9-1.9 1.9-1 0-1.9-.8-1.9-1.9 0-1.1.8-1.9 1.9-1.9"
      fill="#B7B7B7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M164.7 104.7c1.6 0 2.9 1.3 2.9 2.8 0 1.6-1.3 2.8-2.9 2.8-1.6 0-2.9-1.3-2.9-2.8 0-1.5 1.3-2.8 2.9-2.8"
      fill="#078167"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M150.2 99.1h10.4v-1.7h-10.4v1.7ZM145.6 89.5h16.2v-1.6h-16.2v1.6ZM145.6 92.3h16.2v-1.7h-16.2v1.7Z"
      fill="#E2E2E2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M168.2 107.6h21.1v2.9c0 2.2-2 4-4.5 4h-40.4c-2.4 0-4.4-1.7-4.4-3.9v-3h21.2c-.1.2-.1.4-.1.6 0 1.8 1.6 3.2 3.6 3.2s3.6-1.4 3.6-3.2c0-.1-.025-.2-.05-.3-.025-.1-.05-.2-.05-.3Zm20.6 2.9v-2.4h-20v.1c0 2-1.8 3.7-4.1 3.7s-4.1-1.7-4.1-3.7v-.1h-20.1v2.5c0 1.9 1.7 3.4 3.9 3.4h40.4c2.2 0 4-1.6 4-3.5Z"
      fill="#F2F2F2"
    />
    <path fill="#078167" d="M147.3 109.4h7.4v3.3h-7.4z" />
    <path fill="#E2E2E2" d="M175.7 109.4h7.4v3.3h-7.4z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M191.334 32.407c.713-.147 1.626 1.377 2.04 3.404.413 2.025.171 3.787-.542 3.934-.713.146-1.627-1.377-2.04-3.404-.414-2.026-.171-3.787.542-3.934"
      fill="#FBBEBE"
    />
    <mask
      id="a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={203}
      y={114}
      width={6}
      height={5}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M203.646 114.089h5.253v3.96h-5.253v-3.96Z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M203.719 115.119c-.191.581 0 1.308.519 1.625.21.112.436.191.671.234.316.09.644.135.974.134.404-.019.792-.178 1.196-.235a.19.19 0 0 1 .138.019c.041.044.06.104.051.164.005.231.022.461.052.69a.359.359 0 0 0 .121.265.377.377 0 0 0 .187.033h1.266a12.467 12.467 0 0 0-.361-3.316.762.762 0 0 0-.094-.235.77.77 0 0 0-.404-.234 2.251 2.251 0 0 0-.979-.172c-.409.054-.75.319-1.098.54-.349.22-.823.415-1.246.302-.423-.112-.775-.466-.993.186"
        fill="#3F3D56"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M185.125 114.089a3.493 3.493 0 0 1-1.278.872l-.215.103a.15.15 0 0 0-.072.059.13.13 0 0 0 .023.106 1.51 1.51 0 0 0 1.715.626.914.914 0 0 0 .376-.197c.178-.212.28-.478.29-.755a1.548 1.548 0 0 0-.035-.866c-.325-.734-.444-.401-.804.052"
      fill="#FBBEBE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M189.981 116.978a.576.576 0 0 1-.04.26c-.11.216-.416.204-.654.157a15.851 15.851 0 0 0-.287-2.368c-.454-.021-.781.411-1.024.797l-.626.997a1.289 1.289 0 0 1-.374.436 1.088 1.088 0 0 1-.404.129c-1.196.199-2.404-.178-3.559-.553a1.865 1.865 0 0 1-.484-.207.993.993 0 0 1-.233-1.355c.484-.657 1.57-.469 2.173-1.013a5.464 5.464 0 0 1-.308 1.118c.806-.122 1.347-.863 1.997-1.357.163-.139.36-.233.57-.272.158-.014.316.003.468.049a5.473 5.473 0 0 1 2.056 1.015c.245.195.628.469.736.769.114.359-.007 1.016-.007 1.398"
      fill="#3F3D56"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M206.054 29.917c2.666 0 4.827 2.169 4.827 4.845 0 2.675-2.161 4.844-4.827 4.844s-4.827-2.169-4.827-4.844c0-2.676 2.161-4.845 4.827-4.845"
      fill="#FBBEBE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M215.563 63.366a11.49 11.49 0 0 1-.39 2.741 28.592 28.592 0 0 1-.883 3.283c-.278.782-.627 1.565-.624 2.393 0 .344.056.726-.154.998a1.453 1.453 0 0 1-.683.392.856.856 0 0 0-.575.514 3.627 3.627 0 0 1-.485-1.074 89.152 89.152 0 0 1-1.169-3.886.506.506 0 0 1 .033-.47.576.576 0 0 1 .203-.114c.24-.098.459-.24.647-.417a.929.929 0 0 0 .215-.28 1.28 1.28 0 0 0 .092-.53c0-.621 0-1.24-.017-1.861-.01-.574.012-1.148.066-1.72 0-.112.021-.293.039-.485.035-.368.106-.795.304-.938.297-.2 1.185.068 1.521.115.58.08 1.168.174 1.734.284.082.346.124.7.126 1.055M196.072 40.186c-.187.375-.636.555-.981.792l-1.185.816a.789.789 0 0 1-.586.202.77.77 0 0 1-.437-.41.059.059 0 0 0 0-.02l-.246-.412-1.168-1.965a1.12 1.12 0 0 1-.217-.605c0-.1.035-.195.044-.296a1.427 1.427 0 0 0-.233-.797c-.094-.176-.281-.321-.276-.518a1.87 1.87 0 0 1 .131-.758c.122-.222.298-.41.511-.549.11-.079.222-.154.339-.234a.676.676 0 0 1 .203-.094c.194-.04.386.122.582.13.844.567 1.168 1.64 1.832 2.415.374.44.411.778.834 1.172.068.061.133.127.196.195.084.091.155.194.211.305.077.201.542.438.446.63"
      fill="#3F3D56"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M204.357 39.609c.348.738.619 1.55.519 2.361-.021.22-.09.432-.203.622-.337.518-1.042.612-1.657.642a.976.976 0 0 0-.72.235.66.66 0 0 0 .045.802c.197.211.442.372.715.469.972.429 2.021.783 3.077.684.491-.047.967-.19 1.456-.262a15.312 15.312 0 0 1 1.602-.09 30.332 30.332 0 0 0 1.902-.15l1.678-.166a.374.374 0 0 0 .25-.091c.124-.146-.035-.36-.189-.47-.891-.66-2.024-1.116-2.531-2.11-.233-.469-.306-1.027-.479-1.534-.173-.506-.448-.963-.556-1.48a9.883 9.883 0 0 1-.077-1.364 1.836 1.836 0 0 0-.467-1.248c-.334-.312-.818-.392-1.271-.41-1.117-.05-2.306.218-3.099 1.008-.212.214-.394.47-.614.661-.22.193-.519.25-.374.502.145.254.43.41.584.634.156.24.293.493.409.755"
      fill="#FBBEBE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M213.874 46.43a26.755 26.755 0 0 1-1.43 5.284c-.33.77-.611 1.562-.841 2.368-.18.727-.145 1.506-.311 2.235a62.455 62.455 0 0 0-.577 2.732 17.988 17.988 0 0 0-.332 4.245c0 .224.043.446.126.654.077.169.201.314.274.488a1.18 1.18 0 0 1-.066.938c-.12.268-.282.515-.481.731-2.178.652-4.507.439-6.776.577-2.092.127-4.206.558-6.267.2-.234-.939.157-1.921.491-2.836.969-2.657 2.042-5.424 1.869-8.207a8.507 8.507 0 0 0-.11-.969c-.338-1.972.031-4.014.622-5.926.367-1.191.35-2.46.266-3.7a6.723 6.723 0 0 1-.016-1.212c.034-.413.186-.807.439-1.133 0-.017.021-.033.033-.05.012-.016.103-.013.175 0 .037.017.08.017.117 0l.026-.016a.058.058 0 0 1 .067 0c.054.033.085.145.201.167.173.026.347.042.521.049.21.006.419.036.622.089h.028c.304.052.616.025.907-.077.193-.049.38-.121.556-.216.051-.056.373-.108.439-.148.213-.129.166-.734.21-.983.024-.249.084-.494.175-.726.217-.35.591-.568 1-.584.403-.01.805.026 1.199.107.871.125 1.764.104 2.619.306.43.1.9.253 1.295.056.107.026.123.12.123.234-.01.11-.031.217-.065.322-.15.647.357 1.242.825 1.711.197.221.43.408.689.551.234.108.467.153.701.27.19.101.35.249.467.43.135.206.226.438.267.682.05.453.024.913-.077 1.357"
      fill="#3F3D56"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M213.874 46.43a26.758 26.758 0 0 1-1.43 5.284c-.33.77-.611 1.562-.841 2.368-.18.727-.145 1.506-.311 2.235a63.958 63.958 0 0 0-.577 2.732 3.568 3.568 0 0 1-1.068.373c-.569.069-1.146.05-1.71-.057l-3.334-.447c-1.332-.181-2.75-.4-3.739-1.304-.549-.497-.909-1.154-1.304-1.787a8.512 8.512 0 0 0-.109-.969c-.339-1.972.03-4.014.621-5.925.367-1.192.351-2.46.266-3.7a6.82 6.82 0 0 1-.016-1.213c.034-.413.187-.806.439-1.133a1.668 1.668 0 0 1 .351-.066h.068a1 1 0 0 1 .233.029c.159.05.316.11.468.18h.028c.2.066.41.096.621.09.309 0 .626-.05.935-.071.381-.06.771.01 1.107.2a6.6 6.6 0 0 1 .617.564c.234.185.497.328.778.425a8.24 8.24 0 0 0 6.22-.132c.19-.108.406-.163.624-.159.161.032.314.097.449.192.14.082.278.162.42.235.135.206.226.439.267.682.053.46.029.924-.073 1.374"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M215.909 44.878a12.456 12.456 0 0 1-2.664-1.173 1.182 1.182 0 0 0-.448-.192 1.247 1.247 0 0 0-.626.16 8.239 8.239 0 0 1-6.218.13 2.688 2.688 0 0 1-.778-.424c-.194-.2-.4-.389-.617-.565-.769-.5-1.832.087-2.692-.234a4.595 4.595 0 0 0-.467-.18c-.68-.172-1.371.508-2.04.302a11.527 11.527 0 0 0-.549 2.092 22.852 22.852 0 0 0-.152 2.467c0 .494-.023.99 0 1.484a.603.603 0 0 1 .407.265.606.606 0 0 1-.037.504c-.489 1.26-.984 2.533-1.725 3.66a7.916 7.916 0 0 1 1.444 1.108c.818.867 1.22 2.084 2.103 2.886.995.903 2.414 1.123 3.739 1.302l3.334.448a5.673 5.673 0 0 0 1.712.058 3.6 3.6 0 0 0 .914-.297c.189-.08.362-.195.509-.338.124-.14.225-.299.302-.47.264-.53.504-1.071.727-1.62.077-.194.193-.422.404-.438 1.238-1.12 2.406-2.27 3.645-3.391a8.09 8.09 0 0 0 1.329-1.407c.615-.912.056-2.204 0-3.307a4.215 4.215 0 0 0-.105-.837c-.201-.8-.673-1.756-1.451-1.993"
      fill="#12A485"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M196.072 40.186c-.187.375-.636.555-.982.792l-1.184.816a.79.79 0 0 1-.587.202.771.771 0 0 1-.436-.41v-.17c-.009-.19.016-.379.074-.56.116-.265.296-.497.524-.675.44-.418.963-.738 1.535-.938a.524.524 0 0 1 .28-.028.36.36 0 0 1 .103.044c.084.092.154.194.21.305.094.193.559.43.463.622"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M198.862 42.657c-.397-.018-.722-.307-1.012-.581l-.885-.826a.817.817 0 0 1-.155-.173 1.232 1.232 0 0 1-.107-.289 3.31 3.31 0 0 0-.86-1.238.56.56 0 0 0-.255-.183.513.513 0 0 0-.283.028 4.26 4.26 0 0 0-1.534.938c-.228.179-.408.41-.524.676a1.59 1.59 0 0 0-.075.56 4.334 4.334 0 0 0 .538 2.05c.312.517.68.997 1.098 1.432l.556.624c.547.615 1.094 1.23 1.687 1.794.213.228.471.408.757.53.293.12.629.066.869-.14.112-.13.197-.282.248-.446.36-.938.701-1.914.722-2.924.005-.405-.012-.81-.051-1.213-.035-.426-.337-.593-.734-.619"
      fill="#12A485"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M213.021 75.488a54.18 54.18 0 0 1-.5 7.051c-.047.378-.098.755-.124 1.133-.077 1.095.049 2.195-.053 3.283-.054.588-.176 1.172-.279 1.747a31.2 31.2 0 0 0-.397 7.774c.323 4.321-.666 8.646-.217 12.956.054.838.008 1.68-.138 2.507a10.804 10.804 0 0 1-.362 1.515 1300.74 1300.74 0 0 1-1.028 3.517 1.235 1.235 0 0 1-.234.488c-.241.209-.56.304-.876.26-1.348-.042-2.617-.626-3.841-1.196a1.361 1.361 0 0 1-.386-.234c-.254-.265-.233-.683-.346-1.032-.112-.35-.416-.678-.588-1.037a3.35 3.35 0 0 1-.255-1.067 43.726 43.726 0 0 1-.318-3.616c-.089-1.702 1.033-3.269 1.402-4.924.701-3.255.107-6.745 1.353-9.833.392-.975-.178-2.157-.234-3.208a10.252 10.252 0 0 0-.261-2.012c-.234-.882-.727-1.712-.762-2.626-.026-.662.187-1.306.297-1.958a8.851 8.851 0 0 0-.04-2.892c-.124-.788-.313-1.576-.502-2.345l-.043.087a26.71 26.71 0 0 0-1.465 3.459c-.186.574-.35 1.16-.584 1.719-.191.469-.427.898-.659 1.339a38.565 38.565 0 0 0-2.161 4.819c-.29.811-.544 1.641-.797 2.455l-1.731 5.628c-.467 1.48-.979 2.91-1.437 4.388-.647 2.11-1.294 4.202-2.047 6.265-.701 1.959-1.535 3.987-1.285 6.055a4.426 4.426 0 0 1-2.063.42 12.078 12.078 0 0 1-3.505-.853c-.446-.167-.909-.355-1.21-.723-.519-.635-.39-1.566-.234-2.375a5.81 5.81 0 0 1 .28-1.07c.178-.431.468-.813.65-1.24.367-.819.39-1.745.535-2.634.145-.888.467-1.826 1.225-2.309.128-1.562.768-3.103 1.121-4.629.266-1.157.355-2.346.565-3.518.211-1.172.55-2.345 1.255-3.295a5.244 5.244 0 0 1 .636-2.814c.113-.188.207-.39.278-.598.043-.204.068-.41.075-.619.077-.815.424-1.58.986-2.174-.234-.856.091-1.75.383-2.58a8.98 8.98 0 0 0 .49-3.299 2.898 2.898 0 0 1 .045-.938c.065-.234.194-.433.269-.659.066-.275.101-.558.105-.842.091-1.268.602-2.464 1.168-3.597.336-.661.052-1.56.121-2.296.122-1.224.255-2.49.86-3.557.113-.202.234-.394.334-.603.122-.298.188-.615.194-.938.022-.537.096-.982-.095-1.482 1.25-1.043 2.955-1.336 4.581-1.407 1.627-.07 3.272.078 4.863-.27.467-.105.935-.25 1.427-.32 1.12-.165 2.255.09 3.36.344-.077.703.18 1.379.468 2.033a21.895 21.895 0 0 1 1.731 8.377"
      fill="#33313F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m207.519 76.025-2.685 6.06c-.124-.789-.313-1.577-.502-2.346l-.042.087v-.127l1.462-3.229 1.767-.445ZM215.563 63.366a1.003 1.003 0 0 1-.532.2c-.296.01-.592-.03-.874-.12l-2.103-.528a1.057 1.057 0 0 1-.192-.068c.036-.368.105-.795.304-.938.297-.2 1.185.068 1.521.115.58.08 1.169.174 1.734.284.088.345.135.7.142 1.055"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M217.341 49.413c.045.195.108.387.145.584.063.484.046.974-.049 1.452l-.191 1.341-.192 1.348-.741 5.19-.182 1.285-.257 1.794a1.29 1.29 0 0 1-.112.431.897.897 0 0 1-.732.434 2.642 2.642 0 0 1-.873-.12l-2.103-.53a.658.658 0 0 1-.257-.105.51.51 0 0 1-.136-.494c.031-.179.108-.345.133-.524.052-.346-.093-.703-.042-1.048.051-.22.129-.431.234-.63.701-1.555.294-3.37.533-5.059a7.683 7.683 0 0 1 1.621-3.62 10.343 10.343 0 0 1 1.79-1.764c.262-.204.678-.624 1.007-.703.423-.09.334.443.404.738"
      fill="#12A485"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M201.427 29.213c-.154.493.087 1.034-.023 1.536-.119.554-.638.938-.785 1.494-.234.847.481 1.618 1.005 2.322 1.939 2.612 1.684 6.228 1.02 9.415a48.84 48.84 0 0 1-2.207 7.504c-.292.757-.615 1.526-1.19 2.094 1.075-.31 2.217-.621 3.295-.321 1.364.38 2.542 1.71 3.925 1.407.832-.186 1.416-.927 2.164-1.337 1.402-.757 3.091-.281 4.654-.037.337.08.689.062 1.017-.052.25-.135.462-.33.616-.57a5.789 5.789 0 0 0 1.201-3.119 4.01 4.01 0 0 0-1.147-3.079c-.332-.314-.729-.567-.983-.938a2.917 2.917 0 0 1-.386-1.42c-.185-2.58.441-5.214-.204-7.709-.233-.889-.614-1.74-.717-2.65-.128-1.132.178-2.345-.271-3.381-.353-.823-1.131-1.407-1.97-1.707-.838-.3-1.736-.385-2.626-.47-1.28-.107-5.853-.659-6.388 1.018"
      fill="#33313F"
    />
    <g fillRule="evenodd" clipRule="evenodd" fill="#000">
      <path d="M202.644 43.98a48.566 48.566 0 0 1-2.196 7.473c.161-.32.297-.658.425-.994a48.374 48.374 0 0 0 2.208-7.504c.666-3.19.921-6.8-1.018-9.415-.468-.624-1.068-1.3-1.047-2.031-.173.221-.307.47-.397.736-.234.847.481 1.618 1.004 2.322 1.94 2.61 1.685 6.226 1.021 9.413M201.432 30.512c.187-.233.327-.502.411-.79.036-.348.027-.7-.028-1.046a1.13 1.13 0 0 0-.388.537c-.138.415.021.865.005 1.3M215.358 51.636a1.686 1.686 0 0 1-.617.568 1.78 1.78 0 0 1-1.017.051c-1.563-.234-3.271-.717-4.654.04-.75.408-1.332 1.15-2.166 1.337-1.381.31-2.559-1.02-3.923-1.407-.967-.27-1.986-.047-2.961.234a4.068 4.068 0 0 1-.773 1.121c1.075-.31 2.217-.621 3.295-.32 1.364.379 2.542 1.709 3.925 1.406.832-.185 1.416-.926 2.164-1.337 1.402-.757 3.091-.281 4.654-.037.337.08.689.062 1.016-.052.251-.135.463-.33.617-.57.424-.56.751-1.188.968-1.857a6.967 6.967 0 0 1-.528.823" />
    </g>
  </svg>
)

export default DashboardBetaIllustration
