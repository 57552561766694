import React, { useEffect, useRef, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import SubscriptionPlanCard from "../subscriptionPlanCard/subscriptionPlanCard";
import { CREATE_STRIPE_SUBSCRIPTION } from "../../queries/queries";
import { useMutation } from "react-apollo";
import { SUBSCRIPTION_SCREEN } from "../subscriptionPlans/subscriptionPlansModal";
import StylesMobile from "./CheckoutForm.module.css";
import StylesWeb from "./CheckoutFormWeb.module.css";

export default function CheckoutForm({
  isDesktop,
  selectedPlan,
  getUserInfo,
  callCreateSubsApi,
  setSelectedViewScreen,
}) {
  const Styles = isDesktop ? StylesWeb : StylesMobile

  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [buttonVisible, setButtonVisible] = useState(false);
  const subsMainRef = useRef([])

  const [
    createStripeSubscription,
    {
      data: createStripeSubsData,
      loading: createStripeSubsLoading,
      error: createStripeSubsError,
    },
  ] = useMutation(CREATE_STRIPE_SUBSCRIPTION);

  useEffect(() => {
    if (!createStripeSubsLoading && createStripeSubsData) {
      getUserInfo();
      setSelectedViewScreen(SUBSCRIPTION_SCREEN.SUCCESS);
    }
  }, [createStripeSubsData, createStripeSubsError, createStripeSubsLoading]);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    if (callCreateSubsApi) {
      const { error, setupIntent } = await stripe.confirmSetup({
        elements,
        redirect: "if_required",
      });
      if (error) {
        setMessage(error.message);
      } else {
        let paymentMethodId = setupIntent.payment_method
        createStripeSubscription({
          variables: {
            priceId: selectedPlan.stripePriceId,
            paymentMethodId: paymentMethodId
          },
        });
      }
    } else {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        redirect: "if_required",
      });

      if (error) {
        setMessage(error.message);
      } else {
        let paymentMethodId = paymentIntent.payment_method
        createStripeSubscription({
          variables: {
            priceId: selectedPlan.stripePriceId,
            paymentMethodId: paymentMethodId
          },
        });
      }
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <div className={Styles.checkoutFormBody}>
      <div className={Styles.checkoutFormTitle}>
        Payment
      </div>
      <div className={Styles.checkoutFormSusbcriptionPlan}>
        <SubscriptionPlanCard
          subsMainRef={subsMainRef}
          selected={true}
          plan={selectedPlan}
          onPress={() => { }}
        />
        <form className={Styles.paymentForm} onSubmit={handleSubmit}>
          <PaymentElement
            className={Styles.paymentElement}
            options={paymentElementOptions}
            onReady={()=>{
              setButtonVisible(true)
            }}
          />
          { buttonVisible && <button
            disabled={isLoading || !stripe || !elements}
            className={Styles.checkoutFormSubmitBtn}
          >
            <span className={Styles.buttonText}>
              {isLoading ? (
                <div className={Styles.checkoutFormSpinner} id="spinner"></div>
              ) : (
                "Pay now"
              )}
            </span>
          </button> }
          {message && <div className={Styles.paymentMessage}>{message}</div>}
        </form>
      </div>
    </div>
  );
}
