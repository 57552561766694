import { useState } from "react";
import Styles from "./importLayout.module.css";
import ImportedContacts from "../../components/importedContacts/importedContacts";
import ImportClosestContacts from "../../components/importClosestContacts/ImportClosestContacts";
import LaunchScreenTransition from "../../../components/launchScreenTransition/launchScreenTransition";

/**
 * Dashboard Page
 * @constructor
 */
const ImportLayoutPage3 = () => {
  const [showSplash, setShowSplash] = useState(false);

  return (
    <div className={Styles.body}>
      <div className={Styles.bodyMain}>
        <div className={Styles.bodyCloseContacts}>
          <ImportedContacts isStartContact={true} />
          <ImportClosestContacts setShowSplash={setShowSplash} />
        </div>
      </div>
      {showSplash && <LaunchScreenTransition />}
    </div>
  );
};

// Page export
export default ImportLayoutPage3;
