import React from "react";
import { ButtonBase, Modal } from "@mui/material";
import Slide from "@mui/material/Slide";
import createStyle from "./styles";
import { GreenBgButton } from "../buttons/greenBgButton";
import { GreenBorderButton } from "../buttons/greenBorderButton";
import CloseIconGray from "../../images/svg/close_gray";

const UnpaidMemberPopupModal = (props) => {
  const { onBackdropPress, upgradeNow } = props;

  const style = createStyle();

  const onContinuePress = (e) => {
    e.stopPropagation();
    onBackdropPress?.();
    upgradeNow?.();
  };

  const notNow = (e) => {
    e.stopPropagation();
    onBackdropPress?.();
  };

  return (
    <Modal
      open={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Slide in={true} direction="up">
        <div
          onClick={(e) => e.stopPropagation()}
          style={{
            backgroundColor: "rgba(84, 83, 83, 0.2)",
            position: "absolute",
            bottom: 0,
            top: 0,
            width: "100%",
            justifyContent: 'center',
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={style.container}>
            <div style={style.content}>
              <ButtonBase
                style={style.crossButton}
                onClick={notNow}
              >
                <CloseIconGray style={style.closeIcon} />
              </ButtonBase>
              <span style={style.textQuestion}>
                This feature is not accesible under your current plan. Would you
                like to upgrade your plan?
              </span>
              <div style={style.buttonContainer}>
                <GreenBgButton
                  loading={false}
                  onClick={onContinuePress}
                  fullWidth
                  variant="contained"
                >
                  <div style={style.btTitle}>Upgrade Now</div>
                </GreenBgButton>
                <GreenBorderButton
                  fullWidth
                  variant="contained"
                  onClick={notNow}
                  style={{ marginTop: 10 }}
                >
                  <div style={style.btTitle}>Not Now</div>
                </GreenBorderButton>
              </div>
            </div>
          </div>
        </div>
      </Slide>
    </Modal>
  );
};

export default UnpaidMemberPopupModal;
