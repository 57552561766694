// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/backgrounds/layout-fingerprint.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../images/svg/onboarding-bg.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".resetPasswordSuccess_body__k13KS {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: right;\n  background-repeat: no-repeat;\n  height: 100vh;\n  background-color: #283147 ;\n  position: relative;\n  background-size: contain;\n}\n\n\n.resetPasswordSuccess_bodyMain__vcqx3 {\n  position: absolute;\n  z-index: 9;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  background-position: center;\n  background-repeat: no-repeat;\n  width: 360px;\n  background-color: #fff ;\n  padding: 0 30px;\n  padding-top: 40px;\n  height: 100vh;\n  display: flex;\n  flex-direction: column;\n  overflow-y: auto;\n}\n\n.resetPasswordSuccess_footer__1WB2h {\n  bottom: 0;\n  width: 100vw;\n  position: absolute;\n}\n", "",{"version":3,"sources":["webpack://./src/web/pages/resetPasswordSuccess/resetPasswordSuccess.module.css"],"names":[],"mappings":"AAAA;EACE,yDAAwE;EACxE,0BAA0B;EAC1B,4BAA4B;EAC5B,aAAa;EACb,0BAA0B;EAC1B,kBAAkB;EAClB,wBAAwB;AAC1B;;;AAGA;EACE,kBAAkB;EAClB,UAAU;EACV,yDAA8D;EAC9D,2BAA2B;EAC3B,4BAA4B;EAC5B,YAAY;EACZ,uBAAuB;EACvB,eAAe;EACf,iBAAiB;EACjB,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,SAAS;EACT,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":[".body {\n  background-image: url(\"../../images/backgrounds/layout-fingerprint.svg\");\n  background-position: right;\n  background-repeat: no-repeat;\n  height: 100vh;\n  background-color: #283147 ;\n  position: relative;\n  background-size: contain;\n}\n\n\n.bodyMain {\n  position: absolute;\n  z-index: 9;\n  background-image: url(\"../../../images/svg/onboarding-bg.svg\");\n  background-position: center;\n  background-repeat: no-repeat;\n  width: 360px;\n  background-color: #fff ;\n  padding: 0 30px;\n  padding-top: 40px;\n  height: 100vh;\n  display: flex;\n  flex-direction: column;\n  overflow-y: auto;\n}\n\n.footer {\n  bottom: 0;\n  width: 100vw;\n  position: absolute;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "resetPasswordSuccess_body__k13KS",
	"bodyMain": "resetPasswordSuccess_bodyMain__vcqx3",
	"footer": "resetPasswordSuccess_footer__1WB2h"
};
export default ___CSS_LOADER_EXPORT___;
