import React, { useState } from "react";
import notificationBanner from "../../../images/notification-banner.png";
import ImportContactsConfirmModal from "../importContactsConfirmModel/importContactsConfirmModel";
import Styles from "./importContactsBanner.module.css";
import { Button } from "@mui/material";

const ImportContactsBanner = (props) => {
  const [showConfirmModel, setShowConfirmModel] = useState(false);

  return (
    <div className={Styles.wrapper}>
      <span className={Styles.title}>You have not imported any contacts.</span>
      <div className={Styles.top}>
        <div>
          <span className={Styles.text}>
            Get notified about risk or opportunity in your network, and monitor your contacts. 
          </span>
        </div>
        <img className={Styles.illustration} src={notificationBanner} />
      </div>
      <Button className={Styles.connectDiv} onClick={() => setShowConfirmModel(true)}>
        <span className={Styles.connect}>Import Your Contacts</span>
      </Button>
      {showConfirmModel && (
        <ImportContactsConfirmModal
          onBackdropPress={() => setShowConfirmModel(false)}
        />
      )}
    </div>
  );
};

export default ImportContactsBanner;
