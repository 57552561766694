// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".social_container__Pt8TW {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    grid-gap: 1rem;\n    margin-bottom: 30px;\n  }\n\n  .social_containerWeb__VDmEa {\n    display: grid;\n    grid-template-columns: 1fr 1fr 1fr;\n    grid-gap: 1rem;\n    margin-bottom: 30px;\n  }", "",{"version":3,"sources":["webpack://./src/components/socialNetworksGrid/social.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,cAAc;IACd,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,kCAAkC;IAClC,cAAc;IACd,mBAAmB;EACrB","sourcesContent":[".container {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    grid-gap: 1rem;\n    margin-bottom: 30px;\n  }\n\n  .containerWeb {\n    display: grid;\n    grid-template-columns: 1fr 1fr 1fr;\n    grid-gap: 1rem;\n    margin-bottom: 30px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "social_container__Pt8TW",
	"containerWeb": "social_containerWeb__VDmEa"
};
export default ___CSS_LOADER_EXPORT___;
