import React from 'react';
import NagativeNews from './nagative-news'
import CriminalRecords from './criminal-records'
import PoliticalExposure from './political-exposure'
import WatchList from './watchlist'
import Debts from './debts'
import PapersLeaks from './papers-leaks'
import StateOwned from './state-owned'
import PositiveNews from './positive-news'
import CorporateRecords from './corporate-records'
import CourtRecords from './court-records'
import Crunchbase from './crunchbase'
import CourtFillings from './court-filings';
import Assets from './assets';
import MetaSearch from './meta-search'
import Property from './property'
import All from './all';
export const getFilterImage = (filterType, status, iconType, style = {}, {...props}) => {
  switch (filterType?.trim()) {
    case 'News':
      return <NagativeNews selected={status} iconType={iconType} style={style}  {...props}/>
    case 'Adverse Media':
      return <NagativeNews selected={status} iconType={iconType} style={style}  {...props}/>
    case 'Potential Criminal Records':
      return <CriminalRecords selected={status} iconType={iconType} style={style}  {...props}/>
    case 'Debts':
      return <Debts selected={status} iconType={iconType} style={style}  {...props}/>
    case 'Political Exposure':
      return <PoliticalExposure selected={status} iconType={iconType} style={style}  {...props}/>
    case 'Watchlists':
      return <WatchList selected={status} iconType={iconType} style={style}  {...props}/>
    case 'Papers and Leaks':
      return <PapersLeaks selected={status} iconType={iconType} style={style}  {...props}/>
    case 'State Owned Enterprise':
      return <StateOwned selected={status} iconType={iconType} style={style}  {...props}/>
    case 'Positive News':
      return <PositiveNews selected={status} iconType={iconType} style={style}  {...props}/>
    case 'Corporate Records':
      return <CorporateRecords selected={status} iconType={iconType} style={style}  {...props}/>
    case 'Court Records':
      return <CourtRecords selected={status} iconType={iconType} style={style}  {...props}/>
    case 'Crunchbase':
      return <Crunchbase selected={status} iconType={iconType} style={style}  {...props} />
    case 'Court Decisions':
      return <CourtFillings selected={status} iconType={iconType} style={style}  {...props}/>
    case 'Assets':
      return <Assets selected={status} iconType={iconType} style={style}  {...props} />
    case 'MetaSearch':
      return <MetaSearch selected={status} iconType={iconType} style={style}  {...props} />
    case 'Property':
      return <Property selected={status} iconType={iconType} style={style}  {...props} />
    case 'All':
      return <All selected={status} iconType={iconType} style={{marginLeft: 6, marginRight: 6}} {...props}/>
    default:
      return <NagativeNews selected={status} iconType={iconType}  {...props}/>
  }
}