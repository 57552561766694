const parseSaveHistory = (data, recentSearchId, contactDataID, displayPostArticleRelevanceBot) => {
    let saveData = {...data};
    try{
        if(saveData.article){
            Object.keys(saveData.article).forEach(key => {
                saveData[key] = saveData.article[key]
            })
        }

        if(saveData.attributes){
            saveData.attributes.forEach((item)=>{
                delete item['__typename']
            })
        }
        
        saveData.timestamp = saveData.createdAt
        saveData.itemType = saveData.itemtype
        saveData.riskRanking = saveData.riskCategory

        if(contactDataID)
            saveData.contactID = contactDataID
        if(recentSearchId)
            saveData.recentSearchId = recentSearchId

        if (!displayPostArticleRelevanceBot)
            saveData.isRelevant = "YES"
        else
            saveData.isRelevant = "NO"

        delete saveData['article']
        delete saveData['createdAt']
        delete saveData['date']
        delete saveData['itemtype']
        delete saveData['profilePicture']
        delete saveData['riskCategory']
        delete saveData['terms']
        delete saveData['itemtype']
        delete saveData['originalDate']
        delete saveData['displayPostArticleRelevanceBot']
        delete saveData['articleType']
        delete saveData['url']
        delete saveData['isRead']
        delete saveData['attributesArr']
        delete saveData['icon']
        delete saveData['caseId']
    }catch(ee){}
    return saveData;
}
export default parseSaveHistory;