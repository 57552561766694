import * as React from "react"

function SubscribeHeaderArc(props) {
  return (
    <svg
      width={343}
      height={61}
      viewBox="0 0 343 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="a"
        style={{
          maskType: "alpha"
        }}
        maskUnits="userSpaceOnUse"
        x={-6}
        y={0}
        width={388}
        height={61}
      >
        <path
          d="M168.5 60c73.946 6.651 106-60 213-60H-6c42 0 80 51.5 174.5 60z"
          fill="#078167"
        />
      </mask>
      <g mask="url(#a)">
        <path
          d="M168.5 60c73.946 6.651 106-60 213-60H-6c42 0 80 51.5 174.5 60z"
          fill="#E7F3F0"
        />
        <circle cx={129.5} cy={120.5} r={94.5} fill="#77BAAB" />
        <circle cx={291} cy={54} r={48} fill="#77BAAB" />
      </g>
    </svg>
  )
}

export default SubscribeHeaderArc
