const createStyle = () => {
  return {
    container: {
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    tagContainer: {
      marginRight: 10,
      marginTop: 10,
    },
    tagAnimationStyle: {
      height: 35,
    },
    no_text: {
      alignSelf: 'center',
      fontSize: 18,
      marginTop: 20,
      fontWeight: '600',
      color: '#1A233B'}
  }
}

export default createStyle
