import { useEffect } from 'react'
import { TextField } from "@mui/material";
import Styles from './emailField.module.css'

export const EmailField = ({
  email,
  setEmail,
  checkFormValid,
  emailValid,
  setEmailValid,
  placeholder,
  disabled,
  isIcloud = false,
  fieldLabel
}) => {

  const emailValidation = (value) => {
    if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) {
      setEmailValid({ status: false, msg: 'Your email address must be valid' })
      return false
    } else {
      setEmailValid({ status: true, msg: '' })
      return true
    }
  }

  useEffect(() => {
    if ((emailValid.status !== undefined) || (email?.length === 0)) {
      emailValidation(email)
    }
  }, [email])

  return (
    <>
      {isIcloud ? <div className={`${Styles.appleId} ${Styles.fieldLabel}`}>Apple ID</div> :
        <div className={Styles.fieldLabel}>{fieldLabel ?? "Email Address"}</div>}

      <TextField
        disabled={disabled}
        value={email}
        fullWidth
        variant="outlined"
        type="email"
        placeholder={placeholder ? placeholder : "Your email address"}
        className="fieldInput"
        InputProps={{
          sx: {
            color: "#142030",
            height: 48,
            marginTop: 1,
            backgroundColor: "#FFFFFF",
            "& ::placeholder": {
              color: "#8E96A8"
            },
          }
        }}
        FormHelperTextProps={{ style: { backgroundColor: 'transparent', padding: 0, margin: 0, marginTop: 4 } }}
        error={emailValid.status === false && checkFormValid}
        helperText={checkFormValid && emailValid.msg}
        onChange={
          (e) => setEmail(e.target.value)
        }
      />
    </>
  )
}
