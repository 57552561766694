import "./style.css";
import "../../App.css";
import { Button } from "@mui/material";
import { RWebShare } from "react-web-share";

const CardItem = ({
  description,
  name,
  type,
  title,
  icon,
  titleIcon,
  style,
  descriptionIcon,
  descriptionTextBoxStyle,
  onPressItem,
  user
}) => {
  const cardView = <div className="body" style={style} onClick={()=>onPressItem?.(type)}>
  {titleIcon && (
    <div>
      <span>{titleIcon}</span>
      <span className="titleIcon"> {titleIcon}</span>
      <span className="titleIcon"> {titleIcon}</span>
    </div>
  )}
  <span className="descriptionBox">
    <span className="descriptionTextBox" style={descriptionTextBoxStyle}>
    <div className="nameIcon">
        {icon && <div className="icon">{icon}</div>}
        {!titleIcon && <div
          className={icon ? "smallCopyTitle" : "buttonTextSmall"}
          style={icon ? {} : { color: "#078167" }}
        >
          {name}
        </div>}
      </div>
      {title && <div className="h3">{title}</div>}
      <span className="smallCopy2" style={{ color: "#697080" }}>
        {description}
      </span>
      {titleIcon && <div
          className={"buttonTextSmall"}
          style={{ color: "#078167" }}
      >
        {name}
      </div>}
    </span>
    {descriptionIcon && <span className="icon">{descriptionIcon}</span>}
  </span>
</div>

  return (
    <>
      {type == 'referFriend' ? 
      <RWebShare
              data={{
                url: ('https://www.ferret.ai?urefid=' + user?.id),
                title: "Share Ferret",
              }}
              onClick={() => console.log("shared successfully!")}
            >
          {cardView}
      </RWebShare>
      : cardView
      }
    </>
  );
};

export default CardItem;
