// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".filterView_filterShadow__fN\\+5T {\n    box-shadow: 50px 6px 9px -10px #000000;  \n}", "",{"version":3,"sources":["webpack://./src/components/filtersView/filterView.module.css"],"names":[],"mappings":"AAAA;IACI,sCAAsC;AAC1C","sourcesContent":[".filterShadow {\n    box-shadow: 50px 6px 9px -10px #000000;  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterShadow": "filterView_filterShadow__fN+5T"
};
export default ___CSS_LOADER_EXPORT___;
