import { useEffect, useState, useContext } from "react";
import { UserContext } from "../../contexts/userContext";
import ProfilePicture from "../profilePicture/profilePicture";
import { useNavigate } from "react-router-dom";
import DarkCardItem from "../cardItem/darkCardItem";
import { FingerPrintLogo } from "../../images/svg/fingerPrintLogo";
import { logout } from "../../utils/auth";
import SubscriptionPlansModal from "../subscriptionPlans/subscriptionPlansModal";
import ProfilePictureModal from "../ProfilePictureModal/profilePictureModal";
import EditPhoto from "../../images/svg/editPhoto";
import { savedSearchesStore } from '../../store/savedSearchesStore'
import Styles from './account.module.css'
import { ArrowForward } from "../../images/svg/arrowForward";
import { IconButton } from "@mui/material";
import back_white from "../../images/svg/back_white.svg";
import { notificationStore } from "../../store/notificationStore";

function ListItem({ name, action }) {
  return (
    <button className={Styles.accountNavItem} onClick={action}>
      {name}
      {action && <ArrowForward style={{ paddingRight: "17px" }} />}
    </button>
  );
}

export default function Account() {

  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [appVersion, setAppVersion] = useState("")
  const [subscriptionModalVisible, setSubscriptionModalVisible] = useState(false)
  const [subscriptionExpired, setSubscriptionExpired] = useState(false)
  const removeAll = savedSearchesStore((state) => state.removeAll)
  const removeAllNotification = notificationStore((state) => state.removeAll)

  useEffect(() => {
    const majorVersion = process.env.REACT_APP_MAJOR_VERSION;
    const minorVersion = process.env.REACT_APP_MINOR_VERSION;
    const patchVersion = process.env.REACT_APP_PATCH_VERSION;
    const buildNumber = process.env.REACT_APP_BUILD_NUMBER;
    let version = `v${majorVersion}.${minorVersion}.${patchVersion} (build ${buildNumber})`
    setAppVersion(version)
  }, [])

  useEffect(() => {
    setSubscriptionExpired(user?.isSubscribed)
  }, [user, subscriptionModalVisible])

  const menuItems = [
    {
      name: "Edit Profile",
      action: () => navigate("/editProfile"),
    },
    {
      name: "Manage Contact Lists",

      action: () => {
        const data = {
          screenName: 'Account'
        }
        navigate("/importContactsLanding", { state: JSON.stringify(data) })
      },
      // action: () => {
      //   GoogleSignin.signOut()
      //   navigation.navigate('ImportContactsLanding', {
      //     screenName: 'Account',
      //   })
      // }
    },
    {
      name: "Manage Subscription",
      action: () => { setSubscriptionModalVisible(true) },
    },
    {
      name: "FAQ's",
      action: () => window.open(
        'https://www.ferret.ai/faqs',
        '_blank'
      )
    },
    {
      name: "Support",
      action: () => window.open(
        'https://www.ferret.ai/mobile-support',
        '_blank'
      )
    },
    {
      name: "Terms of Use",
      action: () => window.open(
        'https://www.ferret.ai/terms-of-use',
        '_blank'
      )
    },
    {
      name: "Privacy Policy",
      action: () => window.open(
        'https://www.ferret.ai/privacy-policy',
        '_blank'
      )
    },
    {
      name: "Logout",
      action: () => {
        logout().then(() => {
          removeAll()
          removeAllNotification()
          // setOnboardingState(getOnboardingInitialStatus())
        });
      },
    }
  ];

  return (
    <div className={Styles.accountComponent}>
      <header className={Styles.navHeader}>
        <IconButton
          className={Styles["backIconBtn"]}
          onClick={() => {
            navigate(-1);
          }}
        >
          <img src={back_white} alt="back_white" className={Styles.backBtn} />
        </IconButton>
        <div className={Styles.navHeaderTitle}>
          Account
        </div>
      </header>

      <main className={Styles.accountBody}>
        <div className={Styles.profilePhotoDiv}>
          <div className={Styles.profilePhoto}>
            <ProfilePicture
              avatarStyle={{ width: "140px", height: "140px" }}
              profilePhoto={user?.profilePhoto}
            />
          </div>
          <div className={Styles.profilePhotoEdit}>
            <ProfilePictureModal customIcon={<EditPhoto />} />
          </div>
        </div>

        <div className={Styles.name}>
          {user.givenName} {user.familyName}
        </div>

        {!subscriptionExpired && (
          <div style={{ margin: "0 16px" }}>
            <DarkCardItem
              name="Start 7-day Free Trial"
              descriptionIcon={
                <FingerPrintLogo className={Styles.fingerprintLogo} />
              }
              descriptionBoxStyle={{ paddingTop: "0px" }}
              descriptionTextBoxStyle={{
                gap: 0,
                minHeight: "20px",
                justifyContent: "center",
              }}
              imgSpanStyle={{ marginTop: "-20px" }}
              style={{ height: "5%" }}
              imgStyles={{ marginTop: "-18px" }}
              onClick={() => setSubscriptionModalVisible(true)}
              cardType="small"
            />
          </div>
        )}
        <section className={Styles.list}>
          {menuItems.map((l) => (
            <ListItem key={l.name} name={l.name} action={l.action} />
          ))}
          <span className={Styles.version}>{appVersion}</span>
        </section>
      </main>
      {subscriptionModalVisible && <SubscriptionPlansModal
        subscriptionModalVisible={subscriptionModalVisible}
        setSubscriptionModalVisible={setSubscriptionModalVisible}
      />}

    </div>
  );
}
