import { ButtonBase } from "@mui/material";
import React, { useEffect } from "react";
import Styles from "./recordUnavailable.module.css";

const RecordUnavailable = ({
  sourceUrl,
  errorMsg,
  screenHeight,
  setIsSummaryExpanded,
  isSummaryExpanded,
  isExpandable,
  setIsArticleOpened
}) => {
  const openLink = () => {
    window.open(sourceUrl, "_blank");
  };

  useEffect(()=>{
    if (setIsArticleOpened) {
      setIsArticleOpened?.(true)
    }
  }, [])

  return (
    <div className={Styles.container} style={{ height: screenHeight }}>
      <img
        className={Styles.image}
        src={require("../../../../images/post-article-relevancy-bot-logo.png")}
        resizeMode={"contain"}
      />
      <p className={Styles.title}>This record is unavailable</p>
      <p className={Styles.text}>
        It looks like this record may have been modified or taken offline.
      </p>
      {isExpandable ? (
        <ButtonBase
          disabled={isSummaryExpanded}
          className={Styles["showCacheBtn"]}
          onClick={() => {
            setIsSummaryExpanded(true);
          }}
        >
          <span
            className={
              isSummaryExpanded
                ? `${Styles.showCacheBtnTxt} ${Styles.showCacheBtnExpanded}`
                : Styles.showCacheBtnTxt
            }
          >
            Show Cache
          </span>
        </ButtonBase>
      ) : sourceUrl ? (
        <ButtonBase
          className={Styles["showCacheBtn"]}
          onClick={() => {
            openLink();
          }}
        >
          <span className={Styles.showCacheBtnTxt}>Show Source Website</span>
        </ButtonBase>
      ) : (
        <></>
      )}
    </div>
  );
};

export default RecordUnavailable;
