
const createStyle = (theme) => {
  return {
    container: {
      paddingBottom: 6,
      flexDirection: 'column',
      display: "flex",
      whiteSpace: "nowrap",
      alignItems: "center",
      width: 'fit-content',
      cursor: 'pointer',
    },
    containerCount: {
      alignItems:'center',
      flexDirection: 'row',
    },
    countText: {
      fontSize: 14,
      color: '#222F4B',
      marginEnd: 5,
    },
    lableText: {
      letterSpacing: -0.01,
      fontSize: 12,
      color: '#8E96A8',
      fontWeight: '600',
      fontStyle: 'normal',
      fontFamily: "Proxima Nova",
      whiteSpace: 'break-spaces',
      display: 'ruby',
      paddingLeft: 4,
      paddingRight: 4,
    },
    checkBox: {
      margin: 0,
      marginLeft: 0,
      marginRight: 0,
      padding: 0
    },
  }
};

export default createStyle;