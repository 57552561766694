import * as React from "react"
const NagativeNews = (props) => {
  return(
    <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    >
      <g clipPath="url(#a)">
        <path
          d="M11.667 6H7.333c-.92 0-1.666.746-1.666 1.667V14c0 .92.746 1.667 1.666 1.667h4.334c.92 0 1.666-.746 1.666-1.667V7.667c0-.92-.746-1.667-1.666-1.667ZM19.667 8.067H17a1 1 0 0 0-1 1v.666a1 1 0 0 0 1 1h2.667a1 1 0 0 0 1-1v-.666a1 1 0 0 0-1-1ZM18 13.4h-1a1 1 0 0 0-1 1v.667a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V14.4a1 1 0 0 0-1-1ZM13.667 18.733H6.333a1 1 0 0 0-1 1v.667a1 1 0 0 0 1 1h7.334a1 1 0 0 0 1-1v-.667a1 1 0 0 0-1-1ZM12.667 24.067H6.333a1 1 0 0 0-1 1v.666a1 1 0 0 0 1 1h6.334a1 1 0 0 0 1-1v-.666a1 1 0 0 0-1-1Z"
          fill={props.selected ? "#142030" :  "#b5b9c4"}
        />
        <path
          d="M30.828 10.505A4 4 0 0 1 32 13.333V18c0 1.463-.029 2.775-.113 3.95a8.68 8.68 0 0 0-2.559-4.868c.004-.351.005-.712.005-1.082v-2.667A1.333 1.333 0 0 0 28 12h-2a.666.666 0 0 0-.667.667V14c0 .306.002.607.005.904a8.676 8.676 0 0 0-2.667-.212 58.21 58.21 0 0 1-.004-.692V4A.667.667 0 0 0 22 3.333H3.333A.667.667 0 0 0 2.667 4v22.667a2 2 0 0 0 2 2h10.666c.408 0 .795-.002 1.164-.007a8.662 8.662 0 0 0 3.408 2.633c-.804.027-1.66.04-2.572.04H4.667A4.667 4.667 0 0 1 0 26.667V3.333A2.667 2.667 0 0 1 2.667.667h20a2.667 2.667 0 0 1 2.666 2.666v6H28a4 4 0 0 1 2.828 1.172Z"
          fill={props.selected ?  "#142030" :  "#b5b9c4"}
        />
        <path
          d="M23.333 14.667A8.666 8.666 0 1 0 32 23.333a8.68 8.68 0 0 0-8.667-8.666Zm3.334 10H20A1.333 1.333 0 1 1 20 22h6.667a1.334 1.334 0 0 1 0 2.667Z"
          fill={props.selected ? props.iconType =="blackWhite"? "#1E1E1E": "#DA3528" : '#b5b9c4'}
        />
      </g>
      <defs>
        <clip-path id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clip-path>
      </defs>
  </svg>
)
}

export default NagativeNews
