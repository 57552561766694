import React from 'react'

const OkArrowGray = (props) => {
  return (
    <svg width="11px" height="9px" viewBox="0 0 11 9" {...props}>
      <path
        d="M10.7 1.625a1.025 1.025 0 00-1.46 0L3.73 7.214 1.662 5.118a.967.967 0 00-1.378 0 1 1 0 000 1.397l2.837 2.877a.967.967 0 001.378 0l.019-.022.021-.02 6.16-6.245a1.06 1.06 0 000-1.48"
        transform="translate(-35 -476) translate(20 416) translate(15 59)"
        fill="#8E96A8"
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      />
    </svg>
  )
}

export default OkArrowGray