import { useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

const SingleBtnAlert = ({ title, description, btnTxt, onBtnClick }) => {

  const [open, setOpen] = useState(true);

  return (
    <>
      <Dialog
        open={open}
      >
        {title && <DialogTitle>
          {title}
        </DialogTitle>
        }
        <DialogContent>
          <DialogContentText>
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setOpen(false)
            onBtnClick()
          }}>
            {btnTxt}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default SingleBtnAlert;