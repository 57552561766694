// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".searchSelect_searchtextField__DcsSe {\n  border: 2px solid rgba(255, 255, 255, 0.1);\n  border-radius: 8px;\n  display: flex;\n  flex-direction: row;\n  height: 40px;\n  margin: 0 0 8px;\n}\n\n.searchSelect_searchFiledIcon__AtJrB {\n  align-items: center;\n  display: flex;\n  z-index: 10;\n}", "",{"version":3,"sources":["webpack://./src/web/components/search/searchSelect.module.css"],"names":[],"mappings":"AAAA;EACE,0CAA0C;EAC1C,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,WAAW;AACb","sourcesContent":[".searchtextField {\n  border: 2px solid rgba(255, 255, 255, 0.1);\n  border-radius: 8px;\n  display: flex;\n  flex-direction: row;\n  height: 40px;\n  margin: 0 0 8px;\n}\n\n.searchFiledIcon {\n  align-items: center;\n  display: flex;\n  z-index: 10;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchtextField": "searchSelect_searchtextField__DcsSe",
	"searchFiledIcon": "searchSelect_searchFiledIcon__AtJrB"
};
export default ___CSS_LOADER_EXPORT___;
