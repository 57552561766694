import { useContext } from "react";
import { PhotoIcon } from "../../images/svg/photoIcon";
import { useMutation } from "react-apollo";
import { UPDATE_FERRET_USER } from "./../../queries/queries";
import { setUserInfo } from "../../root-navigation";
import { UserContext } from "../../contexts/userContext";
import { getBase64, resizeImage } from "../../utils/utils";
import Styles from './profilePictureModal.module.css'

export default function ProfilePictureModal({ customIcon }) {
  const { user, setUser } = useContext(UserContext);

  const [userUpdaterMutation] = useMutation(UPDATE_FERRET_USER, {
    fetchPolicy: "no-cache",
    onCompleted: ({ updateUser }) => {
      let u = setUserInfo(updateUser);
      u.tempProfilePhoto = null;
      setUser({ ...u });
    },
    onError: (error) => {
      alert("Error saving your profile picture");
      let u = user;
      u.tempProfilePhoto = null;
      setUser({ ...u });
    },
  });

  const onImageChange = (e) => {
    let file = e.target.files[0];
    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        resizeImage(result).then((response) => updateUserImage(response));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateUserImage = (base64String) => {
    let variables = { base64String };
    userUpdaterMutation({
      variables: variables,
    });
    let u = user;
    u.tempProfilePhoto = base64String;
    setUser({ ...u });
  };

  return (
    <>
      <input
        id="icon-button-video"
        type="file"
        accept="image/*"
        onChange={onImageChange}
        className={Styles.inputPhoto}
      />
      <label

        for="icon-button-video"
      >
        {customIcon ? customIcon : <PhotoIcon />}
      </label>
    </>
  );
}
