import React, {useState, useRef} from 'react';
import createStyle from './styles';
import {useMutation} from 'react-apollo';
import {FORGOT_PASSWORD_REQUEST_OTP, VERIFY_OTP} from '../../queries/queries';
import OTPIllustrationLogo from '../../images/svg/otp-Illustration';
import OtpInput from 'react-otp-input';
import { AppBar, Grid, IconButton } from '@mui/material';
import { BackIcon } from '../../images/svg/back';
import { useNavigate, useLocation } from "react-router-dom";
import { GreenBgButton } from '../buttons/greenBgButton';
import SingleBtnAlert from '../alert/singleBtnAlert';


function ForgotPasswordOtpVerifyForm({ authToken, setAuthToken, userEmail }) {
  const navigate = useNavigate();
  const styles = createStyle();
  const [displayError, setDisplayError] = useState(false);
  const [submitButtonState, setSubmitButtonState] = useState(true);
  const errorMessage = 'Incorrect OTP';

  const input1 = useRef();
  const [otpInput, setOtpInput] = useState();
  const [showSingleBtnAlert, setShowSingleBtnAlert] = useState({ status: false, msg: "" });

  //verify otp
  const [verifyOtp, {loading, error}] = useMutation(VERIFY_OTP, {
    onCompleted(data) {
      setSubmitButtonState(true);
      setShowSingleBtnAlert({ status: true, msg: "OTP verified successfully!" })
      navigate('/resetPassword', {
        state: {
          verifiedAuthToken: data.verifyOtp.authToken,
        }
      });
    },
    onError() {
      setDisplayError(true);
      setTimeout(() => {
        setSubmitButtonState(true);
        setDisplayError(false);
      }, 3000);
    },
  });

  //resend otp
  const [requestOtp, { loading: requestOtpLoading }] = useMutation(FORGOT_PASSWORD_REQUEST_OTP, {
    onCompleted(data) {
      setAuthToken(data.requestOtp.authToken);
      setShowSingleBtnAlert({ status: true, msg: "OTP sent successfully!" })
      setSubmitButtonState(true);
    },
    onError() {
      setSubmitButtonState(true);
    },
  });

  return (
    <>
      <div style={styles.otpContainer}>
        <OtpInput
          ref={input1}
          focusStyle={styles.focusStyle}
          inputStyle={styles.roundedTextInput}
          onChange={(text) => setOtpInput(text)}
          value={otpInput}
          numInputs={6}
          isInputNum={true}
        />
      </div>
      {displayError && <span style={styles.errorMessage}>{errorMessage}</span>}
      <div style={styles.resendTextBlock}>
        <span style={styles.resendQuestion}>Didn't recieve the code? </span>
        <span
          style={styles.resendText}
          onClick={() => {
            requestOtp({variables: {email: userEmail}});
            setSubmitButtonState(false);
          }}>
          Resend Code
        </span>
      </div>
      <div style={styles.separator}>
        <GreenBgButton
          disabled={!submitButtonState}
          fullWidth
          variant="contained"
          loading={loading || requestOtpLoading}
          onClick={() => {
            verifyOtp({variables: {authToken: authToken, otp: otpInput}});
            setSubmitButtonState(false);
          }}
        >
          <div style={{fontFamily: "Proxima Nova", fontSize: 16, fontWeight: "600", textTransform: "capitalize"}}>Verify & Proceed</div>
        </GreenBgButton>
      </div>

      {(showSingleBtnAlert.status == true) && <SingleBtnAlert
        description={showSingleBtnAlert.msg}
        btnTxt="OK"
        onBtnClick={() => {
          setShowSingleBtnAlert({ status: false, msg: "" })
        }}
      />
      }
    </>
  );
}

const TextInfo = () => {
  const styles = createStyle();
  return (
    <>
      <span style={styles.textQuestion}>Enter your OTP</span>
      <span style={styles.textSimple}>
        Please use the OTP sent to your registered email to reset your password
      </span>
    </>
  );
};

export default function ForgotPasswordOtpVerify() {
  const { state } = useLocation();
  const {authT, userEmail} = state;
  const styles = createStyle();
  const [authToken, setAuthToken] = useState(authT);
  const navigate = useNavigate();

  return (
    <>
      <AppBar style={styles.header} elevation={0}>
        <Grid
          container
          direction="row"
          alignItems="center"
        >
          <Grid item xs={1}>
            <IconButton onClick={() => navigate((-1), { replace: true })}>
              <BackIcon />
            </IconButton>
          </Grid>

          <Grid item xs>
            <span style={styles.headerText}>
              Password Reset
            </span>
          </Grid>

          <Grid item xs={1}>
          </Grid>
        </Grid>
      </AppBar>
      <div
        style={{
          ...styles.container,
          marginTop: styles.container.marginTop,
        }}>
        
        <OTPIllustrationLogo style={{...styles.logo}} />
        <TextInfo />
        <div style={styles.formContainer}>
          <ForgotPasswordOtpVerifyForm
            authToken={authToken}
            setAuthToken={setAuthToken}
            userEmail={userEmail}
          />
        </div>
      </div>
    </>
  );
}
