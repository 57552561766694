import React from "react";
import AddressProfile from "../../../images/svg/address-profile";
import Styles from "./article.module.css";

const TracersView = (props) => {
  const { style, data, newsItem } = props;

  const renderItem = (item, header) => {
    try {
      if (item) {
        const data = JSON.parse(item);
        if (Array.isArray(data)) {
          return data?.map((mItem) => renderSigleItem(mItem, header));
        } else {
          return renderSigleItem(data, header);
        }
      } else {
        return (
          <div>
            <span style={{ ...style.tracersValue, color: "#142030" }}>
              {"No information available."}
            </span>
          </div>
        );
      }
    } catch (e) {
      console.log("data======eee", e);
    }
  };

  const renderSigleItem = (data, header) => {
    try {
      return Object.keys(data).map((key) => {
        let value = data[key] && data[key] != "" ? data[key] : "Not specified";
        if (key == "State") value = value.toUpperCase();
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {header != key &&
              header != "Owner(s)" &&
              header != "Site Address" && (
                <span style={style.tracersKey}>{key}</span>
              )}
            <div style={style.addressIconView}>
              {header == "Site Address" && (
                <div style={{ width: 16, height: 16, marginLeft: 16 }}>
                  <AddressProfile />
                </div>
              )}
              <span
                style={
                  value == "Not specified"
                    ? { color: "#8E96A8", ...style.tracersValue }
                    : {
                        ...style.tracersValue,
                        fontWeight: header != "Owner(s)" ? "300" : "700",
                      }
                }
                dangerouslySetInnerHTML={{ __html: value }}
              />
            </div>
          </div>
        );
      });
    } catch (e) {
      console.log("data======eee", e);
    }
  };

  const renderSectionHeader = (title, isSeperator) => (
    <div style={{ marginBottom: 10, paddingTop: isSeperator ? 0 : 4 }}>
      <div
        style={{
          backgroundColor: "#E5E5E5",
          height: isSeperator ? 1 : 0,
          marginBottom: 10,
        }}
      ></div>
      <span style={style.tracersTitle}>{title}</span>
    </div>
  );

  return (
    <div style={style.tracersContainer}>
      {data?.map((item, index) => (
        <>
          {renderSectionHeader(item.header, index != 0)}
          <div
            className={
              newsItem?.category != "Assets" ||
              item.header == "Owner(s)" ||
              item.header == "Site Address"
                ? Styles.containerNoGrid
                : Styles.containerGrid
            }
          >
            {renderItem(item.body, item.header)}
          </div>
        </>
      ))}
      <p
        style={{
          fontSize: 12,
          color: "#aaaaaa",
          paddingTop: 20,
          paddingBottom: 20,
          textAlign: "center",
        }}
      >
        {newsItem?.articleId
          ? `${newsItem?.articleId}-${newsItem?.sourceIdentifier}-${newsItem?.verifiedBy}-${newsItem?.matchingLogic}`
          : `${newsItem?.sourceIdentifier}-${newsItem?.verifiedBy}-${newsItem?.matchingLogic}`}
      </p>
    </div>
  );
};
export default TracersView;
